import { LOCALES } from 'src/i18n/locales';
import { TLocaleId } from 'src/i18n';

const portugueseBrazilLangTranslations: { [key: string]: { [idKey in TLocaleId]: string } } = {
  [LOCALES['Português Brasil (Portuguese Brazil)']]: {
    'MursionPortal.SimAttendance.Tooltip.Error': 'Session was unable to be completed due to technical difficulties on the Learner’s side. (if the session was able to be completed after an error this session should be marked complete and not error).',// TODO add translations
    'SessionReports.ErrorOnLoadingVideo': 'Use um laptop ou computador para visualizar sua gravação.',
    'MursionPortal.ConfirmationModal.Project.InvitationsConfirmationText': 'Tem certeza de que deseja convidar <strong>todos os alunos atribuídos</strong> que ainda não concluíram os cenários do projeto? Eles receberão um convite por e-mail para o agendamento durante a noite.',
    'MursionPortal.ConfirmationModal.Scenario.InvitationsConfirmationText': 'Tem certeza de que deseja convidar <strong>todos os alunos atribuídos</strong> que ainda não concluíram este cenário? Eles receberão um convite por e-mail para o agendamento durante a noite.',
    'Clients.Modal.Label.VideoRecordingDataRetentionDays.Tooltip': 'Número de dias que o cliente gostaria de manter seus dados de gravação de vídeo. Após essa quantidade de dias, as gravações de vídeo serão excluídas.',
    'Settings.SSO.CopyScenarioLink': 'Copiar link de convite para o cenário',
    'MursionPortal.Label.VideoRecordingSharing': 'Compartilhamentos ou downloads do usuário de seus vídeos de sessão',
    'MursionPortal.Status.Capitalized.LateCancelled': 'CANCELADA_COM_ATRASO',
    'MursionPortal.Status.Capitalized.EarlyCancelled': 'CANCELADA_ANTECIPADAMENTE',
    'MursionPortal.Status.Abbreviation.LateCancelled': 'LC',
    'MursionPortal.Status.Abbreviation.Cancelled': 'C',
    'MursionPortal.Text.Congratulation': 'Parabéns!',
    'MursionPortal.Text.PasswordChanged': 'Sua senha foi alterada',
    'MursionPortal.Text.PasswordShouldBeSame': 'Suas novas senhas devem corresponder.',
    'Filters.LateRescheduled': 'Reagendada(s) com atraso',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.Scheduled': 'Próximas sessões agendadas que ainda não foram concluídas (incluindo as que estão em execução)',
    'MursionPortal.Checkbox.EnableSmartMetrics': 'Ativar análise de voz do aluno da sessão',
    'Dashboard.SimulationTable.Column.TimesLateRescheduled': 'Quantas vezes remarcadas com atraso',
    'Dashboard.SimulationTable.ColumnTooltip.TimesLateRescheduled': 'Vezes em que esta simulação foi remarcada com atraso',
    'SessionReports.Label.CopyVideoLink': 'Copiar link do vídeo',
    'Login.LoginAgain': 'Entrar novamente',
    'Dashboard.VideosTable.Column.SessionStart': 'Início da sessão',
    'Filters.Date.LastSevenDays': 'Semana passada',
    'Filters.Date.NextSevenDays': 'Semana que vem',
    'Filters.Date.ThisYear': 'Este ano',
    'Mursion.Portal.Status.LearnerMissed': 'O ALUNO FALTOU',
    'Mursion.Portal.Status.SystemCancelled': 'Cancelado',
    'Mursion.Portal.Status.LateCancelled': 'Cancelado com atraso',
    'Mursion.Portal.Status.EarlierCancelled': 'Cancelado com antecedência',
    // 'MursionPortal.Dashboard.Chart.label.LearnerStatus.LearnerCompleted': 'Concluído',
    'MursionPortal.Dashboard.UpcomingSessions': 'Próximas sessões',
    'MursionPortal.Label.Opened': 'Aberto',
    'MursionPortal.Label.ActedOn': 'Ação em',
    'MursionPortal.Label.Unread': 'Não lida',
    'MursionPortal.Label.Timeline': 'Cronograma do projeto',
    'Projects.ProjectInfo.Label.TimelineUtc': 'Linha do tempo do projeto (UTC)',
    'MursionPortal.ScenarioInfo.Header.Description': 'Descrição',
    'MursionPortal.TimelineUTC': 'Linha do tempo do projeto<code>{word}</code> (UTC)',
    'MursionPortal.Label.ChangePassword': 'Alterar a senha',
    'MursionPortal.Label.ChangeYourPassword': 'Altere sua senha',
    'MursionPortal.Label.NewChangePassword': 'Digite sua nova senha',
    'MursionPortal.Label.OldPassword': 'Digite sua senha atual',
    'MursionPortal.Label.RepeatChangePassword': 'Redigite sua nova senha',
    'MursionPortal.Prompt.SessionScheduled': '<code>A sessão</code> foi cancelada!',
    'MursionPortal.Status.Booked': 'Reservado',
    'MursionPortal.Status.Pending': 'Pendente',
    'MursionPortal.Completed': 'Concluído',
    'MursionPortal.Status.Missed': 'Perdido',
    'MursionPortal.Status.Cancelled': 'Cancelado',
    'MursionPortal.Status.LateCancelled': 'Cancelado com atraso',
    'MursionPortal.Status.EarlyCancelled': 'Cancelado com antecedência',
    'MursionPortal.Status.LicenseCancelled': 'Cancelado',
    'MursionPortal.Status.Error': 'Erro',
    'MursionPortal.ConversationalFlow.label.zoom.all': 'Todos',
    'Clients.ImportUsers.DownloadErrorsButton': 'Erros de download',
    'Mursion.Portal.Edit.Team.DownloadErrorButton': 'Erros de download',
    'MursionPortal.Dashboard.NextOnProject': 'Cenários para agendamento',
    'MursionPortal.Dashboard.NextOnProject.Footer.EndDate': 'Você tem até {endDate} para concluir este cenário.',
    'MursionPortal.Dashboard.NextOnProject.Footer.CompletionRate': 'Você tem até {endDate} para concluir até {completionRate} vezes.',
    'MursionPortal.Dashboard.NothingPlanned.text1': 'Você está em dia! Quando voce tem',
    'MursionPortal.Dashboard.NothingPlanned.text2': 'algo para agendar, aparecerá aqui.',
    'MursionPortal.Learner.Missed': 'O ALUNO FALTOU',
    'MursionPortal.AriaImage.Attribute.Next': 'Próximo:',
    'MursionPortal.AriaImage.Attribute.Close': 'Fechar:',
    'MursionPortal.AriaImage.Attribute.Back': 'Voltar:',
    'MursionPortal.AriaImage.Attribute.Delete': 'Excluir:',
    'MursionPortal.AriaImage.Attribute.Remove': 'Remover',
    'MursionPortal.AriaImage.Attribute.Document': 'Documento',
    'MursionPortal.AriaImage.Attribute.Video': 'Vídeo',
    'MursionPortal.AriaImage.Attribute.DeleteDocument': 'Excluir documento',
    'MursionPortal.ScenarioInfo.CompletionRate': '{completionRate} Vezes',
    'MursionPortal.Button.RefreshPage': 'Atualizar a página',
    'MursionPortal.Prompt.CalendarUpdatedOutdatedNotification': 'Tem certeza de que deseja fechar? Sua página de calendário permanecerá desatualizada.',
    'Reports.SessionListHeader.Label.AverageTime': 'Tempo médio <code>em cada sessão</code>',
    'MursionPortal.Users.Archive.HasSessions': 'Não foi possível arquivar o Simulation Specialist porque uma ou mais sessões estão agendadas para este Simulation Specialist. Por favor, altere o Sim Specialist.',
    'MursionPortal.Label.InactiveSIMCertification.Tooltip': 'Use se houver um Sim certificado nesta simulação, mas que não está disponível para novas simulações.',
    'MursionPortal.Label.InactiveSIMCertification': 'Certificação de Sim Inativa',
    'Dashboard.Filters.FilterBySessionDate': 'FILTRAR POR DATAS DA SESSÃO',
    'Dashboard.LeftPane.CategoryHeader.Emails': 'E-MAILS',
    'Projects.ProjectInfo.Label.CancellationDeadline.Tooltip': 'Este é o número de dias/horas/minutos que um aluno teria para cancelar, uma vez registrado para uma simulação dentro do projeto. Se os alunos cancelarem após o prazo, a sessão será considerada concluída para fins de cobrança.',
    'Projects.ProjectInfo.Checkbox.AllowSessionRecording': 'Permitir gravação de sessão',
    'MursionPortal.Label.SendRecordedVideosTo': 'Envie vídeos gravados para:',
    'MursionPortal.Checkbox.AllowLearnerToRefuseRecording': 'Permitir que o aluno participe da sessão se o aluno não consentir com a gravação',
    'Projects.ProjectInfo.SchedulingInterval': 'Intervalo de agendamento',
    'Projects.ProjectInfo.SchedulingInterval.Tooltip': 'Permite que o agendamento ocorra em uma cadência diferente da duração da sessão.',
    'Projects.ProjectInfo.SchedulingIntervalLength': 'Duração do intervalo de agendamento',
    'Projects.ProjectInfo.SchedulingIntervalLength.tooltip': 'Determina a frequência com que os blocos de agendamento são vistos por um aluno. Se definido como 15, os alunos verão os blocos de agendamento em intervalos de 15 minutos. Não define a duração da sessão, apenas com que frequência as sessões são executadas.',
    'Projects.ProjectInfo.DeliveryMode.tooltip': 'A plataforma usada por seus alunos. Defina como ML3 se desejar ativar o software de conexão direta Mursion Magic e/ou ML3. ML3z = Zoom. Mursion Meet = Google Meet.',
    'MursionPortal.Label.WebOnly': 'Somente Web',
    'MursionPortal.Label.DesktopOnly': 'Somente área de trabalho',
    'MursionPortal.Label.DesktopAndWeb': 'Computador e Web',
    'MursionPortal.EmailCategory.CallsToAction': 'Chamadas para ação',
    'MursionPortal.Dashboard.EmailAnalytics.Label.Sent': 'Enviado',
    'MursionPortal.Dashboard.EmailAnalytics.Label.AllEmails': 'Todos os e-mails',
    'MursionPortal.Dashboard.EmailAnalytics.Label.Trends': 'Tendências',
    'MursionPortal.Dashboard.EmailAnalytics.Label.CategorySummary': 'Resumos de categorias',
    'MursionPortal.Dashboard.EmailAnalytics.Label.RegistrationEmail': 'E-mails de registro',
    'MursionPortal.Dashboard.EmailAnalytics.Label.CallToActionEmail': 'E-mails de chamada para ação',
    'MursionPortal.Dashboard.EmailAnalytics.Label.SchedulingEmail': 'E-mails de agendamento',
    'MursionPortal.Dashboard.EmailAnalytics.Label.ReSchedulingEmail': 'E-mails de reagendamento',
    'MursionPortal.Dashboard.EmailAnalytics.Label.VideoEmail': 'E-mails de vídeo',
    'MursionPortal.Dashboard.EmailAnalytics.Label.EmailSent': 'E-mail enviado',
    'MursionPortal.Dashboard.EmailAnalytics.Info.EmailAnalytics': 'A análise de e-mails fornece uma visão geral de como os alunos estão interagindo com as <link>comunicações por e-mail da Mursion</link>. Os e-mails podem se enquadrar em uma das três categorias a seguir',
    'MursionPortal.Dashboard.EmailAnalytics.Info.EmailOpened': 'Um aluno abriu um ou mais e-mails enviados a ele.',
    'MursionPortal.Dashboard.EmailAnalytics.Info.EmailActedOn': 'Um aluno abriu o e-mail e clicou em um ou mais links, como os links <strong>Agendamento</strong> ou <strong>Participar da simulação</strong>.',
    'MursionPortal.Dashboard.EmailAnalytics.Info.Unread': 'Um aluno não abriu o e-mail. Se houver um grande número de e-mails não lidos, confirme se seus alunos os estão recebendo. Verifique com sua equipe de IT para confirmar se <color>no-reply@mursion.com</color> não está bloqueado pelos filtros de spam de sua organização.',
    'MursionPortal.Dashboard.Tooltip.RegisterEmail': 'E-mails para um usuário convidando-o a criar uma conta Mursion',
    'MursionPortal.Dashboard.Tooltip.CallToActionEmail': 'E-mails de lembrete enviados por um Account Owner da página do projeto ou do cenário',
    'MursionPortal.Dashboard.Tooltip.SchedulingEmail': 'E-mails específicos da sessão. Convite para agendamento e outros e-mails para entrar na sessão',
    'MursionPortal.Dashboard.Tooltip.ReschedulingEmail': 'E-mails solicitando que um aluno reagende após uma simulação perdida ou cancelada',
    'MursionPortal.Dashboard.Tooltip.VideoEmail': 'E-mails alertando um aluno de que um vídeo de sua simulação está disponível para visualização',
    'MursionPortal.Dashboard.EmailCategoryFilter': 'Categoria de e-mail',
    'MursionPortal.Dashboard.EmailCategoryFilter.Search': 'Pesquisar categorias de e-mail...',
    'MursionPortal.Dashboard.EmailTypeFilter.SessionRescheduled': 'Sessão reagendada',
    'MursionPortal.Dashboard.EmailTypeFilter.Search': 'Pesquisar tipos de e-mail...',
    'MursionPortal.Text.NoImageAvailable': 'Nenhuma imagem disponível',
    'Mursion.Portal.Edit.Team.Instruction.ExportFile': 'Clique em <strong>Fazer o download das assinaturas atuais</strong> abaixo para atualizar as atribuições da equipe para os alunos (<strong>edit_learner_team_memberships.xlsx</strong>).',
    'Mursion.Portal.Edit.Team.DownloadExcelTemplate': 'Fazer o download das assinaturas atuais',
    'MursionPortal.Project.SameDay': 'Mesmo dia',
    'MursionPortal.Project.CancelationDeadline.Validation': 'O prazo de cancelamento deve ser maior que 0.',
    'ClientUserTable.AddNewUsers.RolesHeading': 'Você só pode adicionar um tipo de usuário por vez. Selecione o tipo de usuário que deseja adicionar.',
    'ClientUserTable.AddNewUsers.Roles.AccountOwner.Tooltip': 'Um Account Owner é o administrador principal de toda a organização.',
    'ClientUserTable.AddNewUsers.Roles.Facilitator.Tooltip': 'Um Facilitator é um administrador em nível de equipe que gerencia uma ou mais equipes específicas.',
    'ClientUserTable.AddNewUsers.Roles.Learner.Tooltip': 'Um Aprendiz é qualquer pessoa que participa de uma simulação Mursion. Esta será a maior parte da sua base de usuários.',
    'ClientUserTable.AddNewUsers.Heading': 'Adicionar novo(s) usuário(s)',
    'ClientUserTable.AddNewUsers.Roles.Heading': 'Atribuir uma função aos usuários',
    'ClientUserTable.AddNewUsers.ImportUsers': 'Conclua os passos abaixo para até 500 usuários. Se importar mais de 500, use nosso recurso "<code>Importar usuários</code>".',
    'ClientUserTable.AddNewAttributes.label': 'Adicionar atributos de usuário',
    'ClientUserTable.AddNewAttributes.Header.Text': 'Observe que esses atributos serão aplicados a todos os usuários nesta lista.',
    'ClientUserTable.UserAuthentication.label': 'Autenticação de usuário',
    'ClientUserTable.NewUsersAdded.Heading': 'Novo(s) usuário(s) adicionado(s)!',
    'ClientUserTable.AddNewUsers.AssignTeams.Heading': 'Atribuir equipe(s) aos usuários',
    'ClientUserTable.AddNewUsers.AssignTeams.CreateTeam': 'Alunos e Facilitators devem ser atribuídos a uma equipe. Se você ainda não criou uma equipe, <code>clique aqui.</code>',
    'ClientUserTable.AddNewUsers.AssignTeams.SelectTeams': 'Selecione a(s) equipe(s)',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Validation': 'Adicione até 500 usuários. Para importar mais de 500 usuários, você pode usar nosso recurso "Importar usuários".',
    'ClientUserTable.AddNewUsers.AssignTeams.Validation': 'Você deve selecionar pelo menos uma equipe.',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Heading': 'Adicionar endereços de e-mail do usuário',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.SubHeading': 'Você pode adicionar um e-mail por vez ou colar uma lista. Os e-mails devem ser separados por espaços, vírgulas ou ponto e vírgulas.',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Placeholder': 'Digite ou cole os endereços de e-mail aqui.',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.MaximumUsersAllowed': '{maximumUsersAllowed} usuários por vez',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Total': 'Total',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Duplicate': 'Duplicado',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.AlreadyExists': 'Já existe',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Invalid': 'Inválido',
    'ClientUserTable.AddNewUsers.AddNewAttributes.SignLanguageInterpreter': 'Intérprete de linguagem de sinais',
    'ClientUserTable.AssignTeams.TotalUsers': 'Você está prestes a adicionar <code>{users} novo {role}</code>',
    'ClientUserTable.AssignTeams.AddAndUpdateUsers': 'Você está prestes a adicionar <code>{users} novo {role}</code> e atualizar <code>{existingUsers} existente {role}</code>',
    'ClientUserTable.AssignTeams.UpdateUsers': 'Você está prestes a atualizar <code>{existingUsers} {role} existente</code>',
    'ClientUserTable.AssignTeams.NumberOfTeams': 'Será atribuído a <code>{numberOfTeams} equipe(s)</code>',
    'ClientUserTable.Confirmation.Heading': 'Confirme antes de adicionar',
    'MursionPortal.InviteToSchedule.Confirmation.Text': 'Saiba mais aqui:<code>Convidar para agendar</code>',
    'MursionPortal.AccountOwnerGuides.Confirmation.Text': 'Guias do Account Owner',
    'MursionPortal.FacilitatorGuides.Confirmation.Text': 'Guias do Facilitator',
    'MursionPortal.Users.Confirmation.Text': 'Seus usuários foram adicionados ao portal Mursion.',
    'MursionPortal.Confirmation.Header.Text.BackToUsers': 'Voltar aos usuários',
    'MursionPortal.Users.Next.Steps.Text': 'Próximos passos',
    'MursionPortal.Users.StepOne.Text': 'Passo 1:',
    'MursionPortal.Users.StepTwo.Text': 'Passo 2:',
    'MursionPortal.Users.StepThree.Text': 'Passo 3:',
    'MursionPortal.Users.ThatIt.Text': 'Pronto!',
    'MursionPortal.Users.InviteToScheduleLearner.Text': 'Convide seus alunos para agendar',
    'MursionPortal.Users.LearnersLink.Text': 'Você pode enviar este link para seus alunos',
    'MursionPortal.Users.InviteToSchedule.ProjectorScenario.Text': 'Use o botão <strong>Convidar para agendar</strong> em qualquer projeto ou cenário.',
    'MursionPortal.Users.CopyInviteLink.Text': 'Copiar link de convite',
    'MursionPortal.Users.ShareContent.Text': 'Compartilhe o conteúdo de suporte com seus administradores',
    'MursionPortal.Users.AOAndFacilitators.Responsibilities.Text': 'Account Owners e Facilitators têm responsabilidades diferentes e podem precisar aprender mais sobre o portal. Compartilhe esses links com os Account Owners e Facilitators para que eles possam encontrar respostas para quaisquer perguntas que possam ter ao longo de sua jornada Mursion.',
    'MursionPortal.Users.Administrators.Text': 'Seus administradores agora terão acesso ao portal Mursion.',
    'MursionPortal.Users.Progress.Text': 'Seus alunos começarão a agendar suas simulações. Você pode acompanhar o progresso deles em seu painel.',
    'MursionPortal.Users.Access.Text': 'Certifique-se de que seu departamento de IT concedeu a esses usuários acesso ao aplicativo Mursion',
    'MursionPortal.Users.Registrationemail.Text': 'Seus usuários devem ter acabado de receber o e-mail de registro de no-reply@mursion.com.',
    'MursionPortal.AddMoreUsers.Btn.Text': 'Adicionar mais usuários',
    'MursionPortal.Users.Ok.Btn.Text': 'OK, entendi!',
    'Users.Button.AddNewUsers': 'Adicionar novos usuários',
    'MursionPortal.Project.CancelationDeadline.UpperLimit.Validation': 'O prazo de cancelamento não pode ser superior a 365 dias.',
    'MursionPortal.Dashboard.EmailTypeFilter.PasswordReset': 'Redefinição de senha',
    'MursionPortal.Dashboard.EmailTypeFilter.Verification': 'Verificação',
    'MursionPortal.Dashboard.EmailTypeFilter.InviteToSchedule': 'Convite para agendamento',
    'MursionPortal.Dashboard.EmailTypeFilter.Summary': 'Resumo',
    'MursionPortal.Dashboard.EmailTypeFilter.RescheuleMissed': 'Reagendamento perdido',
    'MursionPortal.Dashboard.EmailTypeFilter.RescheuleCancelled': 'Reagendamento cancelado',
    'MursionPortal.Dashboard.EmailTypeFilter.PSRescheule': 'Reagendar por PS',
    'MursionPortal.Dashboard.EmailTypeFilter.VRecordingCorrupted': 'Gravações de vídeo corrompidas',
    'MursionPortal.Dashboard.EmailTypeFilter.VRecordingNotUploaded': 'Gravações de vídeo não enviadas',
    'MursionPortal.Dashboard.EmailTypeFilter.VRecordingAvailable': 'Gravação de vídeo disponível',
    'MursionPortal.Dashboard.EmailTypeFilter.VRecordingShared': 'Gravação de vídeo compartilhada',
    'MursionPortal.Dashboard.EmailTypeFilter.Booking': 'Reserva',
    'MursionPortal.Dashboard.EmailTypeFilter.CodeOfConuct': 'Código de conduta',
    'MursionPortal.Dashboard.EmailTypeFilter.UserRemoved': 'Usuário removido',
    'MursionPortal.Dashboard.EmailTypeFilter.AttendanceReminer': 'Lembrete de presença',
    'Users.Button.AddNewLearners': 'Adicionar novos alunos',
    'MursionPortal.Scenario.Label.LastDelivered': 'Última entrega',
    'MursionPortal.Scenario.Label.TotalDeliveries': 'Entregas totais',
    'MursionPortal.Scenario.Text.NotYetDelivered': 'Ainda não entregue',
    'ClientUserTable.AddNewUsers.UpdateUserWarning': 'Esses {existingUsers} usuários já existem. O procedimento adicionará esses usuários às equipes selecionadas (além de quaisquer equipes já atribuídas).',
    'MursionPortal.AddNewUsers.Facilitators': 'Facilitator(s)',
    'ClientUserTable.AddNewUsers.Learners': 'Aluno(s)',
    'MursionPortal.Scheduling.TimeSlotsUnavailableMessage': 'O(s) horário(s) selecionado(s) não estão mais disponíveis. Atualize para ver os intervalos de tempo possíveis para agendar',
    'MursionPortal.Client.EmailTypeFilter.SessionFirstReminder': 'Primeiro lembrete da sessão',
    'MursionPortal.Client.EmailTypeFilter.SessionSecondReminder': 'Segundo lembrete da sessão',
    'Projects.ProjectInfo.Label.ShowSIMAvailaiblity': 'Mostrar disponibilidade do SIM',
    'Projects.ProjectInfo.Label.ShowAvailaiblity.Tooltip': 'Quando um aluno agendar, ele verá a disponibilidade do SIM Specialist para o intervalo que você selecionar.',
    'Projects.InputValidation.GreaterThanZero': 'Deve ser maior que 0.',
    'MursionPortal.Weeks': 'Semana(s)',
    'MursionPortal.Scenario.CompletionRateFulfilled': 'You cannot schedule this simulation as you have completed the maximum number of simulations for this scenario or engagement as set by your organization.',// TODO add translations
    'MursionPortal.Dashboard.EmailTypeFilter.TimeSlotRequest': 'Solicitação de intervalo de tempo',
    'MursionPortal.Dashboard.EmailAnalytics.OpenAndActed.Label': 'Dados abertos e atuados são exatos a partir de 24 de novembro de 2022.',
    'MursionPortal.Dashboard.EmailAnalytics.Prior.Label': 'Antes desta data, todos os e-mails são categorizados como não lidos.',
    'MursionPortal.Learners.Filters.LearnerCompletion': 'Conclusão do aluno',
    'MursionPortal.Learners.Filters.LearnerCompletion.NotYetCompleted': 'Ainda não está concluído',
    'MursionPortal.Learners.Filters.LearnerCompletion.CompletedOneSession': 'Concluiu 1 sessão',
    'MursionPortal.Learners.Filters.LearnerCompletion.CompletedTwoPlusSessions': '2 ou mais sessões concluídas',
    'MursionPortal.Learners.Filters.MissedLateActivity': 'Atividade perdida/atrasada',
    'MursionPortal.Learners.Filters.MissedLateActivity.1Session': '1 sessão',
    'MursionPortal.Learners.Filters.MissedLateActivity.2Sessions': '2 sessões',
    'MursionPortal.Learners.Filters.MissedLateActivity.3Sessions': '3 sessões',
    'MursionPortal.Learners.Filters.MissedLateActivity.4PlusSessions': 'Mais de 4 sessões',
    'MursionPortal.BackToCompletedSessions': 'Voltar para sessões concluídas',
    'MursionPortal.Scenario.InviteToSchedule.Modal.Text': 'Você gostaria de enviar convites para <strong>todos</strong> os alunos atribuídos que ainda não concluíram este cenário ou prefere <strong>selecionar alunos</strong> para convidar?',
    'MursionPortal.BackToSessionSummaries': 'Voltar para os resumos de sessões',
    'MursionPortal.BackToLearnerSessions': 'Voltar para as sessões do aluno',
    'MursionPortal.LearnerSessionReport.SessionObjective': 'Objetivo da sessão',
    'MursionPortal.LearnerSessionReport.SessionStrategy': 'Quão bem você aplicou as estratégias?',
    'MursionPortal.LearnerSessionReport.SessionStrategyIcon': 'Sessão de Estratégia no Alvo',
    'MursionPortal.LearnerSessionReport.SessionPerspective.Title': 'Continue assim!',
    'MursionPortal.LearnerSessionReport.SessionDetail': 'Descrição do cenário',
    'MursionPortal.LearnerSessionReport.SessionDate': 'Data da sessão',
    'ClientUserTable.AddNewUsers.Procced.Button.Tooltip': 'Remova todos os e-mails duplicados ou inválidos antes de prosseguir.',
    'MursionPortal.BackToSessionDetail': 'Voltar aos detalhes da sessão',
    'MursionPortal.SimAttendance.Instructions.Label': 'Mursion Simulation Specialists:',// TODO add translations
    'MursionPortal.SimAttendance.Instructions.LabelText': 'Select an attendance status for each attendee (mouse over the status to learn more). Your status is gathered automatically – these statuses are for the learner ONLY.',// TODO add translations
    'MursionPortal.SimAttendance.Instructions.Label1': 'Software License Simulation Specialists:',// TODO add translations
    'MursionPortal.SimAttendance.Instructions.LabelText1': 'Select an attendance status for each attendee (mouse over the status to learn more). If you experienced an error in the session, please also provide the error details. To do this, select the type of error under the “SIM Reported Error” section and add context in the Additional Details text box. Additional details about attendance--even if it’s not an Error--are encouraged, but are optional. If you have any further questions about attendance, please reach out to your Simulation Program Admin.',// TODO add translations
    'MursionPortal.Dashboard.EmailAnalytics.Label.EmailAnalytics': 'Análise de e-mails',
    'MursionPortal.Session.LearnerSession.Report': 'Relatório',
    'MursionPortal.Session.LearnerSession.Recording': 'Gravação',
    'MursionPortal.Session.LearnerSession.SessionDetails': 'Detalhes da sessão',
    'MursionPortal.Session.LearnerSessionReport': 'Relatório da sessão do aluno',
    'MursionPortal.Scenario.InviteToScedule.Modal.SelectLearners': 'Selecionar alunos',
    'MursionPortal.Scenario.Button.Label': 'Copiar e-mails dos alunos',
    'MursionPortal.VideoStatusFilter.Label': 'Status do vídeo',
    'MursionPortal.TooltipText.VideoNotUploaded': 'Se este vídeo não for carregado em até 48 horas, entre em contato com o suporte.',
    'MursionPortal.VideoStatusFilter.SearchText': 'Pesquisar status do vídeo...',
    'MursionPortal.VideoStatusFilter.Uploaded': 'Carregado',
    'MursionPortal.VideoStatusFilter.NotUploaded': 'Não carregado',
    'MursionPortal.Scenario.Invite.Modal.Button.Label': 'Convidar',
    'MursionPortal.Scenario.CopyEmails.Modal.Button.Label': 'Copiar e-mails',
    'MursionPortal.Scenario.AllLearners.InvitationsSentOvernight': 'Seus convites serão enviados durante a noite se o aluno ainda não tiver agendado ou recebido um convite nos últimos 2 dias.',
    'MursionPortal.Scenario.SelectedLearners.InvitationsSentOvernight': '<code>Os alunos selecionados</code> receberão um e-mail durante a noite convidando-os a agendar, caso ainda não tenham agendado ou recebido um convite nos últimos 2 dias.',
    'MursionPortal.Scenario.SelectedLearners.Confirm.Text': 'Tem certeza de que deseja convidar <code>todos os alunos selecionados</code>',
    'ClientUserTable.Column.LastInvited': 'Último convite',
    'ClientUserTable.ColumnTooltip.LastInvited': 'A data do último convite por e-mail para agendamento foi enviada. Isso pode ser amanhã, caso tenha sido convidado agora.',
    'MursionPortal.Scenario.SelectedLearners.EmailsCopied': 'Os endereços de e-mail dos alunos selecionados foram copiados para sua área de transferência',
    'MursionPortal.ScenarioInfo.MarkAllInactive': 'Marcar todos como inativos',
    'MursionPortal.ScenarioInfo.DecertifyAll': 'Cancelar a certificação de todos',
    'MursionPortal.ScenarioInfo.MarkAllInactive.Confirmation.Text': 'Tem certeza de que deseja desativar todos os SIM Specialists?',
    'MursionPortal.ScenarioInfo.DecertifyAll.Confirmation.Text': 'Tem certeza de que deseja cancelar a certificação de todos os SIM Specialists?',
    'Dashboard.Button.ViewSession': 'Ver sessão',
    'MursionPortal.SessionReport.NotAvailable': 'Não disponível',
    'Projects.ProjectInfo.Checkbox.EnableReportTabSurvey.OneToOne': 'Ativar a aba de relatório para entregas 1:1',
    'MursionPortal.Learner.Session.Report': 'Relatório da sessão do aluno',
    'MursionPortal.Scenario.Sim.InactivedAll': '{effectedSims} de {totalSims} SIMs inativados.',
    'MursionPortal.Scenario.Sim.DecertifiedAll': '{effectedSims} de {totalSims} SIMs com certificação cancelada.',
    'MursionPortal.Scenario.Sim.DecertifiedFew': '{remainingSims} SIMs não podem ter a certificação cancelada porque têm sessões agendadas. Marque-os como inativos para honrar suas sessões e evitar atribuições de sessões futuras.',
    'MursionPortal.AriaLabel.ViewMore': 'Veja mais',
    'MursionPortal.SimAttendance.SimulationAttendance': 'Presença do aluno em simulações',
    'Mursion.Portal.Status.LearnerTechnicalError': 'Learner Technical Error',// TODO add translations
    'Mursion.Portal.Status.TooLateToComplete': 'Tarde demais para concluir',
    'Mursion.Portal.Status.LearnerElectedToLeave': 'Learner Elected to Leave',// TODO add translations
    'Mursion.Portal.Status.Unprepared.Tooltip': 'O aluno ingressou, mas não concluiu a simulação por despreparo ou por outros motivos.',
    'MursionPortal.Label.SchedulingRate': 'Taxa de agendamento',
    'Mursion.Portal.Status.No.Show': 'Não compareceu',
    'MursionPortal.ScenarioInfo.SchedulingRate.Text': '{código} Vezes',
    'MursionPortal.Scenario.Modal.Search.Placeholder': 'Pesquisar endereço de e-mail, nome, sobrenome',
    'MursionPortal.Scenario.SchedulingRateFulfilled': 'Você deve concluir a sessão agendada primeiro.',
    'MursionPortal.Scenario.InviteToScedule.Modal.Button.Tooltip': 'Selecione o aluno para convidar',
    'MursionPortal.Learner.Session.Report.Report.SimulationRecording': 'Reflita sobre a conversa o reproduzir a gravação de sua simulação',
    'Dashboard.SimulationTable.ColumnTooltip.SoftwareVersion': 'Denota o software de sessão usado',
    'Dashboard.SimulationTable.Column.SoftwareVersion': 'Software',
    'MursionPortal.LearnerSessionReport.YourPerspective': 'Sua perspectiva',
    'MursionPortal.LearnerSessionReport.YourPerspectiveDescription': 'Sua confiança antes da simulação era <strong>{preConfidenceLevel}</strong> e após a simulação se tornou <strong>{postConfidenceLevel}.</strong>',
    'MursionPortal.LearnerSessionReport.YourPerspective.VeryLow': 'muito baixa',
    'MursionPortal.LearnerSessionReport.YourPerspective.Low': 'baixa',
    'MursionPortal.LearnerSessionReport.YourPerspective.Moderate': 'moderada',
    'MursionPortal.LearnerSessionReport.YourPerspective.High': 'alta',
    'MursionPortal.LearnerSessionReport.YourPerspective.VeryHigh': 'muito alta',
    'MursionPortal.LearnerSessionReport.Strategies.SuccessfullyStrategy': 'Você demonstrou esta estratégia <strong>com sucesso</strong>',
    'MursionPortal.LearnerSessionReport.Strategies.BenefitStrategy': 'Você <strong>se beneficiaria praticando mais</strong> esta estratégia',
    'MursionPortal.SchedulingRate.ValidationMessage': 'A taxa de agendamento deve ser menor ou igual à taxa de conclusão',
    'MursionPortal.LearnerSessionReport.Footer.FooterTitle': 'Próximos passos: A prática leva à perfeição!',
    'MursionPortal.LearnerSessionReport.Footer.FooterSubTitle': 'A repetição é a chave para tornar essas habilidades algo natural.',
    'MursionPortal.LearnerSessionReport.SessionObjective.Body': 'Seu relatório estará pronto em breve',
    'MursionPortal.Dashboard.NextOnProject.Footer.schedulingRateFulfilled.Text': 'You cannot schedule this simulation at this time as you have currently reached the scheduling limit for this scenario or engagement set by your organization.',// TODO add translations
    'MursionPortal.Status.Abbreviation.LicenseCancelled': 'C',
    'MursionPortal.Scenario.CharacterLimitExceeded.Message': 'Você excedeu o limite de caracteres em {exceededCharLength} caracteres. Por favor, revisar.',
    'MursionPortal.Scenario.AddedCharacter.Message': '{added} de {totalChar}',
    'MursionPortal.Scenario.CharacterLimit.Message': 'Limite de caracteres: {maxLength}',
    'MursionPortal.Scenario.Description.block.default.text': 'Adicionar descrição aqui',
    'MursionPortal.Scenario.AttachmentField.text': 'Você pode adicionar mais arquivos',
    'MursionPortal.sessionTable.Column.OriginalSim': 'SIM original',
    'MursionPortal.sessionTable.Column.NewSim': 'Novo SIM',
    'MursionPortal.Users.EditUser.InactivatedAll.Message': '{code} O(s) certificado(s) foram desativados.',
    'MursionPortal.Users.EditUser.InactivateAll.Confirmation': 'Tem certeza de que deseja desativar todos os certificados para este especialista SIM?',
    'MursionPortal.Users.Modal.ActiveCerts.EmptyMessage': 'Nenhum certificado ativo',
    'MursionPortal.Users.Modal.InactiveCerts.EmptyMessage': 'Nenhum certificado inativo',
    'MursionPortal.Users.Modal.ActiveCerts': 'Certificados ativos',
    'MursionPortal.Users.Modal.InactiveCerts': 'Certificados inativos',
    'MursionPortal.Users.ActiveCerts.MarkAllInactive.Button': 'Marcar todos como inativos',
    'MursionPortal.SurveyFilter.Incomplete': 'Incompleto',
    'MursionPortal.SurveyFilter.SearchText': 'Buscar pesquisa...',
    'MursionPortal.Dashboard.SurveyReminder.Text': 'Você tem {surveyCount} pesquisas para concluir',
    'MursionPortal.Dashboard.RemindMeLater': 'Lembre-me mais tarde',
    'MursionPortal.Dashboard.SurveysIncomplete': 'Pesquisas incompletas',
    'MursionPortal.AriaLabel.NormalFont': 'Fonte normal',
    'MursionPortal.AriaLabel.MediumFont': 'Fonte média',
    'MursionPortal.AriaLabel.LargeFont': 'Fonte grande',
    'MursionPortal.AriaLabel.Font': 'Fonte',
    'Dashboard.LeftPane.CategoryHeader.RescheduledAndCancelled': 'Remarcado e cancelado',
    'MursionPortal.Dashboard.Summary.Certifications': 'Certificações',
    'MursionPortal.SessionsTable.ActionTitle.Pickup': 'escolher',
    'MursionPortal.Label.MissCancellationRate': 'Taxa de faltas/cancelamentos',
    'MursionPortal.Label.NotAllowed': 'Não permitido',
    'Dashboard.LeftPane.CategoryHeader.Simulations': 'SESSÕES',
    'Dashboard.LeftPane.Simulations.SimulationSummaries': 'Resumos das sessões',
    'Dashboard.LeftPane.Simulations.SimulationDetail': 'Detalhes da sessão',
    'MursionPortal.Message.NotEnoughDataToDisplay': 'Não havia dados suficientes desta sessão.',
    'MursionPortal.Message.Notdata.SessionMatrix': 'Não havia dados suficientes desta sessão para fornecer suas análises.',
    'MursionPortal.Tooltip.SchedulingWindow': 'Os usuários finais podem definir este valor em dias, horas e minutos, após os quais somente o usuário será capaz de agendar uma sessão.',
    'MursionPortal.DemandBasedScheduling.TimeBlockUnavailableSelectDate': 'No momento, não temos nenhum espaço de tempo disponível. Por favor, tente novamente mais tarde. <code1></code1>Contate o <code>suporte</code> se você precisar de ajuda imediata.',
    'Session.Edit.Modal.JoinSession': 'Iniciar sessão',
    'MursionPortal.SimAttendance.Tooltip.Completed': 'O aluno completou a parte de simulação da sessão (não incluindo o debrief no final da sessão).',
    'MursionPortal.SimAttendance.Tooltip.Late': 'O aluno se conectou tarde demais para completar a simulação.',
    'MursionPortal.SimAttendance.Tooltip.Left': 'O aluno desconectou intencionalmente antes de completar a simulação. Isto pode ser devido ao aluno não estar preparado, ou ter saído para uma emergência.',
    'Session.Edit.Modal.JoinSessionBtnTooltip': 'O botão Iniciar sessão seria habilitado antes de <code>{minutesBeforeJoin}</code> minutos para o início da sessão.',
    'Dashboard.Simulations.SimulationSummaries.TableHeader': 'Dados dos resumos das sessões',
    'Dashboard.Learners.LearnerActivity.TableHeader': 'Dados de atividades dos alunos',
    'Dashboard.Learners.LearnerSimulation.TableHeader': 'Dados das sessões do aluno',
    'Dashboard.ContractProgress.Column.Missed': 'O aluno faltou',
    'Dashboard.ContractProgress.Column.TotalRescheduled': 'Total reagendado',
    'Dashboard.ContractProgress.Column.RescheduledMakeup': 'Compensação reagendada',
    'Dashboard.ContractProgress.Column.LateRescheduledSimulations': 'Reagendado(a) tardiamente',
    'Dashboard.ContractProgress.Column.EarlyRescheduledSimulations': 'Reagendado(a) com antecedência',
    'Dashboard.ContractProgress.Column.CompletedUtilization': 'Conclusão da sessão',
    'Dashboard.ContractProgress.Column.ScheduledUtilization': 'Conclusão estimada',
    'Dashboard.ContractProgress.ColumnTooltip.Remaining': 'Sessões compradas menos sessões concluídas.',
    'Dashboard.ContractProgress.ColumnTooltip.TotalRescheduled': 'Simulações totais que estão agendadas',
    'Dashboard.ContractProgress.ColumnTooltip.RescheduledMakeup': 'Total de simulações marcadas como compensação reagendada',
    'Dashboard.ContractProgress.ColumnTooltip.LateRescheduledSimulations': 'simulações que são reagendadas tardiamente.',
    'Dashboard.ContractProgress.ColumnTooltip.EarlyRescheduledSimulations': 'simulações que são reagendadas com antecedência.',
    'Dashboard.Learners.Column.SimulationId': 'Sessão - ID',
    'Dashboard.Learners.Column.SimulationId.CopyHover': 'Copiar ID da sessão',
    'Dashboard.Learners.Column.SSO_ID': 'SSO ID',
    'Dashboard.Learners.Column.Recorded': 'Gravado',
    'Dashboard.Learners.Column.LearnerRecordingConsent': 'Permissão de gravação do aluno',
    'Dashboard.Learners.Column.RecordingRecipients': 'Destinatários da gravação',
    'Dashboard.Learners.Column.RecordingUploaded': 'Gravação carregada',
    'Dashboard.Learners.ColumnTooltip.SSOID': 'Usuários que foram provisionados',
    'Dashboard.Learners.ColumnTooltip.Recorded': 'Indica se a simulação foi gravada ou se não foi autorizada.',
    'Dashboard.Learners.ColumnTooltip.LearnerRecordingConsent': 'Indica se o aluno permitiu que a simulação fosse gravada.',
    'Dashboard.Learners.ColumnTooltip.RecordingRecipients': 'Funções para as quais a gravação da simulação é enviada.',
    'Dashboard.Learners.ColumnTooltip.RecordingUploaded': 'Indica se a gravação da simulação foi carregada.',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.LateReschedule': 'Sessões que foram remarcadas tardiamente pelo aluno.',
    'MursionPortal.Dashboard.Chart.label.SimulationBreakdown.LateReschedule': 'Remarcação tardia',
    'MursionPortal.Dashboard.Chart.Header.LearnerStatus': 'Status do aluno (todos os contratos).',
    'Dashboard.SimulationTable.Column.SimulationStatus': 'Status da sessão.',
    'MursionPortal.Placeholder.ContractValue': 'Selecione um contrato.',
    'MursionPortal.Placeholder.NoContractValue': 'Não há contrato disponível.',
    'Dashboard.Learners.Column.AssignedTeams': 'Equipes designadas.',
    'SessionReports.VideoRecordingDeletedWithSupportCase': 'Infelizmente, este vídeo não pode ser visto devido a um #{supportCase} pendente.',
    'MursionPortal.Dashboard.CompletedSessions': 'Sessões concluídas.',
    'MursionPortal.Dashboard.ViewAll': 'Ver tudo.',
    'MursionPortal.Dashboard.DateOfSimulation': 'Data da sessão.',
    'Dashboard.LeftPane.CategoryHeader.Videos': 'VÍDEOS.',
    'Dashboard.LeftPane.Videos.NotUploaded': 'Gravações não carregadas.',
    'Dashboard.NotUploaded.TableHeader': 'Dados das gravações não carregadas.',
    'Dashboard.VideosTable.Column.SessionId': 'SessionID.',
    'Dashboard.VideosTable.Column.SimspecialistId': 'SIMSpecialistID.',
    'Dashboard.VideosTable.Column.ClientId': 'ClientID.',
    'Dashboard.VideosTable.Column.SessionEnd': 'Fim da sessão.',
    'Dashboard.VideosTable.Column.SoftwareVersion': 'Versão do cenário.',
    'Dashboard.VideosTable.NoVideosError': 'Nenhum vídeo foi encontrado.',
    'Filters.SimSpecialist': 'Nome do SIM.',
    'Filters.SimSpecialistPlaceHolderHint': 'Pesquisar SimSpecialists',
    'MursionPortal.Placeholder.SimSpecialistValue': 'Selecione um SimSpecialist',
    'Users.Table.ErrorOnLoadUserMessage': 'Ocorreu um erro ao carregar o usuário!',
    'Dashboard.ContractProgress.InfoBadge.ContractDates': 'Datas do contrato',
    'Dashboard.ContractProgress.InfoBadge.ContractID': 'Identificação do contrato',
    'Dashboard.Learners.Column.EarlyCancelled': 'Cancelado com antecedência',
    'Dashboard.Learners.Column.LateReschedules': 'Remarcações tardias',
    'Dashboard.Learners.Column.EarlyReschedules': 'Reagendamentos com antecedência',
    'Dashboard.Learners.Column.RescheduledMakeUp': 'Compensação reagendada',
    'Dashboard.Learners.Column.ErrorSim': 'Erro (SIM).',
    'Dashboard.Learners.Column.CancelledOps': 'Cancelado (Ops)',
    'Dashboard.Learners.ColumnTooltip.AssignedProjects': 'Projetos aos quais este aluno está atualmente designado',
    'Dashboard.Learners.ColumnTooltip.AssignedTeams': 'Equipes às quais este aluno está atualmente designado',
    'Dashboard.Learners.ColumnTooltip.AssignedScenarios': 'Cenários aos quais este aluno está atualmente designado',
    'Dashboard.Learners.ColumnTooltip.ScheduledScenarios': 'Cenários que este aluno agendou mas ainda não completou',
    'Dashboard.Learners.ColumnTooltip.CompletedScenarios': 'Cenários que este aluno completou',
    'Dashboard.Learners.ColumnTooltip.EarlyCancelled': 'Simulações que foram canceladas com antecedência (antes do prazo de cancelamento) pelo aluno, facilitator, ou account owner ou simulações',
    'Dashboard.Learners.ColumnTooltip.LateReschedules': 'Simulações que foram reagendadas tardiamente (após o prazo de cancelamento) pelo aluno, facilitator ou account owner',
    'Dashboard.Learners.ColumnTooltip.RescheduledMakeUp': 'Simulações que foram perdidas anteriormente pelo aluno, facilitator, account owner ou simulações',
    'Dashboard.Learners.ColumnTooltip.EarlyReschedules': 'Horas de simulações que foram reagendadas com antecedência (antes do prazo de cancelamento) pelo aluno, facilitator ou account owner',
    'Dashboard.Learners.ColumnTooltip.ErrorSim': 'Simulações que terminaram em erro no lado da conexão SIM',
    'Dashboard.Learners.ColumnTooltip.CancelledOps': 'Simulações que foram canceladas por Mursion.',
    'SessionReports.VideoRecordingPermissionNotGranted': 'Este vídeo não está disponível porque um participante não quis ser gravado.',
    'SessionReports.NoVideoAvailableForSession': 'Não há vídeo para esta sessão.',
    'MursionPortal.NotAvailable': 'N/A',
    'Projects.ProjectInfo.Checkbox.EnablePostSimulationSurvey.OneToOne': 'Habilitar pesquisa de feedback SIM pós-simulação para 1',
    'Projects.ProjectInfo.Checkbox.EnablePostSimulationSurvey.Workshop': 'Habilitar a pesquisa de feedback pós-simulação SIM para entregas de workshops',
    'Projects.ProjectInfo.Checkbox.SurveyEnabled.PostSimulationSurvey.OneToOne': 'Pesquisa de feedback SIM pós-simulação 1',
    'Projects.ProjectInfo.Checkbox.SurveyEnabled.PostSimulationSurvey.Workshop': 'Pesquisa de feedback SIM pós-simulação sobre as entregas do workshop',
    'Clients.Portal.Button.EditMemberships': 'Editar associações',
    'Calendar.ProjectsList.ShowOnlyActiveProjects': 'Mostrar somente projetos ativos',
    'MursionPortal.Status.Undefined': 'Indefinido',
    'MursionPortal.Status.Orphan': 'Órfão',
    'MursionPortal.Status.Reserved': 'Reservado',
    'MursionPortal.Status.Waif': 'Abandonado',
    'MursionPortal.Status.Upcoming': 'Seguintes',
    'MursionPortal.Status.Running': 'Em uso',
    'MursionPortal.Status.NeedsReview': 'Necessita revisão',
    'MursionPortal.Status.Reviewed': 'Revisado(a)',
    'MursionPortal.ErrorMessage.NoDataAvailable': 'Não havia dados suficientes desta sessão para fornecer análises da sessão:',
    'Dashboard.NotUploaded.MainHeader': 'Gravações não carregadas.',
    'MursionPortal.Message.NotEnoughDataToDisplay.ConversationalFlow': 'Não havia dados suficientes desta sessão para criar este gráfico',
    'MursionPortal.Label.SIMSpecialist': 'Sim specialist',
    'Dashboard.SimulationTable.Column.LearnerRecordingConsent': 'Permissão de gravação do aluno',
    'Dashboard.SimulationTable.Column.RecordingRecipients': 'Destinatários da gravação',
    'Dashboard.SimulationTable.Column.RecordingUploaded': 'Gravação carregada',
    'Dashboard.SimulationTable.ColumnTooltip.LearnerRecordingConsent': 'Indica se o aluno deu permissão para que a simulação fosse gravada',
    'Dashboard.SimulationTable.ColumnTooltip.RecordingRecipients': 'Funções para as quais a gravação da simulação é enviada',
    'Dashboard.SimulationTable.ColumnTooltip.RecordingUploaded': 'Indica se a gravação da simulação foi carregada',
    'MursionPortal.ConversationalFlow.label.zoom': 'Zoom',
    'MursionPortal.label.LearnerInterjects': 'Interesses do aluno',
    'MursionPortal.label.AvatarInterjects': 'Avatar interrompe',
    'MursionPortal.label.LearnerInterrupted': 'Aluno interrompido',
    'MursionPortal.label.AvatarInterrupted': 'Avatar interrompido',
    'MursionPortal.label.Silence': 'Silêncio',
    'Clients.Modal.Label.UserRecordingConsent': 'permissão de gravação do usuário',
    'Clients.Modal.Label.UserRecordingConsent.ToolTip': 'Solicitar a permissão do usuário para a gravação',
    'MursionPortal.Notset': 'Não definido',
    'Clients.Modal.Label.UserML3SocialRecordingConsent': 'Permissão de gravação do usuário social Mursion',
    'Clients.Modal.Label.UserML3SocialRecordingConsent.ToolTip': 'Solicitar o consentimento do usuário para ser gravado. Caso não seja ativado, o Sim Specialist pedirá verbalmente.',
    'MursionPortal.LearnerSimSelector.Sim.Available.ToolTip': 'SIMs certificados que não estão reservados para uma sessão e têm disponibilidade para o horário selecionado',
    'MursionPortal.LearnerSimSelector.Sim.Unavailable.ToolTip': 'SIMs certificados que não têm disponibilidade no sistema e não estão reservados para outra sessão no horário selecionado',
    'Settings.Config.Client.VideoRecordingDataRetentionPolicy': 'Mostrar configuração da política de retenção de dados de gravação de vídeo',
    'MursionPortal.DemandBasedScheduling.BackButton.BackToScenario': 'Voltar ao cenário',
    'MursionPortal.DemandBasedScheduling.BackButton.BackToCalendar': 'Voltar ao calendário.',
    'SessionReports.VideoRecordingDeletedDueToDataRetentionPolicy': 'Este vídeo não está disponível devido à política de retenção de dados de sua empresa',
    'SessionReports.VideoRecordingAvailabilityMessage': 'Este vídeo estará disponível para visualização até {videoAvailabilityDate}',
    'SessionReports.SessionOrLearnerStatusError': 'Nenhum vídeo foi gravado para esta sessão devido a um erro técnico',
    'Clients.ImportUsers.NextStepButton': 'Próximo passo.',
    'Clients.ImportUsers.CancelButton': 'Cancelar.',
    'Clients.ImportUsers.UploadValidateButton': 'Carregar + validar.',
    'Session.Table.Column.Attendance': 'Presença.',
    'Session.Table.Column.Value.CompleteAttendance': 'Marcar presença',
    'Session.Table.Column.Value.AttendanceComplete': 'Presença completa.',
    'Session.Table.Column.Value.NotApplicable': '(Não aplicável)',
    'Session.Table.Column.Survey': 'Pesquisa',
    'Session.Table.Column.Value.CompleteGoogleForm': 'Preencher o formulário do Google.',
    'Session.Table.Column.Value.CompleteSurvey': 'Preencher pesquisa.',
    'Session.Table.Column.Value.SurveyComplete': 'Pesquisa completa.',
    'Projects.Modal.Text.LearnerSelected': 'Um mínimo de {maxLearners} aluno precisa ser selecionado',
    'Session.Table.Column.Value.SessionNotCompleted': 'A sessão não está concluída',
    'MursionPortal.Project.InviteToSchedule': 'Convidar para agendar',
    'MursionPortal.Project.InvitedToday': 'Convidado hoje',
    'MursionPortal.Project.YourInvitationsText': 'Seus convites serão enviados de um dia para o outro.',
    'MursionPortal.Project.InviteToSchedule.Tooltip': 'Os convites já estão sendo enviados para o cenário. Você poderá enviar mais convites amanhã.',
    'MursionPortal.Project.InvitedToday.Tooltip': 'Os alunos que não agendaram ou cancelaram/perderam este cenário receberão um convite por e-mail para reagendar.',
    'MursionPortal.Project.InviteMessage.SentDate': 'Enviado pela última vez em {sentDate}',
    'ITManager.TechRestriction.EditDialog.ProviderErrorMsg': 'Por favor, selecione o provedor',
    'ITManager.TechRestriction.EditDialog.GlobalRestriction': 'Restrição global',
    'ITManager.TechRestriction.EditDialog.HolidayRestriction': 'Restrição de férias',
    'ITManager.TechRestriction.Table.Provider': 'Provedores',
    'Session.Table.Column.Survey.HoverText': 'Se os SIMs habilitados receberão um link para completar o feedback sobre o aluno',
    'MursionPortal.Project.InviteMessage.InvitedBy': 'Convidado por {user} - {role}',
    'MursionPortal.Project.LearnersNotYetScheduled': 'Os alunos {learners} ainda precisam agendar',
    'Session.Table.Column.Value.AttendanceRequired': 'Presença necessária',
    'MursionPortal.Button.Schedule.Another': 'Agendar outro',
    'MursionPortal.EulaAgreement.CodeOfConduct': 'Cada simulação do Mursion envolve um único Simulation Specialist ao vivo, que fornece o diálogo falado para até cinco avatares virtuais em uma sessão de aprendizado. O trabalho do Simulation Specialist é apresentar desafios conversacionais para permitir que os alunos, como você, possam praticar as complexas habilidades humanas em cada cenário. No Mursion, reconhecemos a importância de criar um espaço seguro onde as pessoas possam praticar corajosamente conversas potencialmente estressantes, com nuances e complexas. Para garantir que tanto os alunos quanto o Simulation Specialist possam participar com confiança, pedimos aos indivíduos de ambos os grupos que se comprometam com um Código de Conduta básico.',
    'MursionPortal.EulaAgreement.CodeOfConduct.Header': 'Visão geral da metodologia e Código de Conduta',
    'MursionPortal.EulaAgreement.UserAgreement.Header': 'Acordo do usuário',
    'MursionPortal.Import.BackToUser': 'Voltar ao usuário',
    'MursionPortal.Import.BackToTeam': 'Voltar à equipe',
    'Mursion.Portal.Edit.Team.Upload.AccurateUploading': 'Certifique-se de que seus dados estejam corretos antes de fazer o upload.',
    'Mursion.Portal.Edit.Team.Upload.AskedReUpload': 'Se houver algum erro em seu arquivo, ele aparecerá em uma planilha de resultados na etapa 4. Siga as instruções na etapa 4 para corrigir os erros no modelo original e fazer o upload do arquivo novamente.',
    'Mursion.Portal.Edit.Team.Upload.IncludeNewUser': 'Você também pode incluir novos usuários a serem importados junto com os usuários que falharam durante o recarregamento.',
    'Mursion.Portal.Edit.Team.ReadyToUpload': 'Quando seu arquivo estiver pronto, clique em carregar + validar',
    'Mursion.Portal.Edit.Team.UploadAndValidationFile': 'Carregando + validando arquivo',
    'Mursion.Portal.Edit.Team.TeamAssignmentsUpdateSuccessfully': 'Tarefas da equipe atualizadas com sucesso',
    'Mursion.Portal.Edit.Team.UploadSuccessTitle': '{userCount} Usuários importados!',
    'Mursion.Portal.Edit.Team.UploadFailureTitle': 'Erro!',
    'Mursion.Portal.Edit.Team.UploadSuccess': 'Todos os usuários foram adicionados com sucesso ao portal.',
    'Mursion.Portal.Edit.Team.DoneButton': 'Pronto',
    'Mursion.Portal.Edit.Team.DownloadSuccessful': 'Download realizado com sucesso - Siga para o próximo passo',
    'Mursion.Portal.Edit.Team.DownloadError': 'Erro no download - Por favor, tente novamente',
    'Mursion.Portal.Edit.Team.UploadFileAgain': 'Carregar o arquivo novamente',
    'Mursion.Portal.Edit.Team.ContinueWithoutCorrecting': 'Continuar sem corrigir os erros',
    'Mursion.Portal.Edit.Team.SuccessfulImportTitle': 'Importação bem sucedida.',
    'Mursion.Portal.Edit.Team.UnSuccessfulImportTitle': 'A importação falhou',
    'Mursion.Portal.Import.User.HeaderTitle': 'Importar usuários',
    'Mursion.Portal.Import.User.Upload.AskedReUpload': 'Se houver algum erro em seu arquivo, ele será mostrado em uma planilha de resultados na etapa 4. Siga as instruções na etapa 4 para corrigir os erros no modelo <strong>original</strong> e recarregue o arquivo.',
    'Mursion.Portal.Import.User.ReadyToUpload': 'Quando seu arquivo estiver pronto e devidamente formatado, favor carregá-lo para validação',
    'Mursion.Portal.Import.User.ValidatingYourDataUntilTheUpload': 'Estamos validando os dados e importando usuários. Por favor, volte em 15 minutos ou quando você receber um alerta de que este processo está completo.',
    'Mursion.Portal.Import.User.UploadSuccessTitle': '{successCount} Usuários importados!',
    'Mursion.Portal.Import.User.ErrorContinueTitle': 'Continuar sem corrigir erros!',
    'Mursion.Portal.Import.User.ErrorContinue': '{failedCount} users failed to import as the errors were not fixed.', // TODO add translations
    'Settings.ScenarioBank.Form.ArtBundlesWarning': 'Por favor, renomeie o(s) pacote(s) de arte a partir de nomes padrão',
    'Mursion.Portal.Import.User.Instruction.Upload': 'Clique em <strong>baixar modelo do Excel</strong> abaixo e abra o arquivo baixado (<strong>cliente_usuários_internos.xlsx</strong> ou <strong>cliente_usuários_externos.xlsx</strong>).',
    'Mursion.Portal.Import.User.Instruction.Format': '<strong>No modelo, os campos de e-mail, função e SSO ID são obrigatórios - Não deixe em branco. Adicione as informações do usuário corretamente sob cada cabeçalho.</strong> NÃO altere os cabeçalhos, a estrutura do modelo ou o nome do arquivo, pois isso pode resultar em falhas na importação.',
    'Mursion.Portal.Import.User.Instruction.NewUser': 'Adicione nas <strong>colunas J-S ou F-O</strong> os nomes das equipes às quais o usuário deve ser alocado (certifique-se de que sua grafia corresponda exatamente ao nome).',
    'Mursion.Portal.Import.User.Instruction.Error': 'Uma vez inseridos todos os seus usuários, salve o arquivo em seu computador e clique em <strong>prosseguir</strong>.',
    'Mursion.Portal.Import.User.Instruction.OriginalTemplate': 'Se houver algum erro em seu arquivo, ele será mostrado em uma planilha resultante na etapa 4. Siga as instruções para corrigir os erros no modelo <strong>original</strong> e recarregue o arquivo.',
    'Mursion.Portal.Import.User.Instruction.UploadList': 'Se você optar por continuar sem corrigir os erros, o arquivo <strong>Falhas no download da lista de upload</strong> ficará disponível apenas por 7 dias.',
    'Mursion.Portal.Import.User.DownloadExcelTemplate': 'Download do modelo Excel',
    'Mursion.Portal.Import.User.UploadFileStatus': 'Carregando arquivo',
    'Mursion.Portal.Import.User.DoNotCloseUntilUpload': 'Estamos validando os dados e importando usuários. Este processo continuará em segundo plano, para que você possa navegar em outra guia ou janela, e retornar a esta tela a qualquer momento. Este processo de importação deve levar menos de 5 minutos.',
    'Mursion.Portal.Import.User.FileStatus.validation': 'Importando usuários',
    'Mursion.Portal.Import.User.CorrectErrorSubHeading': 'Etapa final: corrigir erros',
    'Mursion.Portal.Import.User.SuccessCount': '{successCount} users were successfully imported; {failedCount} users failed to import – please review.', // TODO add translations
    'Mursion.Portal.Import.User.DownloadFile': 'Click the <strong>Download Errors</strong> button and open the file to view the errors.', // TODO add translations
    'Mursion.Portal.Import.User.FileTabs': '<strong>No modelo, os campos de e-mail e funções são obrigatórios - Não deixe em branco</strong>. NÃO altere os cabeçalhos, a estrutura do modelo ou o nome do arquivo, pois isso pode resultar em falhas na importação.',
    'Mursion.Portal.Import.User.ReviewCorrect': 'Corrija os erros no modelo <strong>original</strong> (<strong>cliente_usuários_internos.xlsx</strong> ou <strong>cliente_usuários_externos.xlsx</strong>) e recarregue o arquivo.',
    'Mursion.Portal.Import.User.DownloadErrorButton': 'Erros de download',
    'Mursion.Portal.Edit.Team.EditTeamTitle': 'Editar os membros da equipe',
    'Mursion.Portal.Edit.Team.Instruction.UpdateFile': 'Adicione as informações do usuário corretamente sob cada cabeçalho. Nas colunas L a U, adicione os nomes das equipes às quais o aluno deve ser alocado (ou seja, equipes a serem adicionadas ou atualizadas), com uma coluna por equipe. Não há problema em deixar colunas de equipe em branco, se necessário. Por favor, certifique-se de que isto seja feito corretamente, uma vez que irá sobrescrever as designações de equipes existentes.',
    'Mursion.Portal.Edit.Team.Instruction.Process': 'Carregue um arquivo por vez - número máximo de 5.000 usuários por arquivo.',
    'Mursion.Portal.Edit.Team.Instruction.ProfileFields': '<strong>Os campos de e-mail e de funções são obrigatórios - Não deixe em branco.</strong> Não altere os cabeçalhos, a estrutura do modelo ou o nome do arquivo, pois isto pode resultar em falhas na importação.',
    'Mursion.Portal.Edit.Team.Instruction.ErrorOccur': 'Os formatos de arquivo suportados são apenas .xlsx.',
    'Mursion.Portal.Edit.Team.DoNotCloseUntilUpload': 'Estamos validando os dados e atualizando as atribuições da equipe. Este processo continuará em segundo plano, para que você possa navegar em outra guia ou janela, e retornar a esta tela a qualquer momento. Este processo deve levar menos de 5 minutos.',
    'Mursion.Portal.Edit.Team.FileStatus.validation': 'Atualizando as associações da equipe',
    'Mursion.Portal.Edit.Team.UploadFailureNotification': 'Seu arquivo contém mais de 5 mil usuários. Favor contatar o suporte ou fazer upload de múltiplos arquivos com menos de 5 mil usuários.',
    'Mursion.Portal.Edit.Team.ReviewCorrect': '<strong>No modelo, os campos de e-mail e funções são obrigatórios - Não deixe em branco.</strong> NÃO altere os cabeçalhos, a estrutura do modelo ou o nome do arquivo, pois isto pode resultar em falhas na importação.',
    'Mursion.Portal.Edit.Team.DownloadFile': 'Corrija os erros no arquivo original do modelo (<strong>edit_learner_team_memberships.xlsx</strong>) -- NÃO altere os cabeçalhos, a estrutura do modelo ou o nome do arquivo, pois isso pode resultar em falhas na importação.',
    'Mursion.Settings.ScenarioBank.Modal.ChecklistURL': 'URL da lista de verificação do SIM',
    'Mursion.Settings.ScenarioBank.Modal.MustBeAValidURL': 'Deve ser uma URL válida',
    'MursionPortal.Label.SpeakingIsZero': 'é 0%',
    'MursionPortal.Label.InterruptionIsZero': 'que é 0%',
    'MursionPortal.CodeOfConduct.SchedulingFlow.Text': 'Ao escolher agendar sua sessão Mursion, você está concordando com o modelo de entrega de simulação e confirmando que irá manter e honrar o Código de Conduta Mursion. Leia mais <code>aqui</code>.',
    'Mursion.Portal.Import.User.InvalidFile': 'Somente o modelo csv ou excel será carregado.',
    'Mursion.Portal.Import.User.MaxFileSize': 'O tamanho do arquivo não deve exceder 5MB',
    'Mursion.Portal.Import.User.Tooltip': 'Clique aqui para importar novos usuários ou atualizar usuários existentes a partir de um arquivo de exportação.',
    'Mursion.Portal.Edit.Team.Tooltip': 'Clique aqui para designar ou reatribuir associações da equipe em bloco.',
    'MursionPortal.Project.InviteLearnersToSchedule': 'Convidar os alunos a agendar',
    'MursionPortal.CheckboxLabel.SchedulingByBookIt': 'Agendamento por BookIt',
    'Dashboard.Report.SendReport': 'Enviar relatório',
    'Dashboard.Report.ScheduleReport': 'Relatório de cronograma',
    'Dashboard.Report.EditSchedule': 'Editar cronograma',
    'Dashboard.Report.ViewSchedule': 'Ver cronograma',
    'Dashboard.Report.DeleteSchedule': 'Excluir cronograma',
    'Dashboard.Report.Filter.Title': 'Filtros selecionados',
    'Dashboard.Report.SelectReportFrequency.Label': 'Selecione a frequência do relatório',
    'Dashboard.Report.SelectSessions.Label': 'Selecione sessões para incluir',
    'Dashboard.Report.SelectReportStartDate.Label': 'Selecione a data de início do relatório',
    'Dashboard.Report.SelectReportStartDate.Tooltip': 'Se você escolheu receber relatórios semanais, seu relatório virá todas as semanas no dia que você selecionou. Se você selecionou mensalmente, ele virá nessa mesma data a cada mês.',
    'Dashboard.Report.SelectAccountOwner.Label': 'Selecione o account owner ou facilitator',
    'Dashboard.Report.Dropdown.Select.Placeholder': 'Selecionar',
    'Dashboard.Report.ManuallyAddRecipients.Label': 'Adicionar destinatários manualmente',
    'Dashboard.Report.ManuallyAddRecipients.Label.Placeholder': 'Adicionar o e-mail aqui',
    'Dashboard.Report.RecipientsBox.Label': 'Destinatários',
    'Dashboard.Report.RecipientsBox.RecipientMaxLimit': 'Os destinatários não podem ser mais de 30',
    'Dashboard.Report.SendReport.ConfirmationModal.Text': 'O relatório foi enviado aos destinatários.',
    'Dashboard.Report.ScheduleReport.ConfirmationModal.HeadingText': 'Seu relatório foi agendado',
    'Dashboard.Report.ScheduleReport.ConfirmationModal.Text': 'Parar ou editar a qualquer momento clicando em <strong>editar agenda.</strong>',
    'Dashboard.Report.EditSchedule.ConfirmationModal.HeadingText': 'As mudanças foram salvas!',
    'Dashboard.Report.EditSchedule.ConfirmationModal.Text': 'Parar relatórios a qualquer momento clicando em <strong>editar agenda.</strong>.',
    'Dashboard.Report.DeleteSchedule.ConfirmationModal.Text': 'O agendamento foi excluído',
    'Dashboard.Report.DeleteSchedule.ConfirmationModal.ConfirmationText': 'Você tem certeza de que quer apagar o agendamento para este relatório?',
    'Dashboard.Report.ConfirmationModal.Button': 'Entendi!',
    'Dashboard.Report.DeleteSchedule.ConfirmationButton': 'Sim, excluir',
    'Dashboard.Report.EditSchedule.EditButton': 'Salvar edições de agendamento',
    'Dashboard.Report.ReportFrequencyOption.Daily': 'DIARIAMENTE',
    'Dashboard.Report.ReportFrequencyOption.Weekly': 'SEMANALMENTE',
    'Dashboard.Report.ReportFrequencyOption.Monthly': 'MENSALMENTE',
    'Dashboard.Report.ReportSessionOption.PreviousDay': 'Sessões de ontem',
    'Dashboard.Report.ReportSessionOption.Previous7Days': 'Sessões dos 7 dias anteriores',
    'Dashboard.Report.ReportSessionOption.CurrentSessions': 'Sessões de hoje',
    'Dashboard.Report.ReportSessionOption.Next7Days': 'Sessões nos próximos 7 dias',
    'Dashboard.Report.ReportSessionOption.MonthToDate': 'Sessões "Mês até hoje"',
    'MursionPortal.Dashboard.UpcomingSession': 'Próxima sessão',
    'Dashboard.Report.ReportSessionOption.PreviousFullMonth': 'Sessões do mês completo anterior.',
    'Dashboard.Report.ReportSessionOption.Previous30Days': 'Sessões dos 30 dias anteriores',
    'Projects.ProjectInfo.CheckBox.ScenarioSequencing': 'Sequenciamento de cenários',
    'Projects.ProjectInfo.CheckBox.ScenarioSequencing.Text': 'Clique no botão para visualizar e configurar a ordem dos cenários neste projeto.',
    'Projects.ProjectInfo.Button.ScenarioSequence': 'Sequência de cenários',
    'MursionPortal.Projects.DragAndDropModal.Text': 'Arrastar e soltar cenários em ordem de preferência',
    'MursionPortal.Projects.DragAndDropModal.DragHandlerImage': 'Arrastar imagem do Handle',
    'MursionPortal.Projects.DragAndDropModal.ModalCloseIcon': 'Fechar ícone',
    'MursionPortal.Project.Scheduled.NextDate': 'Agendado para {nextDate}',
    'MursionPortal.Project.Sequencing': 'Sequenciamento',
    'MursionPortal.Project.Checkbox.Sequencing': 'Habilitar sequenciamento',
    'MursionPortal.Dashboard.Join.Tooltip': 'Quando esta sessão estiver disponível, você poderá participar dela',
    'MursionPortal.Project.InviteToScheduleProject.Tooltip': 'Os alunos que não agendaram ou cancelaram/perderam cenários neste projeto receberão um convite por e-mail para reagendá-los.',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts': 'Atalhos de teclado',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.SpaceBar': 'Tecla da barra de espaço',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.SpaceBar.Text': 'Selecione ou solte o cenário.',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.ArrowKeys': 'Teclas para a direita e esquerda ou para cima e para baixo',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.ArrowKeys.Text': 'Arraste o cenário para a esquerda e para a direita.',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.Esc': 'Tecla Esc.',
    'MursionPortal.Esc': 'Esc',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.Esc.Text': 'Fechar modal de sequência de cenário',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.Enter': 'Pressione a tecla',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.Enter.Text': 'Fechar as dicas de atalho de teclado',
    'MursionPortal.Projects.DragAndDropModal.Tooltip': 'Abrir as dicas de atalho de teclado',
    'Dashboard.Report.DuplicateEntry.ErrorMessage': 'Não é permitida a entrada duplicada',
    'Projects.ProjectInfo.EnableSessionTimeStep': 'Habilitar a etapa de tempo da sessão',
    'Projects.ProjectInfo.SessionTimeStepInfo': 'A etapa de tempo de sessão no nível de licença será usada se o valor da etapa de tempo de sessão não for definido no nível de projeto',
    'Session.Edit.Modal.JoinSimulation': 'Participar da sessão',
    'MursionPortal.Selector.RequestStatus': 'Solicitar status',
    'MursionPortal.Dashboard.RetakeSimulation': 'Retomar simulação',
    'MursionPortal.Dashboard.NextSimulationAssigned': 'Próxima simulação atribuída',
    'MursionPortal.AriaLabel.SelectTime': 'Selecione a hora',
    'Dashboard.Report.Frequency.Date.Select.Placeholder': 'Selecione a data de início',
    'MursionPortal.InstructoScenarioTeamMapping.Header': 'Selecione o cenário e a equipe que você quer que sejma mapeados',
    'MursionPortal.InstructoScenarioTeamMapping.MappingAlreadyExistMessage': 'Este cenário e esta equipe já foram mapeados para um contexto diferente.',
    'MursionPortal.DataSummaryPoints.manualFulFilled': 'Preenchido manualmente',
    'MursionPortal.DataSummaryPoints.pendingRequest': 'Com base na demanda pendente',
    'MursionPortal.DataSummaryPoints.declined': 'Pedidos declinados',
    'MursionPortal.DataSummaryPoints.avgFulfilled': 'Preenchimento médio para manual',
    'MursionPortal.DataSummaryPoints.totalSwap': 'Total de trocas',
    'MursionPortal.DataSummaryPoints.swapAccepted': 'Trocas aceitas',
    'MursionPortal.Dashboard.MissedSimulations': 'Simulações perdidas',
    'MursionPortal.Dashboard.RescheduledSimulations': 'Simulações reagendadas.',
    'MursionPortal.Dashboard.BookedSimulations': 'Simulações agendadas',
    'MursionPortal.Dashboard.CancelledSimulations': 'Simulações canceladas',
    'Dashboard.UserTable.Column.In': 'Em',
    'Projects.ProjectInfo.ConfirmationDialog.ScenarioSequencing.Text': 'Você tem certeza de que quer desativar o sequenciamento? Isso também removerá a numeração sequencial em cada cenário.',
    'Projects.ProjectInfo.Disable.Sequencing': 'Desativar sequenciamento',
    'MursionPortal.ErrorMessage.TechnicalIssueMsg': 'Por favor, tente novamente. Encontramos um problema técnico.',
    'SessionFlow.Text.Event.DeletedByUsername': 'Excluído por {user}',
    'Settings.ScenarioBank.Modal.timestepShouldBeAtLeast': 'Desmarque "habilitar etapa de tempo de sessão" ou insira o valor da etapa de tempo pelo menos {minutes} minutos',
    'MursionPortal.AriaImage.Attribute.Previous': 'Anterior',
    'MursionPortal.AriaImage.Attribute.Notify': 'Notificar',
    'MursionPortal.AriaImage.Attribute.Upload': 'Carregar.',
    'MursionPortal.Report.FilterHeading.SoftwareVersions': 'Versões do software.',
    'MursionPortal.Report.FilterHeading.DeliveryModes': 'Modos de entrega',
    'MursionPortal.Report.FilterHeading.LearnerSessionStatuses': 'Status das sessões de alunos',
    'MursionPortal.Report.FilterHeading.LearnerStatuses': 'Status dos alunos.',
    'MursionPortal.Report.FilterHeading.SimulationStatus': 'Status da simulação.',
    'MursionPortal.Project.DuplicateProjectBtnTooltip': 'Isto duplicaria todos os ajustes e configurações deste projeto para um novo projeto',
    'MursionPortal.Project.DuplicateProjectBtnText': 'Duplicar para Projeto Magic',
    'MursionPortal.ScenarioInfo.Label.Until': 'Você tem até {date} para usar isto até {completionRate} vezes.',
    'Clients.Modal.Button.Schedule.Tooltip.Text': 'Você precisa primeiro programar o cenário anterior',
    'MursionPortal.Scenario.Name': 'Nome do cenário',
    'MursionPortal.ScenarioCard.BackToProjects.Button': 'Voltar para projetos',
    'MursionPortal.ScenarioCard.ViewMore.Button': 'Ver mais',
    'MursionPortal.ScenarioInfo.Documents': 'Documentos de cenário',
    'MursionPortal.ScenarioInfo.Documents.File': '{extension} Arquivo',
    'MursionPortal.ScenarioCard.ViewSessions': 'Ver sessões',
    'MursionPortal.Label.ScenarioPreview': 'Pré-visualização do cenário',
    'MursionPortal.ScenarioInfo.AssignedTeams': 'Pesquisar ou selecionar equipe(s)',
    'MursionPortal.ScenarioInfo.CertifiedSIMSpecialists': 'Sim specialists certificados',
    'MursionPortal.ScenarioInfo.AddSimSpecialist': 'Adicionar Sim Specialist',
    'MursionPortal.ScenarioInfo.CertifiedSIMSpecialist.PencilIcon': 'Editar ícone',
    'MursionPortal.ScenarioInfo.CertifiedSIMSpecialist.DeleteButton': 'Apagar SIM',
    'MursionPortal.ScenarioInfo.CertifiedSIMSpecialist.SearchText': 'Pesquisar ou selecionar o(s) Sim specialist(s)',
    'MursionPortal.ScenarioInfo.CertifiedSIMSpecialist.AddSim': 'Adicionar Sim',
    'MursionPortal.Label.InactiveSIMSpecialists': 'Sim specialists inativos',
    'MursionPortal.Label.Search.InactiveSIMSpecialists': 'Pesquisar ou selecionar Sim specialist(s) inativo(s)',
    'MursionPortal.Project.InviteToSchedule.Sequencing.Tooltip': 'A sequência está definida para este cenário e nenhum aluno programou o cenário anterior ainda.',
    'Clients.Modal.Label.FacilitatorView.Tooltip': 'Se definido para nível de cliente, os facilitators poderão ver os detalhes do nível de account owner. Se definido para nível de equipe, o facilitator só poderá ver informações sobre as equipes para as quais eles estão designados.',
    'Clients.Modal.Label.VoiceAnalytics.Tooltip': 'Proporciona aos alunos uma análise automatizada de sua sessão',
    'Clients.Modal.Label.Show.Utilization.Reporting.Tooltip': 'Adiciona o relatório de utilização ao painel do account owner.',
    'Clients.Modal.Label.VideoRecordingDataRetentionPolicy.Tooltip': 'Habilitar se o cliente tiver uma política de retenção de dados personalizada.',
    'MursionPortal.Label.SoftwareType.Tooltip': '"Apenas web" indica que os alunos só podem acessar suas sessões via Mursion Magic, a plataforma criada para navegador web.',
    'Projects.ProjectInfo.Label.Timeline.Tooltip': 'Todas as sessões para este projeto devem ser concluídas dentro do cronograma do projeto.',
    'Projects.ProjectInfo.Checkbox.AllowSessionRecording.Tooltip': 'Desmarcar para evitar gravações.',
    'MursionPortal.Checkbox.AllowLearnerToRefuseRecording.Tooltip': 'Os alunos podem optar por não gravar. Se não for marcado, e um aluno não permitir a gravação, ele não poderá participar da sessão.',
    'Projects.ProjectInfo.label.Tags.Tooltip': 'Os marcadores são agrupamentos organizacionais para encontrar rapidamente projetos similares no nível do cliente.',
    'Projects.ProjectInfo.label.DemandTime.Tooltip': 'Prazo em que os alunos podem solicitar simulações para as faixas de demanda',
    'Projects.ProjectInfo.label.DemandSlots.Tooltip': 'Define o número de opções de agendamento que um aluno recebe. Se ajustado a zero, o aluno será capaz de reservar instantaneamente um horário livre.',
    'Projects.ProjectInfo.label.Rescheduling.Tooltip': 'Permite que os alunos remarquem as sessões. Note que o aluno não pode remarcar uma sessão para o mesmo dia, a menos que a opção de mesmo dia esteja marcada:',
    'MursionPortal.Project.SameDay.Tooltip': 'Permite que os alunos remarquem para mais tarde, no mesmo dia do calendário.',
    'Cleints.Modal.Label.AvailablePrivateSSOLabel': 'Habilitar SSO privado:',
    'Cleints.Modal.Label.DomainsLabel': 'Domínio Web do cliente:',
    'Clients.Modal.Label.Logo': 'Logotipo do cliente:',
    'Projects.ProjectInfo.Label.CancellationDeadline': 'Prazo de cancelamento',
    'Clients.Modal.Label.MursionSocialAttendance.ToolTip': 'Coletar a presença dos alunos durante as sessões sociais de Mursion Social.',
    'Clients.Modal.Label.VideoRecordingDataRetentionDays': 'Retenção de dados de gravação de vídeo (em dias):',
    'Clients.Modal.Label.VideoRecordingDataRetentionPolicy': 'Política de Retenção de Dados de Gravação de Vídeo:',
    'MursionPortal.PasswordRequirementMessage.SpecialCharaters': 'pelo menos um caractere especial',
    'MursionPortal.ErrorMessage.InvalidCaptcha': 'Captcha inválido. Por favor, tente novamente.',
    'MursionPortal.Project.MissedAlert.TooLateJoin': 'Você chegou um pouco tarde demais para participar',
    'MursionPortal.Project.MissedAlert.DontWorry': 'Não se preocupe! Isso acontece.',
    'MursionPortal.Project.MissedAlert.ClickReschedule': 'Clique para remarcar sua simulação.',
    'MursionPortal.Project.EmailMissedLink.TooLateJoin.ClickRescheduleTxt': 'Clique abaixo para remarcar sua simulação.',
    'MursionPortal.Password.Strength.Tooweak': 'A senha é muito fraca',
    'MursionPortal.Password.Strength.Weak': 'A senha é fraca',
    'MursionPortal.Password.Strength.Medium': 'A senha é média',
    'MursionPortal.Password.Strength.Strong': 'A senha é forte',
    'Dashboard.LeftPane.User.SchedulingInfo':'Informações de agendamento',
    'Dashboard.Button.ExportToEmail':'Exportar dados',
    'Filters.All':'Todos(as)',
    'Filters.More':' mais',
    'Filters.AllExcept':'Todos, exceto',
    'Filters.MoreClients':' mais clientes',
    'Filters.AllClients':'Todos os clientes',
    'Filters.Role':'Função',
    'Filters.TimeZone':'Fuso horário',
    'Dashboard.UserActivity.TableHeader':'Dados de atividade do usuário',
    'MursionPortal.GenerateLearnerSurveyLink':'Copiar link da pesquisa',
    'MursionPortal.SurveyLinkLabel':'O link do seu questionário foi copiado para sua área de transferência',
    'MursionPortal.Attention':'Atenção!',
    'MursionPortal.LinkCannotBeGenerated':'O link não pode ser gerado porque está faltando o campo ID do contrato neste projeto de cenários.',
    'Dashboard.UserTable.Column.UserName':'Nome',
    'Dashboard.UserTable.Column.DateInvited':'Convidado(a)',
    'Dashboard.UserTable.Column.DateRegistered':'Registrado(a)',
    'Dashboard.UserTable.Column.DateArchived':'Arquivado(a)',
    'Dashboard.UserTable.Column.DateLastLogin':'Último login',
    'Dashboard.UserTable.Column.UsedSoftwareVersions':'Software',
    'Dashboard.UserTable.Column.SessionsScheduled':'Simulações agendadas',
    'Dashboard.UserTable.Column.SessionsCompleted':'Simulações completas',
    'Dashboard.UserTable.Column.SessionsMissed':'Simulações perdidas',
    'Dashboard.UserTable.Column.LateCancellations':'Simulações canceladas',
    'Dashboard.UserTable.Column.SessionsError':'Erros nas simulações',
    'Session.Table.Column.ProjectId':'ID do contrato',
    'Session.Edit.Modal.Button.Leave':'Cancelar minha sessão',
    'Users.TableModal.SSOIDPlaceHolder':'ID SSO',
    'Users.Table.Modal.SSOID':'ID SSO',
    'Dashboard.SchedulingInfo.Filter.IndustriesAll':'Indústria',
    'Dashboard.SchedulingInfo.Filter.UsedSoftwareVersion':'Software',
    'Dashboard.SchedulingInfo.MainHeader':'Informações de agendamento',
    'Dashboard.SchedulingInfo.TableHeader':'Dados das informações de agendamento',
    'RestorePassword.ResetPassword':'Redefinir senha',
    'RestorePassword.EmailHint':'digite seu e-mail',
    'Dashboard.ProjectUtilization.MainHeader':'Utilização dos projetos',
    'Settings.Config.ProjectAndScenario.AllowMursionMagic':'Tipo de Mursion Magic',
    'Dashboard.SessionLearners.MainHeader':'Alunos da sessão',
    'MursionPortal.Table.ScrollMessage':'role para a esquerda para ver colunas adicionais',
    'Dashboard.ProjectsActivity.MainHeader':'Atividade do projeto',
    'MursionPortal.Table.ColumnHeader.ProjectId':'ID do contrato',
    'MursionPortal.Button.ClearAll':'Limpar tudo',
    'MursionPortal.Dashboard.Chart.Registration':'Cadastro do usuário',
    'MursionPortal.Dashboard.Chart.InviteAll':'Convidar todos',
    'MursionPortal.Dashboard.Chart.Unregistered':'Não registrado(a)',
    'MursionPortal.Dashboard.Chart.Registered':'Registrado(a)',
    'MursionPortal.Dashboard.Chart.Login':'Login',
    'MursionPortal.Dashboard.Chart.NotLoggedIn':'Não logado(a)',
    'MursionPortal.Dashboard.Chart.LoggedIn':'Logado(a)',
    'MursionPortal.Dashboard.Chart.Scheduling':'Usuários agendados',
    'MursionPortal.Dashboard.Chart.NotScheduled':'Não agendado(a)',
    'MursionPortal.Dashboard.Chart.Simulations':'Simulações',
    'MursionPortal.Dashboard.Chart.LateCancellations':'Cancelamentos com atraso',
    'MursionPortal.Dashboard.Chart.Completed':'Concluído(a)',
    'MursionPortal.Dashboard.Chart.Errors':'Erros',
    'MursionPortal.Dashboard.Chart.Scheduled':'Agendado(a)',
    'MursionPortal.Dashboard.Chart.Tooltip.Registered':'Usuários que foram convidados ou usuários SSO aprovisionados e conectados.',
    'MursionPortal.Dashboard.Chart.Tooltip.Unregistered':'Usuários que foram convidados, mas ainda não fizeram login ou usuários SSO aprovisionados e ainda não fizeram login.',
    'MursionPortal.Dashboard.Chart.Tooltip.NotScheduled':'Usuários SSO registrados/aprovisionados que ainda não agendaram uma simulação',
    'MursionPortal.Dashboard.Chart.Tooltip.Scheduled':'Usuários SSO registrados/aprovisionados que agendaram pelo menos uma simulação',
    'MursionPortal.Label.ProjectId':'ID do contrato',
    'MursionPortal.Label.SoftwareType':'Tipo',
    'MursionPortal.Label.SharingOrDownloading':'Não permitir compartilhamento ou download',
    'Settings.SSO.Modal.EmployeeIDClaimName':'Nome do pedido de ID do funcionário',
    'Settings.SSO.Modal.LocationClaimName':'Nome do pedido do local',
    'Settings.SSO.Modal.DepartmentClaimName':'Nome do pedido do departamento',
    'Settings.SSO.Modal.PhoneClaimName':'Nome do pedido do telefone',
    'Settings.SSO.Modal.TitleClaimName':'Nome do pedido do título',
    'Settings.SSO.Modal.TimezoneClaimName':'Nome do pedido do fuso horário',
    'Settings.SSO.Modal.LanguageClaimName':'Nome do pedido do idioma',
    'MursionPortal.ProjectId':'ID do contrato',
    'MursionPortal.AltText.MursionPortal':'Portal Mursion',
    'MursionPortal.Message.ClickToOpenApplicationDefault':'Clique abaixo para assistir a sua simulação',
    'MursionPortal.Message.ClickToOpenApplicationDesktopWeb':'Clique no botão abaixo para acessar sua simulação através do aplicativo da Mursion para computador',
    'MursionPortal.Message.OpenWebApplication':'Você também pode acessar sua simulação no Mursion Magic, nosso aplicativo para web',
    'MursionPortal.Button.OpenMursionWeb':'Iniciar Mursion Magic',
    'MursionPortal.Message.Or':'OU',
    'MursionPortal.Message.PortalHeadingText':'Simulações de treinamento de EQ',
    'ITManager.TechRestriction.Table.CreateButton':'Adicionar restrição',
    'ITManager.TechRestriction.EditDialog.TagName':'Nome do marcador',
    'ITManager.TechRestriction.EditDialog.Title':'Editar restrição técnica',
    'ITManager.TechRestriction.CreateDialog.Title':'Nova restrição técnica',
    'MursionPortal.DateTimePicker.StartDate':'Data de início',
    'MursionPortal.DateTimePicker.EndDate':'Data de término',
    'MursionPortal.DateTimePicker.TimeNotAvailable':'O horário selecionado não está disponível.',
    'ITManager.TechRestriction.ViewDialog.Title':'Ver restrição técnica',
    'MursionPortal.DateTimePicker.Timezone':'Fuso horário',
    'MursionPortal.Text.Team':'equipe',
    'MursionPortal.Text.Industry':'indústria',
    'MursionPortal.Text.Environment':'ambiente',
    'MursionPortal.Text.Avatar':'avatar',
    'MursionPortal.Status.Capitalized.Upcoming':'EM BREVE',
    'MursionPortal.Status.Capitalized.Undefined':'INDEFINIDO(A)',
    'MursionPortal.Status.Capitalized.Orphan':'ÓRFÃ',
    'MursionPortal.Status.Capitalized.Waif':'ABANDONADO(A)',
    'MursionPortal.Status.Capitalized.Reserved':'RESERVADO(A)',
    'MursionPortal.Status.Capitalized.Booked':'RESERVA CONCLUÍDA',
    'MursionPortal.Status.Capitalized.Pending':'PENDENTE',
    'MursionPortal.Status.Capitalized.Running':'EM EXECUÇÃO',
    'MursionPortal.Status.Capitalized.Missed':'PERDIDO(A)',
    'MursionPortal.Status.Capitalized.NeedsReview':'NECESSITA_REVISÃO',
    'MursionPortal.Status.Capitalized.Reviewed':'REVISADO',
    'MursionPortal.Status.Capitalized.Cancelled': 'CANCELADO',
    'MursionPortal.Status.Capitalized.LicenseCancelled':'CANCELADO',
    'MursionPortal.Status.Capitalized.Error':'ERRO',
    'MursionPortal.Status.Capitalized.SimError': 'ERROR',// TODO add translations
    'MursionPortal.Status.Capitalized.Offline':'OFFLINE',
    'MursionPortal.Status.Abbreviation.PendingNoLearners':'P',
    'MursionPortal.Status.Abbreviation.PendingNoParticipants':'EE',
    'MursionPortal.Status.Abbreviation.Swap':'T',
    'MursionPortal.Status.Abbreviation.Prebooked':'R',
    'MursionPortal.Status.Abbreviation.Booked':'RC',
    'MursionPortal.Status.Abbreviation.Active':'A',
    'MursionPortal.Status.Abbreviation.Completed':'C',
    'MursionPortal.Status.Abbreviation.Missed':'PER',
    'MursionPortal.Status.Abbreviation.NeedsReview':'NR',
    'MursionPortal.Status.Abbreviation.Error':'E',
    'Settings.SSO.Table.NoSsoConfigurationsFound':'Nenhuma configuração SSO foi encontrada.',
    'MursionPortal.Label.SelfReviewTracker':'ACOMPANHAMENTO DE AUTO-AVALIAÇÃO',
    'MursionPortal.ToggleButton.All':'todos(as)',
    'MursionPortal.Label.PerformanceTracker':'MEDIDOR DE DESEMPENHO',
    'MursionPortal.ToggleButton.TurnTaking':' TROCA DE TURNOS',
    'MursionPortal.Tooltip.Collaborative':'Esta é a medida do tempo em que você e o(s) avatar(es) se revezaram sem interromper um ao outro durante a conversa.',
    'MursionPortal.Tooltip.Disruptive':'Esta é a medida do tempo em que você ou o(s) avatar(es) se interromperam durante a conversa.',
    'MursionPortal.Tooltip.Speaking':'Esta é a medida do tempo que você passou falando com o(s) avatar(es) durante a conversa.',
    'MursionPortal.Tooltip.Listening':'Esta é a medida do tempo que você passou ouvindo o(s) avatar(es) durante a conversa.',
    'MursionPortal.Tooltip.MiScore':'Sua eficácia social durante a conversa.',
    'MursionPortal.Tooltip.Percentile':'inclua uma descrição do que isso significa em algum lugar na UI',
    'MursionPortal.Label.MiScore':'Pontuação MI',
    'MursionPortal.Label.Percentile':'percentil',
    'MursionPortal.Label.NotEnoughData':'Sem dados suficientes',
    'MursionPortal.Label.Listening':'Ouvindo',
    'MursionPortal.Label.Speaking':'Falando',
    'MursionPortal.Label.Collaborative':'Colaborativo(a)',
    'MursionPortal.Label.Disruptive':'Disruptivo(a)',
    'MursionPortal.ToggleButton.LocalLeaderboard':'Tabela de classificação local',
    'MursionPortal.ToggleButton.GlobalLeaderboard':'Tabela de classificação global',
    'MursionPortal.ToggleButton.DeepInsights':'Insights detalhados',
    'MursionPortal.ErrorMessage.DataBeingProcessed':'Os dados de análise de sessão estão sendo processados no momento. Os dados serão exibidos na página assim que o processamento for concluído.',
    'MursionPortal.ErrorMessage.UnsupportedFormat':'A análise de sessão está vazia ou tem um formato não suportado.',
    'MursionPortal.Label.Positive':'Positivo(a)',
    'MursionPortal.Label.Negative':'Negativo(a)',
    'MursionPortal.Label.Neutral':'Neutro(a)',
    'MursionPortal.Header.Tracking':'Acompanhamento',
    'MursionPortal.Button.ReadMore':'Ler mais',
    'MursionPortal.Button.Hide':'Ocultar',
    'MursionPortal.Title.Interchange':'Intercâmbio. Conversa natural e troca de turnos sem intervenções ou interjeições',
    'MursionPortal.Title.InterventionByYou':'Intervenção feita por você. Interromper com sucesso alguém que está falando, impedindo-o(a) de continuar',
    'MursionPortal.Title.InterventionByAvatars':'Intervenção por Avatares. Interromper com sucesso alguém que está falando, impedindo-o(a) de continuar',
    'MursionPortal.Title.InterjectionByYou':'Interjeição feita por você. Tentativa de interromper alguém que estava falando sem sucesso',
    'MursionPortal.Title.InterjectionByAvatars':'Interjeição por Avatares. Tentativa de interromper alguém que está falando sem sucesso',
    'MursionPortal.Title.Pauses':'Pausa. Mais de 0,2s e menos de 0,75s',
    'MursionPortal.Label.VoiceSelf':'Voz própria',
    'MursionPortal.Label.VoiceOthers':'Voz aos outros',
    'MursionPortal.Label.Avatars':'Avatares',
    'MursionPortal.Label.You':'Você',
    'MursionPortal.ColumnHeader.User':'Usuário',
    'MursionPortal.ColumnHeader.Percentile':'Percentil',
    'MursionPortal.ColumnHeader.ScenariosCompleted':'Cenários concluídos',
    'MursionPortal.Message.LeaderboardsUpdated':'As tabelas de classificação serão atualizadas à medida que mais pessoas completarem suas simulações.',
    'MursionPortal.Message.CheckBack':'Por favor, volte ocasionalmente para ver onde você está!',
    'MursionPortal.Label.SimStart':'Sim inicial',
    'MursionPortal.Label.SimEnd':'Sim final',
    'MursionPortal.Tooltip.Summary.Speaking':'Tempo gasto falando com o(s) avatar(es) durante toda a conversa',
    'MursionPortal.Tooltip.Summary.Listening':'Tempo gasto ouvindo o(s) avatar(es) durante toda a conversa',
    'MursionPortal.Capitalized.NotAvailable':'N/A',
    'MursionPortal.ColumnHeader.Trait':'Característica',
    'MursionPortal.ColumnHeader.Me':'Eu',
    'MursionPortal.ColumnHeader.OthersAvg':'Outros (média)',
    'MursionPortal.TooltipText.Speaking':'Você fala mais do que {percentage} das pessoas.',
    'MursionPortal.TooltipText.Listening':'Você ouve mais do que {percentage} das pessoas.',
    'MursionPortal.TooltipText.Collaborative':'Você é mais colaborativo(a) do que {percentage} das pessoas.',
    'MursionPortal.TooltipText.Disruptive':'Você é mais disruptivo(a) do que {percentage} das pessoas.',
    'MursionPortal.Label.FeedbackValue':'Valor de feedback',
    'MursionPortal.Button.AnalyticsData':'Dados analíticos',
    'MursionPortal.Button.RawAnalyticsData':'Dados brutos de análise',
    'MursionPortal.Header.SocialEffectiveness':'Eficácia social',
    'MursionPortal.Label.Current':'Atual',
    'MursionPortal.Header.ArticulationRate':'Taxa de articulação',
    'MursionPortal.Label.Average':'Média',
    'MursionPortal.Header.Conversation':'Conversação',
    'MursionPortal.Header.SpeakingListening':'Falar/ouvir',
    'MursionPortal.Header.CumulativeTurnTaking':'Troca de turnos cumulativa',
    'MursionPortal.Header.InstantaneousTurnTaking':'Troca de turnos instantânea',
    'MursionPortal.ColumnHeader.ScenarioTemplate':'SCENARIO_TEMPLATE',
    'MursionPortal.ColumnHeader.Provider':'FORNECEDOR',
    'MursionPortal.Label.AbbreviatedMinute':'m',
    'MursionPortal.Header.Mursion':'<code>M</code>ursion',
    'MursionPortal.Header.Index':'<code>I</code>ndex',
    'MursionPortal.Header.Score':'Pontuação',
    'MursionPortal.visibilityHidden.Selected':'selecionado(a)',
    'MursionPortal.AriaLabel.CloseTechSupport':'suporte técnico próximo',
    'MursionPortal.Monitoring':'Monitorando',
    'MursionPortal.Monitoring.User.FirstLetter':'U',
    'MursionPortal.Monitoring.Peer.Label':'PEER-PEER',
    'MursionPortal.Monitoring.Peer.NoUser':'Por favor, selecione o usuário para ver os detalhes',
    'MursionPortal.Monitoring.Sessions.Total':'Total de sessões',
    'MursionPortal.Monitoring.Sessions.Displayed':'Sessões ML3 (exibidas)',
    'MursionPortal.Monitoring.Sessions.NotDisplayed':'Não são sessões ML3',
    'MursionPortal.Monitoring.Card.StartTime':'Hora de início',
    'MursionPortal.Monitoring.Notification.NewSession':'Uma nova sessão foi adicionada.',
    'MursionPortal.ClickRefresh':'Clique em "atualizar" para ver as alterações.',
    'MursionPortal.AriaLabel.DraftScenarioSuperScript':'Rascunho do sobrescrito do cenário {statusConfiguration}',
    'MursionPortal.AriaLabel.FinalizedScenarioSuperScript':'Sobrescrito do cenário terminado {statusConfiguration}',
    'MursionPortal.AriaLabel.StartedScenarioSuperScript':'Sobrescrito do cenário iniciado {statusConfiguration}',
    'MursionPortal.AriaLabel.FinishedScenarioSuperScript':'Sobrescrito do cenário pronto {statusConfiguration}',
    'MursionPortal.AriaLabel.ArchivedScenarioSuperScript':'Sobrescrito do cenário arquivado {statusConfiguration}',
    'Calendar.Button.ScheduleEventON':'Agendar evento em {thisDate}',
    'Calendar.Button.CantScheduleON':'Não é possível agendar o evento em {thisDate}',
    'MursionPortal.AriaLabel.ChooseFileButton':'botão escolher arquivo',
    'MursionPortal.Label.VisibilityHidden.FileUploaded':'Arquivo carregado',
    'MursionPortal.VisibilityHidden.EntityAdded':'Entidade adicionada',
    'MursionPortal.VisibilityHidden.EntityRemoved':'Entidade removida',
    'MursionPortal.Legend.VisibilityHidden.StationTypeHomeOffice':'Tipo de local (escritório, casa)',
    'MursionPortal.Legend.VisibilityHidden.HoursFullTimePartTime':'Horas (período integral, parcial)',
    'MursionPortal.AriaLabel.ShowTechSupport':'mostrar suporte técnico',
    'MursionPortal.Monitoring.Card.UntilTheEnd':'Tempo restante',
    'MursionPortal.Monitoring.Card.OverTime':'Horas extras',
    'MursionPortal.Title.DemandBasedSessionScheduling':'Agendamento de sessão por demanda',
    'MursionPortal.CheckboxLabel.SchedulingByDemand':'Agendamento por demanda',
    'MursionPortal.CheckboxLabel.ProjectTimezone':'Fuso horário do projeto',
    'MursionPortal.CheckboxLabel.DemandTime':'Tempo da demanda',
    'MursionPortal.Label.DemandTimeStart':'Início do tempo da demanda',
    'MursionPortal.Label.DemandTimeEnd':'Fim do tempo da demanda',
    'MursionPortal.Label.DemandWindow':'Janela da demanda',
    'MursionPortal.Project.Days':'Dia(s)',
    'MursionPortal.MyProfile.Hover.Profile':'perfil {userName}',
    'Mursion.Portal.SessionUserStatus.Connected':'CONECTADO(A)',
    'Mursion.Portal.SessionUserStatus.Late':'ATRASADO(A)',
    'Mursion.Portal.SessionUserStatus.Immersing':'IMERSÃO',
    'Mursion.Portal.SessionUserStatus.Left':'SAIU',
    'Mursion.Portal.SessionUserStatus.Declined':'RECUSADO' ,
    'MursionPortal.DemandBasedScheduling.SubmitPage.ThankYou':'Obrigado!',
    'MursionPortal.DemandBasedScheduling.SubmitPage.Message':'Estamos trabalhando para finalizar sua simulação com base nos horários que você forneceu. Aguarde um e-mail nosso com mais detalhes. Nós nos vemos em breve!',
    'MursionPortal.DemandBasedScheduling.SubmitPage.GoToDashboard':'Ir para o meu painel',
    'MursionPortal.DemandBasedScheduling.ScheduleASession':'Agendar uma sessão',
    'MursionPortal.DemandBasedScheduling.Details':'DETALHES',
    'MursionPortal.DemandBasedScheduling.Slot':'SLOT {count}',
    'MursionPortal.DemandBasedScheduling.PickDateAndTime':'Escolha data + hora para definir',
    'MursionPortal.DemandBasedScheduling.Button.Set':'DEFINIR',
    'MursionPortal.DemandBasedScheduling.Button.Clear':'LIMPAR',
    'MursionPortal.DemandBasedScheduling.Jumbotron.Text':'Os detalhes do cenário serão carregados assim que você fizer uma seleção',
    'MursionPortal.AriaDescribedby.StartDate':'Data de início: pressione a tecla do ponto de interrogação para obter os atalhos de teclado para alterar as datas.',
    'MursionPortal.AriaDescribedby.EndDate':'Data de término: pressione a tecla do ponto de interrogação para obter os atalhos de teclado para alterar as datas.',
    'MursionPortal.VisibilityHidden.Footer':'Rodapé',
    'MursionPortal.VisibilityHidden.Header':'Cabeçalho',
    'MursionPortal.AriaLabel.FullScreen':'tela cheia',
    'MursionPortal.AriaLabel.ClientNameEventsDisplayed':'Eventos do cliente {clientName} exibidos no calendário',
    'MursionPortal.AriaLabel.SideNavigationToggle':'Alternar navegação lateral',
    'MursionPortal.AriaLabel.AddTags':'Adicionar marcadores',
    'MursionPortal.VisibilityHidden.Navigation':'Navegação',
    'MursionPortal.AriaLabel.SortByDescendingOrderButton':'ordenar pelo botão de ordem decrescente',
    'MursionPortal.AriaLabel.SortByAscendingOrderButton':'ordenar pelo botão de ordem crescente',
    'MursionPortal.VisibilityHidden.ProjectsTabSelected':'Aba de projetos selecionada',
    'MursionPortal.VisibilityHidden.TeamsTabSelected':'Aba de equipes selecionada',
    'MursionPortal.VisibilityHidden.SessionsTabSelected':'Aba de sessões selecionada',
    'MursionPortal.VisibilityHidden.UsersTabSelected':'Aba de usuários selecionada',
    'MursionPortal.Error.PageNotFound':'Erro 404: página não encontrada',
    'MursionPortal.Text.PageNotFound':'Página não encontrada. Caso o link esteja correto, verifique se você tem permissão para visualizar a página.',
    'MursionPortal.Text.Success':'Sucesso!',
    'MursionPortal.Text.YouAreNowSignedIn':'Agora você está conectado(a) ao software de simulação Mursion.',
    'MursionPortal.Text.PleaseReturnToDesktop':'Por favor, retorne ao aplicativo da área de trabalho clicando em {linebreak} no ícone \'M\' na barra de tarefas do seu computador (Windows) ou Dock (Mac).',
    'MursionPortal.Client.EmailSettings.Header.DemandBased':'Baseados em demanda',
    'MursionPortal.Client.EmailSettings.TimeSlotsReceived':'Slots de horário recebidos',
    'MursionPortal.Label.Past':'Anteriores',
    'MursionPortal.Monitoring.SessionsSummary.Preparation':'Preparation',
    'MursionPortal.Monitoring.SessionsSummary.Normal':'Normal',
    'MursionPortal.Monitoring.SessionsSummary.Error':'Erro',
    'MursionPortal.Monitoring.SessionsSummary.Issue':'Questão',
    'MursionPortal.Monitoring.SessionsSummary.NotLoggedIn':'Não logado',
    'MursionPortal.Monitoring.Legend.Label':'Lenda',
    'MursionPortal.Monitoring.Legend.Dashboard':'Painel',
    'MursionPortal.Label.SimSpecialist':'Especialista em Sim',
    'MursionPortal.Monitoring.SessionsSummary.ConnectedToPeers':'Conectado aos pares',
    'Requests.Table.CertifiedScenarios':'Cenários certificados',
    'Requests.Table.UncertifiedScenarios':'Cenários não certificados',
    'Requests.Table.NoUpComingDemands':'Você não tem solicitações baseadas em demanda com os critérios acima.',
    'Requests.Table.DeclineReason':'Recusar - selecione o motivo',
    'Requests.Table.UnAvailable':'Indisponível',
    'Requests.Table.NotPrepared':'Não preparado(a)',
    'Requests.Table.Other':'Outro',
    'Requests.Table.Availability':'Selecionar disponibilidade',
    'Requests.Table.Declined':'Recusado' ,
    'Requests.Table.SuccessfullySent':'Pedido enviado com sucesso',
    'Requests.Table.RequestCertificate':'Solicitar certificado',
    'Requests.Table.DeclineReasonText':'Razão da recusa',
    'Requests.Table.AvailabilitySlot':'Slot de disponibilidade',
    'Requests.Table.DemandBased':'Por demanda',
    'MursionPortal.Setting.Integrations':'Integração',
    'MursionPortal.Setting.Integrations.Title':'Por favor, selecione uma integração',
    'MursionPortal.Setting.Integrations.Header.PlatformConfigurations':'Configurações da plataforma',
    'MursionPortal.Button.AddConfigurations':'Adicionar configurações',
    'Settings.LTI.PlaceHolder.EnterConfigName':'Inserir o nome da configuração',
    'Settings.LTI.Table.ColumnHeader.DeploymentID':'ID de implantação',
    'Settings.LTI.Table.ColumnHeader.Version':'Versão',
    'Settings.LTI.Table.Caption':'lista de configuração de LTI',
    'Settings.LTI.Table.Hover.ViewEditLti':'Ver/editar LTI',
    'Settings.LTI.Table.NoLtiConfigFound':'Nenhuma configuração de LTI encontrada',
    'MursionPortal.EditLtiModal.Button.DeleteLti':'Excluir configuração',
    'MursionPortal.EditLtiModal.Button.SaveLtiConfig':'Salvar configuração',
    'MursionPortal.Lti.NewLTIConfig':'Nova configuração de LTI',
    'MursionPortal.Lti.CreateLTIConfig':'Criar configuração de LTI',
    'Settings.LTI.Modal.PlatformIssuerIdentifier':'Identificador do emissor da plataforma',
    'Settings.LTI.Modal.PublicKeysetEndpoint':'Fim do conjunto de chaves públicas',
    'Settings.LTI.Modal.DeploymentId':'ID de implantação',
    'Settings.LTI.Modal.RolesMapping':'Mapeamento de funções',
    'Settings.LTI.Modal.RolesMapping.PortalRole':'Função do portal',
    'Settings.LTI.Modal.RolesMapping.LTIRole':'Função LTI',
    'MursionPortal.ErrorMessage.LtiIdMustBeSpecified':'O ID de LTI deve ser especificado.',
    'MursionPortal.Setting.Integrations.LTIVersion':'Versão de LTI',
    'MursionPortal.Dialog.CreateLTIConfiguration.SuccessMessage':'A configuração de LTI foi criada com sucesso.',
    'MursionPortal.DemandBasedScheduling.TimeBlockUnavailableReserved':'Bloco de tempo indisponível - reservado para outra simulação agendada.',
    'Requests.Table.ViewAvailability':'Ver disponibilidade',
    'Settings.LTI.Modal.DeletionConfirmationText':'Tem certeza de que deseja excluir este mapeamento de função?',
    'Settings.ScenarioBank.Modal.InactiveCertification':'Certificação inativa',
    'Settings.ScenarioBank.Modal.NoInactiveSimSpecialist':'Nenhum especialista em Sim inativo',
    'Settings.ScenarioBank.Modal.EditInactiveCertification':'Editar certificação inativa',
    'Settings.ScenarioBank.Modal.NoInactiveCertification':'Sem certificação inativa',
    'MursionPortal.RequestStatus.All':'Status da solicitação: todos',
    'MursionPortal.RequestStatus.Accepted':'Status da solicitação: aceita',
    'MursionPortal.RequestStatus.Declined':'Status da solicitação: recusada',
    'MursionPortal.RequestStatus.Pending':'Status da solicitação: pendente',
    'Requests.Table.RequestCertification':'Solicitar certificação',
    'Requests.Table.RequestTime':'Solicitar horário',
    'Requests.Table.ScheduleType':'Tipo de agenda',
    'Requests.Table.AcceptTime':'Aceitar horário',
    'Requests.Table.Scheduling':'Agendamento',
    'Requests.Table.FulfilledIn':'Preenchido(a) em',
    'MursionPortal.DemandBasedScheduling.Timezone':'Fuso horário',
    'MursionPortal.DemandBasedScheduling.Timezone.Tooltip':'Vá para as configurações do seu perfil para alterar o fuso horário.',
    'MursionPortal.Results.Found':'{count} resultados encontrados',
    'MursionPortal.Header.VisibilityHidden.ClientUsers':'Usuários do cliente',
    'MursonPortal.SchedulingType.Auto':'AUTO',
    'MursonPortal.SchedulingType.Manual':'MANUAL',
    'MursionPortal.ScheduleTypeSelector.All':'Agendar: todos',
    'MursionPortal.ScheduleTypeSelector.Auto':'Agendar: auto',
    'MursionPortal.ScheduleTypeSelector.Manual':'Agendar: manual',
    'MursionPortal.ScheduleTypeSelector.NA':'Agendar: N/A',
    'Integrations.LTI.AppId':'ID do app',
    'Integrations.LTI.ConfigurationId':'ID de configuração',
    'Settings.LTI.ModalHeader.EditLtiConfiguration':'Editar configuração de LTI',
    'Settings.LTI.Modal.DeletionConfigConfirmationText':'Você tem certeza de que deseja excluir esta configuração de LTI?',
    'MursionPortal.Rescheduling.SubmitPage.Message':'Em alguns minutos, enviaremos um e-mail com mais detalhes. Nós nos vemos em breve!',
    'MursionPortal.Rescheduling.RescheduleSimulation':'Reagendar simulação',
    'MursionPortal.Rescheduling.SlotSelectionMessage':'Lamentamos que você não possa fazer sua simulação. Escolha um dia e horário que funcionem para você!',
    'MursionPortal.IVSBrowserWarning.Safari17.Message1': 'The browser version you are currently using is not supported for your Mursion session',  // TODO: add translations
    'MursionPortal.IVSBrowserWarning.Safari17.Message2': 'When joining your Mursion simulation, use one of these browsers:',  // TODO: add translations
    'MursionPortal.IVSBrowserWarning.Safari17.Firefox': "Firefox",  // TODO: add translations
    'MursionPortal.IVSBrowserWarning.Safari17.Chrome': "Chrome", // TODO: add translations
    'MursionPortal.IVSBrowserWarning.Safari17.Chrome.Subtitle': "Recommended", // TODO: add translations
    'MursionPortal.IVSBrowserWarning.Safari17.Edge': "Edge", // TODO: add translations
    'MursionPortal.Result.Found':'{count} resultado encontrado',
    'MursionPortal.Project.Rescheduling':'Reagendando',
    'Session.Edit.Modal.MissedSession':'SESSÃO PERDIDA',
    'Session.Edit.Modal.NoLongerRequired':'não é mais necessário',
    'Session.Edit.Modal.TechDifficulties':'com dificuldades técnicas',
    'Session.Edit.Modal.Other':'outro(a)',
    'Session.Edit.Modal.CancelBecause':'Sim, cancele a sessão porque',
    'Session.Edit.Modal.CancelSimulation':'Cancelar simulação',
    'MursionPortal.UserConsent.Heading':'Permissões de gravação',
    'MursionPortal.UserConsent.Title':'Você quer uma gravação desta sessão de simulação para sua própria jornada de aprendizado?',
    'MursionPortal.UserConsent.Description':'Se você disser "eu concordo", o software Mursion processará sua simulação ao vivo, e fará uma gravação em vídeo de sua sessão de simulação para sua revisão no portal do software Mursion. Observe que, a pedido do cliente que licenciou o software Mursion em seu nome, uma cópia de sua(s) sessão(ões) de simulação gravada(s) será compartilhada com um coach, mentor ou revisor selecionado pelo cliente. Todos os revisores são contratualmente obrigados a seguir a <code>política de privacidade da Mursion</code>, a menos que você tenha concordado com termos de privacidade diferentes. Exceto conforme exigido pela lei aplicável, a Mursion não distribuirá ou divulgará sua gravação de vídeo sem sua permissão. A Mursion usará dados anônimos de seu uso de seu software para melhorar sua tecnologia de simulação.',
    'MursionPortal.UserConsent.IConsent':'Eu concordo',
    'MursionPortal.UserConsent.IDoNotConsent':'Eu não concordo',
    'MursionPortal.RecordingLinkPermisssion.Title':'Permissões de gravação',
    'MursionPortal.RecordingLinkPermisssion.DescriptionOne':'Quer receber uma gravação desta sessão?',
    'MursionPortal.RecordingLinkPermisssion.DescriptionTwo':'Em caso afirmativo, verifique sua caixa de entrada de e-mail e verifique seu endereço de e-mail. Você receberá um link amanhã, se disponível.',
    'MursionPortal.PublicSession.PageTitle':'Insira seus dados para participar de uma simulação',
    'MursionPortal.PublicSession.Form.label.FirstName':'Primeiro nome',
    'MursionPortal.PublicSession.Form.label.LastName':'Sobrenome',
    'MursionPortal.PublicSession.Form.label.Email':'E-mail (opcional)',
    'PublicSession.Form.Button.Join':'Participar',
    'MursionPortal.PublicSession.Form.Placeholder.FirstName':'Seu primeiro nome',
    'MursionPortal.PublicSession.Form.Placeholder.LastName':'Seu sobrenome',
    'MursionPortal.PublicSession.Form.Placeholder.Email':'name@provider.com',
    'MursionPortal.PublicSession.Form.Placeholder.HaveAccount':'Já tem uma conta?',
    'MursionPortal.PublicSession.Form.Placeholder.LogInHere':'Faça o login aqui',
    'Session.Edit.Modal.ToolTip.Google':'Adicione um evento ao seu calendário do Google. Confirme a autorização, se necessário',
    'Session.Edit.Modal.ToolTip.OutlookIcalOther':'Clique para baixar o evento para sua área de trabalho e adicioná-lo ao seu aplicativo de calendário',
    'Session.Edit.Modal.SimSpecialistAssigned':'Especialista em Sim atribuído',
    'Session.Edit.Modal.SessionSimSpecialistField.AddSIMSpecialist':'Adicionar especialista em SIM',
    'Session.Edit.Modal.DemandBasedTimeSlots':'Slots de horário por demanda',
    'Session.Edit.Modal.Reschedule':'Reagendar',
    'Session.Edit.Modal.AddLearner':'Adicionar aluno',
    'MursionPortal.Label.SearchOrSelectLearners':'Pesquise ou selecione o(s) aluno(s)',
    'MursionPortal.Client.EmailSettings.Header.Rescheduling':'Reagendando',
    'MursionPortal.Client.EmailSettings.RescheduleMissedSession':'Reagendar sessão perdida',
    'Session.Edit.Modal.OtherPrioritiesAtWork':'outras prioridades no trabalho',
    'MursionPortal.Modal.Header.Sorry':'Desculpe!',
    'MursionPortal.Modal.UnschedulableSessionMsg':'A sessão não pode ser agendada.',
    'MursionPortal.Rescheduling.Slot.NoLongerAvailable':'O horário selecionado não está mais disponível. Por favor, selecione outra opção.',
    'MursionPortal.EmailVerificationPage.Success':'Sucesso na verificação de e-mail!',
    'MursionPortal.EmailVerificationPage.SuccessDesc':'Seu e-mail foi verificado com sucesso',
    'MursionPortal.EmailVerificationPage.Error':'Falha na verificação de e-mail!',
    'MursionPortal.EmailVerificationPage.ErrorDesc':'Algo deu errado. Por favor, tente novamente para verificar seu e-mail',
    'Settings.Config.Heading.SplashPageAttendance':'PARTICIPAÇÃO',
    // 'Clients.Modal.Label.ShowSplashPage':'Participação/permissão de gravação de usuário não registrado',
    // 'Clients.Modal.Label.ShowSplashPage.ToolTip':'Obtenha a participação e permissão para a gravação para usuários não registrados em simulações do ML3z/Meet.',
    // 'Settings.Config.Invitation.ShowSplashPage':'Obtenha a participação e permissão para a gravação para usuários não registrados em simulações do ML3z/Meet.',
    'Settings.Config.External.Users':'(Pode ser desabilitado na configuração do cliente)',
    'Settings.Config.Invitation.ExternalUser':'Para participação de usuários externos',
    'MursionPortal.Scenario.Vignette.ButtonText.ShowMore.SessionDetails':'detalhes da sessão',
    'MursionPortal.DemandBasedScheduling.ProjectDropdown.Placeholder':'Selecionar um projeto',
    'MursionPortal.DemandBasedScheduling.ScenarioDropdown.Placeholder':'Selecionar um cenário',
    'Session.Edit.Modal.Label.SearchOrSelectASimSpecialist':'Pesquise ou selecione um especialista em SIM',
    'MursionPortal.Button.Exclamation.GotIt':'Entendi!',
    'MursionPortal.Modal.UnavailableSlotMsg':'A hora selecionada não está disponível. Por favor, escolha um novo horário.',
    'MursionPortal.SimAttendance.NoLongerAvailable':'Você não pode mais editar a participação para esta simulação.',
    'MursionPortal.SimAttendance.Midnight':'Você deve concluir a participação até a meia-noite de cada dia. A participação para esta simulação será padronizada para os status do sistema. Entre em contato com o suporte se algo anormal ocorrer.',
    'MursionPortal.SimAttendance.Instructions':'Instruções',
    'MursionPortal.SimAttendance.Attendee':'Participante',
    'MursionPortal.SimAttendance.Tooltip.Missed':'O aluno nunca se conectou à simulação.',
    'MursionPortal.SimAttendance.Tooltip.Declined':'O aluno se conectou à simulação, mas recusou-se a participar e saiu antes de completar pelo menos 50% da simulação.',
    'MursionPortal.SimAttendance.Add.Attendee':'Adicionar participante',
    'Session.Edit.Modal.EditAttendance':'Editar participação',
    'Session.Edit.Modal.CompleteAttendance':'Concluir participação',
    'MursionPortal.SimAttendance.Late':'Atrasado(a)',
    'MursionPortal.SimAttendance.Left':'Saiu',
    'MursionPortal.Instructions.First':'Selecione um status de participação para cada participante (passe o mouse sobre o status para saber mais). Se um aluno não estiver listado, clique em ',
    'MursionPortal.Instructions.Second':'função no canto inferior esquerdo.',
    'MursionPortal.TableColumn.SimsAction':'Ação do Sim',
    'Session.Edit.Modal.Label.SelectTrainer':'Pesquise ou selecione um treinador',
    'MursionPortal.Project.DemandSlots':'Slots solicitados',
    'MursionPortal.CompanyCard.InviteOperations':'Convidar operações',
    'MursionPortal.CompanyCard.Modal.Header.NewOperations':'Novas operações',
    'Settings.Config.Invitation.ShowContractFeature':'Permitir que contratos sejam adicionados',
    'Settings.Config.Contract.Description':'(para acompanhar o andamento do contrato)',
    'MursionPortal.Contract.Edit':'Editar',
    'MursionPortal.Contract.ContractName':'Nome do contrato',
    'MursionPortal.Contract.ContractID':'ID do contrato',
    'MursionPortal.Contract.ContractID.PlaceHolder':'Insira o ID do contrato do Hubspot',
    'MursionPortal.Contract.StartAndEndDates':'Datas de início e término do contrato',
    'MursionPortal.Contract.Notes.PlaceHolder':'Insira outras informações a serem exibidas apenas para CSMs e BDDs.',
    'MursionPortal.Contract.LineItem.Add':'Adicionar um item de linha do contrato',
    'MursionPortal.Contract.LineItem.Add.Instruction':'Você pode adicionar vários itens de linha de simulação. Todos os outros tipos podem ter apenas um item de linha.',
    'MursionPortal.Contract.LineItemType':'Tipo de item de linha',
    'MursionPortal.Contract.LineItemType.PlaceHolder':'Selecionar tipo de item de linha',
    'MursionPortal.Contract.Quantity':'Quantidade',
    'MursionPortal.Contract.Quantity.PlaceHolder':'Inserir quantidade',
    'MursionPortal.Contract.DeliveryMode.PlaceHolder':'Selecionar modo de entrega',
    'MursionPortal.Contract.SaveContract':'Salvar detalhes do contrato',
    'MursionPortal.Contract.Length.PlaceHolder':'Selecione o comprimento',
    'MursionPortal.Contract.SowName.PlaceHolder':'Inserir nome do contrato',
    'MursionPortal.Client.Tab.Contracts':'Contratos',
    'MursionPortal.Client.Contracts':'Contratos do cliente',
    'MursionPortal.Client.Create.Contracts':'Criar contrato',
    'MursionPortal.Client.Contract.ID':'ID do contrato',
    'MursionPortal.NewUser.Optional':' (opcional)',
    'MursionPortal.NewUser.SuchAGoogleAccountOrOtherSsoProvider.TooltipText':'Como uma conta do Google ou outro provedor de SSO',
    'MursionPortal.UserConsentDeclineConfirmation.Heading':'Por favor, confirme sua escolha',
    // 'MursionPortal.UserConsentDeclineConfirmation.Title':'Você tem certeza? Para continuar sem gravar, clique no botão confirmar. Para alterar sua permissão de gravação, clique no botão cancelar.',
    'Settings.Config.Invitation.ShowSowFeature':'Permitir que SOWs sejam adicionados',
    'Settings.Config.SOW.Description':'(para acompanhar o progresso SOW)',
    'MursionPortal.ScenarioBank.StandardizedScenario':'Cenário padronizado',
    'MursionPortal.ScenarioBank.ShowOnlyBundleScenario':'Mostrar apenas cenários padronizados',
    'Settings.Config.Invitation.SimWorkforceManagement':'Gerenciamento da força de trabalho do SIM',
    'Settings.Config.SimWorkforceManagement.Description':'Ativado significa que o relatório está disponível na página do painel, e desativado significa que o relatório não está lá.',
    'MursionPortal.ScenarioBank.BundleScenario':'Pacotes de cenários',
    'MursionPortal.Scheduling.SimAvailable.TimeSlotsTooltipMsg':'Selecione este horário para confirmar automaticamente sua reserva de cenário!',
    'Dashboard.LeftPane.CategoryHeader.SupplyManagement':'Gerenciamento da força de trabalho do SIM',
    'Dashboard.LeftPane.SupplyManagement.Scheduling':'Agendamento',
    'Dashboard.SupplyManagement.Scheduling.MainHeader':'Agendamento',
    'Dashboard.SupplyManagement.Scheduling.TableHeader':'Dados de agendamento',
    'Dashboard.LeftPane.SupplyManagement.ClientRoster':'Lista de clientes',
    'Dashboard.SupplyManagement.ClientRoster.MainHeader':'Lista de clientes',
    'Dashboard.SupplyManagement.ClientRoster.TableHeader':'Dados da lista de clientes',
    'MursionPortal.Table.ColumnHeader.Time':'Hora',
    'MursionPortal.Table.ColumnHeader.AllScheduledSessions':'Todas agendadas (sessões)',
    'MursionPortal.Table.ColumnHeader.SimsAvailable':'SIMs disponíveis (pessoas)',
    'MursionPortal.Table.ColumnHeader.AvailabilityHours':'Disponibilidade (horas)',
    'MursionPortal.Table.ColumnHeader.DemandBasedRequests':'Todas as solicitações por demanda (total - todas as 3 solicitações)',
    'MursionPortal.Table.ColumnHeader.SlotOneRequests':'Solicitações do slot 1',
    'MursionPortal.Table.ColumnHeader.SlotTwoRequests':'Solicitações do slot 2',
    'MursionPortal.Table.ColumnHeader.SlotThreeRequests':'Solicitações do slot 3',
    'MursionPortal.Table.ColumnHeader.PendingRequests':'Solicitações pendentes (sessões)',
    'MursionPortal.Table.ColumnHeader.Auto':'Auto',
    'MursionPortal.Table.ColumnHeader.Manual':'Manual',
    'MursionPortal.Table.ColumnHeader.CapacityIndicator':'Indicador de capacidade',
    'MursionPortal.EmptyMessage.NoSupplyManagementDataYet':'Ainda não há dados de gerenciamento de força de trabalho SIM',
    'MursionPortal.Table.ColumnHeader.SimCertified':'Certificado SIM',
    'MursionPortal.Table.ColumnHeader.ScheduledHours':'Agendadas (horas)',
    'MursionPortal.Table.ColumnHeader.Sessions':'Sessões',
    'MursionPortal.Table.ColumnHeader.AvgFullfilled':'Média de preenchidos',
    'MursionPortal.Table.ColumnHeader.SlotOneFullFillment':'Preenchimento do slot 1',
    'MursionPortal.Table.ColumnHeader.Cancelled':'Cancelada(s)',
    'MursionPortal.Table.ColumnHeader.Missed':'Perdida(s)',
    'MursionPortal.Table.ColumnHeader.Reschedules':'Reagendamentos',
    'MursionPortal.Table.ColumnHeader.Error':'Erro',
    'MursionPortal.Table.ColumnHeader.Completed':'Concluída(s)',
    'MursionPortal.Table.ColumnHeader.Other':'Outro(a)',
    'MursionPortal.Table.ColumnHeader.SchedulingWindow':'Janela de agendamento',
    'MursionPortal.Table.ColumnHeader.RequestDemand':'# de solicitação (demanda)',
    'MursionPortal.Table.ColumnHeader.AutoFulfilled':'Preenchido automaticamente',
    'MursionPortal.Table.ColumnHeader.Pending':'Pendente',
    'MursionPortal.Table.ColumnHeader.SlotTwoFullFillment':'Preenchimento do slot 2',
    'MursionPortal.Table.ColumnHeader.SlotThreeFullFillment':'Preenchimento do slot 3',
    'MursionPortal.Table.ColumnHeader.UnutilizedAvailability':'Disponibilidade não utilizada (horas)',
    'MursionPortal.Table.ColumnHeader.TotalAvailabilityEntered':'Disponibilidade total inserida',
    'MursionPortal.Table.ColumnHeader.AverageHoursWeek':'Média de horas/semana',
    'MursionPortal.Table.ColumnHeader.AvailabilityUtilization':'Uso de disponibilidade',
    'MursionPortal.SupplyManagement.Above':'Acima',
    'MursionPortal.SupplyManagement.Below':'Abaixo',
    'MursionPortal.SupplyManagement.At':'Em',
    'MyProfile.ProfServiceRole.Tooltiptext':'Usuários com nosso nível de acesso mais alto',
    'MyProfile.AccManager.Tooltiptext':'Cria cenários, equipes, adiciona usuários e atribui equipes a simulações',
    'MyProfile.SimSpecialist.Tooltiptext':'Também conhecido como \'Sim\', esse papel realiza simulações para os alunos',
    'MyProfile.Buyer.Tooltiptext':'Um usuário que gerencia um projeto Mursion para sua organização',
    'MyProfile.Facilitator.Tooltiptext':'Participante da simulação que auxilia na entrega da simulação',
    'MyProfile.Learner.Tooltiptext':'Participante da simulação',
    'MyProfile.Operations.Tooltiptext':'Funcionário da Mursion que tem a capacidade de adicionar e editar contratos',
    'MyProfile.SimDesigner.Tooltiptext':'Usuário que tem a capacidade de criar e editar cenários bancários',
    'MursionPortal.Label.AssignedUserRoles':'Funções de usuário atribuídas',
    'MursionPortal.Label.AssignedUserRole':'Função de usuário atribuída',
    'Clients.Modal.Projects.Button.CreateFromBank':'Criado a partir do banco',
    'MursionPortal.Legend.VisibilityHidden.ScenarioPanel.Buttons':'Criar novo, repetir cliente, criar a partir dos botões de cenário bancário',
    'MursionPortal.Label.ArtBundle':'Pacote de arte',
    'Settings.ScenarioBank.Modal.ToolTip.ThisIsTheArtContentMsg':'Este é o conteúdo de arte 3D dos ambientes e avatares que serão utilizados na simulação. O conteúdo desta pasta é exibido na seção intitulada "pacote de arte" abaixo.',
    'MursionPortal.SessionWizard.Label.ScenarioHasNoArtBundles':'O cenário não tem pacotes de arte atribuídos.',
    'MursionPortal.Label.ArtProject':'PROJETO DE ARTE',
    'MursionPortal.Label.UpperCase.ArtProject':'PROJETO DE ARTE',
    'Settings.Config.SimulationContent.ArtProjectFolder':'Arquivo do projeto de arte S3',
    'Settings.ScenarioBank.Modal.ThisScenarioHasNoArtProject':'Este cenário não tem um projeto de arte atribuído',
    'MursionPortal.SessionWizard.Label.SelectArtContentForUse':'Selecionar projeto de arte para uso',
    'Projects.Message.NoArtProjectFound':'Nenhum projeto de arte encontrado',
    'Settings.ScenarioBank.Form.ConfirmArchiveScenarioBank':'Existem cenários secundários ativos criados a partir deste cenário bancário. Você deve arquivar cada um deles individualmente.',
    'Settings.StandardizedScenarioBank.EditConfirmation':'Tem certeza de que deseja atualizar todos os cenários existentes criados a partir deste?',
    'Settings.StandardizedScenarioBank.AttachmentsEditConfirmation':'A atualização de anexos mudará todos os cenários existentes criados a partir deste.',
    'MursionPortal.Contract.Ending':'Final',
    'MursionPortal.ProjectContracts.DuplicateError':'O contrato que você selecionou se sobrepõe a outro contrato adicionado a este projeto. Selecione outra operação ou entre em contato para solicitar um pedido de alteração',
    'MursionPortal.ProjectContracts.DateErrorHeading':'Nenhum contrato existe para essas datas de projeto',
    'MursionPortal.ProjectContracts.DateError':'Não há contrato para justificar essas datas de início e término do projeto. Nenhum projeto deve ser criado sem um contrato válido. Você quer prosseguir?',
    'MursionPortal.Contract.ViewDetails':'Ver detalhes',
    'MursionPortal.Contract.PleaseAddContractToProject':'Por favor, adicione o contrato ao projeto',
    'MursionPortal.Contract.AddContractToProject':'Adicionar contrato ao projeto',
    'MursionPortal.Contract.SelectContractToProject':'Selecione o contrato que deseja adicionar a este projeto - adicione um por vez',
    'MursionPortal.Contract.AddSelectedContract':'Adicionar contrato selecionado',
    'MursionPortal.Contract.AddContract':'Adicionar contrato',
    'MursionPortal.Contract.LineItemList':'Lista de itens de linha',
    'MursionPortal.DemandBasedScheduling.InstantBooking':'Reserva instantânea',
    'MursionPortal.DemandBasedScheduling.AllSlots':'Enviar slots',
    'MursionPortal.DemandBasedScheduling.ScheduleYourScenario':'Agendaremos seu cenário em um dos horários escolhidos.',
    'MursionPortal.DemandBasedScheduling.SelectOneOfTheseSlotsForAnInstantBooking':'Selecione um desses slots para uma reserva instantânea.',
    'MursionPortal.DemandBasedScheduling.TheseSlotDoNotwork':'Se esses slots não funcionarem, selecione "enviar slots" para enviar horários que funcionem para você.',
    'MursionPortal.AltText.CrossButton':'imagem do botão cruz',
    'MursionPortal.Title.BankScenario':'Cenário bancário',
    'Dashboard.LeftPane.SupplyManagement.SimRoster':'Lista de Sims',
    'Dashboard.SupplyManagement.SimRoster.MainHeader':'Lista de Sims',
    'Dashboard.SupplyManagement.SimRoster.TableHeader':'Dados da lista de Sims',
    'MursionPortal.Table.ColumnHeader.SimFullName':'Nome completo do SIM',
    'MursionPortal.Table.ColumnHeader.LanguagesDelivered':'Idiomas entregues (certificado no idioma)',
    'MursionPortal.Table.ColumnHeader.Tier':'Nível',
    'MursionPortal.Table.ColumnHeader.Timezone':'Fuso horário',
    'MursionPortal.Table.ColumnHeader.Scheduled':'Agendada(s)',
    'MursionPortal.Table.ColumnHeader.ProjectsHoursWeekTotal':'Total de horas/semana projetado',
    'MursionPortal.Table.ColumnHeader.ActiveClientCertifications':'Certificações de cliente ativo (faturável)',
    'MursionPortal.Table.ColumnHeader.InactiveCertifications':'Certificação inativa',
    'MursionPortal.Table.ColumnHeader.Declined':'Recusada(s)' ,
    'MursionPortal.Table.ColumnHeader.AvgFullfillTimeForManual':'Tempo médio de preenchimento para manual',
    'MursionPortal.Table.ColumnHeader.Queue':'Fila',
    'MursionPortal.Table.ColumnHeader.Availability':'Disponibilidade',
    'MursionPortal.Filters.Tier':'Nível',
    'MursionPortal.Filters.PlaceHolder.SearchTier':'Buscar nível',
    'MursionPortal.TierOptions.Tier1':'Nível 1',
    'MursionPortal.TierOptions.Tier2':'Nível 2',
    'MursionPortal.TierOptions.Tier3':'Nível 3',
    'MursionPortal.TierOptions.Tier4':'Nível 4',
    'MursionPortal.TierOptions.LeadSimulation':'Especialista em simulação líder',
    'MursionPortal.Contract.BackToCLient':'Voltar ao cliente',
    'MursionPortal.DeliveryMode.Workshop':'Workshop',
    'MursionPortal.DeliveryMode.PrivatePractice':'Prática particular',
    'MursionPortal.DemandBasedScheduling.SimOnlySlotSelectionMessage':'Por favor, escolha um dia e horário que funcione para você.',
    'Dashboard.LeftPane.CategoryHeader.Performance':'UTILIZAÇÃO',
    'Dashboard.LeftPane.Performance.ContractOverview':'Visão geral',
    'Dashboard.LeftPane.Performance.ContractSummaries':'Resumos do contrato',
    'Dashboard.LeftPane.Performance.ContractProgress':'Progresso do contrato',
    'Dashboard.LeftPane.CategoryHeader.Learners':'ALUNOS',
    'Dashboard.LeftPane.Learners.LearnerActivity':'Atividade do aluno',
    'Dashboard.LeftPane.Learners.LearnerSimulations':'Simulações do aluno',
    'Dashboard.Total':'Total de entradas',
    'Filters.SimulationStatus':'Status da simulação',
    'Filters.SimulationStatusPlaceholder':'Buscar status da simulação...',
    'Filters.LearnerStatus':'Status do aluno',
    'Filters.LearnerStatusPlaceHolderHint':'Buscar status do aluno...',
    'Filters.SimulationRecorded':'Simulação gravada',
    'Filters.Standardized':'Padronizado(a)',
    'Filters.Boolean.Yes':'Sim',
    'Filters.Boolean.No':'Não',
    'Dashboard.UserTable.Column.Roles':'Funções',
    'Dashboard.ContractSummaries.MainHeader':'Resumos do contrato',
    'Dashboard.ContractOverview.MainHeader':'Visão geral',
    'Dashboard.ContractProgress.MainHeader':'Progresso do contrato',
    'Dashboard.ContractProgress.Navigation.ContractItems':'Itens do contrato',
    'Dashboard.ContractProgress.Navigation.Projects':'Projetos',
    'Dashboard.ContractProgress.Navigation.Scenarios':'Cenários',
    'Dashboard.ContractProgress.Column.Item':'Item',
    'Dashboard.ContractProgress.Column.Purchased':'Comprada(s)',
    'Dashboard.ContractProgress.Column.Remaining':'Restante(s)',
    'Dashboard.ContractProgress.Column.Completed':'Concluída(s)',
    'Dashboard.ContractProgress.Column.Scheduled':'Agendada(s)',
    'Dashboard.ContractProgress.Column.LateCancellations':'Cancelamentos com atraso',
    'Dashboard.ContractProgress.Column.ProjectName':'Nome do projeto',
    'Dashboard.ContractProgress.Column.StartDate':'Data de início',
    'Dashboard.ContractProgress.Column.EndDate':'Data de término',
    'Dashboard.ContractProgress.Column.Error':'Erro',
    'Dashboard.ContractProgress.Column.ScenarioName':'Nome do cenário',
    'Dashboard.ContractProgress.Column.Standardized':'Padronizado(a)',
    'Dashboard.ContractProgress.Column.LearnersAssigned':'Alunos atribuídos',
    'Dashboard.ContractProgress.Column.LearnersCompleted':'Alunos concluídos',
    'Dashboard.ContractProgress.Column.LearnersCompletedPercent':'% de alunos concluídos',
    'Dashboard.ContractProgress.Column.Client':'Cliente',
    'Dashboard.ContractProgress.Column.ContractName':'Nome do contrato',
    'Dashboard.ContractProgress.Column.ContractId':'ID do contrato',
    'Dashboard.ContractProgress.Column.ContractNameHoverTitle':'Copiar ID',
    // 'Dashboard.ContractProgress.Column.PercentComplete':'% concluído(a)',
    'Dashboard.ContractProgress.Column.ErrorLearner':'Erro (aluno)',
    'Dashboard.ContractProgress.Column.LearnersInvited':'Alunos convidados',
    'Dashboard.ContractProgress.Column.LearnersScheduledPercent':'% de alunos agendados',
    'Dashboard.ContractProgress.ColumnTooltip.Client':'Nome do cliente',
    'Dashboard.ContractProgress.ColumnTooltip.ContractName':'Nome do contrato',
    'Dashboard.ContractProgress.ColumnTooltip.PercentComplete':'Porcentagem de dias concluídos do contrato',
    'Dashboard.ContractProgress.ColumnTooltip.CompletedUtilization':'Simulações concluídas como porcentagem das simulações compradas',
    'Dashboard.ContractProgress.ColumnTooltip.ScheduledUtilization':'Simulações concluídas e agendadas como porcentagem das simulações compradas',
    'Dashboard.ContractProgress.ColumnTooltip.MissedLearner':'Simulações que foram perdidas pelo aluno',
    'Dashboard.ContractProgress.ColumnTooltip.ErrorLearner':'Simulações que terminaram com erro de conexão por parte do aluno',
    'Dashboard.ContractProgress.ColumnTooltip.LearnersInvited':'Alunos que foram convidados a agendar uma simulação',
    'Dashboard.ContractProgress.ColumnTooltip.LearnersScheduledPercent':'Alunos que agendaram pelo menos uma simulação como uma porcentagem daqueles que foram convidados a agendar uma simulação',
    'Dashboard.ContractProgress.ColumnTooltip.Item':'Itens contratados no contrato',
    'Dashboard.ContractProgress.ColumnTooltip.Purchased':'Número total de simulações compradas como parte de um contrato',
    'Dashboard.ContractProgress.ColumnTooltip.Completed':'Simulações concluídas com sucesso',
    'Dashboard.ContractProgress.ColumnTooltip.Scheduled':'Próximas simulações que estão agendadas, mas ainda não foram concluídas (inclui as que estão em execução)',
    'Dashboard.ContractProgress.ColumnTooltip.Missed':'Simulações que foram perdidas pelo aluno',
    'Dashboard.ContractProgress.ColumnTooltip.LateCancellations':'Simulações que foram canceladas com atraso (após o prazo de cancelamento) pelo aluno, facilitador ou proprietário da conta, ou simulações em que o aluno saiu ou recusou',
    'Dashboard.ContractProgress.ColumnTooltip.Error':'Simulações que terminaram com erro de conexão por parte do aluno',
    'Dashboard.ContractProgress.ColumnTooltip.ProjectName':'Nome do projeto',
    'Dashboard.ContractProgress.ColumnTooltip.StartDate':'Data de início do contrato conforme indicado no contrato.',
    'Dashboard.ContractProgress.ColumnTooltip.EndDate':'Data de término do contrato conforme indicado no contrato.',
    'Dashboard.ContractProgress.ColumnTooltip.ScenarioName':'Nome do cenário',
    'Dashboard.ContractProgress.ColumnTooltip.Standardized':'Este é um cenário padronizado',
    'Dashboard.ContractProgress.ColumnTooltip.LearnersAssigned':'Número de alunos que receberam um cenário',
    'Dashboard.ContractProgress.ColumnTooltip.LearnersCompleted':'Alunos que completaram pelo menos uma simulação como porcentagem daqueles que foram convidados a agendar uma simulação',
    'Dashboard.ContractProgress.ColumnTooltip.LearnersCompletedPercent':'Porcentagem de alunos distintos que completaram o cenário',
    'Dashboard.Learners.Column.Provider':'Fornecedor',
    'Dashboard.Learners.Column.Client':'Cliente',
    'Dashboard.Learners.Column.LearnerName':'Nome do aluno',
    'Dashboard.Learners.Column.DateInvited':'Data do convite',
    'Dashboard.Learners.Column.LastLogin':'Último login',
    'Dashboard.Learners.Column.NoAssignedTeams':'Nenhuma equipe atribuída',
    'Dashboard.Learners.Column.NoAssignedScenarios':'Não há cenários atribuídos',
    'Dashboard.Learners.Column.NoCompletedScenarios':'Não há cenários concluídos',
    'Dashboard.Learners.Column.Completed':'Concluído(a)',
    'Dashboard.Learners.Column.Scheduled':'Agendado(a)',
    'Dashboard.Learners.Column.LateCancelled':'Cancelado(a) com atraso',
    'Dashboard.Learners.Column.EndedInError':'Terminado em erro',
    'Dashboard.Learners.Column.ProjectName':'Nome do projeto',
    'Dashboard.Learners.Column.ScenarioName':'Nome do cenário',
    'Dashboard.Learners.Column.ScheduledStartDate':'Data de início agendada',
    'Dashboard.Learners.Column.ScheduledStartTime':'Horário de início agendado',
    'Dashboard.Learners.Column.ScheduledEndTime':'Horário de término agendado',
    'Dashboard.Learners.Column.SimulationStatus':'Status da simulação',
    'Dashboard.Learners.Column.LearnerStatus':'Status do aluno',
    'Dashboard.Learners.Column.LearnerTimeSpent':'Tempo gasto pelo aluno',
    'Dashboard.Learners.Column.SimSpecialistName':'Nome do especialista em Sim',
    'Dashboard.Learners.Column.SimSpecialistStatus':'Status do especialista em Sim',
    'Dashboard.Learners.Column.Report':'Relatório',
    'Dashboard.Learners.ColumnTooltip.Provider':'Nome do fornecedor/licenciado',
    'Dashboard.Learners.ColumnTooltip.Client':'Nome do cliente',
    'Dashboard.Learners.ColumnTooltip.LearnerName':'Sobrenome e nome do usuário',
    'Dashboard.Learners.ColumnTooltip.DateInvited':'Data em que um aluno foi convidado pela primeira vez a agendar um cenário',
    'Dashboard.Learners.ColumnTooltip.LastLogin':'Data em que um usuário fez login pela última vez no portal',
    'Dashboard.Learners.ColumnTooltip.NoAssignedTeams':'Número de equipes que foram atribuídas a um aluno',
    'Dashboard.Learners.ColumnTooltip.NoAssignedScenarios':'Número de cenários distintos que foram atribuídos a um aluno',
    'Dashboard.Learners.ColumnTooltip.NoCompletedScenarios':'Número de cenários distintos que foram concluídos por um aluno',
    'Dashboard.Learners.ColumnTooltip.Completed':'Simulações concluídas com sucesso',
    'Dashboard.Learners.ColumnTooltip.Scheduled':'Próximas simulações que estão agendadas, mas ainda não foram concluídas (inclui as que estão em execução)',
    'Dashboard.Learners.ColumnTooltip.Missed':'Simulações que foram perdidas pelo aluno',
    'Dashboard.Learners.ColumnTooltip.LateCancelled':'Simulações que foram canceladas com atraso (após o prazo de cancelamento) pelo aluno, facilitador ou proprietário da conta ou simulações.',
    'Dashboard.Learners.ColumnTooltip.EndedInError':'Simulações que terminaram com erro de conexão por parte do aluno',
    'Dashboard.Learners.ColumnTooltip.ProjectName':'Nome do projeto',
    'Dashboard.Learners.ColumnTooltip.ScenarioName':'Nome do cenário',
    'Dashboard.Learners.ColumnTooltip.ScheduledStartDate':'Data em que esta simulação foi agendada para começar',
    'Dashboard.Learners.ColumnTooltip.ScheduledStartTime':'Horário em que esta simulação foi agendada para começar',
    'Dashboard.Learners.ColumnTooltip.ScheduledEndTime':'Horário em que esta simulação foi agendada para terminar',
    'Dashboard.Learners.ColumnTooltip.SimulationStatus':'Status final para a simulação',
    'Dashboard.Learners.ColumnTooltip.LearnerStatus':'Status final do aluno para a simulação',
    'Dashboard.Learners.ColumnTooltip.LearnerTimeSpent':'Minutos que o aluno gastou na simulação. Para alunos do Zoom e Meet, isso é estimado com base no especialista em SIM, se concluído',
    'Dashboard.Learners.ColumnTooltip.SimSpecialistName':'Especialista em simulação designado para entregar a simulação',
    'Dashboard.Learners.ColumnTooltip.SimSpecialistStatus':'Status final de especialista em SIM para a simulação',
    'Dashboard.Learners.ColumnTooltip.SimulationId':'Identificador único da simulação',
    'Dashboard.Learners.ColumnTooltip.Report':'Link para o relatório de simulação para visualizar (se disponível) a gravação e análise da simulação',
    'Dashboard.SessionLearners.TableHeader':'Dados dos alunos da sessão',
    'MursionPortal.FilterHeading.Filter':'FILTRO',
    'MursionPortal.FilterHeading.Graph':'GRÁFICOS',
    'MursionPortal.Table.NoRecordsMessage':'Nenhum resultado encontrado!',
    'MursionPortal.Filters.DeliveryMode':'Modo de entrega',
    'MursionPortal.Filters.PlaceHolder.DeliveryMode':'Buscar por modo de entrega...',
    'MursionPortal.Dashboard.Chart.Tooltip.Utilized':'Número total utilizado para o item de linha do contrato. Inclui simulações concluídas, perdidas ou canceladas/reagendadas (após o prazo).',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.Completed':'Simulações que foram concluídas com sucesso.',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.LearnerMissed':'Simulações que foram perdidas pelo aluno',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.Error':'Simulações que terminaram com erro de conexão por parte do aluno.',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.LateCancellation':'Simulações que foram canceladas com atraso (após o prazo de cancelamento) pelo aluno, facilitador ou proprietário da conta, ou simulações em que o aluno saiu ou recusou.',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.EarlyCancellation':'Simulações que foram canceladas antecipadamente (antes do prazo de cancelamento) pelo aluno, facilitador ou proprietário da conta.',
    'MursionPortal.Dashboard.Chart.label.SimulationBreakdown.Completed':'Concluído(a)',
    'MursionPortal.Dashboard.Chart.label.SimulationBreakdown.Error':'Erro',
    'MursionPortal.Dashboard.Chart.label.SimulationBreakdown.LateCancellation':'Canc. com atraso',
    'MursionPortal.Dashboard.Chart.label.SimulationBreakdown.EarlyCancellation':'Canc. com antecedência',
    'MursionPortal.Dashboard.Chart.PrivatePractice1':'30m de prática particular',
    'MursionPortal.Dashboard.Chart.PrivatePractice2':'60m de prática particular',
    'MursionPortal.Dashboard.Chart.Workshop1':'60m de workshop',
    'MursionPortal.Dashboard.Chart.Workshop2':'90m de workshop',
    'MursionPortal.Dashboard.Chart.StringType.Utilized':'Usada(s)',
    'MursionPortal.Dashboard.Chart.Header.SimulationBreakdown':'Detalhamento da simulação',
    'MursionPortal.SessionRecording':'Gravação da sessão',
    'MursionPortal.VoiceAnalytics':'Análise de voz',
    'MursionPortal.VideoTab':'Vídeo',
    'MursionPortal.SessionInformation':'Informação da sessão',
    'MursionPortal.SmartMatrix':'Métricas inteligentes',
    'MursionPortal.DeliveryDetails':'Detalhes da entrega',
    'MursionPortal.ConversationalFlow':'Fluxo conversacional',
    'MursionPortal.ConversationalFlowTooltip':'Este gráfico mostra sua eficácia durante uma conversa. É composto por observações de trocas de turnos que revelam quão bem você é capaz de participar de uma conversa construtiva.',
    'MursionPortal.BalancedTalkTime':'Conversação',
    'MursionPortal.BalancedFeedback':'Feedback equilibrado',
    'MursionPortal.ConversationalDisruptions':'Interrupções',
    'MursionPortal.DisruptionsFeedback':'Feedback de disrupções',
    'MursionPortal.Heading.SessionSmartMetrics':'Análise de voz da sessão:',
    'Clients.Modal.Label.SmartMetrics':'Análise de voz:',
    'Clients.Modal.Projects.Header.SessionSmartMetrics':'Análise de voz da sessão:',
    'Clients.Modal.Projects.Checkbox.EnableSmartMetrics':' Ativar análise de voz',
    'Filters.Contract':'Contrato',
    'Filters.Session': 'Seção',
    'Clients.Modal.Label.ShowSowReporting':'Mostrar relatório SOW',
    'Clients.Modal.Label.ShowSurveyEnabled':'Enviar pesquisa de alunos pós-simulação:',
    'Dashboard.SimulationTable.Column.Provider':'Fornecedor',
    'Dashboard.SimulationTable.Column.Client':'Cliente',
    'Dashboard.SimulationTable.Column.Project':'Projeto',
    'Dashboard.SimulationTable.Column.ScenarioName':'Nome do cenário',
    'Dashboard.SimulationTable.Column.ScheduledStartDate':'Data de início agendada',
    'Dashboard.SimulationTable.Column.ScheduledStartTime':'Horário de início agendado',
    'Dashboard.SimulationTable.Column.ScheduledEndTime':'Horário de término agendado',
    'Dashboard.SimulationTable.Column.LearnersNames':'Nomes do(s) aluno(s)',
    'Dashboard.SimulationTable.Column.LearnersEmails':'E-mails do(s) aluno(s)',
    'Dashboard.SimulationTable.Column.LearnersStatus':'Status do(s) aluno(s)',
    'Dashboard.SimulationTable.Column.LearnersTimeSpent':'Tempo gasto pelo(s) aluno(s)',
    'Dashboard.SimulationTable.Column.SimSpecialistName':'Nome do especialista em Sim',
    'Dashboard.SimulationTable.Column.SimSpecialistStatus':'Status do especialista em Sim',
    'Dashboard.SimulationTable.Column.SimSpecialistTimeSpent':'Tempo gasto pelo especialista em SIM',
    'Dashboard.SimulationTable.Column.ScheduledBy':'Agendado(a) por',
    'Dashboard.SimulationTable.Column.ScheduledAt':'Agendado(a) para',
    'Dashboard.SimulationTable.Column.Recorded':'Gravado(a)',
    'Dashboard.SimulationTable.Column.ClientId':'ID do cliente',
    'Dashboard.SimulationTable.Column.LearnerName':'Nome do aluno',
    'Dashboard.SimulationTable.Column.LearnerName.ButtonHoverTitle':'Copiar e-mail do aluno',
    'Dashboard.SimulationTable.Column.LearnerEmail':'E-mail do aluno',
    'Dashboard.SimulationTable.Column.LearnerStatus':'Status do aluno',
    'Dashboard.SimulationTable.Column.LearnerTimeSpent':'TempoGasto do aluno',
    'Dashboard.SimulationTable.ColumnTooltip.Provider':'Nome do fornecedor/licenciado',
    'Dashboard.SimulationTable.ColumnTooltip.Client':'Nome do cliente',
    'Dashboard.SimulationTable.ColumnTooltip.Project':'Nome do projeto',
    'Dashboard.SimulationTable.ColumnTooltip.ScenarioName':'Nome do cenário',
    'Dashboard.SimulationTable.ColumnTooltip.ScheduledStartDate':'Data em que esta simulação foi agendada para começar',
    'Dashboard.SimulationTable.ColumnTooltip.ScheduledStartTime':'Horário em que esta simulação foi agendada para começar',
    'Dashboard.SimulationTable.ColumnTooltip.ScheduledEndTime':'Horário em que esta simulação foi agendada para terminar',
    'Dashboard.SimulationTable.ColumnTooltip.SimulationStatus':'Status final para a simulação',
    'Dashboard.SimulationTable.ColumnTooltip.LearnersNames':'Nome(s) dos alunos registrados e não registrados para a simulação',
    'Dashboard.SimulationTable.ColumnTooltip.LearnersEmails':'E-mail(s) do(s) aluno(s) registrado(s) e não registrado(s) para a simulação',
    'Dashboard.SimulationTable.ColumnTooltip.LearnersStatus':'Status final do aluno para a simulação',
    'Dashboard.SimulationTable.ColumnTooltip.LearnersTimeSpent':'Tempo gasto conectado à simulação pelos alunos que participaram',
    'Dashboard.SimulationTable.ColumnTooltip.SimSpecialistName':'Nome do especialista em Sim',
    'Dashboard.SimulationTable.ColumnTooltip.SimSpecialistStatus':'Status final de especialista em SIM para a simulação',
    'Dashboard.SimulationTable.ColumnTooltip.SimSpecialistTimeSpent':'Duração real, em minutos, desta simulação',
    'Dashboard.SimulationTable.ColumnTooltip.ScheduledBy':'Nome do usuário que agendou a simulação',
    'Dashboard.SimulationTable.ColumnTooltip.ScheduledAt':'Data e hora em que esta simulação foi agendada',
    'Dashboard.SimulationTable.ColumnTooltip.Recorded':'Indica se a simulação foi gravada ou se não foi permitida.',
    'Dashboard.SimulationTable.ColumnTooltip.LearnerName':'Nome(s) dos alunos registrados e não registrados para a simulação',
    'Dashboard.SimulationTable.ColumnTooltip.LearnerStatus':'Status final do aluno para a simulação',
    'Dashboard.SimulationTable.ColumnTooltip.LearnerTimeSpent':'Minutos que o aluno gastou na simulação. Para alunos do Zoom e Meet, isso é estimado com base no especialista em SIM, se concluído',
    'MursionPortal.Label.VideoTimeLineSlice':'Cortes da linha do tempo do vídeo',
    'MursionPortal.Label.SpeakingMoreThanSixty':'Falar é superior a 60%',
    'MursionPortal.Label.SpeakingBetweenSixty':'Falar está entre 40% e 60%',
    'MursionPortal.Label.InterruptionMoreThanSixty':'A interrupção é superior a 60%',
    'MursionPortal.Label.InterruptionBetweenSixty':'A interrupção está entre 40% e 60%',
    'MursionPortal.Label.SpeakingLessThanFourty':'Falar é inferior a 40%',
    'MursionPortal.Label.InterruptionLessThanFourty':'A interrupção é inferior a 40%',
    'MursionPortal.Label.Interruption.ByAvatar':'Por Avatar',
    'MursionPortal.Label.Interruption.ByLearner':'Pelo aluno',
    'MursionPortal.Projects.NoContractsFound':'Nenhum contrato foi encontrado.',
    'MursionPortal.Projects.ProjectContracts':'Contratos do projeto',
    'MursionPortal.Tooltip.AvatarInterrupt':'Avatar tenta interromper o locutor com sucesso.',
    'MursionPortal.Tooltip.LearnerInterrupt':'O aluno tenta interromper o falante com sucesso.',
    'MursionPortal.Tooltip.Pauses':'Pausa. Mais de 0,2s e menos de 0,75s',
    'MursionPortal.Tooltip.Silence':'Interrupção na fala por mais de 10 segundos',
    'MursionPortal.Tooltip.AvatarSpeaking':'Avatar tenta falar com sucesso.',
    'MursionPortal.Tooltip.LearnerSpeaking':'O aluno tenta falar com sucesso.',
    // 'MursionPortal.label.LearnerInterrupts':'Aluno interrompe',
    // 'MursionPortal.label.AvatarInterrupts':'Avatar interrompe',
    'MursionPortal.label.Pause':'Pausa',
    'MursionPortal.Label.Silence':'Silêncio',
    'MursionPortal.label.AvatarSpeaking':'Avatar falando',
    'MursionPortal.label.LearnerSpeaking':'Aluno falando',
    'MursionPortal.Contract.DuplicateLineItem':'Já existe um item de linha para esta duração de simulação e modo de exibição',
    'MursionPortal.Contract.DeleteLineItemIcon':'Excluir ícone do item de linha',
    'SessionReports.Label.ShareVideoLink':'Compartilhar link do vídeo',
    'MursionPortal.Title.Silence':'Interrupção na fala por mais de 10 segundos',
    'Dashboard.LeftPane.User.UserActivity':'Atividade do usuário',
    'Dashboard.LeftPane.Performance.SelfService':'Utilização de autoatendimento',
    'MursionPortal.Placeholder.ClientValue':'Selecionar um cliente',
    'Filters.Date.FromDate':'Da data',
    'Filters.Date.ToDate':'Até a data',
    'Dashboard.UserActivity.MainHeader':'Atividade do usuário',
    'Dashboard.SelfService.MainHeader':'Utilização de autoatendimento',
    'Dashboard.SelfService.Column.SelfServiceProvider':'Fornecedor',
    'Dashboard.SelfService.Column.SelfServiceTotalHoursUtilized':'Total de horas usadas',
    'Dashboard.SelfService.Column.SelfServiceHoursCompleted':'Horas concluídas',
    'Dashboard.SelfService.Column.SelfServiceHoursMissed':'Horas perdidas',
    'Dashboard.SelfService.Column.SelfServiceHoursErrored':'Horas com erros',
    'Dashboard.SelfService.Column.SelfServiceSimulationsComplete':'Simulações concluídas',
    'Dashboard.SelfService.Column.SelfServiceSimulationsMissed':'Simulações perdidas',
    'Dashboard.SelfService.Column.SelfServiceSimulationsErrored':'Simulações com erros',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceProvider':'Fornecedor',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceTotalHoursUtilized':'Simulações concluídas com sucesso',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceHoursCompleted':'Horas concluídas',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceHoursMissed':'Simulações que foram perdidas pelo aluno',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceHoursErrored':'Simulações que terminaram com erro de conexão por parte do aluno',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceSimulationsComplete':'Simulações concluídas',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceSimulationsMissed':'Simulações perdidas',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceSimulationsErrored':'Simulações com erros',
    'Dashboard.Learners.Column.AssignedProjects':'Projetos atribuídos',
    'Dashboard.Learners.Column.AssignedScenarios':'Cenários atribuídos',
    'Dashboard.Learners.Column.ScheduledScenarios':'Cenários agendados',
    'Dashboard.Learners.Column.CompletedScenarios':'Cenários concluídos',
    'Settings.Config.SessionReport.ShowSmartMetrics':'Análise de voz',
    'Settings.SSO.CopySsoDeepLink':'Copiar link direto de SSO',
    'Settings.SSO.CopySsoDeepLinkNotification':'Seu link SSO foi copiado para sua área de transferência',
    'MursionPortal.Dashboard.Chart.Assigned':'Atribuída',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.Simulation':'Simulações divididas por status.',
    'MursionPortal.Dashboard.Chart.Header.NoDataAvailable':'Nenhum dado disponível',
    'Projects.ProjectInfo.Label.SurveyControl':'Pesquisas dos projetos',
    'Projects.ProjectInfo.Checkbox.EnablePreSimulationSurvey':'Ativar pesquisa para alunos de pré-simulação',
    'Projects.ProjectInfo.Checkbox.EnablePostSimulationSurvey':'Ativar pesquisa para alunos pós-simulação',
    'Projects.ProjectInfo.Checkbox.SurveyEnabled':'Pesquisa ativada',
    'Projects.ProjectInfo.Checkbox.SurveyEnabled.PreSimulationSurvey':'Pesquisa para alunos de pré-simulação',
    'Projects.ProjectInfo.Checkbox.SurveyEnabled.PostSimulationSurvey':'Pesquisa para alunos pós-simulação',
    'Settings.SSO.ModalBody.SelectDestination':'Selecione o destino do link',
    'Settings.SSO.ModalBody.SelectDestination.SessionsTab':'Aba de sessões',
    'Settings.SSO.ModalBody.SelectDestination.CalendarTab':'Aba de calendário',
    'Settings.SSO.ModalBody.SelectDestination.ReportsTab':'Aba de relatórios',
    'Settings.SSO.ModalBody.SelectDestination.DashboardTab':'Aba do painel',
    'Settings.SSO.ModalBody.SelectDestination.ScenarioCard':'Cartão de cenário',
    'Settings.SSO.ModalBody.SelectDestination.ScenarioTabSelectTitle':'Primeiro selecione um projeto e depois o cenário',
    'Settings.SSO.Button.CreateDeepLink':'Criar link direto de SSO',
    'Settings.SSO.ModalHeader.DeepLink':'Copiar link direto de SSO',
    'MursionPortal.Dashboard.Chart.Header.LearnerSimulations':'Número de cenários atribuídos',
    'Clients.Modal.Label.MursionSocialAttendance':'Participação social no Mursion:',
    'MursionPortal.CodeOfConduct.Text':'Ao escolher agendar sua sessão de Mursion, você concorda em manter e honrar o <code>código de conduta</code> do Mursion.',
    'MursionPortal.Dashboard.DonutChart.Tooltip.ContractProgress.CompletedAllAvailable':'Alunos que completaram todos os cenários disponíveis para eles',
    'MursionPortal.Dashboard.DonutChart.Tooltip.ContractProgress.NoneAvailableToSchedule':'Alunos que ainda não tiveram um cenário disponível para agendamento',
    'MursionPortal.Dashboard.DonutChart.Tooltip.ContractProgress.NotYetScheduled':'Alunos com cenários disponíveis, mas que ainda não agendaram uma simulação',
    'MursionPortal.Dashboard.DonutChart.Tooltip.ContractProgress.Scheduled':'Alunos com uma ou mais simulações agendadas que ainda não concluíram',
    'MursionPortal.DateRangeSelector.Label.DateRange':'Período ',
    'MursionPortal.DemandBasedScheduling.TimeBlockUnavailableReservedText':'Já selecionado - por favor, escolha outro bloco de tempo.',
    'MursionPortal.EndDate':'Data de término',
    'MursionPortal.Label.FilterSIMsBy':'Filtrar SIMs por',
    'MursionPortal.LearnerSimSelector.Learner':'Alunos',
    'MursionPortal.LearnerSimSelector.Learner.Selected':'Aluno selecionado',
    'Dashboard.Filters.SimulationID':'ID da simulação',
    'MursionPortal.LearnerSimSelector.Sim.Selected':'Especialista em Sim',
    'MursionPortal.LearnerSimSelector.Sim.Unavailable':'Certificado e indisponível',
    'MursionPortal.LearnerSimSelector.Tab.Search.Learner':'Buscar ou selecionar aluno',
    'MursionPortal.LearnerSimSelector.Tab.Search.Sim':'Pesquise ou selecione o especialista em SIM',
    'MursionPortal.Placeholder.ProvidersValue':'Selecionar um fornecedor',
    'MursionPortal.ProjectContract.Confirmation':'Tem certeza de que deseja excluir o contrato deste projeto?',
    'MursionPortal.Scheduling.Label.DateTimeTab':'Data + hora',
    'MursionPortal.Scheduling.NoAvailabilityForCertifiedAndAvailableSimSpecialist':'Não existe disponibilidade para um SIM certificado.',
    'MursionPortal.Scheduling.NoAvailabilityForCertifiedSimSpecialist':'Não há Sims certificados disponíveis',
    'MursionPortal.SessionFlow.Label.AreYouSure':'Você tem certeza?',
    'MursionPortal.SessionFlow.Label.BeforeDeletionPleaseEnsureTheFollowing':'Antes de excluir, certifique-se do seguinte:',
    'MursionPortal.SessionFlow.Label.BeforeDeletionPleaseEnsureTheFollowing.Text1':'Você verificou se o usuário solicitante tem autoridade para excluir (cuidado com simulações de grupo e permissões do aluno).',
    'MursionPortal.SessionFlow.Label.BeforeDeletionPleaseEnsureTheFollowing.Text2':'Você criou um caso de suporte que informa quem está solicitando a exclusão e que verificou com o proprietário da conta, se necessário.',
    'MursionPortal.SessionFlow.Label.DeleteRecording':'Apagar gravação',
    'MursionPortal.SessionFlow.Label.ProviderName':'Nome do fornecedor',
    'MursionPortal.SessionFlow.Label.ReminderThisCanNotBeUndone':'LEMBRETE: Isso não pode ser desfeito!',
    'MursionPortal.SessionFlow.Label.ScenarioDetails':'Detalhes do cenário',
    'MursionPortal.SessionFlow.Label.SessionStartEndDateTime':'Data de início e término da sessão',
    'MursionPortal.SessionFlow.Label.SupportCase':'Caso de suporte #*',
    'MursionPortal.SessionFlow.Label.YouAreAboutToDeletevideoRecording':'Você está prestes a excluir uma gravação de vídeo',
    'MursionPortal.SessionNotStarted.AudioVideoSettings':"Verifique suas configurações de áudio e vídeo",
    'MursionPortal.SessionNotStarted.DescriptionOne':"Enquanto você espera, você pode:",
    'MursionPortal.SessionNotStarted.DescriptionTwo':"Este pop-up desaparecerá <code>{value}</code> minutos antes de sua simulação. Clique em atualizar se a página não for atualizada automaticamente nesse momento.",
    'MursionPortal.SessionNotStarted.Heading':"Você está um pouco adiantado(a)",
    'MursionPortal.SessionNotStarted.PreSimTips':"Confira estas <code>dicas de pré-simulação</code>",
    'MursionPortal.SessionNotStarted.ScenarioDetails':"Revisar os <code>detalhes do cenário</code>",
    'MursionPortal.StartDate':'Data de início',
    'MursionPortal.Survey.Thankyou.Msg':'Agradecemos o seu tempo respondendo a esta pesquisa. Sua resposta foi registrada.',
    'MursionPortal.Title.Hours':'Hora(s)',
    'MursionPortal.Title.Minutes':'Minuto(s)',
    'MursionPortal.UserConsentDeclineConfirmation.PermissionDoesNotLieWithLearner': 'Por favor, indique se você concorda ou não em prosseguir e ter sua(s) simulação(ões) gravada(s). Se você não concordar, não poderá prosseguir com sua sessão de simulação e o software será encerrado. Se você concordar, sua(s) sessão(ões) de simulação será(ão) gravada(s) e cada gravação será armazenada de forma segura por 12 meses, momento em que será arquivada.',
    'MursionPortal.UserConsentDeclineConfirmation.PermissionsLiesWithLearner':'Você tem certeza? Para continuar sem gravar, clique no botão confirmar. Para alterar sua permissão de gravação, clique no botão cancelar.',
    'Session.Edit.Modal.JoinSimulationBtnTooltip':'Você poderá participar <code>{minutesBeforeJoin}</code> minutos antes do início da simulação.',
    'Session.Edit.Modal.LastEmailTrigger':'Último e-mail enviado ao aluno: {sentDate}',
    'Session.Edit.Modal.LastEmailTrigger.Sender.UserName':'Por: {userName} {sentTime}',
    'Session.Rescheduling.Email.Reminder.Msg':'Enviando lembrete de reagendamento por e-mail para o aluno!',
    'Settings.Config.Invitation.MursionSocialAttendance':'Obter participação social no Mursion.',
    'Settings.LTI.Modal.SendScore':'Enviar pontuação',
    'Dashboard.ContractProgress.Column.Provider':'Fornecedor',
    'Settings.SSO.CopyDeepLinkNotification':'Seu link foi copiado para sua área de transferência',
    'MursionPortal.Dashboard.Table.Empty.Body.Message':'os detalhes serão carregados assim que você fizer uma seleção',
    'Settings.SSO.ModalBody.ProjectSelector.DisabledTitle':'Selecione a opção certa primeiro',
    'Settings.SSO.ModalBody.ScenarioSelector.DisabledTitle':'Primeiro, selecione um projeto',
    'MursionPortal.Label.LearnerListening':'Aluno ouvindo',
    'MursionPortal.Label.InterruptionCount':'Horários',
    'MursionPortal.ConversationalFlow.label.videoTimeLine':'Linha de tempo do vídeo',
    'MursionPortal.ConversationalFlow.label.zoomTimeLine':'Zoom',
    'MursionPortal.LearnerSimSelector.Sim.Available':'Certificado e disponível',
    'MursionPortal.Button.View':'Ver',
    'MursionPortal.Dashboard.NewSimulationAssigned':'Nova simulação atribuída',
    'MursionPortal.Dashboard.MakeUpSimulation':'Simulação perdida',
    'MursionPortal.Dashboard.MissedSimulation':'Workshop perdido',
    'MursionPortal.Dashboard.CompletedSimulations':'Simulações concluídas',
    'MursionPortal.Dashboard.BackToDashboard':'Voltar ao painel',
    'MursionPortal.Dashboard.CancelledSimulation':'Workshop perdido',
    'MursionPortal.ScenarioSearchList.Dialog.Title':'Você tem disponibilidade recorrente agendada durante esse período. Você também deseja atualizar sua disponibilidade recorrente? ',
    'MursionPortal.ScenarioSearchList.Dialog.UpdateText':'Só quero atualizar minha disponibilidade para {startDate}',
    'MursionPortal.ScenarioSearchList.Dialog.UpdateText.ToDate':'Quero atualizar minha disponibilidade recorrente a partir de {date}',
    'Mursion.Portal.Status.Connected':'CONECTADO(A)',
    'Mursion.Portal.Status.Late':'EM ATRASO',
    'Mursion.Portal.Status.Immersing':'IMERSÃO',
    'Mursion.Portal.Status.Left':'SAIU',
    'Mursion.Portal.Status.Declined':'RECUSADO(A)',
    'Login.EditFile': 'Edite {file} e salve para recarregar.',
    'Login.Hello': 'Olá',
    'Login.Title': 'Bem-vindo ao Portal Mursion',
    'Login.Description': 'Projete, programe e gerencie suas simulações de realidade virtual',
    'Login.PrivacyPolicy': 'política de privacidade',
    'Login.PrivacyDataInfo': 'informações de dados de privacidade',
    'Login.TechnicalSupport': 'suporte técnico',
    'Login.SignIn': 'Entrar',
    'Login.Next': 'Próxima',
    'Login.Email': 'E-mail',
    'Login.Password': 'Senha',
    'Login.ForgotPassword': 'Esqueci a senha?',
    'Login.Login': 'Login',
    'Login.EmailHint': 'Digite seu e-mail',
    'Login.PasswordHint': 'coloque sua senha',
    'Login.NewPasswordHint': 'insira a nova senha',
    'Login.RepeatPasswordHint': 'digite a nova senha novamente',
    'Login.Image': 'Mursion-Logo',
    'Filters.Heading': 'FILTROS:',
    'Dashboard.LeftPaneHeader': 'PAINEL DE CONTROLE',
    'Dashboard.LeftPane.CategoryHeader.Users': 'USUÁRIOS',
    'Dashboard.LeftPane.CategoryHeader.Projects': 'PROJETOS',
    'Dashboard.LeftPane.Project.ProjectActivity': 'Atividade de Projetos',
    'Dashboard.LeftPane.Project.ProjectUtilization': 'Utilização de Projetos',
    'Dashboard.LeftPane.CategoryHeader.Sessions': 'SESSÕES',
    'Dashboard.LeftPane.Session.SessionLearner': 'Alunos da Sessão',
    'Dashboard.LeftPane.CategoryHeader.Legacy': 'LEGADO',
    'Dashboard.VideosTable.HoverTitle': ' ', // TODO: add translations
    'Dashboard.Button.ExportToEmailSentMsg': 'O relatório será enviado para {email} em breve.',
    'Dashboard.Button.ExportToEmail.HoverTitle': 'Exportar Dados',
    'Dashboard.Button.ExportToEmail.HoverTitleNotAvailable': 'A exportação não está disponível',
    'Dashboard.Next': 'Próxima',
    'Dashboard.Last': 'Última',
    'Dashboard.Help': 'Ajuda',
    'Dashboard.First': 'Primeiro',
    'Dashboard.Back': 'Voltar',
    'FiltersHeading': 'FILTROS:',
    'Filters.Client': 'Cliente',
    'Filters.ClientPlaceHolderHint': 'Pesquisar Clientes...',
    'Filters.Industries': 'Indústrias',
    'Filters.IndustriesPlaceHolderHint': 'Pesquisar indústrias...',
    'Filters.IndustriesDisabledTitle': 'Desativado',
    'Filters.Project': 'Projeto',
    'Filters.ProjectPlaceHolderHint': 'Pesquisar Projetos...',
    'Filters.ProjectDisabledTitle': 'Selecione os Clientes primeiro',
    'Filters.RoleDisabledTitle': 'A seleção de função está desabilitada',
    'Filters.Scenario': 'Cenário',
    'Filters.ScenarioPlaceHolderHint': 'Pesquisar Cenários...',
    'Filters.ScenarioDisabledTitle': 'Selecione Clientes e Projetos primeiro',
    'Filters.Team': 'Equipe',
    'Filters.TeamPlaceHolderHint': 'Pesquisar Equipes...',
    'Filters.TeamDisabledTitle': 'Selecione Clientes e Projetos primeiro',
    'Filters.SessionStatus': 'Status da sessão',
    'Filters.SessionStatusPlaceHolderHint': 'Pesquisar Status da Sessão...',
    'Filters.RegistrationStatus': 'Status de registro',
    'Filters.RegistrationStatus.ShowAllRegistration': 'Status de registro: Todos',
    'Filters.RegistrationStatus.ShowAcceptedRegistration': 'Registro aceito',
    'Filters.RegistrationStatus.ShowNotAcceptedRegistration': 'Registro não aceito',
    'Filters.Date.LastMonth': 'Mês passado',
    'Filters.Date.Yesterday': 'Ontem',
    'Filters.Date.Today': 'Hoje',
    'Filters.Date.Tomorrow': 'Amanhã',
    'Filters.Date.NextMonth': 'Mês seguinte',
    'Filters.Date.From': 'De',
    'Filters.Date.To': 'para',
    'Dashboard.UserActivityTable.HoverTitle': 'Ver Resumo do Usuário',
    'Dashboard.Export.ErrMsg': 'O limite máximo de entradas para exportação é {limit}. Modifique sua solicitação e tente novamente.',
    'MursionPortal.Close': 'Fechar',
    'Dashboard.UserTable.Column.Provider': 'Fornecedor',
    'Dashboard.UserTable.Column.Client': 'Cliente',
    'Dashboard.UserTable.Column.ProfileId': 'ID do perfil',
    'Dashboard.UserTable.Column.UserEmail': 'E-mail de usuário',
    'Dashboard.UserTable.Column.UserEmail.ButtonHoverTitle': 'Copiar e-mail',
    'Dashboard.UserTable.Column.SSO': 'SSO',
    'Dashboard.UserTable.Column.DateRegistered.ButtonTitle': 'Reenviar convite',
    'Dashboard.UserTable.Column.Scenarios': 'Cenários',
    'Dashboard.UserTable.Column.Teams': 'Equipes',
    'Dashboard.UserTable.Column.Industry': 'Indústria',
    'Dashboard.UserTable.Column.Accepted': 'Aceito',
    'Session.Tab.Scheduled': 'Agendado',
    'Session.Tab.Active': 'Ativo',
    'Session.Tab.Past': 'Anterior',
    'Session.Tab.Cancelled': 'Cancelado',
    'Session.Search': 'Pesquisar',
    'Session.Table.Column.Time': 'Hora',
    'Session.Table.Column.Client': 'Cliente',
    'Session.Table.Column.Project': 'Projeto',
    'Session.Table.Column.SoftwareVersion': 'Versão do Software',
    'Session.Table.Column.Scenario': 'Cenário',
    'Session.Table.Column.Trainees': 'Trainees',
    'Session.Table.Column.Training': 'treinamento',
    'Session.Table.Column.Session': 'sessão',
    'Session.Token.Label.Clients': 'Clientes',
    'Session.Token.Label.Projects': 'Projetos',
    'Session.Token.Label.Scenarios': 'Cenários',
    'Session.Token.Label.Teams': 'Equipes',
    'Session.Token.Label.SessionStatuses': 'Status da sessão',
    'Session.Button.More': 'Mais',
    'Session.Time.NotRunning': 'Ainda não está em execução',
    'Session.Time.Finished': 'Já terminado',
    'Session.Time.Running': 'Executando agora',
    'Session.Table.Column.Learners': 'Alunos',
    'Session.Table.Column.Team': 'Equipe',
    'Session.Table.Column.Trainer': 'Professor',
    'Session.Table.Column.SimSpecialist': 'Especialista em Sim',
    'Session.Table.Column.TimeUntil': 'Tempo até',
    'Session.Table.Column.Created': 'Criado',
    'Session.Table.Column.Cancelled': 'Cancelado',
    'Session.Table.Caption.SessionDetailsList': 'Lista de detalhes da sessão',
    'Session.Export': 'Exportar',
    'Session.Export.Message': 'Exportar conteúdo filtrado por',
    'Session.CalendarDate.Start': 'De:',
    'Session.CalendarDate.End': 'Para:',
    'Session.Cancel': 'Cancelar',
    'Session.Confirm': 'Confirmar',
    'Session.Total': 'TOTAL',
    'Session.Filter.SessionNotFoundMessage': 'Nenhuma sessão foi encontrada.',
    'Session.Filter.LearnerUpcomingSessionNotFoundMessage': 'Você não tem próximas sessões agendadas.',
    'Session.Filter.UpcomingSessionNotFoundMessage': 'Nenhuma próxima sessão agendada.',
    'Session.Filter.LearnerActiveSessionNotFoundMessage': 'Você não tem sessões ativas.',
    'Session.Filter.ActiveSessionNotFoundMessage': 'Sem sessões ativas.',
    'Session.Filter.LearnerCompletedSessionNotFoundMessage': 'Você ainda não concluiu nenhuma sessão.',
    'Session.Filter.SessionReportNotFoundMessage': 'Nenhum relatório de sessão disponível.',
    'Session.Filter.CompletedSessionNotFoundMessage': 'Nenhuma sessão foi concluída.',
    'Session.Filter.CancelledSessionNotFoundMessage': 'Nenhuma sessão foi cancelada.',
    'Session.Edit.Modal.Table.Column.DateTime': 'Data/Hora',
    'Session.Edit.Modal.Table.Column.UserRole': 'Usuário (Função)',
    'Session.Edit.Modal.Table.Column.Change': 'Alterar',
    'Session.Edit.Modal.Table.Column.SessionStatus': 'Status da sessão',
    'Session.Edit.Modal.Table.Column.Reason': 'Motivo',
    'Session.Edit.Modal.AssetIndustry': 'Indústria de Ativos',
    'Session.Edit.Modal.SimulationEnvironment': 'Ambiente(s) de simulação',
    'Session.Edit.Modal.AvatarInSimulation': 'Avatar em Simulação',
    'Session.Edit.Modal.Date': 'Data',
    'Session.Edit.Modal.Time': 'Hora',
    'Session.Edit.Modal.DeliveryMode': 'Modalidade de entrega',
    'Session.Edit.Modal.Duration': 'Duration',
    'Session.Edit.Modal.Status': 'Status',
    'Session.Edit.Modal.LearnersAttended': 'Alunos presentes',
    'Session.Edit.Modal.ReasonForCancellation': 'Motivo para cancelamento',
    'Session.Edit.Modal.MoreInfo': 'Mais informações...',
    'Session.Edit.Modal.HideAdditionalInfo': 'Ocultar informações adicionais...',
    'Session.Edit.Modal.AddToCalendar': 'Adicionar ao calendário',
    'Session.Edit.Modal.AttendSession': 'Comparecer à sessão',
    'Session.Edit.Modal.PleaseWait': 'Aguarde...',
    'Session.Edit.Modal.CopySessionLink': 'Copiar Link da Sessão',
    'Session.Edit.Modal.SessionLink': 'Link da sessão',
    'Session.Edit.Modal.SubmitReview': 'Enviar avaliação',
    'Session.Edit.Modal.SessionRequestPending': 'Solicitação de sessão pendente',
    'Session.Edit.Modal.Back': 'Voltar',
    'Session.Edit.Modal.Learners': 'Aluno(s):',
    'Session.Edit.Modal.Learners.UnauthenticatedCheckbox': 'Apenas usuários não autenticados irão participar',
    'Session.Edit.Modal.Trainees': 'Trainee(s):',
    'Session.Edit.Modal.Trainer': 'Professor:',
    'Session.Edit.Modal.SimSpecialist': 'Especialista em Sim:',
    'Session.Edit.Modal.Notes': 'Observações:',
    'Session.Edit.Modal.ClientNotes': 'Observações do cliente:',
    'Session.Edit.Modal.Notes.EmptyMessage': 'Não há observações.',
    'Session.Edit.Modal.Attendance': 'Presenças:',
    'Session.Edit.Modal.ShowHistory': 'Exibir histórico...',
    'Session.Edit.Modal.HideHistory': 'Ocultar histórico...',
    'Session.Edit.Modal.LearnersAttendedLabel': 'Alunos presentes:',
    'Session.Edit.Modal.SimulationContent': 'Conteúdo de Simulação',
    'Session.Edit.Modal.SimulationContent.Name': 'Nome:',
    'Session.Edit.Modal.SimulationContent.Description': 'Descriҫão:',
    'Session.Edit.Modal.SimulationContent.Image': 'Imagem:',
    'Session.Edit.Modal.RestoreSession.ConfirmationText': 'Tem certeza de que deseja restaurar esta sessão?',
    'Session.Edit.Modal.CancelTraining.ConfirmationText': 'Tem certeza de que deseja cancelar este treinamento?',
    'Session.Edit.Modal.CancelSession.ConfirmationText': 'Tem certeza de que deseja cancelar esta sessão?',
    'Session.Edit.Modal.SessionSwap.ConfirmationText': 'Tem certeza de que deseja aceitar a troca desta sessão?',
    'Session.Edit.Modal.AcceptRequest.ConfirmationText': 'Tem certeza de que deseja aceitar esta solicitação de sessão?',
    'Session.Edit.Modal.AssignSS.ConfirmationText': 'Tem certeza de que deseja atribuir um novo Especialista em Sim?',
    'Session.Edit.Modal.ChangeSS.ConfirmationText': 'Tem certeza que deseja mudar um Especialista em Sim?',
    'Session.Edit.Modal.Button.RestoreSession': 'Restaurar sessão',
    'Session.Edit.Modal.Button.CancelTraining': 'Cancelar treinamento',
    'Session.Edit.Modal.Button.CancelSession': 'Cancelar Sessão',
    'Session.Edit.Modal.Button.AcceptSwap': 'Aceitar troca',
    'Session.Edit.Modal.Button.Participate': 'Participar',
    'Session.Edit.Modal.Button.Swap': 'Trocar',
    'Session.Edit.Modal.Button.UpdateSession': 'Atualizar Sessão',
    'Session.Edit.Modal.Button.UpdateTraining': 'Atualizar Treinamento',
    'Session.Edit.Modal.Button.AcceptRequest': 'Aceitar Solicitação',
    'Session.Edit.Modal.Button.AssignSS': 'Atribuir SS',
    'Session.Edit.Modal.Button.ChangeSS': 'Mudar SS',
    'Session.Attendance.Table.Column.UserName': 'Nome de Usuário',
    'Session.Attendance.Table.Column.Time': 'Hora',
    'Session.Attendance.Table.Column.Status': 'Status',
    'Session.ViewSessionReport': 'Ver Relatório de Sessão',
    'Session.ViewEdit': 'Ver / Editar Sessão',
    'Session.Edit.Modal.Table.HistoryNotFoundMessage': 'Sem Histórico ainda',
    'Session.Export.ErrMsg': 'O limite máximo de entradas para exportação é {limit}. Modifique sua solicitação e tente novamente.',
    'Session.Table.Column.Action': 'Ação',
    'Session.Edit.Modal.SessionSimSpecialistField.EmptyMsg.NoTrainers': 'Sem professores para selecionar',
    'Session.Edit.Modal.SessionSimSpecialistField.EmptyMsg.NoSimSpecialists': 'Nenhum especialista em sim para selecionar',
    'Session.Edit.Modal.SessionSimSpecialistField.NoSelectedMsg.NoTrainers': 'Nenhum professor selecionado',
    'Session.Edit.Modal.SessionSimSpecialistField.NoSelectedMsg.NoSimSpecialists': 'Nenhum especialista em sim selecionado',
    'Session.Edit.Modal.SessionSimSpecialistField.EditIconLabel': 'editar',
    'Session.Edit.Modal.SessionSimSpecialistField.Search.Placeholder': 'Pesquisar',
    'Session.Edit.Modal.SessionSimSpecialist.Button.Accept': 'Aceitar',
    'ConfirmationDialogue.AddReasonForChange': 'Por favor, adicione um motivo para a mudança',
    'ConfirmationDialogue.Button.Submit': 'Enviar',
    'ConfirmationDialogue.Button.Cancel': 'Cancelar',
    'ConfirmationDialogue.Button.Ok': 'Ok',
    'ConfirmationDialogue.ReasonForChange': 'Motivo da mudança',
    'ConfirmButton.CancelSession.ConfirmCancellation': 'Confirmar Cancelamento',
    'ConfirmButton.CancelSession': 'Cancelar Sessão',
    'ConfirmButton.CancelSession.No': 'Não',
    'Pagination.Button.First': 'Primeiro',
    'Pagination.Button.Back': 'Voltar',
    'MursionPortal.UserName': 'Nome de Usuário',
    'MursionPortal.Email': 'E-mail',
    'ClientUserTable.Column.Roles': 'Funções',
    'MursionPortal.Department': 'Departamento',
    'MursionPortal.Location': 'Localização',
    'ClientUserTable.Column.Projects': 'Projetos',
    'ClientUserTable.Column.Teams': 'Equipes',
    'ClientUserTable.Column.Accepted': 'Aceito',
    'ClientUserTable.HoverTitle': 'Ver/Editar Usuário',
    'ClientUserTable.NoUserError': 'Não existem usuários ainda',
    'MursionPortal.FirstName': 'Nome',
    'MursionPortal.LastName': 'Sobrenome',
    'MursionPortal.Phone': 'Telefone',
    'Users.TableModal.EmailPlaceHolder': 'exemplo@seudominio.com',
    'Users.TableModal.Title': 'Cargo',
    'Users.TableModal.LocationPlaceHolder': 'Cidade, País',
    'Users.TableModal.DepartmentPlaceHolder': 'Nome do Departamento',
    'Users.TableModal.TitlePlaceHolder': 'Título do Cargo',
    'Users.Table.Modal.SettingsHeader': 'Configurações do professor / aluno',
    'Users.Table.ModalHeader': 'Informações do usuário',
    'Users.Table.ModalButton.Cancel': 'Cancelar',
    'Users.Table.ModalButton.Delete': 'Excluir',
    'Users.Table.ModalButton.Save': 'Salvar',
    'Users.Table.ModalButton.SendInvite': 'Mandar Convite',
    'Users.Table.ModalButton.Restore': 'Restaurar',
    'Users.Table.Modal.Teams.Text': 'Nenhuma equipe foi adicionada ainda',
    'Users.Table.Modal.Teams': 'Equipes',
    'Users.Table.Modal.EmployeeIdentification': 'Identificação do Funcionário',
    'Users.Table.Modal.Timezone': 'Fuso horário',
    'Users.Table.Modal.Roles': 'Funções',
    'Users.Table.Modal.Settings.LearnerButton': 'como Aluno',
    'Users.Table.Modal.Settings.FacilitatorButton': 'como Professor',
    'Users.Button.CreateUserButton': 'Criar usuário ',
    'Users.Button.Cancel': 'Cancelar',
    'Users.Button.ImportUsers': 'Importar usuários',
    'Users.Button.Export': 'Exportar',
    'Users.heading.ExportContentFilteredBy': 'Exportar conteúdo filtrado por',
    'Users.Button.Active': 'ATIVO',
    'Users.Button.Archived': 'ARQUIVADO',
    'Users.Button.All': 'TODOS',
    'Users.Text.Total': 'TOTAL',
    'Users.Heading.NewUsers': 'Novos usuários',
    'Users.Placeholder': 'exemplo@seudominio.com',
    'Users.Button.AddUser': 'Adicionar usuário ',
    'Users.Button.CreateUser': 'Criar usuário',
    'Users.Button.CreateUsers': 'Criar usuários',
    'Users.Heading.ImportNewUsers': 'Importar novos usuários',
    'Users.Heading.ImportNewExternalUsers': 'Importar novos usuários externos',
    'Users.Button.ImportUserList': 'Importar lista de usuários',
    'Users.Text.InstructionMessage.UsersWithoutPredefined': 'Os usuários sem equipes predefinidas serão atribuídos a',
    'Users.Text.AddCsv': 'Adicione um arquivo .csv ou .xslx contendo uma lista de endereços de e-mail colocados em uma coluna.',
    'Users.Text.ToImport': 'Para importar campos adicionais, siga estas diretrizes:',
    'Users.Text.ListUser': 'O e-mail do usuário deve ser separado da lista de equipes de usuários por vírgula, ponto e vírgula, barra vertical ou dois pontos.',
    'Users.Text.ListTeams': 'As equipes devem ser separadas umas das outras por vírgula.',
    'Users.Text.ListFields': 'Os campos e cada uma das equipes devem ser especificados em células separadas.',
    'Users.Text.UserRole': 'O tipo de função do usuário será padronizado como Aluno',
    'Users.Text.UnlessOtherwiseIndicated': 'salvo indicação em contrário.',
    'Users.Text.SampleImport': 'Você pode baixar uma amostra de arquivos de importação aqui:',
    'Users.DownloadCsvTemplate': 'Baixar Modelo CSV',
    'Users.DownloadExcelTemplate': 'Baixar Modelo do Excel',
    'Users.Button.Update': 'Atualizar',
    'Users.Labels.Clients': 'Clientes',
    'Users.Labels.Projects': 'Projetos',
    'Users.Labels.Scenarios': 'Cenários',
    'Users.Labels.Teams': 'Equipes',
    'Users.Labels.Roles': 'Funções',
    'Users.Text.OnlyAccepted': 'Aceito apenas:',
    'Users.Button.Confirm': 'Confirmar',
    'Users.Button.AccOwner': 'Proprietário da conta',
    'Users.Button.Facilitator': 'Professor',
    'Users.Button.Learner': 'Aluno',
    'Users.Button.ProfServices': 'Serviços Profissionais',
    'Users.Button.ProjectManager': 'Gerente de Projetos',
    'Users.Button.SimSpecialist': 'Especialista em Sim',
    'Users.Button.ITManager': 'Gerente de TI',
    'Users.Button.BusinessAdmin': 'Administrador de empresas',
    'Users.Button.SupportAdmin': 'Suporte do Administrador',
    'Users.Button.DevAdmin': 'Administrador do Dispositivo',
    'Users.Button.ImportExternalUsers': 'Importar usuários externos',
    'Users.Button.AssignLearners': 'Atribuir Alunos',
    'Users.CreateUser.Email.Placeholder': 'exemplo@seudominio.com',
    'Users.Text.Instructions': 'Adicione o arquivo .csv ou .xslx que contém a lista de usuários com os seguintes campos: email, ssoid, nome, sobrenome, função, equipe. Todos os campos, exceto Equipe, são obrigatórios.',
    'Users.Text.Guidelines': 'Siga estas instruções:',
    'Users.Button.CreateUser.PencilIcon': 'Editar detalhes do usuário',
    'Users.Button.CreateUser.TrashIcon': 'Remover usuário',
    'Users.ExportButton.TextYes': 'Sim',
    'Users.ExportButton.TextNo': 'Não',
    'Users.Button.OK': 'Ok',
    'Users.Button.AddProject': 'Adicionar projeto',
    'Users.CreateUser.Label.AddToProjects': 'Adicionar ao(s) projeto(s)',
    'Users.UserInfo.Label.AddTeams': 'Adicionar equipes',
    'Users.UserInfo.Label. SelectATeam': 'Selecione uma equipe',
    'Users.UserInfo.Label.AssignToTeam': 'Atribuir à equipe:',
    'Users.CreateUser.Label.NoProjectsSpecified': 'Nenhum projeto especificado',
    'Users.Option.SelectClient': 'Selecionar Cliente',
    'Users.Label.Client': 'Cliente:',
    'Users.Label.RemoveTeam': 'Remover Equipe',
    'Users.Label.ChooseATeam': 'Escolha uma equipe',
    'Users.Project.Warning': 'Este Cliente não possui projetos. Selecione outro.',
    'Users.Project.Warning.UnknownProject': 'Projeto desconhecido',
    'Users.ProjectContainer.Warning': 'Pelo menos um projeto deve ser adicionado',
    'Users.CompanyUsers.Button.Ok': 'Ok',
    'Users.CompanyUsers.Button.Accept': 'Aceitar',
    'Users.CreateUser.Label.Tier': 'Tier',
    'Users.CreateUser.Label.HireDate': 'Data de Contratação',
    'Users.CreateUser.Label.StationType': 'Tipo da estação',
    'Users.CreateUser.ToggleButton.Home': 'Página Inicial',
    'Users.CreateUser.ToggleButton.Office': 'Cargo',
    'Users.CreateUser.Label.Hours': 'Horas',
    'Users.CreateUser.ToggleButton.FullTime': 'Período Integral',
    'Users.CreateUser.ToggleButton.PartTime': 'Meio Período',
    'Users.CreateUser.Label.ZoomID': 'ID do Zoom',
    'Users.CreateUser.ZoomID.Placeholder': 'ID do Zoom',
    'Users.CreateUser.ZoomID.Hover': 'Insira um ID do Zoom',
    'Users.CreateUser.Label.UserRoles': 'Funções do usuário',
    'Users.CreateUser.Label.ProjectManagerSettings': 'Configurações do Gerente de Projeto',
    'Users.CreateUser.Label.SimSpecialistSettings': 'Configurações do Especialista em Sim',
    'Users.CreateUser.Label.Certificates': 'Certificados',
    'Users.CreateUser.Label.TurnOnEditCertificates': 'Ativar edição de certificados',
    'Users.CreateUser.Label.TurnOffEditCertificates': 'Desativar edição de certificados',
    'Users.CreateUser.Label.NoCertificatesHaveBeenAddedYet': 'Nenhum certificado foi adicionado ainda',
    'Users.CreateUser.Label.AddCertificate': 'Adicionar certificado',
    'Users.CreateUser.Label.CertificateOwner': 'Proprietário do certificado',
    'Users.CreateUser.Button.RemoveCertificate': 'Excluir certificado',
    'Users.TableHeading.Name': 'Nome',
    'Users.TableHeading.Email': 'E-mail',
    'Users.TableHeading.Roles': 'Funções',
    'Users.TableHeading.Location': 'Localização',
    'Users.TableHeading.Accepted': 'Aceito',
    'Users.Table.Hover.ViewEditUser': 'Ver/Editar Usuário',
    'Users.Table.Hover.NoUsersYet': 'Não existem usuários ainda',
    'Users.CreateUser.Button.Client': 'Cliente',
    'Users.CreateUser.Label.ChooseAClient': 'Escolha um cliente',
    'Users.CreateUser.Label.SelectAClient': 'Selecione um cliente',
    'Users.CreateUser.Label.ChooseACertificate': 'Escolha um certificado',
    'Users.CreateUser.Label.SelectACertificate': 'Selecionar Certifidado',
    'Users.CreateUser.Label.NoCertificatesToSelect': 'Nenhum certificado para selecionar',
    'Users.CreateUser.Button.AddCertificate': 'Adicionar certificado ',
    'Users.CreateUser.PlaceHolder.SelectTier': 'Selecione o Nível',
    'Users.RequiredField.Warning': 'Verifique se todos os campos estão preenchidos corretamente',
    'Users.RequiredField.ApplyChanges': 'Aplicar alterações',
    'Users.Heading.NewUserInfo': 'Informações do usuário',
    'Users.SearchPlaceHolderText': 'Digite o nome de usuário',
    'Users.SearchNoUserFoundError': 'Nenhum usuário cliente foi encontrado.',
    'Users.Table.Modal.Settings.AddTeamHeader': 'Adicionar equipes',
    'Users.Table.Modal.Settings.ChooseTeamHeader': 'Escolha uma equipe',
    'Users.Table.Modal.Settings.AssignTeamHeader': 'Atribuir à equipe:',
    'Users.Table.Modal.Settings.SelectTeamOptionHeader': 'Selecione uma equipe',
    'Users.Table.Modal.Settings.TeamLearner': '(Aluno)',
    'Users.Table.Modal.Settings.TeamFacilitator': '(Professor)',
    'Users.Table.Modal.Settings.RemoveTeamOption': 'Remover Equipe',
    'Users.Table.Modal.Settings.TurnOnEditTeamsOption': 'Ativar edição de equipes',
    'Users.Table.Modal.Settings.TurnOffEditTeamsOption': 'Desativar edição de equipes',
    'Users.Total': 'TOTAL',
    'Users.Table.Modal.ArchiveHeader.ConfirmArchiveUser': 'Confirmar arquivar usuário',
    'Users.Table.Modal.ArchiveText': 'Tem certeza de que deseja arquivar este usuário?',
    'Users.Table.Modal.ArchiveUserButton': 'Arquivar usuário',
    'Users.Table.Modal.RestoreHeader.ConfirmRestoreUser': 'Confirmar restaurar usuário',
    'Users.Table.Modal.RestoreText': 'Tem certeza que deseja restaurar este usuário?',
    'Users.Table.Modal.RestoreUserButton': 'Restaurar Usuário',
    'Users.Table.Modal.CancelButton': 'Cancelar',
    'Users.Table.Modal.ArchiveButton': 'Arquivar',
    'Users.Table.Modal.RestoreButton': 'Restaurar',
    'Users.Table.Modal.SendInviteButton': 'Mandar Convite',
    'Users.CreateUserButton.Modal.CreateUserButton': 'Criar usuário',
    'Users.Table.Modal.UpdateUserButton': 'Atualizar Usuário',
    'Users.ViewUserOptions': 'Ver usuário da empresa {companyName}',
    'Users.NewUserOptions': 'Novo usuário da empresa {companyName}',
    'Users.EditUserOptions': 'Editar usuário da empresa {companyName}',
    'Reports.SessionListHeader.Completed': 'Concluído',
    'Reports.SessionListHeader.TotalSessionTime': 'Tempo total da sessão,',
    'Reports.SessionListHeader.TotalActualTime': 'Tempo real total,',
    'Reports.SessionListHeader.SimulationCompletionTime': 'Tempo de conclusão da simulação',
    'Reports.SessionListHeader.CantOpenReport': 'Aguarde enquanto nosso mecanismo de análise processa seus resultados. Pode ser necessário atualizar esta página após alguns minutos.',
    'Reports.SessionListHeader.NoReportsFound': 'Nenhum relatório encontrado.',
    'Reports.ReportCard.Button.ReviewSimulation': 'REVER SIMULAÇÃO',
    'Reports.ReportCard.Strategies': 'Estratégias',
    'Reports.ReportCard.NotChecked': 'Desmarcado',
    'Dashboard.SchedulingInfoTable.Modal.SortBy': 'Filtrar por:',
    'Dashboard.SchedulingInfoTable.Modal.NoItemsFound': 'Nenhum item foi encontrado.',
    'Dashboard.SchedulingInfoTable.Modal.Teams': 'Equipes',
    'Dashboard.SchedulingInfoTable.Modal.Teams.Placeholder': 'Insira o nome da equipe',
    'Dashboard.SchedulingInfoTable.Modal.Teams.EmptyMessage': 'Nenhuma equipe foi encontrada',
    'Dashboard.SchedulingInfoTable.Modal.Capitalized.TeamName': 'Nome da Equipe:',
    'Dashboard.SchedulingInfoTable.Modal.Learners': 'Alunos:',
    'Dashboard.SchedulingInfoTable.Modal.Projects': 'Projetos:',
    'Dashboard.SchedulingInfo.Filter.SearchIndustries.PlaceholderHint': 'Pesquisar indústrias...',
    'Dashboard.SchedulingInfo.Filter.IndustryDisabledTitle': 'Desativado',
    'Dashboard.SchedulingInfo.Filter.UsedSoftwareVersion.PlaceholderHint': 'Versões de software usadas...',
    'Dashboard.SchedulingInfoTable.Modal.Total': 'TOTAL',
    'Dashboard.SchedulingInfoTable.Modal.ScenarioName': 'Nome do Cenário',
    'Dashboard.SchedulingInfoTable.Modal.SessionDate': 'Data da Sessão',
    'Dashboard.SchedulingInfoTable.Modal.ProjectName': 'Nome do projeto',
    'Dashboard.SchedulingInfoTable.Modal.SoftwareVersion': 'Versão do software',
    'Dashboard.SchedulingInfoTable.Modal.Capitalized.DeliveryMode': 'Modalidade de entrega',
    'Dashboard.SchedulingInfoTable.Modal.SessionStatus': 'Status da sessão',
    'Dashboard.SchedulingInfoTable.Modal.Name': 'Nome',
    'Dashboard.SchedulingInfoTable.Modal.Status': 'Status',
    'Dashboard.SchedulingInfoTable.Modal.Recording': 'A gravação vai para',
    'Dashboard.SchedulingInfoTable.Modal.TeamName': 'Nome da equipe',
    'Dashboard.SchedulingInfoTable.Modal.Learner': 'Alunos',
    'Dashboard.SchedulingInfoTable.Modal.CompletedSessions': 'Sessões Concluídas',
    'Dashboard.SchedulingInfoTable.Modal.DeliveryMode': 'Modalidade de entrega',
    'Dashboard.SchedulingInfoTable.Modal.Capitalized.ProjectName': 'Nome do Projeto',
    'Dashboard.SchedulingInfoTable.Modal.Capitalized.SoftwareVersion': 'Versão do Software',
    'Dashboard.SchedulingInfoTable.Modal.Timeline': 'Linha do Tempo',
    'Dashboard.SchedulingInfoTable.Modal.SchedulesSessions': 'Sessões agendadas',
    'Dashboard.SchedulingInfoTable.Modal.MissedSessions': 'Sessões Mistas',
    'Dashboard.SchedulingInfoTable.Modal.LateCancellations': 'Cancelamentos \'tardios\'',
    'Dashboard.SchedulingInfoTable.Modal.TimeSpent': 'Tempo gasto',
    'Dashboard.SchedulingInfoTable.Modal.CancelledBy': 'Cancelada por',
    'Dashboard.SchedulingInfoTable.Modal.CancellationReason': 'Motivo de cancelamento',
    'Dashboard.SchedulingInfoTable.Modal.DateScheduled': 'Data de agendamento',
    'Dashboard.SchedulingInfoTable.Modal.DateCancelled': 'Data de cancelamento',
    'Dashboard.SchedulingInfoTable.Modal.Filter.Teams': 'Equipes',
    'Dashboard.SchedulingInfoTable.Modal.Placeholder.EnterTeamName': 'Insira o nome da equipe',
    'Dashboard.SchedulingInfoTable.Modal.TeamsEmptyMessage': 'Nenhuma equipe foi encontrada',
    'Dashboard.SchedulingInfoTable.Modal.Filter.TeamName': 'Nome da equipe',
    'Dashboard.SchedulingInfoTable.Modal.Filter.Scenarios': 'Cenários',
    'Dashboard.SchedulingInfoTable.Modal.Placeholder.EnterScenarioName': 'Inserir nome do cenário',
    'Dashboard.SchedulingInfoTable.Modal.ScenariosEmptyMessage': 'Nenhum cenário foi encontrado',
    'Dashboard.SchedulingInfoTable.Modal.Filter.ScenarioName': 'Nome do Cenário',
    'Dashboard.SchedulingInfoTable.Modal.Filter.ProjectName': 'Nome do Projeto',
    'Dashboard.SchedulingInfoTable.Modal.Filter.SoftwareVersion': 'Versão do Software',
    'Dashboard.SchedulingInfoTable.Modal.Filter.ScenarioStatus': 'Status do cenário',
    'Dashboard.SchedulingInfoTable.Modal.Filter.DeliveryMode': 'Modalidade de entrega',
    'Dashboard.SchedulingInfoTable.Modal.Filter.SessionsScheduled': 'Sessões agendadas',
    'Dashboard.SchedulingInfoTable.Modal.SessionsEmptyMessage': 'Nenhuma sessão foi encontrada',
    'Dashboard.SchedulingInfoTable.Modal.Filter.SessionsDateTime': 'Data/hora da sessão',
    'Dashboard.SchedulingInfoTable.Modal.Filter.SessionsCompleted': 'Sessões concluídas',
    'Dashboard.SchedulingInfoTable.Modal.Filter.SessionsMissed': 'Sessões perdidas',
    'Dashboard.SchedulingInfoTable.Modal.Filter.LateCancellations': 'Cancelamentos "tardios"',
    'Dashboard.SchedulingInfoTable.Modal.Filter.CancelledBy': 'Cancelado por',
    'Dashboard.SchedulingInfoTable.Modal.Filter.SessionsError': 'Sessões com erro',
    'RestorePassword.EnterEmailAddress': 'Digite seu endereço de e-mail e clique no botão Restaurar - um e-mail será enviado a você com um link para redefinir sua senha.',
    'RestorePassword.Email': 'E-mail',
    'RestorePassword.Button.Cancel': 'Cancelar',
    'RestorePassword.Button.Restore': 'Restaurar',
    'RestorePassword.Modal.IfValidEmailAddressEntered': 'Se um endereço de e-mail válido foi inserido, um e-mail foi enviado para a conta associada com instruções para definição de senha. Entre em contato com o suporte técnico se o problema persistir.',
    'RestorePassword.Modal.Button.GotIt': 'Ok',
    'Trainings.Heading.AllTrainingSessions': 'Todas as sessões de treinamento',
    'Requests.Table.Swap': 'SWAP',
    'Requests.Table.SchedulingRequests': 'Solicitações de agendamento',
    'Requests.Total': 'TOTAL',
    'Requests.Table.NoSessionsFound': 'Nenhuma sessão foi encontrada.',
    'Requests.Table.NoUpcomingSwapSessions': 'Não há próximas sessões SWAP.',
    'Requests.Table.NoUpcomingRequests': 'Você não tem solicitações futuras.',
    'GetTimeToEvents.Day': 'Dia',
    'GetTimeToEvents.Days': 'Dias',
    'GetTimeToEvents.Minute': 'Minuto',
    'GetTimeToEvents.Minutes': 'Minutos',
    'GetTimeToEvents.Hour': 'Hora',
    'GetTimeToEvents.Hours': 'Horas',
    'GetTimeToEvents.LessThanOneMinute': 'Menos de 1 minuto',
    'SessionReports.SessionInformation': 'Informações da sessão',
    'SessionReports.SessionInformation.Hover.Information': 'Informações',
    'SessionReports.SmartMetrics': 'Métricas inteligentes',
    'SessionReports.SendVideoLink.Modal.ShareLinkToVideo': 'Compartilhar link para vídeo',
    'SessionReports.Button.BackToReports': 'Voltar aos Relatórios',
    'SessionReports.Button.BackToTrainings': 'Voltar aos treinamentos',
    'SessionReports.Button.HideInfo': 'Ocultar informação',
    'SessionReports.Button.ShowInfo': 'Exibir informação',
    'SessionReports.Button.SendVideoLink': 'Link do vídeo',
    'SessionReports.TryAgain': 'Tente novamente',
    'SessionReports.Mode': 'Modo:',
    'SessionReports.SessionLength': 'Duração da sessão:',
    'SessionReports.LearnersAttended': 'Alunos presentes:',
    'SessionReports.ScenarioTimeline': 'Cronograma do cenário:',
    'SessionReports.ActualSessionTime': 'Tempo real da sessão:',
    'SessionReports.ActualSimulationTime': 'Tempo real de simulação:',
    'SessionReports.LastUserStatus': 'Último status do usuário:',
    'SessionReports.SessionStatus': 'Status da sessão:',
    'SessionReports.Delivery': 'Entrega',
    'SessionReports.SendVideoLink.Modal.AddEmails': 'Adicionar E-mails',
    'SessionReports.SendVideoLink.Modal.Button.AddEmail': 'Adicionar E-mail ',
    'SessionReports.SendVideoLink.Modal.EmailPlaceHolder': 'exemplo@seudominio.com',
    'SessionReports.SendVideoLink.Modal.Button.Cancel': 'Cancelar',
    'SessionReports.SendVideoLink.Modal.Button.Send': 'Enviar',
    'SessionReports.FactSessionInfo': 'Informações da sessão',
    'SessionReports.Text.NoTraineeParticipatedInSession': 'Nenhum Trainee participou da sessão',
    'SessionReports.Text.NoLearnerParticipatedInSession': 'Nenhum aluno participou da sessão',
    'SessionReports.ToolTip.TrackingYours': 'É muito importante que a sua percepção de um item de resultado ou estratégia esteja alinhada com a do(s) avatar(es). Isso indica que você está monitorando seu impacto no(s) avatar(es), ajudando a melhorar a eficácia social. Essas informações estão contidas na tabela a seguir.',
    'SessionReports.ToolTip.TrackingLearners': 'É muito importante que a sua percepção de um item de resultado ou estratégia esteja alinhada com a do(s) avatar(es). Isso indica que o Aluno está rastreando seu impacto no(s) avatar(es), ajudando assim a melhorar a eficácia social. Essas informações estão contidas na tabela a seguir.',
    'SessionReports.ToolTip.AvatarsHeaderYours': 'Esta coluna indica a percepção de avatar(es) de seu desempenho de simulação. Uma marca de seleção indica que os avatares perceberam que você teve sucesso em alcançar o resultado, enquanto uma marca cruzada indica espaço para melhorias.',
    'SessionReports.ToolTip.AvatarsHeaderLearners': 'Esta coluna indica a percepção de avatar(es) de seu desempenho de simulação. Uma marca de verificação indica que os avatares perceberam que o aluno teve sucesso em alcançar o resultado, enquanto uma marca cruzada indica espaço para melhorias.',
    'SessionReports.ToolTip.SelfHeaderYours': 'Esta coluna indica sua própria avaliação do desempenho da simulação. Uma marca de seleção indica que você pensou que teve sucesso, enquanto uma marca cruzada indica que há espaço para melhorias.',
    'SessionReports.ToolTip.SelfHeaderLearners': 'Esta coluna indica a avaliação do aluno sobre seu desempenho na simulação. Uma marca de verificação indica que o Aluno pensou que teve sucesso, enquanto uma marca cruzada indica que há espaço para melhorias.',
    'SessionReports.ToolTip.VideoLink': 'Você pode compartilhar um link para seu vídeo de desempenho de simulação com seus colegas. Basta inserir o e-mail na caixa pop-up para fazer isso. Observe que suas análises não serão compartilhadas com seus colegas.',
    'SessionReports.ToolTip.SmartMetrics': 'As métricas inteligentes são geradas por meio do uso de algoritmos avançados. Observe essas medidas geradas por computador para ver se elas têm algum impacto em seu rastreamento geral durante várias simulações.',
    'SessionReports.ToolTip.MiScore': 'A pontuação do Mursion Index (MI) é um indicador de sua eficácia social durante essa conversa. Ele é calculado usando vários dados, incluindo seu padrão de tomada de turnos de conversação e seu impacto no(s) avatar(es). Tente avaliar seu impacto sobre o(s) avatar(es) e abordar as conversas de maneiras diferentes para ver como você pode impactar positivamente sua pontuação de MI.',
    'SessionReports.ToolTip.ListeningSpeakingTime': 'Este é um indicador que mostra a proporção do tempo que você gastou falando e ouvindo durante a conversa.',
    'SessionReports.ToolTip.Emotions': 'Emoções (Beta): no momento, estamos investigando algoritmos que nos permitem interpretar seus padrões de voz para determinar o sentimento geral. Observe esses dados para ver se você teve uma conotação positiva, negativa ou neutra em seus padrões vocais ao longo do tempo. Acima de tudo, observe o impacto que você teve nos padrões vocais dos avatares.',
    'SessionReports.ToolTip.TurnTracking': 'A tomada de turnos é um conjunto de medidas que revelam a natureza colaborativa / eficácia social entre você e os avatares.',
    'SessionReports.ToolTip.Pauses': 'As pausas são uma medida indireta de sua fluência durante a conversa.',
    'SessionReports.ToolTip.ArticulationRate': 'Esta é uma medida do ritmo em que um conjunto contínuo de informações é fornecido por você.',
    'SessionReports.TableHeader.Outcome': 'Resultado',
    'SessionReports.TableHeader.Self': 'Self',
    'SessionReports.TableHeader.Avatars': 'Avatar(es)',
    'SessionReports.TableHeader.Strategies': 'Estratégias',
    'MursionPortal.Button.Cancel': 'Cancelar',
    'Settings.Config.Heading.Industries': 'INDÚSTRIAS',
    'Settings.Config.Button.New': 'Nova',
    'Settings.Config.Note': 'NOTA:',
    'Settings.Config.Note.Text.ConfigSettingsGlobal': 'As configurações de configuração são globais. As alterações serão aplicadas a todos os clientes e usuários.',
    'Settings.Config.Heading.SimSpecialist': 'NÍVEIS DE ESPECIALISTAS EM SIM',
    'Settings.Config.Heading.Scheduling': 'AGENDAMENTO',
    'Settings.Config.Heading.Scheduling.Description': '(Especialista em Sim)',
    'Settings.Config.Scheduling.MaximumFullTimeSimSpecialistHours': 'Máximo de horas de especialista em Sim em período integral',
    'Settings.Config.Scheduling.MaximumPartTimeSimSpecialistHours': 'Máximo de horas de especialista em Sim em meio período',
    'Settings.Config.Scheduling.MaximumContinuousSimSpecialistHours': 'Máximo de horas contínuas de especialista em Sim',
    'Settings.Config.Scheduling.LengthOfSimSpecialistRequiredBreak': 'Duração do intervalo obrigatório do Especialista em Sim',
    'Settings.Config.Scheduling.FutureAvailabilityForSimSpecialist': 'Janela de disponibilidade futura para Especialistas em Sim',
    'Settings.Config.Scheduling.SimSpecialistShiftTime': 'Horário de turno do especialista em Sim',
    'Settings.Config.Scheduling.AvailableTimeBlockMaximumPerDay': 'Tempo de disponibilidade máximo de bloco de horas por dia',
    'Settings.Config.Scheduling.AvailableTimeBlockMinimumPerDay': 'Bloco de horas de disponibilidade mínimo por dia',
    'Settings.Config.Scheduling.AvailabilityTimeStep': 'Etapa de tempo de disponibilidade',
    'Settings.Config.Scheduling.AdvanceSSScheduleConfirmation': 'Com que antecedência o agendamento de SS deve ser confirmada',
    'Settings.Config.Scheduling.RequestUnfulfilledReminder': 'Quando enviar lembrete de "solicitação não atendida"',
    'Settings.Config.Scheduling.FirstEmailReminder': 'Quando enviar o primeiro lembrete por e-mail',
    'Settings.Config.Scheduling.SecondEmailReminder': 'Quando enviar um segundo lembrete por e-mail',
    'Settings.Config.Scheduling.BookedSessionWithNoAssignedLearner': 'Quando notificar sobre a Sessão reservada sem nenhum aluno designado',
    'Settings.Config.Scheduling.UnfulfilledSessionSwapRequestReminder': 'Quando enviar um lembrete sobre a solicitação de troca de sessão não cumprida',
    'Settings.Config.Scheduling.NextSessionReminder': 'Quando enviar o lembrete da próxima sessão, dependendo do tempo entre a criação da sessão e a hora de início da sessão agendada',
    'Settings.Config.Scheduling.ReservedAvailabilityExpired': 'Quando a disponibilidade reservada expirar',
    'Settings.Config.Scheduling.StartTimeForConsideringMissedSession': 'Período de tempo após o horário de início em que uma sessão é considerada \"Perdida\"',
    'Settings.Config.Scheduling.EndTimeForConsideringMissedSession': 'Período de tempo após o horário de término em que uma sessão é considerada \"Perdida\"',
    'Settings.Config.Scheduling.RejoinSessionAfterError': 'O tempo que os participantes da sessão têm para retornar à sessão após um erro',
    'Settings.Config.Scheduling.TimeDisabledForSessionScheduling': 'Período de tempo desabilitado para agendamento de sessão',
    'Settings.Config.Scheduling.SessionTimeStep': 'Etapa de tempo da Sessão',
    'Settings.Config.Heading.ProjectAndScenario': 'PROJETO E CENÁRIO',
    'Settings.Config.ProjectAndScenario.MaximumProjectTimelineDuration': 'Duração máxima do cronograma do projeto',
    'Settings.Config.ProjectAndScenario.MinimumProjectTimelineDuration': 'Duração mínima do cronograma do projeto',
    'Settings.Config.ProjectAndScenario.MaximumScenarioTimelineDuration': 'Duração máxima do cronograma do cenário',
    'Settings.Config.ProjectAndScenario.MinimumScenarioTimelineDuration': 'Duração mínima do cronograma do cenário',
    'Settings.Config.ProjectAndScenario.MinimumSessionLength': 'Duração mínima da sessão',
    'Settings.Config.ProjectAndScenario.MaximumSessionLength': 'Duração máxima da sessão',
    'Settings.Config.ProjectAndScenario.MinimumSimulationLength': 'Duração mínima da simulação',
    'Settings.Config.ProjectAndScenario.SessionLengthStep': 'Etapa da duração da sessão',
    'Settings.Config.ProjectAndScenario.MinimumLearnersInRemoteSession': 'Número mínimo de alunos em sessões remotas',
    'Settings.Config.ProjectAndScenario.MaximumLearnersInRemoteSession': 'Número máximo de alunos em sessões remotas',
    'Settings.Config.ProjectAndScenario.MinimumLearnersInFishbowlSession': 'Número mínimo de alunos nas sessões de fishbowl local',
    'Settings.Config.ProjectAndScenario.MaximumLearnersInFishbowlSession': 'Número máximo de alunos nas sessões de fishbowl local',
    'Settings.Config.ProjectAndScenario.MinimumLearnersInGroupSession': 'Número mínimo de alunos em sessões de grupo',
    'Settings.Config.ProjectAndScenario.MaximumLearnersInGroupSession': 'Número máximo de alunos em sessões de grupo',
    'Settings.Config.ProjectAndScenario.AllowedScenarioVersions': 'Versões de cenário permitidas',
    'Settings.Config.ProjectAndScenario.DefaultScenarioVersion': 'Versão de cenário padrão',
    'Settings.Config.Heading.CompanyEvent': 'EVENTO DA EMPRESA',
    'Settings.Config.CompanyEvent.MaximumEventDuration': 'Duração máxima do evento',
    'Settings.Config.CompanyEvent.MinimumEventDuration': 'Duração mínima do evento',
    'Settings.Config.CompanyEvent.EventTimeStep': 'Etapa do horário do Evento',
    'Settings.Config.Heading.SessionReport': 'RELATÓRIO DE SESSÃO',
    'Settings.Config.SessionReport.ShowSessionReport': 'Exibir relatório de sessão',
    'Settings.Config.Heading.FeedbackProvider': 'FORNECEDOR DE FEEDBACK',
    'Settings.Config.FeedbackProvider.ZenDeskSnippetKey': 'Chave de snippet ZenDesk',
    'Settings.Config.Heading.SimulationContent': 'CONTEÚDO DE SIMULAÇÃO',
    'Settings.Config.SimulationContent.SimulationContentFolder': 'Pasta Simulation Content S3',
    'Settings.Config.Heading.Invitation': 'CONVITE',
    'Settings.Config.Invitation.ExpireUserRegistrationInvitation': 'Quando expirar o convite de registro do usuário',
    'Settings.Config.Invitation.ReminderToAcceptRegistrationInvitation': 'Quando lembrar os usuários não registrados para aceitar o convite de registro',
    'Settings.Config.Invitation.RegistrationInvitationExpiresAfter': 'O convite de registro expira após',
    'Settings.Config.IndustriesButton.Modal.EditIndustry': 'Editar Indústria',
    'Settings.Config.NewButton.Modal.NewIndustry': 'Nova Indústria',
    'Settings.Modal.Placeholder.IndustryName': 'Nome da Indústria',
    'Settings.Config.IndustriesButton.Modal.Button.UpdateIndustry': 'Atualizar Indústria',
    'Settings.Config.NewButton.Modal.Button.CreateIndustry': 'Criar Indústria',
    'Settings.Config.IndustriesButton.Modal.Button.RestoreIndustry': 'Restaurar Indústria',
    'Settings.Config.IndustriesButton.Modal.Button.ArchiveIndustry': 'Arquivar Indústria',
    'MursionPortal.Modal.Heading.Name': 'Nome',
    'Settings.Config.RestoreIndustry.Modal.ConfirmRestore': 'Tem certeza que deseja restaurar esta indústria?',
    'Settings.Config.ArchiveIndustry.Modal.ConfirmArchive': 'Tem certeza de que deseja arquivar esta indústria?',
    'Settings.Config.Description.PerWeek': '(por semana)',
    'Settings.Config.Description.PerDay': '(por dia)',
    'Settings.Config.Description.BeforeBreak': '(antes do intervalo)',
    'Settings.Config.Description.Minutes': '(minutos)',
    'Settings.Config.Description.Days': '(dias)',
    'Settings.Config.Description.Hours': '(horas)',
    'Settings.Config.TierButton.Modal.Button.UpdateTier': 'Atualizar Nível',
    'Settings.Config.TierButton.Modal.Heading.EditTier': 'Editar Nível',
    'Settings.Config.TierButton.Modal.Placeholder.TierName': 'Nome do Nível',
    'MursionPortal.Button.Archive': 'Arquivar',
    'Settings.Config.ArchiveIndustry.Modal.Heading.ConfirmArchive': 'Confirmar arquivação',
    'Settings.Config': 'Config.',
    'Settings.ScenarioBank': 'Banco de Cenários',
    'Settings.Environments': 'Ambientes (ML2)',
    'Settings.Avatars': 'Avatares (ML2)',
    'Settings.SSO': 'SSO',
    'Settings.Error.NoSettingsAvailable': 'Nenhuma configuração disponível',
    'MursionPortal.Error.SomethingWentWrong': 'Desculpe, algo deu errado.',
    'MursionPortal.Error.ReloadingPage': 'Tente recarregar a página. O erro foi relatado.',
    'MursionPortal.Error.WorkingHard': 'Estamos trabalhando muito para consertá-lo o mais rápido possível.',
    'MursionPortal.Error.SupportAvailable': 'O suporte está disponível de segunda a sexta-feira',
    'MursionPortal.Error.TechnicalAssistance': 'Se você precisar de assistência técnica, entre em contato com o Suporte Mursion',
    'MursionPortal.At': 'em',
    'MursionPortal.Or': 'ou',
    'Settings.Environment.Modal.PlaceHolder.EnvironmentName': 'Nome do Ambiente',
    'Settings.Environment.Modal.Description': 'Descrição',
    'Settings.Environment.Modal.Industry': 'Indústria',
    'Settings.Environment.Modal.Picture': 'Imagem',
    'MursionPortal.Button.Delete': 'Excluir',
    'Settings.Environment.Modal.Button.UploadFile': 'Carregar arquivo',
    'Settings.Environment.Button.CreateEnvironment': 'Criar Ambiente ',
    'Settings.Environment.ModalHeader.NewEnvironment': 'Novo Ambiente',
    'Settings.Environment.ModalHeader.Environment': 'Ambiente',
    'Settings.Environment.ModalHeader.Archived': '(Arquivado)',
    'Settings.Environment.ModalHeader.EditEnvironment': 'Editar Ambiente',
    'Settings.Environment.Modal.Button.CreateEnvironment': 'Criar Ambiente',
    'Settings.Environment.Modal.Button.UpdateEnvironment': 'Atualizar ambiente',
    'Settings.Environment.Modal.ArchiveEnvironmentText': 'Tem certeza de que deseja arquivar este ambiente?',
    'Settings.Environment.Modal.RestoreEnvironmentText': 'Tem certeza que deseja restaurar este ambiente?',
    'MursionPortal.Button.Restore': 'Restaurar',
    'MursionPortal.Total': 'TOTAL',
    'MursionPortal.Text.Total': 'TOTAL',
    'MursionPortal.Button.ExportToEmail.HoverTitle.ExportData': 'Exportar dados',
    'MursionPortal.Button.ExportToEmail.HoverTitle.ExportNotAvailable': 'A exportação não está disponível',
    'MursionPortal.Button.ExportToEmail': 'Exportar para o e-mail',
    'MursionPortal.WarningMessage.RequestNotImplemented': 'A solicitação de dados ainda não foi implementada',
    'MursionPortal.Table.ColumnHeader.Client': 'Cliente',
    'MursionPortal.Table.ColumnHeader.Provider': 'Fornecedor',
    'MursionPortal.Table.ColumnHeader.Project': 'Projeto',
    'MursionPortal.Table.ColumnHeader.Scenario': 'Cenário',
    'MursionPortal.Table.ColumnHeader.SoftwareVersion': 'Versão do Software',
    'MursionPortal.Table.ColumnHeader.DeliveryMode': 'Modalidade de entrega',
    'MursionPortal.Table.ColumnHeader.SessionStart': 'Início da sessão',
    'MursionPortal.Table.ColumnHeader.SessionEnd': 'Final da sessão',
    'MursionPortal.Table.ColumnHeader.LearnerStatus': 'Status do Aluno',
    'MursionPortal.Table.ColumnHeader.SessionStatus': 'Status da sessão',
    'MursionPortal.Table.ColumnHeader.LearnerName': 'Nome do aluno',
    'MursionPortal.Table.ColumnHeader.LearnerEmail': 'Email do aluno',
    'MursionPortal.Table.LearnerEmail.HoverTitle.CopyEmail': 'Copiar e-mail',
    'MursionPortal.Table.LearnerEmail.HoverTitle.UserEmail': 'E-mail de usuário',
    'MursionPortal.Table.ColumnHeader.SessionId': 'ID da Sessão',
    'MursionPortal.Table.SessionId.HoverTitle.CopySessionId': 'Copiar ID da Sessão',
    'MursionPortal.Table.ColumnHeader.SessionReport': 'Relatório da Sessão',
    'MursionPortal.Table.ColumnHeader.SimSpecialistName': 'Nome do Especialista em Sim',
    'MursionPortal.Table.ColumnHeader.LearnerTimeSpent': 'Tempo gasto pelo aluno',
    'MursionPortal.Table.HoverTitle.SessionSummary': 'Resumo da Sessão',
    'MursionPortal.EmptyMessage.NoSessionsYet': 'Ainda não há sessões',
    'MursionPortal.Button.ViewReport': 'Visualizar Relatório',
    'Mursion.Portal.Status.Requested': 'Solicitado',
    'Mursion.Portal.Status.Pending': 'Pendente',
    'Mursion.Portal.Status.PendingSwap': 'Pendente (troca)',
    'Mursion.Portal.Status.PreBooked': 'Pré-reservado',
    'Mursion.Portal.Status.Booked': 'Agendado',
    'Mursion.Portal.Status.Active': 'Ativo',
    'Mursion.Portal.Status.Complete': 'Concluído',
    'Mursion.Portal.Status.Error': 'Erro',
    'Mursion.Portal.Status.Missed': 'Faltou',
    'Mursion.Portal.Status.NeedsReview': 'Precisa de revisão',
    'Mursion.Portal.Status.Cancelled': 'Cancelado',
    'Dashboard.UsersActivity.Filter.Roles.Placeholder': 'Pesquisar…',
    'Settings.ScenarioBank.Table.ColumnHeader.Name': 'Nome',
    'MursionPortal.DeliveryMode': 'Modalidade de entrega',
    'Settings.ScenarioBank.Table.ColumnHeader.SessionSize': 'Tamanho da sessão',
    'MursionPortal.SessionLength': 'Duração da sessão',
    'Settings.ScenarioBank.Table.ViewEditScenario': 'Ver / Editar Cenário',
    'Settings.ScenarioBank.Table.ThereAreNoScenarios': 'Ainda não há cenários',
    'MursionPortal.Capitalized.Total': 'TOTAL',
    'Settings.ScenarioBank.Form.NewScenario': 'Novo cenário',
    'Settings.ScenarioBank.Form.Scenario': 'Cenário',
    'Settings.ScenarioBank.Form.EditScenario': 'Editar Cenário',
    'Settings.ScenarioBank.Form.Save': 'Salvar',
    'Settings.ScenarioBank.Form.ConfirmArchiveScenario': 'Tem certeza de que deseja arquivar este cenário?',
    'Settings.ScenarioBank.Form.ConfirmRestoreScenario': 'Tem certeza que deseja restaurar este cenário?',
    'Settings.ScenarioBank.Button.CreateScenario': 'Criar cenário ',
    'MursionPortal.ShortLabel.Minutes': 'min',
    'Settings.ScenarioBank.Modal.Scenarios': 'Cenário(s)',
    'Settings.ScenarioBank.Modal.CompleteProjectInfoFirst': 'Preencha as informações do projeto primeiro',
    'Settings.ScenarioBank.Modal.ScenarioName': 'Nome do Cenário',
    'Settings.ScenarioBank.Modal.Placeholder.EnterScenarioName': 'Inserir nome do cenário',
    'Settings.ScenarioBank.Modal.Outcome': 'Resultado',
    'Settings.ScenarioBank.Modal.Strategies': 'Estratégias',
    'Settings.ScenarioBank.Modal.Attachments': 'Anexos',
    'Settings.ScenarioBank.Modal.SoftwareVersion': 'Versão do software',
    'Settings.ScenarioBank.Modal.WeAlreadyHaveSessions': 'Já temos sessões para este cenário',
    'Settings.ScenarioBank.Modal.SelectDeliveryMode': 'Selecione um modo de entrega',
    'Settings.ScenarioBank.Modal.ShouldBeAtLeast': 'Deve ser pelo menos {minutes} minutos',
    'Settings.ScenarioBank.Modal.SelectSessionLength': 'Selecione a duração da sessão',
    'Settings.ScenarioBank.Modal.SessionSize': 'Tamanho da sessão',
    'Settings.ScenarioBank.Modal.Learner': 'Aluno',
    'Settings.ScenarioBank.Modal.Learners': 'Alunos',
    'Settings.ScenarioBank.Modal.SelectSessionSize': 'Selecione o tamanho de sessão',
    'MursionPortal.SimulationContent': 'Conteúdo de Simulação',
    'Settings.ScenarioBank.Modal.ToolTip.ThisIsTheArtContent': 'Este é o conteúdo de arte 3D de ambientes e avatares que serão usados na simulação. O conteúdo desta pasta é exibido na seção intitulada "pacote de conteúdo" abaixo.',
    'Settings.ScenarioBank.Modal.ThisScenarioHasNoSimulation': 'O cenário não tem conteúdo de simulação atribuído',
    'Settings.ScenarioBank.Modal.SelectSimulationContentForUse': 'Selecione o conteúdo da simulação para uso',
    'Settings.ScenarioBank.Modal.Label.ScenarioOutcome': 'Resultado do cenário',
    'Settings.ScenarioBank.Modal.Placeholder.EnterAnOutcome': 'Insira um resultado',
    'Settings.ScenarioBank.Modal.Description': 'Descrição',
    'Settings.ScenarioBank.Modal.NoDescriptionSpecified': 'Nenhuma descrição especificada',
    'Settings.ScenarioBank.Modal.ScenarioDescription': 'Descrição do cenário',
    'Settings.ScenarioBank.Modal.AllowedCharactersLimit': 'O limite de caracteres permitido ({maxLength} caracteres) foi excedido',
    'Settings.ScenarioBank.Modal.ScenarioStrategies': 'Estratégias de Cenário',
    'Settings.ScenarioBank.Modal.Placeholder.EnterAnItem': 'Digite um item',
    'Settings.ScenarioBank.Modal.Attachments.DragAndDrop': 'Arraste e solte alguns arquivos aqui ou clique para selecionar arquivos',
    'Settings.ScenarioBank.Modal.Attachments.UploadedMaximumFiles': 'Você carregou o número máximo disponível de arquivos {maxFiles}.',
    'Settings.ScenarioBank.Modal.Attachments.UploadedFiles': 'Arquivo(s) enviado(s),',
    'Settings.ScenarioBank.Modal.CertifiedSimSpecialists': 'Especialistas certificados em Sim',
    'Settings.ScenarioBank.Modal.NoCertifiedSimSpecialist': 'Nenhum especialista certificado em Sim',
    'Settings.ScenarioBank.Modal.Button.Add': 'Adicionar ',
    'Settings.ScenarioBank.Modal.Edit': 'editar',
    'Settings.ScenarioBank.Modal.AddAttachmentsToScenario': 'Adicionar anexos ao cenário',
    'MursionPortal.Cancel': 'Cancelar',
    'MursionPortal.OK': 'Ok',
    'Settings.ScenarioBank.Modal.FileFormatsSupported': 'Os seguintes formatos são compatíveis:',
    'Settings.ScenarioBank.Modal.Images': 'Imagens',
    'Settings.ScenarioBank.Modal.Videos': 'Vídeos',
    'Settings.ScenarioBank.Modal.Audios': 'Áudios',
    'Settings.ScenarioBank.Modal.Documents': 'Documentos',
    'Settings.ScenarioBank.Modal.New': '(novo)',
    'Settings.ScenarioBank.Modal.FileSizeMustNotExceed': 'O tamanho do arquivo não deve exceder {size}',
    'Settings.ScenarioBank.Modal.CertifySimSpecialists': 'Certificar especialistas em Sim',
    'MursionPortal.Confirm': 'Confirmar',
    'MursionPortal.Delete': 'Excluir',
    'Settings.ScenarioBank.Modal.Archived': '(Arquivado)',
    'Settings.ScenarioBank.Modal.Restore': 'Restaurar',
    'Settings.ScenarioBank.Modal.ConfirmArchive': 'Confirmar arquivação',
    'Settings.ScenarioBank.Modal.Archive': 'Arquivar',
    'Settings.ScenarioBank.Modal.ConfirmRestore': 'Confirmar restauração',
    'MursionPortal.PleaseWait': 'Aguarde...',
    'MursionPortal.Loading': 'Carregando...',
    'Settings.ScenarioBank.Modal.Attachments.FileTypeNotSupported': 'O tipo de arquivo {extensions} não é compatível',
    'Settings.ScenarioBank.Modal.Attachments.FileTypesNotSupported': 'Os tipos de arquivo {extensions} não são compatíveis',
    'Settings.ScenarioBank.Modal.Attachments.FileExceedsSizeLimit': 'O arquivo {fileNames} excede o limite de tamanho',
    'Settings.ScenarioBank.Modal.Attachments.FilesExceedSizeLimit': 'Os arquivos {fileNames} excedem o limite de tamanho',
    'MursionPortal.Label.Total': 'TOTAL',
    'MursionPortal.Button.HoverTitle.ExportData': 'Exportar dados',
    'MursionPortal.Button.HoverTitle.ExportNotAvailable': 'A exportação não está disponível',
    'MursionPortal.Heading.Filters': 'FILTROS:',
    'Dashboard.ProjectsTable.Checkbox.OnlyBillable': 'Apenas Faturável',
    'MursionPortal.Filters.SoftwareVersion': 'Versão do software',
    'MursionPortal.Filters.PlaceHolder.SearchVersion': 'Pesquisar versão...',
    'MursionPortal.Filters.Status': 'Status',
    'MursionPortal.Filters.PlaceHolder.SearchStatus': 'Pesquisar status...',
    'Dashboard.ProjectsTable.ColumnHeader.Provider': 'Fornecedor',
    'Dashboard.ProjectsTable.ColumnHeader.Status': 'Status',
    'Dashboard.ProjectsTable.ColumnHeader.StarAt': 'Inicia às',
    'Dashboard.ProjectsTable.ColumnHeader.EndAt': 'Termina às',
    'Dashboard.ProjectsTable.ColumnHeader.Billable': 'Faturável',
    'Dashboard.ProjectsTable.ColumnHeader.CancellationWindow': 'Janela de cancelamento',
    'MursionPortal.Table.ColumnHeader.Scenarios': 'Cenários',
    'MursionPortal.Table.ColumnHeader.Teams': 'Equipes',
    'MursionPortal.Table.ColumnHeader.SessionsScheduled': 'Sessões agendadas',
    'MursionPortal.Table.ColumnHeader.SessionsCompleted': 'Sessões concluídas',
    'MursionPortal.Table.ColumnHeader.SessionsMissed': 'Sessões perdidas',
    'MursionPortal.Table.ColumnHeader.LateCancellations': 'Cancelamentos tardios',
    'MursionPortal.Table.ColumnHeader.Learners': 'Alunos',
    'Dashboard.ProjectsTable.ColumnHeader.SessionsRecorded': 'Sessões gravadas',
    'MursionPortal.Table.ColumnHeader.SessionsError': 'Sessões com erro',
    'Dashboard.ProjectsTable.ColumnHeader.ContractedHours': 'Horas contratadas',
    'Dashboard.ProjectsTable.ColumnHeader.UtilizedHours': 'Horas utilizadas',
    'Dashboard.ProjectsTable.ColumnHeader.RemainingHours': 'Horas restantes',
    'Dashboard.ProjectsTable.ColumnHeader.CompletedHours': 'Horas concluídas',
    'Dashboard.ProjectsTable.ColumnHeader.MissedHours': 'Horas perdidas',
    'Dashboard.ProjectsTable.HoverTitle.ViewEditProject': 'Ver / Editar Projeto',
    'Dashboard.ProjectsTable.EmptyMessage.NoProjectsYet': 'Não há projetos ainda',
    'MursionPortal.Button.Clear': 'Limpar',
    'Dashboard.Projects.AlertMessage.DataRequestNotImplemented': 'A solicitação de dados ainda não foi implementada',
    'MursionPortal.SelectorMenu.SelectedCountOfTotal': 'Selecionado {formattedSelectedCount} de {formattedTotal}',
    'MursionPortal.Dashboard.Table.Modal.Filter.Teams': 'Equipes',
    'MursionPortal.Dashboard.Table.Modal.Placeholder.EnterTeamName': 'Insira o nome da equipe',
    'MursionPortal.Dashboard.Table.Modal.TeamsEmptyMessage': 'Nenhuma equipe foi encontrada',
    'MursionPortal.Dashboard.Table.Modal.Filter.NumberOfLearners': 'nº de alunos',
    'MursionPortal.Dashboard.Table.Modal.Filter.Scenarios': 'Cenários',
    'MursionPortal.Dashboard.Table.Modal.Placeholder.EnterScenarioName': 'Inserir nome do cenário',
    'MursionPortal.Dashboard.Table.Modal.ScenariosEmptyMessage': 'Nenhum cenário foi encontrado',
    'MursionPortal.Dashboard.Table.Modal.Filter.Name': 'Nome',
    'MursionPortal.Dashboard.Table.Modal.Filter.Status': 'Status',
    'MursionPortal.Dashboard.Table.Modal.Filter.DeliveryMode': 'Modalidade de entrega',
    'MursionPortal.Dashboard.Table.Modal.Filter.Learners': 'Alunos',
    'MursionPortal.Dashboard.Table.Modal.Placeholder.EnterLearnerName': 'Digite o nome do aluno',
    'MursionPortal.Dashboard.Table.Modal.LearnersEmptyMessage': 'Nenhum aluno foi encontrado',
    'MursionPortal.Dashboard.Table.Modal.Filter.SessionsScheduled': 'Sessões agendadas',
    'MursionPortal.Dashboard.Table.Modal.SessionsEmptyMessage': 'Nenhuma sessão foi encontrada',
    'MursionPortal.Dashboard.Table.Modal.Filter.ScenarioName': 'Nome do Cenário',
    'MursionPortal.Dashboard.Table.Modal.Filter.SessionDateTime': 'Data/hora da sessão',
    'MursionPortal.Dashboard.Table.Modal.Filter.SessionsCompleted': 'Sessões concluídas',
    'MursionPortal.Dashboard.Table.Modal.Filter.SessionsError': 'Sessões com erro',
    'Dashboard.ProjectsActivityTable.Modal.Filter.SessionsRecorded': 'Sessões gravadas',
    'MursionPortal.Dashboard.Table.Modal.Filter.SessionsMissed': 'Sessões perdidas',
    'MursionPortal.Dashboard.Table.Modal.Filter.LateCancellations': 'Cancelamentos "tardios"',
    'MursionPortal.Dashboard.Table.Modal.Filter.CancelledBy': 'Cancelado por',
    'MursionPortal.Dashboard.Table.Modal.Filter.DateScheduled': 'Data de agendamento',
    'MursionPortal.Dashboard.Table.Modal.Filter.DateCancelled': 'Data de cancelamento',
    'MursionPortal.ProjectStatus.Active': 'Ativo',
    'MursionPortal.ProjectStatus.Completed': 'Concluído',
    'MursionPortal.ProjectStatus.Archived': 'Arquivado',
    'Settings.Avatars.Modal.Name': 'Nome',
    'Settings.Avatars.Modal.PlaceHolder.AvatarName': 'Nome do Avatar',
    'Settings.Avatars.Modal.Description': 'Descrição',
    'MursionPortal.Name': 'Nome',
    'MursionPortal.Description': 'Descrição',
    'MursionPortal.Environments': 'Ambientes',
    'Settings.Avatars.TableHover.ViewEditAvatar': 'Ver/Editar Avatar',
    'Settings.Avatars.NoAvatarText': 'Ainda não há avatares',
    'Settings.Avatars.ModalHeader.NewAvatar': 'Novo avatar',
    'Settings.Avatars.ModalHeader.Avatar': 'Avatar',
    'Settings.Avatars.ModalHeader.EditAvatar': 'Editar Avatar',
    'Settings.Avatars.Modal.Button.CreateAvatar': 'Criar Avatar',
    'Settings.Avatars.Modal.Button.UpdateAvatar': 'Atualizar Avatar',
    'Settings.Avatars.Modal.ArchiveAvatarText': 'Tem certeza de que deseja arquivar este avatar?',
    'Settings.Avatars.Modal.RestoreAvatarText': 'Tem certeza que deseja restaurar este avatar?',
    'Settings.Avatars.Modal.Button.Edit': 'editar',
    'Settings.Avatars.Modal.EditButton.Accept': 'Aceitar',
    'Settings.Avatars.Modal.NoSelectedEnvironments': 'Nenhum ambiente selecionado',
    'Settings.Avatars.Modal.NoEnvironmentsSetForSelectedIndustry': 'Nenhum ambiente definido para a indústria selecionada.',
    'Settings.Avatars.Button.CreateAvatar': 'Criar Avatar ',
    'MursionPortal.Dashboard': 'Painel',
    'MursionPortal.Tutorial': 'Tutorial',
    'MursionPortal.Eula': 'Eula',
    'MursionPortal.Companies': 'Empresas',
    'MursionPortal.SessionFlow': 'Fluxo de aulas',
    'MursionPortal.SessionReport': 'Relatório da Sessão',
    'MursionPortal.AssetSync': 'Sincronização de ativos',
    'MursionPortal.Users': 'Usuários',
    'MursionPortal.AppDownload': 'Download do App',
    'MursionPortal.Calendar': 'Calendário',
    'MursionPortal.Clients': 'Clientes',
    'MursionPortal.Sessions': 'Sessões',
    'MursionPortal.Trainings': 'Treinamentos',
    'MursionPortal.Requests': 'Solicitações',
    'MursionPortal.Settings': 'Configurações',
    'MursionPortal.ArtDesigner': 'Art Designer',
    'MursionPortal.ArtDesigner.BodyContents': 'We\'ve opened a new browser tab with the Mursion Designer application.',
    'MursionPortal.Projects': 'Projetos',
    'MursionPortal.Teams': 'Equipes',
    'MursionPortal.Reports': 'Relatórios',
    'MursionPortal.Downloads': 'Downloads',
    'MursionPortal.Upcoming': 'em breve',
    'MursionPortal.Auth': 'OAuth',
    'Settings.Avatars.Modal.ErrorMessage.ItemsNotAvailable': 'Até {max} itens disponíveis para escolha',
    'Settings.Avatars.Modal.ErrorMessage.ItemNotAvailable': 'Até {max} item disponível para escolha',
    'MursionPortal.Industry': 'Indústria',
    'Settings.Environment.TableHover.ViewEditEnvironment': 'Ver/Editar Ambiente',
    'Settings.Environment.NoEnvironmentText': 'Ainda não há ambientes',
    'Projects.Button.CreateProject': 'Criar projeto ',
    'Projects.ListHeader.CreationDate': 'Data de criação',
    'Projects.ListHeader.ProjectManagers': 'Gerentes de projeto',
    'MursionPortal.Capitalized.Active': 'ATIVO',
    'MursionPortal.Capitalized.Completed': 'CONCLUÍDO',
    'MursionPortal.Capitalized.Archived': 'ARQUIVADO',
    'MursionPortal.Capitalized.All': 'TODOS',
    'Projects.ProjectCard.BackToClient': 'Voltar para Cliente',
    'Projects.ProjectCard.NoProjectsFound': 'Nenhum projeto encontrado',
    'Projects.ProjectCard.PlaceHolder.EnterProjectName': 'Inserir nome do projeto',
    'Projects.ProjectCard.ProjectsList': 'LISTA DE PROJETOS',
    'MursionPortal.Capitalized.Project': 'PROJETO',
    'MursionPortal.Scenarios': 'Cenários',
    'Projects.ScenarioList.ThereAreNoScenarios': 'Ainda não há cenários.',
    'Projects.ScenarioList.NoScenariosFound': 'Nenhum cenário foi encontrado.',
    'Projects.ScenarioList.Button.Draft': 'Rascunho',
    'MursionPortal.Finalized': 'Finalizado',
    'MursionPortal.Started': 'Iniciado',
    'MursionPortal.Finished': 'Concluído',
    'MursionPortal.Archived': 'Arquivado',
    'MursionPortal.EnterScenarioName': 'Inserir nome do cenário',
    'Projects.AllProjects': 'Todos os Projetos',
    'MursionPortal.Label.LowerCase.Archived': 'arquivado',
    'MursionPortal.Label.Active': 'Ativo',
    'MursionPortal.Label.Completed': 'Concluído',
    'Projects.Tab.Basic': 'Básico',
    'MursionPortal.Heading.ProjectName': 'Nome do Projeto',
    'MursionPortal.Label.Created': 'Criado',
    'MursionPortal.Label.Status': 'Status',
    'MursionPortal.Label.Learners': 'Alunos',
    'MursionPortal.Label.LearnersCount': '{count} alunos',
    'Projects.Tab.NoLearners': 'Sem alunos',
    'MursionPortal.Label.SoftwareVersion': 'Versão do Software',
    'Projects.ProjectInfo.Label.Non-Billable': 'Não Faturável',
    'Projects.ProjectInfo.Label.Contracted': 'Contratado',
    'Projects.ProjectInfo.Label.CancellationWindow': 'Janela de cancelamento',
    'MursionPortal.Header.Recording': 'Gravação',
    'Projects.ProjectInfo.Checkbox.AllowRecording': 'Permitir gravação',
    'MursionPortal.Label.VideoRecording': 'Gravação de Vídeo',
    'MursionPortal.Label.Allowed': 'Permitido',
    'MursionPortal.Label.Disallowed': 'Proibido',
    'MursionPortal.Label.RecordedVideosGoBackTo': 'Vídeos gravados vão para:',
    'MursionPortal.Checkbox.RecordingPermissionLiesWithLearner': 'Cabe ao aluno a permissão de gravação',
    'MursionPortal.Label.LiesWithLearner': 'Cabe ao aluno',
    'Projects.ProjectInfo.Label.Enabled': 'Ativado',
    'Projects.ProjectInfo.Label.Disabled': 'Desativado',
    'MursionPortal.Heading.ProjectManagers': 'Gerentes de projeto',
    'MursionPortal.Heading.Tags': 'Tags',
    'MursionPortal.Button.AreYouSureEditProject': 'Tem certeza que deseja editar este projeto?',
    'MursionPortal.Button.SaveProjectInfo': 'Salvar detalhes do projeto',
    'Projects.Tab.Utilization': 'Utilização',
    'MursionPortal.Label.Loading': 'Carregando...',
    'Projects.ProjectInfo.Label.NoProjectManagers': 'Sem gerentes de projeto',
    'Projects.ProjectInfo.Label.NoSelectedProjectManagers': 'Nenhum gerente de projeto selecionado',
    'MursionPortal.Label.ProjectManagers': 'Gerentes de projeto',
    'MursionPortal.Label.RecordingPermission': 'Permissão de gravação:',
    'Projects.Utilization.Label.UtilizedSessions': 'Sessões utilizadas:',
    'Projects.Utilization.Label.UtilizedMissedSessions': 'Sessões perdidas utilizadas:',
    'Projects.Utilization.Label.UtilizedCancelledSessions': 'Sessões canceladas utilizadas:',
    'Projects.Utilization.Label.UtilizedDuration': 'Duração utilizada:',
    'Projects.Utilization.Label.RemainingDuration': 'Duração restante:',
    'MursionPortal.ScenarioListItem.Label.Timeline': 'Linha do tempo',
    'MursionPortal.ScenarioListItem.Label.SessionLength': 'Duração da sessão',
    'MursionPortal.ScenarioListItem.Label.SessionSize': 'Tamanho da sessão',
    'MursionPortal.ScenarioListItem.Label.DeliveryMode': 'Modo de entrega',
    'MursionPortal.ScenarioCard.BackToProject': 'Voltar para Projeto',
    'MursionPortal.ScenarioCard.NoScenariosFound': 'Nenhum cenário encontrado',
    'MursionPortal.Placeholder.EnterScenarioName': 'Inserir nome do cenário',
    'MursionPortal.ScenarioCard.Label.ScenariosList': 'LISTA DE CENÁRIOS',
    'MursionPortal.ScenarioCard.Header.Scenario': 'CENÁRIO',
    'MursionPortal.ScenarioCard.Header.ChooseTypeOfEvent': 'Escolha o tipo de evento para agendar',
    'MursionPortal.Label.Session': 'Seção',
    'MursionPortal.Label.Training': 'Treinamento',
    'MursionPortal.Button.Confirm': 'Confirmar',
    'MursionPortal.Button.Continue': 'Continuar',
    'MursionPortal.Button.Schedule': 'Agendar',
    'MursionPortal.Label.Draft': 'Rascunho',
    'MursionPortal.Label.Finalized': 'Finalizado',
    'MursionPortal.Label.Started': 'Iniciado',
    'MursionPortal.Label.Finished': 'Concluído',
    'MursionPortal.Label.Archived': 'Arquivado',
    'MursionPortal.Label.Cancel': 'cancelar',
    'MursionPortal.Label.Edit': 'editar',
    'MursionPortal.Label.CancelEdit': 'Cancelar Edição',
    'MursionPortal.Label.EditScenario': 'Editar Cenário',
    'MursionPortal.Label.EditScenarioName': 'Editar Nome do Cenário',
    'MursionPortal.ScenarioCard.Label.ApplyNameChange': 'Aplicar Alteração do Nome',
    'MursionPortal.ScenarioCard.Label.Apply': 'aplicar',
    'MursionPortal.ScenarioCard.Tab.Info': 'INFO',
    'MursionPortal.ScenarioCard.Tab.Learners': 'ALUNOS',
    'MursionPortal.ScenarioCard.Tab.Sessions': 'SESSÕES',
    'MursionPortal.ScenarioCard.ConfirmButton.AreYouSureArchiveScenario': 'Tem certeza de que deseja arquivar este cenário? Todos os dados relacionados também serão arquivados.',
    'MursionPortal.ScenarioCard.ConfirmButton.ArchiveScenario': 'Arquivar Cenário',
    'MursionPortal.Label.Delivery': 'Entrega',
    'MursionPortal.ScenarioInfo.Header.ArchivedScenario': 'CENÁRIO ARQUIVADO',
    'MursionPortal.ScenarioInfo.Button.YouMayOnlyEditTeams': 'Você só pode editar as equipes e a linha do tempo se finalizar um cenário',
    'MursionPortal.ScenarioInfo.Button.FinalizeScenario': 'Finalizar cenário',
    'MursionPortal.ScenarioInfo.Button.SaveScenario': 'Salvar cenário',
    'MursionPortal.ScenarioInfo.Button.CreatePlanning': 'Criar planejamento ',
    'MursionPortal.ScenarioInfo.Label.SimLength': 'Comprimento Sim',
    'MursionPortal.Header.Outcome': 'Resultado',
    'MursionPortal.Header.Strategies': 'Estratégias',
    'MursionPortal.Header.Attachments': 'Anexos',
    'MursionPortal.ScenarioInfo.Button.SaveScenarioInfo': 'Salvar informações do cenário',
    'MursionPortal.Label.ContentBundle': 'Pacote de Conteúdo',
    'MursionPortal.ScenarioInfo.Button.ShowLess': 'Exibir menos',
    'MursionPortal.ScenarioInfo.Button.ShowMore': 'Exibir mais',
    'MursionPortal.AssetSettings.Label.CurrentScenarioNoAssetSettings': 'O cenário atual não possui configurações de ativos.',
    'MursionPortal.Teams.Label.NoSelectedTeams': 'Nenhuma equipe selecionada',
    'MursionPortal.Teams.Label.ClientHasNoAssignedTeams': 'O cliente não tem equipes atribuídas',
    'MursionPortal.Label.Teams': 'Equipes',
    'MursionPortal.Label.NoTeamsSpecified': 'Nenhum time especificado',
    'MursionPortal.Label.Capitalized.Draft': 'RASCUNHO',
    'MursionPortal.Label.Capitalized.Finalized': 'FINALIZADO',
    'MursionPortal.Label.Capitalized.Started': 'INICIADO',
    'MursionPortal.Label.Capitalized.Finished': 'CONCLUÍDO',
    'MursionPortal.ScenarioInfo.Tooltip.ScenarioStillInDevelopmentStage': 'O cenário ainda está em fase de desenvolvimento',
    'MursionPortal.ScenarioInfo.Tooltip.ScenarioDeployedTimelineUpcoming': 'O cenário está implantado, mas a linha do tempo ainda está por vir',
    'MursionPortal.ScenarioInfo.Tooltip.ScenarioActiveLearnersCanSchedule': 'O cenário está ativo e os alunos podem agendar sessões',
    'MursionPortal.ScenarioInfo.Tooltip.ScenarioIsCompleted': 'O cenário está concluído',
    'MursionPortal.Button.Add': 'Adicionar ',
    'MursionPortal.Label.Warning': 'Aviso',
    'MursionPortal.SessionWizard.Label.UnableToCreateSession': 'Impossível criar sessão para o {failedParam} selecionado.',
    'MursionPortal.SessionWizard.Label.PleaseContinueToFindAnother': 'Pressione Continuar para encontrar outro <code>{failedParam}</code>.',
    'MursionPortal.SessionWizard.Label.ScheduleSession': 'Agendar Sessão',
    'MursionPortal.SessionWizard.Label.ScheduleTraining': 'Agendar treinamento',
    'MursionPortal.SessionWizard.Label.AddNotes': 'Adicionar observações',
    'MursionPortal.SessionWizard.Label.SelectSimulationContentForUse': 'Selecione o conteúdo da simulação para uso',
    'MursionPortal.SessionWizard.Label.SelectStep': 'Selecionar {step}',
    'MursionPortal.SessionWizard.Label.Expired': 'expirado',
    'MursionPortal.SessionWizard.ConfirmButton.AreYouSureCreateEmergencySession': 'Tem certeza de que deseja criar uma sessão de emergência?',
    'MursionPortal.SessionWizard.Button.Create': 'Criar',
    'MursionPortal.Button.Next': 'Próxima',
    'MursionPortal.SessionWizard.Label.RemainingTimeToBook': 'restante para reservar este bloco de tempo antes que a espera expire.',
    'MursionPortal.SessionWizard.Label.TimeBlockHoldExpired': 'A retenção do bloco de tempo expirou.',
    'MursionPortal.Label.Date': 'Data',
    'MursionPortal.Label.Time': 'Hora',
    'MursionPortal.SessionWizard.Label.TimeNoLongerAvailableClickSubmit': 'Esse horário não está mais disponível, gostaria de selecionar uma outra opção? Volte para escolher uma opção disponível ou clique em enviar para solicitar a opção existente.',
    'MursionPortal.SessionWizard.Label.TimeNoLongerAvailable': 'Esse horário não está mais disponível, gostaria de selecionar uma outra opção? Volte para escolher uma opção disponível.',
    'MursionPortal.SessionWizard.Button.GoBack': 'Voltar',
    'MursionPortal.Button.Submit': 'Enviar',
    'MursionPortal.SessionWizard.Label.NoAvailableClients': 'Nenhum cliente disponível',
    'MursionPortal.SessionWizard.Label.NoAvailableProjects': 'Nenhum projeto disponí­vel',
    'MursionPortal.SessionWizard.Label.NoAvailableScenarios': 'Nenhum cenário disponível',
    'MursionPortal.SessionWizard.Label.PleaseSelectScenario': 'Selecione o cenário primeiro',
    'MursionPortal.SessionWizard.Label.NoImage': 'Sem Imagem',
    'MursionPortal.SessionWizard.EmptyMessage.NoAvailableSImSpecialists': 'Nenhum especialista em Sim disponível',
    'MursionPortal.SessionWizard.Label.ScenarioHasNoContentBundles': 'O cenário não possui pacotes de conteúdo atribuídos.',
    'MursionPortal.SessionWizard.Option.SelectAnAsset': 'Selecione um ativo',
    'MursionPortal.SimSpecialistStep.Label.NoSimSpecialistsAvailable': 'Não há especialistas em simulação disponíveis para o horário selecionado. A sessão será atribuída por solicitação.',
    'MursionPortal.SsLearnersStep.ErrorMessage.YouCanAddMaxTrainees': 'Você só pode adicionar até {maxLearners} trainees para este tipo de sessão.',
    'MursionPortal.SsLearnersStep.ErrorMessage.YouCanAddMaxTrainee': 'Você só pode adicionar até {maxLearners} trainees para este tipo de sessão.',
    'MursionPortal.EmptyMessage.NoUsersSelected': 'Nenhum usuário selecionado',
    'MursionPortal.Placeholder.Search': 'Pesquisar',
    'MursionPortal.SsLearnersStep.Label.NoTraineeToSelect': 'Nenhum trainee para selecionar',
    'MursionPortal.LearnersStep.ErrorMessage.YouCanAddMaxLearners': 'Você só pode adicionar até {maxLearners} trainees para este tipo de sessão.',
    'MursionPortal.LearnersStep.ErrorMessage.YouCanAddMaxLearner': 'Você só pode adicionar até {maxLearners} trainees para este tipo de sessão.',
    'MursionPortal.LearnersStep.Label.NoLearnersToSelect': 'Nenhum aluno para selecionar',
    'MursionPortal.LearnersStep.Label.NoUsersSelected': 'Nenhum usuário selecionado',
    'MursionPortal.LearnersStep.Option.AllTeams': 'Todas as equipes',
    'MursionPortal.LearnersStep.Button.AddEntireTeam': 'Adicionar equipe inteira',
    'MursionPortal.LearnersStep.Note.AllowedSessionSIze': 'O tamanho da sessão permitida é igual a {maxLearners}. Selecione alunos específicos',
    'MursionPortal.LearnersStep.Checkbox.OnlyUnauthorizedUsers': 'Apenas usuários não autenticados irão participar',
    'MursionPortal.NotesStep.SessionNotes': 'Notas da sessão',
    'MursionPortal.NotesStep.ErrorMessage.AllowedLimitExceeded': 'O limite de caracteres permitido ({limit} caracteres) foi excedido',
    'MursionPortal.Button.LoadMore': 'Carregar mais',
    'MursionPortal.Label.Trainer': 'Professor',
    'MursionPortal.Label.Description': 'Descrição',
    'MursionPortal.Label.Outcome': 'Resultado',
    'MursionPortal.Label.Strategies': 'Estratégias',
    'MursionPortal.Label.DeliveryMode': 'Modalidade de entrega',
    'MursionPortal.Label.SessionLength': 'Duração da sessão',
    'MursionPortal.Label.NoDescriptionProvided': 'Nenhuma descrição fornecida',
    'MursionPortal.Label.NoOutcomeProvided': 'Nenhum resultado fornecido',
    'MursionPortal.Label.NoStrategiesProvided': 'Nenhuma estratégia fornecida',
    'MursionPortal.Placeholder.Dotted.Search': 'Pesquisar…',
    'MursionPortal.TimeStep.Label.NoAvailableTimeBlocks': 'Sem blocos de tempo disponíveis',
    'MursionPortal.TimeStep.Label.NoAvailableTimeBlocksInRange': 'Nenhum bloco de tempo disponível no intervalo selecionado',
    'MursionPortal.TimeStep.Footer.BySelectingThisTimeBlock': 'Ao selecionar este bloco de tempo, você estará solicitando um horário de sessão indisponível. Faremos o nosso melhor para atender a essa solicitação se você continuar, ou clicar abaixo para “Desligar” para selecionar apenas a partir dos blocos de tempo disponíveis confirmados.',
    'MursionPortal.TimeStep.Tooltip.SlideTheToggleOff': 'Clique em "Desligado" para selecionar apenas os blocos de tempo disponíveis.',
    'MursionPortal.TimeStep.Tooltip.SlideTheToggleOn': 'Clique em "Ligado" para solicitar um bloco de tempo extra.',
    'MursionPortal.Label.LowerCase.Client': 'cliente',
    'MursionPortal.Label.Project': 'projeto',
    'MursionPortal.Label.Scenario': 'cenário',
    'MursionPortal.Projects.NoProjectsFound': 'Nenhum projeto foi encontrado.',
    'MursionPortal.Projects.YourTeamsNotAssigned': 'Suas equipes ainda não foram atribuídas a nenhum projeto.',
    'MursionPortal.Projects.NoProjectsCreated': 'Nenhum projeto foi criado ainda.',
    'MursionPortal.Teams.NoTeamsFound': 'Nenhuma equipe foi encontrada.',
    'MursionPortal.Teams.YouHaveNotBeen': 'Você ainda não foi adicionado a nenhuma equipe.',
    'MursionPortal.Teams.YouHaveNoTeams': 'Você ainda não tem equipes.',
    'MursionPortal.Teams.NoTeamsCreated': 'Nenhuma equipe foi criada ainda.',
    'Settings.SSO.Modal.ConfigurationName': 'Nome da Configuração',
    'Settings.SSO.Modal.AuthorizationEndpoint': 'Endpoint de autorização',
    'Settings.SSO.Modal.LogoutEndpoint': 'Sair Endpoint',
    'Settings.SSO.Modal.ClientID': 'ID do Cliente',
    'Settings.SSO.Modal.ClientSecret': 'Cliente Secreto',
    'Settings.SSO.Modal.Scope': 'Escopo',
    'Settings.SSO.Modal.UserInfoEndpoint': 'Informações do usuário Endpoint',
    'Settings.SSO.Modal.TokenEndpoint': 'Token Endpoint',
    'Settings.SSO.Modal.TokenHTTPMethod': 'Método de Token HTTP',
    'Settings.SSO.Modal.UserIDClaimName': 'Nome do usuário da ID da reclamação',
    'Settings.SSO.Modal.EmailClaimName': 'Mandar e-mail para o nome da reclamação',
    'Settings.SSO.Modal.FirstNameClaimName': 'Nome de Reivindicação',
    'Settings.SSO.Modal.LastNameClaimName': 'Sobrenome da reivindicação',
    'Settings.SSO.Modal.LinkAllowed': 'Vinculação permitida',
    'Settings.SSO.Modal.UnlinkAllowed': 'Desvinculação permitida',
    'Settings.SSO.Modal.SingleLogoutEnabled': 'Logout único ativado',
    'Settings.SSO.Modal.SingleSignOnServiceEndpoint': 'Endpoint de serviço de logon único',
    'Settings.SSO.Modal.EntityID': 'ID da entidade',
    'Settings.SSO.Modal.X509Certificate': 'Certificado X.509',
    'Settings.SSO.Button.CreateSsoConfiguration': 'Criar configuração SSO ',
    'Settings.SSO.Modal.Protocol': 'Protocolo :',
    'Settings.SSO.Modal.Type': 'Modelo :',
    'Settings.SSO.Modal.ID': 'ID :',
    'Settings.SSO.Modal.AssignedClients': 'Clientes Atribuídos',
    'Settings.SSO.Modal.Button.DeleteSso': 'Excluir SSO',
    'Settings.SSO.ModalHeader.EditSsoConfiguration': 'Editar configuração SSO',
    'Settings.SSO.ModalHeader.NewSsoConfiguration': 'Nova configuração SSO',
    'Settings.SSO.Modal.Button.CreateSsoConfiguration': 'Criar configuração SSO',
    'Settings.SSO.Modal.Button.SaveSsoConfiguration': 'Salvar configuração SSO',
    'Settings.SSO.Modal.DeletionConfirmationText': 'Tem certeza de que deseja excluir esta configuração SSO?',
    'Settings.SSO.Modal.AddButton.GroupClaimName': 'Nome da reivindicação do grupo',
    'Settings.SSO.Modal.AddButton.Team': 'Equipe',
    'Settings.SSO.Modal.AddButton.PlaceHolder.GroupClaimValue': 'Nome da reivindicação do grupo',
    'Settings.SSO.Modal.SPInitiatedSsoUrl': 'URL de SSO iniciado por SP :',
    'Settings.SSO.Modal.Button.DownloadSPMetadata': 'Download de Metadados SP',
    'Settings.SSO.Modal.Button.Public': 'Público',
    'Settings.SSO.Modal.Button.Private': 'Privado',
    'Settings.SSO.Modal.ConfigurationIcon': 'Ícone de Configuração :',
    'Settings.SSO.Modal.PlaceHolder.EnterTeamClaimName': 'Digite o nome da reivindicação da equipe',
    'Settings.SSO.Modal.TeamClaimName': 'Nome da reivindicação da equipe',
    'Settings.SSO.Modal.TeamMapping': 'Mapeamento da equipe :',
    'Settings.SSO.Modal.Button.Change': 'Alterar',
    'MursionPortal.PlaceHolder.SelectAClient': 'Selecione um cliente',
    'Settings.SSO.Modal.AddButton.PlaceHolder.SelectATeam': 'Selecione uma equipe',
    'MursionPortal.Label.Client': 'Cliente',
    'Settings.SSO.Modal.ConfirmDelete': 'Confirmar exclusão',
    'Settings.SSO.Table.ColumnHeader.ConfigurationName': 'Nome da Configuração',
    'Settings.SSO.Table.ColumnHeader.Type': 'Tipo',
    'Settings.SSO.Table.ColumnHeader.Protocol': 'Protocolo',
    'Settings.SSO.Table.ColumnHeader.ClientsCount': 'Contagem de clientes',
    'Settings.SSO.PlaceHolder.EnterSsoName': 'Digitar nome do SSO',
    'Settings.SSO.Table.NoSsoSchemasFound': 'Nenhum esquema SSO foi encontrado.',
    'Settings.SSO.Table.HoverTitle.ViewEditSso': 'Ver/Editar SSO',
    'Settings.SSO.Modal.HoverTitle.CopySPInitiatedSsoUrl': 'URL de SSO iniciado por SP',
    'Settings.SSO.Modal.CopyButton.ModalHeader.SPInitiatedSsoUrl': 'URL de SSO iniciado por SP',
    'MursionPortal.CopyText.CopiedToClipboard': 'copiado para a área de transferência',
    'Settings.SSO.Text.Public': 'Público',
    'Settings.SSO.Text.Private': 'Privado',
    'Settings.SSO.Modal.NoClientsAssigned': 'Nenhum cliente atribuído',
    'MursionPortal.Label.NotAvailable': 'n/a',
    'Teams.Modal.Header.NewTeam': 'Nova Equipe',
    'MursionPortal.Button.More': 'Mais',
    'Teams.Modal.Header.AllTeams': 'Todas as equipes',
    'MursionPortal.Label.Name': 'Nome:',
    'MursionPortal.Label.Location': 'Local:',
    'MursionPortal.Label.Tags': 'Tags:',
    'MursionPortal.Header.Facilitators': 'Professores:',
    'MursionPortal.Header.Scenarios': 'Cenários:',
    'MursionPortal.Button.SaveTeamInfo': 'Salvar informações da equipe',
    'MursionPortal.IconButton.Cancel': 'cancelar',
    'MursionPortal.IconButton.Edit': 'editar',
    'MursionPortal.ButtonTitle.CancelEdit': 'Cancelar Edição',
    'MursionPortal.ButtonTitle.EditTeam': 'Editar Equipe',
    'MursionPortal.ConfirmationText.RestoreTeam': 'Tem certeza que deseja restaurar esta equipe?',
    'MursionPortal.ButtonTitle.RestoreTeam': 'Restaurar Equipe',
    'MursionPortal.ConfirmationText.ArchiveTeam': 'Tem certeza de que deseja arquivar esta equipe?',
    'MursionPortal.ButtonTitle.ArchiveTeam': 'Arquivar Equipe',
    'MursionPortal.EmptyMessage.NoFacilitators': 'Sem professores',
    'MursionPortal.EmptyMessage.NoSelectedFacilitators': 'Nenhum professor selecionado',
    'MursionPortal.Title.Facilitators': 'Professores',
    'MursionPortal.Heading.Archived': '(Arquivado)',
    'Teams.Modal.Header.AllLearners': 'Todos os alunos',
    'Teams.Modal.Placeholder.SearchLearners': 'Pesquisar alunos...',
    'Teams.Modal.EmptyMessage.NoLearnersToSelect': 'Nenhum aluno para selecionar',
    'Teams.Modal.Header.TeamLearners': 'Alunos da equipe',
    'Teams.Modal.Placeholder.SearchTeamLearners': 'Pesquisar alunos da equipe...',
    'Teams.Modal.EmptyMessage.NoTeamLearnersToSelect': 'Nenhum aluno da equipe para selecionar',
    'Teams.Modal.Header.AssignLearners': 'Atribuir alunos a uma equipe "{teamName}"',
    'Teams.AssignLearners.Modal.Button.SelectAll': 'Selecionar tudo',
    'Teams.AssignLearners.Modal.EmptyMessage.NoItems': 'Nenhum item para selecionar',
    'MursionPortal.Header.Teams': 'Equipes',
    'Teams.Modal.AlertMessage.Timezone': 'No caso de alteração de fuso horário, verifique os eventos existentes e as disponibilidades.',
    'Teams.SelectorMenu.SelectedOf': 'Selecionado {selected} de {totalNumber}',
    'Teams.Modal.AlertMessage.ProfileTimezone': 'A seleção de fuso horário do seu perfil atual não corresponde às configurações de data e hora do seu computador ({updatedTimezone}).Clique em "Salvar alterações" para prosseguir mesmo assim ou selecione uma opção de fuso horário de perfil diferente.',
    'MursionPortal.Label.Learner': 'Aluno',
    'Teams.Modal.Button.More': '{overlappedAmount} mais...',
    'MursionPortal.IconButton.Archive': 'arquivar',
    'Teams.ToggleButton.Learners': 'Alunos',
    'Teams.ToggleButton.Sessions': 'Sessões',
    'MursionPortal.Status.RegistrationStatus': 'Status de registro',
    'MursionPortal.Status.RegistrationAccepted': 'Registro aceito',
    'MursionPortal.Status.RegistrationNotAccepted': 'Registro não aceito',
    'MursionPortal.SortBy': 'Ordenar por',
    'MursionPortal.Placeholder.SortBy': 'ordenar por',
    'Clients.Button.CreateClient': 'Criar cliente ',
    'Clients.CreateClient.Header.NewClient': 'Novo Cliente',
    'Clients.CreateClient.Button.CreateClient': 'Criar cliente',
    'Clients.CreateClient.Label.ClientName': 'Nome do Cliente',
    'Clients.CreateClient.Placeholder.CityCountry': 'Cidade, País',
    'MursionPortal.Timezone': 'Fuso horário',
    'Clients.CreateClient.Label.Logo': 'Logo',
    'Clients.Text.NoClientsFound': 'Nenhum cliente foi encontrado.',
    'MursionPortal.Text.Active': 'Ativo',
    'MursionPortal.Text.Archived': 'Arquivado',
    'Clients.Modal.Button.BackToClientList': 'Voltar para a lista de clientes',
    'Clients.Modal.EmptyMessage.NoClientsFound': 'Nenhum cliente foi encontrado',
    'Clients.Modal.Placeholder.EnterClientName': 'Inserir nome do cliente',
    'Clients.Modal.Header.ClientsList': 'LISTA DE CLIENTES',
    'Clients.Modal.Header.Client': 'CLIENTE',
    'Clients.Modal.Header.AllClients': 'Todos os clientes',
    'MursionPortal.Search': 'Pesquisar',
    'Clients.Modal.RestoreConfirmText': 'Tem certeza que deseja restaurar esta equipe?',
    'Clients.Modal.DeleteConfirmText': 'Tem certeza de que deseja arquivar esta equipe?',
    'Clients.Modal.RelatedDataText': 'Todos os dados relacionados também serão arquivados.',
    'Clients.Modal.DeleteErrorText': 'O cliente atual tem cenário(s). Arquive o(s) cenário(s) do projeto antes de arquivar este cliente.',
    'Clients.Button.RestoreClient': 'Restaurar cliente',
    'Clients.IconButton.Cancel': 'cancelar',
    'Clients.IconButton.Edit': 'editar',
    'Clients.IconButton.CancelEdit': 'Cancelar Edição',
    'Cleints.IconButton.EditScenarioName': 'Editar Nome do Cenário',
    'Cleints.IconButton.ApplyNameChange': 'Aplicar Alteração do Nome',
    'Clients.IconButton.Apply': 'aplicar',
    'Clients.IconButton.EditClient': 'Editar Cliente',
    'Clients.Button.ArchiveClient': 'Arquivar cliente',
    'Clients.Modal.Heading.Archived': '(Arquivado)',
    'Clients.Modal.Label.Name': 'Nome:',
    'Clients.Modal.Placeholder.ClientName': 'Nome do Cliente',
    'Clients.Modal.Label.Location': 'Local:',
    'Clients.Modal.Label.Industry': 'Indústria:',
    'Clients.Modal.Placeholder.SelectIndustry': 'Selecione a indústria',
    'Clients.Modal.Label.Timezone': 'Fuso horário:',
    'Clients.Modal.Label.SchedulingByRequest': 'Agendamento por solicitação:',
    'MursionPortal.Enabled': 'Ativado',
    'MursionPortal.Disabled': 'Desativado',
    'Clients.Modal.Label.SchedulingWindow': 'Janela de agendamento:',
    'Clients.Modal.Label.HowFarInAdvance': 'Com que antecedência os clientes devem agendar',
    'MursionPortal.Days': 'dias',
    'MursionPortal.Day': 'dia',
    'Clients.Modal.Label.AvailableSharedSSOTitle': 'A lista de SSO compartilhados disponíveis que podem ser usados para fazer login',
    'Clients.Modal.Label.AvailableSharedSSOLabel': 'SSO compartilhado disponível:',
    'Clients.Modal.EmptySelectedMessage': 'Nenhum SSO selecionado',
    'Clients.Modal.EmptyListMessage': 'Sem SSO disponível',
    'Clients.Modal.SSOTitle': 'Selecione SSO disponível',
    'Cleints.Modal.Label.AvailablePrivateSSOTitle': 'A lista de SSO compartilhados disponíveis que podem ser usados para fazer login',
    'Clients.Modal.Label.AccountOwners': 'Proprietários da conta:',
    'Clients.Modal.AccountOwner.EmptyListMessage': 'Sem proprietários da conta',
    'Clients.Modal.AccountOwner.EmptySelectedMessage': 'Nenhum proprietário de conta selecionado',
    'Clients.Modal.AccountOwnersTitle': 'Proprietário(s) da conta',
    'MursionPortal.Message.Updating': 'Atualizando…',
    'MursionPortal.Button.SaveClientInfo': 'Salvar informações do cliente',
    'MursionPortal.Add': 'Adicionar ',
    'Clients.Modal.Label.EditSSO': 'Editar SSO',
    'Clients.Modal.Label.Edit': 'editar',
    'MursionPortal.NumberOfFacilitators': 'Número de professores',
    'MursionPortal.NumberOfLearners': 'Número de alunos',
    'Clients.Modal.Button.CreateTeam': 'Criar equipe ',
    'MursionPortal.NewTeam': 'Nova Equipe',
    'MursionPortal.NoTags': 'Sem tags',
    'MursionPortal.Header.Tags': 'Tags',
    'MursionPortal.AddTag': 'Adicionar tag',
    'Clients.Modal.Teams.FacilitatorsEmptyListMessage': 'Nenhum professor selecionado',
    'Clients.Modal.Teams.LearnersEmptyListMessage': 'Nenhum aluno selecionado',
    'MursionPortal.Learners': 'Alunos',
    'MursionPortal.Facilitator': 'Professor',
    'MursionPortal.CreateTeam': 'Criar equipe',
    'Clients.Modal.Teams.ConfirmationText.RestoreTeam': 'Tem certeza que deseja restaurar esta equipe?',
    'Clients.Modal.Teams.ButtonTitle.RestoreTeam': 'Restaurar Equipe',
    'Clients.Modal.Teams.ConfirmationText.ArchiveTeam': 'Tem certeza de que deseja arquivar esta equipe?',
    'Clients.Modal.Teams.ButtonTitle.ArchiveTeam': 'Arquivar Equipe',
    'Clients.Modal.Projects.Header.NewProject': 'Novo Projeto',
    'MursionPortal.ProjectName': 'Nome do Projeto',
    'MursionPortal.Contracted': 'Contratado',
    'MursionPortal.CancellationWindow': 'Janela de cancelamento',
    'MursionPortal.SoftwareVersion': 'Versão do software',
    'MursionPortal.Billing': 'Cobrança',
    'Clients.Modal.Projects.Checkbox.NonBillable': 'Não Faturável',
    'MursionPortal.Recording': 'Gravação',
    'Clients.Modal.Projects.Checkbox.AllowRecording': 'Permitir gravação',
    'Clients.Modal.Projects.Label.RecordedVideos': 'Vídeos gravados vão para:',
    'Clients.Modal.Projects.Checkbox.RecordingPermission': 'Cabe ao aluno a permissão de gravação',
    'Clients.Modal.Projects.ButtonTitle.SaveProjectInfo': 'Salvar detalhes do projeto',
    'Clients.Modal.Projects.Header.ProjectManager': 'Gerente de projetos',
    'Clients.Modal.Projects.ProjectsEmptyListMessage': 'Nenhum gerente de projeto selecionado',
    'MursionPortal.Header.Bracketed.Scenarios': 'Cenário(s)',
    'Clients.Modal.Projects.Text.CompleteProjectInfoFirst': 'Preencha as informações do projeto primeiro',
    'MursionPortal.Header.CreateScenario': 'Criar cenário',
    'Clients.Modal.Projects.ConfirmationText.RestoreProject': 'Tem certeza que deseja restaurar este projeto?',
    'Clients.Modal.Projects.ButtonTitle.RestoreProject': 'Restaurar Projeto',
    'Clients.Modal.Projects.ButtonTitle.Edit': 'Editar Projeto',
    'Clients.Modal.Projects.ButtonTitle.CancelEdit': 'Cancelar Edição',
    'Clients.Modal.Label.Cancel': 'cancelar',
    'Clients.Modal.Projects.ConfirmationText.ArchiveProject': 'Tem certeza de que deseja arquivar este projeto?',
    'Clients.Modal.Projects.ButtonTitle.ArchiveProject': 'Arquivar projeto',
    'Clients.Modal.Projects.ArchiveErrorMessage': 'O projeto atual tem cenário(s) ativo(s). Arquive o(s) cenário(s) do projeto antes de arquivar este cliente.',
    'Clients.Modal.Projects.ButtonTitle.EditTags': 'Editar Tags',
    'Clients.Modal.Projects.Label.UtilizedMissedSessions': 'Sessões perdidas utilizadas,',
    'Clients.Modal.Projects.Button.CreateNew': 'Criar novo ',
    'Clients.Modal.Projects.Button.RepeatClient': 'Repetir cliente',
    'Clients.Modal.Projects.Button.RepeatBank': 'Repetir banco',
    'Clients.Modal.Projects.ButtonTitle.CreateNewScenario': 'Crie um novo cenário',
    'Clients.Modal.Projects.ButtonTitle.RepeatClientScenario': 'Repetir cenário do cliente',
    'Clients.Modal.Projects.ButtonTitle.ScenarioBank': 'Crie um cenário a partir do banco de cenários',
    'Clients.Modal.Button.Schedule': 'Agendar',
    'MursionPortal.Button.ActivateScenario': 'Ativar cenário',
    'MursionPortal.Button.ChangeScenario': 'Alterar cenário',
    'Clients.IconButton.Confirm': 'confirmar',
    'Clients.IconButton.ConfirmEdit': 'Confirme Edição',
    'Clients.Modal.Projects.HeaderText.ChooseScenario': 'Escolher cenário para repetir',
    'Clients.Modal.Projects.HeaderText.ChooseScenarioBank': 'Escolher cenário do Banco de cenários',
    'MursionPortal.Placeholder.LowerCase.EnterScenarioName': 'Inserir nome do cenário',
    'MursionPortal.IconButton.ResetToDefault': 'Redefinir para o nome padrão',
    'MursionPortal.IconButton.Reset': 'redefinir',
    'Clients.Modal.Header.CreatePlanning': 'Criar planejamento para o novo cenário',
    'Clients.Modal.EmptyMessage.NoClientScenariosFound': 'Nenhum cenário de cliente foi encontrado.',
    'Clients.Modal.EmptyMessage.NoBankScenariosFound': 'Nenhum banco de cenário foi encontrado.',
    'Clients.Modal.Message.NoTeamsSpecified': 'Nenhum time especificado',
    'Clients.Modal.EmptyMessage.NoSelectedTeams': 'Nenhuma equipe selecionada',
    'Clients.Modal.Message.NoAssignedTeams': 'O cliente não tem equipes atribuídas',
    'Clients.Modal.Label.Certificate': 'Certificado',
    'Clients.Modal.Label.DeliveryMode': 'Modalidade de entrega',
    'Clients.Modal.Label.SessionSize': 'Tamanho da sessão',
    'Clients.Modal.Label.SessionLength': 'Duração da sessão',
    'Clients.Modal.Label.Outcome': 'Resultado',
    'Clients.Modal.Label.Strategies': 'Estratégias',
    'Clients.Modal.Label.Description': 'Descrição',
    'MursionPortal.Button.MoreInfo': 'Mais informações...',
    'MursionPortal.Button.HideAdditionalInfo': 'Ocultar informações adicionais...',
    'MursionPortal.Header.Timeline': 'Linha do Tempo',
    'MursionPortal.Header.Projects': 'Projetos ',
    'MursionPortal.Header.Sessions': 'Sessões',
    'MursionPortal.Header.Users': 'Usuários',
    'MursionPortal.Text.Yes': 'sim',
    'MursionPortal.Text.No': 'não',
    'MursionPortal.Button.Google': 'Google',
    'MursionPortal.Button.Outlook': 'Outlook, iCal, Outro',
    'MursionPortal.Heading.GoogleCalendar': 'Google Agenda ',
    'MursionPortal.Heading.AddToCalendar': 'Adicionar ao Calendário ',
    'Dashboard.Legacy.GoogleCalendarList.Authorized': 'Se ainda não tiver feito isso, você será solicitado a <code>autorizar</code> o <code>Portal da {companyName}</code> a adicionar eventos ao seu calendário.',
    'Dashboard.Legacy.GoogleCalendarList.EventAdd': 'O evento será <code>adicionado à sua agenda</code> e ainda pode ser acessado no Portal da {companyName}.',
    'MursionPortal.Heading.OtherCalendars': 'Outros Calendários',
    'Dashboard.Legacy.OtherCalendarList.Downloaded': 'O <code>download</code> do seu evento será feito como <code>{companyName}</code>',
    'Dashboard.Legacy.OtherCalendarList.SelectCalendar': '<code>Clique com o botão direito</code>, selecione <code>"Abrir com"</code> e selecione seu aplicativo de calendário.',
    'Dashboard.Legacy.OtherCalendarList.ImportWithinCalendar': '(Você também pode selecionar "Importar" em sua agenda e localizar o arquivo baixado)',
    'Dashboard.Legacy.OtherCalendarList.AddedToCalendar': 'O evento será <code>adicionado à sua agenda</code> e ainda pode ser acessado no Portal da {companyName}.',
    'MursionPortal.Text.NoLearnersAddedYet': 'Nenhum aluno foi adicionado ainda',
    'MursionPortal.Heading.Schedule': 'Agendar',
    'MursionPortal.Heading.Users': 'USUÁRIOS',
    'MursionPortal.Heading.ProfServices': 'Serviços Profissionais',
    'MursionPortal.Heading.SimSpecialist': 'Especialistas Sim',
    'MursionPortal.Heading.Clients': 'Clientes',
    'MursionPortal.Heading.Sessions': 'Sessões',
    'MursionPortal.Heading.Projects': 'Projetos',
    'MursionPortal.Heading.Teams': 'Equipes',
    'MyMessages.Button.ArchiveAll': 'Arquivar tudo',
    'MyMessages.Button.ArchivedMessages': 'Mensagens Arquivadas',
    'MyMessages.Button.ShowMore': 'Mostrar mais...',
    'MyMessages.Label.NewMessages': '<code>{newMessagesCount}</code> Novas mensagens',
    'MyMessages.Label.NewMessage': '<code>{newMessagesCount}</code> Nova mensagem',
    'MyMessages.HoverTitle.InboxButton': 'botão da caixa de entrada',
    'MyMessages.Text.InboxButton': 'Botão da caixa de entrada',
    'MyMessages.Text.HaveNewMessages': 'Você tem {total} novas mensagens',
    'MyMessages.Text.HaveNewMessage': 'Você tem uma nova mensagem',
    'MursionPortal.CookiePolicy.Header.CookiePolicy': 'Política de Cookies',
    'MursionPortal.CookiePolicy.Text.MursionUsesEssentialCookiesOnly': 'A Mursion usa apenas cookies essenciais que são necessários para sua segurança e o funcionamento de nosso aplicativo da web. Não usamos cookies para coletar dados pessoais ou outras informações, como endereços IP ou uso do site.',
    'MursionPortal.CookiePolicy.Text.ForMoreInformation': ' Para obter mais informações, consulte a <code>Política de Privacidade</code>',
    'MursionPortal.Button.Ok': 'Ok',
    'MyProfile.Button.SaveChanges': 'Salvar Alterações',
    'MyProfile.Label.ExternalProfiles': 'Perfis Externos',
    'MyProfile.Label.UserRoles': 'Funções do usuário',
    'MyProfile.Label.UserRole': 'Função de usuário',
    'MyProfile.Heading.ReviewAccountInformation': 'Por favor, revise as informações da sua conta',
    'MyProfile.HoverTitle.UnlinkProfile': 'Desvincule seu perfil de {linkName}',
    'MyProfile.HoverTitle.LinkProfile': 'Vincular perfil de {linkName}',
    'MyProfile.Button.Unlink': 'Desvincular',
    'MyProfile.Text.UnlinkConfirmationText': 'Tem certeza de que deseja desvincular seu perfil {name}? Observe que você também será desconectado de {name}.',
    'MyProfile.Title.ProfileUnlink': 'desvinculação do perfil de {name}',
    'MyProfile.Text.SuccessfullyUnlinkedProfile': 'Você desvinculou com sucesso o seu perfil {name}',
    'MyProfile.Text.TimeZoneIsSetAutomatically': 'Seu fuso horário é definido automaticamente para {getTimeZone}. Clique em "Confirmar" para aceitar esta seleção ou em "Cancelar" para fazer outra seleção.',
    'MyProfile.Text.TimeZoneChange': 'No caso de alteração de fuso horário, verifique os eventos existentes e as disponibilidades.',
    'MyProfile.Text.ProfileTimeZoneNotMatchComputer': 'A seleção de fuso horário do seu perfil atual não corresponde às configurações de data e hora do seu computador ({timeZone}).Clique em "Salvar alterações" para prosseguir mesmo assim ou selecione uma opção de fuso horário de perfil diferente.',
    'MursionPortal.Button.MyProfile': 'Meu perfil',
    'MursionPortal.Button.LogOut': 'Sair',
    'MyProfile.Label.Language': 'Idioma',
    'MursionPortal.Label.Roles': 'Funções',
    'MursionPortal.Label.Role': 'Função',
    'MursionPortal.Projects.Header.ScenarioType': 'Tipo de cenário',
    'MursionPortal.Projects.Header.Client': 'Cliente',
    'MursionPortal.Projects.Header.Project': 'Projeto',
    'MursionPortal.Projects.Header.Scenario': 'Cenário',
    'MursionPortal.Projects.Header.SimulationContent': 'Conteúdo de Simulação',
    'MursionPortal.Projects.Header.Date': 'Data',
    'MursionPortal.Projects.Header.Time': 'Hora',
    'MursionPortal.Projects.Header.Notes': 'Anotações',
    'Filters.Date.Week': 'Semana',
    'Users.Table.Modal.SignLanguageInterpreter': 'Intérprete de língua de sinais',
    'Calendar.Button.Schedule': 'Agendamento',
    'Calendar.Button.Today': 'Hoje',
    'MursionPortal.Availability': 'Disponibilidade',
    'Calendar.Button.Schedule.QualityAssurance': 'Controle de qualidade',
    'MursionPortal.Training': 'Treinamento',
    'Calendar.Button.Schedule.EmergencySession': 'Sessão de emergência',
    'Calendar.Button.Schedule.Session': 'Sessão',
    'Calendar.Button.Schedule.BreakPrep': 'Pausa/Preparação',
    'MursionPortal.Month': 'Mês',
    'MursionPortal.Week': 'Semana',
    'Calendar.Heading.Calendars': 'Calendários',
    'Calendar.Tip.SelectACalendar': 'Selecione um calendário para ver a disponibilidade',
    'Calendar.ScheduledHours.SumOfAllScheduledSessions': 'Soma de todas as sessões agendadas na visualização atual',
    'MursionPortal.ShortLabel.Hours': 'hrs',
    'Calendar.ScheduledHours.SumOfAllTrainingSessions': 'Soma de todas as sessões de treinamento agendadas na visualização atual',
    'Calendar.ScheduledHours.Trainings': 'Treinamentos',
    'Calendar.ScheduledHours.SumOfRemainingAvailabilityHours': 'Soma de todas as horas restantes de disponibilidade de SS na visualização atual',
    'Calendar.ScheduledHours.Available': 'Disponível',
    'MursionPortal.Scheduled': 'Agendado',
    'Calendar.Label.Display': 'Exibição',
    'MursionPortal.Client': 'Cliente',
    'Calendar.Filter.Company': 'Empresa',
    'Calendar.Filter.SwapRequests': 'Pedidos de troca',
    'MursionPortal.QA': 'CQ',
    'Calendar.SearchFilter.FilterProjects': 'Filtrar projetos',
    'Calendar.SearchFilter.FilterClients': 'Filtrar clientes',
    'Calendar.SearchFilter.FilterSimSpecialists': 'Filtrar especialistas em simulações',
    'Calendar.IndustrySelector.Options.SelectAnIndustry': 'Selecionar um setor',
    'Calendar.IndustrySelector.Options.AllIndustries': 'Todos os setores',
    'Calendar.IndustrySelector.Options.NoIndustriesToSelect': 'Nenhum setor para selecionar',
    'MursionPortal.SelectAClient': 'Selecionar um certificado',
    'Calendar.ClientSelector.Options.AllClients': 'Todos os clientes',
    'Calendar.ClientSelector.Options.NoClientToSelect': 'Nenhum cliente para selecionar',
    'MursionPortal.SelectACertificate': 'Selecionar um certificado',
    'Calendar.CertificateSelector.Options.AllCertificates': 'Todos os certificados',
    'Calendar.CertificateSelector.Options.NoCertificatesToSelect': 'Nenhum certificado para selecionar',
    'Calendar.ClientsList.NoClientsFound': 'Nenhum cliente foi encontrado.',
    'MursionPortal.UncheckAll': 'Desmarcar tudo',
    'MursionPortal.CheckAll': 'Marcar tudo',
    'Calendar.SimSpecialistList.NoSimSpecialistsFound': 'Nenhum especialista em simulação foi encontrado.',
    'Calendar.ProjectsList.CloseProjectOverview': 'Fechar a visão geral do projeto',
    'Calendar.ProjectsList.AllProjects': 'TODOS OS PROJETOS',
    'MursionPortal.Bracketed.Scenarios': 'Cenário(s)',
    'Calendar.ProjectsList.Overview': 'Visão geral',
    'Calendar.ProjectsList.CloseClientOverview': 'fechar visão geral do cliente',
    'MursionPortal.Scenario': 'cenário',
    'Calendar.ScenariosList.NoScenarios': 'Nenhum cenário',
    'MursionPortal.Delivery': 'Entrega',
    'MursionPortal.Mode': 'Modo',
    'MursionPortal.Timeline': 'Cronologia',
    'Calendar.ScenariosList.Description': 'Descrição',
    'MursionPortal.Outcome': 'Resultado',
    'MursionPortal.Strategies': 'Estratégias',
    'MursionPortal.ContentBundle': 'Pacote de conteúdo',
    'MursionPortal.SimulationEnvironments': 'Ambiente(s) de simulação',
    'MursionPortal.AvatarsInSimulations': 'Avatar(es) nas simulações',
    'Calendar.LearnersList.SessionNotScheduled': 'Sessão não agendada',
    'Calendar.LearnersList.SessionScheduled': 'Sessão agendada',
    'Calendar.LearnersList.SessionComplete': 'Sessão completa',
    'Calendar.LearnersList.Learner': '{learnersCount} aluno',
    'Calendar.LearnersList.Learners': '{learnersCount} alunos',
    'Calendar.Button.CantSchedule': 'Não é possível agendar',
    'Calendar.Button.ScheduleEvent': 'Agendar evento',
    'Calendar.MainPanel.ConfirmText.AreYouSure': 'Tem certeza de que quer cancelar este evento?',
    'Calendar.MainPanel.CancelEvent': 'Cancelar evento',
    'Calendar.MainPanel.UpdateEvent': 'Atualizar evento',
    'MursionPortal.Submit': 'Enviar',
    'Calendar.MainPanel.NewEvent': 'Novo evento',
    'MursionPortal.Project': 'Projeto',
    'MursionPortal.Button.Reload': 'Recarregar',
    'Clients.Modal.Label.FacilitatorVisibility': 'Visualização do facilitador:',
    'Clients.Modal.Placeholder.FacilitatorVisibility': 'Selecionar visualização do facilitador',
    'Clients.Modal.Projects.ConfirmationText.EditProject': 'Tem certeza de que deseja editar este projeto?',
    'Projects.ScenarioList.Legend.ScenariosStatusTab': 'Guias de status de cenários',
    'Projects.ScenarioList.Legend.CreateNew': 'Botões de cenário: Criar novo, Repetir cliente, Repetir banco',
    'MursionPortal.ScenarioCard.Tab.Legend.ViewModeTab': 'Guia Modo de Visualização',
    'MursionPortal.SearchPlaceHolder.SearchClient': 'Buscar cliente...',
    'Settings.SSO.Modal.AddButton.SearchPlaceHolder.SearchTeam': 'Selecionar equipe...',
    'Settings.SSO.Modal.Button.ChangeLabel': 'Alterar {label}',
    'MursionPortal.Label.Yes': 'Sim',
    'MursionPortal.Label.No': 'Não',
    'Settings.SSO.Modal.AddButton.PlaceHolder.NoOptions': 'Nenhuma opção',
    'Settings.SSO.Modal.ClientName': 'Nome do cliente',
    'MursionPortal.Modal.Button.Add': 'Adicionar',
    'MursionPortal.Modal.Button.Remove': 'Remover',
    'Users.Table.Hover.ViewEditAcceptedUsers': 'Ver/Editar usuários aceitos',
    'Users.Table.Hover.ViewEditUnacceptedUsers': 'Ver/Editar usuários não aceitos',
    'MursionPortal.Label.Industry': 'Setor',
    'MursionPortal.Label.Timezone': 'Fuso horário',
    'MursionPortal.Header.Client': 'Cliente',
    'MursionPortal.FilterByCertificate': 'Filtrar por certificado',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Header.CreateQAEvent': 'Criar evento de CQ',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Label.TimeBlock': 'Bloco(s) de tempo',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Label.Participants': 'Participantes',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Button.SubmitNewEvent': 'Enviar novo evento',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Error.TimeBlockOutsideRange': 'O bloco de tempo está fora do intervalo disponível',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Error.TimeBlockBeforeCurrentTime': 'O bloco de tempo é anterior ao horário atual',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Label.Start': 'Início',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Label.End': 'Término',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Label.Length': 'Duração',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Error.NoAvailableTimeBlock': 'Não há bloco de tempo disponível para o dia selecionado',
    'Calendar.Modal.Button.Client': 'Cliente',
    'Calendar.Modal.Button.Project': 'Projeto',
    'Calendar.Modal.Button.Scenario': 'Cenário',
    'Calendar.Modal.Header.CompanyEvents': 'Eventos empresariais',
    'MursionPortal.Header.Session': 'Sessão',
    'MursionPortal.Header.Events': 'Eventos',
    'MursionPortal.Header.Event': 'Evento',
    'Calendar.Modal.Label.Time': 'Hora',
    'Calendar.Label.SelectTimeZone': 'Selecionar fuso horário',
    'Calendar.Text.Training': 'Treinamento',
    'Calendar.Text.QA': 'CQ',
    'Calendar.Modal.Text.ConfirmDeleteTime': 'Confirmar exclusão do bloco de tempo',
    'Calendar.Button.NewTimeBlock': 'Novo bloco de tempo',
    'Calendar.Modal.Label.Capitalized.Time': 'TEMPO',
    'Calendar.Modal.Label.Participants': 'PARTICIPANTES',
    'Calendar.Modal.Text.NoParticipants': 'Nenhum participante',
    'Calendar.Modal.Label.SimulationSpecialist': 'ESPECIALISTA EM SIMULAÇÃO',
    'Calendar.Modal.Label.MeetingOrganizer': 'ORGANIZADOR DA REUNIÃO',
    'Calendar.Error.ErrorPrefixMustBeBetween': '{errorPrefix} deve estar entre {duration1} e {duration2}.',
    'Calendar.Error.SelectedTimeBlockOverlapsExistingTime': 'O bloco de tempo selecionado se sobrepõe a um bloco de tempo existente.',
    'Calendar.Error.SelectedTimeBlockOverlapsUnavailableTime': 'O bloco de tempo selecionado se sobrepõe a um intervalo de tempo indisponível.',
    'Calendar.Error.SelectedTimeBlockIsNotMultipleOfEventGap': 'O bloco de tempo selecionado não é uma multiplicação do intervalo de evento ({duration})',
    'Calendar.Text.AvailabilityUser': 'Disponibilidade {user}',
    'Calendar.Text.AvailabilityTime': 'Disponibilidade {time}',
    'Calendar.Text.AvailabilityDurationAvailabilityTime': 'Disponibilidade de {availabilityDuration} {time}',
    'Calendar.Text.TrainingsTime': '{eventsCount} treinamentos {time}',
    'Calendar.Text.SessionsTime': '{eventsCount} sessões {time}',
    'Calendar.Text.AvailabilityName': 'Disponibilidade {name}',
    'Calendar.Label.Learners': 'Alunos',
    'Calendar.Text.FullAvailability': 'Disponibilidade total',
    'Calendar.Text.YourAvailability': 'Sua disponibilidade',
    'Calendar.Text.Event': 'Evento',
    'Calendar.Header.Trainings': 'Treinamentos',
    'Calendar.Header.CompanyEvents': 'Eventos empresariais',
    'Calendar.Header.Session': 'Sessão',
    'Calendar.Error.TimeBlockIsOutsideAvailableRange': 'O bloco de tempo está fora do intervalo disponível',
    'Calendar.Error.TimeBlockIsBeforeCurrentTime': 'O bloco de tempo é anterior ao horário atual',
    'Calendar.Label.Break': 'Pausa',
    'Calendar.Label.Prep': 'Preparação',
    'Calendar.Modal.Header.CreateBreakPrepEvent': 'Criar evento de pausa/preparação',
    'Calendar.Modal.Label.EventType': 'EventType',
    'Calendar.Modal.Label.TimeBlocks': 'Bloco(s) de tempo',
    'Calendar.Text.NoTimeBlocksInSelectedRange': 'Nenhum bloco de tempo disponível no intervalo selecionado',
    'Calendar.Text.NoAvailableTimeBlocks': 'Nenhum bloco de tempo disponível',
    'Calendar.Text.SelectingThisTimeBlock': 'Ao selecionar este bloco de tempo, você estará solicitando um horário de sessão indisponível. Faremos nosso melhor para atender a essa solicitação se você prosseguir. Você também pode deslizar o interruptor abaixo para a posição de "Off" para selecionar apenas entre os blocos de tempo disponíveis e confirmados.',
    'Calendar.ToolTip.SlideToggleOff': 'Deslize o botão para a posição de "Off" para selecionar apenas entre os blocos de tempo disponíveis.',
    'Calendar.ToolTip.SlideToggleOn': 'Deslize o botão para a posição de "On" para solicitar um bloco de tempo adicional.',
    'Calendar.Label.SelectAnyTimeBlock': 'Selecione qualquer bloco de tempo',
    'Users.CreateUser.Label.GoogleMeetID': 'ID do Google Meet',
    'Users.CreateUser.GoogleMeetId.Placeholder.GoogleMeetID': 'ID do Google Meet',
    'Users.CreateUser.GoogleMeetID.Hover': 'Insira um ID do Google Meet',
    'Setting.Edit.Modal.Error.UnableToCreateSession': 'Não foi possível criar uma sessão para o hotário selecionado {formattedStartTime} - {formattedEndTime}',
    'Session.Header.Session': 'Sessão',
    'Session.Header.AcceptSessionRequest': 'Confirme se deseja aceitar esta solicitação de sessão e adicioná-la à sua agenda.',
    'CommonComponent.RecurrenceForm.Label.UntilDate': 'Até a data',
    'CommonComponent.RecurrenceForm.Label.Period': 'Período',
    'CommonComponent.RecurrenceForm.Option.Daily': 'Diariamente',
    'CommonComponent.RecurrenceForm.Option.Weekly': 'Semanalmente',
    'CommonComponent.RecurrenceForm.Label.Weekday': 'Dia da semana',
    'CommonComponent.RecurrenceForm.Text.NoSelectedWeekdays': 'Nenhum dia da semana selecionado',
    'CommonComponent.RecurrenceForm.Error.PleaseSelectWeekdays': 'Selecione o(s) dia(s) da semana',
    'Calendar.EventType.Availability': 'Disponibilidade',
    'Calendar.EventType.Session': 'Sessão',
    'Calendar.EventType.Training': 'Treinamento',
    'Calendar.EventType.CompanyEvent': 'Evento empresarial',
    'Calendar.EventType.NewEvent': 'Novo evento',
    'Calendar.Text.NewEventTime': 'Novo evento {time}',
    'Calendar.Header.Training': 'Treinamento',
    'Calendar.Modal.Text.Sessions': 'Sessões',
    'Calendar.Modal.Text.Trainings': 'Treinamentos',
    'Calendar.Modal.Text.Events': 'Eventos',
    'Calendar.Modal.Text.PendingNoSS': '- Pendente (sem SS).',
    'Calendar.Modal.Text.PendingSwap': '- Pendente (troca).',
    'Calendar.Modal.Text.PreBooked': '- Pré-reservado.',
    'Calendar.Modal.Text.NeedsReview': '- Precisa de revisão.',
    'Calendar.Modal.Text.JoinSession': '- você pode entrar na sessão.',
    'Calendar.Modal.Text.NoLearnersInSession': '- não há alunos na sessão.',
    'Calendar.Modal.Text.NoLearnersInSessionAfterBookedTime': '- não há alunos na sessão após o horário reservado.',
    'Calendar.Modal.Header.Events': 'Eventos',
    'Calendar.Modal.Header.Session': 'sessão',
    'Calendar.Modal.Header.Training': 'treinamento',
    'Calendar.Modal.Header.Availability': 'disponibilidade',
    'Calendar.Modal.Filter.Client': 'Cliente',
    'Calendar.Modal.Filter.Project': 'Projeto',
    'Calendar.Modal.Filter.Time': 'Horário',
    'Calendar.Modal.Label.Recurrence': 'Recorrência',
    'Calendar.Modal.Header.CreateAvailability': 'Criar disponibilidade',
    'Calendar.Modal.Text.Recurring': 'Recorrente',
    'Calendar.SimSpecialistButton.Modal.Header.Scheduled': 'Agendado',
    'Calendar.SimSpecialistButton.Modal.Header.ThisPeriod': '(este período)',
    'Calendar.SimSpecialistButton.Modal.Label.Sessions': 'Sessões',
    'Calendar.SimSpecialistButton.Modal.Label.Hours': 'Horas',
    'Calendar.SimSpecialistButton.Modal.Label.Trainings': 'Treinamentos',
    'Calendar.SimSpecialistButton.Modal.Label.TrainingsHours': 'Horas de treinamento',
    'Calendar.SimSpecialistButton.Modal.Label.Available': 'Disponível',
    'Calendar.SimSpecialistButton.Modal.Header.Certification': 'Certificação',
    'Calendar.SimSpecialistButton.Modal.Text.NoCertificates': 'Nenhum certificado',
    'Calendar.SimSpecialistButton.Modal.Header.SimSpecialistOverviewPanel': 'Panel da visão geral do especialista em simulação',
    'Calendar.SimSpecialistButton.Modal.HoverTitle.CloseSimSpecialistOverview': 'fechar visão geral do especialista em simulação',
    'MursionPortal.DeliveryMode.OneToOne': '1:1',
    'MursionPortal.DeliveryMode.OneToManyRemote': 'Remoto',
    'MursionPortal.DeliveryMode.OneToManyLocal': 'Local (Fishbowl)',
    'MursionPortal.DeliveryMode.Group': 'Entrega em grupo',
    'MursionPortal.Projects.Header.Trainees': 'Trainees',
    'Projects.Placeholder.SimContentName': 'Digitar nome do conteúdo de simulação',
    'Projects.Message.NoSimulationContentFound': 'Nenhum conteúdo de simulação encontrado',
    'MursionPortal.Button.UncheckAll': 'Desmarcar tudo',
    'MursionPortal.Button.CheckAll': 'Marcar tudo',
    'Projects.Header.NewScenario': 'Projeto {projectName}',
    'MursionPortal.Button.SaveAsDraft': 'Salvar como rascunho',
    'MursionPortal.Button.Save': 'Salvar',
    'Projects.Message.SelectEnvironmentFirst': 'Selecione um ambiente primeiro',
    'Projects.Message.LoadingAvatarsList': 'Carregando lista de avatares...',
    'Projects.Message.NoAssignedAvatars': 'O ambiente selecionado não tem avatares atribuídos',
    'Projetcs.EmptyMessage.NoSelectedAvatars': 'Nenhum avatar selecionado',
    'Projects.Avatars.NoAvatarsSpecified': 'Nenhum avatar especificado',
    'Projects.Message.SelectIndustryFirst': 'Selecione um setor primeiro',
    'Projects.Message.LoadingEnvironmentsList': 'Carregando lista de ambientes...',
    'Projects.Message.NoAssignedEnvironments': 'O setor selecionado não tem ambientes atribuídos',
    'Projects.Message.ScenarioHasNoAssignedEnvironment': 'O cenário não tem ambiente atribuído',
    'Projects.AltText.EnvironmentImage': 'environment-image',
    'Projects.Placeholder.SelectAnEnviroment': 'Selecionar um ambiente',
    'Projects.Message.UnnamedIndustry': 'Setor sem nome',
    'Projects.Placeholder.SelectAnIndustry': 'Selecionar um setor',
    'Projects.Header.NewProject': 'Novo projeto',
    'Projects.AltText.ClientLogo': 'Logo do cliente',
    'Projects.Message.SelectAnyTimeBlock': 'Selecione qualquer bloco de tempo',
    'Projects.Message.SelectScenarioFirst': 'Selecione o cenário primeiro',
    'MursionPortal.Text.Asset': 'Ativo V2',
    'MursionPortal.Button.Accept': 'Aceitar',
    'Projects.Modal.EmptyMessage.NoLearner': 'Nenhum aluno',
    'MursionPortal.Label.SelectAll': 'Selecionar tudo',
    'Projects.Modal.Placeholder.ChooseATeam': 'Escolher uma equipe',
    'MursionPortal.Placeholder.SearchTeams': 'Buscar equipes...',
    'Projects.Modal.Text.SessionSize': 'O tamanho da sessão permitida é igual a {maxLearners}. Selecionar alunos específicos',
    'Projects.Modal.Placeholder.SearchLearners': 'Buscar alunos',
    'MursionPortal.ErrorMessage.MaxLearners': 'Você só pode adicionar até {maxLearners} trainees para este tipo de sessão.',
    'MursionPortal.ErrorMessage.MaxLearner': 'Você só pode adicionar até {maxLearners} trainees para este tipo de sessão.',
    'MursionPortal.Label.ChooseMembers': 'Escolher membros',
    'MursionPortal.Message.NoMembersToSelect': 'Nenhum membro para selecionar',
    'MursionPortal.Message.PleaseSelectATeamFirst': 'Selecione uma equipe primeiro',
    'AssetSync.Header.AssetSync': 'Sincronização de ativos',
    'AssetSync.Text.AssetsSyncedSuccessfully': 'Os ativos foram sincronizados com sucesso',
    'AssetSync.Button.Sync': 'Sincronizar',
    'AssetSync.Button.TryAgain': 'Tente novamente',
    'AssetSync.SyncButton.Text.Syncing': 'Sincronizando',
    'SessionFlow.Label.SessionExport': 'Exportação de sessão',
    'MursionPortal.Button.Export': 'Exportar',
    'SessionFlow.Label.SessionFlowData': 'Dados do fluxo da sessão',
    'SessionFlow.Button.LoadSessionData': 'Carregar dados da sessão',
    'SessionFlow.Button.StartSessionAnalytics': 'Iniciar análise da sessão',
    'SessionFlow.Label.Checklist': 'Checklist',
    'SessionFlow.Label.RecordingSettings': 'Configurações de gravação',
    'SessionFlow.Label.SessionVideoFact': 'Fato do vídeo da sessão',
    'SessionFlow.Label.UserStatus': 'Status do usuário',
    'SessionFlow.SessionVideoFact.ColumnHeader.VideoAllowed': 'Vídeo permitido',
    'MursionPortal.Table.ColumnHeader.UserName': 'Nome de usuário',
    'MursionPortal.Table.ColumnHeader.Email': 'E-mail',
    'MursionPortal.Table.ColumnHeader.Status': 'Status',
    'SessionFlow.StartSessionAnalyticsButton.Text.EventStartingAnalytics': 'O evento para iniciar o processo de análise foi publicado com sucesso',
    'SessionFlow.RecordingSettings.Label.VideoRecording': 'Gravação de vídeo',
    'SessionFlow.RecordingSettings.Text.Allowed': 'Permitido',
    'SessionFlow.RecordingSettings.Text.Disallowed': 'Não permitido',
    'SessionFlow.RecordingSettings.Label.RecordingPermission': 'Permissão de gravação',
    'SessionFlow.RecordingSettings.Text.LiesWithLearner': 'Cabe ao aluno',
    'SessionFlow.RecordingSettings.Label.RecordedVideosGoBackTo': 'Os vídeos gravados vão para',
    'SessionFlow.Error.CannotFindSession': 'Não é possível encontrar uma sessão com o ID fornecido',
    'SessionFlow.DetailedEventLogButton.Table.ColumnHeader.Type': 'Tipo',
    'SessionFlow.DetailedEventLogButton.Table.ColumnHeader.Date': 'Data',
    'SessionFlow.DetailedEventLogButton.Table.ColumnHeader.Error': 'Erro',
    'SessionFlow.DetailedEventLogButton.Table.ColumnHeader.Payload': 'Carga útil',
    'SessionFlow.SessionFlowData.PlaceHolder.SessionID': 'ID da sessão',
    'SessionFlow.Button.HideDetailedEventLog': 'Ocultar registro detalhado de eventos...',
    'SessionFlow.Button.ShowDetailedEventLog': 'Mostrar registro detalhado de eventos...',
    'SessionFlow.Button.HideSessionHistory': 'Ocultar histórico de sessão...',
    'SessionFlow.Button.ShowSessionHistory': 'Mostrar histórico de sessão...',
    'SessionFlow.Text.NoEvents': 'Nenhum evento',
    'SessionFlow.SessionVideoFact.Label.VideoExists': 'O vídeo existe',
    'SessionFlow.Text.NoLearners': 'Nenhum aluno',
    'SessionFlow.Checklist.DataRecording': 'gravação de dados',
    'SessionFlow.Checklist.DataUploading': 'upload de dados',
    'SessionFlow.Checklist.Analytics': 'análise',
    'Companies.ArchiveIconButton.Modal.ConfirmationText.ArchiveCompany': 'Tem certeza de que deseja arquivar esta empresa?',
    'Companies.ArchiveIconButton.Modal.Header.ArchiveCompany': 'Arquivar empresa',
    'Companies.Label.CompanyInfo': 'Informações empresariais',
    'Companies.CompanyInfo.Archived': '(Arquivado)',
    'Companies.CancelIconButton.HoverTitle.CancelEdit': 'Cancelar edição',
    'Companies.EditIconButton.HoverTitle.EditCompany': 'Editar empresa',
    'Companies.Button.InviteProfService': 'Convidar serviço profissional',
    'Companies.InviteProfServiceButton.Modal.Header.NewProfService': 'Novo serviço profissional',
    'Companies.Label.Config': 'CONFIG',
    'Companies.EditIconButton.HoverTitle.EditConfig': 'Editar configuração',
    'Companies.Config.Button.Test': 'Teste',
    'Companies.Config.Button.SaveCompanyConfig': 'Salvar configuração da empresa',
    'Companies.CompanyInfo.Button.SaveCompanyInfo': 'Salvar informações empresariais',
    'Companies.ValidationError': 'Existem erros de validação',
    'Companies.Config.SaveCompanyConfigButton.FailedToSaveConfig': 'Falha ao salvar uma configuração. Corrija os erros.',
    'Companies.Config.Text.TestPassedSuccessfully': 'Teste aprovado com sucesso',
    'Companies.CreateUserButton.PlaceHolder.CompanyName': 'Nome da empresa',
    'Companies.Button.CreateCompany': 'Criar empresa ',
    'Companies.Error.NoCompanies': 'Nenhuma empresa',
    'Companies.InviteProfServiceButton.Modal.Label.ProfServices': 'Serviços profissionais',
    'Companies.InviteProfServiceButton.Modal.Button.AddUser': 'Adicionar usuário',
    'Companies.CreateUserButton.Modal.Header.NewCompany': 'Nova empresa',
    'Companies.Header.AllCompanies': 'todas as empresas',
    'MursionPortal.Roles': 'funções',
    'Users.ArchiveButton.Modal.Header.ArchivedGlobalUser': 'Usuário global arquivado',
    'Users.ActiveButton.Modal.Header.EditGlobalUser': 'Editar usuário global',
    'Users.CreateUserButton.Modal.Header.NewGlobalUser': 'Novo usuário global',
    'Users.NoGlobalUserFoundError': 'Nenhum usuário global foi encontrado.',
    'Users.Table.ColumnHeader.Department': 'Departamento',
    'MursionPortal.TechSupport.HoverTitle.ShowFeedBack': 'mostrar feedback',
    'MursionPortal.TechSupport.HoverTitle.HideFeedBack': 'ocultar feedback',
    'MursionPortal.TechSupport': 'Suporte técnico',
    'MursionPortal.TechSupport.Header.ContactSupport': 'Entrar em contato com o suporte',
    'MursionPortal.TechSupport.Label.Phone': 'Telefone',
    'MursionPortal.TechSupport.Label.Email': 'E-mail',
    'MursionPortal.TechSupport.Label.Hours': 'Horas',
    'MursionPortal.TechSupport.Link.MursionSupport': 'Suporte do Mursion',
    'Downloads.Text.ClickDownloadButton': 'Clique no botão de download abaixo para obter a versão mais recente do instalador. Este executável será salvo no local de download padrão do navegador (p.ex., pasta de downloads).',
    'Downloads.Text.OnceDownloadIsComplete': 'Quando o download for concluído, clique no executável baixado e siga as instruções na tela para concluir a instalação.',
    'Downloads.Text.AppInstalled': 'Com o aplicativo instalado, você pode clicar duas vezes no ícone da Mursion na próxima vez que precisar iniciá-lo.',
    'Downloads.Button.Download': 'Download',
    'Downloads.Text.PlatformNotCurrentlySupported': 'Desculpe, mas sua plataforma não é compatível no momento.',
    'Downloads.Text.FollowOnScreenInstructions': 'Clique em {info} e siga as instruções na tela para instalar o aplicativo',
    'Downloads.Text.ShortcutCreatedOnInstallationComplete': 'Assim que a instalação for concluída, um atalho para o Mursion será criado em sua área de trabalho. {lineBreak} Você pode clicar duas vezes no atalho {image} quando precisar iniciar o aplicativo para experimentar uma simulação agendada.',
    'Filters.Date.LastWeek': 'Semana passada',
    'MursionPortal.Text.Mins': 'mins',
    'MursionPortal.Text.Min': 'min',
    'Tutorial.Header.ManageYourTeams': 'Gerencie suas equipes',
    'MursionPortal.Button.Awesome': 'Fantástico',
    'Tutorial.Header.ReviewYourProjects': 'Revise seus projetos',
    'MursionPortal.Button.LooksGood': 'Está ótimo',
    'Tutorial.Header.LearnerScheduling': 'Agendamento do aluno',
    'MursionPortal.Button.AllDone': 'Tudo pronto!',
    'Tutorial.Header.InviteYourUsers': 'Convide seus usuários',
    'MursionPortal.Button.GotIt': 'Entendi',
    'Tutorial.Header.CreateATeam': 'Crie uma equipe',
    'Tutorial.Header.ManageYourProjects': 'Gerencie seus projetos',
    'Tutorial.Header.YourTeams': 'Suas equipes',
    'Tutorial.Header.YourProjects': 'Seus projetos',
    'Tutorial.Header.SessionCalendar': 'Calendário de sessão',
    'Tutorial.Tooltip.TeamsOrganize': 'As equipes organizam seus alunos e serão adicionadas aos cenários',
    'Tutorial.Text.ViewSessions': 'Visualize as sessões <code>programadas e concluídas</code> da equipe',
    'Tutorial.Tooltip.ContactManager': 'ou entre em contato com seu gerente de projeto na empresa ({companyName})',
    'Tutorial.Tooltip.YourPointOfContact': 'Ele é seu contato para tudo relacionado a aprendizagem',
    'Tutorial.Text.TeamAdded': 'Uma equipe será <code>adicionada a um cenário</code> para iniciar o agendamento',
    'Tutorial.Text.ViewLearners': 'Visualize as <code>atividades de agendamento</code> e o <code>status</code> dos alunos',
    'Tutorial.Text.EnterEmail': 'Insira um <code>endereço de e-mail</code> para cada novo usuário do portal da empresa ({companyName})',
    'Tutorial.Text.RegistrationEmail': 'Um <code>e-mail de registro</code> será enviado para sua caixa de entrada',
    'Tutorial.Text.TeamsOrganize': 'As equipes <code>organizam seus alunos</code> e são gerenciadas por um facilitador',
    'Tutorial.Text.UniqueName': 'Dê um <code>nome exclusivo</code> à sua equipe e insira um local',
    'Tutorial.Text.SessionDelivery': 'Mais tarde, uma equipe será <code>adicionada a um cenário</code> para a <code>entrega da sessão</code>',
    'Tutorial.Tooltip.Description': 'Descrição, resultado e estratégias',
    'Tutorial.Text.BeginScheduling': 'Uma equipe será <code>adicionada a um cenário</code> para iniciar o agendamento',
    'Tutorial.Text.MonitorSessions': 'Monitore as <code>sessões programadas e a </code>disponibilidade<code> de cada projeto</code>',
    'Tutorial.Text.LearnersWithoutSessions': 'Visualize os alunos que <code>ainda não agendaram</code> uma sessão',
    'Tutorial.Tooltip.LearnersSchedule': 'Os alunos também podem agendar por conta própria',
    'Tutorial.Text.AddedToLearners': 'Você será adicionado a <code>1 ou mais</code> equipes de alunos',
    'Tutorial.Tooltip.TeamAdmin': 'Seu administrador de equipe e contato',
    'Tutorial.Text.TeamAddedBeforeProject': 'Sua equipe será <code>adicionada a um cenário</code> antes do início do projeto',
    'Tutorial.Text.AddRemoveLearners': '<code>Adicione ou remova</code> alunos de suas <tooltip>equipes</tooltip>',
    'Tutorial.Text.AddedByCompany': 'Você será adicionado a um cenário/projeto pelo <tooltip>proprietário da conta</tooltip>  da sua empresa',
    'Tutorial.Text.ViewYourHours': 'Visualize seus horários e cenários ou entre em contato com seu <tooltip><code>gerente de projeto</code></tooltip>',
    'Tutorial.Text.ScenariosDetail': 'Os cenários contêm os <tooltip>detalhes</tooltip> que orientam sua <code>experiência na empresa ({companyName})</code>',
    'Tutorial.Text.SelectTimeSlot': 'Selecione um intervalo de tempo aberto para <tooltip><code>agendar um aluno</code></tooltip> para uma sessão',
    'Tutorial.Text.ChooseRole': 'Escolha uma função (<tooltip>proprietário da conta</tooltip>, <secondTooltip>facilitador</secondTooltip> ou <thirdTooltip>aluno</thirdTooltip>) para cada novo usuário',
    'Tutorial.Tooltip.AdminManages': 'Administrador que gerencia usuários e projetos',
    'Tutorial.Tooltip.ManagesScheduling': 'Gerencia o agendamento e a entrega de sessão',
    'Tutorial.Tooltip.SchedulesSessions': 'Agenda e participa das sessões',
    'Tutorial.Text.ScenariosContain': 'Os cenários contêm os <tooltip>detalhes</tooltip> que orientam sua <code>experiência na empresa ({companyName})</code>',
    'Tutorial.Text.SelectOpenTimeSlot': 'Selecione um intervalo de tempo aberto para <tooltip><code>agendar um aluno</code></tooltip>',
    'Tutorial.Text.AdminOfTeam': 'O <tooltip>facilitador</tooltip> é o administrador e contato de sua equipe',
    'Tutorial.Text.ProjectsContain': 'Os projetos contêm <code>cenários</code> com os <tooltip>detalhes</tooltip> que orientam sua <code>experiência de aprendizagem</code>',
    'Tutorial.Text.ViewAvailability': 'Visualize a disponibilidade e <code>agende sessões</code>',
    'Tutorial.Text.TrackSessions': 'Acompanhe as <code>sessões programadas</code> de cada projeto. Cancele ou remarque, se necessário',
    'Tutorial.Text.ReviewPerformance': 'Analise seu <code>desempenho</code> e a <code>conclusão de estratégias</code> após a sessão',
    'Tutorial.Header.Complete': 'Completo',
    'Tutorial.Text.RevisitThisGuide': 'Você pode conferir este guia e outras informações úteis {lineBreak} <code>a qualquer momento</code> na <code>base de conhecimento. </code>',
    'Tutorial.Button.TakeMeToThePortal': 'Leve-me ao portal',
    'Tutorial.Header.DownloadInstallMursionSimulator': 'Baixe e instale {lineBreak} o Simulador do Mursion',
    'Tutorial.Text.PlanningToUseMursionOnComputer': 'Se você planeja usar este computador para executar simulações do Mursion, recomendamos fortemente instalar o software necessário agora — basta clicar no botão "Instalar Mursion" abaixo.',
    'Tutorial.Text.InstallSoftwareFromPortal': 'Você pode optar por instalar o software diretamente do portal mais tarde. Lembre-se de que a instalação do simulador imersivo do Mursion para o primeiro uso pode levar vários minutos. Planeje instalar o software pelo menos 30 minutos antes do início da simulação para ter tempo suficiente para aproveitar o Mursion plenamente.',
    'Tutorial.Text.ProvideInstructionsForInstallation': 'Continuaremos fornecendo instruções para instalação e outros processos de tempos em tempos através de e-mails e tutoriais no portal e no simulador do Mursion. Esperamos que você goste de suas simulações tanto quanto nós gostamos de criá-las!',
    'Tutorial.Text.TheMursionTeam': 'Equipe do Mursion.',
    'Tutorial.Button.InstallLater': 'Instalar depois',
    'Tutorial.Button.InstallMursion': 'Instalar Mursion',
    'Tutorial.Header.LetsGetStarted': 'Vamos começar',
    'Tutorial.Text.ShortIntro': 'Primeiro, confira aqui uma <code>breve apresentação</code> para ver algumas {lineBreak} das funções comuns <code>que você tem como usuário</code>',
    'Tutorial.Button.Proceed': 'Prosseguir',
    'Tutorial.Label.Step': 'ETAPA',
    'Tutorial.Label.NoSound': 'Este vídeo não tem som.',
    'Tutorial.Button.SkipTutorial': 'Pular tutorial',
    'Tutorial.Button.CloseTutorial': 'Fechar tutorial',
    'MursionPortal.Text.On': 'On',
    'MursionPortal.Text.Off': 'Off',
    'MursionPortal.Text.MursionIcon': 'mursion-icon',
    'MursionPortal.AriaLabel.CreateNew': 'criar novo',
    'MursionPortal.Legend.VisibilityHidden.Buttons': 'Botões de cenário: Criar novo, Repetir cliente, Repetir banco',
    'MursionPortal.Label.VisibilityHidden.EnterEmailId': 'Digitar ID de email',
    'MursionPortal.Label.VisibilityHidden.SelectRegistrationStatus': 'Selecionar status de registro',
    'MursionPortal.Label.VisibilityHidden.ClientLogoUpload': 'upload do logotipo do cliente',
    'MursionPortal.Header.VisibilityHidden.CompanyUsers': 'Usuários empresariais',
    'MursionPortal.Legend.VisibilityHidden.StationType': 'Tipo de estação',
    'MursionPortal.Legend.VisibilityHidden.Hours': 'Horas',
    'MursionPortal.Caption.VisibilityHidden.CompanyUsersList': 'lista de usuários empresariais',
    'MursionPortal.AriaLabel.Search': 'buscar',
    'MursionPortal.AriaLabel.From': 'De',
    'MursionPortal.AriaLabel.To': 'Para',
    'MursionPortal.AriaLabel.RemoveAccountOwner': 'remover proprietário da conta',
    'MursionPortal.AriaLabel.InboxMessagePanel': 'painel de mensagens da caixa de entrada',
    'MursionPortal.AriaLabel.Breadcrumb': 'breadcrumb',
    'MursionPortal.Message.NoItemsHaveBeenFound': 'Nenhum item foi encontrado.',
    'MursionPortal.AriaLabel.Add': 'adicionar',
    'MursionPortal.AriaLabel.SortByDescendingOrder': 'classificar por ordem decrescente',
    'MursionPortal.AriaLabel.SortByAscendingOrder': 'classificar por ordem crescente',
    'MursionPortal.AriaLabel.AddButton': 'adicionar botão',
    'MursionPortal.AriaLabel.Timezone': 'fuso horário',
    'MursionPortal.AriaLabel.Combobox': 'combobox',
    'MursionPortal.AriaLabel.PreviousMonth': 'mês anterior',
    'MursionPortal.AriaLabel.NextMonth': 'mês seguinte',
    'MursionPortal.AriaLabel.SearchInput': 'campo de busca',
    'MursionPortal.AriaLabel.Informational': 'informativo',
    'MursionPortal.Text.RedirectToCalendarPage': 'redirecionar para página do calendário',
    'MursionPortal.AriaLabel.StartDate': 'data de início {startFormattedDate}',
    'MursionPortal.AriaLabel.EndDate': 'data de término {endFormattedDate}',
    'MursionPortal.AriaLabel.CreateProject': 'criar projeto',
    'MursionPortal.AriaLabel.ContentInfo': 'informação de conteúdo',
    'MursionPortal.AriaLabel.DraftSuperScript': 'Rascunho sobrescrito {statusConfiguration}',
    'MursionPortal.AriaLabel.FinalizedSuperScript': 'Sobrescrito finalizado {statusConfiguration}',
    'MursionPortal.AriaLabel.StartedSuperScript': 'Sobrescrito iniciado {statusConfiguration}',
    'MursionPortal.AriaLabel.FinishedSuperScript': 'Sobrescrito finalizado {statusConfiguration}',
    'MursionPortal.AriaLabel.ArchivedSuperScript': 'Sobrescrito arquivado {statusConfiguration}',
    'MursionPortal.AriaLabel.EnterScenarioName': 'inserir nome do cenário',
    'MursionPortal.AriaLabel.Capitalized.Search': 'Buscar',
    'MursionPortal.AriaLabel.Close': 'Fechar',
    'MursionPortal.AriaLabel.CreateTeam': 'criar equipe',
    'MursionPortal.AriaLabel.CreateClient': 'criar cliente',
    'MursionPortal.AriaLabel.CreateUser': 'criar usuário',
    'MursionPortal.AriaLabel.ZoomId': 'ID do Zoom',
    'MursionPortal.AriaLabel.PrimaryContent': 'Conteúdo principal',
    'MursionPortal.AriaLabel.RegistrationStatus': 'status do registro',
    'MursionPortal.AriaLabel.EnterTagName': 'inserir nome da tag',
    'MursionPortal.AriaLabel.EnterUserName': 'inserir nome de usuário',
    'MursionPortal.AriaLabel.SearchProjectManagers': 'buscar gerentes de projeto',
    'MursionPortal.AriaLabel.SearchSSO': 'buscar SSO',
    'MursionPortal.AriaLabel.SearchAccountOwner': 'buscar proprietário da conta',
    'MursionPortal.WarningMessage.AllowedCharacterLimit': 'O limite permitido de caracteres ({maximumLength} caracteres) foi excedido',
    'MursionPortal.WarningMessage.InvalidCharactersDetected': 'Carateres inválidos detectados',
    'MursionPortal.Days.Sunday': 'Domingo',
    'MursionPortal.Days.Monday': 'Segunda-feira',
    'MursionPortal.Days.Tuesday': 'Terça-feira',
    'MursionPortal.Days.Wednesday': 'Quarta-feira',
    'MursionPortal.Days.Thursday': 'Quinta-feira',
    'MursionPortal.Days.Friday': 'Sexta-feira',
    'MursionPortal.Days.Saturday': 'Sábado',
    'MursionPortal.Button.AddNew': 'Adicionar novo',
    'MursionPortal.Title.EditNotes': 'Editar observações',
    'MursionPortal.IconButton.Remove': 'remover',
    'MursionPortal.Filter.ProviderAll': 'Fornecedor',
    'MursionPortal.Placeholder.SearchProviders': 'Buscar fornecedores...',
    'MursionPortal.AriaLabel.InboxButton': 'botão da caixa de entrada Você tem {messageCount} novas mensagens',
    'MursionPortal.AriaLabel.Event': 'evento',
    'MursionPortal.Title.SelectedDate': 'Data selecionada',
    'MursionPortal.Title.CurrentDate': 'Data atual',
    'MursionPortal.Title.OffRangeDate': 'data fora do intervalo',
    'MursionPortal.Label.ClientLevel': 'Nível do cliente',
    'MursionPortal.Label.TeamLevel': 'Nível de equipe',
    'MursionPortal.AltText.UserImage': 'user-image',
    'MursionPortal.AltText.KbMenu': 'kb-menu',
    'MursionPortal.AltText.AvatarImage': 'avatar-image',
    'MursionPortal.AltText.EnvironmentImage': 'environment-image',
    'MursionPortal.AriaLabel.Cancel': 'cancelar',
    'MursionPortal.AriaLabel.Edit': 'editar',
    'MursionPortal.AriaLabel.EditAccountOwner': 'editar proprietário da conta',
    'MursionPortal.AriaLabel.Remove': 'remover',
    'MursionPortal.AriaLabel.SearchFilter': 'filtro de busca',
    'MursionPortal.AriaLabel.TierCombobox': 'nível combobox',
    'MursionPortal.AriaLabel.SortByAscending': 'classificar em ordem ascendente',
    'MursionPortal.AriaLabel.SortByDescending': 'classificar em ordem decrescente',
    'MursionPortal.Message.VisibilityHidden.SelectingDeliveryMode': 'ao selecionar um modo de entrega, você poderá definir a duração e o tamanho da sessão',
    'MursionPortal.AltText.DownloadComputerIcon': 'Ícone do computador para download do Mursion',
    'MursionPortal.AltText.DownloadIcon': 'Ícone de download do Mursion',
    'MursionPortal.AltText.MursionLogo': 'Logo do Mursion',
    'MursionPortal.AriaLabel.MursionSupport': 'suporte do mursion',
    'MursionPortal.AltText.MursionDashboard': 'Mursion-Dashboard',
    'MursionPortal.AltText.BackgroundMaleUser': 'Usuário masculino de fundo do Mursion',
    'MursionPortal.AltText.BackgroundGroupUser': 'Usuário de grupo de fundo do Mursion',
    'MursionPortal.AltText.BackgroundFemaleUser': 'Usuária feminina de fundo do Mursion',
    'MursionPortal.ErrorMessage.CharacterLimitExceeded': 'O limite permitido de caracteres ({maximumLength} caracteres) foi excedido',
    'MursionPortal.ErrorMessage.InvalidCharactersDetected': 'Carateres inválidos detectados',
    'MursionPortal.ErrorMessage.EnterValidPhoneNumber': 'Você deve inserir um número de telefone válido.',
    'MursionPortal.Message.InvitationLinkInvalid': 'Seu link de convite é inválido ou pode ter expirado. {lineBreak} Se você ainda não terminou o registro, {lineBreak} peça um novo convite ao seu gerente.',
    'MursionPortal.Header.SetYourPassword': 'Coloque sua senha',
    'MursionPortal.Message.PasswordMessage': 'Sua senha deve conter o seguinte:',
    'MursionPortal.Label.NewPassword': 'Nova senha',
    'MursionPortal.Label.RepeatPassword': 'Repetir senha',
    'MursionPortal.Button.SetPassword': 'Definir senha',
    'MursionPortal.Header.ErrorMessage.SomethingWentWrong': 'Ocorreu um {lineBreak} erro. {lineBreak} Entre em contato com o suporte',
    'MursionPortal.Header.ContactSupport': 'ENTRAR EM CONTATO COM O SUPORTE',
    'MursionPortal.Label.Phone': 'Telefone',
    'MursionPortal.Label.Email': 'E-mail',
    'MursionPortal.Label.Hours': 'Horas',
    'MursionPortal.Message.EnterPasswordToConfirm': 'Digite sua senha para confirmar esta alteração',
    'MursionPortal.Label.Password': 'Senha',
    'MursionPortal.Message.BrowserDialog': 'Se você não vir uma caixa de diálogo do navegador, clique em <code>Abrir Mursion </code> abaixo{lineBreak} ou use o atalho do Mursion na área de trabalho para abrir o aplicativo do Mursion.',
    'MursionPortal.Button.OpenMursion': 'Abrir ML3 Desktop App',
    'MursionPortal.Message.FollowInstructions': 'Clique em {appName} e siga as instruções na tela para instalar o aplicativo',
    'MursionPortal.Text.PositiveImpact': 'Quando sentir que está tendo um impacto positivo, clique no botão <code>{icon}</code>.',
    'MursionPortal.Text.NegativeImpact': 'Quando sentir que está tendo um impacto negativo, clique no botão <code>{icon}</code>.',
    'MursionPortal.Text.NeutralImpact': 'Quando sentir que seu impacto não foi positivo nem negativo, clique no botão <code>{icon}</code>.',
    'MursionPortal.Title.Next': 'Avançar',
    'MursionPortal.Title.Start': 'Início',
    'MursionPortal.Text.UpdateYourRating': 'Enquanto assiste ao vídeo, você pode atualizar sua classificação a qualquer momento que achar que está tendo um impacto diferente no avatar.',
    'MursionPortal.Text.SeeCurrentRating': 'O gráfico embaixo do vídeo será atualizado para que você possa ver sua classificação atual e alterá-la se achar que seu impacto mudou.',
    'MursionPortal.Text.ClickStartToBegin': 'Clique em "Iniciar" para começar. Você pode clicar em "Concluir" depois de classificar toda a simulação. Observe que a funcionalidade de busca de vídeo pode ser desativada durante partes deste vídeo para preservar a integridade dos dados.',
    'MursionPortal.Header.HowWouldYouProceed': 'Como deseja prosseguir?',
    'MursionPoral.Button.SwitchUser': 'Mudar usuário',
    'MursionPortal.Button.ContinueAs': 'Continue como <code>{userName}</code>',
    'MursionPortal.Text.ToContinue': 'Para continuar como <code>{loginData}</code>, clique em <code>Alterar usuário</code>.',
    'MursionPortal.Message.YouAreSignedIn': 'Você está conectado no momento como <code>{userName}</code> ({userProfile}).',
    'MursionPortal.Title.ContinueAs': 'Continue como {userName}',
    'MursionPortal.Message.OpenLink': 'Abrir link',
    'MursionPortal.Message.Allow': 'Permitir',
    'MursionPortal.Message.Open': 'Abrir',
    'MursionPortal.Message.Yes': 'Sim',
    'MursionPortal.Message.OpenMursionApp': 'Abrir MursionApp',
    'MursionPortal.PasswordRequirementMessage.MinimumCharacters': 'Pelo menos {PASSWORD_MIN_LENGTH} caracteres',
    'MursionPortal.Message.PortalHeading': 'Projete, programe e gerencie seu',
    'MursionPortal.PasswordRequirementMessage.UppercaseLetters': 'Letras maiúsculas + Letras minúsculas',
    'MursionPortal.PasswordRequirementMessage.Numbers': 'qualquer combinação de números',
    'MursionPortal.ErrorMessage.InvalidEmailFormat': 'Formato de email inválido.',
    'MursionPortal.ErrorMessage.EmailCanNotBeEmpty': 'O e-mail não pode ficar vazio.',
    'MursionPortal.ErrorMessage.EnterValidEmailAddress': 'Insira um endereço de e-mail válido.',
    'MursionPortal.ErrorMessage.PasswordCannotBeEmpty': 'A senha não pode estar em branco',
    'MursionPortal.ErrorMessage.UrlCannotBeEmpty': 'O URL não pode ficar em branco',
    'MursionPortal.ErrorMessage.UrlIsNotValid': 'O URL não é válido',
    'MursionPortal.ErrorMessage.NoMeetingId': 'Não contém o ID da reunião',
    'MursionPortal.ValidationErrorMessage.FileRequestFailed': 'Falha na solicitação de arquivo',
    'MursionPortal.ValidationErrorMessage.FileExceedsLimit': 'O arquivo excede o limite de 5 MB',
    'MursionPortal.ValidationErrorMessage.EnterMessage': 'Digite sua mensagem antes de enviar',
    'MursionPortal.ValidationErrorMessage.MessageTooLong': 'Mensagem longa demais',
    'MursionPortal.ValidationErrorMessage.InvalidUrl': 'O URL do arquivo não é válido',
    'MursionPortal.Button.SubmitFeedback': 'Enviar feedback',
    'MursionPortal.Header.Feedback': 'Feedback',
    'MursionPortal.Text.AdditionalComments': 'Comentários ou sugestões adicionais',
    'MursionPortal.Header.ThanksForFeedback': 'Obrigado por seu feedback!',
    'MursionPortal.Button.Close': 'Fechar',
    'MursionPortal.Title.ShowFeedback': 'mostrar feedback',
    'MursionPortal.Title.HideFeedback': 'ocultar feedback',
    'MursionPortal.ErrorMessage.FileTypeNotSupported': 'O tipo de arquivo {type} não é compatível',
    'MursionPortal.ErrorMessage.ScenarioDuration': 'A duração do cenário deve ser entre {durationMinimum} e {durationMaximum}.',
    'MursionPortal.ErrorMessage.ScenarioScheduledSessions': 'Há {sessionsCount} sessões agendadas para este cenário. Cancele essas sessões antes de arquivar este cenário.',
    'MursionPortal.ErrorMessage.ScenarioScheduledSession': 'Há uma {sessionsCount} sessão agendada para este cenário. Cancele esta sessão antes de arquivar este cenário.',
    'MursionPortal.ErrorMessage.ClientScheduledSessions': 'Há {sessionsCount} sessões agendadas para este cliente. Cancele essas sessões antes de arquivar o cliente.',
    'MursionPortal.ErrorMessage.ClientScheduledSession': 'Há {sessionsCount} sessão agendada para este cliente. Cancele esta sessão antes de arquivar o cliente.',
    'MursionPortal.EulaAgreement.EndUserAgreement': 'Este Acordo do Usuário Final ("Acordo") é um contrato legal celebrado entre você, o usuário e a Mursion, Inc. ("Mursion") e está sujeito aos termos e condições do Acordo de Software como Serviço do Mursion entre o Mursion e a entidade do cliente do Mursion através da qual você está usando o Software do Mursion. Para fins de esclarecimento, você reconhece e concorda que, como condição de uso e acesso ao Software do Mursion ("Software do Mursion"), você e a entidade que está fornecendo acesso ao Software do Mursion estão sujeitos e vinculados aos termos e condições deste Acordo.',
    'MursionPortal.EulaAgreement.TermsAndConditions': 'Sujeito aos termos e condições deste Acordo e do Acordo de Software como Serviço do Mursion, o Mursion concede a você direitos limitados, não exclusivos, não transferíveis e não sublicenciáveis de acessar e usar o Software do Mursion.',
    'MursionPortal.EulaAgreement.AgreeAndAcknowledge': 'Você concorda e reconhece que não tem o direito de, e não irá, copiar, modificar, fazer engenharia reversa, descompilar, desmontar ou criar trabalhos derivados baseados parcial ou integralmente no Software do Mursion ou em materiais relacionados. Você concorda em não anular, desabilitar ou contornar qualquer mecanismo de proteção relacionado ao Software do Mursion. Ao tomar conhecimento de qualquer posse, uso ou acesso não autorizado ao Software do Mursion, você notificará a Mursion imediatamente e concorda também em fornecer prontamente informações de cada ocorrência, para auxiliar razoavelmente na prevenção de qualquer recorrência (na medida do possível) e cooperar plenamente em qualquer procedimento realizado para proteger os direitos do Mursion.',
    'MursionPortal.EulaAgreement.ConstruedAsGranting': 'Exceto conforme explicitamente declarado neste documento, nada contido neste Acordo deve ser interpretado como concessão, seja por implicação ou de outra forma, de quaisquer direitos adicionais à propriedade intelectual do Mursion sem a aprovação prévia por escrito do Mursion.',
    'MursionPortal.EulaAgreement.NonDisclosure': 'Você concorda em não mostrar nem divulgar o Software do Mursion ou qualquer parte dele a qualquer pessoa para qualquer finalidade que não esteja expressamente permitida neste documento.',
    'MursionPortal.EulaAgreement.AgreeAndRelease': 'Por meio deste, você concorda em liberar, dispensar e isentar de qualquer outra forma o Mursion, seus funcionários, executivos, agentes, afiliados e diretores de toda e qualquer reclamação, conhecida e desconhecida, que surgir, resultar ou de outra forma se relacionar ao uso do Software do Mursion.',
    'MursionPortal.EulaAgreement.UnderstandAndAcknowledge': 'Você entende e reconhece que o Mursion pode coletar, manter, usar, armazenar e analisar informações e dados coletados do seu uso do Software do Mursion, sujeito aos termos da política de privacidade do Mursion que podem ser encontrados aqui.',
    'MursionPortal.EulaAgreement.AccessRights': 'O uso e os direitos de acesso concedidos a você pelo Mursion devem terminar imediatamente quando o Acordo de Software como Serviço do Mursion, através do qual o acesso ao Software do Mursion é fornecido a você, expira ou é rescindido, ou imediatamente após você violar qualquer disposição deste Acordo.',
    'MursionPortal.EulaAgreement.AgreementGoverned': 'O presente Acordo será regido e interpretado de acordo com as leis do Estado de Delaware, independentemente de conflitos de princípios legais. Todas as disputas decorrentes ou relacionadas ao seu uso do Software do Mursion ou deste contrato devem ser levadas a um tribunal estadual ou federal de jurisdição competente no Estado de Delaware, e você concorda com a jurisdição pessoal em tais tribunais.',
    'MursionPortal.EulaAgreement.ProvisionsDeclaredInvalid': 'Se qualquer cláusula deste Acordo for declarada inválida, elas serão removidas deste Acordo e as outras cláusulas deste documento permanecerão em pleno vigor e efeito.',
    'MursionPortal.EulaAgreement.AgreementConflict': 'No caso de os termos deste Acordo entrarem em conflito com os termos do Acordo de Software como Serviço do Mursion, os termos aqui contidos prevalecerão.',
    'MursionPortal.EulaAgreement.AccessToMursionSofware': 'Seu uso ou acesso ao Software do Mursion constitui aceitação deste Acordo e dos termos e condições aqui declarados.',
    'MursionPortal.Header.MursionUserAgreement': 'Acordo de Usuário e do Mursion',
    'MursionPortal.Button.Back': 'Voltar',
    'MursionPortal.Button.Skip': 'Pular',
    'MursionPortal.Title.BackToSessions': 'Voltar para as sessões',
    'MursionPortal.Title.Proceed': 'Prosseguir',
    'MursionPortal.Message.SkipActivity': 'Se você optar por pular, perderá as informações de desempenho que nosso mecanismo de análise comportamental pode fornecer, por isso, recomendamos que você não pule esta atividade.',
    'MursionPortal.HeaderTitle.ConfirmCancellation': 'Confirmar cancelamento',
    'MursionPortal.Label.Delete': 'excluir',
    'MursionPortal.Label.Restore': 'restaurar',
    'MursionPortal.HeaderTitle.ConfirmRestore': 'Confirmar restauração',
    'MursionPortal.Label.Archive': 'arquivar',
    'MursionPortal.Button.CancelTraining': 'Cancelar treinamento',
    'MursionPortal.Button.No': 'Não',
    'MursionPortal.Button.CancelEvent': 'Cancelar evento',
    'MursionPortal.Label.Update': 'atualizar',
    'MursionPortal.HeaderTitle.ConfirmUpdate': 'Confirmar atualização',
    'MursionPortal.Button.UpdateSession': 'Atualizar sessão',
    'MursionPortal.Label.Swap': 'alternar',
    'MursionPortal.HeaderTitle.ConfirmAcceptedSwap': 'Confirmar troca aceita',
    'MursionPortal.Button.AcceptSwap': 'Aceitar troca',
    'MursionPortal.Label.Reason': 'Motivo',
    'MursionPortal.Placeholder.ReasonForSwapping': 'Adicione um motivo para a troca.',
    'MursionPortal.Label.Request': 'solicitar',
    'MursionPortal.HeaderTitle.ConfirmAcceptedRequest': 'Confirmar solicitação aceita',
    'MursionPortal.Button.AcceptRequest': 'Aceitar solicitação',
    'MursionPortal.Placeholder.ReasonForAccepting': 'Adicione um motivo para aceitar.',
    'MursionPortal.Button.ConfirmProjectChange': 'Confirme a alteração do projeto',
    'MursionPortal.HeaderTitle.Confirm': 'Confirmar',
    'MursionPortal.Button.ConfirmCreateSession': 'Confirmar criação da sessão',
    'MursionPortal.Placeholder.ReasonForCreation': 'Adicione um motivo para a criação.',
    'MursionPortal.Button.ConfirmAssigningSimSpecialist': 'Confirmar atribuição de especialista em simulação',
    'MursionPortal.Placeholder.ReasonForAssigning': 'Adicione um motivo para a atribuição.',
    'MursionPortal.Button.ConfirmChangingSimSpecialist': 'Confirmar alteração do especialista em simulação',
    'MursionPortal.Placeholder.ReasonForChanging': 'Adicione um motivo para a alteração.',
    'MursionPortal.HeaderTitle.ConfirmTimeZone': 'Confirmar fuso horário',
    'MursionPortal.Message.DontHaveMursionInstalled': 'Não tem o ML3 Desktop App instalado? Clique em <code>Baixar</code> para instalar.',
    'MursionPortal.ErrorMessage.CannotContainCharacter': 'O nome do cenário não deve conter o caractere "_"',
    'MursionPortal.ErrorMessage.EnterDifferentName': '{capitalized} {fieldName} já existe. Insira um {objectName} {fieldName} diferente.',
    'MursionPortal.ErrorMessage.EnterDifferentEmail': 'O e-mail de usuário já existe. Insira um endereço de e-mail diferente.',
    'MursionPortal.ErrorMessage.NameExists': 'Já existe um usuário com o mesmo {fieldName}. Insira um {fieldName} diferente.',
    'MursionPortal.ErrorMessage.UnableToRemove': 'Não foi possível remover {entity}. Esta {entity} já está sendo usada em uma {depEntity} ativa.',
    'MursionPortal.ErrorMessage.OverlapsExistingTimeBlock': 'O bloco de tempo selecionado se sobrepõe a um bloco de tempo existente.',
    'MursionPortal.ErrorMessage.AccessIsDenied': 'Acesso negado.',
    'MursionPortal.ErrorMessage.SomethingMustBeBroken': 'Algo deve estar quebrado. Tente novamente mais tarde.',
    'MursionPortal.ErrorMessage.Unauthorized': 'Não autorizado',
    'MursionPortal.ErrorMessage.RefreshTokenNotFound': 'Token de atualização não encontrado',
    'MursionPortal.ErrorMessage.CannotRefreshToken': 'Não é possível atualizar o token',
    'MursionPortal.QADialog.NoParticipants': 'Nenhum participante',
    'MursionPortal.ErrorMessage.UsersUnavailable': 'Alguns dos seus usuários não estão disponíveis no horário selecionado.',
    'Filters.SsoProtocolType': 'Protocolo: Todos',
    'Filters.SsoProtocolTypePlaceHolderHint': 'Buscar protocolo SSO',
    'Filters.SSO.PrivacyAll': 'Tipo todo',
    'Filters.SSO.PrivacyPublic': 'Tipo público',
    'Filters.SSO.PrivacyPrivate': 'Tipo privado',
    'Filters.SSO.PrivacyAriaLabel': 'filtro de tipo de privacidade sso',
    'Cleints.Modal.Label.DomainsTitle': 'A lista de domínios que podem ser usados para iniciar sessão',
    'MursionPortal.NoDomains': 'Sem domínios',
    'MursionPortal.Header.Domains': 'Domínios',
    'MursionPortal.AddDomain': 'Adicionar domínio',
    'Clients.Modal.Projects.ButtonTitle.EditDomains': 'Editar domínios',
    'Clients.Modal.Projects.DomainField.EnterDomain': 'Digite um nome de domínio (ex., exemplo.com)',
    'Clients.Modal.Projects.DomainField.InvalidDomain': 'Formato de domínio inválido',
    'MursionPortal.ErrorMessage.SomethingWrongWithResponse': 'Ocorreu um erro com a resposta.',
    'MursionPortal.ErrorMessage.AuthorizationHeaderIsEmpty': 'O cabeçalho de autorização está vazio',
    'MursionPortal.ErrorMessage.CannotRefreshAccessToken': 'Não é possível atualizar o token de acesso',
    'MursionPortal.ErrorMessage.AuthorizationTokenIsEmpty': 'O token de autorização está vazio',
    'MursionPortal.ErrorMessage.AuthenticationFailed': 'Falha na autenticação.',
    'MursionPortal.ErrorMessage.UnsupportedResponseFormat': 'Formato de resposta não compatível.',
    'MursionPortal.ErrorMessage.AccountBlocked': 'Sua conta foi bloqueada até {blockedDate}. Tente iniciar sessão mais tarde.',
    'MursionPortal.ErrorMessage.AttemptsRemaining': 'Restam {attempts} tentativas antes de um bloqueio de 24 horas. Se precisar de ajuda, redefina sua senha.',
    'MursionPortal.ErrorMessage.ClientIdMustBeSpecified': 'O ID do cliente deve ser especificado',
    'MursionPortal.ErrorMessage.ProjectIdMustBeSpecified': 'O ID do projeto deve ser especificado',
    'MursionPortal.ErrorMessage.SessionIdMustBeSpecified': 'O ID da sessão deve ser especificado',
    'MursionPortal.ErrorMessage.SSOIdMustBeSpecified': 'O ID do SSO deve ser especificado',
    'MursionPortal.ErrorMessage.LearnerIdMustBeSpecified': 'O ID do aluno deve ser especificado',
    'MursionPortal.ErrorMessage.UserIdMustBeSpecified': 'O ID do usuário deve ser especificado',
    'MursionPortal.ErrorMessage.TeamIdMustBeSpecified': 'O ID da equipe deve ser especificado',
    'MursionPortal.ErrorMessage.AssetProjectIdMustBeSpecified': 'O ID do projeto do ativo deve ser especificado',
    'MursionPortal.ErrorMessage.AssetSettingsIdMustBeSpecified': 'O ID de configuração do ativo deve ser especificado',
    'MursionPortal.ErrorMessage.ScenarioIdMustBeSpecified': 'O ID do cenário deve ser especificado',
    'MursionPortal.ErrorMessage.ScenarioTemplateIdMustBeSpecified': 'O ID do modelo de cenário deve ser especificado',
    'MursionPortal.ErrorMessage.LicenseIdMustBeSpecified': 'fetchUsersList: o ID do licenciado deve ser especificado',
    'MursionPortal.ErrorMessage.LicenseCertificateIdMustBeSpecified': 'fetchLicenseeCertificates: o ID do licenciado deve ser especificado',
    'MursionPortal.ErrorMessage.LicenseConfigIdMustBeSpecified': 'fetchConfig: o ID do licenciado deve ser especificado',
    'MursionPortal.ErrorMessage.LicenseUpdateConfigIdMustBeSpecified': 'updateConfig: o ID do licenciado deve ser especificado',
    'MursionPortal.ErrorMessage.LicenseTestConfigIdMustBeSpecified': 'testConfig: o ID do licenciado deve ser especificado',
    'MursionPortal.ErrorMessage.LicenseFetchTiersIdMustBeSpecified': 'fetchTiers: o ID do licenciado deve ser especificado',
    'MursionPortal.ErrorMessage.LicenseUpdateTiersIdMustBeSpecified': 'updateTiers: o ID do licenciado deve ser especificado',
    'MursionPortal.ErrorMessage.GlobalUserIdMustBeSpecified': 'O ID do usuário global deve ser especificado',
    'MursionPortal.ErrorMessage.ScenarioTemplateListLicenseIdMustBeSpecified': 'fetchScenarioTemplatesList: o ID do licenciado deve ser especificado',
    'MursionPortal.ErrorMessage.ScenarioAttachmentListIdMustBeSpecified': 'fetchScenarioAttachmentLink: o ID do cenário e o ID do anexo devem ser especificados',
    'MursionPortal.ErrorMessage.FetchScenarioIdMustBeSpecified': 'fetchScenario: o ID do cenário deve ser especificado',
    'Projects.ProjectInfo.Checkbox.AllowRecordingInfo': 'Isso não excluirá as gravações salvas atuais.',
    'MursionPortal.AriaLabel.ToggleMenu': 'alternar menu',
    'MursionPortal.ClientListItem.RedirectToUserDetails': 'Redirecionar para detalhes de {name}',
    'MursionPortal.AriaLabel.PreviousWeek': 'semana passada',
    'MursionPortal.AriaLabel.PreviousDay': 'dia anterior',
    'MursionPortal.AriaLabel.NextWeek': 'próxima semana',
    'MursionPortal.AriaLabel.NextDay': 'dia anterior',
    'Dashboard.LeftPane.CategoryHeader.SessionsDashboard': 'Painel de sessões',
    'MursionPortal.Title.SessionsPage': 'Página de sessões',
    'Clients.heading.Clients': 'Clientes',
    'MursionPortal.AriaLabel.Client': 'cliente',
    'MursionPortal.AriaLabel.SimSpecialist': 'SimSpecialist',
    'MursionPortal.Project.TimeZoneStartDate': 'Data de início do fuso horário',
    'MursionPortal.Project.TimeZoneEndDate': 'Data de término do fuso horário',
    'MursionPortal.AriaLabel.SelectTimezoneCombobox': 'selecione a caixa de fuso horário',
    'MursionPortal.Support.PhoneNumber': '1-855-999-5818',
    'MursionPortal.Message.LearnerEmpty': 'Selecione os alunos que participaram da sessão',
    'Dashboard.ProjectsTable.StatusValue.Active': 'ATIVO',
    'Dashboard.ProjectsTable.StatusValue.Completed': 'CONCLUÍDO',
    'Dashboard.ProjectsTable.StatusValue.Archived': 'ARQUIVADO',
    'MursionPortal.Button.Refresh': 'Atualizar',
    'MursionPortal.Prompt.CalendarUpdated': 'O calendário foi atualizado.',
    'MursionPortal.Prompt.ClickRefresh': 'Clique em "Atualizar" para visualizar as alterações.',
    'MursionPortal.Label.SessionAttendance': 'Presença na sessão',
    'MursionPortal.Label.SessionVideoUrl': 'URL da sessão de vídeo',
    'MursionPortal.Label.LearnerAttendance': 'Presença do aluno',
    'MursionPortal.Text.UserProfileUpdated': 'Um perfil de usuário foi atualizado!',
    'MursionPortal.Text.ClientUpdated': 'Um cliente foi atualizado!',
    'MursionPortal.Text.ProjectUpdated': 'Um projeto foi atualizado!',
    'MursionPortal.Text.ScenarioUpdated': 'Um cenário foi atualizado!',
    'MursionPortal.Text.LicenseeConfigUpdated': 'A configuração do licenciado foi atualizada!',
    'MursionPortal.Prompt.TrainingScheduled': '<code>O treinamento</code> foi agendado!',
    'Settings.SSO.Table.ColumnHeader.ClientName': 'Nome do cliente',
    'MursionPortal.Text.UserCreatedSuccessfully': 'Usuário criado com sucesso',
    'MursionPortal.Text.UserNotCreated': 'Usuário não criado',
    'MursionPortal.Text.AssignedTeams': 'Equipes selecionadas',
    'MursionPortal.Client.EmailSettings.Invitation': 'Convite',
    'MursionPortal.Client.EmailSettings.InvitationReminder': 'Lembrete de convite',
    'MursionPortal.Client.EmailSettings.Confirmation': 'Confirmação',
    'MursionPortal.Client.EmailSettings.Cancellation': 'Cancelamento',
    'MursionPortal.Client.EmailSettings.FirstReminder': 'Primeiro lembrete',
    'MursionPortal.Client.EmailSettings.SecondReminder': 'Segundo lembrete',
    'MursionPortal.Client.EmailSettings.SessionRecording': 'A gravação da sessão está pronta para o titular da conta',
    'MursionPortal.Client.EmailSettings.LearnerSession': 'A gravação da sessão do aluno está pronta',
    'MursionPortal.Client.EmailSettings.SimulationSessionVideoRejected': 'A gravação da simulação da sessão de vídeo foi recusada',
    'MursionPortal.Client.EmailSettings.SimulationSessionVideoDisallowed': 'A gravação da simulação da sessão de vídeo não é permitida',
    'MursionPortal.Client.EmailSettings.SimulationSessionVideoRejectedUser': 'A gravação da simulação da sessão de vídeo foi recusada por um usuário',
    'MursionPortal.Client.EmailSettings.RequestRecieved': 'Solicitar confirmação de recebimento',
    'MursionPortal.Client.EmailSettings.RequestNotFullfilled': 'Não foi possível completar a solicitação',
    'MursionPortal.Client.EmailSettings.RequestDateTime': 'Solicitar mudança de data/hora necessária',
    'MursionPortal.Client.EmailSettings.Title.SelectEmails': 'Selecione os e-mails que deseja enviar aos alunos. A Mursion recomenda que você habilite todos os e-mails.',
    'MursionPortal.Client.EmailSettings.Header.Registration': 'Registro',
    'MursionPortal.Client.EmailSettings.Header.TrainingSession': 'Sessão de treinamento',
    'MursionPortal.Client.EmailSettings.Header.Videos': 'Vídeos',
    'MursionPortal.Client.EmailSettings.Header.Requests': 'Solicitações',
    'MursionPortal.AriaLabel.ToggleButton': 'Botão de alternar',
    'MursionPortal.Client.EmailSettings.Hint.RecordingEnabled': 'somente se as gravações estiverem habilitadas',
    'MursionPortal.Client.EmailSettings.Hint.RequestFeature': 'somente se solicitar o recurso estiver habilitado',
    'MursionPortal.SuccessDialog.Title': 'Diálogo de sucesso',
    'MursionPortal.Dialog.CreateSSOConfiguartion.SuccessMessage': 'A configuração de SSO do cliente foi criada com sucesso.',
    'ITManager.TechRestriction.Table.EmptyMessage': 'Nenhuma restrição de tecnologia do banco foi encontrada.',
    'ITManager.TechRestriction.Table.Tag': 'Marcador',
    'ITManager.TechRestriction.Table.StartDate': 'Data de início',
    'ITManager.TechRestriction.Table.EndDate': 'Data de término',
    'ITManager.TechRestriction.EditDialog.EnterTag': 'inserir nome do marcador',
    'ITManager.TechRestriction.Table.HoverTitle': 'Ver/editar restrição de tecnologia',
    'ITManager.TechRestriction.Table.NoTechRestrictionError': 'Não há restrições de tecnologia no momento',
    'MursionPortal.DateTimePicker.StartTime': 'Horário de início',
    'MursionPortal.DateTimePicker.EndTime': 'Horário de término',
    'MursionPortal.TechRestrictions': 'Restrições de tecnologia',
    'MursionPortal.Caption.ScenarioBankUsersList': 'lista de usuários do banco do cenário',
    'MursionPortal.Caption.SsoConfigurationList': 'lista de configuração sso',
    'MursionPortal.AriaLabel.DateRangeCalendarButton': 'aperte enter para interagir com o calendário e selecione a data de início e de término',
    'MursionPortal.Modal.Header.SessionTimeout': 'Sessão expirada',
    'MursionPortal.Modal.Body.SessionTimeout': 'Your session will timeout in 1 minute, please continue to extend your session', // TODO
    'MursionPortal.Status.PendingNoSS': 'Pendente (sem SS)',
    'MursionPortal.Status.PendingNoL': 'Pendente (sem A)',
    'MursionPortal.Status.PendingNoLearners': 'Pendente (sem alunos)',
    'MursionPortal.Status.PendingNoLSS': 'Pendente (sem A/SS)',
    'Dashboard.LeftPane.SupplyManagement.SimView': 'Exibição Sim',
    'Dashboard.LeftPane.SupplyManagement.SchedulingView': 'Exibição do agendamento',
    'MursionPortal.Table.ColumnSelection.Button.Label': 'Colunas',
    'Session.Table.Column.SubmitRequest': 'Enviar solicitação',
    'Filters.SimSpecialistStatus': 'Status do Sim Specialist',
    'Filters.SimSpecialistStatusPlaceHolderHint': 'Filtro de pesquisa por status do Sim Specialist...',
    'Users.TableModal.SSOUserID': 'ID do usuário SSO',
    'MursionPortal.UnavailableSlotsError.SorryText': 'Olá! Sinto muito!',
    'MursionPortal.UnavailableSlotsError.BusyText': 'Parece que estamos ocupados simulando com outros alunos.',
    'MursionPortal.UnavailableSlotsError.TryAgainText': 'Por favor, tente novamente amanhã ou outro dia!',
    'MursionPortal.Session.Survey.ClickHere': 'Clique aqui',
    'MursionPortal.Session.SurveyNotLoading': 'A pesquisa não está carregando?',
    'Users.Table.Heading.Viewonly': '(Somente visualização)',
    'MursionPortal.BookItUI.BusyText': 'Parece que estamos ocupados simulando com outros alunos no momento.',
    'MursionPortal.BookItUI.TryLaterText': 'Mais sessões de simulação devem ser abertas em breve, por favor, tente novamente ainda hoje.',
    'MursionPortal.BookItUI.MoreSimulationsText': 'Mais sessões de simulação devem ser abertas em breve.',
    'MursionPortal.BookItUI.ComeBackText': 'Por favor, volte se você quiser fazer sua simulação ainda hoje.',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Archived': 'Usuário arquivado',
    'ClientUserTable.AddNewUsers.UpdateArchivedUserWarning': '{archivedUsers} usuário(s) está(ão) arquivado(s) no momento. Ao prosseguir, você adicionará esse(s) usuário(s) às equipes selecionadas e desarquivará os usuários.',
    'ClientUserTable.AddNewUsers.UpdateExistingArchivedUserWarning': 'Esses usuários {existingUsers} já existem e os usuários {archivedUsers} estão atualmente arquivados. Continuar adicionará esses usuários às equipes selecionadas e também desarquivará os usuários arquivados.',
    'MursionPortal.ProjectCreateStep.UpperLimitValidation.Hours': 'Não pode ser maior do que {code} horas',
    'MursionPortal.ProjectCreateStep.UpcomingAvailability': 'Disponibilidade futura',
    'MursionPortal.Table.ColumnHeader.TotalSession': 'Sessões totais',
    'MursionPortal.Table.ColumnHeader.ActiveCertifications': 'Certificações ativas',
    'MursionPortal.Button.Decline': 'Recusar',
    'MursionPortal.Having.Issues.Logging.In': 'Está com problemas para fazer login?',
    'MursionPortal.Label.Call': 'Chamar:',
    'MursionPortal.Project.Hours': 'Hora(s)',
    'Users.Button.ProfService': 'Serviço profissional',
    'Users.Button.Operations': 'Serviço de operações',
    'Users.Button.SimDesigner': 'Sim Designer',
    'MursionPortal.ArtProject.Placeholder.EnterOrSelect': 'Selecione ou insira',
    'MursionPortal.Setting.LearnerCommunicationPersonalization': 'Personalização da comunicação com o aluno',
    'MursionPortal.LearnerCommunicationPersonalization.Heading': 'Você pode personalizar mensagens específicas para seus alunos aqui.',
    'MursionPortal.SupplyBasedScheduling.SubmitPage.SimulationConfirmation': 'Você está confirmado para a sua simulação! Recomendamos chegar alguns minutos antes do horário de início.',
    'MursionPortal.DemandBasedScheduling.SubmitPage.LearnerConfirmed': '{learners} confirmados para a simulação!',
    'MursionPortal.DemandBasedScheduling.SubmitPage.DateAndTime': 'Data e hora',
    'MursionPortal.Personalized.LearnerDashboard.Section': 'Mensagem que você deseja que todos os seus alunos vejam no Painel do aluno',
    'MursionPortal.Personalized.SchedulingPage.Section': 'Mensagem que você deseja que todos os seus alunos vejam na Página de agendamento',
    'MursionPortal.Personalized.Section.Tooltip': 'A mensagem será atualizada em todos os lugares',
    'MursionPortal.CheckboxLabel.JoinNow': 'Participe agora',
    'MursionPortal.ProjectCreateStep.UpcomingAvailability.Tooltip': 'Seriam mostrados para as funções de cliente horários desabilitados para determinadas horas em \'Próximas disponibilidade\' após \'Disponibilidade do Sim\'. Se a \'Disponibilidade do Sim\' for 0, nenhum horário desativado será mostrado e apenas horários com \'Disponibilidade do Sim\' serão exibidos. Se a \'Próxima disponibilidade\' for de 24 horas, todos os horários disponíveis após as horas com \'Disponibilidade do Sim\' serão mostrados como horários desativados por um dia.',
    'MursionPortal.LearnerDashboard.JoinNow.OutsideOfficeHoursText': 'Por favor, volte mais tarde durante o horário de expediente da sua organização para realizar este cenário.',
    'MursionPortal.Setting.Personalization.LastUpdated': 'Última atualização:',
    'MursionPortal.CheckboxLabel.JoinNowRequests': 'Solicitações para participar agora',
    'MursionPortal.LearnerDashboard.JoinNow.HeaderText': 'Estamos combinando você com um avatar <code>para uma simulação ao vivo</code>. Por favor, reveja os materiais abaixo para se preparar.',
    'MursionPortal.LearnerDashboard.JoinNow.SorryText': 'Sentimos muito!',
    'MursionPortal.LearnerDashboard.JoinNow.AvatarsBusyText': 'Todos os nossos avatares estão ocupados com outros alunos. Volte mais tarde ou tente novamente em alguns minutos.',
    'MursionPortal.LearnerDashboard.JoinNow.CongratsText': 'Parabéns! Você foi combinado com um avatar!',
    'MursionPortal.LearnerDashboard.JoinNow.AvatarsMatchText': 'O avatar está esperando por você! Clique em "Participar de simulação ao vivo" quando você estiver pronto, ou sua experiência começará automaticamente após a contagem regressiva.',
    'MursionPortal.LearnerDashboard.JoinNow.StartLearning': 'If you are not able to get connected, click below to start learning.',
    'MursionPortal.LearnerDashboard.JoinNow.StarBucks.MyLearning': 'Starbucks MyLearning',
    'MursionPortal.LearnerDashboard.JoinNow.StarBucks.LiveSimulationExp': 'This session is a live simulation experience. Partners can now choose to take an eLearning on de-escalating conflict in MyLearning, as an alternative to the live safety simulation.',
    'MursionPortal.Button.Leave': 'Sair',
    'MursionPortal.Personalized.ScenarioPage.Section': 'Mensagem que você deseja que os alunos vejam na página do cenário',
    'MursionPortal.Personalized.CreateNewScenario.Button.Label': 'Criar novo cenário específico',
    'MursionPortal.Personalized.Scenario.Search.Placeholder': 'Pesquise ou selecione o(s) cenário(s):',
    'MursionPortal.Personalized.Scenario.Selection.Title': 'Adicionar novo cenário específico',
    'MursionPortal.Personalized.NoMessage': 'No momento, nenhuma mensagem definida',
    'MursionPortal.Personalized.MetaData.NoHistory': 'Ainda não há história.',
    'MursionPortal.JoinNow.AlreadyBooked.Message': '{code} está reservado para uma sessão neste momento.',
    'Users.Table.SendInvite.TooltipText': 'Desativar e-mails de registro também desativa os botões "clique para convidar" no portal',
    'MursionPortal.JoinNow.AlreadyBooked.JoinSession.Message': 'Clique em "Participar da simulação ao vivo" para entrar ou retomar a sessão.',
    'Dashboard.LeftPane.CategoryHeader.Scenarios': 'Cenários',
    'Dashboard.LeftPane.Learners.TeamAndProjectActivity': 'Equipe e atividade do projeto',
    'MursionPortal.Dashboard.Chart.label.LearnerStatus.NotYetScheduled': 'Ainda não agendado',
    'MursionPortal.Dashboard.Chart.label.LearnerStatus.Scheduled': 'Ainda não concluído',
    'MursionPortal.Dashboard.Chart.label.LearnerStatus.LearnerCompleted': 'Concluído',
    'MursionPortal.Dashboard.Chart.label.LearnerStatus.NotYetAssigned': 'Ainda não atribuído',
    'MursionPortal.Dashboard.Chart.Tooltip.LearnerStatus.NotYetScheduled': 'Número distinto de alunos atribuídos a pelo menos uma equipe, a qual está atribuída a pelo menos um cenário, mas onde o aluno nunca agendou uma sessão.',
    'MursionPortal.Dashboard.Chart.Tooltip.LearnerStatus.Scheduled': 'Número distinto de alunos que agendaram pelo menos uma sessão, mas que ainda não compareceram e concluíram pelo menos uma sessão.',
    'MursionPortal.Dashboard.Chart.Tooltip.LearnerStatus.CompletedAssigned': 'Número distinto de alunos que concluíram pelo menos uma sessão.',
    'MursionPortal.Dashboard.Chart.Tooltip.LearnerStatus.NotYetAssigned': 'Número distinto de alunos que não estão atribuídos a uma equipe ou alunos que fazem parte de uma equipe que não está atribuída a nenhum cenário.',
    'MursionPortal.Dashboard.ScenarioLearner.Header.LearnerStatus': 'Conclusão do aluno',
    'MursionPortal.Dashboard.ScenarioLearner.Header.LearnerSequence': 'Alunos únicos',
    'MursionPortal.Dashboard.ScenarioLearner.Header.SequenceCount': '(Máximo de {sessionCount} sessões)',
    'Dashboard.SequenceOrder.Header.Title': 'Atividades de perdas e cancelamento e reagendamento tardios',
    'MursionPortal.Integrations': 'Integrações',
    'MursionPortal.Personalized.Message.Delete.Title': 'Tem certeza de que deseja excluir a mensagem?',
    'MursionPortal.Personalized.Message.Delete.Tooltip': 'Excluir mensagem',
    'MursionPortal.Personalized.Scenario.Message.OnlyOneAtTime': 'As alterações podem ser feitas em apenas uma mensagem de cada vez. Salve ou cancele o que já está no modo de edição.',
    'MursionPortal.Error.Time': '6h - 22h ET',
    'MursionPortal.CheckboxLabel.JoinNow.Tooltip.Text': 'O agendamento pelo BookIt é sempre ativado quando "Participar agora" está habilitado.',
    'MursionPortal.JoinNow.FinalizedScenario.Message': 'O cenário está finalizado, por favor, volte assim que o cenário estiver ativo.',
    'MursionPortal.CheckboxLabel.sessionMissedTime': 'Tempo perdido da sessão',
    'MursionPortal.Title.Seconds': 'Segundo(s)',
    'MursionPortal.RequestTable.Accept.ErrorMessage': 'Desculpe, a sessão foi cancelada.',
    'MursionPortal.Client.EmailTypeFilter.SchedulingLocked': 'Agendamento bloqueado',
    'MursionPortal.Client.EmailTypeFilter.SchedulingUnlocked': 'Agendamento desbloqueado',
    'MursionPortal.ScenarioInfo.SchedulingLockout.Time': 'Bloqueio de agendamento',
    'MursionPortal.Table.ColumnHeader.SimAvailable': 'Sim disponível',
    'MursionPortal.CheckboxLabel.sessionMissedTime.Tooltip.Text': 'Tempo após o qual a sessão será marcada como perdida se nenhum aluno ou SIM entrar.',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.NotYetCompleted': 'Ainda não concluído',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.completedOneSession': 'Concluída 1 sessão',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.completedTwoPlusSession': 'Concluídas 2+ sessões',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.OneSession': '1 sessão',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.TwoSession': '2 sessões',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.ThreeSession': '3 sessões',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.FourPlusSession': '4+ sessões',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.NotYetCompleted': 'Número de alunos exclusivos atribuídos a esse cenário, mas que ainda não o concluíram',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.completedOneSession': 'Número de alunos exclusivos atribuídos a este cenário que o concluíram uma vez.',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.completedTwoPlusSession': 'Número de alunos exclusivos atribuídos a esse cenário que o concluíram duas vezes ou mais.',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.OneSession': 'Número de alunos únicos que perderam, cancelaram ou reagendaram tardiamente uma sessão.',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.TwoSession': 'Número de alunos únicos que perderam, cancelaram e/ou reagendaram tardiamente duas sessões.',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.ThreeSession': 'Número de alunos únicos que perderam, cancelaram e/ou reagendaram tardiamente três sessões.',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.FourPlusSession': 'Número de alunos únicos que perderam, cancelaram e/ou reagendaram tardiamente quatro ou mais sessões.',
    'MursionPortal.ScenarioBank.ItemBank.Label': 'Blocos de construção',
    'MursionPortal.ScenarioBank.ItemBank.Skills': 'Habilidades',
    'MursionPortal.ScenarioBank.ItemBank.Domains': 'Domínios',
    'MursionPortal.ScenarioBank.ItemBank.Events': 'Eventos',
    'MursionPortal.ScenarioBank.ItemBank.Pathways': 'Percursos',
    'MursionPortal.ScenarioBank.ItemBank.Resources': 'Recursos',
    'MursionPortal.ScenarioBank.ItemBank.Outcomes': 'Resultados',
    'MursionPortal.ScenarioBank.ItemBank.Mindsets': 'Mentalidades',
    'MursionPortal.ScenarioBank.ItemBank.Avatars': 'Avatares',
    'MursionPortal.ScenarioBank.ItemBank.ResearchSummaries': 'Resumos da pesquisa',
    'MursionPortal.ScenarioBank.ItemBank.LearnerInstructions': 'Instruções do aluno',
    'MursionPortal.ScenarioBank.ItemBank.Column.Level': 'Nível',
    'MursionPortal.ScenarioBank.ItemBank.Column.Domain': 'Domínio',
    'MursionPortal.ScenarioBank.ItemBank.Column.Translations': 'Traduções',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Skill': 'Criar habilidade',
    'MursionPortal.Dashboard.Label.ScenarioActivity.ErrorMessage': 'Selecione pelo menos um Projeto e Cenário nos filtros',
    'MursionPortal.Learner.Onboarding': 'Integração do aluno',
    'MursionPortal.Tab.Onboarding': 'INTEGRAÇÃO',
    'MursionPortal.OrganizationName.Placeholder': 'Digite o nome da sua organização',
    'Dashboard.LeftPane.Learners.LearnerLockedOut': 'Aluno bloqueado',
    'Dashboard.Learners.LearnerLockedOut.TableHeader': 'Alunos que estão impedidos de agendar devido à taxa de faltas/cancelamento',
    'Dashboard.Learners.LearnerLockedOut.LockedOutColumn': 'Data/hora bloqueada para agendamento',
    'Dashboard.Learners.LearnerLockedOut.AutoReleaseColumn': 'Data/hora até o lançamento automatizado',
    'MursionPortal.DontHaveEmail.Text': 'Você não tem um endereço de e-mail da sua organização?',
    'MursionPortal.InvalidCompanyName.Error': 'Sua organização exige que os usuários façam login usando o endereço de e-mail. Por favor, insira-o acima.',
    'MursionPortal.LearnerOnboardingPage.Text.Welcome': 'Bem-vindo(a)',
    'MursionPortal.LearnerOnboardingPage.Text.MursionVideoOverview': 'Visão geral em vídeo da Mursion',
    'MursionPortal.LearnerOnboardingPage.Text.KeyThingsToKnow': 'Principais coisas a saber',
    'MursionPortal.LearnerOnboardingPage.Welcome.Section.Text': 'Estamos muito felizes em recebê-lo(a) em seu programa de aprendizagem Mursion! A Mursion usa simulações imersivas impulsionadas pela tecnologia Humano-no-loop, que fornece uma experiência de aprendizado abrangente e que irá equipá-lo(a) com as ferramentas e técnicas necessárias para ter sucesso em um mundo em constante evolução.',
    'MursionPortal.LearnerOnboardingPage.Welcome.Section.Beginner.Text': 'Sejam iniciantes ou profissionais experientes, nosso programa tem algo a oferecer a todos. Estamos muito animados para você começar.',
    'MursionPortal.LearnerOnboardingPage.Video.Section.Text': 'Primeiro, assista a este vídeo para obter uma visão geral <strong>sobre a Mursion!</strong>',
    'MursionPortal.LearnerOnboardingPage.Scheduled.Section.Text': '<strong>Agora você está pronto(a) para:</strong>Agendar sua primeira simulação!',
    'MursionPortal.LearnerOnboardingPage.keysThingsToKnow.Section.Heading': 'A seguir, veremos algumas coisas importantes para você saber!',
    'MursionPortal.LearnerOnboardingPage.Mursion.Methodology': 'Metodologia Mursion',
    'MursionPortal.LearnerOnboardingPage.Mursion.Methodology.Summary': 'Mursion é fundamentado em pesquisas e comprovadamente impulsiona a mudança de comportamentos. Nossas simulações baseadas em avatar ajudarão você a aprender a responder adequadamente em situações emocionalmente difíceis.',
    'MursionPortal.LearnerOnboardingPage.Mursion.WhyAvatars.Text': 'Por que Avatares?',
    'MursionPortal.LearnerOnboardingPage.Mursion.WhyAvatars.Summary': 'Mursion é uma ferramenta mais eficaz do que os métodos tradicionais ou de role-play. Pesquisas mostram que as pessoas ficam mais confiantes e curiosas ao engajar com avatares, em comparação a interagir com seus colegas ou gerentes.',
    'MursionPortal.LearnerOnboardingPage.Human.Text': 'O Humano no Loop',
    'MursionPortal.LearnerOnboardingPage.Human.Summary': 'Cada simulação Mursion apresenta um Simulation Specialist que fornece ao vivo o diálogo falado e os movimentos para até cinco avatares virtuais em cada simulação.',
    'MursionPortal.LearnerOnboardingPage.Practice.Text': 'Por que a prática é importante',
    'MursionPortal.LearnerOnboardingPage.Practice.Text.Summary': 'Melhorar habilidades de liderança não é uma tarefa fácil. Porém, pesquisas mostram que o aprendizado é mais impactante quando inclui a prática. Mursion prioriza a prática, criando espaços seguros, dinâmicos e acessíveis.',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Domain': 'Criar domínio',
    'MursionPortal.ScenarioBank.ItemBank.Level.L1': 'L1',
    'MursionPortal.ScenarioBank.ItemBank.Level.L2': 'L2',
    'MursionPortal.ScenarioBank.ItemBank.Level.L3': 'L3',
    'MursionPortal.Label.OrganizationName': 'Nome da organização',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Pathway': 'Criar percurso',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Event': 'Criar evento',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Resource': 'Criar recurso',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Outcome': 'Criar resultado',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Mindset': 'Crie Mentalidade',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Avatar': 'Criar nome do avatar',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.ResearchSummary': 'Criar resumo da pesquisa',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.LearnerInstruction': 'Criar instrução do aluno',
    'MursionPortal.ScenarioBank.ItemBank.BackButton.Label': 'Voltar ao Banco de cenários',
    'MursionPortal.ScenarioBank.ItemBank.Title.Placeholder': 'Digite aqui',
    'MursionPortal.Dashboard.NextOnProject.Footer.MissOrCancelationRateFulfilled.Text': 'You cannot schedule this simulation as you have reached the missed or cancellation limit set by your organization. Please reach out to your program admin to allow scheduling.',// TODO add translations
    'MursionPortal.ScenarioInfo.Show': 'Mostrar',
    'MursionPortal.ScenarioInfo.CertificationHistory': 'Histórico da certificação',
    'MursionPortal.Session.AlreadyBooked': 'Desculpe, a sessão já foi aceita por outro SIM.',
    'MursionPortal.CustomizeInvitation.Title': 'Personalize seus convites',
    'MursionPortal.CustomizeInvitation.SubTitle': 'Para personalizar, substitua o texto de cada campo abaixo ou deixe o texto padrão.',
    'MursionPortal.CustomizeInvitation.IntroMessage': 'Mensagem de introdução aos alunos (os cenários disponíveis serão preenchidos automaticamente abaixo desta mensagem):',
    'MursionPortal.CustomizeInvitation.Label.SenderName': 'Nome do remetente (o endereço de e-mail será de noreply@mursion.com)',
    'MursionPortal.CustomizeInvitation.Label.Subject': 'Assunto',
    'MursionPortal.InviteLearnersTeam.Section.Title': 'Qual(is) equipe(s) você gostaria de convidar para agendar?',
    'MursionPortal.InviteLearnersTeam.Section.SubTitle': 'Os alunos serão convidados para todos os cenários deste projeto que estão qualificados para agendar',
    'MursionPortal.InviteLearnersTeam.ScenariosAssigned.Text': 'Cenários atribuídos',
    'MursionPortal.Confirmation.Modal.Invitations.Text': 'Seus convites foram agendados!',
    'MursionPortal.ScenarioSetting.ValidationMessage': 'deve ser menor ou igual à mesma configuração no nível do cliente e/ou do projeto.',
    'MursionPortal.ScenarioBank.EventDetail.Details': 'Detalhes',
    'MursionPortal.ScenarioBank.CharacterLimit.TitleMessage': 'Limite de caracteres: {titleMaxLength}',
    'MursionPortal.ScenarioBank.CharacterLimit.DescriptionMessage': 'Limite de caracteres: {descriptionMaxLength}',
    'MursionPortal.ScenarioBank': 'Modelo de evento',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header': 'Inspire confiança e segurança para que outras pessoas compartilhem informações sobre seu trabalho',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header.Title': 'Detalhes do domínio',
    'MursionPortal.ScenarioBank.ItemBank.Edit.DescriptionOfUpdate.Label': 'Salvar essas alterações atualizará esse {type} em todos os cenários. Por favor, adicione uma breve descrição das alterações:',
    'Dashboard.Learners.LearnerLockedOut.LearnerColumn.Tooltip': 'Nome do usuário ou e-mail',
    'MursionPortal.ScenarioInfo.CertificationHistory.Column.Certified': 'Certificado(a)',
    'MursionPortal.ScenarioInfo.CertificationHistory.Column.Decertified': 'Certificação cancelada',
    'MursionPortal.ScenarioInfo.CertificationHistory.Column.Inactive': 'Inativo',
    'MursionPortal.Dashboard.LearnerLockedOut.ManualRelease': 'Liberar o aluno para agendamento',
    'MursionPortal.CustomizeInvitation.Sender.Name': 'Mursion',
    'MursionPortal.CustomizeInvitation.Subject.Title': 'Prepare-se para praticar suas habilidades! Suas sessões Mursion estão prontas para serem agendadas.',
    'MursionPortal.CustomizeInvitation.Intro.Message': 'Você foi convidado a participar de uma experiência de simulação na Mursion. Clique abaixo para agendar uma sessão ou visite seu painel e explore mais.',
    'MursionPortal.Dashboard.LearnerLockedOut.ManualRelease.ConfirmationText': 'Tem certeza de que deseja liberar {code} aluno(s) para agendamento?',
    'MursionPortal.Dashboard.LearnerLockedOut.ManualRelease.SuccessText': '{code} aluno(s) foi liberado(s) para agendamento.',
    'Dashboard.Learners.LearnerLockedOut.LockedOutColumn.Tooltip': 'Data/hora em que o aluno foi bloqueado para agendamento',
    'Dashboard.Learners.LearnerLockedOut.AutoReleaseColumn.Tooltip': 'Data/hora em que o aluno será liberado automaticamente',
    'MursionPortal.ScenarioBank.ItemBank.NextGenScenario': 'Cenário NextGen',
    'MursionPortal.ScenarioBank.ResourceFilter.Book': 'Livro',
    'MursionPortal.ScenarioBank.ResourceFilter.Article': 'Artigo',
    'MursionPortal.ScenarioBank.Filter.Label.Publisher': 'Editor',
    'MursionPortal.ScenarioBank.ItemBank.Level.Placeholder': 'Selecione o nível',
    'MursionPortal.ScenarioBank.ItemBank.Domain.Placeholder': 'Pesquisar ou selecionar domínio:',
    'MursionPortal.Label.CompletionRate.Tooltip': 'Taxa de conclusão é quantas vezes um aluno pode concluir uma simulação antes de perder a capacidade de agendar (bloqueado)',
    'MursionPortal.Label.SchedulingRate.Tooltip': 'Taxa de agendamento é quantas vezes um aluno pode agendar uma simulação antes de perder a capacidade de agendar (bloqueado)',
    'MursionPortal.Label.MissCancellationRate.Tooltip': 'Taxa de perdas/cancelamento é quantas vezes um aluno pode perder ou cancelar uma simulação antes de perder a capacidade de agendar (bloqueado)',
    'MursionPortal.ScenarioInfo.SchedulingLockout.Time.Tooltip': 'Horas de bloqueio de agendamento é quantas horas durará o bloqueio de agendamento',
    'MursionPortal.ScenarioBank.ItemBank.Mindset.Type.Derailing': 'Desviante',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header.Title.Skill': 'Detalhes da habilidade',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header.Title.Mindset': 'Detalhes da mentalidade',
    'MursionPortal.ProjectCreate.DeliveryHours': 'Horário de entrega',
    'MursionPortal.ProjectCreate.DeliveryHours.Tooltip': 'Período de tempo no qual os alunos verão horários instantâneos',
    'MursionPortal.ProjectCreate.DeliveryHours.Start': 'Início do horário de entrega',
    'MursionPortal.ProjectCreate.DeliveryHours.End': 'Fim do horário de entrega',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header.Title.LearnerInstruction': 'Detalhes da Instrução do aluno',
    'MursionPortal.ScenarioBank.ItemBank.Avatar.Edit.Header.Title': 'Detalhes do avatar',
    'MursionPortal.ScenarioBank.Label.AddLink': 'Adicionar link',
    'MursionPortal.ScenarioBank.Year.placeholderText': 'Ano',
    'MursionPortal.ScenarioBank.ItemBank.Resource.Edit.Header.Title': 'Detalhes do recurso',
    'MursionPortal.ScenarioBank.ItemBank.Outcome.Edit.Header.Title': 'Detalhes do resultado',
    'MursionPortal.ScenarioBank.OutcomeLevelSelector.Advanced': 'Avançado',
    'MursionPortal.ScenarioBank.OutcomeLevelSelector.Intermediate': 'Intermediário',
    'MursionPortal.ScenarioBank.OutcomeLevelSelector.Foundational': 'Fundamental',
    'MursionPortal.ScenarioBank.ItemBank.Skill': 'Habilidade',
    'MursionPortal.Resources.Search.Text': 'Pesquisar ou selecionar recurso(s)',
    'MursionPortal.ScenarioBank.ItemBank.ResearchSummaries.Details': 'Detalhes dos Resumos da pesquisa',
    'MursionPortal.ScenarioBank.ItemBank.ResearchSummary': 'Resumo da pesquisa',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header.Title.Pathway': 'Detalhes do percurso',
    'MursionPortal.ScenarioBank.ItemBank.Pathway': 'Percurso',
    'MursionPortal.ScenarioBank.ConfirmationText.Archive': 'Você tem certeza de que deseja arquivar este {block}?',
    'MursionPortal.ScenarioBank.ConfirmationText.Unarchived': 'Tem certeza de que deseja desarquivar este {block}?',
    'MursionPortal.ScenarioBank.Unarchived': 'Desarquivar',
    'MursionPortal.ScenarioBank.ItemBank.Resource': 'Recurso',
    'MursionPortal.ScenarioBank.ItemBank.Mindset': 'Mentalidade',
    'MursionPortal.ScenarioBank.ItemBank.LearnerInstruction': 'Instrução do aluno',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header.Title.Event': 'Detalhes do evento',
    'MursionPortal.ScenarioBank.NextGen.AvatarPerspectives': 'Perspectivas do avatar',
    'MursionPortal.ScenarioBank.NextGen.AvatarPerspectives.Intro': 'Introdução',
    'MursionPortal.ScenarioBank.NextGen.AvatarPerspectives.DiscProfile': 'Perfil Disc',
    'MursionPortal.ScenarioBank.NextGen.AvatarPerspectives.VerbalStyle': 'Estilo verbal',
    'MursionPortal.ScenarioBank.Content': 'Conteúdo',
    'MursionPortal.ScenarioBank.Content.ScenarioTitle': 'Título do cenário',
    'MursionPortal.ScenarioBank.Content.ScenarioCoverStory': 'História de capa do cenário',
    'MursionPortal.ScenarioBank.ItemBank.Outcome.Placeholder': 'Selecione um resultado bancário',
    'MursionPortal.ScenarioBank.Content.WhyThisMatters': 'Por que isso é importante',
    'MursionPortal.ScenarioBank.NextGen.AvatarGuide': 'Guia do avatar',
    'MursionPortal.ScenarioBank.NextGen.ViewScenario': 'Ver cenário',
    'MursionPortal.ScenarioBank.NextGen.Event': 'Ícone do evento',
    'MursionPortal.NextOnProject.Card.JoinNow': 'Participe da simulação ao vivo',
    'MursionPortal.JoinNowWaitingPage.HowItWorks.Title': 'Como funciona',
    'MursionPortal.JoinNowWaitingPage.HowItWorks.Intro': 'Olá! Você está prestes a ser combinado(a) com um avatar para <code>participar de uma simulação ao vivo agora</code>.',
    'MursionPortal.JoinNowWaitingPage.HowItWorks.Matched': 'Depois de ser combinado(a) a um avatar, clique em <code>"Participar da simulação ao vivo agora"</code>',
    'MursionPortal.JoinNowWaitingPage.HowItWorks.Allow': 'Clique em <code>"Permitir"</code> para dar acesso ao microfone/câmera quando for solicitado',
    'MursionPortal.JoinNowWaitingPage.HowItWorks.StartSession': 'Clique em <code>"Iniciar sessão"</code> e aguarde o avatar iniciar sua simulação',
    'MursionPortal.ScenarioBank.NextGen.OutOfSite.Label': 'O que os olhos não vêem, é "top of mind"',
    'MursionPortal.FirstGen.Scenario.Button.Text': '1ª Gen',
    'MursionPortal.NextGen.Scenario.Button.Text': '2ª Gen',
    'MursionPortal.NextGen.Scenario.Assessment': 'Avaliação',
    'MursionPortal.NextGen.Scenario.OutOfSite': 'O que os olhos não vêem, é "top of mind"',
    'MursionPortal.NextGen.Scenario.Synopsis': 'Sinopse',
    'MursionPortal.NextGen.Scenario.MinimumPoints': 'Pontos mínimos necessários para um resultado bem-sucedido',
    'MursionPortal.NextGen.Scenario.Successful': 'Bem-sucedido(a)',
    'MursionPortal.NextGen.Scenario.OpportunityDevelop': 'Oportunidade para se desenvolver ainda mais',
    'MursionPortal.NextGen.Scenario.LibraryInfo': 'Informações da biblioteca',
    'MursionPortal.NextGen.Scenario.LibraryInfo.DifficultyLevel': 'Nível de dificuldade',
    'MursionPortal.NextGen.Scenario.LibraryInfo.ScenarioCoverImage': 'Imagem de capa do cenário',
    'MursionPortal.NextGen.Scenario.LibraryInfo.ScenarioThumbnailImage': 'Imagem em miniatura do cenário',
    'MursionPortal.NextGen.Scenario.LibraryInfo.CoverImageDescription': 'Descrição da imagem de capa',
    'MursionPortal.NextGen.Scenario.LibraryInfo.ThumbnailImageDescription': 'Descrição da imagem em miniatura',
    'MursionPortal.ScenarioBank.CharacterLimit.ResponseMessage': 'Limite de caracteres: {responseMaxLength}',
    'MursionPortal.ScenarioBank.EventDetail.AvatarResponseTitle': 'Resposta(s) possível(s) do avatar',
    'MursionPortal.NextGen.Scenario.ReflectionGuide': 'Guia de reflexão',
    'MursionPortal.NextGen.Scenario.Scoring': 'Pontuação',
    'MursionPortal.ErrorMessage.buildingBlock': 'A ID do bloco de construção deve ser especificada',
    'MursionPortal.NextGen.Scenario.LibraryInfo.DragAndDropText': 'Arraste e solte seu arquivo aqui ou clique para selecionar arquivos',
    'MursionPortal.NextGen.Scenario.Publish': 'Publicar',
    'MursionPortal.NextGen.Scenario.Publish.Label': 'Por favor, preencha os campos obrigatórios (*) para publicar.',
    'MursionPortal.NextGen.Scenario.Last.Publish.Label': 'Última publicação em {publishDate} por {publishedBy}',
    'MursionPortal.EulaAgreement.CodeOfConductCheckBox.Text': 'Concordo com o modelo de entrega de simulação da Mursion e confirmo manter e honrar o Código de Conduta da Mursion em todas as simulações em que participo. Leia mais sobre as simulações Mursion e nosso Código de Conduta <code>aqui.</code>',
    'MursionPortal.JoinNow.Cancellation.FirstOption.Text': 'Não tenho tempo para fazer isso agora',
    'MursionPortal.JoinNow.Cancellation.SecondOption.Text': 'Não sei o que é isso',
    'MursionPortal.JoinNow.Cancellation.ThirdOption.Text': 'Voltarei a fazer isso mais tarde',
    'MursionPortal.JoinNow.Cancellation.FirstOption.Description.Text': 'A maioria das pessoas que fizeram essa simulação acharam que foi um ótimo uso de seu tempo. Isso levará 30 minutos ou menos.',
    'MursionPortal.JoinNow.Cancellation.SecondOption.Description.Text': 'Em vez de um vídeo, esta prática interativa ajuda você a desenvolver competências e habilidades em um espaço seguro e dinâmico.',
    'MursionPortal.JoinNow.Cancellation.ThirdOption.Description.Text': 'Claro! Você pode voltar a qualquer momento durante os horários definidos pelas organizações e participar da simulação ao vivo para praticar suas habilidades!',
    'MursionPortal.JoinNow.CancellationText.Heading': 'Por que você quer cancelar?',
    'MursionPortal.JoinNow.CancellationPage.Title': 'Oportunidade de praticar com o avatar!',
    'MursionPortal.JoinNow.CancellationPage.Text': 'Identifique maneiras positivas de resolver a situação dentro das políticas e diretrizes da Starbucks, e procure oportunidades para criar Melhores Momentos para todos os clientes.',
    'MursionPortal.JoinNow.JoinSimulation.Button.Text': 'Continuar a participar da simulação',
    'MursionPortal.LearnerDashboard.JoinNow.SearchingAvatar': 'Estamos procurando um avatar <code>para sua simulação ao vivo!</code>',
    'MursionPortal.LearnerDashboard.JoinNow.SuccessText': '<code>Parabéns</code>, você foi combinado(a) com um avatar para sua simulação ao vivo!',
    'MursionPortal.LearnerDashboard.JoinNow.ConnectingText': 'Estamos <code>conectando você ao seu avatar</code>, e sua simulação ao vivo começará em breve!',
    'MursionPortal.LearnerDashboard.JoinNow.DoNotCloseText': 'Por favor, não feche esta janela do navegador!',
    'MursionPortal.LearnerDashboard.JoinNow.StrategiesToUse': 'Estratégias para utilizar',
    'MursionPortal.LearnerDashboard.JoinNow.PracticeOpportunityText': 'Oportunidade de praticar com o avatar!',
    'MursionPortal.Text.Sorry': 'Desculpe',
    'MursionPortal.Text.Matching': 'Combinando',
    'MursionPortal.Text.Connecting': 'Conectando',
    'MursionPortal.JoinNowScenarioInfoCard.Text': 'Por favor, volte mais tarde ou tente novamente em alguns minutos!',
    'MursionPortal.JoinNowScenarioInfoCard.HeaderText': 'Todos os nossos avatares estão ocupados com outros alunos!',
    'MursionPortal.JoinNow.CancellationPage.GoBackButton.Text': 'Cancelar e voltar ao painel',
    'MursionPortal.NextGen.Scenario.Event.AddMessage': 'Adicionar um mínimo de 2',
    'MursionPortal.ScenarioBank.ItemBank.Events.AddButton': '+Adicionar',
    'MursionPortal.NextGen.ScenarioBank.Event.Select.Placeholder': 'Selecione um evento',
    'MursionPortal.NextGen.ScenarioBank.Skill.Select.Placeholder': 'Selecione uma habilidade',
    'MursionPortal.NextGen.ScenarioBank.Event.AvatarBehavior.Label': 'Comportamento do avatar*',
    'MursionPortal.NextGen.ScenarioBank.Event.LearnerResponse.Label': 'Resposta esperada do aluno*',
    'MursionPortal.NextGen.ScenarioBank.Event.AvatarAdd.Button': 'Adicionar resposta',
    'MursionPortal.NextGen.ScenarioBank.AvatarResponseName.Select.Placeholder': 'Selecione um avatar',
    'MursionPortal.NextGen.ScenarioBank.Label.AvatarPossibleResponse': 'Resposta(s) possível(s) do avatar',
    'MursionPortal.NextGen.ScenarioBank.Event.AssessmentObservedAction.Label': 'Descrever a ação observada',
    'MursionPortal.NextGen.ScenarioBank.Event.AssessmentImapct.Label': 'Explicar o impacto',
    'MursionPortal.NextGen.ScenarioBank.StrengthAssessment': 'Avaliação(ões) de qualidade*',
    'MursionPortal.NextGen.ScenarioBank.Button.StrengthAssessment': '+ Adicionar avaliação de qualidade',
    'MursionPortal.NextGen.ScenarioBank.Label.Strength': 'Qualidade',
    'MursionPortal.NextGen.ScenarioBank.OpportunityAssessment': 'Avaliação(ões) de oportunidades*',
    'MursionPortal.NextGen.ScenarioBank.Button.OpportunityAssessment': '+ Adicionar avaliação de oportunidades',
    'MursionPortal.NextGen.ScenarioBank.Label.Opportunity': 'Oportunidade',
    'MursionPortal.NextGen.Scenario.Event.Mindset.Select.Placeholder': 'Selecione mentalidade positiva',
    'MursionPortal.NextGen.Scenario.Event.Derailing.Select.Placeholder': 'Selecione mentalidade desviante',
    'MursionPortal.NextGen.Scenario.Event.Scoring.Title': 'Pontuação do evento',
    'MursionPortal.NextGen.Scenario.Event.Mindset.Title': 'Adicione mentalidades positivas e desviantes*',
    'MursionPortal.NextGen.Scenario.Event.Positive.Button.Text': 'Adicione outra mentalidade positiva',
    'MursionPortal.NextGen.Scenario.Event.Derailing.Button.Text': 'Adicione outra mentalidade desviante',
    'MursionPortal.Text.Searching': 'Buscando',
    'MursionPortal.NextGen.Scenario.Event.MindsetsNavigated': 'Mentalidades navegadas',
    'Dashboard.ContractProgress.Column.RemainingUtilization': 'Utilização restante',
    'Dashboard.ContractProgress.Column.EstimatedRemaining': 'Restante estimado',
    'MursionPortal.Label.Completion': 'Taxa de conclusão',
    'Dashboard.Report.ReportSessionOption.AllDays': 'Todas as sessões',
    'MursionPortal.Status.Abbreviation.EarlyCancelled': 'EC',
    'MursionPortal.Dashboard.Chart.label.SimulationBreakdown.EstimatedRemaining': 'Restante estimado',
    'Dashboard.ContractProgress.ColumnTooltip.RemainingUtilization': 'Simulações restantes e agendadas conforme uma série de simulações adquiridas',
    'MursionPortal.Dashboard.Chart.Header.SimulationBreakdownQuickView': 'Detalhamento da sessão – Visualização rápida',
    'MursionPortal.NextGen.SelectorText.Race': 'Raça',
    'MursionPortal.NextGen.SelectorText.Ethnicity': 'Etnia',
    'MursionPortal.NextGen.SelectorText.None': 'Nenhum',
    'MursionPortal.NextGen.SelectorText.Woman': 'É preciso ser mulher',
    'MursionPortal.NextGen.SelectorText.Man': 'É preciso ser homem',
    'MursionPortal.NextGen.SelectorText.Wheelchair': 'É preciso estar em cadeira de rodas',
    'MursionPortal.NextGen.SelectorText.agedText': 'É preciso ter 55+ anos',
    'MursionPortal.NextGen.Avatar.Restriction': 'Restrição do avatar',
    'MursionPortal.NextGen.AvatarOrScenarioComposition': 'Composição do avatar/cenário',
    'MursionPortal.Label.Opening': 'Abertura',
    'MursionPortal.Label.Closing': 'Fechamento',
    'MursionPortal.Label.Introduction': 'Introdução',
    'MursionPortal.ReflectionGuide.LearnerAssessment': 'Avaliação do aluno',
    'MursionPortal.ReflectionGuide.HostAssessment': 'Avaliação do organizador',
    'MursionPortal.ReflectionGuide.HostOpportunity': 'Oportunidade do organizador',
    'MursionPortal.ReflectionGuide.Introduction.Text': 'Bem-vindo(a) de volta! Você se sente pronto(a) para refletir sobre sua experiência? (permitir que o aluno consinta antes de continuar)',
    'MursionPortal.Label.Say': 'DIGA',
    'MursionPortal.Label.Ask': 'PEÇA',
    'MursionPortal.Label.Bullet': 'Ponto',
    'MursionPortal.ReflectionGuide.LearnerPreparation.FirstQuestion': 'Como foi a conversa?',
    'MursionPortal.ReflectionGuide.LearnerPreparation.SecondQuestion': 'Como a realidade da conversa guiou o seu plano para a conversa?',
    'MursionPortal.ReflectionGuide.LearnerPreparation.ThirdQuestion': 'O que aconteceu?',
    'MursionPortal.ReflectionGuide.LearnerPreparation.FourthQuestion': 'Houve alguma indicação (verbal e não verbal) do avatar que lhe deu uma dica sobre como seria a conversa?',
    'MursionPortal.ReflectionGuide.LearnerPreparation.FifthQuestion': 'Houve alguma indicação (verbal e não verbal) do avatar que lhe deu uma dica sobre como seria a conversa?',
    'MursionPortal.ReflectionGuide.LearnerAssessment.FirstSay': 'Essa conversa fez com que você navegasse por algumas dinâmicas desafiadoras – Mickey estava frustrado.',
    'MursionPortal.ReflectionGuide.LearnerAssessment.SecondSay': 'Embora bem-intencionados, muitas vezes vejo líderes reagirem de forma não tão produtiva ao:',
    'MursionPortal.ReflectionGuide.LearnerAssessment.ThirdSay': 'Também vi líderes reagirem de forma mais produtiva ao:',
    'MursionPortal.ReflectionGuide.LearnerAssessment.FourthSay': 'Essa conversa fez com que você navegasse por algumas dinâmicas desafiadoras',
    'MursionPortal.ReflectionGuide.LearnerAssessment.Ask': 'Onde você acha que chegou? (acompanhamento: Por quê/ Como assim/ Como você sabe?): Onde você acha que chegou? (acompanhamento: Por quê/ Como assim/ Como sabe?)',
    'MursionPortal.ReflectionGuide.HostAssessment.Opening': 'Se eu pudesse destacar uma das qualidades que você demonstrou durante a reunião (selecione um):',
    'MursionPortal.ReflectionGuide.HostAssessment.Closing': 'Minhas reflexões se alinham com suas reflexões? E por quê?',
    'MursionPortal.ReflectionGuide.HostAssessment.Opportunity.Opening': 'Se eu pudesse destacar uma das oportunidades de desenvolvimento que observei (selecione uma):',
    'MursionPortal.ReflectionGuide.Closing.Say': 'Isso é realmente difícil, e eu agradeço por praticar essas habilidades importantes com a Mursion hoje!',
    'MursionPortal.ReflectionGuide.Closing.Ask': 'O que você está tirando dessa experiência que você usará na próxima vez em que enfrentar uma situação semelhante na vida real?',
    'MursionPortal.ScenarioBank.ItemBank.ResearchSummary.Placeholder': 'Selecione um resumo da pesquisa',
    'MursionPortal.ScenarioBank.ItemBank.LearnerInstruction.Placeholder': 'Selecione uma Instrução do aluno',
    'MursionPortal.NextGen.Scenario.LibraryInfo.ClinkHereToReplaceThisFile': 'Clique aqui para substituir este arquivo.',
    'MursionPortal.Status.Empty': '',
    'MursionPortal.ScenarioContent.Label': 'Adicione um mínimo de {min} e máximo de {max}',
    'MursionPortal.Search.Text.Skills': 'Pesquisar ou selecionar habilidade(s)',
    'MursionPortal.Search.Text.Avatar': 'Pesquisar ou selecionar Avatar(es)',
    'MursionPortal.NextGen.Scenario.TimelineAndScheduling': 'Linha do Tempo e Agendamento',
    'MursionPortal.NextGen.Session.Replay': 'Reapresentação da sessão',
    'MursionPortal.NextGen.StrengthsAndOpportunities': 'Pontos fortes e oportunidades',
    'MursionPortal.NextGen.Recommendations': 'Recomendações',
    'MursionPortal.NextGen.MoreToDiscover': 'Mais para descobrir',
    'MursionPortal.NextGen.MissionAccomplished': 'Missão cumprida!',
    'MursionPortal.NextGen.Learner.ScenarioMaterials': 'Materiais do cenário',
    'MursionPortal.NextGen.Learner.PathwayAffiliation': 'Afiliação do percurso',
    'MursionPortal.NextGen.Learner.SkillLevel': 'Nível de habilidade',
    'MursionPortal.NextGen.Learner.YourMission': 'Sua missão',
    'MursionPortal.ScenarioBank.ShowOnlySecondGenscenarios': 'Mostrar apenas cenários de 2ª Gen',
    'MursionPortal.NextGen.CreateNewSkill.Header.Text': 'Criar uma nova habilidade para o banco',
    'MursionPortal.NextGen.Learner.StartSimulation': 'Iniciar simulação',
    'MursionPortal.SimMaterials.Label.CoverStory': 'História de capa',
    'MursionPortal.SimMaterials.Label.LearnerMission': 'Missão do aluno',
    'MursionPortal.SimMaterials.Label.Background': 'Fundo',
    'MursionPortal.SimMaterials.Label.PerspectivesAndIdentities': 'Perspectivas e identidades',
    'MursionPortal.SimMaterials.HeaderText.SimDashboard': 'Painel do SIM',
    'MursionPortal.NextGen.Learner.ToStart': 'para iniciar',
    'MursionPortal.Project.DeliveryHours.Standard': 'Horário de entrega padrão',
    'MursionPortal.Project.DeliveryHours.Global': 'Horários de entrega globais',
    'MursionPortal.Label.Monday.ShortForm': 'Seg',
    'MursionPortal.Label.Tuesday.ShortForm': 'Ter',
    'MursionPortal.Label.Wednesday.ShortForm': 'Qua',
    'MursionPortal.Label.Thursday.ShortForm': 'Qui',
    'MursionPortal.Label.Friday.ShortForm': 'Sex',
    'MursionPortal.Label.Saturday.ShortForm': 'Sáb',
    'MursionPortal.Label.Sunday.ShortForm': 'Dom',
    'MursionPortal.NextGen.CreateFromBank.Customized.Label': 'Personalizado a partir do cenário do banco',
    'MursionPortal.NextGen.CreateFromBank.CustomizedFromBank.Tooltip': 'Esse cenário foi personalizado a partir do cenário do banco original. Quaisquer atualizações feitas no cenário do banco não serão propagadas para esse cenário.',
    'MursionPortal.BuildingBlock.Label.PublishedDate': 'Data de Publicação',
    'MursionPortal.Label.CopyTeamInviteLink': 'Copiar link de convite da equipe',
    'MursionPortal.NextGen.SessionReplayText': 'Se eu pudesse destacar um dos pontos fortes que você demonstrou durante a reunião (selecione um):Essa conversa foi uma oportunidade prática para você facilitar uma conversa que apoiasse um membro da equipe durante um momento difícil. Seu sucesso aqui dependia de sua capacidade de ter empatia por Jo.',
    'MursionPortal.NextGen.TheMission': 'A missão:',
    'MursionPortal.NextGen.DemonstratedStrength': 'Avaliação do ponto forte',
    'MursionPortal.NextGen.OpportunityForFutureGrowth': 'Oportunidade de crescimento futuro',
    'MursionPortal.NextGen.CompetenciesFocus': 'As competências em foco foram:',
    'MursionPortal.NextGen.StrengthAndOpportunitiesText': 'Durante a análise da conversa, identificou-se um ponto forte observado e uma oportunidade de crescimento:',
    'MursionPortal.NextGen.WeObserved': 'Observamos...',
    'MursionPortal.NextGen.WhyThisMatters': 'Por que isso é importante',
    'MursionPortal.ScenarioSetting.MissOrCancelationRate.ValidationMessage': 'O tempo de bloqueio de agendamento não pode ser nulo se a taxa de falta/cancelamento não for nula.',
    'MursionPortal.NextGen.CreateFromBank.Customized.Modal.Text': 'Você personalizou um cenário do banco e quaisquer alterações futuras nele não serão refletidas aqui. Deseja prosseguir?',
    'MursionPortal.SimMaterials.Event.SkillsInFocus.Label': 'Competências em foco',
    'MursionPortal.SimMaterials.Label.SimulationEvents': 'Eventos de simulação',
    'MursionPortal.SimMaterials.Event.AvatarBehavior.Label': 'Comportamento do avatar',
    'MursionPortal.SimMaterials.Event.LearnerResponse.Label': 'Resposta esperada do aluno',
    'MursionPortal.Label.NoTimeSelected': 'Sem horário selecionado',
    'MursionPortal.Label.StartTime.EndTime.Selected': 'Tanto o horário de início quanto o horário de término devem ser selecionados',
    'MursionPortal.SimMaterials.Label.ReflectionAndDebrief': 'Reflexão e análise',
    'MursionPortal.NextGen.Scenario.Planning': 'Planejamento',
    'MursionPortal.HowItWorks.Experience.Text': 'Sua experiência começará automaticamente em poucos segundos!',
    'MursionPortal.HowItWorks.MicCamera': 'Microfone/câmera',
    'MursionPortal.HowItWorks.Prompt.Text': 'Quando solicitado, clique em <strong>"Permitir"</strong> para dar acesso ao microfone/câmera para o avatar poder ver e ouvir você!',
    'MursionPortal.HowItWorks.StartSession.Text': 'Clique em <strong>"Iniciar sessão"</strong> e revise a descrição do cenário para se preparar!',
    'MursionPortal.LearnerMaterials.KeyCompetencies': 'Competências-chave',
    'MursionPortal.LearnerMaterials.ResearchMaterials': 'Materiais de pesquisa',
    'MursionPortal.LearnerMaterials.PracticeOpportunity': 'Oportunidade de praticar',
    'MursionPortal.ScenarioInfo.Label.ScenarioBank': 'O cenário é criado a partir do banco',
    'MursionPortal.DemandBasedScheduling.SlotSelectionMessage': 'Por favor, escolha as faixas horárias que funcionariam para você. Nós vamos agendar o seu cenário em um de seus horários escolhidos.',
    'MursionPortal.NextGen.ScenarioBank.SubStrengthAssessment': 'Ponto forte',
    'MursionPortal.NextGen.ScenarioBank.SubOpportunityAssessment': 'Oportunidade',
    // needs translations
    'MursionPortal.EngagementHub.EmailLearners': 'Email Learners', // TODO add translations
    'MursionPortal.EngagementHub.InviteToTeam': 'Invite to Team', // TODO add translations
    'MursionPortal.EngagementHub.InviteLearnersToScheduleTitle': 'Invite your learners to start or continue their practice', // TODO add translations
    'MursionPortal.EngagementHub.InviteLearnersToScheduleSummary': 'Engage your learners via email or by embedding shared links in your own communications.', // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations': 'View Invitations', // TODO add translations
    'MursionPortal.EngagementHub.CreateInvite': 'Create Invite', // TODO add translations
    'MursionPortal.NextGen.replayConversation':'Replay the Conversation',// TODO add translations
    'MursionPortal.EngagementHub.BackTo': 'Back to {clientName}', // TODO add translations
    'MursionPortal.EngagementHub.LearningPotential': 'At Mursion, we believe in maximizing every learner\'s potential.', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationLearnMore': 'Communication Is Key! Learn more', // TODO add translations
    'MursionPortal.EngagementHub.UnlockTeamPotential': 'Unlock Your Team’s Potential', // TODO add translations
    'MursionPortal.EngagementHub.InviteLearners.Title': 'Invite your learners to schedule their simulations', // TODO add translations
    'MursionPortal.EngagementHub.InviteLearners.Summary': '58% of users are more likely to sign up for their simulation if you send a custom invitation to schedule.', // TODO add translations
    'MursionPortal.EngagementHub.ManageEmailSettings.Label': 'Your Email, Your Way!', // TODO add translations
    'MursionPortal.EngagementHub.ManageEmailSettings.Title': 'Manage email settings and gain insights', // TODO add translations
    'MursionPortal.EngagementHub.Customize': 'Customize', // TODO add translations
    'MursionPortal.EngagementHub.EmailConfigurations.Title': 'Email Configurations', // TODO add translations
    'MursionPortal.EngagementHub.EmailConfigurations.Summary': 'Customize email messages that resonate with your learners and drive adoption and engagement.', // TODO add translations
    'MursionPortal.EngagementHub.PowerfulEmailAnalytics.Title': 'Powerful Email Analytics', // TODO add translations
    'MursionPortal.EngagementHub.PowerfulEmailAnalytics.Summary': 'Measure email open, click, and unread rates to discover insights and make data-driven decisions.', // TODO add translations
    'MursionPortal.EngagementHub.PersonalizeMessaging.Label': 'Add Personal Touch', // TODO add translations
    'MursionPortal.EngagementHub.PersonalizeMessaging.Title': 'Personalize messaging in the Mursion Portal', // TODO add translations
    'MursionPortal.EngagementHub.LearnerDashboard': 'Learner Dashboard', // TODO add translations
    'MursionPortal.EngagementHub.ScenarioPage': 'Scenario Pages', // TODO add translations
    'MursionPortal.EngagementHub.SchedulingPage': 'Scheduling Page', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.LearnerFeedbackTooltip': 'This email will only go out if feedback report is enabled at project level. Please reach out to your Mursion representative to learn more.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Title': 'Email Settings', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Summary': 'Use these tools to help customize different emails.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.EmailName': 'Email Name', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.SenderName': 'Sender Name (email will be no-reply@mursion.com)', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.ViewCustomize': 'View/Customize', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.EnableDisable': 'Enable/Disable', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.LearnerLockout': 'Learner Lockout', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.VideoFeedback': 'Video & Feedback', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.ModifySettings': 'Click edit to modify the settings', // TODO add translations
    'MursionPortal.EngagementHub.BackTitle': 'Back to Engagement Hub', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey': 'Communication Is Key!', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.summary': 'A great Mursion rollout starts with effective communication to your learners. It\'s important to ensure they are prepared and understand the connection between Mursion and your learning and development program goals.', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.TItle': 'Elements of Effective Learner Communication', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.LearnerBenefits.Title': 'Lead with Learner Benefits', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.LearnerBenefits.Summary': 'Lead with learner benefits, not product features. Share learner testimonials and tie the skills they are developing to business objectives.', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.PracticeProgress.Title': 'Practice makes Progress', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.PracticeProgress.Summary': 'Practice is essential to developing new skills. Ensure your message highlights The Importance of Practice - And Our Reluctance To Do It to acknowledge challenges that learners face.', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.LeadersMessage.Title': 'Message from Leaders', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.LeadersMessage.Summary': 'Ask senior leaders to communicate (meetings, written, video, etc) why Mursion sessions are valuable and how developing the target skills is tied to business priorities and growth opportunities.', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.SetExpectations.Title': 'Set Expectations', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.SetExpectations.Summary': 'Mursion is a new technology that uses avatars powered by humans. Prepare learners for these conversations by sharing why and how avatars create psychology safety and is an effective way to practice.', // TODO add translations
    'MursionPortal.Tab.Engagement': 'Engagement', // TODO add translations
    'MursionPortal.Tab.EngagementHub': 'Engagement Hub', // TODO add translations
    'MursionPortal.Tab.EmailManagement': 'Email Management', // TODO add translations
    'MursionPortal.Tab.PortalMessaging': 'Portal Messaging', // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations.BackButton.Label': 'Back to Invite to Schedule', // TODO add translations
    'MursionPortal.Portal.Messaging.Heading.Text': 'Inform, engage, and delight learners using your organization’s unique brand voice.',  // TODO add translations
    'MursionPortal.Portal.Messaging.SubHeading.Text': 'Add custom messaging throughout the Portal to engage your learners.',  // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations.Column.InvitationDate': 'Invitation Date', // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations.Column.MessageToLearners': 'Message to Learners', // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations.Column.ExcludedLearners': 'Excluded Learners', // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations.Column.CreatedBy': 'Created By', // TODO add translations
    'MursionPortal.Portal.EngagementHub.InviteToScheuled.Text': '{scenariosCount} scenarios assigned to {learnerCount} learners', // TODO add translations
    'MursionPortal.Portal.EngagementHub.InviteToScheuled.Text.AddLearners': 'Add Learners', // TODO add translations
    'MursionPortal.Tab.EmailManagement.Analytics': 'Analytics', // TODO add translations
    'MursionPortal.CustomizeLearnerInvitations.Teams.Invitation.Message': 'The following team(s) already have scheduled an invitation on this day:', // TODO add translations
    'MursionPortal.CustomizeLearnerInvitations.New.Invitation.Message': 'Please make a note and start a new invitation', // TODO add translations
    'MursionPortal.CustomizeLearnerInvitations.Learners.Invitation.Text': 'Learners will receive the invitation in the early morning hours (Pacific time) on this day.', // TODO add translations
    'MursionPortal.CustomizeLearnerInvitations.SendDate.Label': 'Send Date', // TODO add translations
    'MursionPortal.Scenario.Generation.FilterType.Label' : 'Generation', // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations.CancelInvitation.Modal.Text': 'Are you sure you want to cancel the invitation created by {createdBy}?', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.LearnerFeedbackReport': 'Learner Feedback Report', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.CancellationByLearner': 'Cancellation by Learner', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.MissedSessionByLearner': 'Missed by Learner', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.LearnerReleased': 'Learner Released', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.LearnerLockOut': 'Learner Lockout', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.SessionConfirmation': 'Session Confirmation', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.Invitation': 'Welcome to Mursion - a platform for you to practice your skills', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.InvitationReminder': `Reminder:You're invited to {licenseeName}`, // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.Confirmation': 'Thanks for signing up! Your Simulation is confirmed.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.FirstReminder': `Don't forget! Your Mursion simulation is soon!`, // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.SecondReminder': `Get excited! Your Mursion simulation starts in {upcomingBeforeStart} minutes.`, // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.CancellationByByLearner': 'Your simulation was canceled', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.MissedSessionByLearner': 'We missed you today!', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.LearnerLockedOut': 'You missed/cancelled this scenario too many times', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.LearnerReleased': 'Your session is available to schedule', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.LearnerSessionRecordingIsReady': 'Want to see how you did? Visit your Mursion Dashboard.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.LearnerFeedbackReport': 'Want to see how you did? Review your session report.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.LearnerSessionRecordingReady': 'Learner Session Recording Ready', // TODO add translations
    'MursionPortal.EngagementHub.BackToEmailSettings': 'Back to Email Settings', // TODO add translations
    'MursionPortal.EngagementHub.CustomizeEmail.Text': 'Learners will start receiving this email after you save.', // TODO add translations
    'MursionPortal.NextGen.Learner.FeedbackFrom': 'Feedback from', // TODO add translations
    'MursionPortal.NextGen.Scenario.SimMaterials': 'SIM Materials', // TODO add translations
    'MursionPortal.NextGen.Scenario.LearnerMaterials': 'Learner Materials', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Modal.BodyText': 'Are you sure you want to save the Enable/Disable settings', // TODO add translations
    'MursionPortal.Client.EmailSettings.SessionRescheduledByPS': 'Session Rescheduled by PS', // TODO add translations
    'MursionPortal.EngagementHub.EmailPreview.Sender': 'Sender', // TODO add translations
    'MursionPortal.Scenario.CoverStory.Tooltip.Text': 'Cover Story will be visible to clients in the scenario library as well as learners and SIMs in their materials',// TODO add translations
    'MursionPortal.Scenario.Summary.Tooltip.Text':'Summary will be visible to clients in the scenario library',// TODO add translations
    'MursionPortal.NextGen.Event.positiveMindset': 'There must be at least ({startCount}) positive mindset defined. You can specify a maximum of ({endCount}) positive mindsets.', // TODO add translations
    'MursionPortal.NextGen.Event.derailingMindset': 'There must be at least ({startCount}) derailing mindset defined. You can specify a maximum of ({endCount}) derailing mindsets.', // TODO add translations
    'MursionPortal.NextGen.Event.StrengthAssessment': 'There must be at least ({startCount}) strength assessment defined. You can specify a maximum of ({endCount}) strength assessments.', // TODO add translations
    'MursionPortal.NextGen.Event.OpportunityAssessment': 'There must be at least ({startCount}) opportunity assessment defined. You can specify a maximum of ({endCount}) opportunity assessments.', // TODO add translations
    'MursionPortal.NextGen.Event.StrengthAssessment.AvatarResponse': 'You have added {addedCount} avatar response for strength assessment(s), you must add at least {totalCount} avatar response for strength assessment(s).', // TODO add translations 
    'MursionPortal.NextGen.Event.OpportunityAssessment.AvatarResponse': 'You have added {addedCount} avatar response for opportunity assessment(s), you must add at least {totalCount} avatar response for opportunity assessment(s).', // TODO add translations 
    'MursionPortal.Label.ClientLevelMissCancelSetting': 'Client Level Miss/Cancel Setting', // TODO add translations 
    'MursionPortal.Label.ProjectLevelMissCancelSetting':'Project Level Miss/Cancel Setting', // TODO add translations 
    'MursionPortal.Label.ScenarioLevelMissCancelSetting':'Scenario Level Miss/Cancel Setting', // TODO add translations 
    'MursionPortal.Label.MissedCancelledSessions':'Missed/Cancelled Sessions', // TODO add translations 
    'MursionPortal.EngagementHub.ViewInvitations.Column.SenderName': 'Sender Name', // TODO add translations,
    'MursionPortal.NextGen.Event.Delete.ConfirmBox': 'Are you sure you want to delete this {message}?', // TODO add translations,
    'MursionPortal.NextGen.Event.Message.PositiveMindset': 'Positive Mindset', // TODO add translations,
    'MursionPortal.NextGen.Event.Message.DerailingMindset': 'Derailing Mindset', // TODO add translations,
    'MursionPortal.NextGen.Event.Message.StrengthAssessment': 'Strength assessment', // TODO add translations,
    'MursionPortal.NextGen.Event.Message.OpportunityAssessment': 'Opportunity assessment', // TODO add translations,
    'MursionPortal.PersonalizedMessageTitle': 'Personalized message header. This will appear before every personalized message in Portal.', // TODO add translations
    'MursionPortal.NextGen.ScenarioReferences': 'Scenario References', // TODO add translations 
    'MursionPortal.NextGen.ChildScenarios': 'Child Scenarios', // TODO add translations 
    'MursionPortal.NextGen.Label.Customized': 'Customized', // TODO add translations  
    'MursionPortal.EngagementHub.EmailSettings.InvitationPasswordUsers': 'Invitation (Password Users)', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.InvitationReminderPasswordUsers': 'Invitation Reminder (Password Users)', // TODO add translations
    'MursionPortal.Label.Clicked': 'Clicked', // TODO add translations
    'MursionPortal.Client.EmailSettings.SessionRescheduledByLearner': 'Session Rescheduled by Learner', // TODO add translations
    'MursionPortal.Client.Team.CopyTeamInviteLink.Tooltip':'Users who have not yet registered or have not yet been added to the Portal will not be able to access this link. Please click the "Add Learners" button.', // TODO add translations
    'Settings.Config.LearnerSessionReportDelay': 'Learner Session Report Delay', // TODO add translations
    'MursionPortal.SkillsReferences.Label': 'Skills References', // TODO add translations
    'MursionPortal.ResearchSummariesReferences.Label': 'Research Summaries References', // TODO add translations
    'MursionPortal.Search.Text': 'Search or select', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.Event': 'Please select Event', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.Skill': 'Please select Skill', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.AvatarBehavior': 'Avatar Behavior is required', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.ExpectedLearnerResponse': 'Expected Learner Response is required', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.PositiveMindset': 'Please Select Positive Mindset', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.PositiveMindsetDescription': 'Positive mindset is required', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.DerailingMindset': 'Please Select Derailing Mindset', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.DerailingMindsetDescription': 'Derailing mindset is required', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.DescribeObservedAction': 'Describe Observed Action is required', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.ExplainImpact': 'Explain Impact is required', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.AvatarName': 'Please select avatar name', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.PossibleAvatarResponse':'Possible Avatar Response is required', // TODO add translations
    'MursionPortal.Label.SchedulingLockoutDate': 'Scheduling Lockout Date', // TODO add translations
    'MursionPortal.Label.SchedulingLockoutDate.Tooltip': 'Lockout effective date for all learners who miss and/or late cancel', // TODO add translations
    'MursionPortal.NextGen.scorining.Message.MinimumScore': 'You’ve changed the number of events. Please update the minimum score.', // TODO add translations
    'MursionPortal.NextGen.scorining.Message.SynopsisDescription': 'Synopsis description is required', // TODO add translations
    'MursionPortal.NextGen.scorining.Message.SuccessfulAssessment': 'Successful Assessment is required', // TODO add translations
    'MursionPortal.NextGen.scorining.Message.OpportunityAssessment': 'Opportunity Assessment is required', // TODO add translations
    'MursionPortal.NextGen.Scenario.LibraryInfo.PathwaysAffiliation': 'Pathway Affiliation(s)', // TODO add translations
    'MursionPortal.NextGen.BankScenarioReferences': 'Bank Scenario References', // TODO add translations
    'MursionPortal.Dashboard.ScenarioLearner.Header.LearnerActivationStatus':'Learner Activation',  // TODO add translations
    'MursionPortal.Dashboard.ScenarioLearner.Header.LearnerInviteTitle':'Unlock Your Team’s Potential',  // TODO add translations
    'MursionPortal.Dashboard.ScenarioLearner.Header.LearnerInviteMessage':'Target invitations to your learners with custom messaging',  // TODO add translations
    'MursionPortal.Dashboard.ScenarioLearner.Header.LearnerInvite':'Invite to Schedule',  // TODO add translations
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.NotYetRegistered':'Not Yet Registered', // TODO add translations
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.NotYetScheduled':'Not Yet Scheduled', // TODO add translations
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.ScheduledNotYetCompleted':'Scheduled - Not Yet Completed', // TODO add translations
    'MursionPortal.NextGen.ViewAndInviteLearners': 'View & Invite Learners', // TODO add translations
    'MursionPortal.Dashboard.Tooltip.Label.SchedulingEmail': 'Session Specific Emails and other Join Session Emails', // TODO add translations
    'MursionPortal.Dashboard.EmailAnalytics.Label.InviteToScheduleEmail': 'Invite-to-Schedule Emails', // TODO add translations
    'MursionPortal.Dashboard.Tooltip.Label.CallToActionEmail': 'Emails sent to learners inviting them to schedule scenario(s)', // TODO add translations
    'MursionPortal.NextGen.Scenario.ClientCustomizations': 'Client Customizations', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.InviteToSchedule.Tooltip': 'Invite to Schedule emails are able to be customized each time you send them.', // TODO add translations
    'MursionPortal.Contract.ContractTimezone': 'Contract Timezone', // TODO add translations
    'Dashboard.LeftPane.Simulations.SessionSearch':'Search for Session', // TODO add translations
    'MursionPortal.NextGen.Scenario.ClientCustomizations.ClientScenarioTitle': 'Client Scenario Title', // TODO add translations
    'MursionPortal.NextGen.Scenario.ClientCustomizations.ClientScenarioTitle.Tooltip': 'Client learners and program admins will see this title only. SIMs will see the bank scenario title and client scenario title in their SIM materials.', // TODO add translations
    'MursionPortal.NextGen.Event.DeleteMessage': 'Minimum 2 Events are mandatory for a Scenario.', // TODO add translations
    'Settings.Config.ProjectAndScenario.AllowSimPracticeSession': 'SIM Practice Session', // TODO add translations
    'MursionPortal.Calendar.Button.Practice.Session': 'Practice Session', // TODO add translations
    'MursionPortal.Scenario.InTrainingSimSpecialist': 'In Training SIM Specialists', // TODO add translations
    'MursionPortal.Scenario.NoInTrainingSimSpecialist': 'No In Training Sim Specialist', // TODO add translations
    'MursionPortal.Simspecialist.Thankyou.summary': 'You are scheduled for your practice simulation', // TODO add translations
    'MursionPortal.Button.DoNotSaveMyWork': 'Don’t Save my Work', // TODO add translations
    'MursionPortal.NextGen.Confirm.BrowserBack': 'You have not saved your changes, and navigating away will cause you to lose your work. Please complete required fields.', // TODO add translations
    'MursionPortal.UserConsent.Learner.Description': 'Se você disser "Eu concordo", o software Mursion processará sua simulação ao vivo e fará uma gravação em vídeo de sua sessão de simulação para sua revisão no portal do software Mursion. Exceto conforme exigido pela lei aplicável ou para fins de garantia de qualidade, a Mursion não distribuirá ou divulgará sua gravação de vídeo sem sua permissão. A Mursion usará dados anônimos de seu uso de seu software para melhorar sua tecnologia de simulação.', // TODO add translations, this translation was generated using translate.com, retranslation required in the future
    'MursionPortal.UserConsent.LearnerAndAccountOwnerOrFacilitator.Description': 'Se você disser "Eu concordo", o software Mursion processará sua simulação ao vivo e fará uma gravação em vídeo de sua sessão de simulação para sua revisão no portal do software Mursion. Observe que, a pedido do cliente que licenciou o software Mursion em seu nome, uma cópia da(s) sua(s) sessão(ões) de simulação gravada(s) será compartilhada com um coach, mentor ou revisor selecionado pelo cliente. Todos os revisores são contratualmente obrigados a seguir <code>Aviso de privacidade da Mursion</code>, a menos que você tenha concordado com termos de privacidade diferentes. Exceto conforme exigido pela lei aplicável ou para fins de garantia de qualidade, a Mursion não distribuirá ou divulgará sua gravação de vídeo sem sua permissão. A Mursion usará dados anônimos de seu uso de seu software para melhorar sua tecnologia de simulação.', // TODO add translations, this translation was generated using translate.com, retranslation required in the future
    'MursionPortal.UserConsent.AccountOwnerOrFacilitator.Description': 'Sua(s) sessão(ões) de simulação será(ão) gravada(s) em áudio e vídeo a pedido do cliente que licenciou o Software Mursion em seu nome. Se você consentir em prosseguir, uma cópia da(s) sua(s) sessão(ões) de simulação gravada(s) será compartilhada com um terceiro selecionado pelo cliente para analisar e pontuar a(s) gravação(ões). Esses indivíduos são contratualmente obrigados a seguir <code>Aviso de privacidade da Mursion</code>, a menos que você tenha concordado com termos de privacidade diferentes. No entanto, você não receberá ou terá acesso a uma cópia da(s) sua(s) sessão(ões) de simulação gravada(s) de acordo com os termos da tarefa ou avaliação que você concordou em concluir. A Mursion pode usar sua gravação de vídeo para fins de garantia de qualidade e usará dados anônimos de seu uso de seu software para melhorar sua tecnologia de simulação.', // TODO add translations, this translation was generated using translate.com, retranslation required in the future
    'MursionPortal.ProjectCreate.DeliveryHours.Validation': 'Delivery Hours Start time should be less than End time.', // TODO add translations
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.NotYetRegistered':'Unique number of learners who have been added to the Portal but have not yet completed registration', // TODO add translations
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.NotYetScheduled':'Unique number of learners who have registered but have not yet scheduled a session', // TODO add translations
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.ScheduledNotYetCompleted':'Unique number of learners who have registered and scheduled at least one session but have not yet completed a session', // TODO add translations
    'MursionPortal.Restriction': 'Restriction', // TODO add translations
    'MursionPortal.Dashboard.SessionSearch.Placeholder' : 'Paste a session ID and hit return',  // TODO add translations
    'Operations.TechRestriction.CreateDialog.Title': 'New Holiday Tech Restriction', // TODO add translations
    'Operations.TechRestriction.EditDialog.Title': 'Edit Holiday Tech Restriction', // TODO add translations
    'Operations.TechRestriction.ViewDialog.Title': 'View Holiday Tech Restriction', // TODO add translations
    'MursionPortal.Client.EmailSettings.TriggerHours': 'Trigger (Hours)', // TODO add translations
    'MursionPortal.Client.EmailSettings.TriggerMin': 'Trigger (Minutes)', // TODO add translations
    'MursionPortal.Duplicate.Scenario.Confirmation.Text': 'Are you sure you want to duplicate this scenario?',  // TODO add translations
    'MursionPortal.Contract.LineItem.ToolTip': 'A contract can only contain one type of line item. If your contract has both sessions and hours, please convert the sessions into hours and add it as an hours line item.', // TODO add translations
    'MursionPortal.ReflectionGuide.LearnerPerspective': 'Learner Perspective', // TODO add translations
    'MursionPortal.Dashboard.SessionSearch.Table.Placeholder': 'Search for Session will load once you search session ID', // TODO add translations
    'MursionPortal.Dashboard.SessionSearch.EmptySession': 'No sessions match that ID', // TODO add translations
    'MursionPortal.SimMaterials.Label.IntroAndPreReflection': 'Intro & Pre-Reflection', // TODO add translations
    'MursionPortal.Dashboard.Chart.Header.LearnerBreakdown': 'Learner Breakdown', // TODO add translations
    'MursionPortal.Dashboard.Chart.Header.LearnerBreakdown.Activated': 'Activated', // TODO add translations
    'MursionPortal.NextGen.Scenario.LibraryInfo.VersionNumber': 'Version Number', // TODO add translations
    'MursionPortal.NextGen.Scenario.LibraryInfo.VersionDetails': 'Version Details', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.EmailReminderHours': 'Trigger {tiggerValue} hours before Session.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.EmailReminderMinutes': 'Trigger {tiggerValue} minutes before Session.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.FirstEmailReminder.Tooltip': 'Time before Session when First Reminder would go. If not set Licensee Setting would be used.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.SecondEmailReminder.Tooltip': 'Time before Session when Second Reminder would go. If not set Licensee Setting would be used.', // TODO add translations
    'MursionPortal.SimAttendance.MissedSimulation.Message': 'You missed this simulation so you are unable to edit attendance. Please contact Support if you did not miss the simulation or had a technical issue that caused you to miss the simulation.', // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations.Disclaimer': 'Invites are visible for last 30 days only', // TODO add translations
    'MursionPortal.PortalVersion': 'Portal Version', // TODO add translations
    'MursionPortal.ScenarioInfo.Label.AutoSIM': 'AutoSIM', // TODO add translations
    'MursionPortal.ProjectContract.AddConfirmation': 'Are you sure you want to add the contract to this project?', // TODO add translations
    'MursionPortal.ProjectContract.CompletedContract.AddConfirmation': 'Are you sure you want to add the completed contract to this project?', // TODO add translations
    'MursionPortal.Label.NewPortal' : 'New Portal', // TODO add translations
    'MursionPortal.Label.OldPortal' : 'Old Portal', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.ReminderValidation': 'Second Reminder should be less than First Reminder', // TODO add translations
    'MursionPortal.Project.InviteToSchedule.EmailDisabled.Tooltip' :'Invite to Schedule is disabled as email settings are disabled. Enable them to send this invite', // TODO add translations
    'MursionPortal.SimAttendance.Error.SimReportedError' :'SIM Reported Error (Does not impact session status)', // TODO add translations
    'MursionPortal.SimAttendance.Error.SimLateOrNoShow' :'SIM late or no show', // TODO add translations
    'MursionPortal.SimAttendance.Error.SessionStartedTooEarly' :'Session started too early', // TODO add translations
    'MursionPortal.SimAttendance.Error.AvatarIssue' :'Avatar Issue', // TODO add translations
    'MursionPortal.SimAttendance.Error.MouthlipSyncIssueDuringSession' :'Mouth/lip sync issue during session', // TODO add translations
    'MursionPortal.SimAttendance.Error.Hardware' :'Hardware', // TODO add translations
    'MursionPortal.SimAttendance.Error.AudioVideoIssueForSim' :'Audio/video issue for SIM', // TODO add translations
    'MursionPortal.SimAttendance.Error.AudioVideoIssueForLearner' :'Audio/video issue for Learner', // TODO add translations
    'MursionPortal.SimAttendance.Error.OtherSimHardwareIssues' :'Other SIM hardware issues (controller, laptop, etc.)', // TODO add translations
    'MursionPortal.SimAttendance.Error.LearnerAndSimBothJoined' :'Learner and SIM both joined, but no connection established', // TODO add translations
    'MursionPortal.SimAttendance.Error.SimMomentIssue' :'SIM Moment issue', // TODO add translations
    'MursionPortal.SimAttendance.Error.WifiIssue' :'Wifi Issue', // TODO add translations
    'MursionPortal.SimAttendance.Error.LearnerWifi' :'Learner Wifi', // TODO add translations
    'MursionPortal.SimAttendance.Error.SimWifi' :'SIM Wifi', // TODO add translations
    'MursionPortal.SimAttendance.Error.AdditionalDetails' :'Additional Details', // TODO add translations
    'MursionPortal.SimAttendance.Error.DescribeYourError' :'Describe your error...', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.CancellationByProfessionalServiceOrAccountOwner': 'Cancellation by PS or AO', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.CompleteLearnerSurvey': 'Complete Learner Survey', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.CompleteLearnerSurvey': 'How\'d it go?', // TODO add translations
    'MursionPortal.CustomizeInvitation.ButtonText.Label': 'Button Text', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.Client.LearnerLockedOut': 'You are locked out!', // TODO add translations
    'MursionPortal.Filter.ReleaseLevel.Placeholder': 'Release Level', // TODO add translations
    'MursionPortal.TOTP.Page.Heading': 'We just sent a security code to your {email}. Enter the code to sign in.', // TODO add translations
    'MursionPortal.TOTP.ResendOTP.Text': 'Resend Code', // TODO add translations
    'MursionPortal.TOTP.Code.Label': 'Code', // TODO add translations
    'MursionPortal.TOTP.IncorrectCode.ErrorMsg': 'The code is incorrect. Enter the correct code.', // TODO add translations
    'MursionPortal.TOTP.ResendNewCode.ErrorMsg': 'The code has expired, click "Resend" to receive a new code.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.Client.LearnerReleased': 'You are ready to schedule again.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.NewPortal.Subject.FirstReminder': `You’ve an upcoming Mursion Session in {firstReminderTime} hours!`, // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.NewPortal.Subject.SecondReminder': `Your Mursion Session is starting in {upcomingBeforeStart} minutes!`, // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.NewPortal.Subject.LearnerFeedbackReport': 'Mursion - Review Your Session Performance Report.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.NewPortal.Subject.LearnerSessionRecordingIsReady': 'Your Session Recording is now up! Review how you did', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.NewPortal.Subject.Confirmation': 'Your Mursion Session is Confirmed!', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.NewPortal.Subject.CancellationByLearnerOrPSOrAO': 'Your {licenseeName} session was canceled', // TODO add translations
    'MursionPortal.Personalized.PathwayPage.Section': 'Message you want learners to see on Pathway Page', // TODO add translations
    'MursionPortal.Personalized.CreateNewPathway.Button.Label': 'Create new for specific pathway', // TODO add translations
    'Projects.ScenarioList.NoPathwaysFound': 'No Pathways have been found.',// TODO add translations
    'MursionPortal.Personalized.Pathway.Search.Placeholder': 'Search or select Pathway(s):',// TODO add translations
    'Mursion.Portal.Status.SimTechnicalError': 'Sim Technical Error',// TODO add translations
    'MursionPortal.SimAttendance.Sim.Tooltip.Error': 'Session was unable to be completed due to technical difficulties on the Sim’s side. (if the session was able to be completed after an error this session should be marked complete and not error).',// TODO add translations
    'Session.Edit.Modal.Notes.BlankNoteValidation': 'Either add a valid note or remove this block', // TODO add translations
    'MursionPortal.Scenario.Error.Msg': 'Please complete required field.', // TODO add translations
    'MursionPortal.Scenario.Skills.Error.Msg': 'The number of skills must be in the range of {minSkills} and {maxSkills}.', // TODO add translations
    'MursionPortal.Capitalized.Incomplete': 'INCOMPLETE', // TODO add translations
    'MursionPortal.Dashboard.Chart.LicenseBreakdown.Title': 'License Breakdown – Contract Item',  // TODO add translations
    'MursionPortal.Dashboard.Chart.LearnerLicenses': 'Learner Licenses',  // TODO add translations
    'MursionPortal.Dashboard.Chart.LearnersRegistered': 'Learners Registered',  // TODO add translations
    'MursionPortal.Dashboard.Chart.LearnersScheduled': 'Learners Scheduled',  // TODO add translations
    'MursionPortal.SessionAttendance.AddAttendees': 'Add attendees',  // TODO add translations
    'MursionPortal.SessionAttendance.SimulationSpecialist': 'Simulation Specialist',  // TODO add translations
    'MursionPortal.Dashboard.SimulationSpecialist.Status': 'Simulation Specialist Status',  // TODO add translations
    'MursionPortal.Dashboard.Technical.Issue.Summary': 'If a technical issue occurred during this session, please indicate the type(s) of issue encountered:',  // TODO add translations
    'MursionPortal.Dashboard.Provide.Details': 'Please provide more details on what happened',  // TODO add translations
    'MursionPortal.SessionAttendance.SwitchToSimRole.Message': 'Please switch role to Sim Specialist to view the page!', // TODO add translations
    'MursionPortal.SessionAttendance.Heading': '<code>Instructions:</code> Review the Session, Simulation Specialist, and Learner Status\' below and update as required. This form may be edited for 24 hours after the end of the session', // TODO add translations
    'MursionPortal.SessionAttendance.SessionStatus.Label': 'This is automatically determined by the Learner and Simulation Specialist status. No action required.', // TODO add translations
    'MursionPortal.SessionAttendance.SessionDetails.Heading': '(selections below do not impact session status)', // TODO add translations
    'MursionPortal.SessionAttendance.SessionDetails.Instructions': '<code>Instructions:</code> Session details <code>must</code> be provided for any session that has a Learner Status other than ‘Complete’ or ‘No Show’. To complete, select one or more issue type(s) and describe details in the text box. Session detail information does not impact the Learner, Simulation Specialist, or Session status.', // TODO add translations
    'MursionPortal.SessionAttendance.SessionDetails.Text': `Mursion Simulation Specialists only: If you filled out a UTS ticket for this session, please include the link to the Slack post in the text box (click the 3 vertical dots to the right of the post and select ‘Copy link').`, // TODO add translations
    'MursionPortal.SessionAttendance.SessionStatus.Simspecialist.Label': 'This is automatically determined by the system. If you encountered an error that prevented the session from being completed and the indicated status is ‘Left’, select ‘Technical Error (SIMS)’. Otherwise, no action required.', // TODO add translations
    'MursionPortal.SessionAttendance.SessionStatus.Learner.Label': 'This is automatically determined by the system. If the indicated status is inaccurate, select the correct status and explain the reason for the revision in Session Details. Otherwise, no action required.', // TODO add translations
    'MursionPortal.SessionAttendance.SessionStatus.SimStatus.Label': 'Technical Error (SIMs)', // TODO add translations
    'Mursion.Portal.Status.UnableToComplete': 'Unable to Complete', // TODO add translations
    'Mursion.Portal.Status.ElectedToLeave': 'Elected to Leave', // TODO add translations
    'Mursion.Portal.Status.TechnicalErrorLearner': 'Technical Error (Learner)', // TODO add translations
    'MursionPortal.SessionAttendance.Error.Audio': 'Audio', // TODO add translations
    'MursionPortal.SessionAttendance.Error.ConnectionInternet': 'Connection / Internet', // TODO add translations
    'MursionPortal.SessionAttendance.Error.LearnerTechnicalIssues': 'Learner technical issues', // TODO add translations
    'MursionPortal.SessionAttendance.Error.MursionTechnicalIssues': 'Mursion technical issues', // TODO add translations
    'MursionPortal.SessionAttendance.Error.OtherTechnicalIssues': 'Other technical issues', // TODO add translations
    'MursionPortal.SessionAttendance.Error.MomentSoftware': 'Moment Software', // TODO add translations
    'MursionPortal.SessionAttendance.Error.AvatarOrEnvironmentLipSyncMovementEtc': 'Avatar or Environment (lip sync, movement, etc.)', // TODO add translations
    'MursionPortal.SessionAttendance.Error.HardwareControllerLaptopEtc': 'Hardware (controller, laptop, etc.)', // TODO add translations
    'MursionPortal.SessionAttendance.Error.LearnerAndSimulationSpecialistBothJoined': 'Learner and Simulation Specialist both joined, no connection established', // TODO add translations
    'MursionPortal.SessionAttendance.Error.OtherPleaseExplainBelow': 'Other (please explain below)', // TODO add translations
    'MursionPortal.SessionAttendance.Error.NonTechnicalIssues': 'Non-technical issues', // TODO add translations
    'MursionPortal.SessionAttendance.Error.LearnerMaterialsOrPreparation': 'Learner materials or preparation ', // TODO add translations
    'MursionPortal.SessionAttendance.Error.LearnerLanguageFluency': 'Learner language fluency', // TODO add translations
    'MursionPortal.SessionAttendance.Error.LearnerElectedToLeave': 'Learner elected to leave', // TODO add translations
    'MursionPortal.Dashboard.Overview.Empty.Body.Message': 'Contract report will load once you make a selection', // TODO add translations
    'MursionPortal.Dashboard.Chart.Header.SimulationBreakdown.ContractItem.Simulation':'Session Breakdown – Simulation Contract Item', // TODO add translations
    'MursionPortal.Dashboard.Chart.Header.SimulationBreakdown.ContractItem.Hour':'Session Breakdown – Hour Contract Item', // TODO add translations
    'MursionPortal.Copy.Tooltip.Text': 'Copy (plain text only, no formatting)', // TODO add translations
    'MursionPortal.RTF.DropDown.Text.Green': 'Green', // TODO add translations
    'MursionPortal.RTF.DropDown.Text.Thick': 'Thick', // TODO add translations
    'MursionPortal.Status.Connected.Label': 'Connected',  // TODO add translations
    'MursionPortal.Status.Immersing.Label': 'Immersing',  // TODO add translations
    'MursionPortal.Dashboard.NonTechnical.Issue.Summary': 'If a non-technical issue occurred during this session, please indicate the type(s) of issue encountered:',  // TODO add translations
    'MursionPortal.AutoSim.Scenario.TooltipLabel': 'AutoSim scenario can’t be scheduled.', // TODO add translations
    'MursionPortal.Scenario.Planing.AutoAssignTeam': 'Auto-Assign Team', // TODO add translations
    'MursionPortal.Scenario.Planing.AutoAssignTeam.Tooltip': 'Automatically assign this scenario to the selected teams when learners access the scenario page via the provided link.', // TODO add translations
    'MursionPortal.Dashboard.Overview.NoAssociatedDataForSelectedContract': 'No associated data for the selected contract(s)', // TODO add translations
    'MursionPortal.ScenarioBank.OutcomeLevelSelector.LevelOne': 'Level 1', // TODO add translations
    'MursionPortal.ScenarioBank.OutcomeLevelSelector.LevelTwo': 'Level 2', // TODO add translations
    'MursionPortal.ScenarioBank.OutcomeLevelSelector.LevelThree': 'Level 3', // TODO add translations
    'MursionPortal.Dashboard.UpcomingSessions.IVS.Title': 'Make sure you’re ready to take the session by testing your connection before joining.',// TODO add translations
    'MursionPortal.Dashboard.UpcomingSessions.IVS.Button.Label': 'Test Connection',// TODO add translations
    'MursionPortal.Dashboard.UpcomingSessions.IVS.Support.Link.Title': 'If any test fails, copy results at the end of the test and share them with our support team by',// TODO add translations
    'MursionPortal.Dashboard.UpcomingSessions.IVS.Support.Link': 'Submitting a Support ticket.',// TODO add translations
    'MursionPortal.Dashboard.OnDemand.Text':'Ready, set, practice on-the-go',
    'MursionPortal.Dashboard.OnDemand.Title':'Try On-Demand Simulations',
    'MursionPortal.Dashboard.OnDemand.SubTitle':'Get a first look at the future of skill-building at work - dynamic GenAI simulations available instantly, no scheduling required. Practice anytime and receive actionable feedback that helps you grow and master new skills.',
    'MursionPortal.Dashboard.OnDemand.SmallTitle':'Early access, real results—get ahead now.',
    'MursionPortal.Dashboard.OnDemand.Button.Label':'Go to Mursion On-Demand',
    'MursionPortal.Dashboard.OnDemand.Button.Hide.Label':'Hide this',
    'MursionPortal.Dashboard.OnDemand.Preview':'PREVIEW',
    'MursionPortal.Dashboard.OnDemand.Text1':'✓ Scalable, focused practice',
    'MursionPortal.Dashboard.OnDemand.Text2':'✓ Flexible access',
    'MursionPortal.Dashboard.OnDemand.Text3':'✓ Targeted practice for mastery',
    'MursionPortal.Dashboard.OnDemand.Text4':'✓ Guided self-reflection',
    'MursionPortal.Dashboard.OnDemand.Mursion':'Mursion',
  },
};
export default portugueseBrazilLangTranslations;
