import { LOCALES } from 'src/i18n/locales';
import { TLocaleId } from 'src/i18n';

const russianLangTranslations: { [key: string]: { [idKey in TLocaleId]: string } } = {
  [LOCALES['русский (Russian)']]: {
    'MursionPortal.SimAttendance.Tooltip.Error': 'Session was unable to be completed due to technical difficulties on the Learner’s side. (if the session was able to be completed after an error this session should be marked complete and not error).',// TODO add translations
    'SessionReports.ErrorOnLoadingVideo': 'Пожалуйста, используйте ноутбук или настольный компьютер для просмотра записи.',
    'MursionPortal.ConfirmationModal.Project.InvitationsConfirmationText': 'Вы уверены, что хотите пригласить <strong>всех назначенных учащихся</strong>, которые еще не завершили сценарии в проекте? Они получат приглашение по электронной почте, где сразу смогут записаться.',
    'MursionPortal.ConfirmationModal.Scenario.InvitationsConfirmationText': 'Вы уверены, что хотите пригласить <strong>всех назначенных учащихся</strong>, которые еще не завершили сценарии в проекте? Они получат приглашение по электронной почте, где сразу смогут записаться.',
    'Clients.Modal.Label.VideoRecordingDataRetentionDays.Tooltip': 'Количество дней, в течение которых клиент хотел бы сохранить данные видеозаписи. По истечении этого срока видеозаписи будут удалены.',
    'Settings.SSO.CopyScenarioLink': 'Скопируйте ссылку на приглашение сценария',
    'MursionPortal.Label.VideoRecordingSharing': 'Пользователи делятся или загружают видео своих сеансов',
    'MursionPortal.Status.Capitalized.LateCancelled': 'ПОЗДНЯЯ_ОТМЕНА',
    'MursionPortal.Status.Capitalized.EarlyCancelled': 'РАНЕЕ_ОТМЕНЕННЫЙ',
    'MursionPortal.Status.Abbreviation.LateCancelled': 'LC',
    'MursionPortal.Status.Abbreviation.Cancelled': 'C',
    'MursionPortal.Text.Congratulation': 'Поздравляем!',
    'MursionPortal.Text.PasswordChanged': 'Ваш пароль был изменен',
    'MursionPortal.Text.PasswordShouldBeSame': 'Ваши новые пароли должны совпадать.',
    'Filters.LateRescheduled': 'Перенесенные позднее',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.Scheduled': 'Предстоящие сессии, которые запланированы, но еще не завершены (включая запущенные)',
    'MursionPortal.Checkbox.EnableSmartMetrics': 'Включить голосовую аналитику участника сеанса',
    'Dashboard.SimulationTable.Column.TimesLateRescheduled': 'Количество Перенесенных Позднее',
    'Dashboard.SimulationTable.ColumnTooltip.TimesLateRescheduled': 'Количество раз, когда симуляция была перенесена в поздний срок',
    'SessionReports.Label.CopyVideoLink': 'Скопировать ссылку на видео',
    'Login.LoginAgain': 'Войдите в систему снова',
    'Dashboard.VideosTable.Column.SessionStart': 'Начало сеанса',
    'Filters.Date.LastSevenDays': 'Предыдущая неделя',
    'Filters.Date.NextSevenDays': 'Следующая неделя',
    'Filters.Date.ThisYear': 'Этот год',
    'Mursion.Portal.Status.LearnerMissed': 'Пропущено учащимся',
    'Mursion.Portal.Status.SystemCancelled': 'Отменено',
    'Mursion.Portal.Status.LateCancelled': 'Поздно отменено',
    'Mursion.Portal.Status.EarlierCancelled': 'Досрочно отменено',
    // 'MursionPortal.Dashboard.Chart.label.LearnerStatus.LearnerCompleted': 'Завершено',
    'MursionPortal.Dashboard.UpcomingSessions': 'Предстоящие сессии',
    'MursionPortal.Label.Opened': 'Открытые',
    'MursionPortal.Label.ActedOn': 'Действовующие',
    'MursionPortal.Label.Unread': 'Непрочитанные',
    'MursionPortal.Label.Timeline': 'Временные рамки проекта',
    'Projects.ProjectInfo.Label.TimelineUtc': 'Временные рамки проекта (UTC)',
    'MursionPortal.ScenarioInfo.Header.Description': 'Описание',
    'MursionPortal.TimelineUTC': 'Временные рамки проекта <code>{word}</code> (UTC)',
    'MursionPortal.Label.ChangePassword': 'Изменить пароль',
    'MursionPortal.Label.ChangeYourPassword': 'Измените свой пароль',
    'MursionPortal.Label.NewChangePassword': 'Введите новый пароль',
    'MursionPortal.Label.OldPassword': 'Введите свой текущий пароль',
    'MursionPortal.Label.RepeatChangePassword': 'Повторно введите свой новый пароль',
    'MursionPortal.Prompt.SessionScheduled': '<code>Сессия</code> отменена!',
    'MursionPortal.Status.Booked': 'Забронировано',
    'MursionPortal.Status.Pending': 'Ожидается',
    'MursionPortal.Completed': 'Завершено',
    'MursionPortal.Status.Missed': 'Пропущено',
    'MursionPortal.Status.Cancelled': 'Отменено',
    'MursionPortal.Status.LateCancelled': 'Поздно отменено',
    'MursionPortal.Status.EarlyCancelled': 'Досрочно отменено',
    'MursionPortal.Status.LicenseCancelled': 'Отменено',
    'MursionPortal.Status.Error': 'Ошибка',
    'MursionPortal.ConversationalFlow.label.zoom.all': 'Все',
    'Clients.ImportUsers.DownloadErrorsButton': 'Ошибки загрузки',
    'Mursion.Portal.Edit.Team.DownloadErrorButton': 'Ошибки загрузки',
    'MursionPortal.Dashboard.NextOnProject': 'Сценарии для планирования',
    'MursionPortal.Dashboard.NextOnProject.Footer.EndDate': 'У вас есть время до {endDate}, чтобы завершить этот сценарий.',
    'MursionPortal.Dashboard.NextOnProject.Footer.CompletionRate': 'У вас есть время до {endDate}, чтобы успеть пройти сценарий до {completionRate} раз.',
    'MursionPortal.Dashboard.NothingPlanned.text1': 'Все пройдено! Как только у вас будет',
    'MursionPortal.Dashboard.NothingPlanned.text2': 'что-то запланировано, это появится здесь.',
    'MursionPortal.Learner.Missed': 'ПРОПУЩЕНО УЧАЩИМСЯ',
    'MursionPortal.AriaImage.Attribute.Next': 'Вперед:',
    'MursionPortal.AriaImage.Attribute.Close': 'Закрыть:',
    'MursionPortal.AriaImage.Attribute.Back': 'Назад:',
    'MursionPortal.AriaImage.Attribute.Delete': 'Удалить:',
    'MursionPortal.AriaImage.Attribute.Remove': 'Убрано',
    'MursionPortal.AriaImage.Attribute.Document': 'Документ',
    'MursionPortal.AriaImage.Attribute.Video': 'Видео',
    'MursionPortal.AriaImage.Attribute.DeleteDocument': 'Удалить документ',
    'MursionPortal.ScenarioInfo.CompletionRate': '{completionRate} Раз',
    'MursionPortal.Button.RefreshPage': 'Обновить страницу',
    'MursionPortal.Prompt.CalendarUpdatedOutdatedNotification': 'Вы уверены, что хотите закрыть.Ваша страница календаря не будет обновлена.',
    'Reports.SessionListHeader.Label.AverageTime': 'Среднее время <code> в каждой сессии</code>',
    'MursionPortal.Users.Archive.HasSessions': 'Невозможно архивировать Simulation Specialist , потому что для Simulation Specialist уже запланировано одно или несколько сессий. Пожалуйста, поменяйте или измените Sim Specialist.',
    'MursionPortal.Label.InactiveSIMCertification.Tooltip': 'Если есть Sim, который сертифицирован по данной симуляции, но не имеющих доступа к новым симуляциям, то выберите его.',
    'MursionPortal.Label.InactiveSIMCertification': 'Сертификация неактивных Sim',
    'Dashboard.Filters.FilterBySessionDate': 'ФИЛЬТРОВАТЬ ПО ДАТАМ',
    'Dashboard.LeftPane.CategoryHeader.Emails': 'ЭЛЕКТРОННЫЕ ПИСЬМА',
    'Projects.ProjectInfo.Label.CancellationDeadline.Tooltip': 'Это количество дней/часов/минут, в течение которых учащийся может отменить занятие, если он зарегистрирован для участия в моделировании в рамках проекта. Если учащиеся отменяют занятия в течение этого срока, сессия будет считаться завершенной для выставляемого счета.',
    'Projects.ProjectInfo.Checkbox.AllowSessionRecording': 'Разрешить запись сеанса',
    'MursionPortal.Label.SendRecordedVideosTo': 'Отправляйте записанные видео на:',
    'MursionPortal.Checkbox.AllowLearnerToRefuseRecording': 'Разрешить учащемуся присоединиться к сеансу, если учащийся не согласен на запись',
    'Projects.ProjectInfo.SchedulingInterval': 'Интервал планирования',
    'Projects.ProjectInfo.SchedulingInterval.Tooltip': 'Позволяет составлять расписание с периодичностью, отличной от продолжительности сеанса.',
    'Projects.ProjectInfo.SchedulingIntervalLength': 'Продолжительность интервала планирования',
    'Projects.ProjectInfo.SchedulingIntervalLength.tooltip': 'Определяет частоту, с которой учащийся видит блоки планирования. Если установлено значение 15, учащиеся будут видеть блоки планирования с интервалом в 15 минут. Не учитывается продолжительность сессии, а только регулярность её выполнения.',
    'Projects.ProjectInfo.DeliveryMode.tooltip': 'Платформа, используемая вашими учащимися. Установите значение ML3, если вы хотите включить программное обеспечение Mursion Magic и/или ML3 для прямого подключения. ML3z = Zoom. Mursion Meet = Google Meet.',
    'MursionPortal.Label.WebOnly': 'Только Web',
    'MursionPortal.Label.DesktopOnly': 'Только для настольного компьютера',
    'MursionPortal.Label.DesktopAndWeb': 'Настольный компьютер и Web',
    'MursionPortal.EmailCategory.CallsToAction': 'Призывы к действию',
    'MursionPortal.Dashboard.EmailAnalytics.Label.Sent': 'Отправлено',
    'MursionPortal.Dashboard.EmailAnalytics.Label.AllEmails': 'Все электронные письма',
    'MursionPortal.Dashboard.EmailAnalytics.Label.Trends': 'Тренды',
    'MursionPortal.Dashboard.EmailAnalytics.Label.CategorySummary': 'Краткое описание категорий',
    'MursionPortal.Dashboard.EmailAnalytics.Label.RegistrationEmail': 'Регистрационные электронные письма',
    'MursionPortal.Dashboard.EmailAnalytics.Label.CallToActionEmail': 'Электронные письма с призывами к действию',
    'MursionPortal.Dashboard.EmailAnalytics.Label.SchedulingEmail': 'Планирование электронных писем',
    'MursionPortal.Dashboard.EmailAnalytics.Label.ReSchedulingEmail': 'Изменение планов по рассылке электронных писем',
    'MursionPortal.Dashboard.EmailAnalytics.Label.VideoEmail': 'Видео-электронные письма',
    'MursionPortal.Dashboard.EmailAnalytics.Label.EmailSent': 'Электронное письмо отправлено',
    'MursionPortal.Dashboard.EmailAnalytics.Info.EmailAnalytics': 'Аналитика электронной почты позволяет получить представление о том, как учащиеся взаимодействуют с <link>электронными сообщениями Mursion</link>. Электронные письма могут относиться к одной из следующих трех категорий',
    'MursionPortal.Dashboard.EmailAnalytics.Info.EmailOpened': 'Учащийся открыл одно или несколько отправленных ему электронных писем.',
    'MursionPortal.Dashboard.EmailAnalytics.Info.EmailActedOn': 'Учащийся открыл электронное письмо и нажал на одну или несколько ссылок, таких как <strong> Расписание</strong> или <strong> Присоединиться к симуляции</strong> ссылки.',
    'MursionPortal.Dashboard.EmailAnalytics.Info.Unread': 'Учащийся не открыл письмо. Если имеется большое количество непрочитанных писем, убедитесь, что ваши ученики их получают. Свяжитесь с IT-командой, чтобы убедиться, что <color>no-reply@mursion.com</color> не блокируется спам-фильтрами вашей организации.',
    'MursionPortal.Dashboard.Tooltip.RegisterEmail': 'Электронные письма пользователю с приглашением создать учетную запись Mursion',
    'MursionPortal.Dashboard.Tooltip.CallToActionEmail': 'Электронные письма с напоминаниями, отправленные Account Owner со страницы проекта или сценария',
    'MursionPortal.Dashboard.Tooltip.SchedulingEmail': 'Электронные письма, относящиеся к конкретной сессии. Приглашение в расписание и другие электронные письма о присоединении к сессии',
    'MursionPortal.Dashboard.Tooltip.ReschedulingEmail': 'Электронные письма с предложением учащемуся перенести встречу после пропущенного или отмененного моделирования',
    'MursionPortal.Dashboard.Tooltip.VideoEmail': 'Электронные письма, предупреждающие учащегося о том, что видео с симуляцией доступно для просмотра',
    'MursionPortal.Dashboard.EmailCategoryFilter': 'Категории электронной почты',
    'MursionPortal.Dashboard.EmailCategoryFilter.Search': 'Выполняйте поиск по категориям электронной почты...',
    'MursionPortal.Dashboard.EmailTypeFilter.SessionRescheduled': 'Сессия перенесена',
    'MursionPortal.Dashboard.EmailTypeFilter.Search': 'Выполняйте поиск по типам электронной почты...',
    'MursionPortal.Text.NoImageAvailable': 'Изображение недоступно',
    'Mursion.Portal.Edit.Team.Instruction.ExportFile': 'Нажмите <strong> Загрузить текущие членства</strong> ниже, чтобы обновить командные задания для учащихся (<strong>edit_learner_team_memberships.xlsx </strong>).',
    'Mursion.Portal.Edit.Team.DownloadExcelTemplate': 'Скачать текущие данные об участниках',
    'MursionPortal.Project.SameDay': 'В этот же день',
    'MursionPortal.Project.CancelationDeadline.Validation': 'Крайний срок отмены должен быть больше 0.',
    'ClientUserTable.AddNewUsers.RolesHeading': 'За один раз можно добавить только один тип пользователей. Выберите тип пользователя, которого вы хотите добавить.',
    'ClientUserTable.AddNewUsers.Roles.AccountOwner.Tooltip': 'Account Owner является основным администратором для всей вашей организации.',
    'ClientUserTable.AddNewUsers.Roles.Facilitator.Tooltip': 'Facilitator - это администратор командного уровня, который управляет определенной командой или подгруппами.',
    'ClientUserTable.AddNewUsers.Roles.Learner.Tooltip': 'Ученик - это любой, кто посещает симуляцию Mursion. Это будет основная часть вашей пользовательской базы.',
    'ClientUserTable.AddNewUsers.Heading': 'Добавить нового пользователя (ей)',
    'ClientUserTable.AddNewUsers.Roles.Heading': 'Назначьте роль пользователям',
    'ClientUserTable.AddNewUsers.ImportUsers': 'Пожалуйста, выполните приведенные ниже действия для 500 пользователей. При импорте более 500, используйте нашу функцию "<code>Импортировать пользователей</code>".',
    'ClientUserTable.AddNewAttributes.label': 'Добавить атрибуты пользователя',
    'ClientUserTable.AddNewAttributes.Header.Text': 'Обратите внимание, что эти атрибуты будут применены ко всем пользователям в этом списке.',
    'ClientUserTable.UserAuthentication.label': 'Аутентификация пользователя',
    'ClientUserTable.NewUsersAdded.Heading': 'Добавлен новый(ые) пользователь (и)!',
    'ClientUserTable.AddNewUsers.AssignTeams.Heading': 'Назначьте команду(ы) пользователям',
    'ClientUserTable.AddNewUsers.AssignTeams.CreateTeam': 'Учащиеся и Facilitator(ы) должны быть распределены в команду. Если вы еще не создали команду, пожалуйста, <code>нажмите здесь.</code>',
    'ClientUserTable.AddNewUsers.AssignTeams.SelectTeams': 'Выберите команду(ы)',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Validation': 'Пожалуйста, добавьте 500 или менее пользователей. При импорте более 500, используйте нашу функцию "Импортировать пользователей".',
    'ClientUserTable.AddNewUsers.AssignTeams.Validation': 'Вы должны выбрать хотя бы одну команду.',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Heading': 'Добавление адресов электронной почты пользователей',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.SubHeading': 'Вы можете добавлять по одной электронной почте за раз или вставить список. Электронные адреса следует разделять пробелом, запятой или точкой с запятой.',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Placeholder': 'Введите или вставьте здесь адреса электронной почты.',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.MaximumUsersAllowed': '{maximumUsersAllowed} пользователей за один раз',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Total': 'Все',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Duplicate': 'Повторяющиеся',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.AlreadyExists': 'Существующие',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Invalid': 'Недействительные',
    'ClientUserTable.AddNewUsers.AddNewAttributes.SignLanguageInterpreter': 'Сурдопереводчик',
    'ClientUserTable.AssignTeams.TotalUsers': 'Вы собираетесь добавить <code>{users} нового пользователя {role}</code>',
    'ClientUserTable.AssignTeams.AddAndUpdateUsers': 'Вы собираетесь добавить <code>{users} нового {role}</code> или обновить <code>{existingUsers} существующого пользователя{role}</code>',
    'ClientUserTable.AssignTeams.UpdateUsers': 'Вы собираетесь обновить <code>{existingUsers} существующего пользователя{role}</code>',
    'ClientUserTable.AssignTeams.NumberOfTeams': 'Будет добавлен к <code>{NumberOfTeams} команде(ам)</code>',
    'ClientUserTable.Confirmation.Heading': 'Пожалуйста, подтвердите перед добавлением',
    'MursionPortal.InviteToSchedule.Confirmation.Text': 'Узнайте больше здесь:<code>Приглашение в расписание</code>',
    'MursionPortal.AccountOwnerGuides.Confirmation.Text': 'Руководства для Account Owner',
    'MursionPortal.FacilitatorGuides.Confirmation.Text': 'Руководство Facilitator',
    'MursionPortal.Users.Confirmation.Text': 'Ваш пользователь(и) был добавлен(ы) на портал Mursion.',
    'MursionPortal.Confirmation.Header.Text.BackToUsers': 'Вернуться к пользователям',
    'MursionPortal.Users.Next.Steps.Text': 'Следующие шаги',
    'MursionPortal.Users.StepOne.Text': 'Шаг 1:',
    'MursionPortal.Users.StepTwo.Text': 'Шаг 2:',
    'MursionPortal.Users.StepThree.Text': 'Шаг 3:',
    'MursionPortal.Users.ThatIt.Text': 'Вот и все!',
    'MursionPortal.Users.InviteToScheduleLearner.Text': 'Предложите своим ученикам запланировать сессию',
    'MursionPortal.Users.LearnersLink.Text': 'Вы можете отправить эту ссылку своим ученикам',
    'MursionPortal.Users.InviteToSchedule.ProjectorScenario.Text': 'Используйте кнопку <strong>Приглашение в расписание</strong> для любого проекта или сценария.',
    'MursionPortal.Users.CopyInviteLink.Text': 'Скопировать ссылку на приглашение',
    'MursionPortal.Users.ShareContent.Text': 'Поделитесь контентом поддержки со своими Админами',
    'MursionPortal.Users.AOAndFacilitators.Responsibilities.Text': 'Account Owner(ы) и Facilitator(ы) несут разные обязанности, и им, возможно, потребуется узнать больше о портале. Поделитесь этими ссылками с Account Owner(ами) и Facilitator(ами), чтобы они могли найти ответы на любые вопросы, которые могут возникнуть у них на пути к Mursion.',
    'MursionPortal.Users.Administrators.Text': 'Теперь ваши админы будут иметь доступ к порталу Mursion.',
    'MursionPortal.Users.Progress.Text': 'Ваши ученики скоро начнут планировать свои симуляции. Вы можете отслеживать их прогресс в своей панели инструментов.',
    'MursionPortal.Users.Access.Text': 'Убедитесь, что ваш IT-отдел предоставил этим пользователям доступ к приложению Mursion',
    'MursionPortal.Users.Registrationemail.Text': 'Ваши пользователи уже должны были получить регистрационное письмо от no-reply@mursion.com.',
    'MursionPortal.AddMoreUsers.Btn.Text': 'Добавьте больше пользователей',
    'MursionPortal.Users.Ok.Btn.Text': 'ОК, понятно!',
    'Users.Button.AddNewUsers': 'Добавление новых пользователей',
    'MursionPortal.Project.CancelationDeadline.UpperLimit.Validation': 'Крайний срок отмены не может превышать 365 дней.',
    'MursionPortal.Dashboard.EmailTypeFilter.PasswordReset': 'Сброс пароля',
    'MursionPortal.Dashboard.EmailTypeFilter.Verification': 'Проверка',
    'MursionPortal.Dashboard.EmailTypeFilter.InviteToSchedule': 'Приглашение в расписание',
    'MursionPortal.Dashboard.EmailTypeFilter.Summary': 'Резюме',
    'MursionPortal.Dashboard.EmailTypeFilter.RescheuleMissed': 'Перезапланировать пропущенную сессию',
    'MursionPortal.Dashboard.EmailTypeFilter.RescheuleCancelled': 'Перезапланировать отмененную сессию',
    'MursionPortal.Dashboard.EmailTypeFilter.PSRescheule': 'Перезапланировано сервисом',
    'MursionPortal.Dashboard.EmailTypeFilter.VRecordingCorrupted': 'Видеозаписи повреждены',
    'MursionPortal.Dashboard.EmailTypeFilter.VRecordingNotUploaded': 'Видеозаписи не загружены',
    'MursionPortal.Dashboard.EmailTypeFilter.VRecordingAvailable': 'Доступна видеозапись',
    'MursionPortal.Dashboard.EmailTypeFilter.VRecordingShared': 'Видеозапись доступна всем',
    'MursionPortal.Dashboard.EmailTypeFilter.Booking': 'Бронирование',
    'MursionPortal.Dashboard.EmailTypeFilter.CodeOfConuct': 'Правила поведения',
    'MursionPortal.Dashboard.EmailTypeFilter.UserRemoved': 'Пользователь удален',
    'MursionPortal.Dashboard.EmailTypeFilter.AttendanceReminer': 'Напоминание о посещении',
    'Users.Button.AddNewLearners': 'Добавление новых учащихся',
    'MursionPortal.Scenario.Label.LastDelivered': 'Последнее доставленное',
    'MursionPortal.Scenario.Label.TotalDeliveries': 'Общее количество',
    'MursionPortal.Scenario.Text.NotYetDelivered': 'Еще не доставлено',
    'ClientUserTable.AddNewUsers.UpdateUserWarning': 'Эти пользователи {existingUsers} уже существуют. В дальнейшем эти пользователи будут добавлены в выбранные команды (в дополнение к уже назначенным командам).',
    'MursionPortal.AddNewUsers.Facilitators': 'Facilitator(ы)',
    'ClientUserTable.AddNewUsers.Learners': 'Учащийся(иеся)',
    'MursionPortal.Scheduling.TimeSlotsUnavailableMessage': 'Выбранный(ые) временной(ые) интервал(ы) больше не доступен(ы). Пожалуйста, обновите, чтобы просмотреть доступные временные интервалы.',
    'MursionPortal.Client.EmailTypeFilter.SessionFirstReminder': 'Напоминание о первой сессии',
    'MursionPortal.Client.EmailTypeFilter.SessionSecondReminder': 'Напоминание о второй сессии',
    'Projects.ProjectInfo.Label.ShowSIMAvailaiblity': 'Показать доступность SIM',
    'Projects.ProjectInfo.Label.ShowAvailaiblity.Tooltip': 'При составлении расписания учащийся увидит доступность SIM Specialist для выбранного вами интервала.',
    'Projects.InputValidation.GreaterThanZero': 'Должно быть больше 0.',
    'MursionPortal.Weeks': 'Неделя(и)',
    'MursionPortal.Scenario.CompletionRateFulfilled': 'You cannot schedule this simulation as you have completed the maximum number of simulations for this scenario or engagement as set by your organization.',// TODO add translations
    'MursionPortal.Dashboard.EmailTypeFilter.TimeSlotRequest': 'Запрос временного интервала',
    'MursionPortal.Dashboard.EmailAnalytics.OpenAndActed.Label': 'Информация об открытых и действующих данных точна по состоянию на 24 ноября 2022 года.',
    'MursionPortal.Dashboard.EmailAnalytics.Prior.Label': 'До этой даты все электронные письма относятся к непрочитанным.',
    'MursionPortal.Learners.Filters.LearnerCompletion': 'Завершение обучения',
    'MursionPortal.Learners.Filters.LearnerCompletion.NotYetCompleted': 'Еще не пройдено',
    'MursionPortal.Learners.Filters.LearnerCompletion.CompletedOneSession': 'Пройдена 1 сессия',
    'MursionPortal.Learners.Filters.LearnerCompletion.CompletedTwoPlusSessions': 'Пройдено более 2 сессии',
    'MursionPortal.Learners.Filters.MissedLateActivity': 'Пропущенное/запоздалое занятие',
    'MursionPortal.Learners.Filters.MissedLateActivity.1Session': '1 сессия',
    'MursionPortal.Learners.Filters.MissedLateActivity.2Sessions': '2 сессия',
    'MursionPortal.Learners.Filters.MissedLateActivity.3Sessions': '3 сессия',
    'MursionPortal.Learners.Filters.MissedLateActivity.4PlusSessions': 'более 4 сессий',
    'MursionPortal.BackToCompletedSessions': 'Вернуться к завершенным сессиям',
    'MursionPortal.Scenario.InviteToSchedule.Modal.Text': 'Вы хотите отправить приглашения <strong>всем</strong> назначенным учащимся, которые еще не прошли этот сценарий, или <strong> выбрать учащихся</strong> , которых нужно пригласить?',
    'MursionPortal.BackToSessionSummaries': 'Вернуться к краткому описанию сессий',
    'MursionPortal.BackToLearnerSessions': 'Вернуться в раздел Сессии для учащихся',
    'MursionPortal.LearnerSessionReport.SessionObjective': 'Цель сессии',
    'MursionPortal.LearnerSessionReport.SessionStrategy': 'Насколько хорошо вы применили эти стратегии?',
    'MursionPortal.LearnerSessionReport.SessionStrategyIcon': 'Стратегия сессии Достижение цели',
    'MursionPortal.LearnerSessionReport.SessionPerspective.Title': 'Продолжайте в том же духе!',
    'MursionPortal.LearnerSessionReport.SessionDetail': 'Описание сценария',
    'MursionPortal.LearnerSessionReport.SessionDate': 'Дата сеанса',
    'ClientUserTable.AddNewUsers.Procced.Button.Tooltip': 'Пожалуйста, удалите все дубликаты или недействительные электронные письма, прежде чем продолжить.',
    'MursionPortal.BackToSessionDetail': 'Вернуться к деталям сеанса',
    'MursionPortal.SimAttendance.Instructions.Label': 'Mursion Simulation Specialists:',// TODO add translations
    'MursionPortal.SimAttendance.Instructions.LabelText': 'Select an attendance status for each attendee (mouse over the status to learn more). Your status is gathered automatically – these statuses are for the learner ONLY.',// TODO add translations
    'MursionPortal.SimAttendance.Instructions.Label1': 'Software License Simulation Specialists:',// TODO add translations
    'MursionPortal.SimAttendance.Instructions.LabelText1': 'Select an attendance status for each attendee (mouse over the status to learn more). If you experienced an error in the session, please also provide the error details. To do this, select the type of error under the “SIM Reported Error” section and add context in the Additional Details text box. Additional details about attendance--even if it’s not an Error--are encouraged, but are optional. If you have any further questions about attendance, please reach out to your Simulation Program Admin.',// TODO add translations
    'MursionPortal.Dashboard.EmailAnalytics.Label.EmailAnalytics': 'Аналитика электронной почты',
    'MursionPortal.Session.LearnerSession.Report': 'Отчет',
    'MursionPortal.Session.LearnerSession.Recording': 'Запись',
    'MursionPortal.Session.LearnerSession.SessionDetails': 'Подробности сеанса',
    'MursionPortal.Session.LearnerSessionReport': 'Отчет о сессии для учащихся',
    'MursionPortal.Scenario.InviteToScedule.Modal.SelectLearners': 'Выберите учащихся',
    'MursionPortal.Scenario.Button.Label': 'Копировать электронные письма учащихся',
    'MursionPortal.VideoStatusFilter.Label': 'Статус видео',
    'MursionPortal.TooltipText.VideoNotUploaded': 'Если это видео не будет загружено по истечении 48 часов, пожалуйста, обратитесь в службу поддержки.',
    'MursionPortal.VideoStatusFilter.SearchText': 'Поиск статуса видео...',
    'MursionPortal.VideoStatusFilter.Uploaded': 'Загружено',
    'MursionPortal.VideoStatusFilter.NotUploaded': 'Не загружено',
    'MursionPortal.Scenario.Invite.Modal.Button.Label': 'Пригласить',
    'MursionPortal.Scenario.CopyEmails.Modal.Button.Label': 'Копировать электронные письма',
    'MursionPortal.Scenario.AllLearners.InvitationsSentOvernight': 'Ваши приглашения будут отправлены в одночасье, если учащийся еще не запланировал или не получил приглашение за последние 2 дня.',
    'MursionPortal.Scenario.SelectedLearners.InvitationsSentOvernight': '<code> Выбранные учащиеся</code> получат электронное письмо в ближайшее время с приглашением записаться, если они еще не записались или не получили приглашение за последние 2 дня.',
    'MursionPortal.Scenario.SelectedLearners.Confirm.Text': 'Вы уверены, что хотите пригласить <code>всех выбранных учащихся</code>',
    'ClientUserTable.Column.LastInvited': 'Последний приглашенный',
    'ClientUserTable.ColumnTooltip.LastInvited': 'Дата последнего отправленного по электронной почте приглашения к назначению сессии. Это может быть завтра, если вы только что отправили приглашение.',
    'MursionPortal.Scenario.SelectedLearners.EmailsCopied': 'Адреса электронной почты выбранных учащихся были скопированы в ваш буфер обмена',
    'MursionPortal.ScenarioInfo.MarkAllInactive': 'Отметить все неактивные',
    'MursionPortal.ScenarioInfo.DecertifyAll': 'Отменить сертификацию для всех',
    'MursionPortal.ScenarioInfo.MarkAllInactive.Confirmation.Text': 'Вы уверены, что хотите отключить всех SIM Specialist(ы) ?',
    'MursionPortal.ScenarioInfo.DecertifyAll.Confirmation.Text': 'Вы уверены, что хотите отменить сертификацию всех SIM Specialist(ы)?',
    'Dashboard.Button.ViewSession': 'Просмотреть сессию',
    'MursionPortal.SessionReport.NotAvailable': 'Не доступно',
    'Projects.ProjectInfo.Checkbox.EnableReportTabSurvey.OneToOne': 'Включить вкладку Отчет для отправки 1:1',
    'MursionPortal.Learner.Session.Report': 'Отчет о сессии для учащихся',
    'MursionPortal.Scenario.Sim.InactivedAll': '{effectedSims} из {totalSims} SIM(ов) неактивирован(ы).',
    'MursionPortal.Scenario.Sim.DecertifiedAll': '{effectedSims} из {totalSims} SIM(ов) получивших сертификацию.',
    'MursionPortal.Scenario.Sim.DecertifiedFew': '{remainingSims} SIMы не могут быть аннулированы, поскольку у них запланированы сессии. Отметьте их неактивными, чтобы отменить существующие и предотвратить назначение сессий в будущем.',
    'MursionPortal.AriaLabel.ViewMore': 'Посмотреть больше',
    'MursionPortal.SimAttendance.SimulationAttendance': 'Посещаемость обучающихся в симуляции',
    'Mursion.Portal.Status.LearnerTechnicalError': 'Learner Technical Error',// TODO add translations
    'Mursion.Portal.Status.TooLateToComplete': 'Слишком поздно, чтобы завершить',
    'Mursion.Portal.Status.LearnerElectedToLeave': 'Learner Elected to Leave',// TODO add translations
    'Mursion.Portal.Status.Unprepared.Tooltip': 'Учащийся присоединился, но не завершил симуляцию из-за неподготовленности или по другим причинам.',
    'MursionPortal.Label.SchedulingRate': 'Коэффициент планирования',
    'Mursion.Portal.Status.No.Show': 'Прогул',
    'MursionPortal.ScenarioInfo.SchedulingRate.Text': '{code} Раз',
    'MursionPortal.Scenario.Modal.Search.Placeholder': 'Поиск по адресу электронной почты, имени, фамилии',
    'MursionPortal.Scenario.SchedulingRateFulfilled': 'Сначала вы должны завершить запланированную сессию.',
    'MursionPortal.Scenario.InviteToScedule.Modal.Button.Tooltip': 'Выберите ученика, которого хотите пригласить',
    'MursionPortal.Learner.Session.Report.Report.SimulationRecording': 'Проанализируйте разговор, используя запись вашей симуляции.',
    'Dashboard.SimulationTable.ColumnTooltip.SoftwareVersion': 'Укажите используемое программное обеспечение для сессий',
    'Dashboard.SimulationTable.Column.SoftwareVersion': 'Программное обеспечение',
    'MursionPortal.LearnerSessionReport.YourPerspective': 'Ваша точка зрения',
    'MursionPortal.LearnerSessionReport.YourPerspectiveDescription': 'Ваша уверенность во время симуляции была <strong>{preConfidenceLevel}</strong>, а после симуляции была <strong>{postConfidenceLevel}.</strong>',
    'MursionPortal.LearnerSessionReport.YourPerspective.VeryLow': 'очень низкая',
    'MursionPortal.LearnerSessionReport.YourPerspective.Low': 'низкая',
    'MursionPortal.LearnerSessionReport.YourPerspective.Moderate': 'умеренная',
    'MursionPortal.LearnerSessionReport.YourPerspective.High': 'высокая',
    'MursionPortal.LearnerSessionReport.YourPerspective.VeryHigh': 'очень высокая',
    'MursionPortal.LearnerSessionReport.Strategies.SuccessfullyStrategy': 'Вы <strong>успешно</strong> продемонстрировали эту стратегию',
    'MursionPortal.LearnerSessionReport.Strategies.BenefitStrategy': 'Вам <strong> помогло бы еще немного попрактиковаться</strong> в реализации этой стратегии',
    'MursionPortal.SchedulingRate.ValidationMessage': 'Коэффициент планирования должен быть меньше или равен коэффициенту завершенности',
    'MursionPortal.LearnerSessionReport.Footer.FooterTitle': 'Следующие шаги: Практика помогает достичь совершенства!',
    'MursionPortal.LearnerSessionReport.Footer.FooterSubTitle': 'Повторение - это ключ к тому, чтобы эти навыки вошли в привычку.',
    'MursionPortal.LearnerSessionReport.SessionObjective.Body': 'Ваш отчет будет готов в ближайшее время',
    'MursionPortal.Dashboard.NextOnProject.Footer.schedulingRateFulfilled.Text': 'You cannot schedule this simulation at this time as you have currently reached the scheduling limit for this scenario or engagement set by your organization.',// TODO add translations
    'MursionPortal.Status.Abbreviation.LicenseCancelled': 'C',
    'MursionPortal.Scenario.CharacterLimitExceeded.Message': 'Вы превысили лимит символов {exceededCharLength}.Пожалуйста, внесите изменения.',
    'MursionPortal.Scenario.AddedCharacter.Message': '{added} из {totalChar}',
    'MursionPortal.Scenario.CharacterLimit.Message': 'Ограничение по символам: {maxLength}',
    'MursionPortal.Scenario.Description.block.default.text': 'Добавьте описание здесь',
    'MursionPortal.Scenario.AttachmentField.text': 'Вы можете добавить больше файлов',
    'MursionPortal.sessionTable.Column.OriginalSim': 'Оригинальная SIM',
    'MursionPortal.sessionTable.Column.NewSim': 'Новая SIM',
    'MursionPortal.Users.EditUser.InactivatedAll.Message': '{code} Сертификат(ы) был деактивирован(ы).',
    'MursionPortal.Users.EditUser.InactivateAll.Confirmation': 'Вы уверены, что хотите деактивировать все сертификаты для SIM Specialist?',
    'MursionPortal.Users.Modal.ActiveCerts.EmptyMessage': 'Нет активных сертификатов',
    'MursionPortal.Users.Modal.InactiveCerts.EmptyMessage': 'Нет неактивных сертификатов',
    'MursionPortal.Users.Modal.ActiveCerts': 'Активные сертификаты',
    'MursionPortal.Users.Modal.InactiveCerts': 'Неактивные сертификаты',
    'MursionPortal.Users.ActiveCerts.MarkAllInactive.Button': 'Отметить все неактивные',
    'MursionPortal.SurveyFilter.Incomplete': 'Незавершенные',
    'MursionPortal.SurveyFilter.SearchText': 'Поиск опроса...',
    'MursionPortal.Dashboard.SurveyReminder.Text': 'Вам нужно заполнить опросы {surveyCount}',
    'MursionPortal.Dashboard.RemindMeLater': 'Напомнить позже',
    'MursionPortal.Dashboard.SurveysIncomplete': 'Незавершенные опросы',
    'MursionPortal.AriaLabel.NormalFont': 'Обычный шрифт',
    'MursionPortal.AriaLabel.MediumFont': 'Средний шрифт',
    'MursionPortal.AriaLabel.LargeFont': 'Крупный шрифт',
    'MursionPortal.AriaLabel.Font': 'Шрифт',
    'Dashboard.LeftPane.CategoryHeader.RescheduledAndCancelled': 'Перенесено и отменено',
    'MursionPortal.Dashboard.Summary.Certifications': 'Сертификаты',
    'MursionPortal.SessionsTable.ActionTitle.Pickup': 'забрать',
    'MursionPortal.Label.MissCancellationRate': 'Коэффициент пропусков/отмены',
    'MursionPortal.Label.NotAllowed': 'Нет доступа',
    'Dashboard.LeftPane.CategoryHeader.Simulations': 'СЕАНСЫ',
    'Dashboard.LeftPane.Simulations.SimulationSummaries': 'Резюме сеанса',
    'Dashboard.LeftPane.Simulations.SimulationDetail': 'Сведения о сеансе',
    'MursionPortal.Message.NotEnoughDataToDisplay': 'Недостаточно данных об этом сеансе.',
    'MursionPortal.Message.Notdata.SessionMatrix': 'Недостаточно данных для анализа сеанса.',
    'MursionPortal.Tooltip.SchedulingWindow': 'Конечные пользователи могут установить это значение в днях, часах, минутах, после чего только пользователь сможет запланировать сеанс.',
    'MursionPortal.DemandBasedScheduling.TimeBlockUnavailableSelectDate': 'Сейчас у нас нет свободного времени. Пожалуйста, попробуйте позже. <code1></code1>Обратитесь в <code>поддержку</code>, если вам нужна помощь.',
    'Session.Edit.Modal.JoinSession': 'Начать сеанс',
    'MursionPortal.SimAttendance.Tooltip.Completed': 'Учащийся завершил симуляционную часть занятия (без учета подведения итогов в конце занятия).',
    'MursionPortal.SimAttendance.Tooltip.Late': 'Учащийся подключился слишком поздно для завершении симуляцию.',
    'MursionPortal.SimAttendance.Tooltip.Left': 'Учащийся намеренно отключился от симуляции до ее завершения. Это может произойти из-за неподготовленности учащегося, или по причине непредвиденных обстоятельств.',
    'Session.Edit.Modal.JoinSessionBtnTooltip': 'Кнопка «Начать сеанс» будет активирована до <code>{minutesBeforeJoin}</code> минут до начала сеанса.',
    'Dashboard.Simulations.SimulationSummaries.TableHeader': 'Сводные данные по сеансам',
    'Dashboard.Learners.LearnerActivity.TableHeader': 'Данные об активности учащихся',
    'Dashboard.Learners.LearnerSimulation.TableHeader': 'Данные о сессиях учащихся',
    'Dashboard.ContractProgress.Column.Missed': 'Пропущено учащимся',
    'Dashboard.ContractProgress.Column.TotalRescheduled': 'Всего перенесено',
    'Dashboard.ContractProgress.Column.RescheduledMakeup': 'Перенесенная подготовка',
    'Dashboard.ContractProgress.Column.LateRescheduledSimulations': 'Поздние переносы занятий',
    'Dashboard.ContractProgress.Column.EarlyRescheduledSimulations': 'Ранние переносы занятий',
    'Dashboard.ContractProgress.Column.CompletedUtilization': 'Завершение сеанса',
    'Dashboard.ContractProgress.Column.ScheduledUtilization': 'Предполагаемое завершение',
    'Dashboard.ContractProgress.ColumnTooltip.Remaining': 'Приобретенные сеансы за вычетом завершенных сеансов.',
    'Dashboard.ContractProgress.ColumnTooltip.TotalRescheduled': 'Всего запланированных симуляций',
    'Dashboard.ContractProgress.ColumnTooltip.RescheduledMakeup': 'Общее количество симуляций, помеченных как повторная подготовка',
    'Dashboard.ContractProgress.ColumnTooltip.LateRescheduledSimulations': 'симуляции, которые были перенесены с опозданием.',
    'Dashboard.ContractProgress.ColumnTooltip.EarlyRescheduledSimulations': 'симуляции, которые были перенесены заблаговременно.',
    'Dashboard.Learners.Column.SimulationId': 'ID сеанса',
    'Dashboard.Learners.Column.SimulationId.CopyHover': 'Копировать ID сеанса',
    'Dashboard.Learners.Column.SSO_ID': 'SSO ID',
    'Dashboard.Learners.Column.Recorded': 'Записано',
    'Dashboard.Learners.Column.LearnerRecordingConsent': 'Согласие учащегося на запись',
    'Dashboard.Learners.Column.RecordingRecipients': 'Получатели записи',
    'Dashboard.Learners.Column.RecordingUploaded': 'Запись загружена',
    'Dashboard.Learners.ColumnTooltip.SSOID': 'Пользователи, которым предоставлены права',
    'Dashboard.Learners.ColumnTooltip.Recorded': 'Обозначает, была ли симуляция записана или она была запрещена.',
    'Dashboard.Learners.ColumnTooltip.LearnerRecordingConsent': 'Обозначает, дал ли учащийся согласие на запись имитации.',
    'Dashboard.Learners.ColumnTooltip.RecordingRecipients': 'Роли, которым отправляется запись.',
    'Dashboard.Learners.ColumnTooltip.RecordingUploaded': 'Указывает, была ли загружена запись симуляции.',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.LateReschedule': 'Сеансы, которые были поздно перенесены учащимся.',
    'MursionPortal.Dashboard.Chart.label.SimulationBreakdown.LateReschedule': 'Поздние переносы занятий',
    'MursionPortal.Dashboard.Chart.Header.LearnerStatus': 'Статус учащегося (все контракты)',
    'Dashboard.SimulationTable.Column.SimulationStatus': 'Статус сеанса',
    'MursionPortal.Placeholder.ContractValue': 'Выберите контракт',
    'MursionPortal.Placeholder.NoContractValue': 'Контракт недоступен',
    'Dashboard.Learners.Column.AssignedTeams': 'Назначенные команды',
    'SessionReports.VideoRecordingDeletedWithSupportCase': 'К сожалению, это видео невозможно просмотреть из-за того, что оно находится на рассмотрении #{supportCase}.',
    'MursionPortal.Dashboard.CompletedSessions': 'Завершенные сеансы',
    'MursionPortal.Dashboard.ViewAll': 'Посмотреть все',
    'MursionPortal.Dashboard.DateOfSimulation': 'Дата сессии',
    'Dashboard.LeftPane.CategoryHeader.Videos': 'ВИДЕО',
    'Dashboard.LeftPane.Videos.NotUploaded': 'Записи не были загружены',
    'Dashboard.NotUploaded.TableHeader': 'Данные записи не были загружены',
    'Dashboard.VideosTable.Column.SessionId': 'ID сеанса',
    'Dashboard.VideosTable.Column.SimspecialistId': 'SIMSpecialistID',
    'Dashboard.VideosTable.Column.ClientId': 'ID клиента',
    'Dashboard.VideosTable.Column.SessionEnd': 'Конец сеанса',
    'Dashboard.VideosTable.Column.SoftwareVersion': 'Сценарная версия',
    'Dashboard.VideosTable.NoVideosError': 'Видео не найдено.',
    'Filters.SimSpecialist': 'Имя SIM',
    'Filters.SimSpecialistPlaceHolderHint': 'Поиск SimSpecialists',
    'MursionPortal.Placeholder.SimSpecialistValue': 'Выберите SimSpecialist',
    'Users.Table.ErrorOnLoadUserMessage': 'Произошла ошибка при загрузке пользователя!',
    'Dashboard.ContractProgress.InfoBadge.ContractDates': 'Даты контракта',
    'Dashboard.ContractProgress.InfoBadge.ContractID': 'ID контракта',
    'Dashboard.Learners.Column.EarlyCancelled': 'Ранняя отмена ',
    'Dashboard.Learners.Column.LateReschedules': 'Поздние переносы занятий',
    'Dashboard.Learners.Column.EarlyReschedules': 'Ранние переносы занятий',
    'Dashboard.Learners.Column.RescheduledMakeUp': 'Перенесенный подготовка',
    'Dashboard.Learners.Column.ErrorSim': 'Ошибка (SIM)',
    'Dashboard.Learners.Column.CancelledOps': 'Отменено (Ops)',
    'Dashboard.Learners.ColumnTooltip.AssignedProjects': 'Проекты, на которые в настоящее время назначен учащийся',
    'Dashboard.Learners.ColumnTooltip.AssignedTeams': 'Команды, в которые в настоящее время назначен этот учащийся',
    'Dashboard.Learners.ColumnTooltip.AssignedScenarios': 'Сценарии, к которым в настоящее время назначен этот учащийся.',
    'Dashboard.Learners.ColumnTooltip.ScheduledScenarios': 'Сценарии, которые этот учащийся запланировал, но еще не завершил.',
    'Dashboard.Learners.ColumnTooltip.CompletedScenarios': 'Сценарии, которые выполнил этот учащийся',
    'Dashboard.Learners.ColumnTooltip.EarlyCancelled': 'Симуляция, которая была отменена досрочно (до крайнего срока отмены) учащимся, фасилитатором или владельцем учетной записи или самой симуляцией',
    'Dashboard.Learners.ColumnTooltip.LateReschedules': 'Симуляции, которые были перенесены с опозданием (после крайнего срока отмены) учащимся, фасилитатором или владельцем учетной записи',
    'Dashboard.Learners.ColumnTooltip.RescheduledMakeUp': 'Cимуляции, которые ранее были пропущены учащимся, фасилитатором, владельцем учетной записи или самой симуляцией',
    'Dashboard.Learners.ColumnTooltip.EarlyReschedules': 'Часы симуляции, которые были перенесены заранее (до крайнего срока отмены) учащимся, фасилитатором или владельцем учетной записи',
    'Dashboard.Learners.ColumnTooltip.ErrorSim': 'Симуляции, завершившиеся ошибкой на стороне SIM при подключении',
    'Dashboard.Learners.ColumnTooltip.CancelledOps': 'Симуляции, которые были отменены Mursion',
    'SessionReports.VideoRecordingPermissionNotGranted': 'Это видео недоступно, потому что участник не дал согласия на запись.',
    'SessionReports.NoVideoAvailableForSession': 'Для этого сеанса нет видео.',
    'MursionPortal.NotAvailable': 'Н/Д',
    'Projects.ProjectInfo.Checkbox.EnablePostSimulationSurvey.OneToOne': 'Включить опрос обратной связи SIM после симляции для 1',
    'Projects.ProjectInfo.Checkbox.EnablePostSimulationSurvey.Workshop': 'Включить опрос обратной связи SIM после симуляции для проведения семинаров',
    'Projects.ProjectInfo.Checkbox.SurveyEnabled.PostSimulationSurvey.OneToOne': 'Опрос отзывов о SIM после симуляции 1',
    'Projects.ProjectInfo.Checkbox.SurveyEnabled.PostSimulationSurvey.Workshop': 'Результаты семинара по опросу обратной связи SIM после симуляции',
    'Clients.Portal.Button.EditMemberships': 'Изменить членство ',
    'Calendar.ProjectsList.ShowOnlyActiveProjects': 'Показать только активные проекты',
    'MursionPortal.Status.Undefined': 'Неопределенно',
    'MursionPortal.Status.Orphan': 'Потеряно',
    'MursionPortal.Status.Reserved': 'Зарезервировано',
    'MursionPortal.Status.Waif': 'Свободно',
    'MursionPortal.Status.Upcoming': 'Предстоящее ',
    'MursionPortal.Status.Running': 'Выполняется',
    'MursionPortal.Status.NeedsReview': 'Требуется проверка ',
    'MursionPortal.Status.Reviewed': 'Рассмотрено',
    'MursionPortal.ErrorMessage.NoDataAvailable': 'Недостаточно данных для анализа сеанса.',
    'Dashboard.NotUploaded.MainHeader': 'Записи не были загружены',
    'MursionPortal.Message.NotEnoughDataToDisplay.ConversationalFlow': 'Недостаточно данных о сеансе для создания диаграммы',
    'MursionPortal.Label.SIMSpecialist': 'SIM Specialist',
    'Dashboard.SimulationTable.Column.LearnerRecordingConsent': 'Согласие учащегося на запись',
    'Dashboard.SimulationTable.Column.RecordingRecipients': 'Получатели записи',
    'Dashboard.SimulationTable.Column.RecordingUploaded': 'Запись загружена',
    'Dashboard.SimulationTable.ColumnTooltip.LearnerRecordingConsent': 'Обозначает, дал ли учащийся согласие на запись симуляции',
    'Dashboard.SimulationTable.ColumnTooltip.RecordingRecipients': 'Роли, которым отправляется запись симуляции',
    'Dashboard.SimulationTable.ColumnTooltip.RecordingUploaded': 'Обозначает, была ли загружена запись симуляции',
    'MursionPortal.ConversationalFlow.label.zoom': 'Увеличить',
    'MursionPortal.label.LearnerInterjects': 'Реплика учащегося',
    'MursionPortal.label.AvatarInterjects': 'Реплика аватара',
    'MursionPortal.label.LearnerInterrupted': 'Вмешался ученик',
    'MursionPortal.label.AvatarInterrupted': 'Вмешался аватар',
    'MursionPortal.label.Silence': 'Тишина',
    'Clients.Modal.Label.UserRecordingConsent': 'Согласие пользователя на запись',
    'Clients.Modal.Label.UserRecordingConsent.ToolTip': 'Запросить согласие пользователя на запись',
    'MursionPortal.Notset': 'Не установлено',
    'Clients.Modal.Label.UserML3SocialRecordingConsent': 'Согласие на запись пользователя социальной сети Mursion',
    'Clients.Modal.Label.UserML3SocialRecordingConsent.ToolTip': 'Попросите пользователя дать согласие на запись. Если эта функция не доступна, специалист SIM Specialist спросит об этом устно.',
    'MursionPortal.LearnerSimSelector.Sim.Available.ToolTip': 'Сертифицированные SIMы, которые не забронированы на сеанс и доступны на выбранное время',
    'MursionPortal.LearnerSimSelector.Sim.Unavailable.ToolTip': 'Сертифицированные SIMы, которые не доступны в системе и не забронированы для другого сеанса в выбранное время',
    'Settings.Config.Client.VideoRecordingDataRetentionPolicy': 'Показать конфигурацию политики хранения данных видеозаписи',
    'MursionPortal.DemandBasedScheduling.BackButton.BackToScenario': 'Назад к сценарию',
    'MursionPortal.DemandBasedScheduling.BackButton.BackToCalendar': 'Вернуться в календарь',
    'SessionReports.VideoRecordingDeletedDueToDataRetentionPolicy': 'Это видео недоступно из-за политики хранения данных вашей компании.',
    'SessionReports.VideoRecordingAvailabilityMessage': 'Это видео будет доступно для просмотра до {videoAvailabilityDate}',
    'SessionReports.SessionOrLearnerStatusError': 'Видео для этого сеанса не было записано из-за технической ошибки.',
    'Clients.ImportUsers.NextStepButton': 'Следующий шаг',
    'Clients.ImportUsers.CancelButton': 'Отмена',
    'Clients.ImportUsers.UploadValidateButton': 'Загрузить + Подтвердить',
    'Session.Table.Column.Attendance': 'Посещаемость',
    'Session.Table.Column.Value.CompleteAttendance': 'Полная посещаемость',
    'Session.Table.Column.Value.AttendanceComplete': 'Завершение участия',
    'Session.Table.Column.Value.NotApplicable': '(Не применимо)',
    'Session.Table.Column.Survey': 'Опрос',
    'Session.Table.Column.Value.CompleteGoogleForm': 'Заполнить форму Google',
    'Session.Table.Column.Value.CompleteSurvey': 'Комплексный опрос',
    'Session.Table.Column.Value.SurveyComplete': 'Опрос завершен',
    'Projects.Modal.Text.LearnerSelected': 'Необходимо выбрать как минимум {maxLearners} учащихся',
    'Session.Table.Column.Value.SessionNotCompleted': 'Сеанс не завершен',
    'MursionPortal.Project.InviteToSchedule': 'Пригласить в расписание',
    'MursionPortal.Project.InvitedToday': 'Приглашен сегодня',
    'MursionPortal.Project.YourInvitationsText': 'Ваши приглашения будут отправлены ночью.',
    'MursionPortal.Project.InviteToSchedule.Tooltip': 'Приглашения на сценарий уже рассылаются. Вы сможете снова отправить приглашения завтра.',
    'MursionPortal.Project.InvitedToday.Tooltip': 'Учащиеся, которые не запланировали или не отменили/пропустили этот сценарий, получат электронное письмо с приглашением запланировать его.',
    'MursionPortal.Project.InviteMessage.SentDate': 'Последний раз было отправлено {sentDate}',
    'ITManager.TechRestriction.EditDialog.ProviderErrorMsg': 'Пожалуйста, выберите провайдера',
    'ITManager.TechRestriction.EditDialog.GlobalRestriction': 'Глобальное ограничение',
    'ITManager.TechRestriction.EditDialog.HolidayRestriction': 'Праздничное ограничение',
    'ITManager.TechRestriction.Table.Provider': 'Провайдеры',
    'Session.Table.Column.Survey.HoverText': 'Если эта функция включена, SIM получит ссылку для завершения отзыва об учащемся',
    'MursionPortal.Project.InviteMessage.InvitedBy': 'Приглашен {user} - {role}',
    'MursionPortal.Project.LearnersNotYetScheduled': '{learners} учащиеся все еще должны запланировать сеанс',
    'Session.Table.Column.Value.AttendanceRequired': 'Обязательное присутствие',
    'MursionPortal.Button.Schedule.Another': 'Запланировать еще ',
    'MursionPortal.EulaAgreement.CodeOfConduct': 'В каждой симуляции Mursion участвует один живой специалист по симуляции, который обеспечивает разговорный диалог для пяти виртуальных аватаров в учебной сессии. Задача специалиста по симуляции заключается в том, чтобы представить разговорные задачи, позволяющие обучающимся, таким как вы, отработать сложные человеческие навыки в каждом сценарии. В Mursion мы признаем важность создания безопасного пространства, где люди могут смело практиковаться в потенциально стрессовых, тонких и сложных разговорах. Чтобы обеспечить уверенное участие как обучающихся, так и специалиста по симуляции, мы просим участников обеих групп соблюдать основной кодекс поведения.',
    'MursionPortal.EulaAgreement.CodeOfConduct.Header': 'Обзор методологии и Кодекс поведения',
    'MursionPortal.EulaAgreement.UserAgreement.Header': 'Пользовательское Соглашение',
    'MursionPortal.Import.BackToUser': 'Вернуться к пользователю',
    'MursionPortal.Import.BackToTeam': 'Вернуться к команде',
    'Mursion.Portal.Edit.Team.Upload.AccurateUploading': 'Перед загрузкой убедитесь, что ваши данные верны.',
    'Mursion.Portal.Edit.Team.Upload.AskedReUpload': 'Если в вашем файле есть какие-либо ошибки, они будут показаны в результирующей электронной таблице на шаге 4. Следуйте инструкциям на шаге 4, чтобы исправить ошибки в исходном шаблоне и повторно загрузить файл.',
    'Mursion.Portal.Edit.Team.Upload.IncludeNewUser': 'Вы также можете включить новых пользователей вместе с теми, кто не смог пройти испытание, при повторной загрузке.',
    'Mursion.Portal.Edit.Team.ReadyToUpload': 'Когда ваш файл будет готов, нажмите «Загрузить + Проверить.',
    'Mursion.Portal.Edit.Team.UploadAndValidationFile': 'Загрузка + Проверка файла',
    'Mursion.Portal.Edit.Team.TeamAssignmentsUpdateSuccessfully': 'Командные задания обновлены успешно',
    'Mursion.Portal.Edit.Team.UploadSuccessTitle': 'Импортировано пользователей: {userCount}!',
    'Mursion.Portal.Edit.Team.UploadFailureTitle': 'Ошибка!',
    'Mursion.Portal.Edit.Team.UploadSuccess': 'Все пользователи успешно добавлены на портал.',
    'Mursion.Portal.Edit.Team.DoneButton': 'Готово',
    'Mursion.Portal.Edit.Team.DownloadSuccessful': 'Загрузка выполнена успешно — перейдите к следующему шагу.',
    'Mursion.Portal.Edit.Team.DownloadError': 'Ошибка загрузки. Повторите попытку.',
    'Mursion.Portal.Edit.Team.UploadFileAgain': 'Загрузить файл еще раз',
    'Mursion.Portal.Edit.Team.ContinueWithoutCorrecting': 'Продолжить, не исправляя ошибок.',
    'Mursion.Portal.Edit.Team.SuccessfulImportTitle': 'Успешное импортирование',
    'Mursion.Portal.Edit.Team.UnSuccessfulImportTitle': 'Не удалось импортировать',
    'Mursion.Portal.Import.User.HeaderTitle': 'Импортирование пользователей',
    'Mursion.Portal.Import.User.Upload.AskedReUpload': 'Если в вашем файле есть какие-либо ошибки, они будут показаны в результирующей электронной таблице на шаге 4. Следуйте инструкциям на шаге 4, чтобы исправить ошибки в <strong>исходном шаблоне</strong> и повторно загрузить файл.',
    'Mursion.Portal.Import.User.ReadyToUpload': 'Когда ваш файл будет готов и правильно отформатирован, загрузите файл для проверки',
    'Mursion.Portal.Import.User.ValidatingYourDataUntilTheUpload': 'Мы проверяем данные и импортируем пользователей. Повторите попытку через 15 минут или когда получите уведомление о завершении этого процесса.',
    'Mursion.Portal.Import.User.UploadSuccessTitle': 'Импортировано пользователей: {successCount}!',
    'Mursion.Portal.Import.User.ErrorContinueTitle': 'Продолжить, не исправляя ошибок!',
    'Mursion.Portal.Import.User.ErrorContinue': '{failedCount} users failed to import as the errors were not fixed.', // TODO add translations
    'Settings.ScenarioBank.Form.ArtBundlesWarning': 'Пожалуйста, переименуйте арт-пакет(ы)',
    'Mursion.Portal.Import.User.Instruction.Upload': 'Нажмите <strong>Загрузить шаблон Excel</strong> ниже и откройте загруженный файл (либо <strong>client_internal_users.xlsx</strong>, либо <strong>client_external_users.xlsx</strong>).',
    'Mursion.Portal.Import.User.Instruction.Format': '<strong>В шаблоне обязательны поля электронной почты, роли и SSO ID. Не оставляйте их пустыми. Правильно добавляйте информацию о пользователе под каждым заголовком.</strong> НЕ изменяйте заголовки, структуру шаблона или имя файла, так как это может привести к сбою импорта.',
    'Mursion.Portal.Import.User.Instruction.NewUser': 'В <strong>столбцах JS или FO</strong> добавьте названия любых команд, к которым должен быть назначен пользователь (убедитесь, что ваше написание точно соответствует имени).',
    'Mursion.Portal.Import.User.Instruction.Error': 'Как только вы введете всех своих пользователей, сохраните файл на рабочем столе или в другом месте, которое вы запомните, и нажмите <strong>Продолжить</strong>.',
    'Mursion.Portal.Import.User.Instruction.OriginalTemplate': 'Если в вашем файле есть какие-либо ошибки, они будут показаны в результирующей электронной таблице на шаге 4. Следуйте инструкциям по исправлению ошибок в <strong>исходном шаблоне</strong> и повторно загрузите файл.',
    'Mursion.Portal.Import.User.Instruction.UploadList': 'Если вы решите продолжить без исправления ошибок, файл <strong> Список неудачно загруженных файлов</strong> будет доступен к скачиванию только в течение 7 дней.',
    'Mursion.Portal.Import.User.DownloadExcelTemplate': 'Скачать шаблон Excel',
    'Mursion.Portal.Import.User.UploadFileStatus': 'Загрузка файла',
    'Mursion.Portal.Import.User.DoNotCloseUntilUpload': 'Мы проверяем данные и импортируем пользователей. Этот процесс будет продолжаться в фоновом режиме, поэтому вы можете перейти к другой вкладке или окну и вернуться к этому экрану в любое время. Этот процесс импорта должен занять менее 5 минут.',
    'Mursion.Portal.Import.User.FileStatus.validation': 'Импорт пользователей',
    'Mursion.Portal.Import.User.CorrectErrorSubHeading': 'Последний шаг: исправление ошибок',
    'Mursion.Portal.Import.User.SuccessCount': '{successCount} users were successfully imported; {failedCount} users failed to import – please review.', // TODO add translations
    'Mursion.Portal.Import.User.DownloadFile': 'Click the <strong>Download Errors</strong> button and open the file to view the errors.', // TODO add translations
    'Mursion.Portal.Import.User.FileTabs': '<strong>В шаблоне поля электронной почты и роли являются обязательными. Не оставляйте их пустыми</strong>. НЕ изменяйте заголовки, структуру шаблона или имя файла, так как это может привести к сбою импорта.',
    'Mursion.Portal.Import.User.ReviewCorrect': 'Исправьте ошибки в <strong>исходном шаблоне</strong> (либо <strong>client_internal_users.xlsx</strong>, либо <strong>client_external_users.xlsx</strong>) и повторно загрузите файл.',
    'Mursion.Portal.Import.User.DownloadErrorButton': 'Ошибка загрузки',
    'Mursion.Portal.Edit.Team.EditTeamTitle': 'Изменить членство в команде',
    'Mursion.Portal.Edit.Team.Instruction.UpdateFile': 'Под каждым заголовком правильно добавьте информацию о пользователе. В столбцах L - U добавьте названия команд, к которым должен быть приписан учащийся (т.е. команды, которые нужно добавить или обновить), по одному столбцу на команду. При необходимости можно оставить некоторые колонки команд пустыми. Пожалуйста, убедитесь, что все сделано правильно, так как при этом существующие назначения команд будут перезаписаны.',
    'Mursion.Portal.Edit.Team.Instruction.Process': 'Загружайте по одному файлу за раз — ограничение количества пользователей до 5000 на файл.',
    'Mursion.Portal.Edit.Team.Instruction.ProfileFields': '<strong>Поля электронной почты и роли являются обязательными — не оставляйте их пустыми.</strong> Не изменяйте заголовки, структуру шаблона или имя файла, так как это может привести к сбою импорта.',
    'Mursion.Portal.Edit.Team.Instruction.ErrorOccur': 'Поддерживаемые форматы файлов: только xlsx .',
    'Mursion.Portal.Edit.Team.DoNotCloseUntilUpload': 'Мы проверяем данные и обновляем командные задания. Этот процесс будет продолжаться в фоновом режиме, поэтому вы можете перейти к другой вкладке или окну и вернуться к этому экрану в любое время. Этот процесс должен занять менее 5 минут.',
    'Mursion.Portal.Edit.Team.FileStatus.validation': 'Обновление членства в команде',
    'Mursion.Portal.Edit.Team.UploadFailureNotification': 'В вашем файле более 5 000 пользователей. Пожалуйста, свяжитесь со службой поддержки или загрузите несколько файлов с пользователями менее 5 000 .',
    'Mursion.Portal.Edit.Team.ReviewCorrect': '<strong>В шаблоне поля электронной почты и роли являются обязательными. Не оставляйте их пустыми.</strong> НЕ изменяйте заголовки, структуру шаблона или имя файла, так как это может привести к сбою импорта.',
    'Mursion.Portal.Edit.Team.DownloadFile': 'Исправьте ошибки в исходном файле шаблона (<strong>edit_learner_team_memberships.xlsx</strong>) — НЕ изменяйте заголовки, структуру шаблона или имя файла, так как это может привести к сбою импорта.',
    'Mursion.Settings.ScenarioBank.Modal.ChecklistURL': 'URL-адрес контрольного списка SIM',
    'Mursion.Settings.ScenarioBank.Modal.MustBeAValidURL': 'Должен быть действительный URL',
    'MursionPortal.Label.SpeakingIsZero': 'составляет 0%',
    'MursionPortal.Label.InterruptionIsZero': 'что составляет 0% ',
    'MursionPortal.CodeOfConduct.SchedulingFlow.Text': 'Решив запланировать сеанс Mursion, вы соглашаетесь с моделью проведения симуляции Mursion и подтверждаете, что вы будете поддерживать и соблюдать Кодекс поведения Mursion. Подробнее читайте <code>здесь</code>.',
    'Mursion.Portal.Import.User.InvalidFile': 'Будут загружены только шаблоны csv или excel.',
    'Mursion.Portal.Import.User.MaxFileSize': 'Размер файла не должен превышать 5 МБ.',
    'Mursion.Portal.Import.User.Tooltip': 'Нажмите здесь, чтобы импортировать новых пользователей или обновить существующих пользователей из файла экспорта.',
    'Mursion.Portal.Edit.Team.Tooltip': 'Нажмите здесь, чтобы массово назначить или переназначить членство в команде.',
    'MursionPortal.Project.InviteLearnersToSchedule': 'Пригласите учащихся в расписание',
    'MursionPortal.CheckboxLabel.SchedulingByBookIt': 'Планирование от BookIt',
    'Dashboard.Report.SendReport': 'Отправить жалобу',
    'Dashboard.Report.ScheduleReport': 'Отчет по расписанию',
    'Dashboard.Report.EditSchedule': 'Редактировать расписание',
    'Dashboard.Report.ViewSchedule': 'Посмотреть расписание',
    'Dashboard.Report.DeleteSchedule': 'Удалить расписание',
    'Dashboard.Report.Filter.Title': 'Фильтры выбраны',
    'Dashboard.Report.SelectReportFrequency.Label': 'Выберите периодичность отчетов',
    'Dashboard.Report.SelectSessions.Label': 'Выберите сеансы для включения',
    'Dashboard.Report.SelectReportStartDate.Label': 'Выберите дату начала отчета',
    'Dashboard.Report.SelectReportStartDate.Tooltip': 'Если вы выбрали еженедельное получение отчетов, ваш отчет будет приходить каждую неделю в выбранный вами день. Если вы выбрали ежемесячно, он будет приходить в этот день каждый месяц.',
    'Dashboard.Report.SelectAccountOwner.Label': 'Выберите владельца аккаунта или координатора',
    'Dashboard.Report.Dropdown.Select.Placeholder': 'Выбирать',
    'Dashboard.Report.ManuallyAddRecipients.Label': 'Вручную добавить получателей',
    'Dashboard.Report.ManuallyAddRecipients.Label.Placeholder': 'Добавить адрес электронной почты здесь',
    'Dashboard.Report.RecipientsBox.Label': 'Получатели',
    'Dashboard.Report.RecipientsBox.RecipientMaxLimit': 'Получателей не может быть больше 30',
    'Dashboard.Report.SendReport.ConfirmationModal.Text': 'Отчет отправлен получателям.',
    'Dashboard.Report.ScheduleReport.ConfirmationModal.HeadingText': 'Ваш отчет запланирован!',
    'Dashboard.Report.ScheduleReport.ConfirmationModal.Text': 'Остановите или отредактируйте в любое время, нажав <strong>Редактировать расписание</strong>',
    'Dashboard.Report.EditSchedule.ConfirmationModal.HeadingText': 'Изменения были сохранены!',
    'Dashboard.Report.EditSchedule.ConfirmationModal.Text': 'Остановите отчеты в любое время, нажав <strong>Изменить расписание</strong>',
    'Dashboard.Report.DeleteSchedule.ConfirmationModal.Text': 'Расписание было удалено',
    'Dashboard.Report.DeleteSchedule.ConfirmationModal.ConfirmationText': 'Вы уверены, что хотите удалить расписание для этого отчета?',
    'Dashboard.Report.ConfirmationModal.Button': 'Готово!',
    'Dashboard.Report.DeleteSchedule.ConfirmationButton': 'Да, удалить',
    'Dashboard.Report.EditSchedule.EditButton': 'Сохранить изменения расписания',
    'Dashboard.Report.ReportFrequencyOption.Daily': 'ЕЖЕДНЕВНО',
    'Dashboard.Report.ReportFrequencyOption.Weekly': 'ЕЖЕНЕДЕЛЬНО',
    'Dashboard.Report.ReportFrequencyOption.Monthly': 'ЕЖЕМЕСЯЧНО',
    'Dashboard.Report.ReportSessionOption.PreviousDay': 'Сеансы предыдущего деня',
    'Dashboard.Report.ReportSessionOption.Previous7Days': 'Сеансы за предыдущие 7 дней',
    'Dashboard.Report.ReportSessionOption.CurrentSessions': 'Сеансы текущего дня',
    'Dashboard.Report.ReportSessionOption.Next7Days': 'Сеансы на ближайшие 7 дней',
    'Dashboard.Report.ReportSessionOption.MonthToDate': 'Сеансы с начала месяца',
    'MursionPortal.Dashboard.UpcomingSession': 'Предстоящая сессия',
    'Dashboard.Report.ReportSessionOption.PreviousFullMonth': 'Сеансы за предыдущий полный месяц',
    'Dashboard.Report.ReportSessionOption.Previous30Days': 'Сеансы за предыдущие 30 дней',
    'Projects.ProjectInfo.CheckBox.ScenarioSequencing': 'Последовательность сценариев',
    'Projects.ProjectInfo.CheckBox.ScenarioSequencing.Text': 'Нажмите , чтобы просмотреть и настроить порядок сценариев в этом проекте.',
    'Projects.ProjectInfo.Button.ScenarioSequence': 'Последовательность сценариев',
    'MursionPortal.Projects.DragAndDropModal.Text': 'Перетащите сценарии в порядке предпочтения',
    'MursionPortal.Projects.DragAndDropModal.DragHandlerImage': 'Перетащить изображение',
    'MursionPortal.Projects.DragAndDropModal.ModalCloseIcon': 'Значок Закрыть',
    'MursionPortal.Project.Scheduled.NextDate': 'Запланировано на {nextDate}',
    'MursionPortal.Project.Sequencing': 'Последовательность ',
    'MursionPortal.Project.Checkbox.Sequencing': 'Включить последовательность',
    'MursionPortal.Dashboard.Join.Tooltip': 'Как только эта сессия станет доступной, вы сможете присоединиться к ней.',
    'MursionPortal.Project.InviteToScheduleProject.Tooltip': 'Учащиеся, которые не запланировали или не отменили/пропустили сценарии в этом проекте, получат приглашение по электронной почте для планирования.',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts': 'Горячие клавиши',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.SpaceBar': 'Клавиша пробела',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.SpaceBar.Text': 'Выберите или сбросьте сценарий.',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.ArrowKeys': 'Клавиши со стрелками вправо и влево или клавиши со стрелками вверх и вниз',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.ArrowKeys.Text': 'Перетащите сценарий влево и вправо.',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.Esc': 'Клавиша Esc',
    'MursionPortal.Esc': 'Esc',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.Esc.Text': 'Закрыть модальную последовательность сценариев',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.Enter': 'Клавиша ввода',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.Enter.Text': 'Закрыть всплывающую подсказку по сочетаниям клавиш',
    'MursionPortal.Projects.DragAndDropModal.Tooltip': 'Откройте всплывающую подсказку по сочетаниям клавиш',
    'Dashboard.Report.DuplicateEntry.ErrorMessage': 'Повторная запись не допускается',
    'Projects.ProjectInfo.EnableSessionTimeStep': 'Включить временной шаг сеанса',
    'Projects.ProjectInfo.SessionTimeStepInfo': 'Если значение временного шага сессии не установлено на уровне проекта, будет использоваться временной шаг сессии на уровне лицензиата',
    'Session.Edit.Modal.JoinSimulation': 'Присоединиться к сеансу',
    'MursionPortal.Selector.RequestStatus': 'Статус запроса',
    'MursionPortal.Dashboard.RetakeSimulation': 'Повторить симуляцию',
    'MursionPortal.Dashboard.NextSimulationAssigned': 'Назначена следующая симуляция',
    'MursionPortal.AriaLabel.SelectTime': 'Выберите время',
    'Dashboard.Report.Frequency.Date.Select.Placeholder': 'Выберите дату начала',
    'MursionPortal.InstructoScenarioTeamMapping.Header': 'Выберите сценарий и команду, которую вы хотите отобразить',
    'MursionPortal.InstructoScenarioTeamMapping.MappingAlreadyExistMessage': 'Этот сценарий и команда уже сопоставлены с другим контекстом.',
    'MursionPortal.DataSummaryPoints.manualFulFilled': 'Заполняется вручную',
    'MursionPortal.DataSummaryPoints.pendingRequest': 'В ожидании спроса',
    'MursionPortal.DataSummaryPoints.declined': 'Отклоненные запросы',
    'MursionPortal.DataSummaryPoints.avgFulfilled': 'Среднее заполнение вручную',
    'MursionPortal.DataSummaryPoints.totalSwap': 'Всего обменов',
    'MursionPortal.DataSummaryPoints.swapAccepted': 'Принятые обмены',
    'MursionPortal.Dashboard.MissedSimulations': 'Пропущенные симуляции',
    'MursionPortal.Dashboard.RescheduledSimulations': 'Перенесенные симуляции',
    'MursionPortal.Dashboard.BookedSimulations': 'Забронированные симуляции',
    'MursionPortal.Dashboard.CancelledSimulations': 'Отмененные симуляции',
    'Dashboard.UserTable.Column.In': 'В',
    'Projects.ProjectInfo.ConfirmationDialog.ScenarioSequencing.Text': 'Вы уверены, что хотите отключить последовательность? Это также удалит последовательную нумерацию в каждом сценарии.',
    'Projects.ProjectInfo.Disable.Sequencing': 'Отключить последовательность',
    'MursionPortal.ErrorMessage.TechnicalIssueMsg': 'Пожалуйста, попробуйте еще раз. Мы столкнулись с технической проблемой.',
    'SessionFlow.Text.Event.DeletedByUsername': 'Удалено {пользователем}',
    'Settings.ScenarioBank.Modal.timestepShouldBeAtLeast': 'Либо снимите флажок "Включить временной шаг сеанса", либо введите значение временного шага не менее {минут} минут.',
    'MursionPortal.AriaImage.Attribute.Previous': 'Ранее',
    'MursionPortal.AriaImage.Attribute.Notify': 'Уведомлять',
    'MursionPortal.AriaImage.Attribute.Upload': 'Загрузить',
    'MursionPortal.Report.FilterHeading.SoftwareVersions': 'Версии программного обеспечения',
    'MursionPortal.Report.FilterHeading.DeliveryModes': 'Способы доставки',
    'MursionPortal.Report.FilterHeading.LearnerSessionStatuses': 'Статусы сеансов учащегося',
    'MursionPortal.Report.FilterHeading.LearnerStatuses': 'Статусы учащихся',
    'MursionPortal.Report.FilterHeading.SimulationStatus': 'Статус симуляции',
    'MursionPortal.Project.DuplicateProjectBtnTooltip': 'Продублирует все настройки и конфигурацию этого проекта в новый проект',
    'MursionPortal.Project.DuplicateProjectBtnText': 'Дублировать в Magic Project',
    'MursionPortal.ScenarioInfo.Label.Until': 'У вас есть время до {date}, чтобы выполнить до {completionRate}.',
    'Clients.Modal.Button.Schedule.Tooltip.Text': 'Сначала нужно запланировать предыдущий сценарий.',
    'MursionPortal.Scenario.Name': 'Название сценария',
    'MursionPortal.ScenarioCard.BackToProjects.Button': 'Вернуться к проектам',
    'MursionPortal.ScenarioCard.ViewMore.Button': 'Посмотреть больше',
    'MursionPortal.ScenarioInfo.Documents': 'Сценарные документы',
    'MursionPortal.ScenarioInfo.Documents.File': '{extension} файл',
    'MursionPortal.ScenarioCard.ViewSessions': 'Просмотр сеансов',
    'MursionPortal.Label.ScenarioPreview': 'Предварительный просмотр сценария',
    'MursionPortal.ScenarioInfo.AssignedTeams': 'Найдите или выберите команду (команды)',
    'MursionPortal.ScenarioInfo.CertifiedSIMSpecialists': 'Сертифицированные SIM Specialists',
    'MursionPortal.ScenarioInfo.AddSimSpecialist': 'Добавить SIM Specialist',
    'MursionPortal.ScenarioInfo.CertifiedSIMSpecialist.PencilIcon': 'Редактировать значок',
    'MursionPortal.ScenarioInfo.CertifiedSIMSpecialist.DeleteButton': 'Удалить SIM',
    'MursionPortal.ScenarioInfo.CertifiedSIMSpecialist.SearchText': 'Найдите или выберите SIM Specialist',
    'MursionPortal.ScenarioInfo.CertifiedSIMSpecialist.AddSim': 'Добавить SIM',
    'MursionPortal.Label.InactiveSIMSpecialists': 'Неактивные SIM Specialists',
    'MursionPortal.Label.Search.InactiveSIMSpecialists': 'Найдите или выберите специалиста(ов) в неактивных SIM',
    'MursionPortal.Project.InviteToSchedule.Sequencing.Tooltip': 'Последовательность задана для этого сценария, и никто из учащихся еще не запланировал предыдущий сценарий.',
    'Clients.Modal.Label.FacilitatorView.Tooltip': 'Если установлено значение "Уровень клиента", координаторы смогут видеть информацию на уровне владельца счета. Если установлено значение на уровне команды, координатор сможет видеть информацию только о командах, к которым он приписан.',
    'Clients.Modal.Label.VoiceAnalytics.Tooltip': 'Предоставляет учащимся автоматизированный анализ их сеанса.',
    'Clients.Modal.Label.Show.Utilization.Reporting.Tooltip': 'Добавляет отчет об использовании на приборную панель владельца счета.',
    'Clients.Modal.Label.VideoRecordingDataRetentionPolicy.Tooltip': 'Включите, если у клиента есть настраиваемая политика хранения данных.',
    'MursionPortal.Label.SoftwareType.Tooltip': '«Только через Интернет» означает, что учащиеся могут получить доступ к своим занятиям только через Mursion Magic, платформу на основе браузера.',
    'Projects.ProjectInfo.Label.Timeline.Tooltip': 'Все сеансы для этого проекта должны быть завершены в рамках временной шкалы проекта.',
    'Projects.ProjectInfo.Checkbox.AllowSessionRecording.Tooltip': 'Снимите флажок, чтобы прекратить запись.',
    'MursionPortal.Checkbox.AllowLearnerToRefuseRecording.Tooltip': 'Учащиеся могут отказаться от записи. Если флажок не установлен, и учащийся отклоняет разрешение на запись, он не сможет посетить сеанс.',
    'Projects.ProjectInfo.label.Tags.Tooltip': 'Теги — это организованные группы для быстрого поиска похожих проектов на уровне клиента.',
    'Projects.ProjectInfo.label.DemandTime.Tooltip': 'Сроки, когда учащиеся могут запросить симуляцию для слотов',
    'Projects.ProjectInfo.label.DemandSlots.Tooltip': 'Устанавливает количество вариантов расписания, которые ученик видит при составлении расписания. Если установлено нулевое значение, учащийся сможет мгновенно забронировать свободный временной интервал.',
    'Projects.ProjectInfo.label.Rescheduling.Tooltip': 'Позволяет учащимся перепланировать занятия. Обратите внимание, что учащийся не может забронировать сеанс в тот же день, если не установлен флажок Тот же день.',
    'MursionPortal.Project.SameDay.Tooltip': 'Позволяет учащимся перенести на более позднее время в тот же календарный день.',
    'Cleints.Modal.Label.AvailablePrivateSSOLabel': 'Включить приватный SSO:',
    'Cleints.Modal.Label.DomainsLabel': 'Веб-домен клиента:',
    'Clients.Modal.Label.Logo': 'Логотип клиента:',
    'Projects.ProjectInfo.Label.CancellationDeadline': 'Крайний срок отмены',
    'Clients.Modal.Label.MursionSocialAttendance.ToolTip': 'Собирайте посещаемость учащихся во время сеансов Mursion Social.',
    'Clients.Modal.Label.VideoRecordingDataRetentionDays': 'Срок хранения данных видеозаписи (в днях):',
    'Clients.Modal.Label.VideoRecordingDataRetentionPolicy': 'Политика хранения данных видеозаписи:',
    'MursionPortal.PasswordRequirementMessage.SpecialCharaters': 'хотя бы один специальный символ',
    'MursionPortal.ErrorMessage.InvalidCaptcha': 'Неверная капча. Пожалуйста, попробуйте еще раз.',
    'MursionPortal.Project.MissedAlert.TooLateJoin': 'Вы вошли слишком поздно , чтобы присоединиться',
    'MursionPortal.Project.MissedAlert.DontWorry': 'Не волнуйся! Такое бывает.',
    'MursionPortal.Project.MissedAlert.ClickReschedule': 'Щелкните изменить расписание симуляции.',
    'MursionPortal.Project.EmailMissedLink.TooLateJoin.ClickRescheduleTxt': 'Нажмите ниже, чтобы перенести симуляцию.',
    'MursionPortal.Password.Strength.Tooweak': 'Пароль слишком слабый',
    'MursionPortal.Password.Strength.Weak': 'Слабый пароль',
    'MursionPortal.Password.Strength.Medium': 'Средней надежности пароль',
    'MursionPortal.Password.Strength.Strong': 'Надежный пароль',
    'Dashboard.LeftPane.User.SchedulingInfo':'Информация о расписании',
    'Dashboard.Button.ExportToEmail':'Экспортировать данные',
    'Filters.All':'Всё',
    'Filters.More':' больше',
    'Filters.AllExcept':'Все, кроме',
    'Filters.MoreClients':' больше клиентов',
    'Filters.AllClients':'Все клиенты',
    'Filters.Role':'Роли',
    'FiltersHeading': 'ФИЛЬТРЫ:',
    'Filters.TimeZone':'Часовой пояс',
    'Dashboard.UserActivity.TableHeader':'нформация об активности пользователя',
    'MursionPortal.GenerateLearnerSurveyLink':'Скопировать ссылку на данные',
    'MursionPortal.SurveyLinkLabel':'Ссылка на ваши данные скопирована в ваш буфер обмена',
    'MursionPortal.Attention':'Внимание!',
    'MursionPortal.LinkCannotBeGenerated':'Ссылка не может быть создана, так как поле "ID контракта" отсутствует в этом проекте сценариев.',
    'Dashboard.UserTable.Column.UserName':'Имя',
    'Dashboard.UserTable.Column.DateInvited':'Приглашен(а)',
    'Dashboard.UserTable.Column.DateRegistered':'Зарегистрирован(а)',
    'Dashboard.UserTable.Column.DateArchived':'Архивировано',
    'Dashboard.UserTable.Column.DateLastLogin':'Последний вход',
    'Dashboard.UserTable.Column.UsedSoftwareVersions':'Программное обеспечение',
    'Dashboard.UserTable.Column.SessionsScheduled':'Запланированные симуляции',
    'Dashboard.UserTable.Column.SessionsCompleted':'Законченные симуляции',
    'Dashboard.UserTable.Column.SessionsMissed':'Пропущенныее симуляции',
    'Dashboard.UserTable.Column.LateCancellations':'Отмененные симуляции',
    'Dashboard.UserTable.Column.SessionsError':'Ошибки симуляции',
    'Session.Table.Column.ProjectId':'ID контракта',
    'Session.Edit.Modal.Button.Leave':'Отменить Мой Сеансе',
    'Users.TableModal.SSOIDPlaceHolder':'SSO ID',
    'Users.Table.Modal.SSOID':'SSO ID',
    'Dashboard.SchedulingInfo.Filter.IndustriesAll':'Отрасль',
    'Dashboard.SchedulingInfo.Filter.UsedSoftwareVersion':'Программное обеспечение',
    'Dashboard.SchedulingInfo.MainHeader':'Информация о расписании',
    'Dashboard.SchedulingInfo.TableHeader':'Данные расписания',
    'RestorePassword.ResetPassword':'Сбросить пароль',
    'RestorePassword.EmailHint':'Введите ваш эл.адрес',
    'Dashboard.ProjectUtilization.MainHeader':'Готовность проектов',
    'Settings.Config.ProjectAndScenario.AllowMursionMagic':'Mursion Magic',
    'Dashboard.SessionLearners.MainHeader':'Обучающиеся на сеансе',
    'MursionPortal.Table.ScrollMessage':'прокрутите влево для просмотра дополнительных колонок',
    'Dashboard.ProjectsActivity.MainHeader':'Деятельность по проекту',
    'MursionPortal.Table.ColumnHeader.ProjectId':'ID контракта',
    'MursionPortal.Button.ClearAll':'Очистить все',
    'MursionPortal.Dashboard.Chart.Registration':'Регистрация пользователя',
    'MursionPortal.Dashboard.Chart.InviteAll':'Пригласить Всех',
    'MursionPortal.Dashboard.Chart.Unregistered':'Не зарегистрирован',
    'MursionPortal.Dashboard.Chart.Registered':'Зарегистрирован(а)',
    'MursionPortal.Dashboard.Chart.Login':'Войти',
    'MursionPortal.Dashboard.Chart.NotLoggedIn':'Вы не авторизованны',
    'MursionPortal.Dashboard.Chart.LoggedIn':'Вы авторизованны',
    'MursionPortal.Dashboard.Chart.Scheduling':'Запланированные пользователи',
    'MursionPortal.Dashboard.Chart.NotScheduled':'Незапланированные',
    'MursionPortal.Dashboard.Chart.Simulations':'Симуляции',
    'MursionPortal.Dashboard.Chart.LateCancellations':'Позднее подтверждение',
    'MursionPortal.Dashboard.Chart.Completed':'Завершено',
    'MursionPortal.Dashboard.Chart.Errors':'Ошибки',
    'MursionPortal.Dashboard.Chart.Scheduled':'Запланированные',
    'MursionPortal.Dashboard.Chart.Tooltip.Registered':'Приглашенные пользователи или SSO-пользователи инициализировались и вошли в систему.',
    'MursionPortal.Dashboard.Chart.Tooltip.Unregistered':'Приглашенные пользователи, которые ещё не вошли в систему, или SSO-пользователи , которые инициализировались и ещё не вошли в систему.',
    'MursionPortal.Dashboard.Chart.Tooltip.NotScheduled':'Зарегистрированные/инициализированные пользователи SSO, которые еще не запланировали симуляцию',
    'MursionPortal.Dashboard.Chart.Tooltip.Scheduled':'Зарегистрированные/инициализированные SSO-пользователи,которые запланировали хотя бы одну симуляцию',
    'MursionPortal.Label.ProjectId':'ID контракта',
    'MursionPortal.Label.SoftwareType':'Тип',
    'MursionPortal.Label.SharingOrDownloading':'Не разрешать совместное использование или загрузку',
    'Settings.SSO.Modal.EmployeeIDClaimName':'ID сотрудника Необходимо ввести',
    'Settings.SSO.Modal.LocationClaimName':'Местоположение Необходимо ввести',
    'Settings.SSO.Modal.DepartmentClaimName':'Направление Необходимо ввести',
    'Settings.SSO.Modal.PhoneClaimName':'Телефон Необходимо ввести',
    'Settings.SSO.Modal.TitleClaimName':'Должность Необходимо ввести',
    'Settings.SSO.Modal.TimezoneClaimName':'Часовой пояс Необходимо ввести',
    'Settings.SSO.Modal.LanguageClaimName':'Язык Необходимо ввести',
    'MursionPortal.ProjectId':'ID контракта',
    'MursionPortal.AltText.MursionPortal':'Интернет-портал Mursion',
    'MursionPortal.Message.ClickToOpenApplicationDefault':'Нажмите ниже, чтобы посетить симуляцию',
    'MursionPortal.Message.ClickToOpenApplicationDesktopWeb':'Нажмите кнопку ниже, чтобы получить доступ к симуляции через настольное приложение Mursion',
    'MursionPortal.Message.OpenWebApplication':'Вы также можете получить доступ к вашему моделированию в нашем веб-приложении, Mursion Magic',
    'MursionPortal.Button.OpenMursionWeb':'Запустить Mursion Magic',
    'MursionPortal.Message.Or':'ИЛИ',
    'MursionPortal.Message.PortalHeadingText':'Тренировочные симуляторы EQ',
    'ITManager.TechRestriction.Table.CreateButton':'Добавить ограничение',
    'ITManager.TechRestriction.EditDialog.TagName':'Имя метки',
    'ITManager.TechRestriction.EditDialog.Title':'Редактировать технические ограничения',
    'ITManager.TechRestriction.CreateDialog.Title':'Новые технические ограничения',
    'MursionPortal.DateTimePicker.StartDate':'Дата начала',
    'MursionPortal.DateTimePicker.EndDate':'Дата окончания',
    'MursionPortal.DateTimePicker.TimeNotAvailable':'Выбранное время недоступно.',
    'ITManager.TechRestriction.ViewDialog.Title':'Просмотр технических ограничений',
    'MursionPortal.DateTimePicker.Timezone':'Часовой пояс',
    'MursionPortal.Text.Team':'команда',
    'MursionPortal.Text.Industry':'отрасль',
    'MursionPortal.Text.Environment':' среда',
    'MursionPortal.Text.Avatar':'аватар',
    'MursionPortal.Status.Capitalized.Upcoming':'ПРЕДСТОЯЩЕЕ',
    'MursionPortal.Status.Capitalized.Undefined':'НЕОПРЕДЕЛЕННО',
    'MursionPortal.Status.Capitalized.Orphan':'ПОТЕРЯННО',
    'MursionPortal.Status.Capitalized.Waif':'СВОБОДНО',
    'MursionPortal.Status.Capitalized.Reserved':'ЗАРЕЗЕРВИРОВАНО',
    'MursionPortal.Status.Capitalized.Booked':'ЗАБРОНИРОВАНО',
    'MursionPortal.Status.Capitalized.Pending':'В ОЖИДАНИИ',
    'MursionPortal.Status.Capitalized.Running':'ВЫПОЛНЯЕТСЯ',
    'MursionPortal.Status.Capitalized.Missed':'ПРОПУЩЕНО',
    'MursionPortal.Status.Capitalized.NeedsReview':'ТРЕБУЕТ_РАССМОТРЕНИЯ',
    'MursionPortal.Status.Capitalized.Reviewed':'РАССМОТРЕНО',
    'MursionPortal.Status.Capitalized.Cancelled': 'ОТМЕНЕНО',
    'MursionPortal.Status.Capitalized.LicenseCancelled':'ОТМЕНЕНО',
    'MursionPortal.Status.Capitalized.Error':'ОШИБКА',
    'MursionPortal.Status.Capitalized.SimError': 'ERROR',// TODO add translations
    'MursionPortal.Status.Capitalized.Offline':'ОФФЛАЙН',
    'MursionPortal.Status.Abbreviation.PendingNoLearners':'В ожид',
    'MursionPortal.Status.Abbreviation.PendingNoParticipants':'Вып',
    'MursionPortal.Status.Abbreviation.Swap':'С' ,
    'MursionPortal.Status.Abbreviation.Prebooked':'Заб' ,
    'MursionPortal.Status.Abbreviation.Booked':'З' ,
    'MursionPortal.Status.Abbreviation.Active':'А' ,
    'MursionPortal.Status.Abbreviation.Completed':'О' ,
    'MursionPortal.Status.Abbreviation.Missed':'П' ,
    'MursionPortal.Status.Abbreviation.NeedsReview':' ТР',
    'MursionPortal.Status.Abbreviation.Error':'Ош',
    'Settings.SSO.Table.NoSsoConfigurationsFound':'Конфигурации SSO не найдены.',
    'MursionPortal.Label.SelfReviewTracker':'ТРЕКЕР ДЛЯ САМООЦЕНКИ',
    'MursionPortal.ToggleButton.All':'всё',
    'MursionPortal.Label.PerformanceTracker':'ТРЕКЕР РЕЗУЛЬТАТИВНОСТИ',
    'MursionPortal.ToggleButton.TurnTaking':' ОЧЕРЁДНОСТЬ ОБЩЕНИЯ',
    'MursionPortal.Tooltip.Collaborative':'Показатель времени, в течение которого вы и аватар (ы) по очереди разговаривали, не перебивая друг друга во время разговора.',
    'MursionPortal.Tooltip.Disruptive':'Показатель времени, в течение которого вы или аватар(ы) прерывали друг друга во время разговора.',
    'MursionPortal.Tooltip.Speaking':'Показатель времени, которое вы потратили на общение с аватаром(ами) во время разговора.',
    'MursionPortal.Tooltip.Listening':'Показатель времени, которое вы потратили на прослушивание аватара (ов) во время разговора.',
    'MursionPortal.Tooltip.MiScore':'Ваша социальная эффективность во время разговора.',
    'MursionPortal.Tooltip.Percentile':'включая описание значения в UI',
    'MursionPortal.Label.MiScore':'MI Оценка',
    'MursionPortal.Label.Percentile':'процент',
    'MursionPortal.Label.NotEnoughData':'Недостаточно данных',
    'MursionPortal.Label.Listening':'Слушание',
    'MursionPortal.Label.Speaking':'Говорение',
    'MursionPortal.Label.Collaborative':'Взаимодействие',
    'MursionPortal.Label.Disruptive':'Нарушение дисциплины',
    'MursionPortal.ToggleButton.LocalLeaderboard':'Локальная таблица лидеров',
    'MursionPortal.ToggleButton.GlobalLeaderboard':'Глобальная таблица лидеров',
    'MursionPortal.ToggleButton.DeepInsights':'Подробное пояснение',
    'MursionPortal.ErrorMessage.DataBeingProcessed':'Данные сеансовой аналитики в данный момент обрабатываются. Они будут отображаться на странице после завершения обработки.',
    'MursionPortal.ErrorMessage.UnsupportedFormat':'Аналитика сеансов пуста или имеет не поддерживаемый формат.',
    'MursionPortal.Label.Positive':'Положительный',
    'MursionPortal.Label.Negative':'Отрицательный',
    'MursionPortal.Label.Neutral':'Нейтральный',
    'MursionPortal.Header.Tracking':'Отслеживание',
    'MursionPortal.Button.ReadMore':'Читать Дальше',
    'MursionPortal.Button.Hide':'Скрыть',
    'MursionPortal.Title.Interchange':'Развязка. Естественный разговор и чередование без вмешательств или перебиваний',
    'MursionPortal.Title.InterventionByYou':'Ваше вмешательство. Успешное перебивание того, кто говорит, не позволяя ему продолжить.',
    'MursionPortal.Title.InterventionByAvatars':'Вмешательство Аватаров. Успешное перебивание того, кто говорит, не позволяя ему продолжить',
    'MursionPortal.Title.InterjectionByYou':'Вы перебили.Попытка прервать говорящего безуспешна',
    'MursionPortal.Title.InterjectionByAvatars':'Междометие Аватаров. Попытка прервать говорящего безуспешно.',
    'MursionPortal.Title.Pauses':'Паузы. Более 0,2 с и менее 0,75 с',
    'MursionPortal.Label.VoiceSelf':'Озвучивать себя',
    'MursionPortal.Label.VoiceOthers':'Озвучивать других',
    'MursionPortal.Label.Avatars':'Аватары',
    'MursionPortal.Label.You':'Вы',
    'MursionPortal.ColumnHeader.User':'Пользователь',
    'MursionPortal.ColumnHeader.Percentile':'Процент',
    'MursionPortal.ColumnHeader.ScenariosCompleted':'Сценарии завершены',
    'MursionPortal.Message.LeaderboardsUpdated':'Таблицы лидеров будут обновляться по мере того, как все больше людей будет завершать свои симуляции.',
    'MursionPortal.Message.CheckBack':'Пожалуйста, проверяйте ваше положение в таблице время от времени !',
    'MursionPortal.Label.SimStart':'Начало Симуляции',
    'MursionPortal.Label.SimEnd':'Конец Симуляции',
    'MursionPortal.Tooltip.Summary.Speaking':'Время, потраченное на общение с аватаром(ами) в течение всего периода',
    'MursionPortal.Tooltip.Summary.Listening':'Время, потраченное на слушание аватара(ов) в течение всего разговора',
    'MursionPortal.Capitalized.NotAvailable':'Н/Д',
    'MursionPortal.ColumnHeader.Trait':'Отличительная особенность',
    'MursionPortal.ColumnHeader.Me':'Я',
    'MursionPortal.ColumnHeader.OthersAvg':'Другие (в среднем)',
    'MursionPortal.TooltipText.Speaking':'Вы говорите больше, чем {percentage}людей.',
    'MursionPortal.TooltipText.Listening':'Вы слушаете больше, чем {percentage}людей.',
    'MursionPortal.TooltipText.Collaborative':'Вы более склонны к сотрудничеству, чем {percentage} людей.',
    'MursionPortal.TooltipText.Disruptive':'Вы нарушаете дисциплину больше, чем {percentage} людей.',
    'MursionPortal.Label.FeedbackValue':'Оценка обратной связи',
    'MursionPortal.Button.AnalyticsData':'Аналитические данные',
    'MursionPortal.Button.RawAnalyticsData':'Необработанные аналитические данные',
    'MursionPortal.Header.SocialEffectiveness':'Социальная эффективность',
    'MursionPortal.Label.Current':'Текущее состояние',
    'MursionPortal.Header.ArticulationRate':'Скорость артикуляции',
    'MursionPortal.Label.Average':'Средний показатель',
    'MursionPortal.Header.Conversation':'Общение',
    'MursionPortal.Header.SpeakingListening':'Говорение / Слушание',
    'MursionPortal.Header.CumulativeTurnTaking':'Совокупная очерёдность общения',
    'MursionPortal.Header.InstantaneousTurnTaking':'Мгновенность очерёдности общения',
    'MursionPortal.ColumnHeader.ScenarioTemplate':'ШАБЛОН СЦЕНАРИЯ',
    'MursionPortal.ColumnHeader.Provider':'ПРОВАЙДЕР',
    'MursionPortal.Label.AbbreviatedMinute':'m',
    'MursionPortal.Header.Mursion':'<code>M</code>ursion',
    'MursionPortal.Header.Index':'<code>I</code>ndex',
    'MursionPortal.Header.Score':'Оценка',
    'MursionPortal.visibilityHidden.Selected':'выбранно',
    'MursionPortal.AriaLabel.CloseTechSupport':'Закрыть поддержку',
    'MursionPortal.Monitoring':'Мониторинг',
    'MursionPortal.Monitoring.User.FirstLetter':'U',
    'MursionPortal.Monitoring.Peer.Label':'ОДИН на ОДИН',
    'MursionPortal.Monitoring.Peer.NoUser':'Пожалуйста, выберите пользователя, чтобы просмотреть подробную информацию',
    'MursionPortal.Monitoring.Sessions.Total':'Общее количество сеансов',
    'MursionPortal.Monitoring.Sessions.Displayed':'Сеансы ML3 (отображаются)',
    'MursionPortal.Monitoring.Sessions.NotDisplayed':'Не ML3 сеансы',
    'MursionPortal.Monitoring.Card.StartTime':'Время начала',
    'MursionPortal.Monitoring.Notification.NewSession':'Добавлен новый сеанс.',
    'MursionPortal.ClickRefresh':'Нажмите "Обновить", чтобы просмотреть изменения.',
    'MursionPortal.AriaLabel.DraftScenarioSuperScript':'Статус чернового сценария{statusConfiguration}',
    'MursionPortal.AriaLabel.FinalizedScenarioSuperScript':'Статус завершенного сценария {statusConfiguration}',
    'MursionPortal.AriaLabel.StartedScenarioSuperScript':'Статус начатого сценария {statusConfiguration}',
    'MursionPortal.AriaLabel.FinishedScenarioSuperScript':'Статус законченного сценария {statusConfiguration}',
    'MursionPortal.AriaLabel.ArchivedScenarioSuperScript':'Статус архивированного сценария {statusConfiguration}',
    'Calendar.Button.ScheduleEventON':'Запланируйте событие на {thisDate}',
    'Calendar.Button.CantScheduleON':'Не возможно запланировать событие на {thisDate}',
    'MursionPortal.AriaLabel.ChooseFileButton':'нажмите на кнопку "файл"',
    'MursionPortal.Label.VisibilityHidden.FileUploaded':'Файл загружен',
    'MursionPortal.VisibilityHidden.EntityAdded':'Объект добавлен',
    'MursionPortal.VisibilityHidden.EntityRemoved':'Объект удален',
    'MursionPortal.Legend.VisibilityHidden.StationTypeHomeOffice':'Место (Офис, Дом)',
    'MursionPortal.Legend.VisibilityHidden.HoursFullTimePartTime':'Часы работы (Полный рабочий День, Неполный Рабочий День)',
    'MursionPortal.AriaLabel.ShowTechSupport':'показывать техническую поддержку',
    'MursionPortal.Monitoring.Card.UntilTheEnd':'Оставшееся время',
    'MursionPortal.Monitoring.Card.OverTime':'Превысить время',
    'MursionPortal.Title.DemandBasedSessionScheduling':'Запрашиваемое планирование сеансов',
    'MursionPortal.CheckboxLabel.SchedulingByDemand':'Планирование по требованию',
    'MursionPortal.CheckboxLabel.ProjectTimezone':'Часовой пояс проекта',
    'MursionPortal.CheckboxLabel.DemandTime':'Запросить время',
    'MursionPortal.Label.DemandTimeStart':'Запросить время начала',
    'MursionPortal.Label.DemandTimeEnd':'Запросить время окончания',
    'MursionPortal.Label.DemandWindow':'Диалоговое окно',
    'MursionPortal.Project.Days':'День(дни)',
    'MursionPortal.MyProfile.Hover.Profile':'профиль {userName}',
    'Mursion.Portal.SessionUserStatus.Connected':'ПОДКЛЮЧЕН/А',
    'Mursion.Portal.SessionUserStatus.Late':'ОПОЗДАНИЕ',
    'Mursion.Portal.SessionUserStatus.Immersing':'ВОВЛЕЧЕНИЕ',
    'Mursion.Portal.SessionUserStatus.Left':'ПОКИНУЛ/А СЕАНС',
    'Mursion.Portal.SessionUserStatus.Declined':'ОТКЛОНЕНО',
    'MursionPortal.DemandBasedScheduling.SubmitPage.ThankYou':'Спасибо!',
    'MursionPortal.DemandBasedScheduling.SubmitPage.Message':'Мы работаем над завершением вашей симуляции на основе предоставленных временных интервалов. Ожидайте от нас электронное письмо с более подробной информацией. До скорого!',
    'MursionPortal.DemandBasedScheduling.SubmitPage.GoToDashboard':'Перейдите на Мою панель управления' ,
    'MursionPortal.DemandBasedScheduling.ScheduleASession':'Запланировать сеанс',
    'MursionPortal.DemandBasedScheduling.Details':'ПОДРОБНЕЕ',
    'MursionPortal.DemandBasedScheduling.Slot':'СЛОТ {count}',
    'MursionPortal.DemandBasedScheduling.PickDateAndTime':'Выберите дату и время',
    'MursionPortal.DemandBasedScheduling.Button.Set':'УСТАНОВИТЬ',
    'MursionPortal.DemandBasedScheduling.Button.Clear':'ОЧИСТИТЬ',
    'MursionPortal.DemandBasedScheduling.Jumbotron.Text':'Детали сценария загрузятся, как только вы сделаете выбор',
    'MursionPortal.AriaDescribedby.StartDate':'Дата начала: Нажмите клавишу со знаком вопроса, чтобы получить сочетания клавиш для изменения даты',
    'MursionPortal.AriaDescribedby.EndDate':'Дата окончания: Нажмите клавишу со знаком вопроса, чтобы получить сочетания клавиш для изменения даты.',
    'MursionPortal.VisibilityHidden.Footer':'Нижний колонтитул подвал',
    'MursionPortal.VisibilityHidden.Header':'Заголовок шапка',
    'MursionPortal.AriaLabel.FullScreen':'на весь экран',
    'MursionPortal.AriaLabel.ClientNameEventsDisplayed':'События клиента {clientName}, отображены в календаре',
    'MursionPortal.AriaLabel.SideNavigationToggle':'Боковой переключатель навигации',
    'MursionPortal.AriaLabel.AddTags':'Добавить метки',
    'MursionPortal.VisibilityHidden.Navigation':'Навигация',
    'MursionPortal.AriaLabel.SortByDescendingOrderButton':'кнопка "Сортировать по убыванию" ',
    'MursionPortal.AriaLabel.SortByAscendingOrderButton':'кнопка "Сортировать по возрастанию" ',
    'MursionPortal.VisibilityHidden.ProjectsTabSelected':'Выбрана вкладка "Проекты"',
    'MursionPortal.VisibilityHidden.TeamsTabSelected':'Выбрана вкладка "Команды"',
    'MursionPortal.VisibilityHidden.SessionsTabSelected':'Выбрана вкладка "Сеансы"',
    'MursionPortal.VisibilityHidden.UsersTabSelected':'Выбрана вкладка "Пользователи"',
    'MursionPortal.Error.PageNotFound':'Ошибка 404. Страница Не Найдена',
    'MursionPortal.Text.PageNotFound':'Проверьте, есть ли у вас разрешение на просмотр страницы, и верна ли ссылка.',
    'MursionPortal.Text.Success':'Успешно!',
    'MursionPortal.Text.YouAreNowSignedIn':'Теперь вы вошли в программное обеспечение для симуляции Mursion.',
    'MursionPortal.Text.PleaseReturnToDesktop':'Пожалуйста, вернитесь в настольное приложение, щелкнув {linebreak} на значок \'M\' на панели задач вашего компьютера (Windows) или на док-панеле (Mac).',
    'MursionPortal.Client.EmailSettings.Header.DemandBased':'Заявки',
    'MursionPortal.Client.EmailSettings.TimeSlotsReceived':'Полученные временные интервалы',
    'MursionPortal.Label.Past':'Прошедшее ' ,
    'MursionPortal.Monitoring.SessionsSummary.Preparation':'Подготовка ' ,
    'MursionPortal.Monitoring.SessionsSummary.Normal':'Стандарт' ,
    'MursionPortal.Monitoring.SessionsSummary.Error':'Ошибка' ,
    'MursionPortal.Monitoring.SessionsSummary.Issue':'Проблема' ,
    'MursionPortal.Monitoring.SessionsSummary.NotLoggedIn':'Нет Входа В Систему',
    'MursionPortal.Monitoring.Legend.Label':'Легенда' ,
    'MursionPortal.Monitoring.Legend.Dashboard':'Панель управления' ,
    'MursionPortal.Label.SimSpecialist':'Специалист по симуляции' ,
    'MursionPortal.Monitoring.SessionsSummary.ConnectedToPeers':'Подключен К Узлам ' ,
    'Requests.Table.CertifiedScenarios':'Подтвержденные сценарии',
    'Requests.Table.UncertifiedScenarios':'Неподтвержденные сценарии',
    'Requests.Table.NoUpComingDemands':'У вас нет заявок с указанными выше критериями.',
    'Requests.Table.DeclineReason':'Отклонить - Выберите Причину',
    'Requests.Table.UnAvailable':'Недоступно',
    'Requests.Table.NotPrepared':'Не подготовлено',
    'Requests.Table.Other':'Другое',
    'Requests.Table.Availability':'Выберите готовность',
    'Requests.Table.Declined':'Отклонено',
    'Requests.Table.SuccessfullySent':'Запрос Успешно Отправлен',
    'Requests.Table.RequestCertificate':'Запросить сертификат',
    'Requests.Table.DeclineReasonText':'Причина отказа',
    'Requests.Table.AvailabilitySlot':'Слот готовности',
    'Requests.Table.DemandBased':'Запросы',
    'MursionPortal.Setting.Integrations':'Интеграция',
    'MursionPortal.Setting.Integrations.Title':'Пожалуйста, выберите интеграцию',
    'MursionPortal.Setting.Integrations.Header.PlatformConfigurations':'Конфигурации платформы',
    'MursionPortal.Button.AddConfigurations':'Добавление конфигураций',
    'Settings.LTI.PlaceHolder.EnterConfigName':'Введите Имя конфигурации',
    'Settings.LTI.Table.ColumnHeader.DeploymentID':'ID развертывания',
    'Settings.LTI.Table.ColumnHeader.Version':'Версия',
    'Settings.LTI.Table.Caption':'список конфигурации lti',
    'Settings.LTI.Table.Hover.ViewEditLti':'Просмотр/редактирование Lti',
    'Settings.LTI.Table.NoLtiConfigFound':'Конфигурация LTI не найдена',
    'MursionPortal.EditLtiModal.Button.DeleteLti':'Удалить конфигурацию',
    'MursionPortal.EditLtiModal.Button.SaveLtiConfig':'Сохранить конфигурацию',
    'MursionPortal.Lti.NewLTIConfig':'Новая конфигурация LTI',
    'MursionPortal.Lti.CreateLTIConfig':'Создайте конфигурацию LTI',
    'Settings.LTI.Modal.PlatformIssuerIdentifier':'Идентификатор разработчика платформы',
    'Settings.LTI.Modal.PublicKeysetEndpoint':'Конечная точка открытых ключей',
    'Settings.LTI.Modal.DeploymentId':'ID развертывания',
    'Settings.LTI.Modal.RolesMapping':'Сопоставление ролей',
    'Settings.LTI.Modal.RolesMapping.PortalRole':'Роль портала',
    'Settings.LTI.Modal.RolesMapping.LTIRole':'Роль LTI',
    'MursionPortal.ErrorMessage.LtiIdMustBeSpecified':'Необходимо указать идентификатор LTI.',
    'MursionPortal.Setting.Integrations.LTIVersion':'Версия LTI',
    'MursionPortal.Dialog.CreateLTIConfiguration.SuccessMessage':'Конфигурация LTI была успешно создана.',
    'MursionPortal.DemandBasedScheduling.TimeBlockUnavailableReserved':'Временной блок недоступен - зарезервирован для другой симуляции.',
    'Requests.Table.ViewAvailability':'Просмотреть наличие',
    'Settings.LTI.Modal.DeletionConfirmationText':'Вы уверены, что хотите удалить это сопоставление ролей?',
    'Settings.ScenarioBank.Modal.InactiveCertification':'Неактивная сертификация' ,
    'Settings.ScenarioBank.Modal.NoInactiveSimSpecialist':'Нет неактивных специалистов по симуляции' ,
    'Settings.ScenarioBank.Modal.EditInactiveCertification':'Изменить неактивную сертификацию' ,
    'Settings.ScenarioBank.Modal.NoInactiveCertification':'Нет неактивных сертификатов' ,
    'MursionPortal.RequestStatus.All':'Статус запроса: Все',
    'MursionPortal.RequestStatus.Accepted':'Статус запроса: Принято',
    'MursionPortal.RequestStatus.Declined':'Статус запроса: Отклонено',
    'MursionPortal.RequestStatus.Pending':'Статус запроса: В ожидании',
    'Requests.Table.RequestCertification':'Запросить сертификат',
    'Requests.Table.RequestTime':'Время запроса',
    'Requests.Table.ScheduleType':'Тип расписания',
    'Requests.Table.AcceptTime':'Время принятия ',
    'Requests.Table.Scheduling':'Составление расписания',
    'Requests.Table.FulfilledIn':'Выполнено За',
    'MursionPortal.DemandBasedScheduling.Timezone':'Часовой пояс',
    'MursionPortal.DemandBasedScheduling.Timezone.Tooltip':'Перейдите в настройки своего профиля, чтобы изменить часовой пояс.',
    'MursionPortal.Results.Found':'{count} найденно результатов',
    'MursionPortal.Header.VisibilityHidden.ClientUsers':'Пользователи-клиенты',
    'MursonPortal.SchedulingType.Auto':'АВТОМАТИЧЕСКИ',
    'MursonPortal.SchedulingType.Manual':'ВРУЧНУЮ',
    'MursionPortal.ScheduleTypeSelector.All':'Расписание: Все',
    'MursionPortal.ScheduleTypeSelector.Auto':'Расписание: Автоматически',
    'MursionPortal.ScheduleTypeSelector.Manual':'Расписание: Вручную',
    'MursionPortal.ScheduleTypeSelector.NA':'Расписание: Нет данных',
    'Integrations.LTI.AppId':'ID приложения',
    'Integrations.LTI.ConfigurationId':'ID конфигурации',
    'Settings.LTI.ModalHeader.EditLtiConfiguration':'Изменить конфигурацию LTI',
    'Settings.LTI.Modal.DeletionConfigConfirmationText':'Вы уверены, что хотите удалить эту конфигурацию LTI?',
    'MursionPortal.Rescheduling.SubmitPage.Message':'Через минуту мы отправим вам электронное письмо с более подробной информацией. До скорого!',
    'MursionPortal.Rescheduling.RescheduleSimulation':'Перезапланировать симуляцию',
    'MursionPortal.Rescheduling.SlotSelectionMessage':'Мы сожалеем, что вы не смогли создать свою симуляцию. Выберите удобный для вас день и время!',
    'MursionPortal.IVSBrowserWarning.Safari17.Message1': 'The browser version you are currently using is not supported for your Mursion session',  // TODO: add translations
    'MursionPortal.IVSBrowserWarning.Safari17.Message2': 'When joining your Mursion simulation, use one of these browsers:',  // TODO: add translations
    'MursionPortal.IVSBrowserWarning.Safari17.Firefox': "Firefox",  // TODO: add translations
    'MursionPortal.IVSBrowserWarning.Safari17.Chrome': "Chrome", // TODO: add translations
    'MursionPortal.IVSBrowserWarning.Safari17.Chrome.Subtitle': "Recommended", // TODO: add translations
    'MursionPortal.IVSBrowserWarning.Safari17.Edge': "Edge", // TODO: add translations
    'MursionPortal.Result.Found':'{count} результатов найдено',
    'MursionPortal.Project.Rescheduling':'Изменение расписания',
    'Session.Edit.Modal.MissedSession':'ПРОПУЩЕННАЯ СЕССИЯ',
    'Session.Edit.Modal.NoLongerRequired':'больше не требуется',
    'Session.Edit.Modal.TechDifficulties':'технические проблемы',
    'Session.Edit.Modal.Other':'другое',
    'Session.Edit.Modal.CancelBecause':'Да, отменить сеанс, потому что',
    'Session.Edit.Modal.CancelSimulation':'Отменить симуляцию',
    'MursionPortal.UserConsent.Heading':'Разрешение на запись',
    'MursionPortal.UserConsent.Title':'Вам нужна запись этого сеанса для вашего обучения?',
    'MursionPortal.UserConsent.Description':'Если вы ответите «Я согласен», программное обеспечение Mursion обработает вашу симуляцию в реальном времени и сделает видеозапись вашего сеанса симуляции для просмотра на портале программного обеспечения Mursion.Обратите внимание, что по просьбе клиента, который лицензировал программное обеспечение Mursion от вашего имени, копия записанного вами сенса(ов) симуляции будет передана преподавателю, наставнику или рецензенту, которого выбрал клиент. Все рецензенты по договору обязаны следовать <code>Политике конфиденциальности Mursion</code>, если только вы не согласились на иные условия конфиденциальности. Кроме случаев, предусмотренных действующим законодательством, Mursion не будет распространять или раскрывать вашу видеозапись без вашего согласия. Mursion будет использовать только анонимизированные данные, полученные в результате использования вами ее программного обеспечения, для улучшения технологии симуляции.',
    'MursionPortal.UserConsent.IConsent':'Я согласен(а)',
    'MursionPortal.UserConsent.IDoNotConsent':'Я не согласен(а)',
    'MursionPortal.RecordingLinkPermisssion.Title':'Разрешение на запись',
    'MursionPortal.RecordingLinkPermisssion.DescriptionOne':'Вы хотите, чтобы вам прислали запись этого сеанса?',
    'MursionPortal.RecordingLinkPermisssion.DescriptionTwo':'Если да, пожалуйста, проверьте эл. почту и подтвердите свой адрес. Завтра вам пришлют ссылку(если таковая имеется).',
    'MursionPortal.PublicSession.PageTitle':'Введите свои данные, чтобы присоединиться к симуляции',
    'MursionPortal.PublicSession.Form.label.FirstName':'Имя',
    'MursionPortal.PublicSession.Form.label.LastName':'Фамилия',
    'MursionPortal.PublicSession.Form.label.Email':'Эл. почта (Необязательно)',
    'PublicSession.Form.Button.Join':'Присоединиться',
    'MursionPortal.PublicSession.Form.Placeholder.FirstName':'Ваше имя',
    'MursionPortal.PublicSession.Form.Placeholder.LastName':'Ваша Фамилия',
    'MursionPortal.PublicSession.Form.Placeholder.Email':'name@provider.com',
    'MursionPortal.PublicSession.Form.Placeholder.HaveAccount':'У вас уже есть аккаунт?',
    'MursionPortal.PublicSession.Form.Placeholder.LogInHere':'Авторизуйтесь здесь',
    'Session.Edit.Modal.ToolTip.Google':'Добавьте событие в свой календарь Google. Подтвердите авторизацию, если необходимо.',
    'Session.Edit.Modal.ToolTip.OutlookIcalOther':'Нажмите, чтобы загрузить событие на рабочий стол и добавить в приложение-календарь.',
    'Session.Edit.Modal.SimSpecialistAssigned':'Назначен специалист на сеанс симуляции' ,
    'Session.Edit.Modal.SessionSimSpecialistField.AddSIMSpecialist':'Добавить SIM специалиста',
    'Session.Edit.Modal.DemandBasedTimeSlots':'Запрашиваемый временной интервал',
    'Session.Edit.Modal.Reschedule':'Изменение расписания',
    'Session.Edit.Modal.AddLearner':'Добавить учащегося',
    'MursionPortal.Label.SearchOrSelectLearners':'Найдите или выберите учащегося (ихся)',
    'MursionPortal.Client.EmailSettings.Header.Rescheduling':'Изменение расписания',
    'MursionPortal.Client.EmailSettings.RescheduleMissedSession':'Перезапланировть пропущенную сессию',
    'Session.Edit.Modal.OtherPrioritiesAtWork':'другие приоритеты в работе',
    'MursionPortal.Modal.Header.Sorry':'Извините!',
    'MursionPortal.Modal.UnschedulableSessionMsg':'Эту сессию нельзя запланировать.',
    'MursionPortal.Rescheduling.Slot.NoLongerAvailable':'Выбранное время больше недоступно, выберите другой вариант.',
    'MursionPortal.EmailVerificationPage.Success':'Проверка электронной почты прошла успешно!',
    'MursionPortal.EmailVerificationPage.SuccessDesc':'Ваша электронная почта была успешно подтверждена',
    'MursionPortal.EmailVerificationPage.Error':'Ошибка проверки электронной почты!',
    'MursionPortal.EmailVerificationPage.ErrorDesc':'Что-то пошло не так. Попробуйте еще раз подтвердить свой адрес электронной почты.',
    'Settings.Config.Heading.SplashPageAttendance':'ПОСЕЩАЕМОСТЬ',
    // 'Clients.Modal.Label.ShowSplashPage':'Согласие на присутствие/запись незарегистрированного пользователя ',
    // 'Clients.Modal.Label.ShowSplashPage.ToolTip':'Собирайте данные о посещаемости и записывайте согласие незарегистрированных пользователей в симуляциях ML3z/Meet.',
    // 'Settings.Config.Invitation.ShowSplashPage':'Собирайте данные о посещаемости и записывайте согласие незарегистрированных пользователей в симуляциях ML3z/Meet.',
    'Settings.Config.External.Users':'(можно отключить в настройках клиента)',
    'Settings.Config.Invitation.ExternalUser':'Для посещения внешних пользователей',
    'MursionPortal.Scenario.Vignette.ButtonText.ShowMore.SessionDetails':'Детали сеанса',
    'MursionPortal.DemandBasedScheduling.ProjectDropdown.Placeholder':'Выберите проект',
    'MursionPortal.DemandBasedScheduling.ScenarioDropdown.Placeholder':'Выбрать сценарий',
    'Session.Edit.Modal.Label.SearchOrSelectASimSpecialist':'Найдите или выберите SIM-специалиста.' ,
    'MursionPortal.Button.Exclamation.GotIt':'Готово!',
    'MursionPortal.Modal.UnavailableSlotMsg':'Выбранное время сейчас недоступно. Пожалуйста, выберите новый временной интервал. ',
    'MursionPortal.SimAttendance.NoLongerAvailable':'Вы больше не можете редактировать посещаемость этой симуляции.',
    'MursionPortal.SimAttendance.Midnight':'Вы должны завершать задание к полуночи каждого дня. Форма обучения для этой симуляции по умолчанию будет соответствовать системным статусам. Пожалуйста, свяжитесь со службой поддержки, если что-то пошло не так».',
    'MursionPortal.SimAttendance.Instructions':'Инструкции',
    'MursionPortal.SimAttendance.Attendee':'Участник' ,
    'MursionPortal.SimAttendance.Tooltip.Missed':'Учащийся так и не подключился к симуляции.',
    'MursionPortal.SimAttendance.Add.Attendee':'Добавить участника',
    'Session.Edit.Modal.EditAttendance':'Редактировать участников',
    'Session.Edit.Modal.CompleteAttendance':'Полная посещаемость',
    'MursionPortal.SimAttendance.Late':'Опоздание',
    'MursionPortal.SimAttendance.Left':'Покинул/а сеанс',
    'MursionPortal.Instructions.First':'Выберите статус присутствия для каждого участника (наведите указатель мыши на статус, чтобы узнать больше). Если учащегося нет в списке, щелкните на ',
    'MursionPortal.Instructions.Second':'функцию в левом нижнем углу.',
    'MursionPortal.TableColumn.SimsAction':'Действие Специалиста по симуляции',
    'Session.Edit.Modal.Label.SelectTrainer':'Найдите или выберите преподавателя',
    'MursionPortal.Project.DemandSlots':'Запросить слот',
    'MursionPortal.CompanyCard.InviteOperations':'Пригласить операторов' ,
    'MursionPortal.CompanyCard.Modal.Header.NewOperations':'Новые операторы',
    'Settings.Config.Invitation.ShowContractFeature':'Разрешить добавление договоров',
    'Settings.Config.Contract.Description':' (для отслеживания выполнения договора)',
    'MursionPortal.Contract.Edit':'Редактировать',
    'MursionPortal.Contract.ContractName':'Название договора',
    'MursionPortal.Contract.ContractID':'ID контракта',
    'MursionPortal.Contract.ContractID.PlaceHolder':'Введите ID контракта на Hubspot',
    'MursionPortal.Contract.StartAndEndDates':'Даты начала и окончания договора',
    'MursionPortal.Contract.Notes.PlaceHolder':'Добавьте другую информацию, которая будет показываться только для CSM и BDD.',
    'MursionPortal.Contract.LineItem.Add':'Добавить Статью в контракт',
    'MursionPortal.Contract.LineItem.Add.Instruction':'Можно добавить несколько элементов линии симуляции. Все остальные могут иметь только одну статью.',
    'MursionPortal.Contract.LineItemType':'Тип статьи',
    'MursionPortal.Contract.LineItemType.PlaceHolder':'Выбрать тип статьи',
    'MursionPortal.Contract.Quantity':'Количество',
    'MursionPortal.Contract.Quantity.PlaceHolder':'Ввведите количество',
    'MursionPortal.Contract.DeliveryMode.PlaceHolder':'Выберите способ доставки',
    'MursionPortal.Contract.SaveContract':'Сохранить детали договора',
    'MursionPortal.Contract.Length.PlaceHolder':'Выберите длительность',
    'MursionPortal.Contract.SowName.PlaceHolder':'Введите Название Контракта',
    'MursionPortal.Client.Tab.Contracts':'Контракты',
    'MursionPortal.Client.Contracts':'Контракты клиента',
    'MursionPortal.Client.Create.Contracts':'Создать контракт',
    'MursionPortal.Client.Contract.ID':'ID контракта',
    'MursionPortal.NewUser.Optional':' (необязательно)',
    'MursionPortal.NewUser.SuchAGoogleAccountOrOtherSsoProvider.TooltipText':'Например, учетная запись Google или другой провайдер SSO',
    'MursionPortal.UserConsentDeclineConfirmation.Heading':'Пожалуйста, подтвердите ваш выбор.',
    // 'MursionPortal.UserConsentDeclineConfirmation.Title':'Вы уверены? Чтобы продолжить без записи, нажмите кнопку «Подтвердить». Чтобы изменить свое согласие на запись, нажмите кнопку «Отмена».',
    'Settings.Config.Invitation.ShowSowFeature':'Разрешить добавление SOW',
    'Settings.Config.SOW.Description':'(для отслеживания прогресса SOW)',
    'MursionPortal.ScenarioBank.StandardizedScenario':'Стандартизированный сценарий',
    'MursionPortal.ScenarioBank.ShowOnlyBundleScenario':'Показать только стандартизированные сценарии',
    'Settings.Config.Invitation.SimWorkforceManagement':'Управление персоналом SIM',
    'Settings.Config.SimWorkforceManagement.Description':'Включено - отчеты доступны на странице панели инструментов, а отключено - отчетов нет',
    'MursionPortal.ScenarioBank.BundleScenario':'Объединить сценарии',
    'MursionPortal.Scheduling.SimAvailable.TimeSlotsTooltipMsg':'Выберите данный временной интервал для автоматического подтверждения бронирования сценария!',
    'Dashboard.LeftPane.CategoryHeader.SupplyManagement':'Управление персоналом SIM',
    'Dashboard.LeftPane.SupplyManagement.Scheduling':'Составление расписания',
    'Dashboard.SupplyManagement.Scheduling.MainHeader':'Составление расписания',
    'Dashboard.SupplyManagement.Scheduling.TableHeader':'Данные расписания',
    'Dashboard.LeftPane.SupplyManagement.ClientRoster':'Список клиентов',
    'Dashboard.SupplyManagement.ClientRoster.MainHeader':'Список клиентов',
    'Dashboard.SupplyManagement.ClientRoster.TableHeader':'Данные списка клиентов',
    'MursionPortal.Table.ColumnHeader.Time':'Время',
    'MursionPortal.Table.ColumnHeader.AllScheduledSessions':'Все запланированное (сеансы)',
    'MursionPortal.Table.ColumnHeader.SimsAvailable':'Доступные SIM- специалисты (люди)',
    'MursionPortal.Table.ColumnHeader.AvailabilityHours':'Доступность (Час)',
    'MursionPortal.Table.ColumnHeader.DemandBasedRequests':'Все запросы (всего - все 3 запроса)',
    'MursionPortal.Table.ColumnHeader.SlotOneRequests':'Слот 1 Запросы',
    'MursionPortal.Table.ColumnHeader.SlotTwoRequests':'Слот 2 Запросы',
    'MursionPortal.Table.ColumnHeader.SlotThreeRequests':'Слот 3 Запросы',
    'MursionPortal.Table.ColumnHeader.PendingRequests':'Ожидающие запросы (сеансы)',
    'MursionPortal.Table.ColumnHeader.Auto':'Автоматически',
    'MursionPortal.Table.ColumnHeader.Manual':'Вручную',
    'MursionPortal.Table.ColumnHeader.CapacityIndicator':'Показатель готовности специалистов',
    'MursionPortal.EmptyMessage.NoSupplyManagementDataYet':'Пока нет данных по Управлению персоналом SIM',
    'MursionPortal.Table.ColumnHeader.SimCertified':'Сертифицированные SIMы',
    'MursionPortal.Table.ColumnHeader.ScheduledHours':'Запланировано (Часов)',
    'MursionPortal.Table.ColumnHeader.Sessions':'Сеансы',
    'MursionPortal.Table.ColumnHeader.AvgFullfilled':'Среднее значение выпонения в',
    'MursionPortal.Table.ColumnHeader.SlotOneFullFillment':'Слот 1 Выполнено',
    'MursionPortal.Table.ColumnHeader.Cancelled':'Отменено',
    'MursionPortal.Table.ColumnHeader.Missed':'Пропущено',
    'MursionPortal.Table.ColumnHeader.Reschedules':'Изменение расписания',
    'MursionPortal.Table.ColumnHeader.Error':'Ошибка',
    'MursionPortal.Table.ColumnHeader.Completed':'Завершено',
    'MursionPortal.Table.ColumnHeader.Other':'Другое',
    'MursionPortal.Table.ColumnHeader.SchedulingWindow':'Окно расписания',
    'MursionPortal.Table.ColumnHeader.RequestDemand':'# запрос (заявка)',
    'MursionPortal.Table.ColumnHeader.AutoFulfilled':'Выполнено Автоматически ',
    'MursionPortal.Table.ColumnHeader.Pending':'В ожидании',
    'MursionPortal.Table.ColumnHeader.SlotTwoFullFillment':'Слот 2 Выполненно',
    'MursionPortal.Table.ColumnHeader.SlotThreeFullFillment':'Слот 3 Выполнено',
    'MursionPortal.Table.ColumnHeader.UnutilizedAvailability':'Неиспользованное время (часы)',
    'MursionPortal.Table.ColumnHeader.TotalAvailabilityEntered':'Общая занятость',
    'MursionPortal.Table.ColumnHeader.AverageHoursWeek':'Среднее количество часов в неделю',
    'MursionPortal.Table.ColumnHeader.AvailabilityUtilization':'Доступность',
    'MursionPortal.SupplyManagement.Above':'Выше',
    'MursionPortal.SupplyManagement.Below':'Ниже',
    'MursionPortal.SupplyManagement.At':'В',
    'MyProfile.ProfServiceRole.Tooltiptext':'Пользователи с наивысшим уровнем доступа',
    'MyProfile.AccManager.Tooltiptext':'Создает сценарии, команды, добавляет пользователей и назначает команды на симуляцию".',
    'MyProfile.SimSpecialist.Tooltiptext':'Также известная как "симулятор", эта роль выполняет симуляцию для обучающихся.',
    'MyProfile.Buyer.Tooltiptext':'Пользователь, который управляет проектом Mursion для своей организации',
    'MyProfile.Facilitator.Tooltiptext':'Участник симуляции, который помогает в проведении симуляции',
    'MyProfile.Learner.Tooltiptext':'Участник симуляции',
    'MyProfile.Operations.Tooltiptext':'Сотрудник Mursion, имеющий возможность добавлять и редактировать контракты',
    'MyProfile.SimDesigner.Tooltiptext':'Пользователь, имеющий возможность создавать и редактировать банк сценариев',
    'MursionPortal.Label.AssignedUserRoles':'Роли назначенного пользователя',
    'MursionPortal.Label.AssignedUserRole':'Роль назначенного пользователя',
    'Clients.Modal.Projects.Button.CreateFromBank':'Создать из банка сценариев',
    'MursionPortal.Legend.VisibilityHidden.ScenarioPanel.Buttons':'Кнопки "Создать новый", "Повторить клиента", "Создать из банка сценариев".',
    'MursionPortal.Label.ArtBundle':'Арт-набор',
    'Settings.ScenarioBank.Modal.ToolTip.ThisIsTheArtContentMsg':'Это 3D-контент окружения и аватаров, которые будут использоваться в симуляции. Содержимое этой папки отображается в разделе под названием «Арт-набор» ниже.',
    'MursionPortal.SessionWizard.Label.ScenarioHasNoArtBundles':'Сценарий не имеет назначенных арт-наборов.',
    'MursionPortal.Label.ArtProject':'Арт- проект',
    'MursionPortal.Label.UpperCase.ArtProject':'АРТ- ПРОЕКТ',
    'Settings.Config.SimulationContent.ArtProjectFolder':'Папка "Арт-проект S3" ',
    'Settings.ScenarioBank.Modal.ThisScenarioHasNoArtProject':'Сценарий не имеет назначенного арт проекта',
    'MursionPortal.SessionWizard.Label.SelectArtContentForUse':'Выберите арт проект для использования',
    'Projects.Message.NoArtProjectFound':'Арт-проект не найден',
    'Settings.ScenarioBank.Form.ConfirmArchiveScenarioBank':'Существуют активные дополнительные сценарии, созданные на основе этого банковского сценария. Вы должны архивировать каждый из них по отдельности.',
    'Settings.StandardizedScenarioBank.EditConfirmation':'Вы уверены, что хотите обновить все существующие сценарии, созданные на основе этого?.',
    'Settings.StandardizedScenarioBank.AttachmentsEditConfirmation':'Обновление вложений изменит все существующие сценарии, созданные на основе этого.',
    'MursionPortal.Contract.Ending':'Окончание',
    'MursionPortal.ProjectContracts.DuplicateError':'Выбранный вами контракт перекрывается с другим контрактом, добавленным к этому проекту. Пожалуйста, выберите другой или свяжитесь с отделом операций для запроса заказа на изменение".',
    'MursionPortal.ProjectContracts.DateErrorHeading':'Контракта на эти даты проекта не существует.',
    'MursionPortal.ProjectContracts.DateError':'Нет контракта, подтверждающего эти даты начала и окончания проекта. Ни один проект не должен создаваться без действующего Контракта. Вы хотите продолжить?',
    'MursionPortal.Contract.ViewDetails':'Посмотреть детали',
    'MursionPortal.Contract.PleaseAddContractToProject':'Пожалуйста, добавьте контракт в проект',
    'MursionPortal.Contract.AddContractToProject':' Добавить контракт в проект',
    'MursionPortal.Contract.SelectContractToProject':'Выберите контракт, который вы хотите добавить в этот проект - пожалуйста, добавляйте по одному',
    'MursionPortal.Contract.AddSelectedContract':'Добавить выбранный контракт',
    'MursionPortal.Contract.AddContract':' Добавить контракт',
    'MursionPortal.Contract.LineItemList':'Список статей',
    'MursionPortal.DemandBasedScheduling.InstantBooking':'Мгновенное бронирование',
    'MursionPortal.DemandBasedScheduling.AllSlots':'Подтвердить слоты',
    'MursionPortal.DemandBasedScheduling.ScheduleYourScenario':'Мы запланируем ваш сценарий на один из выбранных вами временных интервалов.',
    'MursionPortal.DemandBasedScheduling.SelectOneOfTheseSlotsForAnInstantBooking':'Выберите один из этих слотов для мгновенного бронирования.',
    'MursionPortal.DemandBasedScheduling.TheseSlotDoNotwork':'Если эти слоты не подходят, пожалуйста, выберите ".", чтобы представить время, которое вам подходит.',
    'MursionPortal.AltText.CrossButton':'изображение кнопки крестика',
    'MursionPortal.Title.BankScenario':'Банк сценариев',
    'Dashboard.LeftPane.SupplyManagement.SimRoster':'Список специалистов по симуляции',
    'Dashboard.SupplyManagement.SimRoster.MainHeader':'Список специалистов по симуляции',
    'Dashboard.SupplyManagement.SimRoster.TableHeader':'Данные списка специалистов по симуляции' ,
    'MursionPortal.Table.ColumnHeader.SimFullName':'Полное имя SIM',
    'MursionPortal.Table.ColumnHeader.LanguagesDelivered':'Языки, на которых осуществляются симуляции ( сертификация на языке)',
    'MursionPortal.Table.ColumnHeader.Tier':'Уровень',
    'MursionPortal.Table.ColumnHeader.Timezone':'Часовой пояс',
    'MursionPortal.Table.ColumnHeader.Scheduled':'Запланированные',
    'MursionPortal.Table.ColumnHeader.ProjectsHoursWeekTotal':'Общее прогнозируемое количество часов в неделю',
    'MursionPortal.Table.ColumnHeader.ActiveClientCertifications':'Активные сертификаты клиента (оплаченные)',
    'MursionPortal.Table.ColumnHeader.InactiveCertifications':'Неактивная сертификация' ,
    'MursionPortal.Table.ColumnHeader.Declined':'Отклонено',
    'MursionPortal.Table.ColumnHeader.AvgFullfillTimeForManual':'Среднее время выполнения вручную',
    'MursionPortal.Table.ColumnHeader.Queue':'Очередь',
    'MursionPortal.Table.ColumnHeader.Availability':'Готовность',
    'MursionPortal.Filters.Tier':'Уровень',
    'MursionPortal.Filters.PlaceHolder.SearchTier':'Поиск уровня',
    'MursionPortal.TierOptions.Tier1':'Уровень 1',
    'MursionPortal.TierOptions.Tier2':'Уровень 2',
    'MursionPortal.TierOptions.Tier3':'Уровень 3',
    'MursionPortal.TierOptions.Tier4':'Уровень 4',
    'MursionPortal.TierOptions.LeadSimulation':'Ведущий специалист по моделированию',
    'MursionPortal.Contract.BackToCLient':'Вернуться к клиенту',
    'MursionPortal.DeliveryMode.Workshop':'Семинар',
    'MursionPortal.DeliveryMode.PrivatePractice':'Индивидуальная практика',
    'MursionPortal.DemandBasedScheduling.SimOnlySlotSelectionMessage':'Пожалуйста, выберите удобный для вас день и время.',
    'Dashboard.LeftPane.CategoryHeader.Performance':'Применение',
    'Dashboard.LeftPane.Performance.ContractOverview':'Обзор',
    'Dashboard.LeftPane.Performance.ContractSummaries':'Краткие сведения о контракте',
    'Dashboard.LeftPane.Performance.ContractProgress':'Ход выполнения договора',
    'Dashboard.LeftPane.CategoryHeader.Learners':'УЧАЩИЕСЯ',
    'Dashboard.LeftPane.Learners.LearnerActivity':'Деятельность учащегося',
    'Dashboard.LeftPane.Learners.LearnerSimulations':'Симуляции учащегося',
    'Dashboard.Total':'Всего записей',
    'Filters.SimulationStatus':'Статус симуляции',
    'Filters.SimulationStatusPlaceholder':'Поиск статуса симуляции...',
    'Filters.LearnerStatus':'Статус учащегося',
    'Filters.LearnerStatusPlaceHolderHint':'Поиск статуса учащегося...".',
    'Filters.SimulationRecorded':'Симуляция записана',
    'Filters.Standardized':'Стандартизировано',
    'Filters.Boolean.Yes':'Да',
    'Filters.Boolean.No':'Нет',
    'Dashboard.UserTable.Column.Roles':'Роли',
    'Dashboard.ContractSummaries.MainHeader':'Краткие сведения о контракте',
    'Dashboard.ContractOverview.MainHeader':'Обзор',
    'Dashboard.ContractProgress.MainHeader':'Ход выполнения договора',
    'Dashboard.ContractProgress.Navigation.ContractItems':'Предмет контракта',
    'Dashboard.ContractProgress.Navigation.Projects':'Проекты',
    'Dashboard.ContractProgress.Navigation.Scenarios':'Сценарии',
    'Dashboard.ContractProgress.Column.Item':'Позиция',
    'Dashboard.ContractProgress.Column.Purchased':'Приобретено',
    'Dashboard.ContractProgress.Column.Remaining':'Остались',
    'Dashboard.ContractProgress.Column.Completed':'Завершено',
    'Dashboard.ContractProgress.Column.Scheduled':'Запланированные',
    'Dashboard.ContractProgress.Column.LateCancellations':'Позднее подтверждение',
    'Dashboard.ContractProgress.Column.ProjectName':'Название проекта',
    'Dashboard.ContractProgress.Column.StartDate':'Дата начала',
    'Dashboard.ContractProgress.Column.EndDate':'Дата окончания',
    'Dashboard.ContractProgress.Column.Error':'Ошибка',
    'Dashboard.ContractProgress.Column.ScenarioName':'Название сценария',
    'Dashboard.ContractProgress.Column.Standardized':'Стандартизировано',
    'Dashboard.ContractProgress.Column.LearnersAssigned':'Назначенные учащиеся',
    'Dashboard.ContractProgress.Column.LearnersCompleted':'Завершившие',
    'Dashboard.ContractProgress.Column.LearnersCompletedPercent':'% завершивших обучение',
    'Dashboard.ContractProgress.Column.Client':'Клиент',
    'Dashboard.ContractProgress.Column.ContractName':'Название договора',
    'Dashboard.ContractProgress.Column.ContractId':'ID контракта',
    'Dashboard.ContractProgress.Column.ContractNameHoverTitle':'Скопировать ID',
    // 'Dashboard.ContractProgress.Column.PercentComplete':'% завершено',
    'Dashboard.ContractProgress.Column.ErrorLearner':'Ошибки (Учащийся)',
    'Dashboard.ContractProgress.Column.LearnersInvited':'Приглашенные Учащиеся',
    'Dashboard.ContractProgress.Column.LearnersScheduledPercent':'% учащихся, которые запланировали симуляцию',
    'Dashboard.ContractProgress.ColumnTooltip.Client':'Имя клиента',
    'Dashboard.ContractProgress.ColumnTooltip.ContractName':' Название договора',
    'Dashboard.ContractProgress.ColumnTooltip.PercentComplete':'Процент выполненных дней по контракту',
    'Dashboard.ContractProgress.ColumnTooltip.CompletedUtilization':'Выполненные симуляции в процентах от приобретенных симуляций',
    'Dashboard.ContractProgress.ColumnTooltip.ScheduledUtilization':'Выполненные и запланированные симуляции в процентах от числа приобретенных симуляций',
    'Dashboard.ContractProgress.ColumnTooltip.MissedLearner':'Симуляции, которые были пропущены учащимся.',
    'Dashboard.ContractProgress.ColumnTooltip.ErrorLearner':'Симуляции, закончившиеся ошибкой на стороне обучающегося при подключении',
    'Dashboard.ContractProgress.ColumnTooltip.LearnersInvited':'Учащиеся, которым было предложено запланировать симуляцию',
    'Dashboard.ContractProgress.ColumnTooltip.LearnersScheduledPercent':'Учащиеся, которые запланировали хотя бы одну симуляцию, как процент от тех, кому было предложено запланировать симуляцию',
    'Dashboard.ContractProgress.ColumnTooltip.Item':'Пункты, предусмотренные Контрактом',
    'Dashboard.ContractProgress.ColumnTooltip.Purchased':'Общее количество симуляций, приобретенных в рамках контракта',
    'Dashboard.ContractProgress.ColumnTooltip.Completed':'Успешно завершенные симуляции',
    'Dashboard.ContractProgress.ColumnTooltip.Scheduled':'Предстоящие симуляции, которые запланированы, но еще не завершены (включая запущенные)',
    'Dashboard.ContractProgress.ColumnTooltip.Missed':'Симуляции, которые были пропущены обучающимся',
    'Dashboard.ContractProgress.ColumnTooltip.LateCancellations':'Симуляции, которые были отменены поздно (после крайнего срока отмены) обучающимся, фасилитатором или владельцем аккаунта, или симуляции, когда обучающийся ушел или отказался',
    'Dashboard.ContractProgress.ColumnTooltip.Error':'Симуляции, закончившиеся ошибкой на стороне обучающегося при подключении',
    'Dashboard.ContractProgress.ColumnTooltip.ProjectName':'Название проекта',
    'Dashboard.ContractProgress.ColumnTooltip.StartDate':'Дата начала действия, указанная в контракте',
    'Dashboard.ContractProgress.ColumnTooltip.EndDate':'Дата окончания, указанная в контракте',
    'Dashboard.ContractProgress.ColumnTooltip.ScenarioName':'Имя сценария',
    'Dashboard.ContractProgress.ColumnTooltip.Standardized':'Это стандартизированный сценарий',
    'Dashboard.ContractProgress.ColumnTooltip.LearnersAssigned':'Количество учащихся, которым был задан сценарий',
    'Dashboard.ContractProgress.ColumnTooltip.LearnersCompleted':'Учащиеся, прошедшие хотя бы одну симуляцию, как процент от тех, кому было предложено запланировать симуляцию',
    'Dashboard.ContractProgress.ColumnTooltip.LearnersCompletedPercent':'Процент отличившихся учащихся, выполнивших сценарий',
    'Dashboard.Learners.Column.Provider':'Провайдер',
    'Dashboard.Learners.Column.Client':'Клиент',
    'Dashboard.Learners.Column.LearnerName':'Имя учащегося',
    'Dashboard.Learners.Column.DateInvited':'Дата приглашения',
    'Dashboard.Learners.Column.LastLogin':'Последний вход',
    'Dashboard.Learners.Column.NoAssignedTeams':'Нет назначенных команд',
    'Dashboard.Learners.Column.NoAssignedScenarios':'Нет назначенных сценариев',
    'Dashboard.Learners.Column.NoCompletedScenarios':'Нет завершенных сценариев',
    'Dashboard.Learners.Column.Completed':'Завершено',
    'Dashboard.Learners.Column.Scheduled':'Запланированные',
    'Dashboard.Learners.Column.LateCancelled':'Поздняя отмена',
    'Dashboard.Learners.Column.EndedInError':'Закончилось ошибкой',
    'Dashboard.Learners.Column.ProjectName':'Название проекта',
    'Dashboard.Learners.Column.ScenarioName':'Название сценария',
    'Dashboard.Learners.Column.ScheduledStartDate':'Запланированная дата начала',
    'Dashboard.Learners.Column.ScheduledStartTime':'Запланированное время начала' ,
    'Dashboard.Learners.Column.ScheduledEndTime':'Запланированное время окончания',
    'Dashboard.Learners.Column.SimulationStatus':'Статус симуляции',
    'Dashboard.Learners.Column.LearnerStatus':'Статус учащегося',
    'Dashboard.Learners.Column.LearnerTimeSpent':'Затраченное время обучающегося',
    'Dashboard.Learners.Column.SimSpecialistName':'Имя специалиста по симуляции',
    'Dashboard.Learners.Column.SimSpecialistStatus':'Статус специалиста по симуляции',
    'Dashboard.Learners.Column.Report':'Отчет',
    'Dashboard.Learners.ColumnTooltip.Provider':'Имя провайдера/лицензиата',
    'Dashboard.Learners.ColumnTooltip.Client':'Имя клиента',
    'Dashboard.Learners.ColumnTooltip.LearnerName':'Фамилия и имя пользователя',
    'Dashboard.Learners.ColumnTooltip.DateInvited':'Дата, когда учащийся был впервые приглашен запланировать сценарий',
    'Dashboard.Learners.ColumnTooltip.LastLogin':'Дата последнего входа пользователя на портал',
    'Dashboard.Learners.ColumnTooltip.NoAssignedTeams':'Количество команд, которые были назначены учащемуся',
    'Dashboard.Learners.ColumnTooltip.NoAssignedScenarios':'Количество отдельных сценариев, которые были назначены учащемуся',
    'Dashboard.Learners.ColumnTooltip.NoCompletedScenarios':'Количество отдельных сценариев, которые были выполнены учащимся',
    'Dashboard.Learners.ColumnTooltip.Completed':'Успешно завершенные симуляции',
    'Dashboard.Learners.ColumnTooltip.Scheduled':'Предстоящие симуляции, которые запланированы, но еще не завершены (включая запущенные)',
    'Dashboard.Learners.ColumnTooltip.Missed':'Симуляции, которые были пропущены обучающимся',
    'Dashboard.Learners.ColumnTooltip.LateCancelled':'Симуляции, которые были отменены поздно (после крайнего срока отмены) обучающимся, фасилитатором или владельцем аккаунта, или симуляции.',
    'Dashboard.Learners.ColumnTooltip.EndedInError':'Симуляции, закончившиеся ошибкой на стороне обучающегося при подключении',
    'Dashboard.Learners.ColumnTooltip.ProjectName':'Название проекта',
    'Dashboard.Learners.ColumnTooltip.ScenarioName':'Имя сценария',
    'Dashboard.Learners.ColumnTooltip.ScheduledStartDate':'Дата, на которую было запланировано начало этой симуляции',
    'Dashboard.Learners.ColumnTooltip.ScheduledStartTime':'Время, на которое было запланировано начало этой симуляции',
    'Dashboard.Learners.ColumnTooltip.ScheduledEndTime':'Время окончания этой симуляции',
    'Dashboard.Learners.ColumnTooltip.SimulationStatus':'Окончательный статус для симуляции',
    'Dashboard.Learners.ColumnTooltip.LearnerStatus':'Окончательный статус учащегося для симуляции',
    'Dashboard.Learners.ColumnTooltip.LearnerTimeSpent':'Минуты, проведенные учащимся в симуляции. Для учащихся Zoom и Meet это время оценивается на основе данных специалиста SIM, по завершению.',
    'Dashboard.Learners.ColumnTooltip.SimSpecialistName':'Специалист по симуляции, назначенный для проведения симуляции',
    'Dashboard.Learners.ColumnTooltip.SimSpecialistStatus':'Окончательный статус SIM-специалиста для симуляции',
    'Dashboard.Learners.ColumnTooltip.SimulationId':'Уникальный идентификатор симуляции',
    'Dashboard.Learners.ColumnTooltip.Report':'Ссылка на отчет по симуляции для просмотра (если доступно) записи и аналитики по симуляции',
    'Dashboard.SessionLearners.TableHeader':'Данные об учающихся на сеансе',
    'MursionPortal.FilterHeading.Filter':'ФИЛЬТЕР',
    'MursionPortal.FilterHeading.Graph':'ГРАФИКИ',
    'MursionPortal.Table.NoRecordsMessage':'Результаты не найдены!',
    'MursionPortal.Filters.DeliveryMode':'Режим доставки',
    'MursionPortal.Filters.PlaceHolder.DeliveryMode':'Поиск способа доставки...',
    'MursionPortal.Dashboard.Chart.Tooltip.Utilized':'Общее количество, которое было использовано по статье контракта. Включает симуляции, завершенные, пропущенные или отмененные/перенесенные (после крайнего срока отмены).' ,
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.Completed':'Успешно завершенные симуляции.',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.LearnerMissed':'Симуляции, которые были пропущены учащимся.',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.Error':'Симуляции, закончившиеся ошибкой на стороне подключения обучающегося.',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.LateCancellation':'Симуляции, которые были отменены поздно (после крайнего срока отмены) обучающимся, фасилитатором или владельцем аккаунта, или симуляции, когда обучающийся ушел или отказался',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.EarlyCancellation':'Симуляции, которые были отменены поздно (после крайнего срока отмены) обучающимся, фасилитатором или владельцем аккаунта.',
    'MursionPortal.Dashboard.Chart.label.SimulationBreakdown.Completed':'Завершено',
    'MursionPortal.Dashboard.Chart.label.SimulationBreakdown.Error':'Ошибка',
    'MursionPortal.Dashboard.Chart.label.SimulationBreakdown.LateCancellation':'Поздняя отмена',
    'MursionPortal.Dashboard.Chart.label.SimulationBreakdown.EarlyCancellation':'Ранняя отмена',
    'MursionPortal.Dashboard.Chart.PrivatePractice1':'30м индивидуальной практики',
    'MursionPortal.Dashboard.Chart.PrivatePractice2':'60м индивидуальной практики',
    'MursionPortal.Dashboard.Chart.Workshop1':'60м Практического семинара',
    'MursionPortal.Dashboard.Chart.Workshop2':'90м Практического семинара',
    'MursionPortal.Dashboard.Chart.StringType.Utilized':'Используется',
    'MursionPortal.Dashboard.Chart.Header.SimulationBreakdown':'Нарушение симуляции',
    'MursionPortal.SessionRecording':'Запись сеанса',
    'MursionPortal.VoiceAnalytics':'Голосовая аналитика',
    'MursionPortal.VideoTab':'Видео',
    'MursionPortal.SessionInformation':'Информация о сессии',
    'MursionPortal.SmartMatrix':'Smart метрики',
    'MursionPortal.DeliveryDetails':'Детали доставки',
    'MursionPortal.ConversationalFlow':'Разговорный поток',
    'MursionPortal.ConversationalFlowTooltip':'Этот график отражает вашу эффективность во время беседы. Он состоит из пошаговых вводов, которые показывают, насколько хорошо вы способны участвовать в конструктивном разговоре".',
    'MursionPortal.BalancedTalkTime':'Общение',
    'MursionPortal.BalancedFeedback':'Баланс обратной связи',
    'MursionPortal.ConversationalDisruptions':'Прерывание',
    'MursionPortal.DisruptionsFeedback':'Обратная связь о сбоях',
    'MursionPortal.Heading.SessionSmartMetrics':'Аналитика голоса во время сеанса:',
    'Clients.Modal.Label.SmartMetrics':'Голосовая аналитика',
    'Clients.Modal.Projects.Header.SessionSmartMetrics':'Аналитика голоса во время сеанса:',
    'Clients.Modal.Projects.Checkbox.EnableSmartMetrics':' Включить голосовую аналитику',
    'Filters.Contract':'Контракт',
    'Filters.Session': 'Сессия',
    'Clients.Modal.Label.ShowSowReporting':'Показать отчетность SOW:',
    'Clients.Modal.Label.ShowSurveyEnabled':'Отправить пост-симуляционный отчет об учащемся:',
    'Dashboard.SimulationTable.Column.Provider':'Провайдер',
    'Dashboard.SimulationTable.Column.Client':'Клиент',
    'Dashboard.SimulationTable.Column.Project':'Проект',
    'Dashboard.SimulationTable.Column.ScenarioName':'Название сценария',
    'Dashboard.SimulationTable.Column.ScheduledStartDate':'Запланированная дата начала',
    'Dashboard.SimulationTable.Column.ScheduledStartTime':'Запланированное время начала' ,
    'Dashboard.SimulationTable.Column.ScheduledEndTime':'Запланированное время окончания',
    'Dashboard.SimulationTable.Column.LearnersNames':'Имя учащегося/имена учащихся',
    'Dashboard.SimulationTable.Column.LearnersEmails':'Эл. почта(ы) учащегося(ихся)',
    'Dashboard.SimulationTable.Column.LearnersStatus':'Статус учащегося(ихся)',
    'Dashboard.SimulationTable.Column.LearnersTimeSpent':'Затраченное учащимся(учащимися) время',
    'Dashboard.SimulationTable.Column.SimSpecialistName':'Имя специалиста по симуляции',
    'Dashboard.SimulationTable.Column.SimSpecialistStatus':'Статус специалиста по симуляции',
    'Dashboard.SimulationTable.Column.SimSpecialistTimeSpent':'Затраченное время специалиста SIM',
    'Dashboard.SimulationTable.Column.ScheduledBy':'Запланировано',
    'Dashboard.SimulationTable.Column.ScheduledAt':'Запланировано на',
    'Dashboard.SimulationTable.Column.Recorded':'Записано',
    'Dashboard.SimulationTable.Column.ClientId':'Id клиента',
    'Dashboard.SimulationTable.Column.LearnerName':'Имя учащегося',
    'Dashboard.SimulationTable.Column.LearnerName.ButtonHoverTitle':'Скопировать эл.почту учащегося',
    'Dashboard.SimulationTable.Column.LearnerEmail':'Эл.почта учащегося',
    'Dashboard.SimulationTable.Column.LearnerStatus':'Статус учащегося',
    'Dashboard.SimulationTable.Column.LearnerTimeSpent':'Затраченное учащимся время',
    'Dashboard.SimulationTable.ColumnTooltip.Provider':'Имя провайдера/лицензиата',
    'Dashboard.SimulationTable.ColumnTooltip.Client':'Имя клиента',
    'Dashboard.SimulationTable.ColumnTooltip.Project':'Название проекта',
    'Dashboard.SimulationTable.ColumnTooltip.ScenarioName':'Имя сценария',
    'Dashboard.SimulationTable.ColumnTooltip.ScheduledStartDate':'Дата, на которую было запланировано начало этой симуляции',
    'Dashboard.SimulationTable.ColumnTooltip.ScheduledStartTime':'Время, на которое было запланировано начало этой симуляции',
    'Dashboard.SimulationTable.ColumnTooltip.ScheduledEndTime':'Время окончания этой симуляции',
    'Dashboard.SimulationTable.ColumnTooltip.SimulationStatus':'Окончательный статус для симуляции',
    'Dashboard.SimulationTable.ColumnTooltip.LearnersNames':'Имя (имена) зарегистрированных и незарегистрированных учащихся для симуляции',
    'Dashboard.SimulationTable.ColumnTooltip.LearnersEmails':'Эл.почта(ы) зарегистрированных и незарегистрированных учащихся для симуляции',
    'Dashboard.SimulationTable.ColumnTooltip.LearnersStatus':'Окончательный статус учащегося для симуляции',
    'Dashboard.SimulationTable.ColumnTooltip.LearnersTimeSpent':'Время, затраченное на подключение к симуляции учащимися',
    'Dashboard.SimulationTable.ColumnTooltip.SimSpecialistName':'Имя специалиста по симуляции',
    'Dashboard.SimulationTable.ColumnTooltip.SimSpecialistStatus':'Окончательный статус SIM-специалиста для симуляции',
    'Dashboard.SimulationTable.ColumnTooltip.SimSpecialistTimeSpent':'Продолжительность, в минутах, данной симуляции',
    'Dashboard.SimulationTable.ColumnTooltip.ScheduledBy':'Имя пользователя, запланировавшего симуляцию',
    'Dashboard.SimulationTable.ColumnTooltip.ScheduledAt':'Дата и время, на которое была запланирована эта симуляция',
    'Dashboard.SimulationTable.ColumnTooltip.Recorded':'Обозначает, была ли записана симуляция или запись была не разрешена',
    'Dashboard.SimulationTable.ColumnTooltip.LearnerName':'Имя (имена) зарегистрированных и незарегистрированных учащихся для симуляции',
    'Dashboard.SimulationTable.ColumnTooltip.LearnerStatus':'Окончательный статус учащегося для симуляции',
    'Dashboard.SimulationTable.ColumnTooltip.LearnerTimeSpent':'Минуты, проведенные учащимся в симуляции. Для учащихся Zoom и Meet это время оценивается на основе данных специалиста SIM, по завершению.',
    'MursionPortal.Label.VideoTimeLineSlice':'Нарезки видео',
    'MursionPortal.Label.SpeakingMoreThanSixty':'Говорение - более 60%',
    'MursionPortal.Label.SpeakingBetweenSixty':'Говорение - от 40% до 60%',
    'MursionPortal.Label.InterruptionMoreThanSixty':'Прерывание - более 60%',
    'MursionPortal.Label.InterruptionBetweenSixty':'Прерывание - от 40% до 60%',
    'MursionPortal.Label.SpeakingLessThanFourty':'Говорение - менее 40%',
    'MursionPortal.Label.InterruptionLessThanFourty':'Прерывание - менее 40%',
    'MursionPortal.Label.Interruption.ByAvatar':'Аватар',
    'MursionPortal.Label.Interruption.ByLearner':'Учащийся',
    'MursionPortal.Projects.NoContractsFound':'Контракты не найдены".',
    'MursionPortal.Projects.ProjectContracts':'Проектные контракты',
    'MursionPortal.Tooltip.AvatarInterrupt':'Аватар успешно пытается прервать говорящего.',
    'MursionPortal.Tooltip.LearnerInterrupt':'Успешная попытка учащегося прервать говорящего".',
    'MursionPortal.Tooltip.Pauses':'Паузы. Более 0,2 с и менее 0,75 с',
    'MursionPortal.Tooltip.Silence':'Пайуза в речи более 10 сек',
    'MursionPortal.Tooltip.AvatarSpeaking':'Аватар успешно пытается заговорить.',
    'MursionPortal.Tooltip.LearnerSpeaking':'Успешная попытка учащегося заговорить.',
    // 'MursionPortal.label.LearnerInterrupts':'Учащийся прерывает',
    // 'MursionPortal.label.AvatarInterrupts':'Аватар прерывает',
    'MursionPortal.label.Pause':'Пауза',
    'MursionPortal.Label.Silence':'Тишина',
    'MursionPortal.label.AvatarSpeaking':'Аватар говорит',
    'MursionPortal.label.LearnerSpeaking':'Учащийся говорит',
    'MursionPortal.Contract.DuplicateLineItem':'Для данной продолжительности симуляции и способа доставки уже существует статья ',
    'MursionPortal.Contract.DeleteLineItemIcon':'Значок удаления статьи',
    'SessionReports.Label.ShareVideoLink':'Поделиться ссылкой на видео',
    'MursionPortal.Title.Silence':'Пайуза в речи более 10 сек',
    'Dashboard.LeftPane.User.UserActivity':'Активность пользователя',
    'Dashboard.LeftPane.Performance.SelfService':'Применение системы самообслуживания',
    'MursionPortal.Placeholder.ClientValue':'Выбрать клиента',
    'Filters.Date.FromDate':'C',
    'Filters.Date.ToDate':'До',
    'Dashboard.UserActivity.MainHeader':'Активность пользователя',
    'Dashboard.SelfService.MainHeader':'Применение системы самообслуживания',
    'Dashboard.SelfService.Column.SelfServiceProvider':'Провайдер',
    'Dashboard.SelfService.Column.SelfServiceTotalHoursUtilized':'Всего пройденных часов',
    'Dashboard.SelfService.Column.SelfServiceHoursCompleted':'Завершено часов',
    'Dashboard.SelfService.Column.SelfServiceHoursMissed':'Пропущено часов',
    'Dashboard.SelfService.Column.SelfServiceHoursErrored':'Количество часов с учётом ошибок',
    'Dashboard.SelfService.Column.SelfServiceSimulationsComplete':'Законченные симуляции',
    'Dashboard.SelfService.Column.SelfServiceSimulationsMissed':'Пропущенные симуляции',
    'Dashboard.SelfService.Column.SelfServiceSimulationsErrored':'Ошибка симуляции',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceProvider':'Провайдер',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceTotalHoursUtilized':'Успешно завершенные симуляции',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceHoursCompleted':'Завершено часов',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceHoursMissed':'Симуляции, которые были пропущены обучающимся',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceHoursErrored':'Симуляции, закончившиеся ошибкой на стороне обучающегося при подключении',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceSimulationsComplete':'Законченные симуляции',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceSimulationsMissed':'Пропущенные симуляции',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceSimulationsErrored':'Ошибка симуляции',
    'Dashboard.Learners.Column.AssignedProjects':'Назначенные проекты',
    'Dashboard.Learners.Column.AssignedScenarios':'Назначенные сценарии',
    'Dashboard.Learners.Column.ScheduledScenarios':'Запланированные сценарии',
    'Dashboard.Learners.Column.CompletedScenarios':'Завершенные сценарии',
    'Settings.Config.SessionReport.ShowSmartMetrics':'Голосовая аналитика',
    'Settings.SSO.CopySsoDeepLink':'Копирование ссылки SSO Deep',
    'Settings.SSO.CopySsoDeepLinkNotification':'Ссылка на данные SSO была скопирована в ваш буфер обмена',
    'MursionPortal.Dashboard.Chart.Assigned':'Назначено',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.Simulation':'Симуляции с разделением по каждому статусу.',
    'MursionPortal.Dashboard.Chart.Header.NoDataAvailable':'Данные отсутствуют',
    'Projects.ProjectInfo.Label.SurveyControl':'Опросы по проекту',
    'Projects.ProjectInfo.Checkbox.EnablePreSimulationSurvey':'Включить опрос учащихся перед симуляцией',
    'Projects.ProjectInfo.Checkbox.EnablePostSimulationSurvey':'Включить опрос учащихся после симуляции',
    'Projects.ProjectInfo.Checkbox.SurveyEnabled':'Опрос включен',
    'Projects.ProjectInfo.Checkbox.SurveyEnabled.PreSimulationSurvey':'Опрос учащихся перед симуляцией',
    'Projects.ProjectInfo.Checkbox.SurveyEnabled.PostSimulationSurvey':'Опрос учащихся после симуляции',
    'Settings.SSO.ModalBody.SelectDestination':'Выберите ссылку на место назначения',
    'Settings.SSO.ModalBody.SelectDestination.SessionsTab':'Вкладка Сеансы',
    'Settings.SSO.ModalBody.SelectDestination.CalendarTab':'Вкладка Календарь',
    'Settings.SSO.ModalBody.SelectDestination.ReportsTab':'Вкладка Отчеты',
    'Settings.SSO.ModalBody.SelectDestination.DashboardTab':'Вкладка Панель управления',
    'Settings.SSO.ModalBody.SelectDestination.ScenarioCard':'Карта сценария',
    'Settings.SSO.ModalBody.SelectDestination.ScenarioTabSelectTitle':'Сначала выберите проект, затем сценарий',
    'Settings.SSO.Button.CreateDeepLink':'Создать ссылку SSO deep',
    'Settings.SSO.ModalHeader.DeepLink':'Копирование ссылки SSO Deep',
    'MursionPortal.Dashboard.Chart.Header.LearnerSimulations':'Количество назначенных сценариев',
    'Clients.Modal.Label.MursionSocialAttendance':'Социальная посещаемость Mursion:',
    'MursionPortal.CodeOfConduct.Text':'Решая запланировать сеанс Mursion, вы соглашаетесь поддерживать и соблюдать <code>Кодекс поведения Mursion</code>".',
    'MursionPortal.Dashboard.DonutChart.Tooltip.ContractProgress.CompletedAllAvailable':'Учащиеся, выполнившие все доступные им сценарии',
    'MursionPortal.Dashboard.DonutChart.Tooltip.ContractProgress.NoneAvailableToSchedule':'Учащиеся, у которых еще не было сценария, доступного для планирования',
    'MursionPortal.Dashboard.DonutChart.Tooltip.ContractProgress.NotYetScheduled':'Учащиеся, у которых есть сценарии, но они еще не запланировали симуляцию',
    'MursionPortal.Dashboard.DonutChart.Tooltip.ContractProgress.Scheduled':'"Учащиеся, у которых запланирована одна или несколько симуляций, которые они еще не прошли',
    'MursionPortal.DateRangeSelector.Label.DateRange':'Диапазон даты ',
    'MursionPortal.DemandBasedScheduling.TimeBlockUnavailableReservedText':'Уже выбрано - пожалуйста, выберите другой тайм-блок.',
    'MursionPortal.EndDate':'Дата окончания',
    'MursionPortal.Label.FilterSIMsBy':'Фильтровать SIM по ',
    'MursionPortal.LearnerSimSelector.Learner':'Учащиеся',
    'MursionPortal.LearnerSimSelector.Learner.Selected':'Выбранный ученик',
    'Dashboard.Filters.SimulationID':'ID симуляции',
    'MursionPortal.LearnerSimSelector.Sim.Selected':'Специалист по симуляции',
    'MursionPortal.LearnerSimSelector.Sim.Unavailable':'Сертифицировано и не доступно',
    'MursionPortal.LearnerSimSelector.Tab.Search.Learner':'Найдите или выберите учащегося',
    'MursionPortal.LearnerSimSelector.Tab.Search.Sim':'Найдите или выберите SIM-специалиста',
    'MursionPortal.Placeholder.ProvidersValue':'Выбрать провайдера',
    'MursionPortal.ProjectContract.Confirmation':'Вы уверены, что хотите удалить контракт из проекта?',
    'MursionPortal.Scheduling.Label.DateTimeTab':'Дата + Время',
    'MursionPortal.Scheduling.NoAvailabilityForCertifiedAndAvailableSimSpecialist':'Сертифицированный SIM-специалист не доступен.',
    'MursionPortal.Scheduling.NoAvailabilityForCertifiedSimSpecialist':'Нет сертифицированных специалистов по симуляции',
    'MursionPortal.SessionFlow.Label.AreYouSure':'Вы уверены?',
    'MursionPortal.SessionFlow.Label.BeforeDeletionPleaseEnsureTheFollowing':'Перед удалением, пожалуйста, убедитесь в следующем:',
    'MursionPortal.SessionFlow.Label.BeforeDeletionPleaseEnsureTheFollowing.Text1':'Вы убедились, что запрашиваемый пользователь имеет право на удаление ( следует помнить о групповых симуляциях и согласии учащегося)',
    'MursionPortal.SessionFlow.Label.BeforeDeletionPleaseEnsureTheFollowing.Text2':'Вы создали обращение в службу поддержки, в котором указано, кто запрашивает удаление, и что вы согласовывали это, при необходимости, с владельцем учетной записи.',
    'MursionPortal.SessionFlow.Label.DeleteRecording':'Удалить запись',
    'MursionPortal.SessionFlow.Label.ProviderName':'Имя провайдера',
    'MursionPortal.SessionFlow.Label.ReminderThisCanNotBeUndone':'ОБРАТИТЕ ВНИМАНИЕ: Это нельзя отменить!',
    'MursionPortal.SessionFlow.Label.ScenarioDetails':'Детали сценария',
    'MursionPortal.SessionFlow.Label.SessionStartEndDateTime':'Начало и окончание сеанса Дата Время',
    'MursionPortal.SessionFlow.Label.SupportCase':'Дело поддержки #*',
    'MursionPortal.SessionFlow.Label.YouAreAboutToDeletevideoRecording':'Вы собираетесь удалить видеозапись',
    'MursionPortal.SessionNotStarted.AudioVideoSettings':"Проверьте настройки аудио и видео",
    'MursionPortal.SessionNotStarted.DescriptionOne':"Пока вы ждёте, вы можете:",
    'MursionPortal.SessionNotStarted.DescriptionTwo':"Это всплывающее окно исчезнет за <code>{value}</code> минут до начала вашей симуляции. Нажмите Обновить, если страница не обновится автоматически в это время.",
    'MursionPortal.SessionNotStarted.Heading':"Вы немного рано",
    'MursionPortal.SessionNotStarted.PreSimTips':"Ознакомьтесь с этими <code>советами по предварительной симуляции</code>",
    'MursionPortal.SessionNotStarted.ScenarioDetails':"Просмотрите <code>детали сценария</code>",
    'MursionPortal.StartDate':'Дата начала',
    'MursionPortal.Survey.Thankyou.Msg':'Мы благодарим вас за время, потраченное на прохождение этого опроса. Ваш ответ был зафиксирован.',
    'MursionPortal.Title.Hours':'Час(ы)',
    'MursionPortal.Title.Minutes':'Минут(а)',
    'MursionPortal.UserConsentDeclineConfirmation.PermissionDoesNotLieWithLearner':'Пожалуйста, укажите, согласны ли вы продолжить и записать вашу симуляцию(и). Если вы не согласны, вы не сможете продолжить сеанс симуляции, и программа будет прекращена. Если вы согласны, сеанс(ы) симуляции будет записана, и каждая запись будет надежно храниться в течение 12 месяцев, после чего она будет архивирована.',
    'MursionPortal.UserConsentDeclineConfirmation.PermissionsLiesWithLearner':'Вы уверены? Чтобы продолжить без записи, нажмите кнопку «Подтвердить». Чтобы изменить свое согласие на запись, нажмите кнопку «Отмена».',
    'Session.Edit.Modal.JoinSimulationBtnTooltip':'Вы сможете присоединиться за <code>{minutesBeforeJoin}</code> минут до начала симуляции.',
    'Session.Edit.Modal.LastEmailTrigger':'Последнее письмо, отправленное учащемуся: {sentDate}',
    'Session.Edit.Modal.LastEmailTrigger.Sender.UserName':'От: {userName} {sentTime}',
    'Session.Rescheduling.Email.Reminder.Msg':'Отправка напоминания учащемуся о переносе расписания по электронной почте !',
    'Settings.Config.Invitation.MursionSocialAttendance':'Собрать социальную посещаемость Mursion.',
    'Settings.LTI.Modal.SendScore':'Отправить оценку',
    'Dashboard.ContractProgress.Column.Provider':'Провайдер',
    'Settings.SSO.CopyDeepLinkNotification':'Ссылка была скопирована в ваш буфер обмена',
    'MursionPortal.Dashboard.Table.Empty.Body.Message':'Детали загрузятся, как только вы сделаете выбор',
    'Settings.SSO.ModalBody.ProjectSelector.DisabledTitle':'Сначала выберите нужный вариант' ,
    'Settings.SSO.ModalBody.ScenarioSelector.DisabledTitle':'Сначала выберите проект',
    'MursionPortal.Label.LearnerListening':'Учащийся слушает',
    'MursionPortal.ConversationalFlow.label.videoTimeLine':'Порядок видеозаписи',
    'MursionPortal.ConversationalFlow.label.zoomTimeLine':'Zoom',
    'MursionPortal.LearnerSimSelector.Sim.Available':'Сертифицировано и доступно',
    'MursionPortal.Button.View':'Смотреть',
    'MursionPortal.Dashboard.NewSimulationAssigned':'Назначена новая симуляция',
    'MursionPortal.Dashboard.MakeUpSimulation':'Создать симуляцию',
    'MursionPortal.Dashboard.MissedSimulation':'Пропущенный семинар',
    'MursionPortal.Dashboard.CompletedSimulations':'Завершенные симуляции',
    'MursionPortal.Dashboard.BackToDashboard':'Вернуться к Панель управления',
    'MursionPortal.Dashboard.CancelledSimulation':'Пропущенный семинар',
    'MursionPortal.ScenarioSearchList.Dialog.Title':'На это время у вас запланирована повторяющаяся активность. Хотите ли вы также обновить свою повторяющуюся активность? ',
    'MursionPortal.ScenarioSearchList.Dialog.UpdateText':'Я хочу обновить данные только для {startDate}',
    'MursionPortal.ScenarioSearchList.Dialog.UpdateText.ToDate':'Я хочу обновить данные повторяющейся активности {date}',
    'Mursion.Portal.Status.Connected':'ПОДКЛЮЧЕН/А',
    'Mursion.Portal.Status.Late':'ОПОЗДАНИЕ',
    'Mursion.Portal.Status.Immersing':'ВОВЛЕЧЕНИЕ',
    'Mursion.Portal.Status.Left':'ПОКИНУЛ/А СЕАНС',
    'Mursion.Portal.Status.Declined':'ОТКЛОНЕНО',
    'Login.EditFile': 'Отредактируйте {file} и сохраните для повторной загрузки.',
    'Login.Hello': 'Здравствуйте!',
    'Login.Title': 'Добро пожаловать на портал Mursion',
    'Login.Description': 'Создавайте, планируйте и управляйте симуляциями виртуальной реальности',
    'Login.PrivacyPolicy': 'политика конфиденциальности',
    'Login.PrivacyDataInfo': 'информация о конфиденциальности',
    'Login.TechnicalSupport': 'техническая поддержка',
    'Login.SignIn': 'Войти',
    'Login.Next': 'Далее',
    'Login.Email': 'Эл. почта',
    'Login.Password': 'Пароль',
    'Login.ForgotPassword': 'Забыли пароль?',
    'Login.Login': 'Вход',
    'Login.EmailHint': 'введите адрес электронной почты',
    'Login.PasswordHint': 'введите ваш пароль',
    'Login.NewPasswordHint': 'Введите новый пароль',
    'Login.RepeatPasswordHint': 'введите новый пароль еще раз',
    'Login.Image': 'Mursion-Logo',
    'Filters.Heading': 'ФИЛЬТРЫ:',
    'Dashboard.LeftPaneHeader': 'ПАНЕЛЬ ИНСТРУМЕНТОВ',
    'Dashboard.LeftPane.CategoryHeader.Users': 'ПОЛЬЗОВАТЕЛИ',
    'Dashboard.LeftPane.CategoryHeader.Projects': 'ПРОЕКТЫ',
    'Dashboard.LeftPane.Project.ProjectActivity': 'Активность по проектам',
    'Dashboard.LeftPane.Project.ProjectUtilization': 'Использование проектов',
    'Dashboard.LeftPane.CategoryHeader.Sessions': 'СЕССИИ',
    'Dashboard.LeftPane.Session.SessionLearner': 'Учащиеся сессии',
    'Dashboard.LeftPane.CategoryHeader.Legacy': 'ПРЕЖНЯЯ ВЕРСИЯ',
    'Dashboard.VideosTable.HoverTitle': ' ', // TODO: add translations
    'Dashboard.Button.ExportToEmailSentMsg': 'Отчет скоро будет отправлен на адрес {email}.',
    'Dashboard.Button.ExportToEmail.HoverTitle': 'Экспорт данных',
    'Dashboard.Button.ExportToEmail.HoverTitleNotAvailable': 'Экспорт недоступен',
    'Dashboard.Next': 'Далее',
    'Dashboard.Last': 'Последний',
    'Dashboard.Help': 'Помощь',
    'Dashboard.First': 'Первый',
    'Dashboard.Back': 'Назад',
    'Filters.Client': 'Клиент',
    'Filters.ClientPlaceHolderHint': 'Поиск по клиентам...',
    'Filters.Industries': 'Отрасли',
    'Filters.IndustriesPlaceHolderHint': 'Поиск по отраслям...',
    'Filters.IndustriesDisabledTitle': 'Отключено',
    'Filters.Project': 'Проект',
    'Filters.ProjectPlaceHolderHint': 'Поиск по проектам...',
    'Filters.ProjectDisabledTitle': 'Сначала выберите клиентов',
    'Filters.RoleDisabledTitle': 'Выбор роли отключен',
    'Filters.Scenario': 'Сценарий',
    'Filters.ScenarioPlaceHolderHint': 'Поиск по сценариям...',
    'Filters.ScenarioDisabledTitle': 'Сначала выберите клиентов и проекты',
    'Filters.Team': 'Команда',
    'Filters.TeamPlaceHolderHint': 'Поиск по командам…',
    'Filters.TeamDisabledTitle': 'Сначала выберите клиентов и проекты',
    'Filters.SessionStatus': 'Статус сессии',
    'Filters.SessionStatusPlaceHolderHint': 'Поиск по статусу сессии...',
    'Filters.RegistrationStatus': 'Статус регистрации',
    'Filters.RegistrationStatus.ShowAllRegistration': 'Статус регистрации: все',
    'Filters.RegistrationStatus.ShowAcceptedRegistration': 'Запрос о регистрации принят',
    'Filters.RegistrationStatus.ShowNotAcceptedRegistration': 'Запрос о регистрации не принят',
    'Filters.Date.LastMonth': 'Последний месяц',
    'Filters.Date.Yesterday': 'Вчера',
    'Filters.Date.Today': 'Сегодня',
    'Filters.Date.Tomorrow': 'Завтра',
    'Filters.Date.NextMonth': 'Следующий месяц',
    'Filters.Date.From': 'С',
    'Filters.Date.To': 'до',
    'Dashboard.UserActivityTable.HoverTitle': 'Просмотр сведений о пользователе',
    'Dashboard.Export.ErrMsg': 'Максимальное число записей для экспорта — {limit}. Измените запрос и повторите попытку.',
    'MursionPortal.Close': 'Закрыть',
    'Dashboard.UserTable.Column.Provider': 'Поставщик',
    'Dashboard.UserTable.Column.Client': 'Клиент',
    'Dashboard.UserTable.Column.ProfileId': 'ID профиля',
    'Dashboard.UserTable.Column.UserEmail': 'Эл. почта пользователя',
    'Dashboard.UserTable.Column.UserEmail.ButtonHoverTitle': 'Копировать эл. почту',
    'Dashboard.UserTable.Column.SSO': 'Единый вход',
    'Dashboard.UserTable.Column.DateRegistered.ButtonTitle': 'Отправить приглашение повторно',
    'Dashboard.UserTable.Column.Scenarios': 'Сценарии',
    'Dashboard.UserTable.Column.Teams': 'Команды',
    'Dashboard.UserTable.Column.Industry': 'Отрасль',
    'Dashboard.UserTable.Column.Accepted': 'Принято',
    'Session.Tab.Scheduled': 'Запланировано',
    'Session.Tab.Active': 'Активно',
    'Session.Tab.Past': 'Прошлые',
    'Session.Tab.Cancelled': 'Отменено',
    'Session.Search': 'Поиск',
    'Session.Table.Column.Time': 'Время',
    'Session.Table.Column.Client': 'Клиент',
    'Session.Table.Column.Project': 'Проект',
    'Session.Table.Column.SoftwareVersion': 'Версия ПО',
    'Session.Table.Column.Scenario': 'Сценарий',
    'Session.Table.Column.Trainees': 'Слушатели',
    'Session.Table.Column.Training': 'обучение',
    'Session.Table.Column.Session': 'сессия',
    'Session.Token.Label.Clients': 'Клиенты',
    'Session.Token.Label.Projects': 'Проекты',
    'Session.Token.Label.Scenarios': 'Сценарии',
    'Session.Token.Label.Teams': 'Команды',
    'Session.Token.Label.SessionStatuses': 'Статусы сессий',
    'Session.Button.More': 'Больше',
    'Session.Time.NotRunning': 'Еще не началась',
    'Session.Time.Finished': 'Уже закончена',
    'Session.Time.Running': 'Началась',
    'Session.Table.Column.Learners': 'Учащиеся',
    'Session.Table.Column.Team': 'Команда',
    'Session.Table.Column.Trainer': 'Преподаватель',
    'Session.Table.Column.SimSpecialist': 'Специалист по симуляции',
    'Session.Table.Column.TimeUntil': 'Время до',
    'Session.Table.Column.Created': 'Автор',
    'Session.Table.Column.Cancelled': 'Отменено',
    'Session.Table.Caption.SessionDetailsList': 'Список данных сессии',
    'Session.Export': 'Экспорт',
    'Session.Export.Message': 'Экспорт контента, отсортированного по',
    'Session.CalendarDate.Start': 'От кого:',
    'Session.CalendarDate.End': 'Кому:',
    'Session.Cancel': 'Отменить',
    'Session.Confirm': 'Подтвердить',
    'Session.Total': 'ВСЕГО',
    'Session.Filter.SessionNotFoundMessage': 'Сессии не найдены.',
    'Session.Filter.LearnerUpcomingSessionNotFoundMessage': 'У вас нет запланированных сессий.',
    'Session.Filter.UpcomingSessionNotFoundMessage': 'Нет запланированных сессий.',
    'Session.Filter.LearnerActiveSessionNotFoundMessage': 'У вас нет активных сессий.',
    'Session.Filter.ActiveSessionNotFoundMessage': 'Нет активных сессий.',
    'Session.Filter.LearnerCompletedSessionNotFoundMessage': 'Вы еще не завершили ни одной сессии.',
    'Session.Filter.SessionReportNotFoundMessage': 'Нет доступных отчетов о сессии.',
    'Session.Filter.CompletedSessionNotFoundMessage': 'Нет завершенных сессий.',
    'Session.Filter.CancelledSessionNotFoundMessage': 'Нет отмененных сессий.',
    'Session.Edit.Modal.Table.Column.DateTime': 'Дата/время',
    'Session.Edit.Modal.Table.Column.UserRole': 'Пользователь (роль)',
    'Session.Edit.Modal.Table.Column.Change': 'Изменить',
    'Session.Edit.Modal.Table.Column.SessionStatus': 'Статус сессии',
    'Session.Edit.Modal.Table.Column.Reason': 'Причина',
    'Session.Edit.Modal.AssetIndustry': 'Отрасль актива',
    'Session.Edit.Modal.SimulationEnvironment': 'Среда(-ы) симуляции',
    'Session.Edit.Modal.AvatarInSimulation': 'Аватар(-ы) в симуляции(-ях)',
    'Session.Edit.Modal.Date': 'Дата',
    'Session.Edit.Modal.Time': 'Время',
    'Session.Edit.Modal.DeliveryMode': 'Формат обучения',
    'Session.Edit.Modal.Duration': 'Продолжительность',
    'Session.Edit.Modal.Status': 'Статус',
    'Session.Edit.Modal.LearnersAttended': 'Присутствующие учащиеся',
    'Session.Edit.Modal.ReasonForCancellation': 'Причина отмены',
    'Session.Edit.Modal.MoreInfo': 'Подробнее...',
    'Session.Edit.Modal.HideAdditionalInfo': 'Скрыть дополнительные сведения...',
    'Session.Edit.Modal.AddToCalendar': 'Добавить в календарь',
    'Session.Edit.Modal.AttendSession': 'Участвовать в сессии',
    'Session.Edit.Modal.PleaseWait': 'Подождите…',
    'Session.Edit.Modal.CopySessionLink': 'Копировать ссылку на сессию',
    'Session.Edit.Modal.SessionLink': 'Ссылка на сессию',
    'Session.Edit.Modal.SubmitReview': 'Отправить оценку',
    'Session.Edit.Modal.SessionRequestPending': 'Запрос сессии ожидает рассмотрения',
    'Session.Edit.Modal.Back': 'Назад',
    'Session.Edit.Modal.Learners': 'Учащий(-е)ся',
    'Session.Edit.Modal.Learners.UnauthenticatedCheckbox': 'Участие только для неавторизованных пользователей',
    'Session.Edit.Modal.Trainees': 'Преподаватель(-и):',
    'Session.Edit.Modal.Trainer': 'Преподаватель:',
    'Session.Edit.Modal.SimSpecialist': 'Специалист по симуляции:',
    'Session.Edit.Modal.Notes': 'Примечания:',
    'Session.Edit.Modal.ClientNotes': 'Примечания клиента:',
    'Session.Edit.Modal.Notes.EmptyMessage': 'Нет примечаний',
    'Session.Edit.Modal.Attendance': 'Посещаемость:',
    'Session.Edit.Modal.ShowHistory': 'Показать историю...',
    'Session.Edit.Modal.HideHistory': 'Скрыть историю...',
    'Session.Edit.Modal.LearnersAttendedLabel': 'Присутствующие учащиеся:',
    'Session.Edit.Modal.SimulationContent': 'Контент для симуляции',
    'Session.Edit.Modal.SimulationContent.Name': 'Название:',
    'Session.Edit.Modal.SimulationContent.Description': 'Описание:',
    'Session.Edit.Modal.SimulationContent.Image': 'Изображение:',
    'Session.Edit.Modal.RestoreSession.ConfirmationText': 'Уверены, что хотите восстановить сессию?',
    'Session.Edit.Modal.CancelTraining.ConfirmationText': 'Уверены, что хотите отменить обучение?',
    'Session.Edit.Modal.CancelSession.ConfirmationText': 'Уверены, что хотите отменить сессию?',
    'Session.Edit.Modal.SessionSwap.ConfirmationText': 'Уверены, что хотите принять замену сессии?',
    'Session.Edit.Modal.AcceptRequest.ConfirmationText': 'Уверены, что хотите принять запрос на сессию?',
    'Session.Edit.Modal.AssignSS.ConfirmationText': 'Уверены, что хотите назначить нового специалиста по симуляции?',
    'Session.Edit.Modal.ChangeSS.ConfirmationText': 'Уверены, что хотите изменить специалиста по симуляции?',
    'Session.Edit.Modal.Button.RestoreSession': 'Восстановить сессию',
    'Session.Edit.Modal.Button.CancelTraining': 'Отменить обучение',
    'Session.Edit.Modal.Button.CancelSession': 'Отменить сессию',
    'Session.Edit.Modal.Button.AcceptSwap': 'Принять замену',
    'Session.Edit.Modal.Button.Participate': 'Принять участие',
    'Session.Edit.Modal.Button.Swap': 'Заменить',
    'Session.Edit.Modal.Button.UpdateSession': 'Обновить сессию',
    'Session.Edit.Modal.Button.UpdateTraining': 'Обновить обучение',
    'Session.Edit.Modal.Button.AcceptRequest': 'Принять запрос',
    'Session.Edit.Modal.Button.AssignSS': 'Назначить СС',
    'Session.Edit.Modal.Button.ChangeSS': 'Изменить СС',
    'Session.Attendance.Table.Column.UserName': 'Имя пользователя',
    'Session.Attendance.Table.Column.Time': 'Время',
    'Session.Attendance.Table.Column.Status': 'Статус',
    'Session.ViewSessionReport': 'Просмотр отчета о сессии',
    'Session.ViewEdit': 'Просмотр/редактирование сессии',
    'Session.Edit.Modal.Table.HistoryNotFoundMessage': 'Истории пока нет',
    'Session.Export.ErrMsg': 'Максимальное число записей для экспорта — {limit}. Измените запрос и повторите попытку.',
    'Session.Table.Column.Action': 'Действие',
    'Session.Edit.Modal.SessionSimSpecialistField.EmptyMsg.NoTrainers': 'Нет преподавателей для выбора',
    'Session.Edit.Modal.SessionSimSpecialistField.EmptyMsg.NoSimSpecialists': 'Нет специалистов по симуляции для выбора',
    'Session.Edit.Modal.SessionSimSpecialistField.NoSelectedMsg.NoTrainers': 'Преподаватели не выбраны',
    'Session.Edit.Modal.SessionSimSpecialistField.NoSelectedMsg.NoSimSpecialists': 'Специалисты по симуляции не выбраны',
    'Session.Edit.Modal.SessionSimSpecialistField.EditIconLabel': 'редактировать',
    'Session.Edit.Modal.SessionSimSpecialistField.Search.Placeholder': 'Поиск',
    'Session.Edit.Modal.SessionSimSpecialist.Button.Accept': 'Принять',
    'ConfirmationDialogue.AddReasonForChange': 'Укажите причину изменения',
    'ConfirmationDialogue.Button.Submit': 'Отправить',
    'ConfirmationDialogue.Button.Cancel': 'Отменить',
    'ConfirmationDialogue.Button.Ok': 'ОК',
    'ConfirmationDialogue.ReasonForChange': 'Причина изменения',
    'ConfirmButton.CancelSession.ConfirmCancellation': 'Подтвердить отмену',
    'ConfirmButton.CancelSession': 'Отменить сессию',
    'ConfirmButton.CancelSession.No': 'Нет',
    'Pagination.Button.First': 'Первый',
    'Pagination.Button.Back': 'Назад',
    'MursionPortal.UserName': 'Имя пользователя',
    'MursionPortal.Email': 'Эл. почта',
    'ClientUserTable.Column.Roles': 'Роли',
    'MursionPortal.Department': 'Подразделение',
    'MursionPortal.Location': 'Местонахождение',
    'ClientUserTable.Column.Projects': 'Проекты',
    'ClientUserTable.Column.Teams': 'Команды',
    'ClientUserTable.Column.Accepted': 'Принято',
    'ClientUserTable.HoverTitle': 'Просмотр/редактирование пользователя',
    'ClientUserTable.NoUserError': 'Пользователей пока нет',
    'MursionPortal.FirstName': 'Имя',
    'MursionPortal.LastName': 'Фамилия',
    'MursionPortal.Phone': 'Телефон',
    'Users.TableModal.EmailPlaceHolder': 'example@domain.com',
    'Users.TableModal.Title': 'Наименование',
    'Users.TableModal.LocationPlaceHolder': 'Город, страна',
    'Users.TableModal.DepartmentPlaceHolder': 'Название подразделения',
    'Users.TableModal.TitlePlaceHolder': 'Должность',
    'Users.Table.Modal.SettingsHeader': 'Настройки координатора/учащегося',
    'Users.Table.ModalHeader': 'Информация о пользователе',
    'Users.Table.ModalButton.Cancel': 'Отменить',
    'Users.Table.ModalButton.Delete': 'Удалить',
    'Users.Table.ModalButton.Save': 'Сохранить',
    'Users.Table.ModalButton.SendInvite': 'Отправить приглашение',
    'Users.Table.ModalButton.Restore': 'Восстановить',
    'Users.Table.Modal.Teams.Text': 'Команды еще не добавлены',
    'Users.Table.Modal.Teams': 'Команды',
    'Users.Table.Modal.EmployeeIdentification': 'Идентификация сотрудника',
    'Users.Table.Modal.Timezone': 'Часовой пояс',
    'Users.Table.Modal.Roles': 'Роли',
    'Users.Table.Modal.Settings.LearnerButton': 'В качестве учащегося',
    'Users.Table.Modal.Settings.FacilitatorButton': 'В качестве координатора',
    'Users.Button.CreateUserButton': 'Создать пользователя ',
    'Users.Button.Cancel': 'Отменить',
    'Users.Button.ImportUsers': 'Импорт пользователей',
    'Users.Button.Export': 'Экспорт',
    'Users.heading.ExportContentFilteredBy': 'Экспорт контента, отсортированного по',
    'Users.Button.Active': 'АКТИВНО',
    'Users.Button.Archived': 'АРХИВИРОВАНО',
    'Users.Button.All': 'ВСЕ',
    'Users.Text.Total': 'ВСЕГО',
    'Users.Heading.NewUsers': 'Новые пользователи',
    'Users.Placeholder': 'example@domain.com',
    'Users.Button.AddUser': 'Добавить пользователя ',
    'Users.Button.CreateUser': 'Создать пользователя',
    'Users.Button.CreateUsers': 'Создать пользователей',
    'Users.Heading.ImportNewUsers': 'Импорт новых пользователей',
    'Users.Heading.ImportNewExternalUsers': 'Импорт новых внешних пользователей',
    'Users.Button.ImportUserList': 'Импорт списка пользователей',
    'Users.Text.InstructionMessage.UsersWithoutPredefined': 'Пользователи без предопределенных команд будут назначены',
    'Users.Text.AddCsv': 'Добавьте файл .csv или .xslx, содержащий список адресов эл. почты, помещенных в один столбец.',
    'Users.Text.ToImport': 'Чтобы импортировать дополнительные поля, следуйте этим рекомендациям:',
    'Users.Text.ListUser': 'Эл. почта пользователя должна быть отделена от списка команд пользователей запятой, точкой с запятой, вертикальной чертой или двоеточием.',
    'Users.Text.ListTeams': 'Команды должны быть отделены друг от друга запятой.',
    'Users.Text.ListFields': 'Поля и каждая команда должны быть указаны в отдельных ячейках.',
    'Users.Text.UserRole': 'Тип роли пользователя по умолчанию — учащийся,',
    'Users.Text.UnlessOtherwiseIndicated': 'если не указано иное',
    'Users.Text.SampleImport': 'Образцы файлов для импорта можно скачать здесь:',
    'Users.DownloadCsvTemplate': 'Скачать шаблон CSV',
    'Users.DownloadExcelTemplate': 'Скачать шаблон Excel',
    'Users.Button.Update': 'Обновить',
    'Users.Labels.Clients': 'Клиенты',
    'Users.Labels.Projects': 'Проекты',
    'Users.Labels.Scenarios': 'Сценарии',
    'Users.Labels.Teams': 'Команды',
    'Users.Labels.Roles': 'Роли',
    'Users.Text.OnlyAccepted': 'Только принятые:',
    'Users.Button.Confirm': 'Подтвердить',
    'Users.Button.AccOwner': 'Владелец аккаунта',
    'Users.Button.Facilitator': 'Координатор',
    'Users.Button.Learner': 'Учащийся',
    'Users.Button.ProfServices': 'Проф. услуги',
    'Users.Button.ProjectManager': 'Руководитель проекта',
    'Users.Button.SimSpecialist': 'Специалист по симуляции',
    'Users.Button.ITManager': 'Менеджер ИТ',
    'Users.Button.BusinessAdmin': 'Бизнес-администратор',
    'Users.Button.SupportAdmin': 'Администратор поддержки',
    'Users.Button.DevAdmin': 'Администратор-разработчик',
    'Users.Button.ImportExternalUsers': 'Импорт внешних пользователей',
    'Users.Button.AssignLearners': 'Закрепить учащихся',
    'Users.CreateUser.Email.Placeholder': 'example@domain.com',
    'Users.Text.Instructions': 'Добавьте файл .csv или .xslx, содержащий список пользователей со следующими полями: эл. почта, ID единого входа, имя, фамилия, роль, команда. Все поля, кроме «Команда», обязательны для заполнения.',
    'Users.Text.Guidelines': 'Следуйте рекомендациям ниже:',
    'Users.Button.CreateUser.PencilIcon': 'Редактировать сведения о пользователе',
    'Users.Button.CreateUser.TrashIcon': 'Удалить пользователя',
    'Users.ExportButton.TextYes': 'Да',
    'Users.ExportButton.TextNo': 'Нет',
    'Users.Button.OK': 'OK',
    'Users.Button.AddProject': 'Добавить проект',
    'Users.CreateUser.Label.AddToProjects': 'Добавить в проект(-ы)',
    'Users.UserInfo.Label.AddTeams': 'Добавить команды',
    'Users.UserInfo.Label. SelectATeam': 'Выберите команду',
    'Users.UserInfo.Label.AssignToTeam': 'Назначить в команду:',
    'Users.CreateUser.Label.NoProjectsSpecified': 'Проекты не указаны',
    'Users.Option.SelectClient': 'Выбрать клиента',
    'Users.Label.Client': 'Клиент:',
    'Users.Label.RemoveTeam': 'Удалить команду',
    'Users.Label.ChooseATeam': 'Выбрать команду',
    'Users.Project.Warning': 'У этого клиента нет проектов. Выберите другого.',
    'Users.Project.Warning.UnknownProject': 'Неизвестный проект',
    'Users.ProjectContainer.Warning': 'Необходимо добавить хотя бы один проект',
    'Users.CompanyUsers.Button.Ok': 'ОК',
    'Users.CompanyUsers.Button.Accept': 'Принять',
    'Users.CreateUser.Label.Tier': 'Уровень',
    'Users.CreateUser.Label.HireDate': 'Дата приема на работу',
    'Users.CreateUser.Label.StationType': 'Тип станции',
    'Users.CreateUser.ToggleButton.Home': 'Дом',
    'Users.CreateUser.ToggleButton.Office': 'Офис',
    'Users.CreateUser.Label.Hours': 'Почасовая',
    'Users.CreateUser.ToggleButton.FullTime': 'Полная занятость',
    'Users.CreateUser.ToggleButton.PartTime': 'Неполная занятость',
    'Users.CreateUser.Label.ZoomID': 'ID в Zoom',
    'Users.CreateUser.ZoomID.Placeholder': 'ID в Zoom',
    'Users.CreateUser.ZoomID.Hover': 'Введите ID в Zoom',
    'Users.CreateUser.Label.UserRoles': 'Роль(-и) пользователя',
    'Users.CreateUser.Label.ProjectManagerSettings': 'Настройки руководителя проекта',
    'Users.CreateUser.Label.SimSpecialistSettings': 'Настройки специалиста по симуляции',
    'Users.CreateUser.Label.Certificates': 'Сертификаты',
    'Users.CreateUser.Label.TurnOnEditCertificates': 'Включить редактирование сертификатов',
    'Users.CreateUser.Label.TurnOffEditCertificates': 'Отключить редактирование сертификатов',
    'Users.CreateUser.Label.NoCertificatesHaveBeenAddedYet': 'Сертификаты еще не добавлены',
    'Users.CreateUser.Label.AddCertificate': 'Добавить сертификат',
    'Users.CreateUser.Label.CertificateOwner': 'Держатель сертификата',
    'Users.CreateUser.Button.RemoveCertificate': 'Удалить сертификат',
    'Users.TableHeading.Name': 'Название',
    'Users.TableHeading.Email': 'Эл. почта',
    'Users.TableHeading.Roles': 'Роли',
    'Users.TableHeading.Location': 'Местонахождение',
    'Users.TableHeading.Accepted': 'Принято',
    'Users.Table.Hover.ViewEditUser': 'Просмотр/редактирование пользователя',
    'Users.Table.Hover.NoUsersYet': 'Пользователей пока нет',
    'Users.CreateUser.Button.Client': 'Клиент',
    'Users.CreateUser.Label.ChooseAClient': 'Выбрать клиента',
    'Users.CreateUser.Label.SelectAClient': 'Выберите клиента:',
    'Users.CreateUser.Label.ChooseACertificate': 'Выбрать сертификат',
    'Users.CreateUser.Label.SelectACertificate': 'Выберите сертификат',
    'Users.CreateUser.Label.NoCertificatesToSelect': 'Нет сертификатов для выбора',
    'Users.CreateUser.Button.AddCertificate': 'Добавить сертификат ',
    'Users.CreateUser.PlaceHolder.SelectTier': 'Выбрать уровень',
    'Users.RequiredField.Warning': 'Убедитесь, что все поля заполнены правильно',
    'Users.RequiredField.ApplyChanges': 'Применить изменения',
    'Users.Heading.NewUserInfo': 'Информация о новом пользователе',
    'Users.SearchPlaceHolderText': 'Укажите имя пользователя',
    'Users.SearchNoUserFoundError': 'Пользователи клиента не найдены.',
    'Users.Table.Modal.Settings.AddTeamHeader': 'Добавить команды',
    'Users.Table.Modal.Settings.ChooseTeamHeader': 'Выбрать команду',
    'Users.Table.Modal.Settings.AssignTeamHeader': 'Назначить в команду:',
    'Users.Table.Modal.Settings.SelectTeamOptionHeader': 'Выберите команду',
    'Users.Table.Modal.Settings.TeamLearner': '(учащийся)',
    'Users.Table.Modal.Settings.TeamFacilitator': '(координатор)',
    'Users.Table.Modal.Settings.RemoveTeamOption': 'Удалить команду',
    'Users.Table.Modal.Settings.TurnOnEditTeamsOption': 'Включить редактирование команд',
    'Users.Table.Modal.Settings.TurnOffEditTeamsOption': 'Отключить редактирование команд',
    'Users.Total': 'ВСЕГО',
    'Users.Table.Modal.ArchiveHeader.ConfirmArchiveUser': 'Подтвердить архивирование пользователя',
    'Users.Table.Modal.ArchiveText': 'Уверены, что хотите архивировать пользователя?',
    'Users.Table.Modal.ArchiveUserButton': 'Архивировать пользователя',
    'Users.Table.Modal.RestoreHeader.ConfirmRestoreUser': 'Подтвердить восстановление пользователя',
    'Users.Table.Modal.RestoreText': 'Уверены, что хотите восстановить пользователя?',
    'Users.Table.Modal.RestoreUserButton': 'Восстановить пользователя',
    'Users.Table.Modal.CancelButton': 'Отменить',
    'Users.Table.Modal.ArchiveButton': 'Архивировать',
    'Users.Table.Modal.RestoreButton': 'Восстановить',
    'Users.Table.Modal.SendInviteButton': 'Отправить приглашение',
    'Users.CreateUserButton.Modal.CreateUserButton': 'Создать пользователя',
    'Users.Table.Modal.UpdateUserButton': 'Обновить пользователя',
    'Users.ViewUserOptions': 'Посмотреть пользователя {companyName}',
    'Users.NewUserOptions': 'Новый пользователь {companyName}',
    'Users.EditUserOptions': 'Редактировать пользователя {companyName}',
    'Reports.SessionListHeader.Completed': 'Завершено',
    'Reports.SessionListHeader.TotalSessionTime': 'Общее время сессии,',
    'Reports.SessionListHeader.TotalActualTime': 'Общее фактическое время,',
    'Reports.SessionListHeader.SimulationCompletionTime': 'Время выполнения симуляции',
    'Reports.SessionListHeader.CantOpenReport': 'Подождите, пока наша аналитическая система обработает результаты. Возможно, потребуется обновить страницу через несколько минут.',
    'Reports.SessionListHeader.NoReportsFound': 'Отчеты не найдены.',
    'Reports.ReportCard.Button.ReviewSimulation': 'ОБЗОР СИМУЛЯЦИИ',
    'Reports.ReportCard.Strategies': 'Стратегии',
    'Reports.ReportCard.NotChecked': 'Не отмечено',
    'Dashboard.SchedulingInfoTable.Modal.SortBy': 'Сортировать по:',
    'Dashboard.SchedulingInfoTable.Modal.NoItemsFound': 'Элементы не найдены.',
    'Dashboard.SchedulingInfoTable.Modal.Teams': 'Команды',
    'Dashboard.SchedulingInfoTable.Modal.Teams.Placeholder': 'Укажите название команды',
    'Dashboard.SchedulingInfoTable.Modal.Teams.EmptyMessage': 'Команды не найдены',
    'Dashboard.SchedulingInfoTable.Modal.Capitalized.TeamName': 'Название команды:',
    'Dashboard.SchedulingInfoTable.Modal.Learners': 'Учащиеся:',
    'Dashboard.SchedulingInfoTable.Modal.Projects': 'Проекты:',
    'Dashboard.SchedulingInfo.Filter.SearchIndustries.PlaceholderHint': 'Поиск по отраслям...',
    'Dashboard.SchedulingInfo.Filter.IndustryDisabledTitle': 'Отключено',
    'Dashboard.SchedulingInfo.Filter.UsedSoftwareVersion.PlaceholderHint': 'Используемые версии ПО...',
    'Dashboard.SchedulingInfoTable.Modal.Total': 'ВСЕГО',
    'Dashboard.SchedulingInfoTable.Modal.ScenarioName': 'Название сценария',
    'Dashboard.SchedulingInfoTable.Modal.SessionDate': 'Дата сессии:',
    'Dashboard.SchedulingInfoTable.Modal.ProjectName': 'Название проекта',
    'Dashboard.SchedulingInfoTable.Modal.SoftwareVersion': 'Версия ПО',
    'Dashboard.SchedulingInfoTable.Modal.Capitalized.DeliveryMode': 'Формат обучения',
    'Dashboard.SchedulingInfoTable.Modal.SessionStatus': 'Статус сессии',
    'Dashboard.SchedulingInfoTable.Modal.Name': 'Название',
    'Dashboard.SchedulingInfoTable.Modal.Status': 'Статус',
    'Dashboard.SchedulingInfoTable.Modal.Recording': 'Сохранить запись в',
    'Dashboard.SchedulingInfoTable.Modal.TeamName': 'Название команды',
    'Dashboard.SchedulingInfoTable.Modal.Learner': 'Учащиеся',
    'Dashboard.SchedulingInfoTable.Modal.CompletedSessions': 'Завершенные сессии',
    'Dashboard.SchedulingInfoTable.Modal.DeliveryMode': 'Формат обучения',
    'Dashboard.SchedulingInfoTable.Modal.Capitalized.ProjectName': 'Название проекта',
    'Dashboard.SchedulingInfoTable.Modal.Capitalized.SoftwareVersion': 'Версия ПО',
    'Dashboard.SchedulingInfoTable.Modal.Timeline': 'Срок реализации',
    'Dashboard.SchedulingInfoTable.Modal.SchedulesSessions': 'Расписание сессий',
    'Dashboard.SchedulingInfoTable.Modal.MissedSessions': 'Смешанные сессии',
    'Dashboard.SchedulingInfoTable.Modal.LateCancellations': '\«Поздние\» отмены',
    'Dashboard.SchedulingInfoTable.Modal.TimeSpent': 'Затраченное времени',
    'Dashboard.SchedulingInfoTable.Modal.CancelledBy': 'Отменено',
    'Dashboard.SchedulingInfoTable.Modal.CancellationReason': 'Причина отмены',
    'Dashboard.SchedulingInfoTable.Modal.DateScheduled': 'Запланированная дата',
    'Dashboard.SchedulingInfoTable.Modal.DateCancelled': 'Дата отмены',
    'Dashboard.SchedulingInfoTable.Modal.Filter.Teams': 'Команды',
    'Dashboard.SchedulingInfoTable.Modal.Placeholder.EnterTeamName': 'Укажите название команды',
    'Dashboard.SchedulingInfoTable.Modal.TeamsEmptyMessage': 'Команды не найдены',
    'Dashboard.SchedulingInfoTable.Modal.Filter.TeamName': 'Название команды',
    'Dashboard.SchedulingInfoTable.Modal.Filter.Scenarios': 'Сценарии',
    'Dashboard.SchedulingInfoTable.Modal.Placeholder.EnterScenarioName': 'Укажите название сценария',
    'Dashboard.SchedulingInfoTable.Modal.ScenariosEmptyMessage': 'Сценарии не найдены',
    'Dashboard.SchedulingInfoTable.Modal.Filter.ScenarioName': 'Название сценария',
    'Dashboard.SchedulingInfoTable.Modal.Filter.ProjectName': 'Название проекта',
    'Dashboard.SchedulingInfoTable.Modal.Filter.SoftwareVersion': 'Версия ПО',
    'Dashboard.SchedulingInfoTable.Modal.Filter.ScenarioStatus': 'Статус сценария',
    'Dashboard.SchedulingInfoTable.Modal.Filter.DeliveryMode': 'Формат обучения',
    'Dashboard.SchedulingInfoTable.Modal.Filter.SessionsScheduled': 'Запланированные сессии',
    'Dashboard.SchedulingInfoTable.Modal.SessionsEmptyMessage': 'Сессии не найдены',
    'Dashboard.SchedulingInfoTable.Modal.Filter.SessionsDateTime': 'Дата/время сессии',
    'Dashboard.SchedulingInfoTable.Modal.Filter.SessionsCompleted': 'Завершенные сессии',
    'Dashboard.SchedulingInfoTable.Modal.Filter.SessionsMissed': 'Пропущенные сессии',
    'Dashboard.SchedulingInfoTable.Modal.Filter.LateCancellations': '«Поздние» отмены',
    'Dashboard.SchedulingInfoTable.Modal.Filter.CancelledBy': 'Отменено',
    'Dashboard.SchedulingInfoTable.Modal.Filter.SessionsError': 'Ошибка сессии',
    'RestorePassword.EnterEmailAddress': 'Укажите адрес эл. почты и нажмите кнопку «Восстановить». Вы получите письмо со ссылкой для сброса пароля.',
    'RestorePassword.Email': 'Эл. почта',
    'RestorePassword.Button.Cancel': 'Отменить',
    'RestorePassword.Button.Restore': 'Восстановить',
    'RestorePassword.Modal.IfValidEmailAddressEntered': 'Если вы ввели действительный адрес эл. почты, письмо с инструкциями по сбросу пароля было отправлено на адрес, связанный с аккаунтом. Обратитесь в службу техподдержки, если проблема не устранится.',
    'RestorePassword.Modal.Button.GotIt': 'Понятно',
    'Trainings.Heading.AllTrainingSessions': 'все обучающие сессии',
    'Requests.Table.Swap': 'ЗАМЕНА',
    'Requests.Table.SchedulingRequests': 'Планирование запросов',
    'Requests.Total': 'ВСЕГО',
    'Requests.Table.NoSessionsFound': 'Сессии не найдены.',
    'Requests.Table.NoUpcomingSwapSessions': 'Нет предстоящих сессий на ЗАМЕНУ.',
    'Requests.Table.NoUpcomingRequests': 'Нет предстоящих запросов.',
    'GetTimeToEvents.Day': 'День',
    'GetTimeToEvents.Days': 'Дни',
    'GetTimeToEvents.Minute': 'Минута',
    'GetTimeToEvents.Minutes': 'Минуты',
    'GetTimeToEvents.Hour': 'Час',
    'GetTimeToEvents.Hours': 'Часы',
    'GetTimeToEvents.LessThanOneMinute': 'Меньше 1 минуты',
    'SessionReports.SessionInformation': 'Информация о сессии',
    'SessionReports.SessionInformation.Hover.Information': 'Информация',
    'SessionReports.SmartMetrics': 'Умные метрики',
    'SessionReports.SendVideoLink.Modal.ShareLinkToVideo': 'Поделиться ссылкой на видео',
    'SessionReports.Button.BackToReports': 'Вернуться к отчетам',
    'SessionReports.Button.BackToTrainings': 'Вернуться к обучению',
    'SessionReports.Button.HideInfo': 'Скрыть информацию',
    'SessionReports.Button.ShowInfo': 'Показать информацию',
    'SessionReports.Button.SendVideoLink': 'Отправить ссылку на видео',
    'SessionReports.TryAgain': 'Повторите попытку',
    'SessionReports.Mode': 'Формат:',
    'SessionReports.SessionLength': 'Продолжительность сессии:',
    'SessionReports.LearnersAttended': 'Присутствующие учащиеся:',
    'SessionReports.ScenarioTimeline': 'Срок реализации сценария:',
    'SessionReports.ActualSessionTime': 'Фактическое время сессии:',
    'SessionReports.ActualSimulationTime': 'Фактическое время симуляции:',
    'SessionReports.LastUserStatus': 'Статус последнего пользователя:',
    'SessionReports.SessionStatus': 'Статус сессии:',
    'SessionReports.Delivery': 'Исполнение',
    'SessionReports.SendVideoLink.Modal.AddEmails': 'Добавить эл. почту',
    'SessionReports.SendVideoLink.Modal.Button.AddEmail': 'Добавить эл. почту ',
    'SessionReports.SendVideoLink.Modal.EmailPlaceHolder': 'example@domain.com',
    'SessionReports.SendVideoLink.Modal.Button.Cancel': 'Отменить',
    'SessionReports.SendVideoLink.Modal.Button.Send': 'Отправить',
    'SessionReports.FactSessionInfo': 'Фактическая информация о сессии',
    'SessionReports.Text.NoTraineeParticipatedInSession': 'Ни один преподаватель не участвовал в сессии',
    'SessionReports.Text.NoLearnerParticipatedInSession': 'Ни один учащийся не участвовал в сессии',
    'SessionReports.ToolTip.TrackingYours': 'Очень важно, чтобы ваше восприятие результата или стратегии совпадало с восприятием аватара(-ов). Это означает, что вы отслеживаете свое влияние на аватара(-ов), тем самым повышая социальную эффективность. Эта информация содержится в таблице ниже.',
    'SessionReports.ToolTip.TrackingLearners': 'Очень важно, чтобы восприятие результата или стратегии у учащихся совпадало с восприятием аватара(-ов). Это означает, что учащийся отслеживает свое влияние на аватар(-ов), тем самым повышая социальную эффективность. Эта информация содержится в таблице ниже.',
    'SessionReports.ToolTip.AvatarsHeaderYours': 'Этот столбец показывает восприятие вашей симуляции аватаром(-ами). Галочка указывает на то, что аватары восприняли вашу симуляцию как успешную, а крестик — на возможность улучшения.',
    'SessionReports.ToolTip.AvatarsHeaderLearners': 'Этот столбец показывает восприятие аватаром(-ами) симуляции учащегося. Галочка указывает на то, что аватары восприняли симуляцию учащегося как успешную, а крестик — на возможность улучшения.',
    'SessionReports.ToolTip.SelfHeaderYours': 'В этом столбце указана ваша собственная оценка эффективности симуляции. Галочка указывает на то, что вы посчитали ее успешной, а крестик — на возможность улучшения.',
    'SessionReports.ToolTip.SelfHeaderLearners': 'В этом столбце указана оценка учащегося эффективности собственной симуляции. Галочка указывает на то, что учащийся посчитал ее успешной, а крестик — на возможность улучшения.',
    'SessionReports.ToolTip.VideoLink': 'Вы можете поделиться ссылкой на видео с результатами симуляции с коллегами. Для этого просто введите их адрес эл. почты во всплывающем окне. Обратите внимание, что коллегам не будут доступны ваши аналитические данные.',
    'SessionReports.ToolTip.SmartMetrics': 'Умные метрики генерируются с помощью передовых алгоритмов. Следите за этими компьютерными измерениями, чтобы понять, влияют ли они на общий мониторинг во время разных симуляций.',
    'SessionReports.ToolTip.MiScore': 'Индекс Mursion (MI) — это показатель вашей социальной эффективности во время этого разговора. Он рассчитывается с использованием различных данных, включая схему вашего участия в разговоре и ее влияние на аватар(-ов). Постарайтесь оценить свое влияние на аватар(-ов) и меняйте схемы разговора, чтобы понять, как улучшить свой индекс MI.',
    'SessionReports.ToolTip.ListeningSpeakingTime': 'Это индикатор, показывающий соотношение времени, которое вы потратили на речь и слушание во время разговора.',
    'SessionReports.ToolTip.Emotions': 'Эмоции (бета): в настоящее время мы изучаем алгоритмы, которые позволят нам интерпретировать тембры вашего голоса для определения общего настроения. Проверяйте эти данные, чтобы отслеживать положительные, отрицательные или нейтральные коннотации в тембрах вашего голоса с течением времени. Что еще более важно, понаблюдайте за тем, какое влияние вы оказали на тембры голоса аватара(-ов).',
    'SessionReports.ToolTip.TurnTracking': 'Схема поочередного участия — это набор мер, которые демонстрируют характер сотрудничества/социальную эффективность между вами и аватарами.',
    'SessionReports.ToolTip.Pauses': 'Паузы являются косвенным показателем беглости вашей речи во время разговора.',
    'SessionReports.ToolTip.ArticulationRate': 'Это показатель скорости, с которой вы доставляете непрерывный набор информации.',
    'SessionReports.TableHeader.Outcome': 'Результат',
    'SessionReports.TableHeader.Self': 'Свой',
    'SessionReports.TableHeader.Avatars': 'Аватар(-ы)',
    'SessionReports.TableHeader.Strategies': 'Стратегии',
    'MursionPortal.Button.Cancel': 'Отменить',
    'Settings.Config.Heading.Industries': 'ОТРАСЛИ',
    'Settings.Config.Button.New': 'Новый ',
    'Settings.Config.Note': 'ПРИМЕЧАНИЕ.',
    'Settings.Config.Note.Text.ConfigSettingsGlobal': 'Настройки конфигурации являются глобальными. Изменения затронут всех клиентов и пользователей.',
    'Settings.Config.Heading.SimSpecialist': 'УРОВНИ СПЕЦИАЛИСТОВ ПО СИМУЛЯЦИИ',
    'Settings.Config.Heading.Scheduling': 'ПЛАНИРОВАНИЕ',
    'Settings.Config.Heading.Scheduling.Description': '(Специалист по симуляции)',
    'Settings.Config.Scheduling.MaximumFullTimeSimSpecialistHours': 'Макс. кол-во часов для специалиста по симуляции (полная занятость)',
    'Settings.Config.Scheduling.MaximumPartTimeSimSpecialistHours': 'Макс. кол-во часов для специалиста по симуляции (неполная занятость)',
    'Settings.Config.Scheduling.MaximumContinuousSimSpecialistHours': 'Макс. кол-во часов непрерывной работы специалиста по симуляции',
    'Settings.Config.Scheduling.LengthOfSimSpecialistRequiredBreak': 'Продолжительность перерыва специалиста по симуляции',
    'Settings.Config.Scheduling.FutureAvailabilityForSimSpecialist': 'Будущий период доступности специалиста по симуляции',
    'Settings.Config.Scheduling.SimSpecialistShiftTime': 'Часы работы специалиста по симуляции',
    'Settings.Config.Scheduling.AvailableTimeBlockMaximumPerDay': 'Макс. период доступности в день',
    'Settings.Config.Scheduling.AvailableTimeBlockMinimumPerDay': 'Мин. период доступности в день',
    'Settings.Config.Scheduling.AvailabilityTimeStep': 'Рабочий интервал',
    'Settings.Config.Scheduling.AdvanceSSScheduleConfirmation': 'Насколько заранее следует подтвердить график СС',
    'Settings.Config.Scheduling.RequestUnfulfilledReminder': 'Когда отправлять напоминание о невыполнении запроса',
    'Settings.Config.Scheduling.FirstEmailReminder': 'Когда отправлять первое напоминание по эл. почте',
    'Settings.Config.Scheduling.SecondEmailReminder': 'Когда отправлять второе напоминание по эл. почте',
    'Settings.Config.Scheduling.BookedSessionWithNoAssignedLearner': 'Когда уведомлять о забронированной сессии без назначенного учащегося',
    'Settings.Config.Scheduling.UnfulfilledSessionSwapRequestReminder': 'Когда отправлять напоминание о невыполненном запросе о замене сессии',
    'Settings.Config.Scheduling.NextSessionReminder': 'Когда отправлять напоминание о следующей сессии, в зависимости от времени между созданием и запланированным временем начала сессии',
    'Settings.Config.Scheduling.ReservedAvailabilityExpired': 'Когда истекает срок зарезервированной доступности',
    'Settings.Config.Scheduling.StartTimeForConsideringMissedSession': 'Кол-во времени после начала, когда сессия считается \«пропущенной\»',
    'Settings.Config.Scheduling.EndTimeForConsideringMissedSession': 'Кол-во времени после окончания, когда сессия считается \«пропущенной\»',
    'Settings.Config.Scheduling.RejoinSessionAfterError': 'Время, в течение которого участники должны повторно подключиться к сессии после ошибки',
    'Settings.Config.Scheduling.TimeDisabledForSessionScheduling': 'Время, на которое отключено планирование сессии',
    'Settings.Config.Scheduling.SessionTimeStep': 'Рабочий интервал сессии',
    'Settings.Config.Heading.ProjectAndScenario': 'ПРОЕКТ И СЦЕНАРИЙ',
    'Settings.Config.ProjectAndScenario.MaximumProjectTimelineDuration': 'Макс. срок реализации проекта',
    'Settings.Config.ProjectAndScenario.MinimumProjectTimelineDuration': 'Мин. срок реализации проекта',
    'Settings.Config.ProjectAndScenario.MaximumScenarioTimelineDuration': 'Макс. срок реализации сценария',
    'Settings.Config.ProjectAndScenario.MinimumScenarioTimelineDuration': 'Мин. срок реализации сценария',
    'Settings.Config.ProjectAndScenario.MinimumSessionLength': 'Мин. продолжительность сессии',
    'Settings.Config.ProjectAndScenario.MaximumSessionLength': 'Макс. продолжительность сессии',
    'Settings.Config.ProjectAndScenario.MinimumSimulationLength': 'Мин. продолжительность симуляции',
    'Settings.Config.ProjectAndScenario.SessionLengthStep': 'Интервал продолжительности сессии',
    'Settings.Config.ProjectAndScenario.MinimumLearnersInRemoteSession': 'Мин. кол-во учащихся в удаленных сессиях',
    'Settings.Config.ProjectAndScenario.MaximumLearnersInRemoteSession': 'Макс. кол-во учащихся в удаленных сессиях',
    'Settings.Config.ProjectAndScenario.MinimumLearnersInFishbowlSession': 'Мин. кол-во учащихся в сессиях Local Fishbowl',
    'Settings.Config.ProjectAndScenario.MaximumLearnersInFishbowlSession': 'Макс. кол-во учащихся в сессиях Local Fishbowl',
    'Settings.Config.ProjectAndScenario.MinimumLearnersInGroupSession': 'Мин. кол-во учащихся в групповых сессиях',
    'Settings.Config.ProjectAndScenario.MaximumLearnersInGroupSession': 'Макс. кол-во учащихся в групповых сессиях',
    'Settings.Config.ProjectAndScenario.AllowedScenarioVersions': 'Разрешенные версии сценария',
    'Settings.Config.ProjectAndScenario.DefaultScenarioVersion': 'Версия сценария по умолчанию',
    'Settings.Config.Heading.CompanyEvent': 'СОБЫТИЕ КОМПАНИИ',
    'Settings.Config.CompanyEvent.MaximumEventDuration': 'Макс. продолжительность события',
    'Settings.Config.CompanyEvent.MinimumEventDuration': 'Мин. продолжительность события',
    'Settings.Config.CompanyEvent.EventTimeStep': 'Интервал события',
    'Settings.Config.Heading.SessionReport': 'ОТЧЕТ О СЕССИИ',
    'Settings.Config.SessionReport.ShowSessionReport': 'Показать отчет о сессии',
    'Settings.Config.Heading.FeedbackProvider': 'ЛИЦО, ПРЕДОСТАВЛЯЮЩЕЕ ОБРАТНУЮ СВЯЗЬ',
    'Settings.Config.FeedbackProvider.ZenDeskSnippetKey': 'Клавиша для фрагмента ZenDesk',
    'Settings.Config.Heading.SimulationContent': 'КОНТЕНТ ДЛЯ СИМУЛЯЦИИ',
    'Settings.Config.SimulationContent.SimulationContentFolder': 'Папка с контентом для симуляции S3',
    'Settings.Config.Heading.Invitation': 'ПРИГЛАШЕНИЕ',
    'Settings.Config.Invitation.ExpireUserRegistrationInvitation': 'Когда истекает срок действия приглашения на регистрацию пользователя',
    'Settings.Config.Invitation.ReminderToAcceptRegistrationInvitation': 'Когда напоминать незарегистрированному пользователю о принятии приглашения на регистрацию',
    'Settings.Config.Invitation.RegistrationInvitationExpiresAfter': 'Срок действия приглашения на регистрацию истекает через',
    'Settings.Config.IndustriesButton.Modal.EditIndustry': 'Редактировать отрасль',
    'Settings.Config.NewButton.Modal.NewIndustry': 'Новая отрасль',
    'Settings.Modal.Placeholder.IndustryName': 'Название отрасли',
    'Settings.Config.IndustriesButton.Modal.Button.UpdateIndustry': 'Обновить отрасль',
    'Settings.Config.NewButton.Modal.Button.CreateIndustry': 'Создать отрасль',
    'Settings.Config.IndustriesButton.Modal.Button.RestoreIndustry': 'Восстановить отрасль',
    'Settings.Config.IndustriesButton.Modal.Button.ArchiveIndustry': 'Архивировать отрасль',
    'MursionPortal.Modal.Heading.Name': 'Название',
    'Settings.Config.RestoreIndustry.Modal.ConfirmRestore': 'Уверены, что хотите восстановить отрасль?',
    'Settings.Config.ArchiveIndustry.Modal.ConfirmArchive': 'Уверены, что хотите архивировать отрасль?',
    'Settings.Config.Description.PerWeek': '(в неделю)',
    'Settings.Config.Description.PerDay': '(в день)',
    'Settings.Config.Description.BeforeBreak': '(до перерыва)',
    'Settings.Config.Description.Minutes': '(мин.)',
    'Settings.Config.Description.Days': '(дн.)',
    'Settings.Config.Description.Hours': '(ч)',
    'Settings.Config.TierButton.Modal.Button.UpdateTier': 'Обновить уровень',
    'Settings.Config.TierButton.Modal.Heading.EditTier': 'Редактировать уровень',
    'Settings.Config.TierButton.Modal.Placeholder.TierName': 'Название уровня',
    'MursionPortal.Button.Archive': 'Архивировать',
    'Settings.Config.ArchiveIndustry.Modal.Heading.ConfirmArchive': 'Подтвердить архивирование',
    'Settings.Config': 'Настройка',
    'Settings.ScenarioBank': 'Банк сценариев',
    'Settings.Environments': 'Среды (ML2)',
    'Settings.Avatars': 'Аватары (ML2)',
    'Settings.SSO': 'Единый вход',
    'Settings.Error.NoSettingsAvailable': 'Нет доступных настроек',
    'MursionPortal.Error.SomethingWentWrong': 'К сожалению, что-то пошло не так.',
    'MursionPortal.Error.ReloadingPage': 'Попробуйте перезагрузить страницу. Сообщение об ошибке отправлено.',
    'MursionPortal.Error.WorkingHard': 'Мы сделаем всё возможное для скорейшего устранения проблемы.',
    'MursionPortal.Error.SupportAvailable': 'Поддержка доступна с понедельника по пятницу',
    'MursionPortal.Error.TechnicalAssistance': 'Если вам нужна техническая помощь, обратитесь в службу поддержки Mursion',
    'MursionPortal.At': 'в',
    'MursionPortal.Or': 'или',
    'Settings.Environment.Modal.PlaceHolder.EnvironmentName': 'Название среды',
    'Settings.Environment.Modal.Description': 'Описание',
    'Settings.Environment.Modal.Industry': 'Отрасль',
    'Settings.Environment.Modal.Picture': 'Изображение',
    'MursionPortal.Button.Delete': 'Удалить',
    'Settings.Environment.Modal.Button.UploadFile': 'Загрузить файл',
    'Settings.Environment.Button.CreateEnvironment': 'Создать среду ',
    'Settings.Environment.ModalHeader.NewEnvironment': 'Новая среда',
    'Settings.Environment.ModalHeader.Environment': 'Среда',
    'Settings.Environment.ModalHeader.Archived': '(Архивировано)',
    'Settings.Environment.ModalHeader.EditEnvironment': 'Редактировать среду',
    'Settings.Environment.Modal.Button.CreateEnvironment': 'Создать среду',
    'Settings.Environment.Modal.Button.UpdateEnvironment': 'Обновить среду',
    'Settings.Environment.Modal.ArchiveEnvironmentText': 'Уверены, что хотите архивировать среду?',
    'Settings.Environment.Modal.RestoreEnvironmentText': 'Уверены, что хотите восстановить среду?',
    'MursionPortal.Button.Restore': 'Восстановить',
    'MursionPortal.Total': 'ВСЕГО',
    'MursionPortal.Text.Total': 'ВСЕГО',
    'MursionPortal.Button.ExportToEmail.HoverTitle.ExportData': 'Экспорт данных',
    'MursionPortal.Button.ExportToEmail.HoverTitle.ExportNotAvailable': 'Экспорт недоступен',
    'MursionPortal.Button.ExportToEmail': 'Экспорт в электронную почту',
    'MursionPortal.WarningMessage.RequestNotImplemented': 'Запрос данных пока не выполнен',
    'MursionPortal.Table.ColumnHeader.Client': 'Клиент',
    'MursionPortal.Table.ColumnHeader.Provider': 'Поставщик',
    'MursionPortal.Table.ColumnHeader.Project': 'Проект',
    'MursionPortal.Table.ColumnHeader.Scenario': 'Сценарий',
    'MursionPortal.Table.ColumnHeader.SoftwareVersion': 'Версия ПО',
    'MursionPortal.Table.ColumnHeader.DeliveryMode': 'Формат обучения',
    'MursionPortal.Table.ColumnHeader.SessionStart': 'Начало сессии',
    'MursionPortal.Table.ColumnHeader.SessionEnd': 'Окончание сессии',
    'MursionPortal.Table.ColumnHeader.LearnerStatus': 'Статус учащегося',
    'MursionPortal.Table.ColumnHeader.SessionStatus': 'Статус сессии',
    'MursionPortal.Table.ColumnHeader.LearnerName': 'Имя учащегося',
    'MursionPortal.Table.ColumnHeader.LearnerEmail': 'Эл. почта учащегося',
    'MursionPortal.Table.LearnerEmail.HoverTitle.CopyEmail': 'Копировать эл. почту',
    'MursionPortal.Table.LearnerEmail.HoverTitle.UserEmail': 'Эл. почта пользователя',
    'MursionPortal.Table.ColumnHeader.SessionId': 'ID сессии',
    'MursionPortal.Table.SessionId.HoverTitle.CopySessionId': 'Копировать ID сессии',
    'MursionPortal.Table.ColumnHeader.SessionReport': 'Отчет о сессии',
    'MursionPortal.Table.ColumnHeader.SimSpecialistName': 'Имя специалиста по симуляции',
    'MursionPortal.Table.ColumnHeader.LearnerTimeSpent': 'Время, затраченное учащимся',
    'MursionPortal.Table.HoverTitle.SessionSummary': 'Краткая информация о сессии',
    'MursionPortal.EmptyMessage.NoSessionsYet': 'Сессий пока нет',
    'MursionPortal.Button.ViewReport': 'Просмотр отчета',
    'Mursion.Portal.Status.Requested': 'Запрос отправлен',
    'Mursion.Portal.Status.Pending': 'В ожидании',
    'Mursion.Portal.Status.PendingSwap': 'В ожидании (замена)',
    'Mursion.Portal.Status.PreBooked': 'Забронировано заранее',
    'Mursion.Portal.Status.Booked': 'Забронировано',
    'Mursion.Portal.Status.Active': 'Активно',
    'Mursion.Portal.Status.Complete': 'Завершено',
    'Mursion.Portal.Status.Error': 'Ошибка',
    'Mursion.Portal.Status.Missed': 'Пропущено',
    'Mursion.Portal.Status.NeedsReview': 'Требуется проверка',
    'Mursion.Portal.Status.Cancelled': 'Отменено',
    'Dashboard.UsersActivity.Filter.Roles.Placeholder': 'Поиск...',
    'Settings.ScenarioBank.Table.ColumnHeader.Name': 'Название',
    'MursionPortal.DeliveryMode': 'Формат обучения',
    'Settings.ScenarioBank.Table.ColumnHeader.SessionSize': 'Размер сессии',
    'MursionPortal.SessionLength': 'Продолжительность сессии',
    'Settings.ScenarioBank.Table.ViewEditScenario': 'Просмотр/редактирование сценария',
    'Settings.ScenarioBank.Table.ThereAreNoScenarios': 'Сценариев пока нет',
    'MursionPortal.Capitalized.Total': 'ВСЕГО',
    'Settings.ScenarioBank.Form.NewScenario': 'Новый сценарий',
    'Settings.ScenarioBank.Form.Scenario': 'Сценарий',
    'Settings.ScenarioBank.Form.EditScenario': 'Редактировать сценарий',
    'Settings.ScenarioBank.Form.Save': 'Сохранить',
    'Settings.ScenarioBank.Form.ConfirmArchiveScenario': 'Уверены, что хотите архивировать сценарий?',
    'Settings.ScenarioBank.Form.ConfirmRestoreScenario': 'Уверены, что хотите восстановить сценарий?',
    'Settings.ScenarioBank.Button.CreateScenario': 'Создать сценарий ',
    'MursionPortal.ShortLabel.Minutes': 'мин.',
    'Settings.ScenarioBank.Modal.Scenarios': 'Сценарий(-и)',
    'Settings.ScenarioBank.Modal.CompleteProjectInfoFirst': 'Сначала укажите информацию о проекте',
    'Settings.ScenarioBank.Modal.ScenarioName': 'Название сценария',
    'Settings.ScenarioBank.Modal.Placeholder.EnterScenarioName': 'Укажите название сценария',
    'Settings.ScenarioBank.Modal.Outcome': 'Результат',
    'Settings.ScenarioBank.Modal.Strategies': 'Стратегии',
    'Settings.ScenarioBank.Modal.Attachments': 'Вложения',
    'Settings.ScenarioBank.Modal.SoftwareVersion': 'Версия ПО',
    'Settings.ScenarioBank.Modal.WeAlreadyHaveSessions': 'Сессии по этому сценарию уже существуют',
    'Settings.ScenarioBank.Modal.SelectDeliveryMode': 'Выберите формат обучения',
    'Settings.ScenarioBank.Modal.ShouldBeAtLeast': 'Мин. продолжительность — {minutes} мин.',
    'Settings.ScenarioBank.Modal.SelectSessionLength': 'Выберите продолжительность сессии',
    'Settings.ScenarioBank.Modal.SessionSize': 'Размер сессии',
    'Settings.ScenarioBank.Modal.Learner': 'Учащийся',
    'Settings.ScenarioBank.Modal.Learners': 'Учащиеся',
    'Settings.ScenarioBank.Modal.SelectSessionSize': 'Выберите размер сессии',
    'MursionPortal.SimulationContent': 'Контент для симуляции',
    'Settings.ScenarioBank.Modal.ToolTip.ThisIsTheArtContent': 'Это художественный 3D-контент со средами и аватарами, которые будут использоваться в симуляции. Содержимое этой папки отображается в разделе «Пакет контента» ниже.',
    'Settings.ScenarioBank.Modal.ThisScenarioHasNoSimulation': 'За сценарием не закреплено никакого контента для симуляции',
    'Settings.ScenarioBank.Modal.SelectSimulationContentForUse': 'Выберите нужный контент для симуляции',
    'Settings.ScenarioBank.Modal.Label.ScenarioOutcome': 'Результат сценария',
    'Settings.ScenarioBank.Modal.Placeholder.EnterAnOutcome': 'Укажите результат',
    'Settings.ScenarioBank.Modal.Description': 'Описание',
    'Settings.ScenarioBank.Modal.NoDescriptionSpecified': 'Описание не указано',
    'Settings.ScenarioBank.Modal.ScenarioDescription': 'Описание сценария',
    'Settings.ScenarioBank.Modal.AllowedCharactersLimit': 'Превышено допустимое количество символов (макс. длина — {maxLength})',
    'Settings.ScenarioBank.Modal.ScenarioStrategies': 'Стратегии сценария',
    'Settings.ScenarioBank.Modal.Placeholder.EnterAnItem': 'Укажите элемент',
    'Settings.ScenarioBank.Modal.Attachments.DragAndDrop': 'Перетащите файлы сюда или нажмите для выбора',
    'Settings.ScenarioBank.Modal.Attachments.UploadedMaximumFiles': 'Вы загрузили максимальное кол-во файлов ({maxFiles}).',
    'Settings.ScenarioBank.Modal.Attachments.UploadedFiles': 'Загруженные файлы,',
    'Settings.ScenarioBank.Modal.CertifiedSimSpecialists': 'Сертифицированные специалисты по симуляции',
    'Settings.ScenarioBank.Modal.NoCertifiedSimSpecialist': 'Нет сертифицированного специалиста по симуляции',
    'Settings.ScenarioBank.Modal.Button.Add': 'Добавить ',
    'Settings.ScenarioBank.Modal.Edit': 'редактировать',
    'Settings.ScenarioBank.Modal.AddAttachmentsToScenario': 'Добавить вложения к сценарию',
    'MursionPortal.Cancel': 'Отменить',
    'MursionPortal.OK': 'OK',
    'Settings.ScenarioBank.Modal.FileFormatsSupported': 'Поддерживаются следующие форматы файлов:',
    'Settings.ScenarioBank.Modal.Images': 'Изображения',
    'Settings.ScenarioBank.Modal.Videos': 'Видео',
    'Settings.ScenarioBank.Modal.Audios': 'Аудио',
    'Settings.ScenarioBank.Modal.Documents': 'Документы',
    'Settings.ScenarioBank.Modal.New': '(новый)',
    'Settings.ScenarioBank.Modal.FileSizeMustNotExceed': 'Макс. размер файла — {size}',
    'Settings.ScenarioBank.Modal.CertifySimSpecialists': 'Сертифицировать специалистов по симуляции',
    'MursionPortal.Confirm': 'Подтвердить',
    'MursionPortal.Delete': 'Удалить',
    'Settings.ScenarioBank.Modal.Archived': '(Архивировано)',
    'Settings.ScenarioBank.Modal.Restore': 'Восстановить',
    'Settings.ScenarioBank.Modal.ConfirmArchive': 'Подтвердить архивирование',
    'Settings.ScenarioBank.Modal.Archive': 'Архивировать',
    'Settings.ScenarioBank.Modal.ConfirmRestore': 'Подтвердить восстановление',
    'MursionPortal.PleaseWait': 'Подождите…',
    'MursionPortal.Loading': 'Загрузка…',
    'Settings.ScenarioBank.Modal.Attachments.FileTypeNotSupported': 'Файл с расширением {extensions} не поддерживается',
    'Settings.ScenarioBank.Modal.Attachments.FileTypesNotSupported': 'Файлы с расширением {extensions} не поддерживаются',
    'Settings.ScenarioBank.Modal.Attachments.FileExceedsSizeLimit': 'Размер файла {fileNames} превышает максимально допустимый',
    'Settings.ScenarioBank.Modal.Attachments.FilesExceedSizeLimit': 'Размеры файлов {fileNames} превышают максимально допустимые',
    'MursionPortal.Label.Total': 'ВСЕГО',
    'MursionPortal.Button.HoverTitle.ExportData': 'Экспорт данных',
    'MursionPortal.Button.HoverTitle.ExportNotAvailable': 'Экспорт недоступен',
    'MursionPortal.Heading.Filters': 'ФИЛЬТРЫ:',
    'Dashboard.ProjectsTable.Checkbox.OnlyBillable': 'Только оплачиваемые',
    'MursionPortal.Filters.SoftwareVersion': 'Версия ПО',
    'MursionPortal.Filters.PlaceHolder.SearchVersion': 'Поиск по версии...',
    'MursionPortal.Filters.Status': 'Статус',
    'MursionPortal.Filters.PlaceHolder.SearchStatus': 'Поиск по статусу...',
    'Dashboard.ProjectsTable.ColumnHeader.Provider': 'Поставщик',
    'Dashboard.ProjectsTable.ColumnHeader.Status': 'Статус',
    'Dashboard.ProjectsTable.ColumnHeader.StarAt': 'Начать в',
    'Dashboard.ProjectsTable.ColumnHeader.EndAt': 'Завершить в',
    'Dashboard.ProjectsTable.ColumnHeader.Billable': 'Платный',
    'Dashboard.ProjectsTable.ColumnHeader.CancellationWindow': 'Окно отмены',
    'MursionPortal.Table.ColumnHeader.Scenarios': 'Сценарии',
    'MursionPortal.Table.ColumnHeader.Teams': 'Команды',
    'MursionPortal.Table.ColumnHeader.SessionsScheduled': 'Запланированные сессии',
    'MursionPortal.Table.ColumnHeader.SessionsCompleted': 'Завершенные сессии',
    'MursionPortal.Table.ColumnHeader.SessionsMissed': 'Пропущенные сессии',
    'MursionPortal.Table.ColumnHeader.LateCancellations': 'Поздние отмены',
    'MursionPortal.Table.ColumnHeader.Learners': 'Учащиеся',
    'Dashboard.ProjectsTable.ColumnHeader.SessionsRecorded': 'Записанные сессии',
    'MursionPortal.Table.ColumnHeader.SessionsError': 'Ошибка сессии',
    'Dashboard.ProjectsTable.ColumnHeader.ContractedHours': 'Часы работы по договору',
    'Dashboard.ProjectsTable.ColumnHeader.UtilizedHours': 'Использованные часы',
    'Dashboard.ProjectsTable.ColumnHeader.RemainingHours': 'Оставшиеся часы',
    'Dashboard.ProjectsTable.ColumnHeader.CompletedHours': 'Завершенные часы',
    'Dashboard.ProjectsTable.ColumnHeader.MissedHours': 'Пропущенные часы',
    'Dashboard.ProjectsTable.HoverTitle.ViewEditProject': 'Просмотр/редактирование проекта',
    'Dashboard.ProjectsTable.EmptyMessage.NoProjectsYet': 'Проектов пока нет',
    'MursionPortal.Button.Clear': 'Очистить',
    'Dashboard.Projects.AlertMessage.DataRequestNotImplemented': 'Запрос данных пока не выполнен',
    'MursionPortal.SelectorMenu.SelectedCountOfTotal': 'Выбрано {formattedSelectedCount} из {formattedTotal}',
    'MursionPortal.Dashboard.Table.Modal.Filter.Teams': 'Команды',
    'MursionPortal.Dashboard.Table.Modal.Placeholder.EnterTeamName': 'Укажите название команды',
    'MursionPortal.Dashboard.Table.Modal.TeamsEmptyMessage': 'Команды не найдены',
    'MursionPortal.Dashboard.Table.Modal.Filter.NumberOfLearners': 'кол-во учащихся',
    'MursionPortal.Dashboard.Table.Modal.Filter.Scenarios': 'Сценарии',
    'MursionPortal.Dashboard.Table.Modal.Placeholder.EnterScenarioName': 'Укажите название сценария',
    'MursionPortal.Dashboard.Table.Modal.ScenariosEmptyMessage': 'Сценарии не найдены',
    'MursionPortal.Dashboard.Table.Modal.Filter.Name': 'Название',
    'MursionPortal.Dashboard.Table.Modal.Filter.Status': 'Статус',
    'MursionPortal.Dashboard.Table.Modal.Filter.DeliveryMode': 'Формат обучения',
    'MursionPortal.Dashboard.Table.Modal.Filter.Learners': 'Учащиеся',
    'MursionPortal.Dashboard.Table.Modal.Placeholder.EnterLearnerName': 'Укажите имя учащегося',
    'MursionPortal.Dashboard.Table.Modal.LearnersEmptyMessage': 'Учащиеся не найдены',
    'MursionPortal.Dashboard.Table.Modal.Filter.SessionsScheduled': 'Запланированные сессии',
    'MursionPortal.Dashboard.Table.Modal.SessionsEmptyMessage': 'Сессии не найдены',
    'MursionPortal.Dashboard.Table.Modal.Filter.ScenarioName': 'Название сценария',
    'MursionPortal.Dashboard.Table.Modal.Filter.SessionDateTime': 'Дата/время сессии',
    'MursionPortal.Dashboard.Table.Modal.Filter.SessionsCompleted': 'Завершенные сессии',
    'MursionPortal.Dashboard.Table.Modal.Filter.SessionsError': 'Ошибка сессии',
    'Dashboard.ProjectsActivityTable.Modal.Filter.SessionsRecorded': 'Записанные сессии',
    'MursionPortal.Dashboard.Table.Modal.Filter.SessionsMissed': 'Пропущенные сессии',
    'MursionPortal.Dashboard.Table.Modal.Filter.LateCancellations': '«Поздние» отмены',
    'MursionPortal.Dashboard.Table.Modal.Filter.CancelledBy': 'Отменено',
    'MursionPortal.Dashboard.Table.Modal.Filter.DateScheduled': 'Запланированная дата',
    'MursionPortal.Dashboard.Table.Modal.Filter.DateCancelled': 'Дата отмены',
    'MursionPortal.ProjectStatus.Active': 'Активно',
    'MursionPortal.ProjectStatus.Completed': 'Завершено',
    'MursionPortal.ProjectStatus.Archived': 'Архивировано',
    'Settings.Avatars.Modal.Name': 'Название',
    'Settings.Avatars.Modal.PlaceHolder.AvatarName': 'Имя аватара',
    'Settings.Avatars.Modal.Description': 'Описание',
    'MursionPortal.Name': 'Название',
    'MursionPortal.Description': 'Описание',
    'MursionPortal.Environments': 'Среды',
    'Settings.Avatars.TableHover.ViewEditAvatar': 'Просмотр/редактирование аватара',
    'Settings.Avatars.NoAvatarText': 'Аватаров пока нет',
    'Settings.Avatars.ModalHeader.NewAvatar': 'Новый аватар',
    'Settings.Avatars.ModalHeader.Avatar': 'Аватар',
    'Settings.Avatars.ModalHeader.EditAvatar': 'Редактировать аватар',
    'Settings.Avatars.Modal.Button.CreateAvatar': 'Создать аватар',
    'Settings.Avatars.Modal.Button.UpdateAvatar': 'Обновить аватар',
    'Settings.Avatars.Modal.ArchiveAvatarText': 'Уверены, что хотите архивировать аватар?',
    'Settings.Avatars.Modal.RestoreAvatarText': 'Уверены, что хотите восстановить аватар?',
    'Settings.Avatars.Modal.Button.Edit': 'редактировать',
    'Settings.Avatars.Modal.EditButton.Accept': 'Принять',
    'Settings.Avatars.Modal.NoSelectedEnvironments': 'Нет выбранных сред',
    'Settings.Avatars.Modal.NoEnvironmentsSetForSelectedIndustry': 'Не установлены среды для выбранной отрасли.',
    'Settings.Avatars.Button.CreateAvatar': 'Создать аватар ',
    'MursionPortal.Dashboard': 'Панель управления',
    'MursionPortal.Tutorial': 'Руководство',
    'MursionPortal.Eula': 'Пользовательское соглашение',
    'MursionPortal.Companies': 'Компании',
    'MursionPortal.SessionFlow': 'Поток сессии',
    'MursionPortal.SessionReport': 'Отчет о сессии',
    'MursionPortal.AssetSync': 'Синхронизация активов',
    'MursionPortal.Users': 'Пользователи',
    'MursionPortal.AppDownload': 'Скачать приложение',
    'MursionPortal.Calendar': 'Календарь',
    'MursionPortal.Clients': 'Клиенты',
    'MursionPortal.Sessions': 'Сессии',
    'MursionPortal.Trainings': 'Обучение',
    'MursionPortal.Requests': 'Запросы',
    'MursionPortal.Settings': 'Настройки',
    'MursionPortal.ArtDesigner': 'Art Designer',
    'MursionPortal.ArtDesigner.BodyContents': 'We\'ve opened a new browser tab with the Mursion Designer application.',
    'MursionPortal.Projects': 'Проекты',
    'MursionPortal.Teams': 'Команды',
    'MursionPortal.Reports': 'Отчеты',
    'MursionPortal.Downloads': 'Загрузки',
    'MursionPortal.Upcoming': 'предстоящие',
    'MursionPortal.Auth': 'Oauth',
    'Settings.Avatars.Modal.ErrorMessage.ItemsNotAvailable': 'Макс. кол-во элементов для выбора — {max}',
    'Settings.Avatars.Modal.ErrorMessage.ItemNotAvailable': 'Макс. кол-во элементов для выбора — {max}',
    'MursionPortal.Industry': 'Отрасль',
    'Settings.Environment.TableHover.ViewEditEnvironment': 'Просмотр/редактирование среды',
    'Settings.Environment.NoEnvironmentText': 'Сред пока нет',
    'Projects.Button.CreateProject': 'Создать проект ',
    'Projects.ListHeader.CreationDate': 'Дата создания',
    'Projects.ListHeader.ProjectManagers': 'Руководители проекта',
    'MursionPortal.Capitalized.Active': 'АКТИВНО',
    'MursionPortal.Capitalized.Completed': 'ЗАВЕРШЕНО',
    'MursionPortal.Capitalized.Archived': 'АРХИВИРОВАНО',
    'MursionPortal.Capitalized.All': 'ВСЕ',
    'Projects.ProjectCard.BackToClient': 'Вернуться к клиенту',
    'Projects.ProjectCard.NoProjectsFound': 'Проекты не найдены',
    'Projects.ProjectCard.PlaceHolder.EnterProjectName': 'Укажите название проекта',
    'Projects.ProjectCard.ProjectsList': 'СПИСОК ПРОЕКТОВ',
    'MursionPortal.Capitalized.Project': 'ПРОЕКТ',
    'MursionPortal.Scenarios': 'Сценарии',
    'Projects.ScenarioList.ThereAreNoScenarios': 'Сценариев пока нет.',
    'Projects.ScenarioList.NoScenariosFound': 'Сценарии не найдены.',
    'Projects.ScenarioList.Button.Draft': 'Черновик',
    'MursionPortal.Finalized': 'Отправлено',
    'MursionPortal.Started': 'Начато',
    'MursionPortal.Finished': 'Завершено',
    'MursionPortal.Archived': 'Архивировано',
    'MursionPortal.EnterScenarioName': 'Укажите название сценария',
    'Projects.AllProjects': 'все проекты',
    'MursionPortal.Label.LowerCase.Archived': 'архивировано',
    'MursionPortal.Label.Active': 'Активно',
    'MursionPortal.Label.Completed': 'Завершено',
    'Projects.Tab.Basic': 'Базовый',
    'MursionPortal.Heading.ProjectName': 'Название проекта',
    'MursionPortal.Label.Created': 'Автор',
    'MursionPortal.Label.Status': 'Статус',
    'MursionPortal.Label.Learners': 'Учащиеся',
    'MursionPortal.Label.LearnersCount': 'Кол-во учащихся — {count}',
    'Projects.Tab.NoLearners': 'Нет учащихся',
    'MursionPortal.Label.SoftwareVersion': 'Версия ПО',
    'Projects.ProjectInfo.Label.Non-Billable': 'Неоплачиваемые',
    'Projects.ProjectInfo.Label.Contracted': 'Договор заключен',
    'Projects.ProjectInfo.Label.CancellationWindow': 'Окно отмены',
    'MursionPortal.Header.Recording': 'Запись',
    'Projects.ProjectInfo.Checkbox.AllowRecording': 'Разрешить запись',
    'MursionPortal.Label.VideoRecording': 'Видеозапись',
    'MursionPortal.Label.Allowed': 'Разрешено',
    'MursionPortal.Label.Disallowed': 'Запрещено',
    'MursionPortal.Label.RecordedVideosGoBackTo': 'Записанные видео сохраняются на:',
    'MursionPortal.Checkbox.RecordingPermissionLiesWithLearner': 'Разрешение на запись предоставляет учащийся',
    'MursionPortal.Label.LiesWithLearner': 'Предоставляет учащийся',
    'Projects.ProjectInfo.Label.Enabled': 'Включено',
    'Projects.ProjectInfo.Label.Disabled': 'Отключено',
    'MursionPortal.Heading.ProjectManagers': 'Руководители проекта',
    'MursionPortal.Heading.Tags': 'Теги',
    'MursionPortal.Button.AreYouSureEditProject': 'Уверены, что хотите редактировать проект?',
    'MursionPortal.Button.SaveProjectInfo': 'Сохранить информацию о проекте',
    'Projects.Tab.Utilization': 'Использование',
    'MursionPortal.Label.Loading': 'Загрузка…',
    'Projects.ProjectInfo.Label.NoProjectManagers': 'Нет руководителей проекта',
    'Projects.ProjectInfo.Label.NoSelectedProjectManagers': 'Руководители проекта не выбраны',
    'MursionPortal.Label.ProjectManagers': 'Руководители проекта',
    'MursionPortal.Label.RecordingPermission': 'Разрешение на запись:',
    'Projects.Utilization.Label.UtilizedSessions': 'Использованные сессии:',
    'Projects.Utilization.Label.UtilizedMissedSessions': 'Использованные пропущенные сессии:',
    'Projects.Utilization.Label.UtilizedCancelledSessions': 'Использованные отмененные сессии:',
    'Projects.Utilization.Label.UtilizedDuration': 'Использованная продолжительность:',
    'Projects.Utilization.Label.RemainingDuration': 'Оставшаяся продолжительность:',
    'MursionPortal.ScenarioListItem.Label.Timeline': 'срок реализации',
    'MursionPortal.ScenarioListItem.Label.SessionLength': 'продолжительность сессии',
    'MursionPortal.ScenarioListItem.Label.SessionSize': 'размер сессии',
    'MursionPortal.ScenarioListItem.Label.DeliveryMode': 'формат обучения',
    'MursionPortal.ScenarioCard.BackToProject': 'Вернуться к проекту',
    'MursionPortal.ScenarioCard.NoScenariosFound': 'Сценарии не найдены',
    'MursionPortal.Placeholder.EnterScenarioName': 'Укажите название сценария',
    'MursionPortal.ScenarioCard.Label.ScenariosList': 'СПИСОК СЦЕНАРИЕВ',
    'MursionPortal.ScenarioCard.Header.Scenario': 'СЦЕНАРИЙ',
    'MursionPortal.ScenarioCard.Header.ChooseTypeOfEvent': 'Выберите тип события для планирования',
    'MursionPortal.Label.Session': 'Сессия',
    'MursionPortal.Label.Training': 'Обучение',
    'MursionPortal.Button.Confirm': 'Подтвердить',
    'MursionPortal.Button.Continue': 'Продолжить',
    'MursionPortal.Button.Schedule': 'Планировать',
    'MursionPortal.Label.Draft': 'Черновик',
    'MursionPortal.Label.Finalized': 'Отправлено',
    'MursionPortal.Label.Started': 'Начато',
    'MursionPortal.Label.Finished': 'Завершено',
    'MursionPortal.Label.Archived': 'Архивировано',
    'MursionPortal.Label.Cancel': 'отменить',
    'MursionPortal.Label.Edit': 'редактировать',
    'MursionPortal.Label.CancelEdit': 'Отменить редактирование',
    'MursionPortal.Label.EditScenario': 'Редактировать сценарий',
    'MursionPortal.Label.EditScenarioName': 'Редактировать название сценария',
    'MursionPortal.ScenarioCard.Label.ApplyNameChange': 'Применить изменение названия',
    'MursionPortal.ScenarioCard.Label.Apply': 'применить',
    'MursionPortal.ScenarioCard.Tab.Info': 'ИНФОРМАЦИЯ',
    'MursionPortal.ScenarioCard.Tab.Learners': 'УЧАЩИЕСЯ',
    'MursionPortal.ScenarioCard.Tab.Sessions': 'СЕССИИ',
    'MursionPortal.ScenarioCard.ConfirmButton.AreYouSureArchiveScenario': 'Уверены, что хотите архивировать сценарий? Все связанные данные также будут отправлены в архив.',
    'MursionPortal.ScenarioCard.ConfirmButton.ArchiveScenario': 'Архивировать сценарий',
    'MursionPortal.Label.Delivery': 'Исполнение',
    'MursionPortal.ScenarioInfo.Header.ArchivedScenario': 'АРХИВИРОВАННЫЙ СЦЕНАРИЙ',
    'MursionPortal.ScenarioInfo.Button.YouMayOnlyEditTeams': 'Вы можете редактировать команды и срок реализации, только если отправите сценарий.',
    'MursionPortal.ScenarioInfo.Button.FinalizeScenario': 'Отправить сценарий',
    'MursionPortal.ScenarioInfo.Button.SaveScenario': 'Сохранить сценарий',
    'MursionPortal.ScenarioInfo.Button.CreatePlanning': 'Создать планирование ',
    'MursionPortal.ScenarioInfo.Label.SimLength': 'Продолжительность симуляции',
    'MursionPortal.Header.Outcome': 'Результат',
    'MursionPortal.Header.Strategies': 'Стратегии',
    'MursionPortal.Header.Attachments': 'Вложения',
    'MursionPortal.ScenarioInfo.Button.SaveScenarioInfo': 'Сохранить информацию о сценарии',
    'MursionPortal.Label.ContentBundle': 'Пакет контента',
    'MursionPortal.ScenarioInfo.Button.ShowLess': 'Показать меньше',
    'MursionPortal.ScenarioInfo.Button.ShowMore': 'Показать больше',
    'MursionPortal.AssetSettings.Label.CurrentScenarioNoAssetSettings': 'В текущем сценарии нет настроек актива.',
    'MursionPortal.Teams.Label.NoSelectedTeams': 'Нет выбранных команд',
    'MursionPortal.Teams.Label.ClientHasNoAssignedTeams': 'У клиента нет назначенных команд',
    'MursionPortal.Label.Teams': 'Команды',
    'MursionPortal.Label.NoTeamsSpecified': 'Команды не указаны',
    'MursionPortal.Label.Capitalized.Draft': 'ЧЕРНОВИК',
    'MursionPortal.Label.Capitalized.Finalized': 'ОТПРАВЛЕНО',
    'MursionPortal.Label.Capitalized.Started': 'НАЧАТО',
    'MursionPortal.Label.Capitalized.Finished': 'ЗАВЕРШЕНО',
    'MursionPortal.ScenarioInfo.Tooltip.ScenarioStillInDevelopmentStage': 'Сценарий все еще в стадии разработки',
    'MursionPortal.ScenarioInfo.Tooltip.ScenarioDeployedTimelineUpcoming': 'Сценарий разработан, но сроки еще не определены',
    'MursionPortal.ScenarioInfo.Tooltip.ScenarioActiveLearnersCanSchedule': 'Сценарий активен, и учащиеся могут планировать сессии',
    'MursionPortal.ScenarioInfo.Tooltip.ScenarioIsCompleted': 'Сценарий завершен',
    'MursionPortal.Button.Add': 'Добавить ',
    'MursionPortal.Label.Warning': 'Предупреждение',
    'MursionPortal.SessionWizard.Label.UnableToCreateSession': 'Не удалось создать сессию для выбранного параметра {failedParam}.',
    'MursionPortal.SessionWizard.Label.PleaseContinueToFindAnother': 'Нажмите «Продолжить» для поиска другого параметра <code>{failedParam}</code>.',
    'MursionPortal.SessionWizard.Label.ScheduleSession': 'Запланировать сессию',
    'MursionPortal.SessionWizard.Label.ScheduleTraining': 'Запланировать обучение',
    'MursionPortal.SessionWizard.Label.AddNotes': 'Добавить примечания',
    'MursionPortal.SessionWizard.Label.SelectSimulationContentForUse': 'Выберите нужный контент для симуляции',
    'MursionPortal.SessionWizard.Label.SelectStep': 'Выберите {step}',
    'MursionPortal.SessionWizard.Label.Expired': 'срок действия истек',
    'MursionPortal.SessionWizard.ConfirmButton.AreYouSureCreateEmergencySession': 'Уверены, что хотите создать специальную сессию?',
    'MursionPortal.SessionWizard.Button.Create': 'Создать',
    'MursionPortal.Button.Next': 'Далее',
    'MursionPortal.SessionWizard.Label.RemainingTimeToBook': 'осталось для бронирования этого интервала до истечения срока хранения.',
    'MursionPortal.SessionWizard.Label.TimeBlockHoldExpired': 'Срок хранения интервала истек.',
    'MursionPortal.Label.Date': 'Дата',
    'MursionPortal.Label.Time': 'Время',
    'MursionPortal.SessionWizard.Label.TimeNoLongerAvailableClickSubmit': 'Это время больше не доступно. Хотите выбрать другой вариант? Вернитесь, чтобы выбрать доступный вариант, или нажмите «Отправить», чтобы запросить существующий.',
    'MursionPortal.SessionWizard.Label.TimeNoLongerAvailable': 'Это время больше не доступно. Хотите выбрать другой вариант? Вернитесь, чтобы выбрать доступный вариант.',
    'MursionPortal.SessionWizard.Button.GoBack': 'Вернуться',
    'MursionPortal.Button.Submit': 'Отправить',
    'MursionPortal.SessionWizard.Label.NoAvailableClients': 'Нет доступных клиентов',
    'MursionPortal.SessionWizard.Label.NoAvailableProjects': 'Нет доступных проектов',
    'MursionPortal.SessionWizard.Label.NoAvailableScenarios': 'Нет доступных сценариев',
    'MursionPortal.SessionWizard.Label.PleaseSelectScenario': 'Сначала выберите сценарий',
    'MursionPortal.SessionWizard.Label.NoImage': 'Нет изображения',
    'MursionPortal.SessionWizard.EmptyMessage.NoAvailableSImSpecialists': 'Нет доступных специалистов по симуляции',
    'MursionPortal.SessionWizard.Label.ScenarioHasNoContentBundles': 'За сценарием не закреплено никаких пакетов контента.',
    'MursionPortal.SessionWizard.Option.SelectAnAsset': 'Выберите актив',
    'MursionPortal.SimSpecialistStep.Label.NoSimSpecialistsAvailable': 'На выбранное время нет доступных специалистов по симуляции. Сессия будет закреплена по запросу.',
    'MursionPortal.SsLearnersStep.ErrorMessage.YouCanAddMaxTrainees': 'Макс. кол-во слушателей для этого типа сессии — {maxLearners}.',
    'MursionPortal.SsLearnersStep.ErrorMessage.YouCanAddMaxTrainee': 'Макс. кол-во слушателей для этого типа сессии — {maxLearners}.',
    'MursionPortal.EmptyMessage.NoUsersSelected': 'Пользователи не выбраны',
    'MursionPortal.Placeholder.Search': 'Поиск',
    'MursionPortal.SsLearnersStep.Label.NoTraineeToSelect': 'Нет слушателей для выбора',
    'MursionPortal.LearnersStep.ErrorMessage.YouCanAddMaxLearners': 'Макс. кол-во учащихся для этого типа сессии — {maxLearners}.',
    'MursionPortal.LearnersStep.ErrorMessage.YouCanAddMaxLearner': 'Макс. кол-во учащихся для этого типа сессии — {maxLearners}.',
    'MursionPortal.LearnersStep.Label.NoLearnersToSelect': 'Нет учащихся для выбора',
    'MursionPortal.LearnersStep.Label.NoUsersSelected': 'Пользователи не выбраны',
    'MursionPortal.LearnersStep.Option.AllTeams': 'Все команды',
    'MursionPortal.LearnersStep.Button.AddEntireTeam': 'Добавить всю команду',
    'MursionPortal.LearnersStep.Note.AllowedSessionSIze': 'Макс. размер сессии — {maxLearners}. Выбрать конкретных учащихся',
    'MursionPortal.LearnersStep.Checkbox.OnlyUnauthorizedUsers': 'Участие только для неавторизованных пользователей',
    'MursionPortal.NotesStep.SessionNotes': 'Примечания к сессии',
    'MursionPortal.NotesStep.ErrorMessage.AllowedLimitExceeded': 'Превышено допустимое количество символов (макс. длина — {limit})',
    'MursionPortal.Button.LoadMore': 'Загрузить еще',
    'MursionPortal.Label.Trainer': 'Преподаватель',
    'MursionPortal.Label.Description': 'Описание',
    'MursionPortal.Label.Outcome': 'Результат',
    'MursionPortal.Label.Strategies': 'Стратегии',
    'MursionPortal.Label.DeliveryMode': 'Формат обучения',
    'MursionPortal.Label.SessionLength': 'Продолжительность сессии',
    'MursionPortal.Label.NoDescriptionProvided': 'Описание не указано',
    'MursionPortal.Label.NoOutcomeProvided': 'Результат не указан',
    'MursionPortal.Label.NoStrategiesProvided': 'Стратегии не указаны',
    'MursionPortal.Placeholder.Dotted.Search': 'Поиск...',
    'MursionPortal.TimeStep.Label.NoAvailableTimeBlocks': 'Нет доступных интервалов',
    'MursionPortal.TimeStep.Label.NoAvailableTimeBlocksInRange': 'В выбранном диапазоне нет доступных интервалов',
    'MursionPortal.TimeStep.Footer.BySelectingThisTimeBlock': 'Выбирая этот интервал, вы запрашиваете недоступное на данный момент время сессии. Мы сделаем всё возможное для выполнения запроса, если вы его отправите.  В противном случае измените положение переключателя «Выкл.» ниже, чтобы выбрать интервал из списка доступных.',
    'MursionPortal.TimeStep.Tooltip.SlideTheToggleOff': 'Измените положение переключателя «Выкл.», чтобы выбрать интервал из списка доступных.',
    'MursionPortal.TimeStep.Tooltip.SlideTheToggleOn': 'Измените положение переключателя «Вкл.», чтобы запросить дополнительный интервал.',
    'MursionPortal.Label.LowerCase.Client': 'клиент',
    'MursionPortal.Label.Project': 'проект',
    'MursionPortal.Label.Scenario': 'сценарий',
    'MursionPortal.Projects.NoProjectsFound': 'Проекты не найдены.',
    'MursionPortal.Projects.YourTeamsNotAssigned': 'Ваши команды еще не назначены ни на один проект.',
    'MursionPortal.Projects.NoProjectsCreated': 'Проекты пока не созданы.',
    'MursionPortal.Teams.NoTeamsFound': 'Команды не найдены.',
    'MursionPortal.Teams.YouHaveNotBeen': 'Вас еще не добавили ни в одну команду.',
    'MursionPortal.Teams.YouHaveNoTeams': 'У вас пока нет команд.',
    'MursionPortal.Teams.NoTeamsCreated': 'Команды пока не созданы.',
    'Settings.SSO.Modal.ConfigurationName': 'Название конфигурации',
    'Settings.SSO.Modal.AuthorizationEndpoint': 'Конечная точка авторизации',
    'Settings.SSO.Modal.LogoutEndpoint': 'Конечная точка выхода',
    'Settings.SSO.Modal.ClientID': 'ID клиента',
    'Settings.SSO.Modal.ClientSecret': 'Ключ клиента',
    'Settings.SSO.Modal.Scope': 'Сфера действия',
    'Settings.SSO.Modal.UserInfoEndpoint': 'Конечная точка информации о пользователе',
    'Settings.SSO.Modal.TokenEndpoint': 'Конечная точка токена',
    'Settings.SSO.Modal.TokenHTTPMethod': 'HTTP-метод токена',
    'Settings.SSO.Modal.UserIDClaimName': 'Имя для запроса (ID пользователя)',
    'Settings.SSO.Modal.EmailClaimName': 'Имя для запроса (эл. почта)',
    'Settings.SSO.Modal.FirstNameClaimName': 'Имя для запроса (имя)',
    'Settings.SSO.Modal.LastNameClaimName': 'Имя для запроса (фамилия)',
    'Settings.SSO.Modal.LinkAllowed': 'Привязка разрешена',
    'Settings.SSO.Modal.UnlinkAllowed': 'Отмена привязки разрешена',
    'Settings.SSO.Modal.SingleLogoutEnabled': 'Единый выход из системы включен',
    'Settings.SSO.Modal.SingleSignOnServiceEndpoint': 'Конечная точка службы единого входа',
    'Settings.SSO.Modal.EntityID': 'ID организации',
    'Settings.SSO.Modal.X509Certificate': 'Сертификат X.509',
    'Settings.SSO.Button.CreateSsoConfiguration': 'Создать конфигурацию единого входа ',
    'Settings.SSO.Modal.Protocol': 'Протокол :',
    'Settings.SSO.Modal.Type': 'Тип :',
    'Settings.SSO.Modal.ID': 'ID :',
    'Settings.SSO.Modal.AssignedClients': 'Назначенные клиенты',
    'Settings.SSO.Modal.Button.DeleteSso': 'Удалить единый вход',
    'Settings.SSO.ModalHeader.EditSsoConfiguration': 'Редактировать конфигурацию единого входа',
    'Settings.SSO.ModalHeader.NewSsoConfiguration': 'Новая конфигурация единого входа',
    'Settings.SSO.Modal.Button.CreateSsoConfiguration': 'Создать конфигурацию единого входа',
    'Settings.SSO.Modal.Button.SaveSsoConfiguration': 'Сохранить конфигурацию единого входа',
    'Settings.SSO.Modal.DeletionConfirmationText': 'Уверены, что хотите удалить конфигурацию единого входа?',
    'Settings.SSO.Modal.AddButton.GroupClaimName': 'Название заявки на группу',
    'Settings.SSO.Modal.AddButton.Team': 'Команда',
    'Settings.SSO.Modal.AddButton.PlaceHolder.GroupClaimValue': 'Название заявки на группу',
    'Settings.SSO.Modal.SPInitiatedSsoUrl': 'URL-ссылка инициированного SP единого входа :',
    'Settings.SSO.Modal.Button.DownloadSPMetadata': 'Скачать метаданные SP',
    'Settings.SSO.Modal.Button.Public': 'Общедоступный',
    'Settings.SSO.Modal.Button.Private': 'Частный',
    'Settings.SSO.Modal.ConfigurationIcon': 'Значок конфигурации :',
    'Settings.SSO.Modal.PlaceHolder.EnterTeamClaimName': 'Введите имя для запроса (команда)',
    'Settings.SSO.Modal.TeamClaimName': 'Имя для запроса (команда)',
    'Settings.SSO.Modal.TeamMapping': 'Отбор команды :',
    'Settings.SSO.Modal.Button.Change': 'Изменить',
    'MursionPortal.PlaceHolder.SelectAClient': 'Выберите клиента',
    'Settings.SSO.Modal.AddButton.PlaceHolder.SelectATeam': 'Выберите команду',
    'MursionPortal.Label.Client': 'Клиент',
    'Settings.SSO.Modal.ConfirmDelete': 'Подтвердить удаление',
    'Settings.SSO.Table.ColumnHeader.ConfigurationName': 'Название конфигурации',
    'Settings.SSO.Table.ColumnHeader.Type': 'Тип',
    'Settings.SSO.Table.ColumnHeader.Protocol': 'Протокол',
    'Settings.SSO.Table.ColumnHeader.ClientsCount': 'Кол-во клиентов',
    'Settings.SSO.PlaceHolder.EnterSsoName': 'Укажите название системы единого входа',
    'Settings.SSO.Table.NoSsoSchemasFound': 'Системы единого входа не найдены.',
    'Settings.SSO.Table.HoverTitle.ViewEditSso': 'Просмотр/редактирование систем единого входа',
    'Settings.SSO.Modal.HoverTitle.CopySPInitiatedSsoUrl': 'Коприровать URL-ссылку инициированного SP единого входа:',
    'Settings.SSO.Modal.CopyButton.ModalHeader.SPInitiatedSsoUrl': 'URL-ссылка инициированного SP единого входа',
    'MursionPortal.CopyText.CopiedToClipboard': 'скопировано в буфер обмена!',
    'Settings.SSO.Text.Public': 'Общедоступный',
    'Settings.SSO.Text.Private': 'Частный',
    'Settings.SSO.Modal.NoClientsAssigned': 'Клиенты не назначены',
    'MursionPortal.Label.NotAvailable': 'не применимо',
    'Teams.Modal.Header.NewTeam': 'Новая команда',
    'MursionPortal.Button.More': 'Больше',
    'Teams.Modal.Header.AllTeams': 'все команды',
    'MursionPortal.Label.Name': 'Название:',
    'MursionPortal.Label.Location': 'Местоположение:',
    'MursionPortal.Label.Tags': 'Теги:',
    'MursionPortal.Header.Facilitators': 'Координаторы:',
    'MursionPortal.Header.Scenarios': 'Сценарии:',
    'MursionPortal.Button.SaveTeamInfo': 'Сохранить информацию о команде',
    'MursionPortal.IconButton.Cancel': 'отменить',
    'MursionPortal.IconButton.Edit': 'редактировать',
    'MursionPortal.ButtonTitle.CancelEdit': 'Отменить редактирование',
    'MursionPortal.ButtonTitle.EditTeam': 'Редактировать команду',
    'MursionPortal.ConfirmationText.RestoreTeam': 'Уверены, что хотите восстановить команду?',
    'MursionPortal.ButtonTitle.RestoreTeam': 'Восстановить команду',
    'MursionPortal.ConfirmationText.ArchiveTeam': 'Уверены, что хотите архивировать команду?',
    'MursionPortal.ButtonTitle.ArchiveTeam': 'Архивировать команду',
    'MursionPortal.EmptyMessage.NoFacilitators': 'Без координаторов',
    'MursionPortal.EmptyMessage.NoSelectedFacilitators': 'Координаторы не выбраны',
    'MursionPortal.Title.Facilitators': 'Координаторы',
    'MursionPortal.Heading.Archived': '(Архивировано)',
    'Teams.Modal.Header.AllLearners': 'Все учащиеся',
    'Teams.Modal.Placeholder.SearchLearners': 'Поиск по учащимся...',
    'Teams.Modal.EmptyMessage.NoLearnersToSelect': 'Нет учащихся для выбора',
    'Teams.Modal.Header.TeamLearners': 'Команда учащихся',
    'Teams.Modal.Placeholder.SearchTeamLearners': 'Поиск по команде учащихся...',
    'Teams.Modal.EmptyMessage.NoTeamLearnersToSelect': 'Нет команды учащихся для выбора',
    'Teams.Modal.Header.AssignLearners': 'Назначить учащихся в команду «{teamName}»',
    'Teams.AssignLearners.Modal.Button.SelectAll': 'Выбрать все',
    'Teams.AssignLearners.Modal.EmptyMessage.NoItems': 'Нет элементов для выбора',
    'MursionPortal.Header.Teams': 'Команды',
    'Teams.Modal.AlertMessage.Timezone': 'При изменении часового пояса проверьте существующие события и доступность.',
    'Teams.SelectorMenu.SelectedOf': 'Выбрано {selected} из {totalNumber}',
    'Teams.Modal.AlertMessage.ProfileTimezone': 'Часовой пояс в текущем профиле не соответствует настройкам даты и времени вашего компьютера ({updatedTimezone}).Нажмите «Сохранить изменения», чтобы продолжить, или выберите другой часовой пояс в профиле.',
    'MursionPortal.Label.Learner': 'Учащийся',
    'Teams.Modal.Button.More': 'на {overlappedAmount} больше...',
    'MursionPortal.IconButton.Archive': 'архив',
    'Teams.ToggleButton.Learners': 'Учащиеся',
    'Teams.ToggleButton.Sessions': 'Сессии',
    'MursionPortal.Status.RegistrationStatus': 'Статус регистрации',
    'MursionPortal.Status.RegistrationAccepted': 'Запрос о регистрации принят',
    'MursionPortal.Status.RegistrationNotAccepted': 'Запрос о регистрации не принят',
    'MursionPortal.SortBy': 'Сортировать по',
    'MursionPortal.Placeholder.SortBy': 'сортировать по',
    'Clients.Button.CreateClient': 'Создать клиента ',
    'Clients.CreateClient.Header.NewClient': 'Новый клиент',
    'Clients.CreateClient.Button.CreateClient': 'Создать клиента',
    'Clients.CreateClient.Label.ClientName': 'Имя клиента',
    'Clients.CreateClient.Placeholder.CityCountry': 'Город, страна',
    'MursionPortal.Timezone': 'Часовой пояс',
    'Clients.CreateClient.Label.Logo': 'Логотип',
    'Clients.Text.NoClientsFound': 'Клиенты не найдены.',
    'MursionPortal.Text.Active': 'Активно',
    'MursionPortal.Text.Archived': 'Архивировано',
    'Clients.Modal.Button.BackToClientList': 'Вернуться к списку клиентов',
    'Clients.Modal.EmptyMessage.NoClientsFound': 'Клиенты не найдены',
    'Clients.Modal.Placeholder.EnterClientName': 'Укажите имя клиента',
    'Clients.Modal.Header.ClientsList': 'СПИСОК КЛИЕНТОВ',
    'Clients.Modal.Header.Client': 'КЛИЕНТ',
    'Clients.Modal.Header.AllClients': 'все клиенты',
    'MursionPortal.Search': 'Поиск',
    'Clients.Modal.RestoreConfirmText': 'Уверены, что хотите восстановить клиента?',
    'Clients.Modal.DeleteConfirmText': 'Уверены, что хотите архивировать клиента?',
    'Clients.Modal.RelatedDataText': 'Все связанные данные также будут отправлены в архив.',
    'Clients.Modal.DeleteErrorText': 'У текущего клиента есть сценарий(-и). Перед архивированием клиента отправьте в архив сценарий(-и) проекта.',
    'Clients.Button.RestoreClient': 'Восстановить клиента',
    'Clients.IconButton.Cancel': 'отменить',
    'Clients.IconButton.Edit': 'редактировать',
    'Clients.IconButton.CancelEdit': 'Отменить редактирование',
    'Cleints.IconButton.EditScenarioName': 'Редактировать название сценария',
    'Cleints.IconButton.ApplyNameChange': 'Применить изменение названия',
    'Clients.IconButton.Apply': 'применить',
    'Clients.IconButton.EditClient': 'Редактировать клиента',
    'Clients.Button.ArchiveClient': 'Архивировать клиента',
    'Clients.Modal.Heading.Archived': '(Архивировано)',
    'Clients.Modal.Label.Name': 'Название:',
    'Clients.Modal.Placeholder.ClientName': 'Имя клиента',
    'Clients.Modal.Label.Location': 'Местоположение:',
    'Clients.Modal.Label.Industry': 'Отрасль:',
    'Clients.Modal.Placeholder.SelectIndustry': 'Выберите отрасль',
    'Clients.Modal.Label.Timezone': 'Часовой пояс:',
    'Clients.Modal.Label.SchedulingByRequest': 'Планирование по запросу:',
    'MursionPortal.Enabled': 'Включено',
    'MursionPortal.Disabled': 'Отключено',
    'Clients.Modal.Label.SchedulingWindow': 'Окно планирования:',
    'Clients.Modal.Label.HowFarInAdvance': 'Насколько заранее должны планировать клиенты',
    'MursionPortal.Days': 'дн.',
    'MursionPortal.Day': 'день',
    'Clients.Modal.Label.AvailableSharedSSOTitle': 'Список доступных общих систем единого входа',
    'Clients.Modal.Label.AvailableSharedSSOLabel': 'Доступен общий единый вход:',
    'Clients.Modal.EmptySelectedMessage': 'Система единого входа не выбрана',
    'Clients.Modal.EmptyListMessage': 'Нет доступной системы единого входа',
    'Clients.Modal.SSOTitle': 'Выберите доступную систему единого входа',
    'Cleints.Modal.Label.AvailablePrivateSSOTitle': 'Список доступных частных систем единого входа',
    'Clients.Modal.Label.AccountOwners': 'Владельцы аккаунта:',
    'Clients.Modal.AccountOwner.EmptyListMessage': 'Нет владельцев аккаунта',
    'Clients.Modal.AccountOwner.EmptySelectedMessage': 'Владелец аккаунта не выбран',
    'Clients.Modal.AccountOwnersTitle': 'Владелец(-ы) аккаунта',
    'MursionPortal.Message.Updating': 'Обновление...',
    'MursionPortal.Button.SaveClientInfo': 'Сохранить информацию о клиенте',
    'MursionPortal.Add': 'Добавить ',
    'Clients.Modal.Label.EditSSO': 'Редактировать систему единого входа',
    'Clients.Modal.Label.Edit': 'редактировать',
    'MursionPortal.NumberOfFacilitators': 'Количество координаторов',
    'MursionPortal.NumberOfLearners': 'Количество учащихся',
    'Clients.Modal.Button.CreateTeam': 'Создать команду ',
    'MursionPortal.NewTeam': 'Новая команда',
    'MursionPortal.NoTags': 'Нет тегов',
    'MursionPortal.Header.Tags': 'Теги',
    'MursionPortal.AddTag': 'Добавить тег',
    'Clients.Modal.Teams.FacilitatorsEmptyListMessage': 'Координаторы не выбраны',
    'Clients.Modal.Teams.LearnersEmptyListMessage': 'Учащиеся не выбраны',
    'MursionPortal.Learners': 'Учащиеся',
    'MursionPortal.Facilitator': 'Координатор',
    'MursionPortal.CreateTeam': 'Создать команду',
    'Clients.Modal.Teams.ConfirmationText.RestoreTeam': 'Уверены, что хотите восстановить команду?',
    'Clients.Modal.Teams.ButtonTitle.RestoreTeam': 'Восстановить команду',
    'Clients.Modal.Teams.ConfirmationText.ArchiveTeam': 'Уверены, что хотите архивировать команду?',
    'Clients.Modal.Teams.ButtonTitle.ArchiveTeam': 'Архивировать команду',
    'Clients.Modal.Projects.Header.NewProject': 'Новый проект',
    'MursionPortal.ProjectName': 'Название проекта',
    'MursionPortal.Contracted': 'Договор заключен',
    'MursionPortal.CancellationWindow': 'Окно отмены',
    'MursionPortal.SoftwareVersion': 'Версия ПО',
    'MursionPortal.Billing': 'Оплата',
    'Clients.Modal.Projects.Checkbox.NonBillable': 'Неоплачиваемый',
    'MursionPortal.Recording': 'Запись',
    'Clients.Modal.Projects.Checkbox.AllowRecording': 'Разрешить запись',
    'Clients.Modal.Projects.Label.RecordedVideos': 'Записанные видео сохраняются на:',
    'Clients.Modal.Projects.Checkbox.RecordingPermission': 'Разрешение на запись предоставляет учащийся',
    'Clients.Modal.Projects.ButtonTitle.SaveProjectInfo': 'Сохранить информацию о проекте',
    'Clients.Modal.Projects.Header.ProjectManager': 'Руководитель проекта',
    'Clients.Modal.Projects.ProjectsEmptyListMessage': 'Руководители проекта не выбраны',
    'MursionPortal.Header.Bracketed.Scenarios': 'Сценарий(-и)',
    'Clients.Modal.Projects.Text.CompleteProjectInfoFirst': 'Сначала укажите информацию о проекте',
    'MursionPortal.Header.CreateScenario': 'Создать сценарий',
    'Clients.Modal.Projects.ConfirmationText.RestoreProject': 'Уверены, что хотите восстановить проект?',
    'Clients.Modal.Projects.ButtonTitle.RestoreProject': 'Восстановить проект',
    'Clients.Modal.Projects.ButtonTitle.Edit': 'Редактировать проект',
    'Clients.Modal.Projects.ButtonTitle.CancelEdit': 'Отменить редактирование',
    'Clients.Modal.Label.Cancel': 'отменить',
    'Clients.Modal.Projects.ConfirmationText.ArchiveProject': 'Уверены, что хотите архивировать проект?',
    'Clients.Modal.Projects.ButtonTitle.ArchiveProject': 'Архивировать проект',
    'Clients.Modal.Projects.ArchiveErrorMessage': 'В текущем проекте есть активный сценарий(-и). Перед архивированием проекта отправьте в архив его сценарий(-и).',
    'Clients.Modal.Projects.ButtonTitle.EditTags': 'Редактировать теги',
    'Clients.Modal.Projects.Label.UtilizedMissedSessions': 'Использованные пропущенные сессии,',
    'Clients.Modal.Projects.Button.CreateNew': 'Создать новый ',
    'Clients.Modal.Projects.Button.RepeatClient': 'Дублировать клиента',
    'Clients.Modal.Projects.Button.RepeatBank': 'Дублировать банк',
    'Clients.Modal.Projects.ButtonTitle.CreateNewScenario': 'Создать новый сценарий',
    'Clients.Modal.Projects.ButtonTitle.RepeatClientScenario': 'Дублировать сценарий клиента',
    'Clients.Modal.Projects.ButtonTitle.ScenarioBank': 'Создать сценарий из банка сценариев',
    'Clients.Modal.Button.Schedule': 'Планировать',
    'MursionPortal.Button.ActivateScenario': 'Активировать сценарий',
    'MursionPortal.Button.ChangeScenario': 'Изменить сценарий',
    'Clients.IconButton.Confirm': 'подтвердить',
    'Clients.IconButton.ConfirmEdit': 'Подтвердите редактирование',
    'Clients.Modal.Projects.HeaderText.ChooseScenario': 'Выберите сценарий для дублирования',
    'Clients.Modal.Projects.HeaderText.ChooseScenarioBank': 'Выбрать сценарий из банка сценариев',
    'MursionPortal.Placeholder.LowerCase.EnterScenarioName': 'Укажите название сценария',
    'MursionPortal.IconButton.ResetToDefault': 'Вернуть название по умолчанию',
    'MursionPortal.IconButton.Reset': 'сбросить',
    'Clients.Modal.Header.CreatePlanning': 'Создать планирование для нового сценария',
    'Clients.Modal.EmptyMessage.NoClientScenariosFound': 'Сценарии клиента не найдены.',
    'Clients.Modal.EmptyMessage.NoBankScenariosFound': 'Сценарии в банке не найдены.',
    'Clients.Modal.Message.NoTeamsSpecified': 'Команды не указаны',
    'Clients.Modal.EmptyMessage.NoSelectedTeams': 'Нет выбранных команд',
    'Clients.Modal.Message.NoAssignedTeams': 'У клиента нет назначенных команд',
    'Clients.Modal.Label.Certificate': 'Сертификат',
    'Clients.Modal.Label.DeliveryMode': 'Формат обучения',
    'Clients.Modal.Label.SessionSize': 'Размер сессии',
    'Clients.Modal.Label.SessionLength': 'Продолжительность сессии',
    'Clients.Modal.Label.Outcome': 'Результат',
    'Clients.Modal.Label.Strategies': 'Стратегии',
    'Clients.Modal.Label.Description': 'Описание',
    'MursionPortal.Button.MoreInfo': 'Подробнее...',
    'MursionPortal.Button.HideAdditionalInfo': 'Скрыть дополнительные сведения...',
    'MursionPortal.Header.Timeline': 'Срок реализации',
    'MursionPortal.Header.Projects': 'Проекты ',
    'MursionPortal.Header.Sessions': 'Сессии',
    'MursionPortal.Header.Users': 'Пользователи',
    'MursionPortal.Text.Yes': 'да',
    'MursionPortal.Text.No': 'нет',
    'MursionPortal.Button.Google': 'Google',
    'MursionPortal.Button.Outlook': 'Outlook, iCal, другое',
    'MursionPortal.Heading.GoogleCalendar': 'Календарь Google ',
    'MursionPortal.Heading.AddToCalendar': 'Добавить в календарь ',
    'Dashboard.Legacy.GoogleCalendarList.Authorized': 'Если вы еще этого не сделали, вам будет предложено <code>авторизовать</code> <code>портал {companyName}</code> для добавления событий в ваш календарь.',
    'Dashboard.Legacy.GoogleCalendarList.EventAdd': 'Событие будет <code>добавлено в ваш календарь</code>. Вы сможете получить к нему доступ на портале {companyName}.',
    'MursionPortal.Heading.OtherCalendars': 'Другие календари',
    'Dashboard.Legacy.OtherCalendarList.Downloaded': 'Ваше событие будет <code>загружено</code> как <code>{companyName}</code>',
    'Dashboard.Legacy.OtherCalendarList.SelectCalendar': '<code>Нажмите правую кнопку мыши</code>, выберите <code>«Открыть с помощью»</code>, а потом — приложение календаря.',
    'Dashboard.Legacy.OtherCalendarList.ImportWithinCalendar': '(Вы также можете выбрать «Импорт» в своем календаре и найти загруженный файл.)',
    'Dashboard.Legacy.OtherCalendarList.AddedToCalendar': 'Событие будет <code>добавлено в ваш календарь</code>. Вы сможете получить к нему доступ на портале {companyName}.',
    'MursionPortal.Text.NoLearnersAddedYet': 'Учащиеся пока не добавлены',
    'MursionPortal.Heading.Schedule': 'Планировать',
    'MursionPortal.Heading.Users': 'ПОЛЬЗОВАТЕЛИ',
    'MursionPortal.Heading.ProfServices': 'Проф. услуги',
    'MursionPortal.Heading.SimSpecialist': 'Специалисты по симуляции',
    'MursionPortal.Heading.Clients': 'Клиенты',
    'MursionPortal.Heading.Sessions': 'Сессии',
    'MursionPortal.Heading.Projects': 'Проекты',
    'MursionPortal.Heading.Teams': 'Команды',
    'MyMessages.Button.ArchiveAll': 'Архивировать все',
    'MyMessages.Button.ArchivedMessages': 'Архивированные сообщения',
    'MyMessages.Button.ShowMore': 'Показать больше...',
    'MyMessages.Label.NewMessages': 'Количество новых сообщений — <code>{newMessagesCount}</code>',
    'MyMessages.Label.NewMessage': '<code>{newMessagesCount}</code> новое сообщение',
    'MyMessages.HoverTitle.InboxButton': 'кнопка «Входящие»',
    'MyMessages.Text.InboxButton': 'Кнопка «Входящие»',
    'MyMessages.Text.HaveNewMessages': 'Количество новых сообщений — {total}',
    'MyMessages.Text.HaveNewMessage': 'У вас новое сообщение',
    'MursionPortal.CookiePolicy.Header.CookiePolicy': 'Политика в отношении файлов cookie',
    'MursionPortal.CookiePolicy.Text.MursionUsesEssentialCookiesOnly': 'Mursion использует только основные файлы cookie, которые необходимы для вашей безопасности и работы нашего веб-приложения. Мы не используем файлы cookie для сбора каких-либо персональных данных или другой информации, такой как IP-адреса или использование сайта.',
    'MursionPortal.CookiePolicy.Text.ForMoreInformation': ' Для получения дополнительной информации ознакомьтесь с <code>Политикой конфиденциальности</code>',
    'MursionPortal.Button.Ok': 'ОК',
    'MyProfile.Button.SaveChanges': 'Сохранить изменения',
    'MyProfile.Label.ExternalProfiles': 'Внешние профили',
    'MyProfile.Label.UserRoles': 'Роли пользователя',
    'MyProfile.Label.UserRole': 'Роль пользователя',
    'MyProfile.Heading.ReviewAccountInformation': 'Проверьте информацию аккаунта',
    'MyProfile.HoverTitle.UnlinkProfile': 'Отменить привязку профиля {linkName}',
    'MyProfile.HoverTitle.LinkProfile': 'Привязать профиль {linkName}',
    'MyProfile.Button.Unlink': 'Отменить привязку',
    'MyProfile.Text.UnlinkConfirmationText': 'Уверены, что хотите отменить привязку профиля {name}? Обратите внимание, что вы также выйдете из аккаунта {name}.',
    'MyProfile.Title.ProfileUnlink': 'Отмена привязки профиля {name}',
    'MyProfile.Text.SuccessfullyUnlinkedProfile': 'Вы отменили привязку профиля {name}',
    'MyProfile.Text.TimeZoneIsSetAutomatically': 'Часовой пояс автоматически установлен на {getTimeZone}. Нажмите «Подтвердить» в случае согласия или «Отменить», если хотите изменить выбор.',
    'MyProfile.Text.TimeZoneChange': 'При изменении часового пояса проверьте существующие события и доступность.',
    'MyProfile.Text.ProfileTimeZoneNotMatchComputer': 'Часовой пояс в текущем профиле не соответствует настройкам даты и времени вашего компьютера ({timeZone}).Нажмите «Сохранить изменения», чтобы продолжить, или выберите другой часовой пояс в профиле.',
    'MursionPortal.Button.MyProfile': 'Мой профиль',
    'MursionPortal.Button.LogOut': 'Выйти',
    'MyProfile.Label.Language': 'Язык',
    'MursionPortal.Label.Roles': 'Роли',
    'MursionPortal.Label.Role': 'Роль',
    'MursionPortal.Projects.Header.ScenarioType': 'Тип сценария',
    'MursionPortal.Projects.Header.Client': 'Клиент',
    'MursionPortal.Projects.Header.Project': 'Проект',
    'MursionPortal.Projects.Header.Scenario': 'Сценарий',
    'MursionPortal.Projects.Header.SimulationContent': 'Контент для симуляции',
    'MursionPortal.Projects.Header.Date': 'Дата',
    'MursionPortal.Projects.Header.Time': 'Время',
    'MursionPortal.Projects.Header.Notes': 'Примечания',
    'Filters.Date.Week': 'Неделя',
    'Users.Table.Modal.SignLanguageInterpreter': 'Переводчик языка жестов',
    'Calendar.Button.Schedule': 'Расписание',
    'Calendar.Button.Today': 'Сегодня',
    'MursionPortal.Availability': 'Доступность',
    'Calendar.Button.Schedule.QualityAssurance': 'Обеспечение качества',
    'MursionPortal.Training': 'Обучение',
    'Calendar.Button.Schedule.EmergencySession': 'Экстренная сессия',
    'Calendar.Button.Schedule.Session': 'Сессия',
    'Calendar.Button.Schedule.BreakPrep': 'Перерыв/подготовка',
    'MursionPortal.Month': 'Месяц',
    'MursionPortal.Week': 'Неделя',
    'Calendar.Heading.Calendars': 'Календари',
    'Calendar.Tip.SelectACalendar': 'Выберите календарь, чтобы посмотреть доступные сессии',
    'Calendar.ScheduledHours.SumOfAllScheduledSessions': 'Сумма всех запланированных сессий в текущем представлении',
    'MursionPortal.ShortLabel.Hours': 'ч.',
    'Calendar.ScheduledHours.SumOfAllTrainingSessions': 'Сумма всех запланированных обучающих сессий в текущем представлении',
    'Calendar.ScheduledHours.Trainings': 'Обучение',
    'Calendar.ScheduledHours.SumOfRemainingAvailabilityHours': 'Сумма всех оставшихся свободных часов СС в текущем представлении',
    'Calendar.ScheduledHours.Available': 'Доступен',
    'MursionPortal.Scheduled': 'Запланировано',
    'Calendar.Label.Display': 'Отобразить',
    'MursionPortal.Client': 'Клиент',
    'Calendar.Filter.Company': 'Компания',
    'Calendar.Filter.SwapRequests': 'Запросы на замену',
    'MursionPortal.QA': 'QA',
    'Calendar.SearchFilter.FilterProjects': 'Фильтр по проектам',
    'Calendar.SearchFilter.FilterClients': 'Фильтр по клиентам',
    'Calendar.SearchFilter.FilterSimSpecialists': 'Фильтр по специалистам по симуляции',
    'Calendar.IndustrySelector.Options.SelectAnIndustry': 'Выберите отрасль',
    'Calendar.IndustrySelector.Options.AllIndustries': 'Все отрасли',
    'Calendar.IndustrySelector.Options.NoIndustriesToSelect': 'Нет отраслей для выбора',
    'MursionPortal.SelectAClient': 'Выберите сертификат',
    'Calendar.ClientSelector.Options.AllClients': 'Все клиенты',
    'Calendar.ClientSelector.Options.NoClientToSelect': 'Нет клиентов для выбора',
    'MursionPortal.SelectACertificate': 'Выберите сертификат',
    'Calendar.CertificateSelector.Options.AllCertificates': 'Все сертификаты',
    'Calendar.CertificateSelector.Options.NoCertificatesToSelect': 'Нет сертификатов для выбора',
    'Calendar.ClientsList.NoClientsFound': 'Клиенты не найдены.',
    'MursionPortal.UncheckAll': 'Очистить всё',
    'MursionPortal.CheckAll': 'Выбрать всё',
    'Calendar.SimSpecialistList.NoSimSpecialistsFound': 'Специалисты по симуляции не найдены',
    'Calendar.ProjectsList.CloseProjectOverview': 'Закрыть обзор проекта',
    'Calendar.ProjectsList.AllProjects': 'ВСЕ ПРОЕКТЫ',
    'MursionPortal.Bracketed.Scenarios': 'Сценарий (-и)',
    'Calendar.ProjectsList.Overview': 'Обзор',
    'Calendar.ProjectsList.CloseClientOverview': 'закрыть обзор клиента',
    'MursionPortal.Scenario': 'сценарий',
    'Calendar.ScenariosList.NoScenarios': 'Нет сценариев',
    'MursionPortal.Delivery': 'Обучение',
    'MursionPortal.Mode': 'Формат',
    'MursionPortal.Timeline': 'Срок реализации',
    'Calendar.ScenariosList.Description': 'Описание',
    'MursionPortal.Outcome': 'Результат',
    'MursionPortal.Strategies': 'Стратегии',
    'MursionPortal.ContentBundle': 'Пакет контента',
    'MursionPortal.SimulationEnvironments': 'Среда(-ы) симуляции',
    'MursionPortal.AvatarsInSimulations': 'Аватар(-ы) в симуляции(-ях)',
    'Calendar.LearnersList.SessionNotScheduled': 'Сессия не запланирована',
    'Calendar.LearnersList.SessionScheduled': 'Сессия запланирована',
    'Calendar.LearnersList.SessionComplete': 'Сессия завершена',
    'Calendar.LearnersList.Learner': '{learnersCount} учащийся',
    'Calendar.LearnersList.Learners': 'Количество учащихся: {learnersCount}',
    'Calendar.Button.CantSchedule': 'Не удается запланировать',
    'Calendar.Button.ScheduleEvent': 'Запланировать событие',
    'Calendar.MainPanel.ConfirmText.AreYouSure': 'Вы уверены, что хотите отменить это событие?',
    'Calendar.MainPanel.CancelEvent': 'Отменить событие',
    'Calendar.MainPanel.UpdateEvent': 'Обновить событие',
    'MursionPortal.Submit': 'Отправить',
    'Calendar.MainPanel.NewEvent': 'Новое событие',
    'MursionPortal.Project': 'Проект',
    'MursionPortal.Button.Reload': 'Перезагрузить',
    'Clients.Modal.Label.FacilitatorVisibility': 'Представление координатора:',
    'Clients.Modal.Placeholder.FacilitatorVisibility': 'Выберите представление координатора',
    'Clients.Modal.Projects.ConfirmationText.EditProject': 'Уверены, что хотите редактировать этот проект?',
    'Projects.ScenarioList.Legend.ScenariosStatusTab': 'Вкладки статуса сценариев',
    'Projects.ScenarioList.Legend.CreateNew': 'Создать новый, Дублировать клиента, Дублировать банк кнопки сценария',
    'MursionPortal.ScenarioCard.Tab.Legend.ViewModeTab': 'Вкладка режима просмотра',
    'MursionPortal.SearchPlaceHolder.SearchClient': 'Поиск клиента...',
    'Settings.SSO.Modal.AddButton.SearchPlaceHolder.SearchTeam': 'Выбрать команду...',
    'Settings.SSO.Modal.Button.ChangeLabel': 'Изменить {label}',
    'MursionPortal.Label.Yes': 'Да',
    'MursionPortal.Label.No': 'Нет',
    'Settings.SSO.Modal.AddButton.PlaceHolder.NoOptions': 'Нет вариантов',
    'Settings.SSO.Modal.ClientName': 'Имя клиента',
    'MursionPortal.Modal.Button.Add': 'Добавить',
    'MursionPortal.Modal.Button.Remove': 'Удалить',
    'Users.Table.Hover.ViewEditAcceptedUsers': 'Просмотр/редактирование подтвержденных пользователей',
    'Users.Table.Hover.ViewEditUnacceptedUsers': 'Просмотр/редактирование неподтвержденных пользователей',
    'MursionPortal.Label.Industry': 'Отрасль',
    'MursionPortal.Label.Timezone': 'Часовой пояс',
    'MursionPortal.Header.Client': 'Клиент',
    'MursionPortal.FilterByCertificate': 'Фильтр по сертификату',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Header.CreateQAEvent': 'Создать событие QA',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Label.TimeBlock': 'Интервал(ы)',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Label.Participants': 'Участники',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Button.SubmitNewEvent': 'Создать новое событие',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Error.TimeBlockOutsideRange': 'Интервал находится за пределами доступного диапазона',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Error.TimeBlockBeforeCurrentTime': 'Интервал находится раньше текущего времени',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Label.Start': 'Начать',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Label.End': 'Завершение',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Label.Length': 'Продолжительность',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Error.NoAvailableTimeBlock': 'Для выбранного дня нет доступного интервала',
    'Calendar.Modal.Button.Client': 'Клиент',
    'Calendar.Modal.Button.Project': 'Проект',
    'Calendar.Modal.Button.Scenario': 'Сценарий',
    'Calendar.Modal.Header.CompanyEvents': 'События компании',
    'MursionPortal.Header.Session': 'Сессия',
    'MursionPortal.Header.Events': 'События',
    'MursionPortal.Header.Event': 'Событие',
    'Calendar.Modal.Label.Time': 'Время',
    'Calendar.Label.SelectTimeZone': 'Выберите часовой пояс',
    'Calendar.Text.Training': 'Обучение',
    'Calendar.Text.QA': ' QA',
    'Calendar.Modal.Text.ConfirmDeleteTime': 'Подтвердить удаление интервала',
    'Calendar.Button.NewTimeBlock': 'Новый интервал',
    'Calendar.Modal.Label.Capitalized.Time': 'ВРЕМЯ',
    'Calendar.Modal.Label.Participants': 'УЧАСТНИКИ',
    'Calendar.Modal.Text.NoParticipants': 'Нет участников',
    'Calendar.Modal.Label.SimulationSpecialist': 'СПЕЦИАЛИСТ ПО СИМУЛЯЦИИ',
    'Calendar.Modal.Label.MeetingOrganizer': 'ОРГАНИЗАТОР ВСТРЕЧИ',
    'Calendar.Error.ErrorPrefixMustBeBetween': '{errorPrefix} должно быть от {duration1} до {duration2}.',
    'Calendar.Error.SelectedTimeBlockOverlapsExistingTime': 'Выбранный интервал пересекается с существующим интервалом.',
    'Calendar.Error.SelectedTimeBlockOverlapsUnavailableTime': 'Выбранный интервал пересекается с недоступным временным интервалом.',
    'Calendar.Error.SelectedTimeBlockIsNotMultipleOfEventGap': 'Выбранный интервал не является произведением интервалов между событиями ({duration})',
    'Calendar.Text.AvailabilityUser': 'Доступность {user}',
    'Calendar.Text.AvailabilityTime': 'Доступность {time}',
    'Calendar.Text.AvailabilityDurationAvailabilityTime': '{availabilityDuration} Доступность {time}',
    'Calendar.Text.TrainingsTime': 'Обучение {time}: {eventsCount}',
    'Calendar.Text.SessionsTime': 'Сессии {time}: {eventsCount}',
    'Calendar.Text.AvailabilityName': 'Доступность {name}',
    'Calendar.Label.Learners': 'Учащиеся',
    'Calendar.Text.FullAvailability': 'Полная доступность',
    'Calendar.Text.YourAvailability': 'Ваша доступность',
    'Calendar.Text.Event': 'Событие',
    'Calendar.Header.Trainings': 'Обучение',
    'Calendar.Header.CompanyEvents': 'События компании',
    'Calendar.Header.Session': 'Сессия',
    'Calendar.Error.TimeBlockIsOutsideAvailableRange': 'Интервал находится за пределами доступного диапазона',
    'Calendar.Error.TimeBlockIsBeforeCurrentTime': 'Интервал находится раньше текущего времени',
    'Calendar.Label.Break': 'Перерыв',
    'Calendar.Label.Prep': 'Подготовка',
    'Calendar.Modal.Header.CreateBreakPrepEvent': 'Создать событие перерыва/подготовки',
    'Calendar.Modal.Label.EventType': 'Тип события',
    'Calendar.Modal.Label.TimeBlocks': 'Интервал(ы)',
    'Calendar.Text.NoTimeBlocksInSelectedRange': 'В выбранном диапазоне нет доступных интервалов',
    'Calendar.Text.NoAvailableTimeBlocks': 'Нет доступных интервалов',
    'Calendar.Text.SelectingThisTimeBlock': 'Выбирая этот интервал, вы запрашиваете недоступное на данный момент время сессии. Мы сделаем всё возможное для выполнения запроса, если вы его отправите.  В противном случае измените положение переключателя «Выкл.» ниже, чтобы выбрать интервал из списка доступных.',
    'Calendar.ToolTip.SlideToggleOff': 'Измените положение переключателя «Выкл.», чтобы выбрать интервал из списка доступных.',
    'Calendar.ToolTip.SlideToggleOn': 'Измените положение переключателя «Вкл.», чтобы запросить дополнительный интервал.',
    'Calendar.Label.SelectAnyTimeBlock': 'Выберите любой интервал',
    'Users.CreateUser.Label.GoogleMeetID': 'ID Google Meet',
    'Users.CreateUser.GoogleMeetId.Placeholder.GoogleMeetID': 'ID Google Meet',
    'Users.CreateUser.GoogleMeetID.Hover': 'Введите ID Google Meet',
    'Setting.Edit.Modal.Error.UnableToCreateSession': 'Не удалось создать сессию на выбранное время {formattedStartTime} – {formattedEndTime}',
    'Session.Header.Session': 'Сессия',
    'Session.Header.AcceptSessionRequest': 'Подтвердите, что вы хотите принять этот запрос сессии и добавить ее в свое расписание.',
    'CommonComponent.RecurrenceForm.Label.UntilDate': 'Дата окончания',
    'CommonComponent.RecurrenceForm.Label.Period': 'Период',
    'CommonComponent.RecurrenceForm.Option.Daily': 'Ежедневно',
    'CommonComponent.RecurrenceForm.Option.Weekly': 'Еженедельно',
    'CommonComponent.RecurrenceForm.Label.Weekday': 'День недели',
    'CommonComponent.RecurrenceForm.Text.NoSelectedWeekdays': 'Дни недели не выбраны',
    'CommonComponent.RecurrenceForm.Error.PleaseSelectWeekdays': 'Выберите день/дни недели',
    'Calendar.EventType.Availability': 'Доступность',
    'Calendar.EventType.Session': 'Сессия',
    'Calendar.EventType.Training': 'Обучение',
    'Calendar.EventType.CompanyEvent': 'Событие компании',
    'Calendar.EventType.NewEvent': 'Новое событие',
    'Calendar.Text.NewEventTime': 'Новое событие {time}',
    'Calendar.Header.Training': 'Обучение',
    'Calendar.Modal.Text.Sessions': 'Сессии',
    'Calendar.Modal.Text.Trainings': 'Обучение',
    'Calendar.Modal.Text.Events': 'События',
    'Calendar.Modal.Text.PendingNoSS': '— В ожидании (без СС).',
    'Calendar.Modal.Text.PendingSwap': '— В ожидании (замена).',
    'Calendar.Modal.Text.PreBooked': '— Забронировано заранее.',
    'Calendar.Modal.Text.NeedsReview': '— Требуется проверка.',
    'Calendar.Modal.Text.JoinSession': '— вы можете присоединиться к сессии.',
    'Calendar.Modal.Text.NoLearnersInSession': '— на сессии нет учащихся.',
    'Calendar.Modal.Text.NoLearnersInSessionAfterBookedTime': '— после назначенного времени на сессии нет учащихся.',
    'Calendar.Modal.Header.Events': 'События',
    'Calendar.Modal.Header.Session': 'сессия',
    'Calendar.Modal.Header.Training': 'обучение',
    'Calendar.Modal.Header.Availability': 'доступность',
    'Calendar.Modal.Filter.Client': 'Клиент',
    'Calendar.Modal.Filter.Project': 'Проект',
    'Calendar.Modal.Filter.Time': 'Время',
    'Calendar.Modal.Label.Recurrence': 'Повторение',
    'Calendar.Modal.Header.CreateAvailability': 'Создать доступность',
    'Calendar.Modal.Text.Recurring': 'Повторяющееся',
    'Calendar.SimSpecialistButton.Modal.Header.Scheduled': 'Запланировано',
    'Calendar.SimSpecialistButton.Modal.Header.ThisPeriod': '(этот период)',
    'Calendar.SimSpecialistButton.Modal.Label.Sessions': 'Сессии',
    'Calendar.SimSpecialistButton.Modal.Label.Hours': 'Часы',
    'Calendar.SimSpecialistButton.Modal.Label.Trainings': 'Обучение',
    'Calendar.SimSpecialistButton.Modal.Label.TrainingsHours': 'Часы обучения',
    'Calendar.SimSpecialistButton.Modal.Label.Available': 'Доступен',
    'Calendar.SimSpecialistButton.Modal.Header.Certification': 'Сертификаты',
    'Calendar.SimSpecialistButton.Modal.Text.NoCertificates': 'Сертификатов нет',
    'Calendar.SimSpecialistButton.Modal.Header.SimSpecialistOverviewPanel': 'Обзорная панель специалиста по симуляции',
    'Calendar.SimSpecialistButton.Modal.HoverTitle.CloseSimSpecialistOverview': 'закрыть обзор специалиста по симуляции',
    'MursionPortal.DeliveryMode.OneToOne': '1:1',
    'MursionPortal.DeliveryMode.OneToManyRemote': 'Удаленно',
    'MursionPortal.DeliveryMode.OneToManyLocal': 'Местное (Fishbowl)',
    'MursionPortal.DeliveryMode.Group': 'Групповое обучение',
    'MursionPortal.Projects.Header.Trainees': 'Слушатели',
    'Projects.Placeholder.SimContentName': 'Введите название контента симуляции',
    'Projects.Message.NoSimulationContentFound': 'Контент симуляции не найден',
    'MursionPortal.Button.UncheckAll': 'Очистить всё',
    'MursionPortal.Button.CheckAll': 'Выбрать всё',
    'Projects.Header.NewScenario': 'Проект {projectName}',
    'MursionPortal.Button.SaveAsDraft': 'Сохранить как черновик',
    'MursionPortal.Button.Save': 'Сохранить',
    'Projects.Message.SelectEnvironmentFirst': 'Сначала выберите среду',
    'Projects.Message.LoadingAvatarsList': 'Загрузка списка аватаров... ',
    'Projects.Message.NoAssignedAvatars': 'За выбранной средой не закреплено никаких аватаров',
    'Projetcs.EmptyMessage.NoSelectedAvatars': 'Нет выбранных аватаров',
    'Projects.Avatars.NoAvatarsSpecified': 'Аватары не указаны',
    'Projects.Message.SelectIndustryFirst': 'Сначала выберите отрасль',
    'Projects.Message.LoadingEnvironmentsList': 'Загрузка списка сред... ',
    'Projects.Message.NoAssignedEnvironments': 'За выбранной отраслью не закреплено никаких сред',
    'Projects.Message.ScenarioHasNoAssignedEnvironment': 'За сценарием не закреплено никакой среды',
    'Projects.AltText.EnvironmentImage': 'изображение-среды',
    'Projects.Placeholder.SelectAnEnviroment': 'Выберите среду',
    'Projects.Message.UnnamedIndustry': 'Безымянная отрасль',
    'Projects.Placeholder.SelectAnIndustry': 'Выберите отрасль',
    'Projects.Header.NewProject': 'Новый проект',
    'Projects.AltText.ClientLogo': 'Логотип клиента',
    'Projects.Message.SelectAnyTimeBlock': 'Выберите любой интервал',
    'Projects.Message.SelectScenarioFirst': 'Сначала выберите сценарий',
    'MursionPortal.Text.Asset': 'Актив V2',
    'MursionPortal.Button.Accept': 'Принять',
    'Projects.Modal.EmptyMessage.NoLearner': 'Нет учащегося',
    'MursionPortal.Label.SelectAll': 'Выбрать все',
    'Projects.Modal.Placeholder.ChooseATeam': 'Выберите команду',
    'MursionPortal.Placeholder.SearchTeams': 'Поиск по командам…',
    'Projects.Modal.Text.SessionSize': 'Макс. размер сессии — {maxLearners}. Выберите конкретных учащихся',
    'Projects.Modal.Placeholder.SearchLearners': 'Поиск по учащимся...',
    'MursionPortal.ErrorMessage.MaxLearners': 'Макс. кол-во учащихся для этого типа сессии — {maxLearners}.',
    'MursionPortal.ErrorMessage.MaxLearner': 'Макс. кол-во учащихся для этого типа сессии — {maxLearners}.',
    'MursionPortal.Label.ChooseMembers': 'Выберите участников',
    'MursionPortal.Message.NoMembersToSelect': 'Нет участников для выбора',
    'MursionPortal.Message.PleaseSelectATeamFirst': 'Сначала выберите команду',
    'AssetSync.Header.AssetSync': 'Синхронизация активов',
    'AssetSync.Text.AssetsSyncedSuccessfully': 'Активы успешно синхронизированы',
    'AssetSync.Button.Sync': 'Синхронизация',
    'AssetSync.Button.TryAgain': 'Повторите попытку',
    'AssetSync.SyncButton.Text.Syncing': 'Выполняется синхронизация',
    'SessionFlow.Label.SessionExport': 'Экспорт сессии',
    'MursionPortal.Button.Export': 'Экспорт',
    'SessionFlow.Label.SessionFlowData': 'Данные потока сессии',
    'SessionFlow.Button.LoadSessionData': 'Загрузить данные сессии',
    'SessionFlow.Button.StartSessionAnalytics': 'Начать аналитику сессии',
    'SessionFlow.Label.Checklist': 'Контрольный список',
    'SessionFlow.Label.RecordingSettings': 'Настройки записи',
    'SessionFlow.Label.SessionVideoFact': 'Видеофакт сессии',
    'SessionFlow.Label.UserStatus': 'Статус пользователя',
    'SessionFlow.SessionVideoFact.ColumnHeader.VideoAllowed': 'Видео разрешено',
    'MursionPortal.Table.ColumnHeader.UserName': 'Имя пользователя',
    'MursionPortal.Table.ColumnHeader.Email': 'Электронная почта',
    'MursionPortal.Table.ColumnHeader.Status': 'Статус',
    'SessionFlow.StartSessionAnalyticsButton.Text.EventStartingAnalytics': 'Событие для запуска процесса аналитики успешно опубликовано',
    'SessionFlow.RecordingSettings.Label.VideoRecording': 'Видеозапись',
    'SessionFlow.RecordingSettings.Text.Allowed': 'Разрешено',
    'SessionFlow.RecordingSettings.Text.Disallowed': 'Запрещено',
    'SessionFlow.RecordingSettings.Label.RecordingPermission': 'Разрешение на запись',
    'SessionFlow.RecordingSettings.Text.LiesWithLearner': 'Предоставляет учащийся',
    'SessionFlow.RecordingSettings.Label.RecordedVideosGoBackTo': 'Записанные видео сохраняются на',
    'SessionFlow.Error.CannotFindSession': 'Не удается найти сессию с предоставленным ID',
    'SessionFlow.DetailedEventLogButton.Table.ColumnHeader.Type': 'Тип',
    'SessionFlow.DetailedEventLogButton.Table.ColumnHeader.Date': 'Дата',
    'SessionFlow.DetailedEventLogButton.Table.ColumnHeader.Error': 'Ошибка',
    'SessionFlow.DetailedEventLogButton.Table.ColumnHeader.Payload': 'Полезная нагрузка',
    'SessionFlow.SessionFlowData.PlaceHolder.SessionID': 'ID сессии',
    'SessionFlow.Button.HideDetailedEventLog': 'Скрыть подробный журнал событий... ',
    'SessionFlow.Button.ShowDetailedEventLog': 'Показать подробный журнал событий... ',
    'SessionFlow.Button.HideSessionHistory': 'Скрыть историю сесссий... ',
    'SessionFlow.Button.ShowSessionHistory': 'Показать историю сессий... ',
    'SessionFlow.Text.NoEvents': 'Нет событий',
    'SessionFlow.SessionVideoFact.Label.VideoExists': 'Видео существует',
    'SessionFlow.Text.NoLearners': 'Нет учащихся',
    'SessionFlow.Checklist.DataRecording': 'запись данных',
    'SessionFlow.Checklist.DataUploading': 'загрузка данных',
    'SessionFlow.Checklist.Analytics': 'аналитика',
    'Companies.ArchiveIconButton.Modal.ConfirmationText.ArchiveCompany': 'Вы уверены, что хотите архивировать эту компанию?',
    'Companies.ArchiveIconButton.Modal.Header.ArchiveCompany': 'Архивировать компанию',
    'Companies.Label.CompanyInfo': 'Информация о компании',
    'Companies.CompanyInfo.Archived': '(Архивировано)',
    'Companies.CancelIconButton.HoverTitle.CancelEdit': 'Отменить редактирование',
    'Companies.EditIconButton.HoverTitle.EditCompany': 'Редактировать компанию',
    'Companies.Button.InviteProfService': 'Пригласить проф. услуги',
    'Companies.InviteProfServiceButton.Modal.Header.NewProfService': 'Новые проф. услуги',
    'Companies.Label.Config': 'КОНФИГУРАЦИЯ',
    'Companies.EditIconButton.HoverTitle.EditConfig': 'Редактировать конфигурацию',
    'Companies.Config.Button.Test': 'Тест',
    'Companies.Config.Button.SaveCompanyConfig': 'Сохранить конфигурацию компании',
    'Companies.CompanyInfo.Button.SaveCompanyInfo': 'Сохранить информацию о компании',
    'Companies.ValidationError': 'Ошибки при валидации',
    'Companies.Config.SaveCompanyConfigButton.FailedToSaveConfig': 'Не удалось сохранить конфигурацию. Исправьте ошибки.',
    'Companies.Config.Text.TestPassedSuccessfully': 'Тест успешно пройден',
    'Companies.CreateUserButton.PlaceHolder.CompanyName': 'Наименование компании',
    'Companies.Button.CreateCompany': 'Создать компанию ',
    'Companies.Error.NoCompanies': 'Нет компаний',
    'Companies.InviteProfServiceButton.Modal.Label.ProfServices': 'Проф. услуги',
    'Companies.InviteProfServiceButton.Modal.Button.AddUser': 'Добавить пользователя',
    'Companies.CreateUserButton.Modal.Header.NewCompany': 'Новая компания',
    'Companies.Header.AllCompanies': 'все компании',
    'MursionPortal.Roles': 'Роли',
    'Users.ArchiveButton.Modal.Header.ArchivedGlobalUser': 'Архивированный глобальный пользователь',
    'Users.ActiveButton.Modal.Header.EditGlobalUser': 'Редактировать глобального пользователя',
    'Users.CreateUserButton.Modal.Header.NewGlobalUser': 'Новый глобальный пользователь',
    'Users.NoGlobalUserFoundError': 'Глобальные пользователи не найдены.',
    'Users.Table.ColumnHeader.Department': 'Подразделение',
    'MursionPortal.TechSupport.HoverTitle.ShowFeedBack': 'показать отзыв',
    'MursionPortal.TechSupport.HoverTitle.HideFeedBack': 'скрыть отзыв',
    'MursionPortal.TechSupport': 'Тех. поддержка',
    'MursionPortal.TechSupport.Header.ContactSupport': 'Связаться со службой поддержки',
    'MursionPortal.TechSupport.Label.Phone': 'Телефон',
    'MursionPortal.TechSupport.Label.Email': 'Электронная почта',
    'MursionPortal.TechSupport.Label.Hours': 'Часы',
    'MursionPortal.TechSupport.Link.MursionSupport': 'Поддержка Mursion',
    'Downloads.Text.ClickDownloadButton': 'Нажмите на кнопку загрузки ниже, чтобы получить последнюю версию приложения. Этот исполняемый файл будет сохранен в папку загрузок браузера по умолчанию (например, в папку «Загрузки»).',
    'Downloads.Text.OnceDownloadIsComplete': 'После завершения загрузки нажмите на загруженный исполняемый файл и следуйте инструкциям на экране для завершения установки.',
    'Downloads.Text.AppInstalled': 'После установки приложения, чтобы запустить его, нажмите  дважды на значок Mursion.',
    'Downloads.Button.Download': 'Загрузка',
    'Downloads.Text.PlatformNotCurrentlySupported': 'К сожалению, ваша платформа в настоящее время не поддерживается.',
    'Downloads.Text.FollowOnScreenInstructions': 'Нажмите {info} и следуйте инструкциям на экране, чтобы установить приложение',
    'Downloads.Text.ShortcutCreatedOnInstallationComplete': 'После завершения установки на рабочем столе будет создан ярлык Mursion. {lineBreak} Нажмите дважды на {image} ярлык, чтобы запустить приложение и принять участие в запланированной симуляции.',
    'Filters.Date.LastWeek': 'Прошлая неделя',
    'MursionPortal.Text.Mins': 'мин.',
    'MursionPortal.Text.Min': 'мин.',
    'Tutorial.Header.ManageYourTeams': 'Управляйте своими командами',
    'MursionPortal.Button.Awesome': 'Потрясающе',
    'Tutorial.Header.ReviewYourProjects': 'Просмотрите свои проекты',
    'MursionPortal.Button.LooksGood': 'Отлично выглядит',
    'Tutorial.Header.LearnerScheduling': 'Расписание учащихся',
    'MursionPortal.Button.AllDone': 'Все готово!',
    'Tutorial.Header.InviteYourUsers': 'Пригласите своих пользователей',
    'MursionPortal.Button.GotIt': 'Понятно',
    'Tutorial.Header.CreateATeam': 'Создайте команду',
    'Tutorial.Header.ManageYourProjects': 'Управляйте своими проектами',
    'Tutorial.Header.YourTeams': 'Ваши команды',
    'Tutorial.Header.YourProjects': 'Ваши проекты',
    'Tutorial.Header.SessionCalendar': 'Календарь сессий',
    'Tutorial.Tooltip.TeamsOrganize': 'Команды организуют ваших учащихся, и они будут добавлены в сценарии',
    'Tutorial.Text.ViewSessions': 'Посмотрите <code>предстоящие и завершенные</code> сессии команды',
    'Tutorial.Tooltip.ContactManager': 'или обратитесь к своему менеджеру проекта {companyName}',
    'Tutorial.Tooltip.YourPointOfContact': 'Ваше контактное лицо по вопросам обучения',
    'Tutorial.Text.TeamAdded': 'Команда будет <code>добавлена к сценарию,</code> чтобы начать составление расписания',
    'Tutorial.Text.ViewLearners': 'Посмотрите <code>учебное расписание</code> и <code>статус учащихся</code>',
    'Tutorial.Text.EnterEmail': 'Укажите <code>адрес электронной почты</code> для каждого нового {companyName} пользователя портала',
    'Tutorial.Text.RegistrationEmail': '<code>Регистрационное письмо</code> будет отправлено на указанный адрес',
    'Tutorial.Text.TeamsOrganize': 'Команды <code>организуют ваших учащихся</code> и находятся под управлением координатора',
    'Tutorial.Text.UniqueName': 'Дайте своей команде <code>уникальное название</code> и укажите местоположение',
    'Tutorial.Text.SessionDelivery': 'Позже команда будет <code>добавлена к сценарию</code>  для <code>обучения на сессии</code>',
    'Tutorial.Tooltip.Description': 'Описание, результат и стратегии',
    'Tutorial.Text.BeginScheduling': 'Команда будет <code>добавлена к сценарию,</code> чтобы начать составление расписания',
    'Tutorial.Text.MonitorSessions': 'Следите за <code>предстоящими сессиями</code> и <code>доступностью</code> каждого проекта',
    'Tutorial.Text.LearnersWithoutSessions': 'Посмотрите, какие учащиеся <code>еще не запланировали</code> сессию',
    'Tutorial.Tooltip.LearnersSchedule': 'Учащиеся тоже могут составлять свое расписание',
    'Tutorial.Text.AddedToLearners': 'Вы будете добавлены в <code>одну или несколько</code> команд учащихся',
    'Tutorial.Tooltip.TeamAdmin': 'Администратор вашей команды и контактное лицо',
    'Tutorial.Text.TeamAddedBeforeProject': 'Ваша команда будет <code>добавлена в сценарий</code> до начала проекта',
    'Tutorial.Text.AddRemoveLearners': '<code>Добавляйте или удаляйте</code> учащихся из ваших <tooltip>команд</tooltip>',
    'Tutorial.Text.AddedByCompany': 'Владелец <tooltip>аккаунта вашей компании</tooltip> добавит вас в сценарий/проект',
    'Tutorial.Text.ViewYourHours': 'Просмотрите свои часы работы и сценарии или свяжитесь с <tooltip><code>менеджером проекта</code></tooltip>',
    'Tutorial.Text.ScenariosDetail': 'Сценарии содержат <tooltip>детали</tooltip>, которые определяют ваш <code>{companyName} опыт</code>',
    'Tutorial.Text.SelectTimeSlot': 'Выберите открытый временной интервал, чтобы <tooltip><code>назначить учащегося</code></tooltip> на сессию',
    'Tutorial.Text.ChooseRole': 'Выберите роль (<tooltip>владелец аккаунта</tooltip> <secondTooltip>координатор</secondTooltip> или <thirdTooltip>учащийся</thirdTooltip>) для каждого нового пользователя',
    'Tutorial.Tooltip.AdminManages': 'Администратор, который управляет пользователями и проектами',
    'Tutorial.Tooltip.ManagesScheduling': 'Управляет расписанием и исполнением сессии',
    'Tutorial.Tooltip.SchedulesSessions': 'Планирует и посещает сессии',
    'Tutorial.Text.ScenariosContain': 'Сценарии содержат <tooltip>детали</tooltip>, которые определяют ваш <code>{companyName} опыт</code>',
    'Tutorial.Text.SelectOpenTimeSlot': 'Выберите открытый временной интервал, чтобы <tooltip><code>составить расписание учащегося</code></tooltip>',
    'Tutorial.Text.AdminOfTeam': '<tooltip>Координатор</tooltip> является администратором и контактным лицом для вашей команды',
    'Tutorial.Text.ProjectsContain': 'Проекты содержат <code>сценарии</code>с <tooltip>деталями,</tooltip> которые определяют ваш <code>опыт обучения</code>',
    'Tutorial.Text.ViewAvailability': 'Посмотрите доступность и <code>запланируйте сессии</code>',
    'Tutorial.Text.TrackSessions': 'Следите за <code>предстоящими сессиями</code> для каждого проекта. Отменяйте/переносите при необходимости',
    'Tutorial.Text.ReviewPerformance': 'Проверяйте свою <code>работу</code> и <code>следование стратегиям</code> после сессии',
    'Tutorial.Header.Complete': 'Завершено',
    'Tutorial.Text.RevisitThisGuide': 'Вы можете посмотреть это руководство и другую полезную информацию {lineBreak} <code>в любое время</code> в <code>Базе знаний. </code>',
    'Tutorial.Button.TakeMeToThePortal': 'К порталу',
    'Tutorial.Header.DownloadInstallMursionSimulator': 'Скачайте и установите {lineBreak} Mursion Simulator',
    'Tutorial.Text.PlanningToUseMursionOnComputer': 'Если вы планируете использовать этот компьютер для запуска симуляций Mursion, настоятельно рекомендуем сразу установить необходимое программное обеспечение, нажав на кнопку «Установить Mursion» ниже.',
    'Tutorial.Text.InstallSoftwareFromPortal': 'Вы можете установить программное обеспечение позже прямо с портала. Учтите, что при первом использовании установка иммерсивного симулятора Mursion может занять несколько минут. Начните установку программного обеспечения как минимум за 30 минут до начала симуляции, чтобы у вас было достаточно времени, чтобы полностью насладиться работой с Mursion.',
    'Tutorial.Text.ProvideInstructionsForInstallation': 'С определенной периодичностью мы будем предоставлять инструкции по установке и остальным процессам с помощью электронных писем и учебных пособий на портале и симуляторе Mursion. Мы надеемся, что вам понравится использовать симуляторы так же, как нам нравится их создавать!',
    'Tutorial.Text.TheMursionTeam': 'Команда Mursion.',
    'Tutorial.Button.InstallLater': 'Установить позже',
    'Tutorial.Button.InstallMursion': 'Установить Mursion',
    'Tutorial.Header.LetsGetStarted': 'Начнем!',
    'Tutorial.Text.ShortIntro': 'Прежде всего, перед вами <code>небольшое введение</code>, в котором мы расскажем о некоторых {lineBreak} общих функциях <code>вашей роли пользователя</code>.',
    'Tutorial.Button.Proceed': 'Продолжить',
    'Tutorial.Label.Step': 'ШАГ',
    'Tutorial.Label.NoSound': 'У видео нет звука.',
    'Tutorial.Button.SkipTutorial': 'Пропустить обучение',
    'Tutorial.Button.CloseTutorial': 'Закрыть обучение',
    'MursionPortal.Text.On': 'Включить',
    'MursionPortal.Text.Off': 'Выключить',
    'MursionPortal.Text.MursionIcon': 'значок-mursion',
    'MursionPortal.AriaLabel.CreateNew': 'создать новый',
    'MursionPortal.Legend.VisibilityHidden.Buttons': 'Создать новый, Дублировать клиента, Дублировать банк кнопки сценария',
    'MursionPortal.Label.VisibilityHidden.EnterEmailId': 'Введите email ID',
    'MursionPortal.Label.VisibilityHidden.SelectRegistrationStatus': 'Выберите статус регистрации',
    'MursionPortal.Label.VisibilityHidden.ClientLogoUpload': 'загрузка логотипа клиента',
    'MursionPortal.Header.VisibilityHidden.CompanyUsers': 'Пользователи компании',
    'MursionPortal.Legend.VisibilityHidden.StationType': 'Тип станции',
    'MursionPortal.Legend.VisibilityHidden.Hours': 'Часы',
    'MursionPortal.Caption.VisibilityHidden.CompanyUsersList': 'список пользователей компании',
    'MursionPortal.AriaLabel.Search': 'поиск',
    'MursionPortal.AriaLabel.From': 'От',
    'MursionPortal.AriaLabel.To': 'До',
    'MursionPortal.AriaLabel.RemoveAccountOwner': 'удалить владельца аккаунта',
    'MursionPortal.AriaLabel.InboxMessagePanel': 'панель входящих сообщений',
    'MursionPortal.AriaLabel.Breadcrumb': 'навигационная цепочка',
    'MursionPortal.Message.NoItemsHaveBeenFound': 'Элементы не найдены.',
    'MursionPortal.AriaLabel.Add': 'добавить',
    'MursionPortal.AriaLabel.SortByDescendingOrder': 'сортировать по убыванию',
    'MursionPortal.AriaLabel.SortByAscendingOrder': 'сортировать по возрастанию',
    'MursionPortal.AriaLabel.AddButton': 'добавить кнопку',
    'MursionPortal.AriaLabel.Timezone': 'часовой пояс',
    'MursionPortal.AriaLabel.Combobox': 'комбинированный список',
    'MursionPortal.AriaLabel.PreviousMonth': 'предыдущий месяц',
    'MursionPortal.AriaLabel.NextMonth': 'следующий месяц',
    'MursionPortal.AriaLabel.SearchInput': 'поле ввода',
    'MursionPortal.AriaLabel.Informational': 'Информационный',
    'MursionPortal.Text.RedirectToCalendarPage': 'перенаправить на страницу календаря',
    'MursionPortal.AriaLabel.StartDate': 'дата начала {startFormattedDate}',
    'MursionPortal.AriaLabel.EndDate': 'дата окончания {endFormattedDate}',
    'MursionPortal.AriaLabel.CreateProject': 'создать проект',
    'MursionPortal.AriaLabel.ContentInfo': 'информация о контенте',
    'MursionPortal.AriaLabel.DraftSuperScript': 'Черновой надстрочный индекс {statusConfiguration}',
    'MursionPortal.AriaLabel.FinalizedSuperScript': 'Окончательный надстрочный индекс {statusConfiguration}',
    'MursionPortal.AriaLabel.StartedSuperScript': 'Начальный надстрочный индекс {statusConfiguration}',
    'MursionPortal.AriaLabel.FinishedSuperScript': 'Законченный надстрочный индекс {statusConfiguration}',
    'MursionPortal.AriaLabel.ArchivedSuperScript': 'Архивированный надстрочный индекс {statusConfiguration}',
    'MursionPortal.AriaLabel.EnterScenarioName': 'укажите название сценария',
    'MursionPortal.AriaLabel.Capitalized.Search': 'Поиск',
    'MursionPortal.AriaLabel.Close': 'Закрыть',
    'MursionPortal.AriaLabel.CreateTeam': 'создать команду',
    'MursionPortal.AriaLabel.CreateClient': 'создать клиента',
    'MursionPortal.AriaLabel.CreateUser': 'создать пользователя',
    'MursionPortal.AriaLabel.ZoomId': 'ID в Zoom',
    'MursionPortal.AriaLabel.PrimaryContent': 'Основной контент',
    'MursionPortal.AriaLabel.RegistrationStatus': 'статус регистрации',
    'MursionPortal.AriaLabel.EnterTagName': 'введите название тега',
    'MursionPortal.AriaLabel.EnterUserName': 'введите имя пользователя',
    'MursionPortal.AriaLabel.SearchProjectManagers': 'поиск менеджеров проекта',
    'MursionPortal.AriaLabel.SearchSSO': 'поиск системы единого входа',
    'MursionPortal.AriaLabel.SearchAccountOwner': 'поиск владельца аккаунта',
    'MursionPortal.WarningMessage.AllowedCharacterLimit': 'Превышено допустимое количество символов ({maximumLength})',
    'MursionPortal.WarningMessage.InvalidCharactersDetected': 'Обнаружены недопустимые символы',
    'MursionPortal.Days.Sunday': 'Воскресенье',
    'MursionPortal.Days.Monday': 'Понедельник',
    'MursionPortal.Days.Tuesday': 'Вторник',
    'MursionPortal.Days.Wednesday': 'Среда',
    'MursionPortal.Days.Thursday': 'Четверг',
    'MursionPortal.Days.Friday': 'Пятница',
    'MursionPortal.Days.Saturday': 'Суббота',
    'MursionPortal.Button.AddNew': 'Добавить новый',
    'MursionPortal.Title.EditNotes': 'Редактировать заметки',
    'MursionPortal.IconButton.Remove': 'удалить',
    'MursionPortal.Filter.ProviderAll': 'Поставщик',
    'MursionPortal.Placeholder.SearchProviders': 'Поиск поставщиков... ',
    'MursionPortal.AriaLabel.InboxButton': 'кнопка «Входящие» У вас новые сообщения ({messageCount})',
    'MursionPortal.AriaLabel.Event': 'событие',
    'MursionPortal.Title.SelectedDate': 'Выбранная дата',
    'MursionPortal.Title.CurrentDate': 'Текущая дата',
    'MursionPortal.Title.OffRangeDate': 'дата выхода за пределы диапазона',
    'MursionPortal.Label.ClientLevel': 'Уровень-клиента',
    'MursionPortal.Label.TeamLevel': 'Уровень-команды',
    'MursionPortal.AltText.UserImage': 'изображение-пользователя',
    'MursionPortal.AltText.KbMenu': 'kb-меню',
    'MursionPortal.AltText.AvatarImage': 'изображение-аватара',
    'MursionPortal.AltText.EnvironmentImage': 'изображение-среды',
    'MursionPortal.AriaLabel.Cancel': 'отменить',
    'MursionPortal.AriaLabel.Edit': 'редактировать',
    'MursionPortal.AriaLabel.EditAccountOwner': 'изменить владельца аккаунта',
    'MursionPortal.AriaLabel.Remove': 'удалить',
    'MursionPortal.AriaLabel.SearchFilter': 'параметры поиска',
    'MursionPortal.AriaLabel.TierCombobox': 'комбинированный список',
    'MursionPortal.AriaLabel.SortByAscending': 'сортировать по возрастанию',
    'MursionPortal.AriaLabel.SortByDescending': 'сортировать по убыванию',
    'MursionPortal.Message.VisibilityHidden.SelectingDeliveryMode': 'выбор формата обучения позволит установить продолжительность и размер сессии',
    'MursionPortal.AltText.DownloadComputerIcon': 'Значок загрузки Mursion для компьютера',
    'MursionPortal.AltText.DownloadIcon': 'Значок загрузки Mursion',
    'MursionPortal.AltText.MursionLogo': 'Логотип Mursion',
    'MursionPortal.AriaLabel.MursionSupport': 'поддержка Mursion',
    'MursionPortal.AltText.MursionDashboard': 'Mursion-панель управления',
    'MursionPortal.AltText.BackgroundMaleUser': 'Фоновый пользователь Mursion мужского пола',
    'MursionPortal.AltText.BackgroundGroupUser': 'Фоновая группа пользователей Mursion',
    'MursionPortal.AltText.BackgroundFemaleUser': 'Фоновый пользователь Mursion женского пола',
    'MursionPortal.ErrorMessage.CharacterLimitExceeded': 'Превышено допустимое количество символов ({maximumLength})',
    'MursionPortal.ErrorMessage.InvalidCharactersDetected': 'Обнаружены недопустимые символы',
    'MursionPortal.ErrorMessage.EnterValidPhoneNumber': 'Укажите действующий номер телефона.',
    'MursionPortal.Message.InvitationLinkInvalid': 'Ваша пригласительная ссылка недействительна или срок ее действия истек. {lineBreak} Если вы еще не завершили регистрацию {lineBreak}, обратитесь к своему менеджеру за повторным приглашением.',
    'MursionPortal.Header.SetYourPassword': 'Введите свой пароль',
    'MursionPortal.Message.PasswordMessage': 'Ваш пароль должен содержать следующее:',
    'MursionPortal.Label.NewPassword': 'Новый пароль',
    'MursionPortal.Label.RepeatPassword': 'Повторите пароль',
    'MursionPortal.Button.SetPassword': 'Установить пароль',
    'MursionPortal.Header.ErrorMessage.SomethingWentWrong': 'Что-то пошло {lineBreak} не так. Обратитесь {lineBreak} в службу поддержки',
    'MursionPortal.Header.ContactSupport': 'СВЯЗАТЬСЯ СО СЛУЖБОЙ ПОДДЕРЖКИ',
    'MursionPortal.Label.Phone': 'Телефон',
    'MursionPortal.Label.Email': 'Электронная почта',
    'MursionPortal.Label.Hours': 'Часы',
    'MursionPortal.Message.EnterPasswordToConfirm': 'Введите пароль для подтверждения изменения',
    'MursionPortal.Label.Password': 'Пароль',
    'MursionPortal.Message.BrowserDialog': 'Если вы не видите диалоговое окно браузера, нажмите <code>«Открыть Mursion»</code> ниже {lineBreak} или откройте приложение Mursion с помощью ярлыка Mursion на рабочем столе.',
    'MursionPortal.Button.OpenMursion': 'Открыть ML3 Desktop App',
    'MursionPortal.Message.FollowInstructions': 'Нажмите {appName} и следуйте инструкциям на экране, чтобы установить приложение',
    'MursionPortal.Text.PositiveImpact': 'Когда вы почувствуете, что оказываете положительное влияние, нажмите на кнопку <code>{icon}</code>.',
    'MursionPortal.Text.NegativeImpact': 'Когда вы почувствуете, что оказываете отрицательное влияние, нажмите на кнопку <code>{icon}</code>.',
    'MursionPortal.Text.NeutralImpact': 'Когда вы почувствуете, что не оказываете ни положительного, ни отрицательного влияния, нажмите на кнопку <code>{icon}</code>.',
    'MursionPortal.Title.Next': 'Далее',
    'MursionPortal.Title.Start': 'Начать',
    'MursionPortal.Text.UpdateYourRating': 'Во время просмотра видео вы можете обновить свой рейтинг в любой момент, если почувствуете, что ваше влияние на аватар изменилось.',
    'MursionPortal.Text.SeeCurrentRating': 'График под видео обновится, и вы сможете посмотреть свой текущий рейтинг и изменить его, если считаете, что ваше влияние изменилось.',
    'MursionPortal.Text.ClickStartToBegin': 'Нажмите «Начать», когда вы готовы. Вы можете нажать «Завершить» после того, как оцените всю симуляцию. Обратите внимание, что функция поиска видео может быть отключена во время части этого видео для сохранения целостности данных.',
    'MursionPortal.Header.HowWouldYouProceed': 'Как бы вы хотели продолжить?',
    'MursionPoral.Button.SwitchUser': 'Поменять пользователя',
    'MursionPortal.Button.ContinueAs': 'Продолжить как <code>{userName}</code>',
    'MursionPortal.Text.ToContinue': 'Чтобы продолжить как <code>{loginData}</code>, нажмите <code>«Сменить пользователя»</code>.',
    'MursionPortal.Message.YouAreSignedIn': 'Вы вошли в систему как <code>{userName}</code> ({userProfile}).',
    'MursionPortal.Title.ContinueAs': 'Продолжить как {userName}',
    'MursionPortal.Message.OpenLink': 'Открыть ссылку',
    'MursionPortal.Message.Allow': 'Разрешить',
    'MursionPortal.Message.Open': 'Открыть',
    'MursionPortal.Message.Yes': 'Да',
    'MursionPortal.Message.OpenMursionApp': 'Откройте MursionApp',
    'MursionPortal.PasswordRequirementMessage.MinimumCharacters': 'Не менее {PASSWORD_MIN_LENGTH} символов',
    'MursionPortal.Message.PortalHeading': 'Создавайте, планируйте и управляйте своими',
    'MursionPortal.PasswordRequirementMessage.UppercaseLetters': 'Заглавные буквы + Строчные буквы',
    'MursionPortal.PasswordRequirementMessage.Numbers': 'любая комбинация чисел',
    'MursionPortal.ErrorMessage.InvalidEmailFormat': 'Недопустимый формат электронной почты.',
    'MursionPortal.ErrorMessage.EmailCanNotBeEmpty': 'Электронная почта не может быть пустой.',
    'MursionPortal.ErrorMessage.EnterValidEmailAddress': 'Введите действительный адрес электронной почты.',
    'MursionPortal.ErrorMessage.PasswordCannotBeEmpty': 'Пароль не может быть пустым',
    'MursionPortal.ErrorMessage.UrlCannotBeEmpty': 'URL не может быть пустым',
    'MursionPortal.ErrorMessage.UrlIsNotValid': 'URL недействителен',
    'MursionPortal.ErrorMessage.NoMeetingId': 'Не содержит ID встречи',
    'MursionPortal.ValidationErrorMessage.FileRequestFailed': 'Ошибка запроса файла',
    'MursionPortal.ValidationErrorMessage.FileExceedsLimit': 'Файл превышает допустимый размер 5 Мб',
    'MursionPortal.ValidationErrorMessage.EnterMessage': 'Введите сообщение перед отправкой',
    'MursionPortal.ValidationErrorMessage.MessageTooLong': 'Сообщение слишком длинное',
    'MursionPortal.ValidationErrorMessage.InvalidUrl': 'URL файла недействителен',
    'MursionPortal.Button.SubmitFeedback': 'Отправить отзыв',
    'MursionPortal.Header.Feedback': 'Отзыв',
    'MursionPortal.Text.AdditionalComments': 'Дополнительные комментарии или предложения',
    'MursionPortal.Header.ThanksForFeedback': 'Благодарим за ваш отзыв!',
    'MursionPortal.Button.Close': 'Закрыть',
    'MursionPortal.Title.ShowFeedback': 'показать отзыв',
    'MursionPortal.Title.HideFeedback': 'скрыть отзыв',
    'MursionPortal.ErrorMessage.FileTypeNotSupported': 'Тип файла {type} не поддерживается',
    'MursionPortal.ErrorMessage.ScenarioDuration': 'Продолжительность сценария должна быть от {durationMinimum} до {durationMaximum}.',
    'MursionPortal.ErrorMessage.ScenarioScheduledSessions': 'Сессии, запланированные для этого сценария: {sessionsCount}. Отмените эти сессии перед архивированием сценария.',
    'MursionPortal.ErrorMessage.ScenarioScheduledSession': 'Для этого сценария запланирована {sessionsCount} сессия. Отмените эту сессию перед архивированием сценария.',
    'MursionPortal.ErrorMessage.ClientScheduledSessions': 'Сессии, запланированные для этого клиента: {sessionsCount}. Отмените эти сессии перед архивированием клиента.',
    'MursionPortal.ErrorMessage.ClientScheduledSession': 'Для этого клиента запланирована {sessionsCount} сессия. Отмените эту сессию перед архивированием клиента.',
    'MursionPortal.EulaAgreement.EndUserAgreement': 'Настоящее Соглашение с конечным пользователем («Соглашение») является юридическим соглашением между Вами, пользователем, и компанией Mursion, Inc. («Mursion») и подчиняется положениям и условиям Соглашения о предоставлении Программного обеспечения Mursion в качестве услуги между Mursion и организацией-клиентом Mursion, через которую Вы используете Программное обеспечение Mursion. В целях ясности Вы признаете и соглашаетесь, что в качестве условия Вашего использования и доступа к Программному обеспечению Mursion («Программное обеспечение Mursion») Вы, а также организация, предоставляющая Вам доступ к Программному обеспечению Mursion, подчиняетесь и связаны условиями настоящего Соглашения.',
    'MursionPortal.EulaAgreement.TermsAndConditions': 'В соответствии с положениями и условиями настоящего Соглашения и Соглашения о предоставлении Программного обеспечения Mursion в качестве услуги, компания Mursion настоящим предоставляет Вам ограниченные, неисключительные, непередаваемые и не подлежащие сублицензированию права на доступ и использование Программного обеспечения Mursion.',
    'MursionPortal.EulaAgreement.AgreeAndAcknowledge': 'Вы соглашаетесь и признаете, что не имеете права и не будете копировать, изменять, перепроектировать, декомпилировать, дизассемблировать или создавать производные продукты, основанные, полностью или частично, на Программном обеспечении Mursion или связанных с ним материалах. Вы соглашаетесь не нарушать, не отключать и не обходить любые механизмы защиты, связанные с Программным обеспечением Mursion. Узнав о любом несанкционированном владении, использовании или доступе к Программному обеспечению Mursion, Вы незамедлительно уведомите Mursion, и Вы также соглашаетесь незамедлительно предоставлять подробную информацию о каждом таком случае, оказывать разумное содействие по предотвращению его повторения (насколько это возможно) и полностью сотрудничать в любых судебных разбирательствах, предпринятых для защиты прав компании Mursion.',
    'MursionPortal.EulaAgreement.ConstruedAsGranting': 'За исключением случаев, прямо указанных в настоящем Соглашении, ничто, содержащееся в настоящем Соглашении, не должно толковаться как предоставление, косвенно или иным образом, любых дополнительных прав на интеллектуальную собственность компании Mursion без предварительного письменного разрешения компании Mursion.',
    'MursionPortal.EulaAgreement.NonDisclosure': 'Вы соглашаетесь не показывать и не раскрывать Программное обеспечение Mursion или любую его часть кому бы то ни было для любых целей, кроме тех, которые прямо разрешены в настоящем документе.',
    'MursionPortal.EulaAgreement.AgreeAndRelease': 'Вы соглашаетесь и настоящим обязуетесь освобождать от обвинений, снимать ответственность и иным образом защищать компанию Mursion, ее сотрудников, должностных лиц, агентов, аффилированных лиц, руководителей и директоров от любых претензий, известных и неизвестных, когда бы они ни возникали, которые являются результатом, вытекают из или иным образом связаны с использованием Вами Программного обеспечения Mursion.',
    'MursionPortal.EulaAgreement.UnderstandAndAcknowledge': 'Вы понимаете и признаете, что компания Mursion может собирать, поддерживать, использовать, хранить и анализировать информацию и данные, собранные в ходе Вашего использования Программного обеспечения Mursion, в соответствии с условиями политики конфиденциальности Mursion, доступной по ссылке.',
    'MursionPortal.EulaAgreement.AccessRights': 'Права на использование и доступ, предоставленные Вам компанией Mursion, прекращают свое действие немедленно после истечения срока действия Соглашения о предоставлении Программного обеспечения Mursion в качестве услуги или после нарушения Вами любого положения данного Соглашения.',
    'MursionPortal.EulaAgreement.AgreementGoverned': 'Настоящее Соглашение регулируется и толкуется в соответствии с законодательством штата Делавэр без учета коллизионных норм данного штата. Все споры, возникающие в связи с использованием Вами Программного обеспечения Mursion, или настоящего Соглашения, или связанные с ним, рассматриваются в государственных и федеральных судах компетентной юрисдикции в штате Делавэр, и вы настоящим даете согласие на личную юрисдикцию таких судов.',
    'MursionPortal.EulaAgreement.ProvisionsDeclaredInvalid': 'Если какое-либо из положений настоящего Соглашения становится недействительным, оно исключается из Соглашения, а остальные положения Соглашения продолжают иметь полную юридическую силу.',
    'MursionPortal.EulaAgreement.AgreementConflict': 'В случае возникновения противоречий между настоящим Соглашением и Соглашением о предоставлении Программного обеспечения Mursion в качестве услуги условия настоящего Соглашения имеют преимущественную силу.',
    'MursionPortal.EulaAgreement.AccessToMursionSofware': 'Используя или обладая доступом к Программному обеспечению Mursion, Вы подтверждает свое согласие с настоящим Соглашением и изложенными в нем условиями.',
    'MursionPortal.Header.MursionUserAgreement': 'Mursion и Пользовательское соглашение',
    'MursionPortal.Button.Back': 'Назад',
    'MursionPortal.Button.Skip': 'Пропустить',
    'MursionPortal.Title.BackToSessions': 'Назад к сессиям',
    'MursionPortal.Title.Proceed': 'Продолжить',
    'MursionPortal.Message.SkipActivity': 'Пропустив этот шаг, вы потеряете предварительную информацию, которую может предоставить наш механизм поведенческой аналитики. Рекомендуем не пропускать данное действие.',
    'MursionPortal.HeaderTitle.ConfirmCancellation': 'Подтвердите отмену',
    'MursionPortal.Label.Delete': 'удалить',
    'MursionPortal.Label.Restore': 'восстановить',
    'MursionPortal.HeaderTitle.ConfirmRestore': 'Подтвердите восстановление',
    'MursionPortal.Label.Archive': 'архивировать',
    'MursionPortal.Button.CancelTraining': 'Отменить обучение',
    'MursionPortal.Button.No': 'Нет',
    'MursionPortal.Button.CancelEvent': 'Отменить событие',
    'MursionPortal.Label.Update': 'обновить',
    'MursionPortal.HeaderTitle.ConfirmUpdate': 'Подтвердите обновление',
    'MursionPortal.Button.UpdateSession': 'Обновить сессию',
    'MursionPortal.Label.Swap': 'заменить',
    'MursionPortal.HeaderTitle.ConfirmAcceptedSwap': 'Подтвердите принятую замену',
    'MursionPortal.Button.AcceptSwap': 'Принять замену',
    'MursionPortal.Label.Reason': 'Причина',
    'MursionPortal.Placeholder.ReasonForSwapping': 'Укажите причину замены.',
    'MursionPortal.Label.Request': 'запрос',
    'MursionPortal.HeaderTitle.ConfirmAcceptedRequest': 'Подтвердите принятый запрос',
    'MursionPortal.Button.AcceptRequest': 'Принять запрос',
    'MursionPortal.Placeholder.ReasonForAccepting': 'Укажите причину принятия.',
    'MursionPortal.Button.ConfirmProjectChange': 'Подтвердите изменение проекта',
    'MursionPortal.HeaderTitle.Confirm': 'Подтвердить',
    'MursionPortal.Button.ConfirmCreateSession': 'Подтвердите создание сессии',
    'MursionPortal.Placeholder.ReasonForCreation': 'Укажите причину создания.',
    'MursionPortal.Button.ConfirmAssigningSimSpecialist': 'Подтвердите назначение специалиста по симуляции',
    'MursionPortal.Placeholder.ReasonForAssigning': 'Укажите причину назначения.',
    'MursionPortal.Button.ConfirmChangingSimSpecialist': 'Подтвердите замену специалиста по симуляции',
    'MursionPortal.Placeholder.ReasonForChanging': 'Укажите причину замены.',
    'MursionPortal.HeaderTitle.ConfirmTimeZone': 'Подтвердите часовой пояс',
    'MursionPortal.Message.DontHaveMursionInstalled': 'У вас не установлен ML3 Desktop App? Для установки нажмите <code>Скачать</code>.',
    'MursionPortal.ErrorMessage.CannotContainCharacter': 'Название сценария не должно содержать символа «_»',
    'MursionPortal.ErrorMessage.EnterDifferentName': '{capitalized} {fieldName} уже существует. Введите другое {objectName} {fieldName}.',
    'MursionPortal.ErrorMessage.EnterDifferentEmail': 'Адрес эл. почты пользователя уже используется. Введите другой адрес эл. почты.',
    'MursionPortal.ErrorMessage.NameExists': 'Пользователь с таким {fieldName} уже существует. Введите другое {fieldName}.',
    'MursionPortal.ErrorMessage.UnableToRemove': 'Невозможно удалить {entity}. {entity} в настоящее время используется в активе {depEntity}.',
    'MursionPortal.ErrorMessage.OverlapsExistingTimeBlock': 'Выбранный интервал пересекается с существующим интервалом.',
    'MursionPortal.ErrorMessage.AccessIsDenied': 'Доступ запрещен.',
    'MursionPortal.ErrorMessage.SomethingMustBeBroken': 'Что-то не работает. Повторите попытку позже.',
    'MursionPortal.ErrorMessage.Unauthorized': 'Не разрешен',
    'MursionPortal.ErrorMessage.RefreshTokenNotFound': 'Токен обновления не найден',
    'MursionPortal.ErrorMessage.CannotRefreshToken': 'Не удается обновить токен',
    'MursionPortal.QADialog.NoParticipants': 'Нет участников',
    'MursionPortal.ErrorMessage.UsersUnavailable': 'В выбранное время некоторые из ваших пользователей недоступны.',
    'Filters.SsoProtocolType': 'Протокол: Все',
    'Filters.SsoProtocolTypePlaceHolderHint': 'Поиск по протоколу единого входа',
    'Filters.SSO.PrivacyAll': 'Тип Все',
    'Filters.SSO.PrivacyPublic': 'Тип Общедоступные',
    'Filters.SSO.PrivacyPrivate': 'Тип Частные',
    'Filters.SSO.PrivacyAriaLabel': 'система единого входа фильтр типа приватности',
    'Cleints.Modal.Label.DomainsTitle': 'Список доступных доменов для входа',
    'MursionPortal.NoDomains': 'Нет доменов',
    'MursionPortal.Header.Domains': 'Домены',
    'MursionPortal.AddDomain': 'Добавить домен',
    'Clients.Modal.Projects.ButtonTitle.EditDomains': 'Редактировать домен',
    'Clients.Modal.Projects.DomainField.EnterDomain': 'Введите доменное имя (например, example.com)',
    'Clients.Modal.Projects.DomainField.InvalidDomain': 'Недействительный формат домена',
    'MursionPortal.ErrorMessage.SomethingWrongWithResponse': 'Произошла ошибка при ответе',
    'MursionPortal.ErrorMessage.AuthorizationHeaderIsEmpty': 'Заголовок авторизации пуст',
    'MursionPortal.ErrorMessage.CannotRefreshAccessToken': 'Не удается обновить токен доступа',
    'MursionPortal.ErrorMessage.AuthorizationTokenIsEmpty': 'Токен авторизации пуст',
    'MursionPortal.ErrorMessage.AuthenticationFailed': 'Аутентификация не выполнена.',
    'MursionPortal.ErrorMessage.UnsupportedResponseFormat': 'Недопустимый формат ответа.',
    'MursionPortal.ErrorMessage.AccountBlocked': 'Ваш аккаунт заблокирован до {blockedDate}. Попробуйте войти в систему через.',
    'MursionPortal.ErrorMessage.AttemptsRemaining': 'Количество попыток, оставшихся до 24-часовой блокировки: {attempts}. Если вам нужна помощь, сбросьте пароль.',
    'MursionPortal.ErrorMessage.ClientIdMustBeSpecified': 'Необходимо указать ID клиента',
    'MursionPortal.ErrorMessage.ProjectIdMustBeSpecified': 'Необходимо указать ID проекта',
    'MursionPortal.ErrorMessage.SessionIdMustBeSpecified': 'Необходимо указать ID сессии',
    'MursionPortal.ErrorMessage.SSOIdMustBeSpecified': 'Необходимо указать ID системы единого входа',
    'MursionPortal.ErrorMessage.LearnerIdMustBeSpecified': 'Необходимо указать ID учащегося',
    'MursionPortal.ErrorMessage.UserIdMustBeSpecified': 'Необходимо указать ID пользователя',
    'MursionPortal.ErrorMessage.TeamIdMustBeSpecified': 'Необходимо указать ID команды',
    'MursionPortal.ErrorMessage.AssetProjectIdMustBeSpecified': 'Необходимо указать ID проекта актива',
    'MursionPortal.ErrorMessage.AssetSettingsIdMustBeSpecified': 'Необходимо указать ID настроек актива',
    'MursionPortal.ErrorMessage.ScenarioIdMustBeSpecified': 'Необходимо указать ID сценария',
    'MursionPortal.ErrorMessage.ScenarioTemplateIdMustBeSpecified': 'Необходимо указать ID шаблона сценария',
    'MursionPortal.ErrorMessage.LicenseIdMustBeSpecified': 'fetchUsersList: необходимо указать ID обладателя лицензии',
    'MursionPortal.ErrorMessage.LicenseCertificateIdMustBeSpecified': 'fetchLicenseeCertificates: необходимо указать ID обладателя лицензии',
    'MursionPortal.ErrorMessage.LicenseConfigIdMustBeSpecified': 'fetchConfig: необходимо указать ID обладателя лицензии',
    'MursionPortal.ErrorMessage.LicenseUpdateConfigIdMustBeSpecified': 'updateConfig: необходимо указать ID обладателя лицензии',
    'MursionPortal.ErrorMessage.LicenseTestConfigIdMustBeSpecified': 'testConfig: необходимо указать ID обладателя лицензии',
    'MursionPortal.ErrorMessage.LicenseFetchTiersIdMustBeSpecified': 'fetchTiers: необходимо указать ID обладателя лицензии',
    'MursionPortal.ErrorMessage.LicenseUpdateTiersIdMustBeSpecified': 'updateTiers: необходимо указать ID обладателя лицензии',
    'MursionPortal.ErrorMessage.GlobalUserIdMustBeSpecified': 'Необходимо указать ID глобального пользователя',
    'MursionPortal.ErrorMessage.ScenarioTemplateListLicenseIdMustBeSpecified': 'fetchScenarioTemplatesList: необходимо указать ID обладателя лицензии',
    'MursionPortal.ErrorMessage.ScenarioAttachmentListIdMustBeSpecified': 'fetchScenarioAttachmentLink: необходимо указать ID сценария и ID вложения',
    'MursionPortal.ErrorMessage.FetchScenarioIdMustBeSpecified': 'fetchScenario: необходимо указать ID сценария',
    'Projects.ProjectInfo.Checkbox.AllowRecordingInfo': 'Это не удалит текущие сохраненные записи.',
    'MursionPortal.AriaLabel.ToggleMenu': 'переключить меню',
    'MursionPortal.ClientListItem.RedirectToUserDetails': 'Перенаправить на детали {name}',
    'MursionPortal.AriaLabel.PreviousWeek': 'предыдущая неделя',
    'MursionPortal.AriaLabel.PreviousDay': 'предыдущий день',
    'MursionPortal.AriaLabel.NextWeek': 'следующая неделя',
    'MursionPortal.AriaLabel.NextDay': 'предыдущий день',
    'Dashboard.LeftPane.CategoryHeader.SessionsDashboard': 'Панель управления сессиями',
    'MursionPortal.Title.SessionsPage': 'Страница сессий',
    'Clients.heading.Clients': 'Клиенты',
    'MursionPortal.AriaLabel.Client': 'клиент',
    'MursionPortal.AriaLabel.SimSpecialist': 'Специалист по Симуляции',
    'MursionPortal.Project.TimeZoneStartDate': 'Дата начала часового пояса',
    'MursionPortal.Project.TimeZoneEndDate': 'Дата окончания часового пояса',
    'MursionPortal.AriaLabel.SelectTimezoneCombobox': 'выберите часовой пояс в поле со списком',
    'MursionPortal.Support.PhoneNumber': '1-855-999-5818',
    'MursionPortal.Message.LearnerEmpty': 'Выберите учащихся, участвовавших в сессии',
    'Dashboard.ProjectsTable.StatusValue.Active': 'АКТИВНО',
    'Dashboard.ProjectsTable.StatusValue.Completed': 'ЗАВЕРШЕНО',
    'Dashboard.ProjectsTable.StatusValue.Archived': 'АРХИВИРОВАНО',
    'MursionPortal.Button.Refresh': 'Обновить',
    'MursionPortal.Prompt.CalendarUpdated': 'Календарь обновлен.',
    'MursionPortal.Prompt.ClickRefresh': 'Нажмите «Обновить», чтобы посмотреть изменения.',
    'MursionPortal.Label.SessionAttendance': 'Посещаемость сессии',
    'MursionPortal.Label.SessionVideoUrl': 'URL видеозаписи сессии',
    'MursionPortal.Label.LearnerAttendance': 'Посещаемость учащихся',
    'MursionPortal.Text.UserProfileUpdated': 'Профиль пользователя обновлен!',
    'MursionPortal.Text.ClientUpdated': 'Клиент обновлен!',
    'MursionPortal.Text.ProjectUpdated': 'Проект обновлен!',
    'MursionPortal.Text.ScenarioUpdated': 'Сценарий обновлен!',
    'MursionPortal.Text.LicenseeConfigUpdated': 'Конфигурация владельца лицензии обновлена!',
    'MursionPortal.Prompt.TrainingScheduled': '<code>Обучение</code> запланировано!',
    'Settings.SSO.Table.ColumnHeader.ClientName': 'Имя клиента',
    'MursionPortal.Text.UserCreatedSuccessfully': 'Пользователь успешно создан',
    'MursionPortal.Text.UserNotCreated': 'Пользователь не создан',
    'MursionPortal.Text.AssignedTeams': 'Назначенные команды',
    'MursionPortal.Client.EmailSettings.Invitation': 'Приглашение',
    'MursionPortal.Client.EmailSettings.InvitationReminder': 'Напоминание о приглашении',
    'MursionPortal.Client.EmailSettings.Confirmation': 'Подтверждение',
    'MursionPortal.Client.EmailSettings.Cancellation': 'Отмена',
    'MursionPortal.Client.EmailSettings.FirstReminder': 'Первое напоминание',
    'MursionPortal.Client.EmailSettings.SecondReminder': 'Второе напоминание',
    'MursionPortal.Client.EmailSettings.SessionRecording': 'Видеозапись сессии готова для владельца аккаунта/координатора',
    'MursionPortal.Client.EmailSettings.LearnerSession': 'Видеозапись сессии учащегося готова',
    'MursionPortal.Client.EmailSettings.SimulationSessionVideoRejected': 'Видеозапись сессии симуляции отклонена',
    'MursionPortal.Client.EmailSettings.SimulationSessionVideoDisallowed': 'Видеозапись сессии симуляции запрещена',
    'MursionPortal.Client.EmailSettings.SimulationSessionVideoRejectedUser': 'Видеозапись сессии симуляции отклонена пользователем',
    'MursionPortal.Client.EmailSettings.RequestRecieved': 'Подтверждение получения запроса',
    'MursionPortal.Client.EmailSettings.RequestNotFullfilled': 'Запрос не выполнен',
    'MursionPortal.Client.EmailSettings.RequestDateTime': 'Необходимо изменить дату/время запроса',
    'MursionPortal.Client.EmailSettings.Title.SelectEmails': 'Выберите электронные письма, которые вы хотите отправить учащимся. Mursion рекомендует оставить включенными все электронные письма.',
    'MursionPortal.Client.EmailSettings.Header.Registration': 'Регистрация',
    'MursionPortal.Client.EmailSettings.Header.TrainingSession': 'Учебная сессия',
    'MursionPortal.Client.EmailSettings.Header.Videos': 'Видео',
    'MursionPortal.Client.EmailSettings.Header.Requests': 'Запросы',
    'MursionPortal.AriaLabel.ToggleButton': 'Кнопка переключения',
    'MursionPortal.Client.EmailSettings.Hint.RecordingEnabled': 'только если включены видеозаписи',
    'MursionPortal.Client.EmailSettings.Hint.RequestFeature': 'только если включена функция запроса',
    'MursionPortal.SuccessDialog.Title': 'Диалог успеха',
    'MursionPortal.Dialog.CreateSSOConfiguartion.SuccessMessage': 'Конфигурация системы единого входа клиента успешно создана.',
    'ITManager.TechRestriction.Table.EmptyMessage': 'Ограничения по банковским технологиям не найдены.',
    'ITManager.TechRestriction.Table.Tag': 'Тег',
    'ITManager.TechRestriction.Table.StartDate': 'Дата начала',
    'ITManager.TechRestriction.Table.EndDate': 'Дата окончания',
    'ITManager.TechRestriction.EditDialog.EnterTag': 'Введите название тега',
    'ITManager.TechRestriction.Table.HoverTitle': 'Посмотреть/изменить техническое ограничение',
    'ITManager.TechRestriction.Table.NoTechRestrictionError': 'Технических ограничений пока не существует',
    'MursionPortal.DateTimePicker.StartTime': 'Время начала',
    'MursionPortal.DateTimePicker.EndTime': 'Время окончания',
    'MursionPortal.TechRestrictions': 'Технические ограничения',
    'MursionPortal.Caption.ScenarioBankUsersList': 'список пользователей банка сценариев',
    'MursionPortal.Caption.SsoConfigurationList': 'список конфигурации единого входа',
    'MursionPortal.AriaLabel.DateRangeCalendarButton': 'нажмите «Ввод» (Enter), чтобы взаимодействовать с календарем и выбирать дату начала и окончания',
    'MursionPortal.Modal.Header.SessionTimeout': 'Время сессии истекло',
    'MursionPortal.Modal.Body.SessionTimeout': 'Your session will timeout in 1 minute, please continue to extend your session', // TODO
    'MursionPortal.Status.PendingNoSS': 'В ожидании (нет СС)',
    'MursionPortal.Status.PendingNoL': 'В ожидании (нет У)',
    'MursionPortal.Status.PendingNoLearners': 'В ожидании (нет учащихся)',
    'MursionPortal.Status.PendingNoLSS': 'В ожидании (нет У/СС)',
    'MursionPortal.Status.Empty': ' ',
    'MursionPortal.SimAttendance.Tooltip.Declined':'Учащийся подключился к симуляции, но отказался от участия и вышел, не пройдя хотя бы 50% симуляции.',
    // needs translations
    'MursionPortal.Label.InterruptionCount': 'Times', // TODO: add translation
    'Dashboard.LeftPane.SupplyManagement.SimView': 'Просмотр Sim',
    'Dashboard.LeftPane.SupplyManagement.SchedulingView': 'Просмотр расписания',
    'MursionPortal.Table.ColumnSelection.Button.Label': 'Раздел',
    'Session.Table.Column.SubmitRequest': 'Отправить запрос',
    'Filters.SimSpecialistStatus': 'Статус Simulation Specialist',
    'Filters.SimSpecialistStatusPlaceHolderHint': 'Поиск по статусу Sim Specialist...',
    'Users.TableModal.SSOUserID': 'SSO ID пользователя',
    'MursionPortal.UnavailableSlotsError.SorryText': 'Здравствуйте! Нам очень жаль!',
    'MursionPortal.UnavailableSlotsError.BusyText': 'Но похоже, все симуляции заняты другими учащимися.',
    'MursionPortal.UnavailableSlotsError.TryAgainText': 'Пожалуйста, повторите попытку завтра или в другой день!',
    'MursionPortal.Session.Survey.ClickHere': 'Нажмите сюда',
    'MursionPortal.Session.SurveyNotLoading': 'Опрос не загружается?',
    'Users.Table.Heading.Viewonly': '(Только для просмотра)',
    'MursionPortal.BookItUI.BusyText': 'Похоже, что в данный момент мы заняты симуляцией с другими учащимися.',
    'MursionPortal.BookItUI.TryLaterText': 'Скоро должны начаться новые сеансы симуляции, пожалуйста, повторите попытку позже.',
    'MursionPortal.BookItUI.MoreSimulationsText': 'В ближайшее время должны открыться новые сеансы симуляции.',
    'MursionPortal.BookItUI.ComeBackText': 'Пожалуйста, вернитесь позже, если вы хотите провести сеанс сегодня.',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Archived': 'Заархивированный пользователь',
    'ClientUserTable.AddNewUsers.UpdateArchivedUserWarning': '{archivedUsers} пользователь(и) в настоящее время помещен(ы) в архив. В дальнейшем они будут добавлены в выбранные команды и разархивированы.',
    'ClientUserTable.AddNewUsers.UpdateExistingArchivedUserWarning': 'Эти пользователи {existingUsers} уже существуют, а пользователи {archivedUsers} находятся в архиве. В дальнейшем они будут добавлены в выбранные команды и разархивированы.',
    'MursionPortal.ProjectCreateStep.UpperLimitValidation.Hours': 'Не дольше {code} часов',
    'MursionPortal.ProjectCreateStep.UpcomingAvailability': 'Будет доступно в ближайшее время',
    'MursionPortal.Table.ColumnHeader.TotalSession': 'Итоговая сессия',
    'MursionPortal.Table.ColumnHeader.ActiveCertifications': 'Действующие сертификаты',
    'MursionPortal.Button.Decline': 'Отклонить',
    'MursionPortal.Having.Issues.Logging.In': 'Возникли проблемы при входе в систему?',
    'MursionPortal.Label.Call': 'Связаться:',
    'MursionPortal.Project.Hours': 'Час(ы)',
    'Users.Button.ProfService': 'Профессиональное обслуживание',
    'Users.Button.Operations': 'Сервисная служба',
    'Users.Button.SimDesigner': 'Sim Designer',
    'MursionPortal.ArtProject.Placeholder.EnterOrSelect': 'Выберите или введите',
    'MursionPortal.Setting.LearnerCommunicationPersonalization': 'Персонализация общения с учащимися',
    'MursionPortal.LearnerCommunicationPersonalization.Heading': 'Здесь вы можете настроить персонализированные сообщения для своих учеников.',
    'MursionPortal.SupplyBasedScheduling.SubmitPage.SimulationConfirmation': 'Ваша запись на симуляцию подтверждена! Мы рекомендуем прийти за несколько минут до начала.',
    'MursionPortal.DemandBasedScheduling.SubmitPage.LearnerConfirmed': '{learners}подтверждены для участия в симуляции!',
    'MursionPortal.DemandBasedScheduling.SubmitPage.DateAndTime': 'Дата и время',
    'MursionPortal.Personalized.LearnerDashboard.Section': 'Сообщение, которое вы хотите, чтобы все ваши ученики видели на Панели информации',
    'MursionPortal.Personalized.SchedulingPage.Section': 'Сообщение, которое вы хотите, чтобы все ваши учащиеся увидели на Странице Расписания',
    'MursionPortal.Personalized.Section.Tooltip': 'Сообщение будет обновлено везде',
    'MursionPortal.CheckboxLabel.JoinNow': 'Присоединиться прямо сейчас',
    'MursionPortal.ProjectCreateStep.UpcomingAvailability.Tooltip': 'Роли клиентов будут отображаться в виде слотов, отключенных для данного часа в разделе \'Доступно в ближайшее время\' после \'Доступные Sim\'. Если же в разделе \'Доступно в ближайшее время\' указано 0, то отключенные слоты не будут показаны, а будут показаны только \'Доступные Sim\'. Если время в разделе \'Доступно в ближайшее время\' составляет 24 часа, то все свободные слоты после \'Доступные Sim\' будут показаны отключенными в течение дня.',
    'MursionPortal.LearnerDashboard.JoinNow.OutsideOfficeHoursText': 'Пожалуйста, вернитесь позже, в рабочее время вашей организации, чтобы пройти этот сценарий.',
    'MursionPortal.Setting.Personalization.LastUpdated': 'Последнее обновление:',
    'MursionPortal.CheckboxLabel.JoinNowRequests': 'Запросы на присоединение прямо сейчас',
    'MursionPortal.LearnerDashboard.JoinNow.HeaderText': 'Мы подбираем вам аватар <code> для симуляции в реальном времени</code>. Пожалуйста, ознакомьтесь с приведенными ниже материалами для подготовки.',
    'MursionPortal.LearnerDashboard.JoinNow.SorryText': 'Приносим свои извинения!',
    'MursionPortal.LearnerDashboard.JoinNow.AvatarsBusyText': 'Все наши аватары заняты другими учениками. Пожалуйста, вернитесь позже или повторите попытку через несколько минут.',
    'MursionPortal.LearnerDashboard.JoinNow.CongratsText': 'Поздравляю! Вы получили аватар!',
    'MursionPortal.LearnerDashboard.JoinNow.AvatarsMatchText': 'Ваш аватар уже ждет вас! Нажмите кнопку "Присоединиться к симуляции в реальном времени", когда будете готовы, или ваш опыт начнется автоматически после обратного отсчета.',
    'MursionPortal.LearnerDashboard.JoinNow.StartLearning': 'If you are not able to get connected, click below to start learning.',
    'MursionPortal.LearnerDashboard.JoinNow.StarBucks.MyLearning': 'Starbucks MyLearning',
    'MursionPortal.LearnerDashboard.JoinNow.StarBucks.LiveSimulationExp': 'This session is a live simulation experience. Partners can now choose to take an eLearning on de-escalating conflict in MyLearning, as an alternative to the live safety simulation.',
    'MursionPortal.Button.Leave': 'Выйти',
    'MursionPortal.Personalized.ScenarioPage.Section': 'Сообщение, которое вы хотите, чтобы учащиеся увидели на Странице Сценария',
    'MursionPortal.Personalized.CreateNewScenario.Button.Label': 'Создать новое для данного сценария',
    'MursionPortal.Personalized.Scenario.Search.Placeholder': 'Выполните поиск или выберите сценарий(и):',
    'MursionPortal.Personalized.Scenario.Selection.Title': 'Добавить новый сценарий',
    'MursionPortal.Personalized.NoMessage': 'В настоящее время сообщений не установлено',
    'MursionPortal.Personalized.MetaData.NoHistory': 'Истории пока нет.',
    'MursionPortal.JoinNow.AlreadyBooked.Message': '{code}на данный момент забронирован для проведения сеанса. .',
    'Users.Table.SendInvite.TooltipText': 'Отключение регистрационных писем также блокирует кнопку ‘Пригласить’ на портале',
    'MursionPortal.JoinNow.AlreadyBooked.JoinSession.Message': 'Нажмите кнопку "Присоединиться к симуляции в реальном времени" , чтобы подключиться к сеансу или возобновить его.',
    'Dashboard.LeftPane.CategoryHeader.Scenarios': 'Сценарии',
    'Dashboard.LeftPane.Learners.TeamAndProjectActivity': 'Командная и проектная деятельность',
    'MursionPortal.Dashboard.Chart.label.LearnerStatus.NotYetScheduled': 'Еще не запланировано',
    'MursionPortal.Dashboard.Chart.label.LearnerStatus.Scheduled': 'Еще не завершено',
    'MursionPortal.Dashboard.Chart.label.LearnerStatus.LearnerCompleted': 'Завершено',
    'MursionPortal.Dashboard.Chart.label.LearnerStatus.NotYetAssigned': 'Еще не назначено',
    'MursionPortal.Dashboard.Chart.Tooltip.LearnerStatus.NotYetScheduled': 'Количество обучающихся, состоящих хотя бы в одной команде, которая закреплена хотя бы за одним сценарием, и при этом обучающийся ни разу не запланировал сеанс.',
    'MursionPortal.Dashboard.Chart.Tooltip.LearnerStatus.Scheduled': 'Количество обучающихся, которые запланировали хотя бы один сеанс, но еще не посетили и не завершили его.',
    'MursionPortal.Dashboard.Chart.Tooltip.LearnerStatus.CompletedAssigned': 'Количество учащихся, прошедших хотя бы одну сессию.',
    'MursionPortal.Dashboard.Chart.Tooltip.LearnerStatus.NotYetAssigned': 'Количество обучающихся, не назначенных в группу, или входящих в группу, но не назначенных ни на один сценарий.',
    'MursionPortal.Dashboard.ScenarioLearner.Header.LearnerStatus': 'Завершающие обучение',
    'MursionPortal.Dashboard.ScenarioLearner.Header.LearnerSequence': 'Отличившиеся учащиеся',
    'MursionPortal.Dashboard.ScenarioLearner.Header.SequenceCount': '(Максимальное количество сеансов {sessionCount})',
    'Dashboard.SequenceOrder.Header.Title': 'Пропущенные, поздние отмены и поздние переносы мероприятий',
    'MursionPortal.Integrations': 'Интеграции',
    'MursionPortal.Personalized.Message.Delete.Title': 'Вы уверены, что хотите удалить это сообщение?',
    'MursionPortal.Personalized.Message.Delete.Tooltip': 'Удалить сообщение',
    'MursionPortal.Personalized.Scenario.Message.OnlyOneAtTime': 'Изменения могут быть внесены только в одно сообщение за раз. Пожалуйста, сохраните или отмените то сообщение, которое уже находится в режиме редактирования.',
    'MursionPortal.Error.Time': '6:00 - 22:00 ET',
    'MursionPortal.CheckboxLabel.JoinNow.Tooltip.Text': 'Запись с помощью функции "Забронировать" всегда включена, если включена функция "Присоединиться сейчас"',
    'MursionPortal.JoinNow.FinalizedScenario.Message': 'Сценарий дорабатывается, пожалуйста, возвращайтесь, когда сценарий будет активен.',
    'MursionPortal.CheckboxLabel.sessionMissedTime': 'Пропущенное время сессии',
    'MursionPortal.Title.Seconds': 'Секунда(ы)',
    'MursionPortal.RequestTable.Accept.ErrorMessage': 'Извините, сеанс отменен.',
    'MursionPortal.Client.EmailTypeFilter.SchedulingLocked': 'Планирование заблокировано',
    'MursionPortal.Client.EmailTypeFilter.SchedulingUnlocked': 'Планирование разблокировано',
    'MursionPortal.ScenarioInfo.SchedulingLockout.Time': 'Планирование блокировки',
    'MursionPortal.Table.ColumnHeader.SimAvailable': 'Доступные Sim',
    'MursionPortal.CheckboxLabel.sessionMissedTime.Tooltip.Text': 'Время, по истечении которого сессия будет отмечена как пропущенная, если к ней не присоединится ни один обучающийся или SIM.',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.NotYetCompleted': 'Еще не завершено',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.completedOneSession': 'Завершен 1 сеанс',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.completedTwoPlusSession': 'Завершено более 2 сеансов',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.OneSession': '1 Сеанс',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.TwoSession': '2 Сеанса',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.ThreeSession': '3 Сеанса',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.FourPlusSession': '4+ Сеансов',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.NotYetCompleted': 'Количество уникальных учащихся, назначенных для этого сценария, но еще не завершивших его',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.completedOneSession': 'Количество уникальных учащихся, назначенных для этого сценария, которые выполнили его один раз.',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.completedTwoPlusSession': 'Количество уникальных учащихся, назначенных для этого сценария, которые выполнили его два или более раз.',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.OneSession': 'Количество уникальных учащихся, которые пропустили, поздно отменили или поздно перенесли один сеанс.',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.TwoSession': 'Количество уникальных учащихся, которые пропустили, поздно отменили и/или поздно перенесли два сеанса.',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.ThreeSession': 'Количество уникальных учащихся, которые пропустили, поздно отменили и/или поздно перенесли три сеанса.',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.FourPlusSession': 'Количество уникальных учащихся, которые пропустили, поздно отменили и/или поздно перенесли четыре или более сеансов.',
    'MursionPortal.ScenarioBank.ItemBank.Label': 'Составные модули',
    'MursionPortal.ScenarioBank.ItemBank.Skills': 'Навыки',
    'MursionPortal.ScenarioBank.ItemBank.Domains': 'Домены',
    'MursionPortal.ScenarioBank.ItemBank.Events': 'События',
    'MursionPortal.ScenarioBank.ItemBank.Pathways': 'Пути',
    'MursionPortal.ScenarioBank.ItemBank.Resources': 'Ресурсы',
    'MursionPortal.ScenarioBank.ItemBank.Outcomes': 'Результаты',
    'MursionPortal.ScenarioBank.ItemBank.Mindsets': 'Образы мышления',
    'MursionPortal.ScenarioBank.ItemBank.Avatars': 'Аватары',
    'MursionPortal.ScenarioBank.ItemBank.ResearchSummaries': 'Резюме исследований',
    'MursionPortal.ScenarioBank.ItemBank.LearnerInstructions': 'Инструкции для учащихся',
    'MursionPortal.ScenarioBank.ItemBank.Column.Level': 'Уровень',
    'MursionPortal.ScenarioBank.ItemBank.Column.Domain': 'Домен',
    'MursionPortal.ScenarioBank.ItemBank.Column.Translations': 'Переводы',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Skill': 'Создать навык',
    'MursionPortal.Dashboard.Label.ScenarioActivity.ErrorMessage': 'Пожалуйста, выберите по крайней мере один проект и сценарий из фильтров',
    'MursionPortal.Learner.Onboarding': 'Регистрация учащихся в системе',
    'MursionPortal.Tab.Onboarding': 'РЕГИСТРАЦИЯ',
    'MursionPortal.OrganizationName.Placeholder': 'введите название вашей организации',
    'Dashboard.LeftPane.Learners.LearnerLockedOut': 'Ученик заблокирован',
    'Dashboard.Learners.LearnerLockedOut.TableHeader': 'Обучающиеся, не допущенные к расписанию из-за ошибок/отмены занятий',
    'Dashboard.Learners.LearnerLockedOut.LockedOutColumn': 'Дата/ время заблокированы для планирования',
    'Dashboard.Learners.LearnerLockedOut.AutoReleaseColumn': 'Дата/Время до автоматического выпуска',
    'MursionPortal.DontHaveEmail.Text': 'У Вашей организации нет адреса электронной почты?',
    'MursionPortal.InvalidCompanyName.Error': 'Ваша организация требует, чтобы пользователи входили в систему, используя свой адрес электронной почты. Пожалуйста, введите его выше.',
    'MursionPortal.LearnerOnboardingPage.Text.Welcome': 'Добро пожаловать',
    'MursionPortal.LearnerOnboardingPage.Text.MursionVideoOverview': 'Видеообзор Mursion',
    'MursionPortal.LearnerOnboardingPage.Text.KeyThingsToKnow': 'Основные сведения',
    'MursionPortal.LearnerOnboardingPage.Welcome.Section.Text': 'Мы рады приветствовать вас в программе обучения Mursion! Mursion использует иммерсивные симуляторы, управляемые с помощью технологии "human-in-the-loop", чтобы обеспечить комплексное обучение, которое позволит вам получить необходимые навыки и методы для достижения успеха в постоянно развивающемся мире.',
    'MursionPortal.LearnerOnboardingPage.Welcome.Section.Beginner.Text': 'Не важно, новичок вы или опытный профессионал, в нашей программе есть что предложить каждому. Мы с радостью приглашаем вас начать обучение.',
    'MursionPortal.LearnerOnboardingPage.Video.Section.Text': 'Сначала посмотрите видео <strong> о Mursion!</strong>',
    'MursionPortal.LearnerOnboardingPage.Scheduled.Section.Text': '<strong> Теперь вы готовы:</strong> Запланируйте свою первую симуляцию!',
    'MursionPortal.LearnerOnboardingPage.keysThingsToKnow.Section.Heading': 'Далее рассмотрим некоторые ключевые моменты, которые необходимо знать!',
    'MursionPortal.LearnerOnboardingPage.Mursion.Methodology': 'Методология Mursion',
    'MursionPortal.LearnerOnboardingPage.Mursion.Methodology.Summary': 'Программа Mursion основана на научных исследованиях и подтвердила свою эффективность в изменении поведения. Наши симуляции с применением аватаров помогут вам научиться правильно реагировать в эмоционально напряженных ситуациях.',
    'MursionPortal.LearnerOnboardingPage.Mursion.WhyAvatars.Text': 'Почему именно аватары',
    'MursionPortal.LearnerOnboardingPage.Mursion.WhyAvatars.Summary': 'Mursion - наиболее эффективный инструмент по сравнению с традиционными или ролевыми методами. Исследования показывают, что люди более доверчивы и проявляют любопытство при работе с аватарами, в отличие от взаимодействия со своими коллегами или менеджерами.',
    'MursionPortal.LearnerOnboardingPage.Human.Text': 'Машинное обучение с участием человека " human-in-the-Loop"',
    'MursionPortal.LearnerOnboardingPage.Human.Summary': 'В каждой симуляции Mursion принимает участие живой Simulation Specialist, который обеспечивает речевой диалог и движения для пяти виртуальных аватаров в каждой симуляции.',
    'MursionPortal.LearnerOnboardingPage.Practice.Text': 'Почему практика имеет значение',
    'MursionPortal.LearnerOnboardingPage.Practice.Text.Summary': 'Совершенствование лидерских навыков - непростая задача. Но исследования показывают, что обучение наиболее эффективно, когда оно включает в себя практику. Mursion уделяет этому приоритетное внимание, создавая безопасные, динамичные и доступные пространства.',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Domain': 'Создать домен',
    'MursionPortal.ScenarioBank.ItemBank.Level.L1': 'L1',
    'MursionPortal.ScenarioBank.ItemBank.Level.L2': 'L2',
    'MursionPortal.ScenarioBank.ItemBank.Level.L3': 'L3',
    'MursionPortal.Label.OrganizationName': 'Название организации',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Pathway': 'Создать путь',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Event': 'Создать событие',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Resource': 'Создать ресурс',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Outcome': 'Сформировать результат',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Mindset': 'Создать образ мышления',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Avatar': 'Добавить имя аватара',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.ResearchSummary': 'Сформировать резюме исследования',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.LearnerInstruction': 'Создайте инструкцию для учащихся',
    'MursionPortal.ScenarioBank.ItemBank.BackButton.Label': 'Вернуться к банку сценариев',
    'MursionPortal.ScenarioBank.ItemBank.Title.Placeholder': 'Введите сюда',
    'MursionPortal.Dashboard.NextOnProject.Footer.MissOrCancelationRateFulfilled.Text': 'You cannot schedule this simulation as you have reached the missed or cancellation limit set by your organization. Please reach out to your program admin to allow scheduling.',// TODO add translations
    'MursionPortal.ScenarioInfo.Show': 'Показать',
    'MursionPortal.ScenarioInfo.CertificationHistory': 'История сертификации',
    'MursionPortal.Session.AlreadyBooked': 'Извините, эта сессия уже принята другим SIM',
    'MursionPortal.CustomizeInvitation.Title': 'Настройка приглашений',
    'MursionPortal.CustomizeInvitation.SubTitle': 'Для настройки измените текст для каждого поля, приведенного ниже, или оставьте текст по умолчанию.',
    'MursionPortal.CustomizeInvitation.IntroMessage': 'Вступительное сообщение для обучающихся (под этим сообщением будут автоматически отображаться доступные сценарии):',
    'MursionPortal.CustomizeInvitation.Label.SenderName': 'Имя отправителя (будет отправлено с адреса noreply@mursion.com.)',
    'MursionPortal.CustomizeInvitation.Label.Subject': 'Тема',
    'MursionPortal.InviteLearnersTeam.Section.Title': 'Какую команду (команды) вы хотели бы пригласить для участия в расписании?',
    'MursionPortal.InviteLearnersTeam.Section.SubTitle': 'Учащиеся будут приглашены на все сценарии этого проекта, которые они смогут запланировать',
    'MursionPortal.InviteLearnersTeam.ScenariosAssigned.Text': 'назначенные сценарии',
    'MursionPortal.Confirmation.Modal.Invitations.Text': 'Ваши приглашения уже отправлены!',
    'MursionPortal.ScenarioSetting.ValidationMessage': 'должно быть меньше или равно тому же параметру на уровне клиента и/или проекта.',
    'MursionPortal.ScenarioBank.EventDetail.Details': 'Подробнее',
    'MursionPortal.ScenarioBank.CharacterLimit.TitleMessage': 'Ограничение по количеству символов:{titleMaxLength}',
    'MursionPortal.ScenarioBank.CharacterLimit.DescriptionMessage': 'Ограничение по количеству символов:{descriptionMaxLength}',
    'MursionPortal.ScenarioBank': 'Шаблон события',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header': 'Создавайте атмосферу уверенности и доверия, позволяющую другим делиться информацией о своей работе.',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header.Title': 'Сведения о домене',
    'MursionPortal.ScenarioBank.ItemBank.Edit.DescriptionOfUpdate.Label': 'Сохранение этих изменений приведет к обновлению {type} во всех сценариях. Пожалуйста, добавьте краткое описание изменений:',
    'Dashboard.Learners.LearnerLockedOut.LearnerColumn.Tooltip': 'Имя пользователя или адрес электронной почты',
    'MursionPortal.ScenarioInfo.CertificationHistory.Column.Certified': 'Сертифицированный',
    'MursionPortal.ScenarioInfo.CertificationHistory.Column.Decertified': 'Не прошедший сертификацию',
    'MursionPortal.ScenarioInfo.CertificationHistory.Column.Inactive': 'Неактивный',
    'MursionPortal.Dashboard.LearnerLockedOut.ManualRelease': 'Освободить учащегося от расписания',
    'MursionPortal.CustomizeInvitation.Sender.Name': 'Mursion',
    'MursionPortal.CustomizeInvitation.Subject.Title': 'Приготовьтесь к практике! Ваши занятия в Mursion уже можно запланировать.',
    'MursionPortal.CustomizeInvitation.Intro.Message': 'Вы получили приглашение принять участие в симуляционной программе Mursion. Перейдите по ссылке ниже, чтобы запланировать сеанс, или откройте свою Информационную панель и проверьте ее.',
    'MursionPortal.Dashboard.LearnerLockedOut.ManualRelease.ConfirmationText': 'Вы уверены, что хотите освободить {code}учащегося(учащихся) от планирования занятий?',
    'MursionPortal.Dashboard.LearnerLockedOut.ManualRelease.SuccessText': '{code}учащийся(еся) был(и) освобожден(ы) от планирования занятий?',
    'Dashboard.Learners.LearnerLockedOut.LockedOutColumn.Tooltip': 'Дата/время, когда обучающийся был освобожден от планирования занятий?',
    'Dashboard.Learners.LearnerLockedOut.AutoReleaseColumn.Tooltip': 'Дата/время, когда учащийся будет автоматически освобожден',
    'MursionPortal.ScenarioBank.ItemBank.NextGenScenario': 'NextGen сенарий',
    'MursionPortal.ScenarioBank.ResourceFilter.Book': 'Книга',
    'MursionPortal.ScenarioBank.ResourceFilter.Article': 'Статья',
    'MursionPortal.ScenarioBank.Filter.Label.Publisher': 'Издатель',
    'MursionPortal.ScenarioBank.ItemBank.Level.Placeholder': 'Выберите уровень',
    'MursionPortal.ScenarioBank.ItemBank.Domain.Placeholder': 'Выполните поиск или выберите домен:',
    'MursionPortal.Label.CompletionRate.Tooltip': 'Коэффициент завершения - это количество раз, которое обучаемый может пройти симуляцию, прежде чем потеряет возможность планировать занятия (будет заблокирован)',
    'MursionPortal.Label.SchedulingRate.Tooltip': 'Частота планирования - это количество раз, которое обучающийся может запланировать симуляцию, прежде чем потеряет возможность планировать занятия (будет заблокирован)',
    'MursionPortal.Label.MissCancellationRate.Tooltip': 'Коэффициент пропусков/отмен - это количество раз, которое обучающийся может пропустить или отменить симуляцию, прежде чем потеряет возможность планировать занятия (будет заблокирован)',
    'MursionPortal.ScenarioInfo.SchedulingLockout.Time.Tooltip': 'Время блокировки составления расписания - сколько часов будет длиться блокировка возможности составления расписания',
    'MursionPortal.ScenarioBank.ItemBank.Mindset.Type.Derailing': 'Деструктивный',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header.Title.Skill': 'Сведения о навыках',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header.Title.Mindset': 'Детали образа мышления',
    'MursionPortal.ProjectCreate.DeliveryHours': 'Время подачи заявок',
    'MursionPortal.ProjectCreate.DeliveryHours.Tooltip': 'Временные рамки, в течение которых учащимся будут показаны мгновенные слоты',
    'MursionPortal.ProjectCreate.DeliveryHours.Start': 'Время начала подачи заявок',
    'MursionPortal.ProjectCreate.DeliveryHours.End': 'Время окончания подачи заявок',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header.Title.LearnerInstruction': 'Инструкция для учащегося',
    'MursionPortal.ScenarioBank.ItemBank.Avatar.Edit.Header.Title': 'Детали аватара',
    'MursionPortal.ScenarioBank.Label.AddLink': 'Добавить ссылку',
    'MursionPortal.ScenarioBank.Year.placeholderText': 'Год',
    'MursionPortal.ScenarioBank.ItemBank.Resource.Edit.Header.Title': 'Сведения о ресурсах',
    'MursionPortal.ScenarioBank.ItemBank.Outcome.Edit.Header.Title': 'Подробности о результатах',
    'MursionPortal.ScenarioBank.OutcomeLevelSelector.Advanced': 'Продвинутый',
    'MursionPortal.ScenarioBank.OutcomeLevelSelector.Intermediate': 'Средний',
    'MursionPortal.ScenarioBank.OutcomeLevelSelector.Foundational': 'Базовый',
    'MursionPortal.ScenarioBank.ItemBank.Skill': 'Навык',
    'MursionPortal.Resources.Search.Text': 'Поиск или выбор ресурса(ов)',
    'MursionPortal.ScenarioBank.ItemBank.ResearchSummaries.Details': 'Краткие сведения об исследованиях',
    'MursionPortal.ScenarioBank.ItemBank.ResearchSummary': 'Краткое содержание исследования',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header.Title.Pathway': 'Детали пути',
    'MursionPortal.ScenarioBank.ItemBank.Pathway': 'Путь',
    'MursionPortal.ScenarioBank.ConfirmationText.Archive': 'Вы уверены, что хотите заархивировать {block}?',
    'MursionPortal.ScenarioBank.ConfirmationText.Unarchived': 'Вы уверены, что хотите разархивировать {block}?',
    'MursionPortal.ScenarioBank.Unarchived': 'Разархивировать',
    'MursionPortal.ScenarioBank.ItemBank.Resource': 'Ресурс',
    'MursionPortal.ScenarioBank.ItemBank.Mindset': 'Образ мышления',
    'MursionPortal.ScenarioBank.ItemBank.LearnerInstruction': 'Инструкция для учащихся',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header.Title.Event': 'Подробности события',
    'MursionPortal.ScenarioBank.NextGen.AvatarPerspectives': 'Перспективы развития Аватара',
    'MursionPortal.ScenarioBank.NextGen.AvatarPerspectives.Intro': 'Вступление',
    'MursionPortal.ScenarioBank.NextGen.AvatarPerspectives.DiscProfile': 'Disc профиль',
    'MursionPortal.ScenarioBank.NextGen.AvatarPerspectives.VerbalStyle': 'Речевой стиль',
    'MursionPortal.ScenarioBank.Content': 'Содержание',
    'MursionPortal.ScenarioBank.Content.ScenarioTitle': 'Название сценария',
    'MursionPortal.ScenarioBank.Content.ScenarioCoverStory': 'История на обложке сценария',
    'MursionPortal.ScenarioBank.ItemBank.Outcome.Placeholder': 'Выберите из банка результатов',
    'MursionPortal.ScenarioBank.Content.WhyThisMatters': 'Почему это так важно',
    'MursionPortal.ScenarioBank.NextGen.AvatarGuide': 'Руководство по аватару',
    'MursionPortal.ScenarioBank.NextGen.ViewScenario': 'Просмотр сценария',
    'MursionPortal.ScenarioBank.NextGen.Event': 'Значок события',
    'MursionPortal.NextOnProject.Card.JoinNow': 'Присоединяйтесь к симуляции в реальном времени',
    'MursionPortal.JoinNowWaitingPage.HowItWorks.Title': 'Как это работает',
    'MursionPortal.JoinNowWaitingPage.HowItWorks.Intro': 'Привет! Вскоре вам подберут аватар, чтобы <code> Присоединиться к симуляции в реальном времени </code>.',
    'MursionPortal.JoinNowWaitingPage.HowItWorks.Matched': 'Как только вы подберете аватар, нажмите <code>Присоединиться к симуляции в реальном времени </code>.',
    'MursionPortal.JoinNowWaitingPage.HowItWorks.Allow': 'Нажмите <code>"Разрешить"</code> для доступа к микрофону/ камере, когда вам будет предложено',
    'MursionPortal.JoinNowWaitingPage.HowItWorks.StartSession': 'Нажмите <code> "Начать сеанс" </code> и дождитесь, пока аватар начнет вашу симуляцию',
    'MursionPortal.ScenarioBank.NextGen.OutOfSite.Label': 'Не на виду, но в центре внимания',
    'MursionPortal.FirstGen.Scenario.Button.Text': '1 Gen',
    'MursionPortal.NextGen.Scenario.Button.Text': '2 Gen',
    'MursionPortal.NextGen.Scenario.Assessment': 'Оценка',
    'MursionPortal.NextGen.Scenario.OutOfSite': 'Не на виду, но в центре внимания',
    'MursionPortal.NextGen.Scenario.Synopsis': 'Краткий обзор',
    'MursionPortal.NextGen.Scenario.MinimumPoints': 'Минимальное количество баллов, необходимое для успешного прохождения',
    'MursionPortal.NextGen.Scenario.Successful': 'Успешно',
    'MursionPortal.NextGen.Scenario.OpportunityDevelop': 'Возможности дальнейшего развития',
    'MursionPortal.NextGen.Scenario.LibraryInfo': 'Информация о библиотеке',
    'MursionPortal.NextGen.Scenario.LibraryInfo.DifficultyLevel': 'Уровень сложности',
    'MursionPortal.NextGen.Scenario.LibraryInfo.ScenarioCoverImage': 'Изображение обложки сценария',
    'MursionPortal.NextGen.Scenario.LibraryInfo.ScenarioThumbnailImage': 'Изображение Превью сценария',
    'MursionPortal.NextGen.Scenario.LibraryInfo.CoverImageDescription': 'Описание изображения на обложке',
    'MursionPortal.NextGen.Scenario.LibraryInfo.ThumbnailImageDescription': 'Описание изображения превью',
    'MursionPortal.ScenarioBank.CharacterLimit.ResponseMessage': 'Ограничение по количеству символов: {responseMaxLength}',
    'MursionPortal.ScenarioBank.EventDetail.AvatarResponseTitle': 'Возможный(ые) ответ(ы) аватара',
    'MursionPortal.NextGen.Scenario.ReflectionGuide': 'Руководство для размышления',
    'MursionPortal.NextGen.Scenario.Scoring': 'Баллы',
    'MursionPortal.ErrorMessage.buildingBlock': 'Необходимо указать ID Составного модуля',
    'MursionPortal.NextGen.Scenario.LibraryInfo.DragAndDropText': 'Перетащите ваш файл сюда или нажмите, чтобы выбрать файлы',
    'MursionPortal.NextGen.Scenario.Publish': 'Публиковать',
    'MursionPortal.NextGen.Scenario.Publish.Label': 'Пожалуйста, заполните обязательные поля (*) для публикации.',
    'MursionPortal.NextGen.Scenario.Last.Publish.Label': 'Последняя публикация {publishDate}автором {publishedBy}',
    'MursionPortal.EulaAgreement.CodeOfConductCheckBox.Text': 'Я согласен с действующей в Mursion моделью проведения симуляций и подтверждаю, что буду соблюдать и выполнять Кодекс поведения Mursion во всех симуляциях, в которых я участвую. Подробнее о симуляциях Mursion и нашем Кодексе поведения можно прочитать <code> здесь.</code>',
    'MursionPortal.JoinNow.Cancellation.FirstOption.Text': 'Сейчас у меня нет на это времени',
    'MursionPortal.JoinNow.Cancellation.SecondOption.Text': 'Я не знаю, что это такое',
    'MursionPortal.JoinNow.Cancellation.ThirdOption.Text': 'Я вернусь к этому вопросу позже',
    'MursionPortal.JoinNow.Cancellation.FirstOption.Description.Text': 'Большинство людей, прошедших эту симуляцию, считают ее полезным. Это займет 30 минут или меньше.',
    'MursionPortal.JoinNow.Cancellation.SecondOption.Description.Text': 'Эта интерактивная практика помогает развивать компетенции и навыки в безопасном и динамичном пространстве, заменив видеоролик.',
    'MursionPortal.JoinNow.Cancellation.ThirdOption.Description.Text': 'Конечно! Вы можете вернуться в любое установленное организацией время и присоединиться к симуляции, чтобы отработать свои навыки!',
    'MursionPortal.JoinNow.CancellationText.Heading': 'Почему вы хотите все отменить?',
    'MursionPortal.JoinNow.CancellationPage.Title': 'Возможность потренироваться с аватаром!',
    'MursionPortal.JoinNow.CancellationPage.Text': 'Выявлять позитивные способы разрешения ситуации в рамках политики и правил Starbucks и искать возможности для создания лучших впечатлений для всех клиентов.',
    'MursionPortal.JoinNow.JoinSimulation.Button.Text': 'Продолжить присоединение к моделированию',
    'MursionPortal.LearnerDashboard.JoinNow.SearchingAvatar': 'Мы ищем аватар <code> для вашей симуляции в реальном времени! </code>',
    'MursionPortal.LearnerDashboard.JoinNow.SuccessText': '<code>Поздравляем</code>, вам был подобран аватар для вашей симуляции!',
    'MursionPortal.LearnerDashboard.JoinNow.ConnectingText': 'Мы <code>подключаем вас к вашему аватару</code>, и вскоре начнется симуляция в реальном времени!',
    'MursionPortal.LearnerDashboard.JoinNow.DoNotCloseText': 'Пожалуйста, не закрывайте это окно браузера!',
    'MursionPortal.LearnerDashboard.JoinNow.StrategiesToUse': 'Рекомендуемые стратегии',
    'MursionPortal.LearnerDashboard.JoinNow.PracticeOpportunityText': 'Возможность потренироваться с аватаром!',
    'MursionPortal.Text.Sorry': 'Извините',
    'MursionPortal.Text.Matching': 'Соответствие',
    'MursionPortal.Text.Connecting': 'Соединение',
    'MursionPortal.JoinNowScenarioInfoCard.Text': 'Пожалуйста, зайдите позже или повторите попытку через несколько минут!',
    'MursionPortal.JoinNowScenarioInfoCard.HeaderText': 'Все наши аватары заняты другими учениками!',
    'MursionPortal.JoinNow.CancellationPage.GoBackButton.Text': 'Отмена и возврат к информационной панели',
    'MursionPortal.NextGen.Scenario.Event.AddMessage': 'Добавьте минимум 2х',
    'MursionPortal.ScenarioBank.ItemBank.Events.AddButton': '+Добавить',
    'MursionPortal.NextGen.ScenarioBank.Event.Select.Placeholder': 'Выберите событие',
    'MursionPortal.NextGen.ScenarioBank.Skill.Select.Placeholder': 'Выберите навык',
    'MursionPortal.NextGen.ScenarioBank.Event.AvatarBehavior.Label': 'Поведение аватара*',
    'MursionPortal.NextGen.ScenarioBank.Event.LearnerResponse.Label': 'Ожидаемый ответ учащегося*',
    'MursionPortal.NextGen.ScenarioBank.Event.AvatarAdd.Button': 'Добавить ответ',
    'MursionPortal.NextGen.ScenarioBank.AvatarResponseName.Select.Placeholder': 'Выберите аватар',
    'MursionPortal.NextGen.ScenarioBank.Label.AvatarPossibleResponse': 'Возможный(ые) ответ(ы) аватара',
    'MursionPortal.NextGen.ScenarioBank.Event.AssessmentObservedAction.Label': 'Опишите наблюдаемое действие',
    'MursionPortal.NextGen.ScenarioBank.Event.AssessmentImapct.Label': 'Объясните действие',
    'MursionPortal.NextGen.ScenarioBank.StrengthAssessment': 'Оценка стойкости*',
    'MursionPortal.NextGen.ScenarioBank.Button.StrengthAssessment': '+ Добавить оценку',
    'MursionPortal.NextGen.ScenarioBank.Label.Strength': 'Стойкость',
    'MursionPortal.NextGen.ScenarioBank.OpportunityAssessment': 'Оценка возможностей*',
    'MursionPortal.NextGen.ScenarioBank.Button.OpportunityAssessment': '+ Добавить оценку возможностей',
    'MursionPortal.NextGen.ScenarioBank.Label.Opportunity': 'Возможность',
    'MursionPortal.NextGen.Scenario.Event.Mindset.Select.Placeholder': 'Выберите позитивный образ мышления',
    'MursionPortal.NextGen.Scenario.Event.Derailing.Select.Placeholder': 'Выберите деструктивный образ мышления',
    'MursionPortal.NextGen.Scenario.Event.Scoring.Title': 'Подсчет очков за события',
    'MursionPortal.NextGen.Scenario.Event.Mindset.Title': 'Добавьте позитивный или деструктивный образ мышления*',
    'MursionPortal.NextGen.Scenario.Event.Positive.Button.Text': 'Добавить дополнительный позитивный образ мышления',
    'MursionPortal.NextGen.Scenario.Event.Derailing.Button.Text': 'Добавить дополнительный деструктивный образ мышления',
    'MursionPortal.Text.Searching': 'Поиск',
    'MursionPortal.NextGen.Scenario.Event.MindsetsNavigated': 'Навигация по образу мышления',
    'Dashboard.ContractProgress.Column.RemainingUtilization': 'Осталось освоить',
    'Dashboard.ContractProgress.Column.EstimatedRemaining': 'Предполагаемый остаток',
    'MursionPortal.Label.Completion': 'Коэффициент завершения',
    'Dashboard.Report.ReportSessionOption.AllDays': 'Все сеансы',
    'MursionPortal.Status.Abbreviation.EarlyCancelled': 'ЕС',
    'MursionPortal.Dashboard.Chart.label.SimulationBreakdown.EstimatedRemaining': 'Предполагаемый остаток',
    'Dashboard.ContractProgress.ColumnTooltip.RemainingUtilization': 'Выполненные и запланированные симуляции в процентах от числа приобретенных',
    'MursionPortal.Dashboard.Chart.Header.SimulationBreakdownQuickView': 'Перерыв сеанса – Быстрый просмотр',
    'MursionPortal.NextGen.SelectorText.Race': 'Раса',
    'MursionPortal.NextGen.SelectorText.Ethnicity': 'Этническая принадлежность',
    'MursionPortal.NextGen.SelectorText.None': 'Отсутствует',
    'MursionPortal.NextGen.SelectorText.Woman': 'Одна женщиной',
    'MursionPortal.NextGen.SelectorText.Man': 'Один мужчиной',
    'MursionPortal.NextGen.SelectorText.Wheelchair': 'Один человек должен быть в инвалидном кресле',
    'MursionPortal.NextGen.SelectorText.agedText': 'Один человек должен быть в возрасте 55+',
    'MursionPortal.NextGen.Avatar.Restriction': 'Ограничение на аватар',
    'MursionPortal.NextGen.AvatarOrScenarioComposition': 'Композиция аватара/сценария',
    'MursionPortal.Label.Opening': 'Начало',
    'MursionPortal.Label.Closing': 'Окончание',
    'MursionPortal.Label.Introduction': 'Вступление',
    'MursionPortal.ReflectionGuide.LearnerAssessment': 'Оценка обучающегося',
    'MursionPortal.ReflectionGuide.HostAssessment': 'Оценка ведущего',
    'MursionPortal.ReflectionGuide.HostOpportunity': 'Возможности ведущего',
    'MursionPortal.ReflectionGuide.Introduction.Text': 'С возвращением! Готовы ли Вы поразмышлять о своем опыте? (дайте учащемуся согласие, прежде чем продолжить)',
    'MursionPortal.Label.Say': 'ГОВОРИТЬ',
    'MursionPortal.Label.Ask': 'СПРОСИТЬ',
    'MursionPortal.Label.Bullet': 'Цель',
    'MursionPortal.ReflectionGuide.LearnerPreparation.FirstQuestion': 'Как проходила беседа?',
    'MursionPortal.ReflectionGuide.LearnerPreparation.SecondQuestion': 'Как реальная ситуация, сложившаяся в ходе беседы, соотносится с вашим планом?',
    'MursionPortal.ReflectionGuide.LearnerPreparation.ThirdQuestion': 'Что произошло?',
    'MursionPortal.ReflectionGuide.LearnerPreparation.FourthQuestion': 'Были ли какие-либо сигналы (вербальные и невербальные) со стороны аватара, которые давали Вам понять, как будет проходить разговор?',
    'MursionPortal.ReflectionGuide.LearnerPreparation.FifthQuestion': 'Были ли какие-либо сигналы (вербальные и невербальные) со стороны аватара, которые давали Вам понять, как будет проходить разговор?',
    'MursionPortal.ReflectionGuide.LearnerAssessment.FirstSay': 'В этой беседе вам пришлось преодолевать сложную динамику - Mickey был расстроен.',
    'MursionPortal.ReflectionGuide.LearnerAssessment.SecondSay': 'Несмотря на благие намерения, я часто вижу, как лидеры реагируют не совсем продуктивно:',
    'MursionPortal.ReflectionGuide.LearnerAssessment.ThirdSay': 'Я также видел, как ведущие руководители реагируют более продуктивно:',
    'MursionPortal.ReflectionGuide.LearnerAssessment.FourthSay': 'В ходе беседы вам пришлось преодолевать непростую динамику',
    'MursionPortal.ReflectionGuide.LearnerAssessment.Ask': 'Как вы думаете, где вы оказались? (последующие вопросы: Почему/ Каким образом/ Откуда вы знаете?): Как вы думаете, где вы оказались? (продолжение: Почему/ Каким образом/ Откуда вы знаете?)',
    'MursionPortal.ReflectionGuide.HostAssessment.Opening': 'Если бы я мог выделить одну из сильных сторон, которую вы продемонстрировали во время встречи, то это была бы (выберите одну):',
    'MursionPortal.ReflectionGuide.HostAssessment.Closing': 'Совпадают ли приведенные мною размышления с вашими? Если да, то почему?',
    'MursionPortal.ReflectionGuide.HostAssessment.Opportunity.Opening': 'Если бы я мог улучшить одну из замеченных мною возможностей для развития, то это была бы (выберите одну):',
    'MursionPortal.ReflectionGuide.Closing.Say': 'ЭТО трудная задача, и я благодарю вас за то, что сегодня вы отрабатываете эти важные навыки вместе с Mursion!',
    'MursionPortal.ReflectionGuide.Closing.Ask': 'Что вы извлекли из этого урока, и что вы будете использовать в следующий раз, когда столкнетесь с подобной ситуацией в реальной жизни?',
    'MursionPortal.ScenarioBank.ItemBank.ResearchSummary.Placeholder': 'Выберите краткое описание исследования',
    'MursionPortal.ScenarioBank.ItemBank.LearnerInstruction.Placeholder': 'Выберите инструкцию для учащегося',
    'MursionPortal.NextGen.Scenario.LibraryInfo.ClinkHereToReplaceThisFile': 'Нажмите здесь, чтобы заменить этот файл.',
    'MursionPortal.EngagementHub.EmailLearners': 'Email Learners', // TODO add translations
    'MursionPortal.EngagementHub.InviteToTeam': 'Invite to Team', // TODO add translations
    'MursionPortal.EngagementHub.InviteLearnersToScheduleTitle': 'Invite your learners to start or continue their practice', // TODO add translations
    'MursionPortal.EngagementHub.InviteLearnersToScheduleSummary': 'Engage your learners via email or by embedding shared links in your own communications.', // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations': 'View Invitations', // TODO add translations
    'MursionPortal.EngagementHub.CreateInvite': 'Create Invite', // TODO add translations
    'MursionPortal.ScenarioContent.Label': 'Добавьте минимальное значение {min} и максимальное {max}',
    'MursionPortal.Search.Text.Skills': 'Поиск или выбор навыка(ов)',
    'MursionPortal.Search.Text.Avatar': 'Выполните поиск или выберите аватар(ы)',
    'MursionPortal.NextGen.Scenario.TimelineAndScheduling': 'Сроки и Планирование',
    'MursionPortal.NextGen.Session.Replay': 'Воспроизведение сеанса',
    'MursionPortal.NextGen.StrengthsAndOpportunities': 'Сильные стороны и Возможности',
    'MursionPortal.NextGen.Recommendations': 'Рекомендации',
    'MursionPortal.NextGen.MoreToDiscover': 'Еще больше открытий',
    'MursionPortal.NextGen.MissionAccomplished': 'Миссия выполнена!',
    'MursionPortal.NextGen.Learner.ScenarioMaterials': 'Материалы сценария',
    'MursionPortal.NextGen.Learner.PathwayAffiliation': 'Принадлежность к обучению',
    'MursionPortal.NextGen.Learner.SkillLevel': 'Уровень квалификации',
    'MursionPortal.NextGen.Learner.YourMission': 'Ваша миссия',
    'MursionPortal.ScenarioBank.ShowOnlySecondGenscenarios': 'Показывать только сценарии 2 Gen',
    'MursionPortal.NextGen.CreateNewSkill.Header.Text': 'Создайте новый навык в банке сценариев',
    'MursionPortal.NextGen.Learner.StartSimulation': 'Начать симуляцию',
    'MursionPortal.SimMaterials.Label.CoverStory': 'История на обложке',
    'MursionPortal.SimMaterials.Label.LearnerMission': 'Миссия учащегося',
    'MursionPortal.SimMaterials.Label.Background': 'Справочный материал',
    'MursionPortal.SimMaterials.Label.PerspectivesAndIdentities': 'Перспективы и Особенности',
    'MursionPortal.SimMaterials.HeaderText.SimDashboard': 'Панель управления SIM',
    'MursionPortal.NextGen.Learner.ToStart': 'Начать ',
    'MursionPortal.Project.DeliveryHours.Standard': 'Стандартные часы выполнения',
    'MursionPortal.Project.DeliveryHours.Global': 'Часы глобальной загрузки',
    'MursionPortal.Label.Monday.ShortForm': 'Пн',
    'MursionPortal.Label.Tuesday.ShortForm': 'Вт',
    'MursionPortal.Label.Wednesday.ShortForm': 'Ср',
    'MursionPortal.Label.Thursday.ShortForm': 'Чт',
    'MursionPortal.Label.Friday.ShortForm': 'Пт',
    'MursionPortal.Label.Saturday.ShortForm': 'Сб',
    'MursionPortal.Label.Sunday.ShortForm': 'Вс',
    'MursionPortal.NextGen.CreateFromBank.Customized.Label': 'Изменено в банке сценариев',
    'MursionPortal.NextGen.CreateFromBank.CustomizedFromBank.Tooltip': 'Этот сценарий был изменен по сравнению с исходным из банка сценариев. Любые обновления, внесенные в исходный сценарий, не будут распространяться на него. ',
    'MursionPortal.BuildingBlock.Label.PublishedDate': 'Дата публикации',
    'MursionPortal.Label.CopyTeamInviteLink': 'Скопировать ссылку на приглашение команды',
    'MursionPortal.NextGen.SessionReplayText': 'В ходе этой ситуации вам предстояло потренироваться в проведении беседы, направленной на поддержку члена команды в трудную минуту. Ваш успех здесь зависел от вашей способности сопереживать Джо.',
    'MursionPortal.NextGen.TheMission': 'Миссия:',
    'MursionPortal.NextGen.DemonstratedStrength': 'Продемонстрированная сильная сторона',
    'MursionPortal.NextGen.OpportunityForFutureGrowth': 'Возможность дальнейшего роста',
    'MursionPortal.NextGen.CompetenciesFocus': 'Основными компетенциями были:',
    'MursionPortal.NextGen.StrengthAndOpportunitiesText': 'В ходе подведения итогов беседы были выявлены наблюдаемые сильные стороны и возможности для роста:',
    'MursionPortal.NextGen.WeObserved': 'Мы обнаружили, что....',
    'MursionPortal.NextGen.WhyThisMatters': 'Почему это так важно',
    'MursionPortal.ScenarioSetting.MissOrCancelationRate.ValidationMessage': 'Время блокировки по расписанию не может быть равным нулю, если коэффициент пропусков/отмен не равна нулю.',
    'MursionPortal.NextGen.CreateFromBank.Customized.Modal.Text': 'Вы настроили банк сценариев, и любые последующие изменения не будут отображаться в этом сценарии. Хотите продолжить?',
    'MursionPortal.SimMaterials.Event.SkillsInFocus.Label': 'Профессиональные навыки в фокусе',
    'MursionPortal.SimMaterials.Label.SimulationEvents': 'События симуляции',
    'MursionPortal.SimMaterials.Event.AvatarBehavior.Label': 'Поведение аватара',
    'MursionPortal.SimMaterials.Event.LearnerResponse.Label': 'Ожидаемая реакция обучающегося',
    'MursionPortal.Label.NoTimeSelected': 'Время не выбрано',
    'MursionPortal.Label.StartTime.EndTime.Selected': 'Необходимо выбрать как время начала, так и время окончания',
    'MursionPortal.SimMaterials.Label.ReflectionAndDebrief': 'Размышление и Подведение итогов',
    'MursionPortal.NextGen.Scenario.Planning': 'Планирование',
    'MursionPortal.HowItWorks.Experience.Text': 'Ваш опыт автоматически начнется через несколько секунд!',
    'MursionPortal.HowItWorks.MicCamera': 'Микрофон/камера',
    'MursionPortal.HowItWorks.Prompt.Text': 'При появлении запроса нажмите <strong> "Разрешить" </strong> для доступа к микрофону / камере, чтобы аватар мог видеть и слышать вас!',
    'MursionPortal.HowItWorks.StartSession.Text': 'Нажмите <strong> ‘Начать сеанс’</strong> и ознакомьтесь с описанием сценария!',
    'MursionPortal.LearnerMaterials.KeyCompetencies': 'Основные компетенции',
    'MursionPortal.LearnerMaterials.ResearchMaterials': 'Материалы для исследований',
    'MursionPortal.LearnerMaterials.PracticeOpportunity': 'Возможность практического применения',
    'MursionPortal.ScenarioInfo.Label.ScenarioBank': 'Сценарий создается на основе того, что взят из банка сценариев',
    'MursionPortal.DemandBasedScheduling.SlotSelectionMessage': 'Пожалуйста, выберите подходящее для вас время. Мы назначим сценарий на один из выбранных вами временных интервалов.',
    'MursionPortal.NextGen.ScenarioBank.SubStrengthAssessment': 'Сильная сторона',
    'MursionPortal.NextGen.ScenarioBank.SubOpportunityAssessment': 'Возможность',
    // needs translations
    'MursionPortal.NextGen.replayConversation':'Replay the Conversation',// TODO add translations
    'MursionPortal.EngagementHub.BackTo': 'Back to {clientName}', // TODO add translations
    'MursionPortal.EngagementHub.LearningPotential': 'At Mursion, we believe in maximizing every learner\'s potential.', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationLearnMore': 'Communication Is Key! Learn more', // TODO add translations
    'MursionPortal.EngagementHub.UnlockTeamPotential': 'Unlock Your Team’s Potential', // TODO add translations
    'MursionPortal.EngagementHub.InviteLearners.Title': 'Invite your learners to schedule their simulations', // TODO add translations
    'MursionPortal.EngagementHub.InviteLearners.Summary': '58% of users are more likely to sign up for their simulation if you send a custom invitation to schedule.', // TODO add translations
    'MursionPortal.EngagementHub.ManageEmailSettings.Label': 'Your Email, Your Way!', // TODO add translations
    'MursionPortal.EngagementHub.ManageEmailSettings.Title': 'Manage email settings and gain insights', // TODO add translations
    'MursionPortal.EngagementHub.Customize': 'Customize', // TODO add translations
    'MursionPortal.EngagementHub.EmailConfigurations.Title': 'Email Configurations', // TODO add translations
    'MursionPortal.EngagementHub.EmailConfigurations.Summary': 'Customize email messages that resonate with your learners and drive adoption and engagement.', // TODO add translations
    'MursionPortal.EngagementHub.PowerfulEmailAnalytics.Title': 'Powerful Email Analytics', // TODO add translations
    'MursionPortal.EngagementHub.PowerfulEmailAnalytics.Summary': 'Measure email open, click, and unread rates to discover insights and make data-driven decisions.', // TODO add translations
    'MursionPortal.EngagementHub.PersonalizeMessaging.Label': 'Add Personal Touch', // TODO add translations
    'MursionPortal.EngagementHub.PersonalizeMessaging.Title': 'Personalize messaging in the Mursion Portal', // TODO add translations
    'MursionPortal.EngagementHub.LearnerDashboard': 'Learner Dashboard', // TODO add translations
    'MursionPortal.EngagementHub.ScenarioPage': 'Scenario Pages', // TODO add translations
    'MursionPortal.EngagementHub.SchedulingPage': 'Scheduling Page', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.LearnerFeedbackTooltip': 'This email will only go out if feedback report is enabled at project level. Please reach out to your Mursion representative to learn more.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Title': 'Email Settings', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Summary': 'Use these tools to help customize different emails.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.EmailName': 'Email Name', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.SenderName': 'Sender Name (email will be no-reply@mursion.com)', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.ViewCustomize': 'View/Customize', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.EnableDisable': 'Enable/Disable', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.LearnerLockout': 'Learner Lockout', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.VideoFeedback': 'Video & Feedback', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.ModifySettings': 'Click edit to modify the settings', // TODO add translations
    'MursionPortal.EngagementHub.BackTitle': 'Back to Engagement Hub', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey': 'Communication Is Key!', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.summary': 'A great Mursion rollout starts with effective communication to your learners. It\'s important to ensure they are prepared and understand the connection between Mursion and your learning and development program goals.', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.TItle': 'Elements of Effective Learner Communication', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.LearnerBenefits.Title': 'Lead with Learner Benefits', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.LearnerBenefits.Summary': 'Lead with learner benefits, not product features. Share learner testimonials and tie the skills they are developing to business objectives.', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.PracticeProgress.Title': 'Practice makes Progress', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.PracticeProgress.Summary': 'Practice is essential to developing new skills. Ensure your message highlights The Importance of Practice - And Our Reluctance To Do It to acknowledge challenges that learners face.', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.LeadersMessage.Title': 'Message from Leaders', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.LeadersMessage.Summary': 'Ask senior leaders to communicate (meetings, written, video, etc) why Mursion sessions are valuable and how developing the target skills is tied to business priorities and growth opportunities.', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.SetExpectations.Title': 'Set Expectations', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.SetExpectations.Summary': 'Mursion is a new technology that uses avatars powered by humans. Prepare learners for these conversations by sharing why and how avatars create psychology safety and is an effective way to practice.', // TODO add translations
    'MursionPortal.Tab.Engagement': 'Engagement', // TODO add translations
    'MursionPortal.Tab.EngagementHub': 'Engagement Hub', // TODO add translations
    'MursionPortal.Tab.EmailManagement': 'Email Management', // TODO add translations
    'MursionPortal.Tab.PortalMessaging': 'Portal Messaging', // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations.BackButton.Label': 'Back to Invite to Schedule', // TODO add translations
    'MursionPortal.Portal.Messaging.Heading.Text': 'Inform, engage, and delight learners using your organization’s unique brand voice.',  // TODO add translations
    'MursionPortal.Portal.Messaging.SubHeading.Text': 'Add custom messaging throughout the Portal to engage your learners.',  // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations.Column.InvitationDate': 'Invitation Date', // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations.Column.MessageToLearners': 'Message to Learners', // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations.Column.ExcludedLearners': 'Excluded Learners', // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations.Column.CreatedBy': 'Created By', // TODO add translations
    'MursionPortal.Portal.EngagementHub.InviteToScheuled.Text': '{scenariosCount} scenarios assigned to {learnerCount} learners', // TODO add translations
    'MursionPortal.Portal.EngagementHub.InviteToScheuled.Text.AddLearners': 'Add Learners', // TODO add translations
    'MursionPortal.Tab.EmailManagement.Analytics': 'Analytics', // TODO add translations
    'MursionPortal.CustomizeLearnerInvitations.Teams.Invitation.Message': 'The following team(s) already have scheduled an invitation on this day:', // TODO add translations
    'MursionPortal.CustomizeLearnerInvitations.New.Invitation.Message': 'Please make a note and start a new invitation', // TODO add translations
    'MursionPortal.CustomizeLearnerInvitations.Learners.Invitation.Text': 'Learners will receive the invitation in the early morning hours (Pacific time) on this day.', // TODO add translations
    'MursionPortal.CustomizeLearnerInvitations.SendDate.Label': 'Send Date', // TODO add translations
    'MursionPortal.Scenario.Generation.FilterType.Label' : 'Generation', // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations.CancelInvitation.Modal.Text': 'Are you sure you want to cancel the invitation created by {createdBy}?', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.LearnerFeedbackReport': 'Learner Feedback Report', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.CancellationByLearner': 'Cancellation by Learner', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.MissedSessionByLearner': 'Missed by Learner', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.LearnerReleased': 'Learner Released', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.LearnerLockOut': 'Learner Lockout', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.SessionConfirmation': 'Session Confirmation', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.Invitation': 'Welcome to Mursion - a platform for you to practice your skills', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.InvitationReminder': `Reminder:You're invited to {licenseeName}`, // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.Confirmation': 'Thanks for signing up! Your Simulation is confirmed.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.FirstReminder': `Don't forget! Your Mursion simulation is soon!`, // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.SecondReminder': `Get excited! Your Mursion simulation starts in {upcomingBeforeStart} minutes.`, // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.CancellationByByLearner': 'Your simulation was canceled', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.MissedSessionByLearner': 'We missed you today!', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.LearnerLockedOut': 'You missed/cancelled this scenario too many times', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.LearnerReleased': 'Your session is available to schedule', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.LearnerSessionRecordingIsReady': 'Want to see how you did? Visit your Mursion Dashboard.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.LearnerFeedbackReport': 'Want to see how you did? Review your session report.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.LearnerSessionRecordingReady': 'Learner Session Recording Ready', // TODO add translations
    'MursionPortal.EngagementHub.BackToEmailSettings': 'Back to Email Settings', // TODO add translations
    'MursionPortal.EngagementHub.CustomizeEmail.Text': 'Learners will start receiving this email after you save.', // TODO add translations
    'MursionPortal.NextGen.Learner.FeedbackFrom': 'Feedback from', // TODO add translations
    'MursionPortal.NextGen.Scenario.SimMaterials': 'SIM Materials', // TODO add translations
    'MursionPortal.NextGen.Scenario.LearnerMaterials': 'Learner Materials', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Modal.BodyText': 'Are you sure you want to save the Enable/Disable settings', // TODO add translations
    'MursionPortal.Client.EmailSettings.SessionRescheduledByPS': 'Session Rescheduled by PS', // TODO add translations
    'MursionPortal.EngagementHub.EmailPreview.Sender': 'Sender', // TODO add translations
    'MursionPortal.Scenario.CoverStory.Tooltip.Text': 'Cover Story will be visible to clients in the scenario library as well as learners and SIMs in their materials',// TODO add translations
    'MursionPortal.Scenario.Summary.Tooltip.Text':'Summary will be visible to clients in the scenario library',// TODO add translations
    'MursionPortal.NextGen.Event.positiveMindset': 'There must be at least ({startCount}) positive mindset defined. You can specify a maximum of ({endCount}) positive mindsets.', // TODO add translations
    'MursionPortal.NextGen.Event.derailingMindset': 'There must be at least ({startCount}) derailing mindset defined. You can specify a maximum of ({endCount}) derailing mindsets.', // TODO add translations
    'MursionPortal.NextGen.Event.StrengthAssessment': 'There must be at least ({startCount}) strength assessment defined. You can specify a maximum of ({endCount}) strength assessments.', // TODO add translations
    'MursionPortal.NextGen.Event.OpportunityAssessment': 'There must be at least ({startCount}) opportunity assessment defined. You can specify a maximum of ({endCount}) opportunity assessments.', // TODO add translations
    'MursionPortal.NextGen.Event.StrengthAssessment.AvatarResponse': 'You have added {addedCount} avatar response for strength assessment(s), you must add at least {totalCount} avatar response for strength assessment(s).', // TODO add translations 
    'MursionPortal.NextGen.Event.OpportunityAssessment.AvatarResponse': 'You have added {addedCount} avatar response for opportunity assessment(s), you must add at least {totalCount} avatar response for opportunity assessment(s).', // TODO add translations 
    'MursionPortal.Label.ClientLevelMissCancelSetting': 'Client Level Miss/Cancel Setting', // TODO add translations 
    'MursionPortal.Label.ProjectLevelMissCancelSetting':'Project Level Miss/Cancel Setting', // TODO add translations 
    'MursionPortal.Label.ScenarioLevelMissCancelSetting':'Scenario Level Miss/Cancel Setting', // TODO add translations 
    'MursionPortal.Label.MissedCancelledSessions':'Missed/Cancelled Sessions', // TODO add translations 
    'MursionPortal.EngagementHub.ViewInvitations.Column.SenderName': 'Sender Name', // TODO add translations,
    'MursionPortal.NextGen.Event.Delete.ConfirmBox': 'Are you sure you want to delete this {message}?', // TODO add translations,
    'MursionPortal.NextGen.Event.Message.PositiveMindset': 'Positive Mindset', // TODO add translations,
    'MursionPortal.NextGen.Event.Message.DerailingMindset': 'Derailing Mindset', // TODO add translations,
    'MursionPortal.NextGen.Event.Message.StrengthAssessment': 'Strength assessment', // TODO add translations,
    'MursionPortal.NextGen.Event.Message.OpportunityAssessment': 'Opportunity assessment', // TODO add translations,
    'MursionPortal.PersonalizedMessageTitle': 'Personalized message header. This will appear before every personalized message in Portal.', // TODO add translations
    'MursionPortal.NextGen.ScenarioReferences': 'Scenario References', // TODO add translations 
    'MursionPortal.NextGen.ChildScenarios': 'Child Scenarios', // TODO add translations 
    'MursionPortal.NextGen.Label.Customized': 'Customized', // TODO add translations  
    'MursionPortal.EngagementHub.EmailSettings.InvitationPasswordUsers': 'Invitation (Password Users)', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.InvitationReminderPasswordUsers': 'Invitation Reminder (Password Users)', // TODO add translations
    'MursionPortal.Label.Clicked': 'Clicked', // TODO add translations
    'MursionPortal.Client.EmailSettings.SessionRescheduledByLearner': 'Session Rescheduled by Learner', // TODO add translations
    'MursionPortal.Client.Team.CopyTeamInviteLink.Tooltip':'Users who have not yet registered or have not yet been added to the Portal will not be able to access this link. Please click the "Add Learners" button.', // TODO add translations
    'Settings.Config.LearnerSessionReportDelay': 'Learner Session Report Delay', // TODO add translations
    'MursionPortal.SkillsReferences.Label': 'Skills References', // TODO add translations
    'MursionPortal.ResearchSummariesReferences.Label': 'Research Summaries References', // TODO add translations
    'MursionPortal.Search.Text': 'Search or select', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.Event': 'Please select Event', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.Skill': 'Please select Skill', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.AvatarBehavior': 'Avatar Behavior is required', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.ExpectedLearnerResponse': 'Expected Learner Response is required', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.PositiveMindset': 'Please Select Positive Mindset', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.PositiveMindsetDescription': 'Positive mindset is required', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.DerailingMindset': 'Please Select Derailing Mindset', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.DerailingMindsetDescription': 'Derailing mindset is required', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.DescribeObservedAction': 'Describe Observed Action is required', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.ExplainImpact': 'Explain Impact is required', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.AvatarName': 'Please select avatar name', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.PossibleAvatarResponse':'Possible Avatar Response is required', // TODO add translations
    'MursionPortal.Label.SchedulingLockoutDate': 'Scheduling Lockout Date', // TODO add translations
    'MursionPortal.Label.SchedulingLockoutDate.Tooltip': 'Lockout effective date for all learners who miss and/or late cancel', // TODO add translations
    'MursionPortal.NextGen.scorining.Message.MinimumScore': 'You’ve changed the number of events. Please update the minimum score.', // TODO add translations
    'MursionPortal.NextGen.scorining.Message.SynopsisDescription': 'Synopsis description is required', // TODO add translations
    'MursionPortal.NextGen.scorining.Message.SuccessfulAssessment': 'Successful Assessment is required', // TODO add translations
    'MursionPortal.NextGen.scorining.Message.OpportunityAssessment': 'Opportunity Assessment is required', // TODO add translations
    'MursionPortal.NextGen.Scenario.LibraryInfo.PathwaysAffiliation': 'Pathway Affiliation(s)', // TODO add translations
    'MursionPortal.NextGen.BankScenarioReferences': 'Bank Scenario References', // TODO add translations
    'MursionPortal.Dashboard.ScenarioLearner.Header.LearnerActivationStatus':'Learner Activation',  // TODO add translations
    'MursionPortal.Dashboard.ScenarioLearner.Header.LearnerInviteTitle':'Unlock Your Team’s Potential',  // TODO add translations
    'MursionPortal.Dashboard.ScenarioLearner.Header.LearnerInviteMessage':'Target invitations to your learners with custom messaging',  // TODO add translations
    'MursionPortal.Dashboard.ScenarioLearner.Header.LearnerInvite':'Invite to Schedule',  // TODO add translations
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.NotYetRegistered':'Not Yet Registered', // TODO add translations
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.NotYetScheduled':'Not Yet Scheduled', // TODO add translations
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.ScheduledNotYetCompleted':'Scheduled - Not Yet Completed', // TODO add translations
    'MursionPortal.NextGen.ViewAndInviteLearners': 'View & Invite Learners', // TODO add translations
    'MursionPortal.Dashboard.Tooltip.Label.SchedulingEmail': 'Session Specific Emails and other Join Session Emails', // TODO add translations
    'MursionPortal.Dashboard.EmailAnalytics.Label.InviteToScheduleEmail': 'Invite-to-Schedule Emails', // TODO add translations
    'MursionPortal.Dashboard.Tooltip.Label.CallToActionEmail': 'Emails sent to learners inviting them to schedule scenario(s)', // TODO add translations
    'MursionPortal.NextGen.Scenario.ClientCustomizations': 'Client Customizations', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.InviteToSchedule.Tooltip': 'Invite to Schedule emails are able to be customized each time you send them.', // TODO add translations
    'MursionPortal.Contract.ContractTimezone': 'Contract Timezone', // TODO add translations
    'Dashboard.LeftPane.Simulations.SessionSearch':'Search for Session', // TODO add translations
    'MursionPortal.NextGen.Scenario.ClientCustomizations.ClientScenarioTitle': 'Client Scenario Title', // TODO add translations
    'MursionPortal.NextGen.Scenario.ClientCustomizations.ClientScenarioTitle.Tooltip': 'Client learners and program admins will see this title only. SIMs will see the bank scenario title and client scenario title in their SIM materials.', // TODO add translations
    'MursionPortal.NextGen.Event.DeleteMessage': 'Minimum 2 Events are mandatory for a Scenario.', // TODO add translations
    'Settings.Config.ProjectAndScenario.AllowSimPracticeSession': 'SIM Practice Session', // TODO add translations
    'MursionPortal.Calendar.Button.Practice.Session': 'Practice Session', // TODO add translations
    'MursionPortal.Scenario.InTrainingSimSpecialist': 'In Training SIM Specialists', // TODO add translations
    'MursionPortal.Scenario.NoInTrainingSimSpecialist': 'No In Training Sim Specialist', // TODO add translations
    'MursionPortal.Simspecialist.Thankyou.summary': 'You are scheduled for your practice simulation', // TODO add translations
    'MursionPortal.Button.DoNotSaveMyWork': 'Don’t Save my Work', // TODO add translations
    'MursionPortal.NextGen.Confirm.BrowserBack': 'You have not saved your changes, and navigating away will cause you to lose your work. Please complete required fields.', // TODO add translations
    'MursionPortal.UserConsent.Learner.Description': 'Если вы скажете «Я согласен», программное обеспечение Mursion обработает ваше живое моделирование и сделает видеозапись сеанса моделирования для вашего просмотра на портале программного обеспечения Mursion. За исключением случаев, предусмотренных действующим законодательством или в целях обеспечения качества, Mursion не будет распространять или раскрывать вашу видеозапись без вашего разрешения. Компания Mursion будет использовать анонимизированные данные об использовании вами ее программного обеспечения для улучшения своей технологии моделирования.', // TODO add translations, this translation was generated using the Google Translate, retranslation required in the future
    'MursionPortal.UserConsent.LearnerAndAccountOwnerOrFacilitator.Description': 'Если вы скажете «Я согласен», программное обеспечение Mursion обработает ваше живое моделирование и сделает видеозапись сеанса моделирования для вашего просмотра на портале программного обеспечения Mursion. Обратите внимание, что по запросу клиента, который лицензировал программное обеспечение Mursion от вашего имени, копия ваших записанных сеансов моделирования будет передана тренеру, наставнику или рецензенту, выбранному клиентом. Все рецензенты по контракту обязаны следовать <code>уведомлению о конфиденциальности Mursion</code>, если только вы не согласились с другими условиями конфиденциальности. За исключением случаев, предусмотренных действующим законодательством или в целях обеспечения качества, Mursion не будет распространять или раскрывать вашу видеозапись без вашего разрешения. Компания Mursion будет использовать анонимизированные данные об использовании вами ее программного обеспечения для улучшения своей технологии моделирования.', // TODO add translations, this translation was generated using the Google Translate, retranslation required in the future
    'MursionPortal.UserConsent.AccountOwnerOrFacilitator.Description': 'Ваши сеансы моделирования будут записаны как на аудио, так и на видео по запросу клиента, который лицензировал программное обеспечение Mursion от вашего имени. Если вы согласны продолжить, копия ваших записанных сеансов моделирования будет передана выбранной клиентом третьей стороне для анализа и оценки записей. Эти лица по контракту обязаны соблюдать <code>Уведомление о конфиденциальности Mursion</code>, если только вы не согласились с другими условиями конфиденциальности. Однако вы не получите или не будете иметь доступа к копии записанных сеансов моделирования в соответствии с условиями задания или оценки, которые вы согласились выполнить. Компания Mursion может использовать вашу видеозапись в целях обеспечения качества и будет использовать анонимные данные, полученные в результате использования вами ее программного обеспечения, для улучшения своей технологии моделирования.', // TODO add translations, this translation was generated using the Google Translate, retranslation required in the future
    'MursionPortal.ProjectCreate.DeliveryHours.Validation': 'Delivery Hours Start time should be less than End time.', // TODO add translations
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.NotYetRegistered':'Unique number of learners who have been added to the Portal but have not yet completed registration', // TODO add translations
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.NotYetScheduled':'Unique number of learners who have registered but have not yet scheduled a session', // TODO add translations
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.ScheduledNotYetCompleted':'Unique number of learners who have registered and scheduled at least one session but have not yet completed a session', // TODO add translations
    'MursionPortal.Restriction': 'Restriction', // TODO add translations
    'MursionPortal.Dashboard.SessionSearch.Placeholder' : 'Paste a session ID and hit return',  // TODO add translations
    'Operations.TechRestriction.CreateDialog.Title': 'New Holiday Tech Restriction', // TODO add translations
    'Operations.TechRestriction.EditDialog.Title': 'Edit Holiday Tech Restriction', // TODO add translations
    'Operations.TechRestriction.ViewDialog.Title': 'View Holiday Tech Restriction', // TODO add translations
    'MursionPortal.Client.EmailSettings.TriggerHours': 'Trigger (Hours)', // TODO add translations
    'MursionPortal.Client.EmailSettings.TriggerMin': 'Trigger (Minutes)', // TODO add translations
    'MursionPortal.Duplicate.Scenario.Confirmation.Text': 'Are you sure you want to duplicate this scenario?',  // TODO add translations
    'MursionPortal.Contract.LineItem.ToolTip': 'A contract can only contain one type of line item. If your contract has both sessions and hours, please convert the sessions into hours and add it as an hours line item.', // TODO add translations
    'MursionPortal.ReflectionGuide.LearnerPerspective': 'Learner Perspective', // TODO add translations
    'MursionPortal.Dashboard.SessionSearch.Table.Placeholder': 'Search for Session will load once you search session ID', // TODO add translations
    'MursionPortal.Dashboard.SessionSearch.EmptySession': 'No sessions match that ID', // TODO add translations
    'MursionPortal.SimMaterials.Label.IntroAndPreReflection': 'Intro & Pre-Reflection', // TODO add translations
    'MursionPortal.Dashboard.Chart.Header.LearnerBreakdown': 'Learner Breakdown', // TODO add translations
    'MursionPortal.Dashboard.Chart.Header.LearnerBreakdown.Activated': 'Activated', // TODO add translations
    'MursionPortal.NextGen.Scenario.LibraryInfo.VersionNumber': 'Version Number', // TODO add translations
    'MursionPortal.NextGen.Scenario.LibraryInfo.VersionDetails': 'Version Details', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.EmailReminderHours': 'Trigger {tiggerValue} hours before Session.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.EmailReminderMinutes': 'Trigger {tiggerValue} minutes before Session.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.FirstEmailReminder.Tooltip': 'Time before Session when First Reminder would go. If not set Licensee Setting would be used.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.SecondEmailReminder.Tooltip': 'Time before Session when Second Reminder would go. If not set Licensee Setting would be used.', // TODO add translations
    'MursionPortal.SimAttendance.MissedSimulation.Message': 'You missed this simulation so you are unable to edit attendance. Please contact Support if you did not miss the simulation or had a technical issue that caused you to miss the simulation.', // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations.Disclaimer': 'Invites are visible for last 30 days only', // TODO add translations
    'MursionPortal.PortalVersion': 'Portal Version', // TODO add translations
    'MursionPortal.ScenarioInfo.Label.AutoSIM': 'AutoSIM', // TODO add translations
    'MursionPortal.ProjectContract.AddConfirmation': 'Are you sure you want to add the contract to this project?', // TODO add translations
    'MursionPortal.ProjectContract.CompletedContract.AddConfirmation': 'Are you sure you want to add the completed contract to this project?', // TODO add translations
    'MursionPortal.Label.NewPortal' : 'New Portal', // TODO add translations
    'MursionPortal.Label.OldPortal' : 'Old Portal', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.ReminderValidation': 'Second Reminder should be less than First Reminder', // TODO add translations
    'MursionPortal.Project.InviteToSchedule.EmailDisabled.Tooltip' :'Invite to Schedule is disabled as email settings are disabled. Enable them to send this invite', // TODO add translations
    'MursionPortal.SimAttendance.Error.SimReportedError' :'SIM Reported Error (Does not impact session status)', // TODO add translations
    'MursionPortal.SimAttendance.Error.SimLateOrNoShow' :'SIM late or no show', // TODO add translations
    'MursionPortal.SimAttendance.Error.SessionStartedTooEarly' :'Session started too early', // TODO add translations
    'MursionPortal.SimAttendance.Error.AvatarIssue' :'Avatar Issue', // TODO add translations
    'MursionPortal.SimAttendance.Error.MouthlipSyncIssueDuringSession' :'Mouth/lip sync issue during session', // TODO add translations
    'MursionPortal.SimAttendance.Error.Hardware' :'Hardware', // TODO add translations
    'MursionPortal.SimAttendance.Error.AudioVideoIssueForSim' :'Audio/video issue for SIM', // TODO add translations
    'MursionPortal.SimAttendance.Error.AudioVideoIssueForLearner' :'Audio/video issue for Learner', // TODO add translations
    'MursionPortal.SimAttendance.Error.OtherSimHardwareIssues' :'Other SIM hardware issues (controller, laptop, etc.)', // TODO add translations
    'MursionPortal.SimAttendance.Error.LearnerAndSimBothJoined' :'Learner and SIM both joined, but no connection established', // TODO add translations
    'MursionPortal.SimAttendance.Error.SimMomentIssue' :'SIM Moment issue', // TODO add translations
    'MursionPortal.SimAttendance.Error.WifiIssue' :'Wifi Issue', // TODO add translations
    'MursionPortal.SimAttendance.Error.LearnerWifi' :'Learner Wifi', // TODO add translations
    'MursionPortal.SimAttendance.Error.SimWifi' :'SIM Wifi', // TODO add translations
    'MursionPortal.SimAttendance.Error.AdditionalDetails' :'Additional Details', // TODO add translations
    'MursionPortal.SimAttendance.Error.DescribeYourError' :'Describe your error...', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.CancellationByProfessionalServiceOrAccountOwner': 'Cancellation by PS or AO', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.CompleteLearnerSurvey': 'Complete Learner Survey', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.CompleteLearnerSurvey': 'How\'d it go?', // TODO add translations
    'MursionPortal.CustomizeInvitation.ButtonText.Label': 'Button Text', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.Client.LearnerLockedOut': 'You are locked out!', // TODO add translations
    'MursionPortal.Filter.ReleaseLevel.Placeholder': 'Release Level', // TODO add translations
    'MursionPortal.TOTP.Page.Heading': 'We just sent a security code to your {email}. Enter the code to sign in.', // TODO add translations
    'MursionPortal.TOTP.ResendOTP.Text': 'Resend Code', // TODO add translations
    'MursionPortal.TOTP.Code.Label': 'Code', // TODO add translations
    'MursionPortal.TOTP.IncorrectCode.ErrorMsg': 'The code is incorrect. Enter the correct code.', // TODO add translations
    'MursionPortal.TOTP.ResendNewCode.ErrorMsg': 'The code has expired, click "Resend" to receive a new code.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.Client.LearnerReleased': 'You are ready to schedule again.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.NewPortal.Subject.FirstReminder': `You’ve an upcoming Mursion Session in {firstReminderTime} hours!`, // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.NewPortal.Subject.SecondReminder': `Your Mursion Session is starting in {upcomingBeforeStart} minutes!`, // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.NewPortal.Subject.LearnerFeedbackReport': 'Mursion - Review Your Session Performance Report.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.NewPortal.Subject.LearnerSessionRecordingIsReady': 'Your Session Recording is now up! Review how you did', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.NewPortal.Subject.Confirmation': 'Your Mursion Session is Confirmed!', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.NewPortal.Subject.CancellationByLearnerOrPSOrAO': 'Your {licenseeName} session was canceled', // TODO add translations
    'MursionPortal.Personalized.PathwayPage.Section': 'Message you want learners to see on Pathway Page', // TODO add translations
    'MursionPortal.Personalized.CreateNewPathway.Button.Label': 'Create new for specific pathway', // TODO add translations
    'Projects.ScenarioList.NoPathwaysFound': 'No Pathways have been found.',// TODO add translations
    'MursionPortal.Personalized.Pathway.Search.Placeholder': 'Search or select Pathway(s):',// TODO add translations
    'Mursion.Portal.Status.SimTechnicalError': 'Sim Technical Error',// TODO add translations
    'MursionPortal.SimAttendance.Sim.Tooltip.Error': 'Session was unable to be completed due to technical difficulties on the Sim’s side. (if the session was able to be completed after an error this session should be marked complete and not error).',// TODO add translations
    'Session.Edit.Modal.Notes.BlankNoteValidation': 'Either add a valid note or remove this block', // TODO add translations
    'MursionPortal.Scenario.Error.Msg': 'Please complete required field.', // TODO add translations
    'MursionPortal.Scenario.Skills.Error.Msg': 'The number of skills must be in the range of {minSkills} and {maxSkills}.', // TODO add translations
    'MursionPortal.Capitalized.Incomplete': 'INCOMPLETE', // TODO add translations
    'MursionPortal.Dashboard.Chart.LicenseBreakdown.Title': 'License Breakdown – Contract Item',  // TODO add translations
    'MursionPortal.Dashboard.Chart.LearnerLicenses': 'Learner Licenses',  // TODO add translations
    'MursionPortal.Dashboard.Chart.LearnersRegistered': 'Learners Registered',  // TODO add translations
    'MursionPortal.Dashboard.Chart.LearnersScheduled': 'Learners Scheduled',  // TODO add translations
    'MursionPortal.SessionAttendance.AddAttendees': 'Add attendees',  // TODO add translations
    'MursionPortal.SessionAttendance.SimulationSpecialist': 'Simulation Specialist',  // TODO add translations
    'MursionPortal.Dashboard.SimulationSpecialist.Status': 'Simulation Specialist Status',  // TODO add translations
    'MursionPortal.Dashboard.Technical.Issue.Summary': 'If a technical issue occurred during this session, please indicate the type(s) of issue encountered:',  // TODO add translations
    'MursionPortal.Dashboard.Provide.Details': 'Please provide more details on what happened',  // TODO add translations
    'MursionPortal.SessionAttendance.SwitchToSimRole.Message': 'Please switch role to Sim Specialist to view the page!', // TODO add translations
    'MursionPortal.SessionAttendance.Heading': '<code>Instructions:</code> Review the Session, Simulation Specialist, and Learner Status\' below and update as required. This form may be edited for 24 hours after the end of the session', // TODO add translations
    'MursionPortal.SessionAttendance.SessionStatus.Label': 'This is automatically determined by the Learner and Simulation Specialist status. No action required.', // TODO add translations
    'MursionPortal.SessionAttendance.SessionDetails.Heading': '(selections below do not impact session status)', // TODO add translations
    'MursionPortal.SessionAttendance.SessionDetails.Instructions': '<code>Instructions:</code> Session details <code>must</code> be provided for any session that has a Learner Status other than ‘Complete’ or ‘No Show’. To complete, select one or more issue type(s) and describe details in the text box. Session detail information does not impact the Learner, Simulation Specialist, or Session status.', // TODO add translations
    'MursionPortal.SessionAttendance.SessionDetails.Text': `Mursion Simulation Specialists only: If you filled out a UTS ticket for this session, please include the link to the Slack post in the text box (click the 3 vertical dots to the right of the post and select ‘Copy link').`, // TODO add translations
    'MursionPortal.SessionAttendance.SessionStatus.Simspecialist.Label': 'This is automatically determined by the system. If you encountered an error that prevented the session from being completed and the indicated status is ‘Left’, select ‘Technical Error (SIMS)’. Otherwise, no action required.', // TODO add translations
    'MursionPortal.SessionAttendance.SessionStatus.Learner.Label': 'This is automatically determined by the system. If the indicated status is inaccurate, select the correct status and explain the reason for the revision in Session Details. Otherwise, no action required.', // TODO add translations
    'MursionPortal.SessionAttendance.SessionStatus.SimStatus.Label': 'Technical Error (SIMs)', // TODO add translations
    'Mursion.Portal.Status.UnableToComplete': 'Unable to Complete', // TODO add translations
    'Mursion.Portal.Status.ElectedToLeave': 'Elected to Leave', // TODO add translations
    'Mursion.Portal.Status.TechnicalErrorLearner': 'Technical Error (Learner)', // TODO add translations
    'MursionPortal.SessionAttendance.Error.Audio': 'Audio', // TODO add translations
    'MursionPortal.SessionAttendance.Error.ConnectionInternet': 'Connection / Internet', // TODO add translations
    'MursionPortal.SessionAttendance.Error.LearnerTechnicalIssues': 'Learner technical issues', // TODO add translations
    'MursionPortal.SessionAttendance.Error.MursionTechnicalIssues': 'Mursion technical issues', // TODO add translations
    'MursionPortal.SessionAttendance.Error.OtherTechnicalIssues': 'Other technical issues', // TODO add translations
    'MursionPortal.SessionAttendance.Error.MomentSoftware': 'Moment Software', // TODO add translations
    'MursionPortal.SessionAttendance.Error.AvatarOrEnvironmentLipSyncMovementEtc': 'Avatar or Environment (lip sync, movement, etc.)', // TODO add translations
    'MursionPortal.SessionAttendance.Error.HardwareControllerLaptopEtc': 'Hardware (controller, laptop, etc.)', // TODO add translations
    'MursionPortal.SessionAttendance.Error.LearnerAndSimulationSpecialistBothJoined': 'Learner and Simulation Specialist both joined, no connection established', // TODO add translations
    'MursionPortal.SessionAttendance.Error.OtherPleaseExplainBelow': 'Other (please explain below)', // TODO add translations
    'MursionPortal.SessionAttendance.Error.NonTechnicalIssues': 'Non-technical issues', // TODO add translations
    'MursionPortal.SessionAttendance.Error.LearnerMaterialsOrPreparation': 'Learner materials or preparation ', // TODO add translations
    'MursionPortal.SessionAttendance.Error.LearnerLanguageFluency': 'Learner language fluency', // TODO add translations
    'MursionPortal.SessionAttendance.Error.LearnerElectedToLeave': 'Learner elected to leave', // TODO add translations
    'MursionPortal.Dashboard.Overview.Empty.Body.Message': 'Contract report will load once you make a selection', // TODO add translations
    'MursionPortal.Dashboard.Chart.Header.SimulationBreakdown.ContractItem.Simulation':'Session Breakdown – Simulation Contract Item', // TODO add translations
    'MursionPortal.Dashboard.Chart.Header.SimulationBreakdown.ContractItem.Hour':'Session Breakdown – Hour Contract Item', // TODO add translations
    'MursionPortal.Copy.Tooltip.Text': 'Copy (plain text only, no formatting)', // TODO add translations
    'MursionPortal.RTF.DropDown.Text.Green': 'Green', // TODO add translations
    'MursionPortal.RTF.DropDown.Text.Thick': 'Thick', // TODO add translations
    'MursionPortal.Status.Connected.Label': 'Connected',  // TODO add translations
    'MursionPortal.Status.Immersing.Label': 'Immersing',  // TODO add translations
    'MursionPortal.Dashboard.NonTechnical.Issue.Summary': 'If a non-technical issue occurred during this session, please indicate the type(s) of issue encountered:',  // TODO add translations
    'MursionPortal.AutoSim.Scenario.TooltipLabel': 'AutoSim scenario can’t be scheduled.', // TODO add translations
    'MursionPortal.Scenario.Planing.AutoAssignTeam': 'Auto-Assign Team', // TODO add translations
    'MursionPortal.Scenario.Planing.AutoAssignTeam.Tooltip': 'Automatically assign this scenario to the selected teams when learners access the scenario page via the provided link.', // TODO add translations
    'MursionPortal.Dashboard.Overview.NoAssociatedDataForSelectedContract': 'No associated data for the selected contract(s)', // TODO add translations
    'MursionPortal.ScenarioBank.OutcomeLevelSelector.LevelOne': 'Level 1', // TODO add translations
    'MursionPortal.ScenarioBank.OutcomeLevelSelector.LevelTwo': 'Level 2', // TODO add translations
    'MursionPortal.ScenarioBank.OutcomeLevelSelector.LevelThree': 'Level 3', // TODO add translations
    'MursionPortal.Dashboard.UpcomingSessions.IVS.Title': 'Make sure you’re ready to take the session by testing your connection before joining.',// TODO add translations
    'MursionPortal.Dashboard.UpcomingSessions.IVS.Button.Label': 'Test Connection',// TODO add translations
    'MursionPortal.Dashboard.UpcomingSessions.IVS.Support.Link.Title': 'If any test fails, copy results at the end of the test and share them with our support team by',// TODO add translations
    'MursionPortal.Dashboard.UpcomingSessions.IVS.Support.Link': 'Submitting a Support ticket.',// TODO add translations
    'MursionPortal.Dashboard.OnDemand.Text':'Ready, set, practice on-the-go',
    'MursionPortal.Dashboard.OnDemand.Title':'Try On-Demand Simulations',
    'MursionPortal.Dashboard.OnDemand.SubTitle':'Get a first look at the future of skill-building at work - dynamic GenAI simulations available instantly, no scheduling required. Practice anytime and receive actionable feedback that helps you grow and master new skills.',
    'MursionPortal.Dashboard.OnDemand.SmallTitle':'Early access, real results—get ahead now.',
    'MursionPortal.Dashboard.OnDemand.Button.Label':'Go to Mursion On-Demand',
    'MursionPortal.Dashboard.OnDemand.Button.Hide.Label':'Hide this',
    'MursionPortal.Dashboard.OnDemand.Preview':'PREVIEW',
    'MursionPortal.Dashboard.OnDemand.Text1':'✓ Scalable, focused practice',
    'MursionPortal.Dashboard.OnDemand.Text2':'✓ Flexible access',
    'MursionPortal.Dashboard.OnDemand.Text3':'✓ Targeted practice for mastery',
    'MursionPortal.Dashboard.OnDemand.Text4':'✓ Guided self-reflection',
    'MursionPortal.Dashboard.OnDemand.Mursion':'Mursion',
  },
};
export default russianLangTranslations;
